import React from "react";
import AddIcon from "./AddIcon";
import MinusIcon from "./MinusIcon";

export default ({
  className,
  direction,
  onDelete,
  onAddAfter,
  onAddBefore,
}) => {
  const preventPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <span className={className} onMouseDown={preventPropagation}>
      <AddIcon onClick={onAddBefore} />
      {onDelete && <MinusIcon onClick={onDelete} />}
      <AddIcon onClick={onAddAfter} />
    </span>
  );
};
