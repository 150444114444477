import { memo } from "react";
import PropTypes from "prop-types";
import TaskEditor from "./TaskEditor";
import styles from "./Segment.module.scss";
import { getFirstBlock } from "../../editorUtils";

const Segment = (props) => {
  const {
    updateSegmentEditorRef,
    deliverableId,
    decorators,
    draftKey,
    isCommentable,
    isConfirmed,
    isEditable,
    onFocus,
    sourceEditorState,
    selectedCommentGroup,
    stageId,
    sourceText,
    taskFieldId,
    targetEditorState,
  } = props;

  const confirm = () => {
    const block = getFirstBlock(targetEditorState);

    if (block.text.length > 0) {
      props.confirmSegment(sourceText, block);
    }
  };

  const unconfirm = () => {
    const block = getFirstBlock(targetEditorState);
    props.unconfirmSegment(sourceText, block);
  };

  const updateEditorState = (editorState) => {
    props.updateEditorState(draftKey, editorState);
  };

  const renderConfirmButton = () => {
    if (isConfirmed) {
      // Unconfirm button
      return (
        <div className={styles.btnConfirmed} onClick={unconfirm}>
          <div className={styles.prompt}>Click to make changes</div>

          <svg height="16" viewBox="0 0 30 16" width="24">
            <path d="M13 1l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z" />
            <path d="M28 1l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z" />
          </svg>
        </div>
      );
    }

    // Confirm button
    return (
      <div className={styles.btnUnconfirmed} onClick={confirm}>
        <svg
          className={styles.exclamation}
          height="20"
          viewBox="170 100 200 300"
          width="30"
        >
          {/* eslint-disable max-len */}
          <path d="M256.09 125.9a21.3 21.3 0 0 0-21.33 21.34V275.2a21.32 21.32 0 1 0 42.66 0V147.25a21.3 21.3 0 0 0-21.33-21.33zM275.69 354.52a19.89 19.89 0 0 0-4.48-7.03c-1.06-.86-2.13-1.92-3.41-2.56a12.34 12.34 0 0 0-3.63-1.92 12.36 12.36 0 0 0-4.05-1.28c-6.8-1.5-14.29.87-19.17 5.76a19.91 19.91 0 0 0-4.48 7.03 21.24 21.24 0 0 0-1.7 8.1c0 2.78.63 5.55 1.7 8.11a24.62 24.62 0 0 0 4.48 7.04 24.66 24.66 0 0 0 7.04 4.48 21.24 21.24 0 0 0 8.1 1.7c2.77 0 5.52-.63 8.08-1.7a24.9 24.9 0 0 0 7.04-4.48 24.64 24.64 0 0 0 4.48-7.04 21.24 21.24 0 0 0 1.7-8.1c0-2.77-.63-5.55-1.7-8.1z" />
          {/* eslint-enable max-len */}
        </svg>
        <svg className={styles.tick} height="13" viewBox="0 0 16 13" width="16">
          <path d="M13 1l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z" />
        </svg>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.source}>
        <TaskEditor editorState={sourceEditorState} readOnly />
      </div>
      <div className={styles.target}>
        <TaskEditor
          closeCommentBox={props.closeCommentBox}
          confirmSegment={confirm}
          decorators={decorators}
          deliverableId={deliverableId}
          draftKey={draftKey}
          editorState={targetEditorState}
          focusNextUnconfirmedEditor={props.focusNextUnconfirmedEditor}
          isCommentable={isCommentable && isConfirmed}
          isConfirmed={isConfirmed}
          isTargetEditor
          onFocus={onFocus}
          openCommentBox={props.openCommentBox}
          readOnly={!isEditable}
          saveConfirmedSegments={props.saveConfirmedSegments}
          selectedCommentGroup={selectedCommentGroup}
          stageId={stageId}
          taskFieldId={taskFieldId}
          unconfirmSegment={unconfirm}
          updateDecorators={props.updateDecorators}
          updateEditorState={updateEditorState}
          updateSegmentEditorRef={updateSegmentEditorRef}
          updateSelectedCommentGroup={props.updateSelectedCommentGroup}
        />

        {isEditable && (
          <div className={styles.btnContainer}>{renderConfirmButton()}</div>
        )}
      </div>
    </div>
  );
};

Segment.propTypes = {
  closeCommentBox: PropTypes.func,
  confirmSegment: PropTypes.func.isRequired,
  decorators: PropTypes.array,
  deliverableId: PropTypes.number.isRequired,
  draftKey: PropTypes.string.isRequired,
  focusNextUnconfirmedEditor: PropTypes.func,
  isCommentable: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  isEditable: PropTypes.bool,
  onFocus: PropTypes.func,
  openCommentBox: PropTypes.func.isRequired,
  richButtonsPlugin: PropTypes.object,
  saveConfirmedSegments: PropTypes.func.isRequired,
  selectedCommentGroup: PropTypes.object,
  sourceEditorState: PropTypes.object,
  sourceText: PropTypes.string,
  stageId: PropTypes.number.isRequired,
  targetEditorState: PropTypes.object,
  taskFieldId: PropTypes.number.isRequired,
  unconfirmSegment: PropTypes.func.isRequired,
  updateDecorators: PropTypes.func,
  updateEditorState: PropTypes.func,
  updateSegmentEditorRef: PropTypes.func,
  updateSelectedCommentGroup: PropTypes.func,
};

export default memo(Segment);
