import React from "react";
import PropTypes from "prop-types";
import { RichUtils } from "draft-js";
import classNames from "classnames";
import styles from "./styles.module.scss";

const Button = ({
  blockType,
  disabled,
  editorState,
  inlineStyle,
  setEditorState,
  text,
}) => {
  const onClick = (e) => {
    e.preventDefault();

    if (!editorState || disabled) return;

    if (inlineStyle) {
      setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    } else if (blockType) {
      setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    }
  };

  const styleIsActive = () => {
    if (!editorState || disabled) return false;

    if (inlineStyle) {
      return editorState.getCurrentInlineStyle().has(inlineStyle);
    }

    if (blockType) {
      const selection = editorState.getSelection();
      const currentType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

      return currentType === blockType;
    }

    return false;
  };

  const btnStyles = classNames({
    [styles.toolbarBtn]: true,
    [styles.active]: styleIsActive(),
    [styles.disabled]: disabled,
  });

  return (
    <div className={btnStyles} onMouseDown={onClick}>
      {text}
    </div>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  inlineStyle: PropTypes.string,
  blockType: PropTypes.string,
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  text: PropTypes.string,
};

export default Button;
