import AdminLayout from "./containers/AdminLayout";
import { meActionHandlers, meInitialState } from "../../../modules/me";
import {
  peopleActionHandlers,
  peopleInitialState,
} from "../../../modules/people";
import {
  paymentActionHandlers,
  paymentInitialState,
} from "../../../modules/payments";

export default {
  Component: AdminLayout,
  reducers: [
    {
      key: "me",
      actionHandlers: meActionHandlers,
      initialState: meInitialState,
    },
    {
      key: "people",
      actionHandlers: peopleActionHandlers,
      initialState: peopleInitialState,
    },
    {
      key: "payments",
      actionHandlers: paymentActionHandlers,
      initialState: paymentInitialState,
    },
  ],
};
