import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./ImageEditor.module.scss";
import Loading from "../../../../../../Loading/v2";

const MAX_FILE_SIZE_MB = 20;
const MAX_FILE_SIZE = 1000 * 1000 * MAX_FILE_SIZE_MB;

export default class ImageEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      isLoading: false,
      savedContent: props.content || "",
    };
  }

  get taskState() {
    const { taskFieldId } = this.props;
    const content = this.state.savedContent;

    // we always save images immediately after uploading, so there will never be unsaved image task field content
    return { content, hasChanged: false, rawContent: "", taskFieldId };
  }

  get UUIDSegment() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  saveContent = async (content) => {
    const { deliverableId, personId, saveSingleTask, stageId, taskFieldId } =
      this.props;

    saveSingleTask({
      content,
      deliverableId,
      personId,
      rawContent: "",
      stageId,
      taskFieldId,
    });

    this.setState({ savedContent: content });
  };

  uploadHandler = (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    this.setState({ isLoading: true }, () => {
      const { gCloud, projectId, taskFieldId } = this.props;
      const errors = [];
      const file = files[0];

      if (file.size > MAX_FILE_SIZE) {
        return this.setState({
          errors: [
            `Attempted to upload a file greater than ${MAX_FILE_SIZE_MB}mb; please compress the image`,
          ],
          isLoading: false,
        });
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const JWT = gCloud ? gCloud.JWT : null;
        if (!JWT) return;

        let headers = new Headers();
        headers.append(
          "Content-Type",
          "application/x-www-form-urlencoded; charset=UTF-8"
        );

        try {
          /*  Perform a request to Google's oAuth server in order to retrieve an
            access token that can be used for follow-up requests to Google APIs.
            Reference: https://developers.google.com/identity/protocols/OAuth2ServiceAccount  */
          const { access_token: accessToken } = await (
            await fetch("https://www.googleapis.com/oauth2/v4/token", {
              method: "POST",
              headers,
              body: `grant_type=${encodeURIComponent(
                "urn:ietf:params:oauth:grant-type:jwt-bearer"
              )}&assertion=${JWT}`,
            })
          ).json();

          // Upload directory follows the following format: projectId/taskFieldId
          const uploadDirectory = `${projectId}/${taskFieldId}`;
          const fileName = `${this.UUIDSegment}-${this.UUIDSegment}-${this.UUIDSegment}-${this.UUIDSegment}`;
          /* eslint-disable max-len */
          const uploadURL = `https://www.googleapis.com/upload/storage/v1/b/${process.env.BUCKET_MEDIA}/o?uploadType=media&name=`;
          /* eslint-enable max-len */

          headers = new Headers();
          headers.append("Authorization", `Bearer ${accessToken}`);
          headers.append("Content-Type", file.type);

          const { error, mediaLink } = await (
            await fetch(`${uploadURL}${uploadDirectory}/${fileName}`, {
              method: "POST",
              headers,
              body: file,
            })
          ).json();

          this.setState({ isLoading: false }, async () => {
            if (error) {
              // Error code 403 occurs when a service account does not have delete access
              // to a bucket. In this case, this implies that a user is trying to upload
              // a file with a filename that already exists on the bucket, i.e. uploading
              // to the bucket would perform an override
              const errorMessage =
                error.code === 403
                  ? "A file with that name already exists"
                  : "An unknown error occurred";

              errors.push(errorMessage);
              this.setState({ errors });
            } else {
              await this.saveContent(mediaLink);
            }
          });
        } catch (err) {
          this.setState({
            errors: ["An unknown error occurred with the image upload service"],
            isLoading: false,
          });
        }
      };

      reader.readAsDataURL(file);
    });
  };

  removeImage = () => {
    this.saveContent("");
  };

  renderSavedImage = () => {
    const { savedContent } = this.state;

    return (
      <div className={styles.imageContainer}>
        <img alt="preview" className={styles.imagePreview} src={savedContent} />

        {this.props.isEditable && (
          <div className={styles.removeButton} onClick={this.removeImage}>
            <span>Remove image</span>
            {/* eslint-disable max-len */}
            <svg height="16" width="14">
              <path d="M2 16h10l1-11H1l1 11zM9 2h5v3l-1-1H1L0 5V2h5V0h4v2zM8 2V1H6v1h2z" />
            </svg>
            {/* eslint-enable max-len */}
          </div>
        )}
      </div>
    );
  };

  renderErrors = () => {
    const { errors } = this.state;

    if (errors.length === 0) return null;

    return (
      <div>
        {errors.map((e) => {
          return <div>{JSON.stringify(e)}</div>;
        })}
      </div>
    );
  };

  render() {
    const { isLoading, savedContent } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    if (savedContent) {
      return this.renderSavedImage();
    }

    if (!this.props.isEditable) {
      return <div className={styles.noImage}>(No image)</div>;
    }

    const maxFileSizeString = `${MAX_FILE_SIZE_MB}MB`;

    return (
      <div className={styles.imageSelector}>
        <div>
          <label className={styles.hiddenInput}>
            <input onChange={this.uploadHandler} type="file" />
          </label>

          {/* eslint-disable max-len */}
          <svg height="35" width="40">
            <path d="M33.75 12.5H35V30H5v-3.75l12.5-12.5 7.5 7.5 8.75-8.75zm2.5-10H3.75c-.339 0-.632.124-.879.371s-.371.54-.371.879v27.5c0 .339.124.632.371.879s.54.371.879.371h32.5c.339 0 .632-.124.879-.371s.371-.54.371-.879V3.75c0-.339-.124-.632-.371-.879a1.201 1.201 0 0 0-.879-.371zm0-2.5c1.042 0 1.927.365 2.656 1.094C39.636 1.823 40 2.708 40 3.75v27.5c0 1.042-.365 1.927-1.094 2.656-.729.73-1.614 1.094-2.656 1.094H3.75c-1.042 0-1.927-.365-2.656-1.094C.364 33.177 0 32.292 0 31.25V3.75c0-1.042.365-1.927 1.094-2.656C1.823.364 2.708 0 3.75 0h32.5z" />
          </svg>
          {/* eslint-disable max-len */}

          <div>Browse...</div>
          <div>Maxiumum File Size: {maxFileSizeString}</div>
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}

ImageEditor.propTypes = {
  content: PropTypes.string,
  deliverableId: PropTypes.number,
  gCloud: PropTypes.shape({
    JWT: PropTypes.string,
  }),
  isEditable: PropTypes.bool.isRequired,
  personId: PropTypes.number,
  projectId: PropTypes.number,
  saveSingleTask: PropTypes.func.isRequired,
  stageId: PropTypes.number,
  taskFieldId: PropTypes.number.isRequired,
};
