import InProduction from "./containers/InProduction";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../../../../../modules/accounts";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../../../../../modules/orderForms";
import {
  parentAccountActionHandlers,
  parentAccountInitialState,
} from "../../../../../../../modules/parentAccounts";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../../../../../modules/projects";
import {
  projectLanguageActionHandlers,
  projectLanguageInitialState,
} from "../../../../../../../modules/projectLanguages";
import {
  batchActionHandlers,
  batchInitialState,
} from "../../../../../../../modules/batches";
import {
  parentDeliverableActionHandlers,
  parentDeliverableInitialState,
} from "../../../../../../../modules/parentDeliverables";
import {
  deliverableActionHandlers,
  deliverableInitialState,
} from "../../../../../../../modules/deliverables";
import {
  peopleActionHandlers,
  peopleInitialState,
} from "../../../../../../../modules/people";
import {
  assignmentActionHandlers,
  assignmentInitialState,
} from "../../../../../../../modules/assignments";
import {
  assignmentGroupActionHandlers,
  assignmentGroupInitialState,
} from "../../../../../../../modules/assignmentGroups";
import {
  defaultAssigneeActionHandlers,
  defaultAssigneeInitialState,
} from "../../../../../../../modules/defaultAssignees";
import {
  rateActionHandlers,
  rateInitialState,
} from "../../../../../../../modules/rates";

export default {
  Component: InProduction,
  reducers: [
    {
      key: "parentAccounts",
      actionHandlers: parentAccountActionHandlers,
      initialState: parentAccountInitialState,
    },
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
    {
      key: "projectLanguages",
      actionHandlers: projectLanguageActionHandlers,
      initialState: projectLanguageInitialState,
    },
    {
      key: "batches",
      actionHandlers: batchActionHandlers,
      initialState: batchInitialState,
    },
    {
      key: "parentDeliverables",
      actionHandlers: parentDeliverableActionHandlers,
      initialState: parentDeliverableInitialState,
    },
    {
      key: "deliverables",
      actionHandlers: deliverableActionHandlers,
      initialState: deliverableInitialState,
    },
    {
      key: "people",
      actionHandlers: peopleActionHandlers,
      initialState: peopleInitialState,
    },
    {
      key: "assignments",
      actionHandlers: assignmentActionHandlers,
      initialState: assignmentInitialState,
    },
    {
      key: "assignmentGroups",
      actionHandlers: assignmentGroupActionHandlers,
      initialState: assignmentGroupInitialState,
    },
    {
      key: "rates",
      actionHandlers: rateActionHandlers,
      initialState: rateInitialState,
    },
    {
      key: "defaultAssignees",
      actionHandlers: defaultAssigneeActionHandlers,
      initialState: defaultAssigneeInitialState,
    },
  ],
};
