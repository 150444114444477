import React, { memo } from "react";
import Count from "./Count";
import punycode from "punycode/";
import PropTypes from "prop-types";
import styles from "./Counter.module.scss";

export function getCharCount(editorState) {
  const decodeUnicode = (str) => punycode.ucs2.decode(str); // func to handle unicode characters
  const plainText = editorState.getCurrentContent().getPlainText("");
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, "").trim(); // replace above characters w/ nothing
  return decodeUnicode(cleanString).length;
}

const Counter = ({
  editorState,
  wordCount,
  minWords,
  maxWords,
  targetCharCount,
  minCharacters,
  maxCharacters,
  qualityCheck,
}) => {
  const hasCharCount = minCharacters || maxCharacters;
  const charCount =
    hasCharCount && editorState ? getCharCount(editorState) : targetCharCount;

  return (
    <div className={styles.container}>
      {qualityCheck && (
        <div title="Keyword check on" className={styles.qualityCheck}>
          <svg width="16" height="16">
            <path d="M2.032 10l0.9-3h4.137l0.9 3h1.775l-3-10h-3.488l-3 10h1.776zM4.432 2h1.137l0.9 3h-2.937l0.9-3z" />
            <path d="M14 7.5l-5.5 5.5-2-2-1.5 1.5 3.5 3.5 7-7z" />
          </svg>
        </div>
      )}

      <div className={styles.counter}>
        <Count
          label={"Words"}
          count={wordCount}
          min={minWords}
          max={maxWords}
        />

        {hasCharCount && (
          <Count
            label={"Characters"}
            count={charCount}
            min={minCharacters}
            max={maxCharacters}
          />
        )}
      </div>
    </div>
  );
};

Counter.propTypes = {
  editorState: PropTypes.object,
  maxCharacters: PropTypes.number,
  maxWords: PropTypes.number,
  minCharacters: PropTypes.number,
  minWords: PropTypes.number,
  qualityCheck: PropTypes.bool,
  wordCount: PropTypes.number.isRequired,
};

export default memo(Counter);
