import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  projectLanguagesSelector,
  workflowsDropdownSelector,
  contentTypesDropdownSelector,
  orderFormsDropdownSelector,
  projectTeamsCandidateSelector,
  transformToDropdownFormat,
  projectTeamsSelector,
  currenciesDropdownSelector,
  orderFormById,
  accountById,
  parentAccountById,
} from "../../../../../../utils/entitySelector";

import ProjectsEdit from "../components/ProjectsEdit";
import WithData from "../../../../../../decorators/WithData";

import { getInitialData } from "../modules/getInitialData";
import { projectById, updateProject } from "../../../../../../modules/projects";
import { showModal, hideModal } from "../../../../../../modules/modal";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  updateProject: (data) =>
    dispatch(updateProject(data, ownProps.history, ownProps.match.params)),
  showModal: () => dispatch(showModal()),
  hideModal: () => dispatch(hideModal()),
});

const initialLanguagesSelector = createSelector(
  projectLanguagesSelector,
  (projectLanguages, entities) => {
    return projectLanguages.map(({ languageCode }) => languageCode);
  }
);

const initialProjectTeamsSelector = createSelector(
  projectTeamsSelector,
  (state, projectId) => state.people.entities,
  (state, projectId, personType) => personType,
  (projectTeams, entities, personTypes) => {
    return projectTeams
      .map(({ personId }) => personId)
      .filter(
        (personId) =>
          entities[personId] &&
          personTypes.includes(entities[personId].personType)
      );
  }
);

const languages = createSelector(
  (state, initialLanguages) => initialLanguages,
  (state) => state.languages.entities,
  (state) => state.languages.result,
  (projectLanguages, entities, result) => {
    const selected = projectLanguages.map((id) => ({
      id,
      value: id,
      label: entities[id].languageName,
      clearableValue: false,
    }));
    const remaining = [];
    result.forEach((id) => {
      if (projectLanguages.indexOf(id) === -1) {
        remaining.push({
          id,
          value: id,
          label: entities[id].languageName,
        });
      }
    });
    return selected.concat(remaining);
  }
);

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  const { editableInReview } = state.featureToggles;

  const initialLanguages = initialLanguagesSelector(state, projectId);
  const initialProductionTeam = initialProjectTeamsSelector(state, projectId, [
    "In-house",
    "Freelancer",
  ]);
  const initialClientTeam = initialProjectTeamsSelector(state, projectId, [
    "Client",
  ]);
  const project = projectById(state, projectId);

  const initialValues = {
    ...project,
    briefLink: project.briefLink || "",
    projectLanguages: initialLanguages,
    productionTeam: initialProductionTeam,
    clientTeam: initialClientTeam,
  };
  // As we destructure currentProject it contains sourceLanguage (as null) for non-localisation projects
  // this errors as we are sending null instead of a string to the backend
  if (!initialValues.sourceLanguage) delete initialValues.sourceLanguage;

  const initialError = ownProps.location.state && ownProps.location.state.error;

  const orderForm = orderFormById(state, project.orderFormId);
  const account = accountById(state, orderForm.accountId);
  const parentAccount = parentAccountById(state, account.parentAccountId);

  const productionTeam = transformToDropdownFormat(
    projectTeamsCandidateSelector(state),
    "personId",
    ["firstName", "lastName"]
  );
  const clientTeam = transformToDropdownFormat(
    projectTeamsCandidateSelector(state, "Client"),
    "personId",
    ["firstName", "lastName"]
  );

  const editableClientStagesValues = [
    { value: "NONE", label: "None" },
    { value: "POST_APPROVAL", label: "Editable post approval" },
  ];

  if (editableInReview) {
    editableClientStagesValues.push({
      value: "CLIENT_REVIEW",
      label: "Editable in review",
    });
  }

  return {
    account,
    orderForm,
    parentAccount,
    orderFormIds: orderFormsDropdownSelector(state),
    contentTypes: contentTypesDropdownSelector(state),
    workflows: workflowsDropdownSelector(state),
    languages: languages(state, initialLanguages),
    currencies: currenciesDropdownSelector(state),
    initialValues,
    productionTeam,
    clientTeam,
    modal: state.modal,
    initialError,
    editableClientStagesValues,
  };
};

const ProjectEditWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ProjectsEdit));
export default ProjectEditWithData;
