import { getGraphQL } from "../../../../../../../../../../utils/fetch";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../../../modules/projects";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../../modules/batches";
import {
  parentDeliverablesByBatchQuery,
  fetchParentDeliverablesSuccess,
} from "../../../../../../../../../modules/parentDeliverables";
import {
  deliverablesByBatchStageLangQuery,
  fetchDeliverablesSuccess,
} from "../../../../../../../../../modules/deliverables";
import {
  briefingFieldsByProjectIdQuery,
  fetchBriefingFieldsSuccess,
} from "../../../../../../../../../modules/briefingFields";
import {
  parentDeliverableBriefingFieldsByBatchQuery,
  fetchParentDeliverableBriefingFieldsSuccess,
} from "../../../../../../../../../modules/parentDeliverableBriefingFields";
import {
  latestNonClientContentQuery,
  fetchTaskFieldContentSuccess,
  taskFieldContentByDeliverableIdsQueryV2,
} from "../../../../../../../../../modules/taskFieldContent";
import {
  commentGroupsByBatchIdQuery,
  fetchCommentGroupsSuccess,
} from "../../../../../../../../../modules/commentGroups";
import {
  personNativeLanguagesQuery,
  fetchPersonNativeLanguagesSuccess,
} from "../../../../../../../../../modules/personNativeLanguages";

export const initialQuery = `query ClientBatchStage ($projectId: String, $batchId: String, $personId: String) {
  ${projectQuery}
  ${batchesByProjectIdQuery}
  ${parentDeliverablesByBatchQuery}
  ${briefingFieldsByProjectIdQuery}
  ${parentDeliverableBriefingFieldsByBatchQuery}
  ${commentGroupsByBatchIdQuery}
  ${personNativeLanguagesQuery}
}`;

export const deliverableQuery = `query deliverables ($batchId: String, $stageId: Int, $languageCode: String) {
  ${deliverablesByBatchStageLangQuery}
}`;

export const contentQuery = `query latestContent ($deliverableId: String) {
  ${latestNonClientContentQuery}
  ${taskFieldContentByDeliverableIdsQueryV2}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch, getState) => {
    const { editableClientStages } =
      getState().projects.entities[params.projectId] || {};
    const personId = getState().me;
    const json = await getGraphQL(
      initialQuery,
      { ...params, personId },
      url,
      cookies
    );
    dispatch(fetchProjectSuccess(json.project));
    dispatch(fetchBatchesSuccess(json.batches));
    dispatch(fetchParentDeliverablesSuccess(json.parentDeliverables));
    dispatch(fetchBriefingFieldsSuccess(json.briefingFields));
    dispatch(
      fetchParentDeliverableBriefingFieldsSuccess(
        json.parentDeliverableBriefingFields
      )
    );
    dispatch(fetchCommentGroupsSuccess(json.commentGroups));
    dispatch(fetchPersonNativeLanguagesSuccess(json.personNativeLanguages));
    if (params.languageCode) {
      const { deliverables } = await getGraphQL(
        deliverableQuery,
        params,
        url,
        cookies
      );
      dispatch(fetchDeliverablesSuccess(deliverables));
      if (deliverables && editableClientStages === "CLIENT_REVIEW") {
        const deliverableId = Object.values(deliverables).map(
          ({ deliverableId }) => deliverableId
        );
        const { taskFieldContentV2, latestNonClientContent } = await getGraphQL(
          contentQuery,
          {
            deliverableId,
          },
          url,
          cookies
        );
        dispatch(fetchTaskFieldContentSuccess(taskFieldContentV2));
        dispatch(fetchTaskFieldContentSuccess(latestNonClientContent));
      }
    }
  };
}
