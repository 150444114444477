import React from "react";
import ClientPageHeader from "../../ClientPageHeader";
import DeliverableLinks from "../../../Admin/Projects/Id/ParentDeliverables/ParentDeliverablesEdit/components/DeliverableLinks";

const PD = (props) => {
  const {
    parentDeliverable: { batchId, deliverableLinks },
    match: {
      params: { projectId },
    },
  } = props;

  return (
    <div>
      <ClientPageHeader projectId={projectId} title={"Deliverables"} />

      <DeliverableLinks
        batchId={batchId}
        projectId={projectId}
        deliverableLinks={deliverableLinks}
      />
    </div>
  );
};

export default PD;
