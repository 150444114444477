import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import BannedWordsForm from "./BannedWordsForm";
import PageHeader from "../../../../../../components/PageHeader";

const BannedWords = (props) => {
  const {
    project: { projectId, projectName },
  } = props;

  return (
    <div>
      <Helmet>
        {" "}
        <title>Banned Words</title>{" "}
      </Helmet>
      <div>
        <PageHeader
          title={`${projectName} - Banned words`}
          breadCrumbItems={[
            { url: "/admin", text: "Parent accounts" },
            {
              text: `${props.parentAccount.parentAccountName} | ${props.account.accountName} | ${props.orderForm.orderFormNumber}`,
            },
            { url: `/admin/projects/${projectId}`, text: `${projectName}` },
            { text: "Banned words" },
          ]}
        />
        <BannedWordsForm
          enableReinitialize
          initialValues={props.initialValues}
          onSubmit={props.updateBannedWords}
          projectLanguages={props.projectLanguages}
        />
      </div>
    </div>
  );
};

BannedWords.propTypes = {
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string.isRequired,
  }),
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
  }),
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number.isRequired,
  }),
  initialValues: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  projectLanguages: PropTypes.array.isRequired,
  updateBannedWords: PropTypes.func.isRequired,
};

export default BannedWords;
