import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./AssignmentGroupRow.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Icon from "../../../../../../../../../../components/Icon";
import {
  convertDateToLocal,
  formatDateObject,
  formatDateString,
} from "../../../../../../../../../../utils/date";
import AdminRequestModal from "./AdminRequestModal";
import AutomatedReminders from "./AutomatedReminders";
import FeatureToggle from "../../../../../../../../../../components/FeatureToggle";

const acceptedIcon = <img alt="checkmark" src={"/icons/checkmark.svg"} />;
const rejectedIcon = <img alt="cross" src={"/icons/cross.svg"} />;
const backspaceIcon = <img alt="backspace" src={"/icons/backspace.svg"} />;

function Adjustment(props) {
  const {
    hasRequestPending,
    selectAssignment,
    assignmentGroupId,
    assigneeName,
    projectId,
    stageName,
    deadline,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const openRequest = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  if (hasRequestPending) {
    return (
      <div className={styles.requestContainer} onClick={openRequest}>
        {isOpen && (
          <AdminRequestModal
            setIsOpen={setIsOpen}
            assignmentGroupId={assignmentGroupId}
            assigneeName={assigneeName}
            projectId={projectId}
            stageName={stageName}
            oldDeadline={deadline}
          />
        )}
        <Icon name="EditCalendar" size="lg" />
      </div>
    );
  }

  return (
    <div className={styles.reassign}>
      <div className={styles.reassignIcon} onClick={selectAssignment}>
        <Icon name="StepOver" size="lg" />
      </div>
    </div>
  );
}

const AssignmentGroupRow = (props) => {
  const localTime = convertDateToLocal(formatDateString(props.deadline));

  const deadlineStyles =
    localTime > new Date() ? styles.deadline : styles.overdueDeadline;
  const inTrainingIcon = props.inTraining ? (
    <Icon name="Training" size="lg" />
  ) : null;

  let icon = null;
  if (props.rejected) icon = rejectedIcon;
  if (props.accepted) icon = acceptedIcon;

  const selectAssignment = (e) => {
    e.preventDefault();
    props.setModalState(true, props);
  };

  const selectReminder = (assignmentGroupId) => {
    const type = "EmailLogs";
    props.setReminderState(type, assignmentGroupId);
  };

  const {
    amendsRequested,
    assignmentGroupId,
    assigneeFirstName,
    assigneeLastName,
    assignmentCount,
    baseUrl,
    batchName,
    hasRequestPending,
    completedAssignmentCount,
    languageCode,
    open,
    toggleFilter,
    projectId,
    email,
    emailTemplate,
    createDate,
    selectedReminder,
    createManualReminder,
    automatedReminderActive,
    handleGroupChange,
    marked,
    disabled,
    stageName,
    deadline,
  } = props;

  const assignmentGroupUrl = `${baseUrl}/assignments/${assignmentGroupId}`;

  const assignmentGroupRow = classNames({
    [styles.assignmentGroupRow]: true,
    [styles.closed]: !open,
    [styles.automatedReminders]: automatedReminderActive,
  });

  return (
    <Link className={assignmentGroupRow} to={assignmentGroupUrl}>
      <FeatureToggle toggle="selected">
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id={assignmentGroupId}
            checked={marked}
            disabled={disabled}
            onChange={() => handleGroupChange(assignmentGroupId)}
            onClick={(e) => e.stopPropagation()}
          />
          <label
            htmlFor={assignmentGroupId}
            className={styles.label}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </FeatureToggle>
      <div className={styles.assignee} onClick={toggleFilter}>
        {assigneeFirstName} {assigneeLastName}
      </div>
      <div className={styles.status}>{icon}</div>
      <div className={styles.count}>
        {completedAssignmentCount}/{assignmentCount}
      </div>
      <div className={styles.batch}>{batchName}</div>
      <div className={deadlineStyles}>
        {formatDateObject(localTime).substring(0, 16)}
      </div>
      <div className={styles.inTraining}>{inTrainingIcon}</div>
      <div className={styles.language}>{languageCode}</div>
      <div className={styles.amendsRequested}>
        {amendsRequested && backspaceIcon}
      </div>
      <Adjustment
        hasRequestPending={hasRequestPending}
        selectAssignment={selectAssignment}
        assignmentGroupId={assignmentGroupId}
        assigneeName={`${assigneeFirstName} ${assigneeLastName}`}
        projectId={projectId}
        stageName={stageName}
        deadline={deadline}
      />

      <FeatureToggle toggle="automatedReminders">
        <AutomatedReminders
          selectedReminder={selectedReminder}
          selectReminder={selectReminder}
          assignmentGroupId={assignmentGroupId}
          reminderType={emailTemplate}
          createDate={createDate}
          email={email}
          projectId={projectId}
          createManualReminder={createManualReminder}
        />
      </FeatureToggle>
    </Link>
  );
};

AssignmentGroupRow.propTypes = {
  accepted: PropTypes.bool.isRequired,
  amendsRequested: PropTypes.bool.isRequired,
  assigneeFirstName: PropTypes.string.isRequired,
  assigneeLastName: PropTypes.string.isRequired,
  assignmentCount: PropTypes.number.isRequired,
  assignmentGroupId: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
  batchName: PropTypes.string.isRequired,
  completedAssignmentCount: PropTypes.number.isRequired,
  deadline: PropTypes.string.isRequired,
  inTraining: PropTypes.bool.isRequired,
  languageCode: PropTypes.string.isRequired,
  minRate: PropTypes.string,
  open: PropTypes.bool.isRequired,
  rejected: PropTypes.bool.isRequired,
  setModalState: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func,
  totalRate: PropTypes.number,
};

export default AssignmentGroupRow;
