import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const fields = ["personId", "nativeLanguage"];

export const personNativeLanguagesQuery = `personNativeLanguages (personId: $personId) {
  ${fields}
}`;

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_PERSON_NATIVE_LANGUAGES_SUCCESS =
  "FETCH_PERSON_NATIVE_LANGUAGES_SUCCESS";
export const REPLACE_PERSON_NATIVE_LANGUAGES_SUCCESS =
  "REPLACE_PERSON_NATIVE_LANGUAGES_SUCCESS";
export const REMOVE_PERSON_NATIVE_LANGUAGES_SUCCESS =
  "REMOVE_PERSON_NATIVE_LANGUAGES_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPersonNativeLanguagesSuccess = createAction(
  FETCH_PERSON_NATIVE_LANGUAGES_SUCCESS
);
export const replacePersonNativeLanguagesSuccess = createAction(
  REPLACE_PERSON_NATIVE_LANGUAGES_SUCCESS
);
export const removePersonNativeLanguagesSuccess = createAction(
  REMOVE_PERSON_NATIVE_LANGUAGES_SUCCESS
);

// ------------------------------------
// Action Handlers
// ------------------------------------

export const personNativeLanguageActionHandlers = {
  [RESET_INITIAL_STATE]: () => personNativeLanguageInitialState,
  [FETCH_PERSON_NATIVE_LANGUAGES_SUCCESS]: (state, { payload }) =>
    payload.reduce((acc, val) => {
      if (
        acc.some(
          (a) =>
            a.personId === val.personId &&
            a.nativeLanguage === val.nativeLanguage
        )
      ) {
        return acc;
      }
      return acc.concat(val);
    }, state),
  [REPLACE_PERSON_NATIVE_LANGUAGES_SUCCESS]: (state, { payload }) => {
    const { personId, personNativeLanguages } = payload;

    const newState = [];
    state.map((personNativeLanguage) => {
      if (personNativeLanguage.personId !== personId) {
        newState.push(personNativeLanguage);
      }
      return personNativeLanguage;
    });

    return newState.concat(personNativeLanguages);
  },
  [REMOVE_PERSON_NATIVE_LANGUAGES_SUCCESS]: (state, { payload }) => {
    const personId = payload;

    const newState = state.reduce((acc, val) => {
      return acc.concat(val.personId !== personId ? val : []);
    }, []);

    return newState;
  },
};

// ------------------------------------
// Initial State
// ------------------------------------

export const personNativeLanguageInitialState = [];
