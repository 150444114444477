import React from "react";
import PropTypes from "prop-types";
import styles from "./LanguageSelector.module.scss";
import Dropdown from "../../Dropdown";

const LanguageSelector = (props) => {
  return (
    <Dropdown title={props.title} containerStyles={props.containerStyles}>
      <div
        className={styles.dropdownItem}
        key={"all"}
        onClick={() => props.selectLanguage(props.projectLanguages, "all")}
      >
        {"All"}
      </div>
      {props.projectLanguages.map((language) => (
        <div
          className={styles.dropdownItem}
          key={language.languageCode}
          onClick={() => props.selectLanguage(language.languageCode)}
        >
          {props.languageNames[language.languageCode]}
        </div>
      ))}
    </Dropdown>
  );
};

LanguageSelector.propTypes = {
  languageNames: PropTypes.object,
  projectLanguages: PropTypes.array,
  selectLanguage: PropTypes.func,
  title: PropTypes.string.isRequired,
  containerStyles: PropTypes.string,
};

export default LanguageSelector;
