import { createAction } from "redux-actions";
import { upsertData, removeData } from "../utils/normalize";
import { postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
const attachmentFields = `
    attachmentId, attachmentUrl, bucket, parentDeliverableId, projectId, archived, fileName
`;

export const attachmentsByParentDeliverableId = `attachments (parentDeliverableId: $parentDeliverableId) {
  ${attachmentFields}
}`;

const CREATE_ATTACHMENTS_SUCCESS = "CREATE_ATTACHMENT_SUCCESS";
const FETCH_ATTACHMENTS_SUCCESS = "FETCH_ATTACHMENTS_SUCCESS";
const ARCHIVE_ATTACHMENT_SUCCESS = "ARCHIVE_ATTACHMENT_SUCCESS";

export const archiveAttachmentSuccess = createAction(
  ARCHIVE_ATTACHMENT_SUCCESS
);
export const fetchAttachmentsSuccess = createAction(FETCH_ATTACHMENTS_SUCCESS);
export const createAttachmentSuccess = createAction(CREATE_ATTACHMENTS_SUCCESS);

export function createAttachments(body, projectId, parentDeliverableId) {
  const { name, type } = body;
  return async (dispatch) => {
    const generateUploadQuery = `mutation createUploadUrl ($fileName: String, $projectId: Int) {
      createUploadUrl (fileName: $fileName, projectId: $projectId) {
        uploadUrl, fileName, projectId, bucketName
      }
    }`;
    const createAttachmentQuery = `mutation createAttachments ($attachments: [AttachmentInput]) {
      createAttachments (attachments: $attachments) {
          attachmentId, attachmentUrl, bucket, parentDeliverableId, projectId, archived, fileName
      }
    }`;

    const { uploadUrl, bucketName, fileName } = await postGraphQL(
      generateUploadQuery,
      { fileName: name, projectId, contentType: type },
      "createUploadUrl"
    );
    const { url } = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": `${type}`,
      },
      body,
    });

    let attachments = []; // handles array input for multiple attachments
    attachments.push({
      attachmentUrl: url.split("?")[0],
      bucket: bucketName,
      projectId,
      fileName,
      parentDeliverableId,
    });

    if (attachments.length >= 1) {
      const [json] = await postGraphQL(
        createAttachmentQuery,
        { attachments },
        "createAttachments"
      );
      dispatch(createAttachmentSuccess(json));
    }
  };
}

export function archiveAttachment(attachmentId) {
  return async (dispatch) => {
    const query = `mutation archiveAttachment($input: AttachmentInput) {
      archiveAttachment(input: $input) {
        attachmentId
      }
    }`;

    await postGraphQL(query, { input: { attachmentId } }, "archiveAttachment");
    dispatch(archiveAttachmentSuccess(attachmentId));
  };
}

export const attachmentActionHandlers = {
  [RESET_INITIAL_STATE]: () => attachmentInitialState,
  [ARCHIVE_ATTACHMENT_SUCCESS]: (state, { payload }) =>
    removeData(state, payload, "attachmentId"),
  [FETCH_ATTACHMENTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "attachmentId"),
  [CREATE_ATTACHMENTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "attachmentId"),
};

export const attachmentInitialState = { entities: {}, result: [] };
