import { getGraphQL } from "../../utils/fetch";

export const userDictionaryQuery = `userDictionary (textString: $textString, projectId: $projectId) {
  projectId
}`;

export function addToDictionary(textString, { projectId }) {
  return async (dispatch) => {
    const query = `query UserDictionary ($textString: String, $projectId: Int) {
      ${userDictionaryQuery}
    }`;

    try {
      await getGraphQL(query, { textString, projectId });
    } catch (err: any) {}
  };
}
