import React from "react";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../components/PageHeader";
import { connect } from "react-redux";
import WithData from "../../../../../../decorators/WithData";
import {
  projectById,
  projectDefaultAssigneesSelector,
  rateBandsByProjectIdSelector,
  accountById,
  orderFormById,
  parentAccountById,
} from "../../../../../../utils/entitySelector";
import { getInitialData } from "./getInitialData";
import QuotesForm from "../QuotesForm/v2";
import {
  updateQuotedRates,
  quotedRatesByProjectId,
} from "../../../../../../modules/quotedRates";

function Quotes(props) {
  const {
    project: { projectId, projectName },
    rateBands,
    initialValues,
    quotedRateAssignees,
    updateQuotedRates,
    parentAccount: { parentAccountName },
    account: { accountName },
    orderForm: { orderFormNumber },
  } = props;

  const baseUrl = `/admin/projects/${projectId}`;

  function onSubmit({ quotes }) {
    const associations = Object.entries(quotes).reduce(
      (acc, [languageCode, stages]) => {
        Object.entries(stages).forEach(([stageId, people]) => {
          Object.entries(people).forEach(([personId, rateBands]) => {
            Object.entries(rateBands).forEach(([rateBandId, rate]) => {
              acc.push({
                personId: Number(personId),
                rateBandId: Number(rateBandId),
                languageCode,
                stageId: Number(stageId),
                projectId,
                rate,
              });
            });
          });
        });

        return acc;
      },
      []
    );

    updateQuotedRates(associations);
  }

  return (
    <div>
      <Helmet>
        <title>Quotes</title>
      </Helmet>

      <PageHeader
        breadCrumbItems={[
          { url: `/admin`, text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { url: `${baseUrl}`, text: projectName },
          { text: "Quotes" },
        ]}
        title={`${projectName} - Quotes`}
      />

      <QuotesForm
        rateBands={rateBands}
        quotedRateAssignees={quotedRateAssignees}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  updateQuotedRates: (associations) =>
    dispatch(updateQuotedRates(associations)),
});

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);
  const project = projectById(state, projectId);
  const rateBands = rateBandsByProjectIdSelector(state, projectId);
  const defaultAssignees = projectDefaultAssigneesSelector(state, projectId);
  const orderForm = orderFormById(state, project.orderFormId);
  const account = accountById(state, orderForm.accountId);
  const parentAccount = parentAccountById(state, account.parentAccountId);
  const people = state.people.entities;

  const quotedRateAssigneeMap = defaultAssignees.reduce((acc, da) => {
    const { stageId, languageCode } = da;

    if (!acc[languageCode]) acc[languageCode] = {};
    if (!acc[languageCode][stageId]) acc[languageCode][stageId] = [];

    acc[languageCode][stageId].push(da);

    return acc;
  }, {});

  const quotedRateAssignees = Object.entries(quotedRateAssigneeMap).map(
    ([languageCode, rest]) => {
      const stages = Object.entries(rest).map(([stageId, rest2]) => {
        const { stageName } = state.stages.entities[stageId];
        const assignees = rest2
          .reduce((acc, da) => {
            const { personId } = da;

            if (!people[personId]) {
              return acc;
            }

            acc.push({
              ...da,
              ...people[da.personId],
            });

            return acc;
          }, [])
          .sort((a, b) => a.firstName.localeCompare(b.firstName));

        return { stageId, stageName, assignees };
      });

      const { languageName } = state.languages.entities[languageCode];
      return { languageName, languageCode, stages };
    }
  );

  const initialValues = {
    quotes: quotedRatesByProjectId(state, projectId),
  };

  return {
    initialValues,
    project,
    rateBands,
    orderForm,
    quotedRateAssignees,
    account,
    parentAccount,
  };
};

const ConnectedQuotes = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Quotes));
ConnectedQuotes.getInitialData = getInitialData;

export default {
  Component: ConnectedQuotes,
};
