import React from "react";
import PropTypes from "prop-types";
import styles from "./NestedAccountRow.module.scss";
import NestedRow from "./NestedRow";
import NestedAdminRow from "./NestedAdminRow";
import Collapsible from "../../decorators/Collapsible";
import NestedOrderFormRow from "./NestedOrderFormRow";

const NestedAccountRow = (props) => {
  const Row = props.admin ? NestedAdminRow : NestedRow;
  const accountRowContainerStyles = props.admin
    ? styles.adminAccountRowContainer
    : styles.clientAccountRowContainer;
  const accountRowStyles = props.admin ? styles.adminAccountRow : "";
  return (
    <div className={accountRowContainerStyles}>
      <Row
        hide={props.hide}
        restore={props.restore}
        restoreEnabled={props.restoreEnabled}
        favouritesEnabled={props.favouritesEnabled}
        archivedComponent={props.archivedComponent}
        handleRowClick={props.toggle}
        handleCreateOrEdit={props.handleCreateOrEdit}
        handleDelete={props.handleDelete}
        name={props.account.accountName}
        modalId={props.account.accountId}
        accountId={props.account.accountId}
        modalType="account"
        modalTitle="account"
        childTitle="order form"
        childType="orderForm"
        customStyles={accountRowStyles}
        isFavourite={props.isFavourite}
        favourite={props.favourite}
        unfavourite={props.unfavourite}
        favouriteComponent={props.favouriteComponent}
      />

      {props.open &&
        (props.orderForms[props.account.accountId] || []).map((orderForm) => (
          <NestedOrderFormRow
            hide={props.hide}
            restore={props.restore}
            restoreEnabled={
              props.orderFormIds &&
              props.orderFormIds.includes(orderForm.orderFormId)
            }
            favouritesEnabled={
              props.orderFormIds &&
              props.orderFormIds.includes(orderForm.orderFormId)
            }
            projectIds={props.projectIds}
            archivedComponent={props.archivedComponent}
            key={orderForm.orderFormId}
            orderForm={orderForm}
            projects={props.projects}
            admin={props.admin}
            handleCreateOrEdit={props.handleCreateOrEdit}
            handleDelete={props.handleDelete}
            isFavourite={orderForm.isFavourite}
            favourite={props.favourite}
            unfavourite={props.unfavourite}
            favouriteComponent={props.favouriteComponent}
          />
        ))}
    </div>
  );
};

NestedAccountRow.propTypes = {
  admin: PropTypes.bool,
  account: PropTypes.shape({
    accountName: PropTypes.string,
    accountId: PropTypes.number,
  }),
  orderForms: PropTypes.object,
  projects: PropTypes.object,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleCreateOrEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default Collapsible(NestedAccountRow);
