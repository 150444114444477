import { connect } from "react-redux";

import { loginFetch } from "../../../../modules/me";
import Login from "../components/Login";
import queryString from "query-string";

export const mapDispatchToProps = (dispatch, ownProps) => ({
  login: (data) => dispatch(loginFetch(data)),
});

export const mapStateToProps = (state, { location: { search } }) => {
  const { error } = queryString.parse(search);
  return {
    me: state.people.entities[state.me],
    auth0Login: state.featureToggles.auth0Login,
    verifyEmailMessage: error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
