import PropTypes from "prop-types";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Errors from "../../../../../components/Errors";
import PageHeader from "../../../../../components/PageHeader";
import { PAYMENT_TYPE } from "../../../../../modules/payments";
import PaymentsForm from "./PaymentsForm";
import styles from "./PaymentsSearch.module.scss";

const listUrl = "/admin/payments";

class PaymentsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { redirect: true };
  }

  submitAndRedirect = () => {
    this.setState({ redirect: true }, () => this.paymentsForm.submit());
  };

  /**
   * Redirects to the paymentList view with the search parameters in the
   * query string
   */
  searchPayments = (data) => {
    let queryString = "";

    // Flatten month params (startDate and endDate)
    const params = { ...data, ...data.month };
    delete params.month;

    if (params.paymentType === PAYMENT_TYPE.WORKFLOW) delete params.paymentTask;
    if (params.paymentType === PAYMENT_TYPE.AD_HOC) delete params.stageName;

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        if (queryString.length > 0) queryString += "&";
        queryString += `${key}=${params[key]}`;
      }
    });

    this.props.history.push(`${listUrl}?${queryString}`);
  };

  cancelSearch = () => {
    this.props.clearPaymentsForm();
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Payment search</title>
        </Helmet>

        {this.props.errors.display && <Errors />}

        <div>
          <PageHeader
            title={"Search payments by content creator"}
            breadCrumbItems={[
              { text: "Payments" },
              { text: "Search payments by content creator" },
            ]}
          >
            <div className={styles.headerRight}>
              <Link
                to={`/admin/payments?paymentType=${PAYMENT_TYPE.AD_HOC_DRAFT}&createdByPersonId=${this.props.me}`}
                className={styles.newPayment}
              >
                View my draft ad hocs
              </Link>
              <Link to={"/admin/payments/new"} className={styles.newPayment}>
                New payment
              </Link>
            </div>
          </PageHeader>

          <PaymentsForm
            ref={(r) => {
              this.paymentsForm = r;
            }}
            freelancers={this.props.freelancers}
            accounts={this.props.accounts}
            orderForms={this.props.orderForms}
            projects={this.props.projects}
            tasks={this.props.tasks}
            searchFreelancers={this.props.searchFreelancers}
            fetchOrderFormsByAccountId={this.props.fetchOrderFormsByAccountId}
            fetchProjectsByOrderFormId={this.props.fetchProjectsByOrderFormId}
            stages={this.props.stages}
            onSubmit={this.searchPayments}
          />
          <div className={styles.buttonsContainer}>
            <button onClick={this.cancelSearch} className={styles.cancelButton}>
              Cancel
            </button>
            <button
              onClick={this.submitAndRedirect}
              className={styles.searchButton}
              disabled={this.paymentsForm && !this.paymentsForm.valid}
            >
              View payments
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PaymentsSearch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  errors: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }),
  freelancers: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  orderForms: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  searchFreelancers: PropTypes.func.isRequired,
  fetchOrderFormsByAccountId: PropTypes.func.isRequired,
  fetchProjectsByOrderFormId: PropTypes.func.isRequired,
  clearPaymentsForm: PropTypes.func.isRequired,
};

export default PaymentsSearch;
