import {
  versionHistoryQuery,
  fetchVersionHistoryDetailsSuccess,
} from "../../../../../../modules/versionHistory";

import { getGraphQL } from "../../../../../../../utils/fetch";

export const initialQuery = `query versionHistoryQueryByDeliverableId ($deliverableId: Int, $stageId: Int) {
  ${versionHistoryQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);
    dispatch(fetchVersionHistoryDetailsSuccess(json.versionHistory));
  };
}
