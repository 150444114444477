import PropTypes from "prop-types";
import Parser from "html-react-parser";
import styles from "./SourceField.module.scss";
import WordCount from "../WordCount";
import CharacterCount from "../CharacterCount";
import { getWordCount, getCharacterCount } from "../../../../../utils/counts";
import classNames from "classnames";
import { isTaskFormatHTML } from "../../../../../modules/taskFields";
import { matchHTMLWords } from "../../../../../utils/matchHTMLWords";

/**
 * Read-only source content
 */
function SourceField({ format, sourceContent, glossarySourceWords }) {
  const isHTMLFormat = isTaskFormatHTML(format);

  const fieldStyles = classNames({
    [styles.sourceField]: true,
    [styles.plaintext]: !isHTMLFormat,
  });

  return (
    <div className={fieldStyles}>
      {isHTMLFormat && <div className={styles.toolbar} />}

      <div className={styles.sourceFieldDraft}>
        {sourceContent &&
          Parser(matchHTMLWords(sourceContent, glossarySourceWords, styles))}
      </div>
      <div className={styles.counter}>
        <CharacterCount
          count={sourceContent ? getCharacterCount(sourceContent, format) : 0}
        />
        <WordCount
          count={sourceContent ? getWordCount(sourceContent, format) : 0}
        />
      </div>
    </div>
  );
}

SourceField.propTypes = {
  format: PropTypes.string.isRequired,
  sourceContent: PropTypes.string,
};

export default SourceField;
