import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import styles from "./Login.module.scss";
import LoginForm from "./LoginForm";
import Icon from "../../../../components/Icon";

export const VerifyEmail = () => {
  return (
    <div className={styles.alertMessage}>
      <div>
        <Icon name="Info" size="lg" fill="#0069DF" />
      </div>
      <div>
        A verification link has been sent to your email account. Please click on
        the link that has just been sent to your email account to verify your
        email and continue.
      </div>
    </div>
  );
};

const Login = ({ auth0Login, login, verifyEmailMessage }) => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className={styles.leftColumn}>
        <div className={styles.logoName}>
          <img alt="Scribe logo" src="/icons/nav/sidebar-scribe-logo.svg" />
          <span>Scribe</span>
        </div>
        <div className={styles.welcomeText}>Welcome</div>
        {verifyEmailMessage && <VerifyEmail message={verifyEmailMessage} />}
        <LoginForm onSubmit={login} auth0Login={auth0Login} />
      </div>
      <div className={styles.rightColumn}></div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Login;
