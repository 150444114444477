import { getGraphQL } from "../../../../../utils/fetch";
import {
  bannedWordsByProjectIdQuery,
  fetchBannedWordsSuccess,
} from "../../../../modules/bannedWords";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../modules/projectLanguages";
import {
  orderFormsQuery,
  fetchOrderFormsSuccess,
} from "../../../../modules/orderForms";
import {
  accountsQuery,
  fetchAccountsSuccess,
} from "../../../../modules/accounts";

export const initialQuery = `query ClientProjectInfo ($projectId: String) {
  ${accountsQuery}
  ${orderFormsQuery}
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
  ${bannedWordsByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchBannedWordsSuccess(json.bannedWords));
      dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
    });
  };
}
