import { connect } from "react-redux";
import { updateFetch } from "../modules/update";
import { resetFetch } from "../../_modules/reset";

import Update from "../components/Update";

export const mapDispatchToProps = (dispatch, ownProps) => ({
  updateFetch: (data) => dispatch(updateFetch(data, ownProps.location.search)),
  resetFetch: (data) => dispatch(resetFetch(data)),
});

export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Update);
