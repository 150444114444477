import React, { Component } from "react";
import ArrowRenderer from "./ArrowRenderer";
import ValueComponent from "./ValueComponent";

import Select, { Creatable } from "react-select";

// Don't import css files here because they are reimported every time they are used
// instead import them in styles/core.module.scss
// import 'react-select/dist/react-select.css'
// import './ReduxFormCompatible.css'

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : "");
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map((value) => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi, creatable) {
  if (multi && typeof value === "string") return value;

  // for creatable we just return the entered values
  // (no need to filter because their value is created)
  if (creatable) {
    return multi
      ? value.map((v) => ({ value: v, label: v }))
      : { value, label: value };
  }

  // for non created values we must filter for the results
  const filteredOptions = options.filter((option) => {
    return multi ? value.indexOf(option.value) !== -1 : option.value === value;
  });

  return multi ? filteredOptions : filteredOptions[0];
}

function reduxFormCompatible(WrappedComponent, creatable = false) {
  return class ReduxFormCompatible extends Component {
    render() {
      /* eslint-disable react/prop-types */
      const transformedValue = transformValue(
        this.props.input.value,
        this.props.options,
        this.props.multi,
        creatable
      );
      const onChange = this.props.multi
        ? multiChangeHandler(this.props.input.onChange)
        : singleChangeHandler(this.props.input.onChange);
      const onBlur = () => this.props.input.onBlur(this.props.input.value);
      const onFocus = () => this.props.input.onFocus(this.props.input.value);

      const className = this.props.styles || "";
      const arrowRenderer = this.props.customArrow || ArrowRenderer;

      const updatedOptions = this.props.hasAiContentModel
        ? this.props.options.filter(({ value }) => value !== "AI content model")
        : this.props.options;

      const customValueComponent = this.props.multi
        ? (props) => (
            <ValueComponent
              rebrandingContainer={this.props.rebrandingContainer}
              {...props}
            />
          )
        : undefined;
      /* eslint-enable react/prop-types */
      return (
        <WrappedComponent
          {...this.props}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          value={transformedValue}
          className={className}
          arrowRenderer={arrowRenderer}
          valueComponent={customValueComponent}
          options={updatedOptions}
        />
      );
    }
  };
}

export const ReduxFormCompatibleCreatable = reduxFormCompatible(
  Creatable,
  true
);
export const ReduxFormCompatibleSelect = reduxFormCompatible(Select);
export default reduxFormCompatible;
