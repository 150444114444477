import {
  checkValidations,
  isNotBlank,
  isNotBlankArray,
  validateEmail,
} from "../../utils/validations";

const validate = (values) => {
  const errors = {};

  const firstNameErrors = checkValidations(values.firstName, [
    { validate: isNotBlank, msg: "Please enter a first name for this person." },
  ]);
  const lastNameErrors = checkValidations(values.lastName, [
    { validate: isNotBlank, msg: "Please enter a last name for this person." },
  ]);
  const emailErrors = checkValidations(values.email, [
    {
      validate: validateEmail,
      msg: "Please enter a valid email address for this person.",
    },
  ]);
  const nativeLanguageErrors = checkValidations(values.nativeLanguage, [
    {
      validate: isNotBlankArray,
      msg: "Please enter a native language for this person.",
    },
  ]);
  const personTypeErrors = checkValidations(values.personType, [
    { validate: isNotBlank, msg: "Please enter a person type." },
  ]);

  let accountErrors;
  if (values.personType === "Client") {
    accountErrors = checkValidations(values.account, [
      { validate: isNotBlankArray, msg: "Required" },
    ]);
  }

  let paymentMethodErrors;
  if (
    values.personType === "Freelancer" ||
    values.personType === "Senior freelancer"
  ) {
    paymentMethodErrors = checkValidations(values.paymentMethod, [
      { validate: isNotBlank, msg: "Required" },
    ]);
  }

  if (firstNameErrors) errors.firstName = firstNameErrors;
  if (lastNameErrors) errors.lastName = lastNameErrors;
  if (emailErrors) errors.email = emailErrors;
  if (nativeLanguageErrors) errors.nativeLanguage = nativeLanguageErrors;
  if (personTypeErrors) errors.personType = personTypeErrors;

  if (accountErrors) errors.account = accountErrors;

  if (paymentMethodErrors) errors.paymentMethod = paymentMethodErrors;

  if (values.vatRegistered) {
    if (!values.vatNumber) errors.vatNumber = "Required";
  }

  return errors;
};

export default validate;
