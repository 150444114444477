const validate = (values) => {
  const errors = {};

  if (!values.rateBands) return {};

  const rateBandNames = values.rateBands
    .map(({ rateBandName }) => rateBandName)
    .filter((row) => row); // remove undefined rows

  if (rateBandNames.length !== new Set(rateBandNames).size) {
    errors._error = "You cannot have duplicate rate band names";
  }
  return errors;
};

export default validate;
