import React, { Component } from "react";
import styles from "./PaymentList.module.scss";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../components/PageHeader";
import { formatDateObject } from "../../../../../utils/date";
import { Link } from "react-router-dom";
import { setLSItem } from "../../../../../utils/localStorage";
import CurrencyDisplay from "../../../../../components/CurrencyDisplay";
import CurrencyRateSums from "../../../../../components/CurrencyRateSums/CurrencyRateSums";
import { PAYMENT_TYPE_AD_HOC_DRAFT } from "../../../../../modules/payments";
import Dropdown from "../../../../../components/Dropdown/v4";
import parseQuery, { handleQuery } from "../../../../../utils/parseQuery";
import qs from "query-string";
import classNames from "classnames";
import PaymentBackDateModal from "./PaymentBackdateModal";
import PaymentCheckBox from "./PaymentCheckBox";
import { paymentTaskLabels } from "../../../../../modules/payments";

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.selectableIds = [];
    this.state = {
      checkboxStates: {},
    };
  }
  componentWillMount() {
    setLSItem("payment-search-string", this.props.location.search);
  }

  copyIds = (ids) => {
    const textField = document.createElement("textarea");
    textField.value = ids.join("\n");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  copyDeliverableIds = () => {
    const ids = this.props.payments
      .filter((p) => p.deliverableId)
      .map((p) => p.deliverableId);

    this.copyIds(ids);
  };

  copyPaymentIds = () => {
    const ids = this.props.payments
      .filter((p) => p.paymentId)
      .map((p) => p.paymentId);

    this.copyIds(ids);
  };

  getSearchString() {
    if (!this.props.payeeName && !this.props.startDate & !this.props.endDate) {
      return null;
    }

    return (
      <div className={styles.criteria}>
        Displaying payments
        {this.props.payeeName && (
          <span>
            {" "}
            for{" "}
            <span className={styles.searchParam}>{this.props.payeeName}</span>
          </span>
        )}
        {this.props.startDate && this.props.endDate && (
          <span>
            <span> between </span>
            <span className={styles.searchParam}>
              {formatDateObject(this.props.startDate, {
                chars: 10,
                dateDelim: "/",
              })}
            </span>
            <span> and </span>
            <span className={styles.searchParam}>
              {formatDateObject(this.props.endDate, {
                chars: 10,
                dateDelim: "/",
              })}
            </span>
          </span>
        )}
      </div>
    );
  }

  linkToPayment = (e) => {
    const {
      featureToggles,
      history,
      location: { pathname, search },
    } = this.props;

    if (!featureToggles.QCC_1261_viewPayments) return;

    const paymentId = Number(e?.currentTarget?.getAttribute("data-payment-id"));

    history.push({
      pathname: `/admin/payments/${paymentId}`,
      state: { originator: `${pathname}${search}` },
    });
  };

  approvePayment = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const paymentId = Number(e?.currentTarget?.getAttribute("data-payment-id"));

    this.props.approvePayment(paymentId);
  };

  deselectAll = () => {
    this.setState({ checkboxStates: {} });
  };

  toggleItem = ({ target: { id } }) => {
    const checkboxState = { ...this.state.checkboxStates[id] };
    checkboxState.checked = !checkboxState.checked;

    // update with the new checked status
    const checkboxStates = Object.assign(this.state.checkboxStates, {
      [id]: checkboxState,
    });

    this.setState({ checkboxStates });
  };

  /**
   * Renders the table/list of payments
   */
  renderList() {
    const {
      payeeName,
      allAdHocDrafts,
      payments,
      searchParams,
      paymentTypeOptionsArr,
    } = this.props;

    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.paymentIds} onClick={this.copyPaymentIds}>
                Payment ID
              </th>
              {!payeeName && <th>Name</th>}
              <th>Account</th>
              <th>Project</th>
              <th>Task</th>
              <th>Approved date</th>
              {!allAdHocDrafts && (
                <>
                  <th
                    className={styles.deliverableIds}
                    onClick={this.copyDeliverableIds}
                  >
                    Deliverable ID
                  </th>
                  <th>Batch</th>
                </>
              )}
              <th>Rate</th>

              <th className={`${styles.headerPaymentType} ${styles.noPadding}`}>
                <Dropdown
                  onClick={this.filterPaymentType}
                  optionsArr={paymentTypeOptionsArr}
                  rightAligned
                  mainContainerExtraStyles={styles.paymentTypeStyles}
                  mainButtonStyles={styles.mainButtonStyles}
                  titleExtraStyles={styles.titleExtraStyles}
                  title={searchParams.paymentType || "Payment Type"}
                />
              </th>

              <th>Notes</th>
              <th className={styles.paymentStatus}>Payment Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {payments.map(
              ({
                paymentId,
                firstName,
                lastName,
                accountName,
                projectName,
                task,
                createDate,
                rate,
                paymentType,
                stageName,
                batchName,
                notes,
                deliverableId,
                paymentStatus,
                draftPaymentDate,
                currencyCode,
              }) => {
                const isAdHocDraft = paymentType === PAYMENT_TYPE_AD_HOC_DRAFT;
                const rowStyles = classNames({
                  [styles.rowContainer]: true,
                  [styles.checked]:
                    this.state.checkboxStates[paymentId]?.checked,
                });
                return (
                  <tr
                    className={rowStyles}
                    key={paymentId}
                    data-payment-id={paymentId}
                    onClick={this.linkToPayment}
                  >
                    <td>{paymentId}</td>
                    {!payeeName && <td>{firstName + " " + lastName}</td>}
                    <td>{accountName}</td>
                    <td>{projectName}</td>
                    <td>
                      {paymentTaskLabels[task] || task || stageName || "-"}
                    </td>
                    <td>
                      {isAdHocDraft ? `Due ${draftPaymentDate}` : createDate}
                    </td>
                    {!allAdHocDrafts && (
                      <>
                        <td>{deliverableId || "-"}</td>
                        <td>{batchName || "-"}</td>
                      </>
                    )}
                    <td>
                      <CurrencyDisplay currencyCode={currencyCode} />
                      {rate}
                    </td>
                    <td>{paymentType || "WORKFLOW"}</td>
                    <td>{notes || "-"}</td>
                    <td
                      className={`${styles.paymentStatusBase} ${
                        isAdHocDraft ? styles.noPadding : ""
                      }`}
                    >
                      {isAdHocDraft ? (
                        <div className={styles.approvePayment}>
                          <div
                            className={styles.status}
                            onClick={this.approvePayment}
                            data-payment-id={paymentId}
                          >
                            <span className={styles.pending}>Pending</span>
                            <span className={styles.approve}>Approve</span>
                          </div>
                        </div>
                      ) : (
                        paymentStatus
                      )}
                    </td>
                    <td>
                      <PaymentCheckBox
                        checkboxStates={this.state.checkboxStates}
                        paymentId={paymentId}
                        toggleItem={this.toggleItem}
                      />
                    </td>
                  </tr>
                );
              }
            )}
            <CurrencyRateSums
              payments={payments}
              payeeName={payeeName}
              styles={styles.totalRow}
              allAdHocDrafts={allAdHocDrafts}
            />
          </tbody>
        </table>
        <div className={styles.infoBar}>
          <div className={styles.opacityBackground} />
          <div className={styles.backDateContainer}>
            <PaymentBackDateModal
              deselectAll={this.deselectAll}
              backdatePayments={this.props.backdatePayments}
              checkboxStates={this.state.checkboxStates}
              showModal={this.props.showModal}
              hideModal={this.props.hideModal}
            />
          </div>
        </div>
      </div>
    );
  }

  updateQuerySearch = (term, value) => {
    const { history, location } = this.props;
    const query = handleQuery(parseQuery(location.search), term, value);

    const search = qs.stringify(query);

    // only push a new history state if the params are different
    if (location.search !== `?${search}`) {
      history.push({ search });
    }
  };

  filterPaymentType = (paymentType) => {
    this.updateQuerySearch("paymentType", paymentType);
  };

  render() {
    const { payments } = this.props;

    return (
      <div>
        <Helmet>
          <title>List payments</title>
        </Helmet>

        <div>
          <PageHeader
            breadCrumbItems={[
              { url: "/admin/payments/search", text: "Search payments" },
              { text: "List payments" },
            ]}
            title={"Payments"}
          >
            <div className={styles.headerRight}>
              <Link className={styles.newPayment} to={"/admin/payments/search"}>
                New search
              </Link>
              <Link className={styles.newPayment} to={"/admin/payments/new"}>
                New payment
              </Link>
            </div>
          </PageHeader>
        </div>

        {this.getSearchString()}

        {payments.length > 0 ? (
          this.renderList()
        ) : (
          <div className={styles.emptyRow}>
            No payments were found within these search criteria
          </div>
        )}
      </div>
    );
  }
}

PaymentList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  payments: PropTypes.array.isRequired,
  payeeName: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  featureToggles: PropTypes.object.isRequired,
};

export default PaymentList;
