import { getGraphQL } from "../../../../../../../../utils/fetch";

import {
  accountsQuery,
  fetchAccountsSuccess,
} from "../../../../../../../modules/accounts";
import {
  orderFormsQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../modules/projects";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../modules/batches";
import {
  batchDeadlinesByProjectIdQuery,
  fetchBatchDeadlinesSuccess,
} from "../../../../../../../modules/batchDeadlines";
import {
  parentDeliverablesByProjectQuery,
  fetchParentDeliverablesSuccess,
} from "../../../../../../../modules/parentDeliverables";
import {
  deliverablesByProjectQuery,
  fetchDeliverablesSuccess,
} from "../../../../../../../modules/deliverables";

export const initialQuery = `query ClientProjectOverview ($projectId: String){
  ${accountsQuery}
  ${orderFormsQuery}
  ${projectQuery}
  ${batchesByProjectIdQuery}
  ${parentDeliverablesByProjectQuery}
  ${deliverablesByProjectQuery}
  ${batchDeadlinesByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchBatchesSuccess(json.batches));
      dispatch(fetchParentDeliverablesSuccess(json.parentDeliverables));
      dispatch(fetchDeliverablesSuccess(json.deliverables));
      dispatch(fetchBatchDeadlinesSuccess(json.batchDeadlines));
    });
  };
}
