import React from "react";
import PropTypes from "prop-types";
import styles from "./Show.module.scss";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../components/PageHeader";

const Show = (props) => {
  let briefLinkPrefix = "";
  if (
    props.project.briefLink &&
    props.project.briefLink.substr(0, 4) !== "http"
  )
    briefLinkPrefix = "//";

  return (
    <div>
      <Helmet>
        <title>Project information</title>
      </Helmet>
      <PageHeader
        title={"Project information"}
        breadCrumbItems={[
          { url: "/freelancer", text: "Dashboard" },
          { text: "Project information" },
        ]}
      />
      <div>
        <div>
          <div className={styles.header}>Brief link</div>
          {props.project.briefLink ? (
            <div className={styles.row}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${briefLinkPrefix}${props.project.briefLink}`}
              >
                {props.project.briefLink}
              </a>
            </div>
          ) : (
            <div className={styles.row}>No brief link available</div>
          )}
        </div>
        <div>
          <div className={styles.header}>Workflow</div>
          <div className={styles.row}>{props.workflow.workflowName}</div>
        </div>
        <div>
          <div className={styles.header}>Project languages</div>
          {props.projectLanguages.map((p) => (
            <div className={styles.row} key={p.languageCode}>
              {p.languageCode}
            </div>
          ))}
        </div>
        <div>
          <div className={styles.header}>Banned words</div>
          {Object.keys(props.bannedWords).map((key) => {
            return (
              <div className={styles.row} key={key}>
                <div className={styles.marginRight}>{key}</div>
                <span>{props.bannedWords[key].join(", ")}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Show.propTypes = {
  project: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  bannedWords: PropTypes.object.isRequired,
  projectLanguages: PropTypes.array.isRequired,
};

export default Show;
