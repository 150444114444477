import { getGraphQL } from "../../../../../../utils/fetch";
import {
  accountsQuery,
  fetchAccountSuccess,
} from "../../../../../modules/accounts";

export const initialQuery = `query getParentAccountAndAccounts {
  ${accountsQuery}
}`;

export function getInitialData(data) {
  return async (dispatch) => {
    const {
      match: { params },
    } = data;

    const { accounts } = await getGraphQL(initialQuery, params);

    dispatch(fetchAccountSuccess(accounts));
  };
}
