import React from "react";
import ParseHtml from "html-react-parser";

export function renderBriefingFieldValue(value, format) {
  if (format === "Hyperlink")
    return (
      <a alt={value} href={value} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    );
  if (format === "HTML") return ParseHtml(value);

  return <p>{value}</p>;
}
