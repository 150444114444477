import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Input from "../../../../../../../../components/Input";
import styles from "./RatesForm.module.scss";

const returnNumber = (val) => Number(val) || 0;

let RatesForm = (props) => {
  /* eslint-disable max-len */
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <div className={styles.th} />
            </th>
            {props.stages.map((s) => (
              <th key={s.stageId}>
                <div className={styles.th}>{s.stageName}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.projectLanguages.map(({ languageCode }, idx) => {
            return (
              <tr key={languageCode}>
                <td>
                  <div className={styles.column}>{languageCode}</div>
                </td>
                {props.stages.map((s, sIdx) => {
                  return (
                    <td key={s.stageId + languageCode}>
                      <div className={styles.column}>
                        <svg
                          className={styles.icon}
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#000000"
                            d="M7.5 1c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5zM7.5 14.5c-3.314 0-6-2.686-6-6s2.686-6 6-6c3.314 0 6 2.686 6 6s-2.686 6-6 6z"
                          />
                          <path
                            fill="#000000"
                            d="M9.5 11h-3.5v-2h1.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-0.5c0-0.827 0.673-1.5 1.5-1.5 0.534 0 1.032 0.288 1.3 0.75 0.138 0.239 0.444 0.321 0.683 0.182s0.321-0.444 0.182-0.683c-0.446-0.771-1.276-1.25-2.165-1.25-1.378 0-2.5 1.122-2.5 2.5v0.5h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v3h4.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z"
                          />
                        </svg>

                        <Field
                          name={`${languageCode}[${s.stageId}]`}
                          component={Input}
                          type="number"
                          min="0"
                          max="999"
                          step="0.01"
                          parse={returnNumber}
                          disabled={props.submitting}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={styles.buttonContainer}>
        {props.projectCreation && (
          <Link
            to={{
              pathname: `${props.baseUrl}/default-assignees`,
              state: { projectCreation: true },
            }}
          >
            <div className={styles.submit}>Skip</div>
          </Link>
        )}
        <button
          className={styles.button}
          type="submit"
          disabled={props.submitting || !props.valid}
        >
          Save
        </button>
      </div>
    </form>
  );
  /* eslint-enable max-len */
};

RatesForm.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  projectCreation: PropTypes.bool,
  projectLanguages: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

RatesForm = reduxForm({
  form: "ratesForm",
})(RatesForm);

export default RatesForm;
