import React from "react";
import PropTypes from "prop-types";

import styles from "./ErrorMessage.module.scss";

const ErrorMessage = (props) => (
  <div className={styles.error}>
    <h4>{props.title}</h4>
    <p>{props.main}</p>
    <p>{props.footer}</p>
  </div>
);

ErrorMessage.propTypes = {
  title: PropTypes.string,
  main: PropTypes.string,
  footer: PropTypes.string,
};

export default ErrorMessage;
