import React, { Component } from "react";
import PropTypes from "prop-types";
import AssignmentsForm from "../../../../../../../components/AssignmentsForm";
import PageHeader from "../../../../../../../components/PageHeader";
import styles from "./AssignmentsNew.module.scss";
import BatchForm from "./BatchForm";

class AssignmentsNew extends Component {
  onSubmit = async (values) => {
    const { filteredProjectLanguages } = this.props;
    try {
      await this.createAssignments({ ...values, filteredProjectLanguages });
    } catch (error) {
      throw new Error("Submission failed!");
    }
  };

  createAssignments = (data) => {
    const {
      deliverableIds,
      deliverables,
      deliverableRatesMap,
      stages,
      quotedRates,
      history,
      match: { params },
    } = this.props;

    return this.props.createAssignments(data, history, params, {
      deliverableIds,
      deliverables,
      deliverableRatesMap,
      quotedRates,
      stages,
    });
  };

  renderErrorsOrAssignmentsForm = () => {
    const {
      assignees,
      defaultAssignees,
      featureToggles,
      numParentDeliverables,
      languageCodes,
      orderForm: { orderFormId },
      people,
      project: { projectId },
      projectLanguages,
      selectedBatchId,
      stages,
      unallocatedLanguages,
    } = this.props;

    let errorMessage;

    Object.keys(defaultAssignees).forEach((stageId) => {
      Object.keys(defaultAssignees[stageId]).forEach((languageCode) => {
        if (defaultAssignees[stageId][languageCode].length === 0) {
          errorMessage =
            "Not all languages and stages have a default team. Please select your teams before proceeding.";
        }
      });
    });

    if (errorMessage) {
      return <div className={styles.errorMessage}> {errorMessage} </div>;
    }
    // dont show assignment form if user hasn't selected numParentDeliverables
    if (numParentDeliverables === 0) return;

    const cancelUrl = `/admin/projects/${projectId}`;

    return (
      <AssignmentsForm
        assignees={assignees}
        cancelUrl={cancelUrl}
        defaultAssignees={defaultAssignees}
        featureToggles={featureToggles}
        header="Assignments"
        languageCodes={languageCodes}
        numParentDeliverables={numParentDeliverables}
        onSubmit={this.onSubmit}
        orderFormId={orderFormId}
        people={people}
        projectId={projectId}
        projectLanguages={projectLanguages}
        selectedBatchId={selectedBatchId}
        stages={stages}
        submitText="Assign work"
        unallocatedLanguages={unallocatedLanguages}
      />
    );
  };

  render() {
    const {
      account: { accountName },
      batches,
      clearAssignmentsForm,
      deliverableIds,
      defaultAssignees,
      languageCodes,
      stages,
      maxNumParentDeliverables,
      orderForm: { orderFormNumber },
      parentAccount: { parentAccountName },
      project: { projectName, projectId },
      projectLanguages,
      selectedBatchId,
      unallocatedLanguages,
      isAiContentWorkflow,
      fetchDeliverablesByBatchId,
    } = this.props;

    const hasUnallocatedDeliverables =
      Object.keys(deliverableIds.unallocated).length > 0;

    // atleast one language has been selected
    const hasSelectedLanguage = languageCodes?.length > 0;

    const shouldRenderAssignmentForm =
      selectedBatchId &&
      hasSelectedLanguage &&
      maxNumParentDeliverables > 0 &&
      hasUnallocatedDeliverables;

    return (
      <div>
        <PageHeader
          breadCrumbItems={[
            { url: `/admin`, text: "Parent accounts" },
            {
              text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
            },
            { url: `/admin/projects/${projectId}`, text: `${projectName}` },
            { text: "Assign work" },
          ]}
          title={`${projectName} - Assign work`}
        />
        <BatchForm
          languageCodes={languageCodes}
          batches={batches}
          clearAssignmentsForm={clearAssignmentsForm}
          deliverableIds={deliverableIds}
          defaultAssignees={defaultAssignees}
          maxNumParentDeliverables={maxNumParentDeliverables}
          projectLanguages={projectLanguages}
          selectedBatchId={selectedBatchId}
          stages={stages}
          unallocatedLanguages={unallocatedLanguages}
          isAiContentWorkflow={isAiContentWorkflow}
          fetchDeliverablesByBatchId={fetchDeliverablesByBatchId}
        />
        {shouldRenderAssignmentForm && this.renderErrorsOrAssignmentsForm()}
      </div>
    );
  }
}

AssignmentsNew.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  assignees: PropTypes.object,
  batches: PropTypes.array.isRequired,
  clearAssignmentsForm: PropTypes.func.isRequired,
  createAssignments: PropTypes.func.isRequired,
  defaultAssignees: PropTypes.object.isRequired,
  deliverableIds: PropTypes.shape({
    allocated: PropTypes.object.isRequired,
    unallocated: PropTypes.object.isRequired,
  }).isRequired,
  deliverableRatesMap: PropTypes.object,
  featureToggles: PropTypes.object,
  filteredProjectLanguages: PropTypes.instanceOf(Set),
  isLocalisation: PropTypes.bool,
  maxNumParentDeliverables: PropTypes.number.isRequired,
  numParentDeliverables: PropTypes.number.isRequired,
  orderForm: PropTypes.shape({
    orderFormId: PropTypes.number,
    orderFormNumber: PropTypes.number,
  }),
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string,
  }),
  people: PropTypes.object.isRequired,
  project: PropTypes.shape({
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  }),
  projectLanguages: PropTypes.array.isRequired,
  selectedBatchId: PropTypes.number,
  stages: PropTypes.array.isRequired,
  unallocatedLanguages: PropTypes.object,
};

export default AssignmentsNew;
