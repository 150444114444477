import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const personRolesByPersonIdQuery = `personRoles (personId: $personId) {
  personId, role
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_PERSON_ROLES_SUCCESS = "FETCH_PERSON_ROLES_SUCCESS";
export const REMOVE_PERSON_ROLES_SUCCESS = "REMOVE_PERSON_ROLES_SUCCESS";
export const REPLACE_PERSON_ROLES_SUCCESS = "REPLACE_PERSON_ROLES_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPersonRolesSuccess = createAction(FETCH_PERSON_ROLES_SUCCESS);
export const removePersonRolesSuccess = createAction(
  REMOVE_PERSON_ROLES_SUCCESS
);
export const replacePersonRolesSuccess = createAction(
  REPLACE_PERSON_ROLES_SUCCESS
);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const personRolesActionHandlers = {
  [RESET_INITIAL_STATE]: () => personRolesInitialState,
  [FETCH_PERSON_ROLES_SUCCESS]: (state, { payload }) =>
    payload.reduce((acc, pr) => {
      if (acc.some((a) => a.personId === pr.personId && a.role === pr.role)) {
        return acc;
      }
      return acc.concat(pr);
    }, state),
  [REMOVE_PERSON_ROLES_SUCCESS]: (state, { payload }) => {
    if (!payload) return state;
    const personId = payload;

    const newState = state.reduce(
      (acc, val) => acc.concat(val.personId !== personId ? val : []),
      []
    );

    return newState;
  },
  [REPLACE_PERSON_ROLES_SUCCESS]: (state, { payload }) => {
    if (!payload) return state;

    let personId;
    const newState = payload.reduce((acc, p) => {
      if (!personId) personId = p.personId;
      if (acc.some((a) => a.personId === p.personId && a.role === p.role))
        return acc;
      acc.push(p);
      return acc;
    }, []);
    return newState;
  },
};

// ------------------------------------
// Initial State
// ------------------------------------
export const personRolesInitialState = [];
