import React from "react";
import PropTypes from "prop-types";
import validate from "./validations";
import { Field, reduxForm } from "redux-form";
import { InputWithErrors } from "../../../../decorators/WithErrors";

import styles from "./ResetForm.module.scss";

const Form = (props) => (
  <form noValidate onSubmit={props.handleSubmit}>
    <label className={styles.label}>Please provide your email:</label>
    <Field name="email" type="email" component={InputWithErrors} />
    <div>{props.error && <strong>{props.error}</strong>}</div>
    <button
      type="submit"
      className={styles.button}
      disabled={props.pristine || props.submitting}
    >
      Reset
    </button>
  </form>
);

Form.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const ResetForm = reduxForm({
  form: "reset",
  validate,
})(Form);

export default ResetForm;
