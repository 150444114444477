import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  bannedWordsByProjectIdQuery,
  fetchBannedWordsSuccess,
} from "../../../../../../modules/bannedWords";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../modules/projectLanguages";

export const initialQuery = `query getInitialData ($projectId: String) {
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
  ${bannedWordsByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchBannedWordsSuccess(json.bannedWords));
      dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
    });
  };
}
