import PropTypes from "prop-types";
import Modal from "../Modal";
import { ModalType } from "../../modules/modal";
import styles from "./LanguageMismatchAlertModal.module.scss";

const LanguageMismatchAlertModal = ({
  data,
  hideModal,
  languageName,
  processTransitionRequest,
}) => (
  <Modal
    body={
      <div>
        {data?.transition?.transitionName === "Request amends"
          ? `You are attempting to request amendments in ${languageName}, which is not linked to your profile on J+ Scribe. Are you sure you want to proceed with the request?`
          : `You are attempting to approve content in ${languageName}, which is not linked to your profile on J+ Scribe. Are you sure you want to proceed with the approval?`}
      </div>
    }
    footer={
      <div>
        <button className={styles.modalCancel} onClick={hideModal}>
          Cancel
        </button>
        <button
          className={styles.modalConfirm}
          onClick={() => {
            processTransitionRequest(data);
            hideModal();
          }}
        >
          Proceed
        </button>
      </div>
    }
    type={ModalType.NativeLanguageMismatchAlert}
  />
);

LanguageMismatchAlertModal.propTypes = {
  data: PropTypes.object,
  hideModal: PropTypes.func,
  languageName: PropTypes.string,
  processTransitionRequest: PropTypes.func,
};

export default LanguageMismatchAlertModal;
