import ProjectInfo from "../components/ProjectInfo";
import WithData from "../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import {
  accountById,
  orderFormById,
  projectBannedWordsSelector,
  projectLanguagesSelector,
} from "../../../../utils/entitySelector";
import { createSelector } from "reselect";
import { projectById } from "../../../../modules/projects";

const orderByLanguage = createSelector(
  (entity, property) => entity,
  (entity, property) => property,
  (entity, property) => {
    const tree = {};
    entity.forEach((e) => {
      if (!tree[e.languageCode]) tree[e.languageCode] = [];
      tree[e.languageCode].push(e[property]);
    });
    return tree;
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);

  const project = projectById(state, projectId);
  const orderForm = orderFormById(state, project.orderFormId);
  const account = accountById(state, orderForm.accountId);
  const { contentTypeName } =
    state.contentTypes.entities[project.contentTypeId] || {};
  const workflow = state.workflows.entities[project.workflowId] || {};

  const projectLanguages = projectLanguagesSelector(state, projectId);
  const bannedWords = orderByLanguage(
    projectBannedWordsSelector(state, projectId),
    "word"
  );

  return {
    project,
    workflow,
    projectLanguages,
    bannedWords,
    orderForm,
    contentTypeName,
    account,
  };
};

const ClientProjectInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ProjectInfo));
export default ClientProjectInfo;
