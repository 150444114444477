import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import AttachmentsDisplay from "../AttachmentsDisplay";
import styles from "./Attachments.module.scss";

const Attachments = ({
  createAttachments,
  attachments,
  projectId,
  parentDeliverableId,
  hideModal,
  showModal,
  toggleAttachmentsModal,
}) => {
  const [attachment, setAttachment] = useState();

  useEffect(() => {
    showModal();
  });

  const selectFile = (e) => {
    const [file] = e.target.files;
    setAttachment(file);
  };

  const closeModal = (e) => {
    toggleAttachmentsModal(e);
    hideModal();
  };

  const uploadAttachment = () => {
    createAttachments(attachment, projectId, parentDeliverableId);
  };

  return (
    <Modal
      key={attachments.length}
      title={`Upload Attachments`}
      onClickCallback={hideModal}
      wide
      body={
        <AttachmentsDisplay attachments={attachments} selectFile={selectFile} />
      }
      footer={
        <div>
          <button
            disabled={!attachment}
            className={styles.modalConfirm}
            onClick={uploadAttachment}
          >
            Upload
          </button>
          <button className={styles.modalCancel} onClick={closeModal}>
            Cancel
          </button>
        </div>
      }
    />
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array,
  createAttachments: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  parentDeliverableId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  showModal: PropTypes.func.isRequired,
  toggleAttachmentsModal: PropTypes.func.isRequired,
};

export default Attachments;
