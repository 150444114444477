import React from "react";
import PropTypes from "prop-types";
import OverlayLoading from "../../../../../../../components/OverlayLoading/v1";
import TableHeaders from "./TableHeaders";
import TableContent from "./TableContent";
import TableTitles from "./TableTitles";
import styles from "./Overview.module.scss";

export const Overview = (props) => {
  const {
    projectId,
    dataReady,
    clientStages,
    totals,
    location,
    history,
  } = props;
  const clientStagesLength = clientStages.length;

  if (!dataReady) return <OverlayLoading />;

  if (totals.length === 0) {
    return (
      <div className={styles.noBatches}>
        No batches were found with search criteria.
      </div>
    );
  }

  return (
    <div className={styles.overviewContainer}>
      <TableTitles clientStagesLength={clientStagesLength} />
      <TableHeaders
        clientStages={clientStages}
        batches={totals}
        location={location}
        history={history}
      />
      <TableContent
        clientStagesLength={clientStagesLength}
        projectId={projectId}
        totals={totals}
        location={location}
      />
    </div>
  );
};

Overview.propTypes = {
  clientStages: PropTypes.array.isRequired,
  dataReady: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  totals: PropTypes.array.isRequired,
};

export default Overview;
