import Layout from "./containers/Layout";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../../../../modules/projects";
export default {
  Component: Layout,
  reducers: [
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
  ],
};
