import React, { memo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Dropdown.module.scss";

const Dropdown = ({
  buttonStyles,
  children,
  containerStyles,
  onToggle,
  title,
}) => {
  const [show, setShow] = useState(false);

  const toggleDropdown = useCallback(() => {
    setShow((show) => {
      if (onToggle) onToggle(!show);

      return !show;
    });
  }, [onToggle]);

  const dropdownContainerStyles = classNames({
    [styles.dropdownContainer]: true,
    [containerStyles]: containerStyles,
  });

  const dropdownStyles = classNames({
    [styles.dropdown]: true,
    [styles.dropdownBoxShadow]: show,
  });

  const dropdownButtonStyles = classNames({
    [styles.dropdownButton]: true,
    [styles.dropdownButtonBorder]: show,
    [buttonStyles]: buttonStyles,
  });

  const overlayStyles = classNames({
    [styles.overlay]: show,
  });

  return (
    <div className={dropdownContainerStyles}>
      <div className={dropdownStyles} onClick={toggleDropdown}>
        <button className={dropdownButtonStyles}>{title}</button>
        {show && <div className={styles.dropdownMenu}>{children}</div>}
      </div>
      <div className={overlayStyles} onClick={toggleDropdown} />
    </div>
  );
};

Dropdown.propTypes = {
  buttonStyles: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array,
  ]),
  containerStyles: PropTypes.string,
  onToggle: PropTypes.func, // only used on the briefing tab, because position absolute nightmare
  title: PropTypes.string.isRequired,
};

export default memo(Dropdown);
