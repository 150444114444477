import React, { Component } from "react";
import PropTypes from "prop-types";
import { isTaskFormatHTML, TaskFormat } from "../../../../modules/taskFields";
import SourceField from "./SourceField";
import TaskEditor from "./TaskEditor";
import ImageEditor from "./TaskEditor/ImageEditor";
import styles from "./Task.module.scss";
import CommentGroup from "../_components/CommentGroup";
import TitleBar from "../_components/TitleBar";
import PlagiarismMessage from "./PlagiarismMessage";
import WritingAidSuggestions from "../_components/WritingAidSuggestions/WritingAidSuggestions";
import classNames from "classnames";

class Task extends Component {
  state = {};

  get wordCount() {
    return this.taskEditor && this.taskEditor.wordCount;
  }

  get taskState() {
    return this.taskEditor && this.taskEditor.taskState;
  }

  get content() {
    return this.taskEditor && this.taskEditor.content;
  }

  /**
   * @param {Object} positionRect position area rectangle
   * @param {string} type the type of comment (inline, field)
   * @param {number} [commentGroupId]
   * @param {Object} [selection] draft js selection
   */
  openCommentBox = (positionRect, type, commentGroupId, selection) => {
    this.setState({
      selectedCommentGroup: {
        positionRect,
        type,
        commentGroupId,
        selection,
      },
    });
  };

  openSuggestionBox = (positionRect, suggestion, text, start) => {
    this.setState({
      selectedSuggestionGroup: {
        positionRect,
        suggestion,
        text,
        start,
      },
    });
  };

  closeCommentBox = () => {
    this.setState({ selectedCommentGroup: null });
  };

  closeSuggestionBox = () => {
    this.setState({ selectedSuggestionGroup: null });
  };

  discardChanges = () => {
    this.taskEditor.discardChanges();
  };

  applySuggestion = (suggestion, text, start) => {
    this.taskEditor.applySuggestion(suggestion, text, start);
    this.setState({ selectedSuggestionGroup: null });
  };

  addToDictionary = (text) => {
    this.taskEditor.addToDictionary(text);
    this.setState({ selectedSuggestionGroup: null });
  };

  /**
   * @param {Object} comment
   */
  onCommentAdded = ({ commentGroupId }) => {
    // Ensure rawContent inline-styles are saved
    this.taskEditor && this.taskEditor.saveImmediately();

    const { selectedCommentGroup } = this.state;

    if (
      !selectedCommentGroup ||
      commentGroupId !== selectedCommentGroup.commentGroupId
    ) {
      // Re-render box if comment group id has changed
      this.setState({
        selectedCommentGroup: {
          ...(this.state.selectedCommentGroup || {}),
          commentGroupId,
        },
      });
    }
  };

  fetchGrammarCheckIgnores = async () => {
    await this.props.fetchGrammarCheckIgnores(this.props.projectId);

    this.props.checkAllGrammars();
  };

  renderSuggestionBox() {
    const { selectedSuggestionGroup } = this.state;
    if (selectedSuggestionGroup) {
      const { suggestion, text, positionRect, start } =
        selectedSuggestionGroup || {};
      return (
        <WritingAidSuggestions
          suggestion={suggestion}
          text={text}
          onClose={this.closeSuggestionBox}
          position={positionRect}
          start={start}
          applySuggestion={this.applySuggestion}
          addToDictionary={this.addToDictionary}
          taskFieldId={this.props.taskFieldId}
          projectId={this.props.projectId}
          refetchGrammarChecks={this.fetchGrammarCheckIgnores}
        />
      );
    }
  }

  checkGrammar() {
    this.taskEditor.checkGrammar();
  }

  /**
   * Render the comment box
   */
  renderCommentGroup() {
    const {
      deliverableId,
      isCommentable,
      stageId,
      taskFieldId,
      initialClientTeam,
    } = this.props;

    const { selectedCommentGroup } = this.state;

    // Comment box dialog
    if (selectedCommentGroup) {
      const { taskEditor } = this;
      const { commentGroupId, positionRect, type, selection } =
        selectedCommentGroup;

      return (
        <CommentGroup
          addInlineStyle={taskEditor && taskEditor.addStyle}
          commentGroupId={commentGroupId}
          commentType={type}
          deliverableId={deliverableId}
          isCommentable={isCommentable}
          onClose={this.closeCommentBox}
          onCommentAdded={this.onCommentAdded}
          onCommentGroupArchived={() =>
            taskEditor && taskEditor.saveImmediately()
          }
          position={positionRect}
          removeInlineStyle={taskEditor && taskEditor.removeStyle}
          selection={selection}
          stageId={stageId}
          taskFieldId={taskFieldId}
          initialClientTeam={initialClientTeam}
        />
      );
    }
  }

  /**
   * Renders a source field and task field
   */
  render() {
    const {
      addToDictionary,
      bannedWords,
      commentGroups,
      content,
      debounceSaveAll,
      deliverableId,
      dualDecorator,
      fieldName,
      format,
      isCommentable,
      isCurrentStage,
      isEditable,
      isFinalStage,
      isLocalisationProject,
      keywords,
      onWordCountUpdate,
      personId,
      projectId,
      qualityCheck,
      rawContent,
      removeSuggestion,
      saveSingleTask,
      sourceContent,
      plagiarismTask,
      stageId,
      taskFieldId,
      minWords,
      maxWords,
      minCharacters,
      maxCharacters,
      filteredGrammarChecks,
      allowGrammarCheck,
      languageCode,
      glossaryWords,
      textAlignment,
      textDirectionality,
      glossarySourceWords,
      initialClientTeam,
      draftJsTablePlugin,
      isWithClient,
    } = this.props;

    const isHTML = isTaskFormatHTML(format);

    // some editors require different props than others so explicitely define them
    const isTextEditor = format === TaskFormat.Text || isHTML;
    const isImageEditor = format === TaskFormat.Image;

    const hasRawContent = typeof rawContent !== "undefined";

    const sharedEditorProps = {
      // Ensure React creates a fresh instance if values change
      key: `${deliverableId}-${taskFieldId}-${hasRawContent}`,
      deliverableId,
      isEditable,
      personId,
      saveSingleTask,
      stageId,
      taskFieldId,
      projectId,
    };

    const taskEditorContainerSizes = classNames({
      [styles.taskEditorContainer]: true,
      [styles.isLocalisation]: isLocalisationProject,
    });

    return (
      <div className={styles.taskContainer}>
        <div className={`${styles.task} task`} id={`task-${taskFieldId}`}>
          <TitleBar
            deliverableId={deliverableId}
            fieldName={fieldName}
            isCommentable={isCommentable}
            stageId={stageId}
            taskFieldId={taskFieldId}
            initialClientTeam={initialClientTeam}
          />

          <div className={styles.fieldContainer}>
            {isLocalisationProject && (
              <div className={styles.sourceFieldContainer}>
                <SourceField
                  format={format}
                  sourceContent={sourceContent}
                  glossarySourceWords={glossarySourceWords}
                />
              </div>
            )}

            <div className={taskEditorContainerSizes}>
              {isTextEditor && (
                <TaskEditor
                  ref={(component) => (this.taskEditor = component)}
                  bannedWords={bannedWords}
                  commentGroups={commentGroups}
                  debounceSaveAll={debounceSaveAll}
                  dualDecorator={dualDecorator}
                  isCommentable={isCommentable}
                  isCurrentStage={isCurrentStage}
                  isFinalStage={isFinalStage}
                  isHTML={isHTML}
                  keywords={keywords}
                  glossaryWords={glossaryWords}
                  maxCharacters={maxCharacters}
                  maxWords={maxWords}
                  minCharacters={minCharacters}
                  minWords={minWords}
                  onWordCountUpdate={onWordCountUpdate}
                  openCommentBox={this.openCommentBox}
                  openSuggestionBox={this.openSuggestionBox}
                  qualityCheck={qualityCheck}
                  rawContent={rawContent}
                  grammarChecks={filteredGrammarChecks}
                  removeSuggestion={removeSuggestion}
                  allowGrammarCheck={allowGrammarCheck}
                  languageCode={languageCode}
                  addToDictionary={addToDictionary}
                  processTransitionGrammarCheck={
                    this.props.processTransitionGrammarCheck
                  }
                  textAlignment={textAlignment}
                  textDirectionality={textDirectionality}
                  draftJsTablePlugin={draftJsTablePlugin}
                  isWithClient={isWithClient}
                  {...sharedEditorProps}
                />
              )}

              {isImageEditor && (
                <ImageEditor
                  ref={(component) => (this.taskEditor = component)}
                  content={content}
                  projectId={projectId}
                  {...sharedEditorProps}
                />
              )}
            </div>
          </div>

          {plagiarismTask && <PlagiarismMessage {...plagiarismTask} />}

          {this.renderCommentGroup()}
          {this.renderSuggestionBox()}
        </div>
      </div>
    );
  }
}

Task.propTypes = {
  bannedWords: PropTypes.array,
  commentGroups: PropTypes.object.isRequired,
  content: PropTypes.string,
  debounceSaveAll: PropTypes.func.isRequired,
  deliverableId: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  isCommentable: PropTypes.bool.isRequired,
  isCurrentStage: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isFinalStage: PropTypes.bool,
  isLocalisationProject: PropTypes.bool.isRequired,
  keywords: PropTypes.array,
  maxCharacters: PropTypes.number,
  maxWords: PropTypes.number,
  minCharacters: PropTypes.number,
  minWords: PropTypes.number,
  onWordCountUpdate: PropTypes.func.isRequired,
  personId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  qualityCheck: PropTypes.bool,
  rawContent: PropTypes.object,
  saveSingleTask: PropTypes.func.isRequired,
  sourceContent: PropTypes.string,
  stageId: PropTypes.number.isRequired,
  taskFieldId: PropTypes.number.isRequired,
  initialClientTeam: PropTypes.array.isRequired,
};

export default Task;
