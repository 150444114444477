export const fullMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthRange = (month, year) => {
  const newDate = new Date(year, month);

  const firstDateOfMonth = new Date(
    newDate.getFullYear(),
    newDate.getMonth(),
    1
  );
  const lastDateOfMonth = new Date(
    newDate.getFullYear(),
    newDate.getMonth() + 1,
    0
  );
  const start = formatDateObject(firstDateOfMonth).substring(0, 10);
  const end = formatDateObject(lastDateOfMonth).substring(0, 10);

  return { start, end };
};

/**
 * @param {Date} date
 * @returns {string} date in yyyy-mm-dd format
 */
export const formatDate = (date, chars = 10) => {
  if (date.constructor !== Date) date = new Date(date);
  return formatDateObject(convertDate(date)).substring(0, chars);
};

/**
 * convertDate - Converts a date string into UTC from a local timezone or vice versa
 * @param  {String} dateString string representation of a date
 * @param  {Boolean} UTC set to true if converting to UTC; otherwise it converts to local
 * @return {Date}
 */
export const convertDate = (dateString, UTC = false) => {
  let date = new Date(dateString);
  const localOffset = date.getTimezoneOffset() * 60 * 1000;
  const localTime = date.getTime();

  date = UTC ? localTime + localOffset : localTime - localOffset;
  return new Date(date);
};

export const convertDateToLocal = (dateString) =>
  convertDate(dateString, false);
export const convertDateToUTC = (dateString) => convertDate(dateString, true);

/**
 * convertDate - Converts a date into yy-mm-dd hh:mm:ss format
 * @param  {Date} date Date object
 * @param  {Boolean} includeSeparator set to true if including the 'T' separator
 * @return {String}
 */
export const formatDateObject = (date, opts = {}) => {
  const convertToString = (num) => (num < 10 ? `0${num}` : `${num}`);
  const day = convertToString(date.getDate());
  const month = convertToString(date.getMonth() + 1);
  const year = convertToString(date.getFullYear());

  const hours = convertToString(date.getHours());
  const minutes = convertToString(date.getMinutes());
  const seconds = convertToString(date.getSeconds());

  /* 'T' is used as a separator to distinguish between the date and time; include
   * this when using the date in an Input field for example */
  const separator = opts.includeSeparator ? "T" : " ";
  const dateDelim = opts.dateDelim || "-";

  return opts.chars
    ? `${year}${dateDelim}${month}${dateDelim}${day}${separator}${hours}:${minutes}:${seconds}`.substring(
        0,
        opts.chars
      )
    : `${year}${dateDelim}${month}${dateDelim}${day}${separator}${hours}:${minutes}:${seconds}`;
};

/**
 * formatDateString - YY-MM-DD HH:MM:SS format for creating dates is not supported
 * by Safari despite being included in the ECMA-262 standard -
 * https://stackoverflow.com/questions/4310953/invalid-date-in-safari
 *
 * This re-formats strings to YY/MM/DD HH:MM:SS format to account for this
 *
 * @param  {string} date
 * @return {string}
 */
export const formatDateString = (date) => date.replace(/-/g, "/");

export const currentISODate = () => {
  const regex = new RegExp(/.*T\d{2}:\d{2}/);
  return formatDateObject(convertDate(new Date()), {
    includeSeparator: true,
  }).match(regex)[0];
};

/**
 * @typedef {Object} YearMonthDropdownOption
 * @property {string} label the month and year (i.e. June 2018)
 * @property {Object} value object containing the date range
 * @property {string} value.startDate the start date of the range (i.e. 2018-06-01)
 * @property {string} value.endDate the end date of the range (i.e. 2018-06-30)
 */
/**
 * Returns a list of years and months for use in dropdown boxes
 *
 * @param {number} [startYear= 2018] the earliest year
 * @returns {YearMonthDropdownOption[]} array of options
 */
export const getYearMonthDropdownOptions = (startYear = 2018) => {
  const list = [];
  const today = new Date();

  for (let year = today.getFullYear(); year >= startYear; year--) {
    for (let month = 11; month >= 0; month--) {
      if (year === today.getFullYear() && month > today.getMonth()) continue;

      const startDate = new Date(year, month, 1);
      const endDate = new Date(year, month + 1, 0);

      list.push({
        label: `${fullMonthNames[month]} ${year}`,
        value: {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
        },
      });
    }
  }

  return list;
};

export const getLocalTimezoneString = () => {
  const timezoneString = new Date().toString().match(/GMT.*/);
  return timezoneString && timezoneString[0] ? timezoneString[0] : "";
};

/**
 * Parse an array of date strings
 *
 * @param   {string[]}  dates array of date strings
 * @returns {Object[]}  array of parsed date objects
 */
export const parseDateValues = (dates) => {
  return dates.map((date) => {
    const newDate = new Date(formatDateString(date));

    return {
      day: newDate.getDate().toString().padStart(2, "0"),
      monthName: fullMonthNames[newDate.getMonth()],
      monthValue: newDate.getFullYear().toString().padStart(2, "0"),
      year: newDate.getFullYear(),
    };
  });
};

/**
 * @param   {String} dateString
 * @returns {Date}
 */
export const fromUTCString = (dateString) => new Date(`${dateString} UTC`);

/**
 * @param   {Date}    date
 * @returns {String}  Date formatted for inputs (YYYY-MM-DDTHH:MM)
 */
export const toInputString = (date) =>
  formatDateObject(date, { includeSeparator: true });

/**
 * @param   {number} number
 * @returns {string} number padded to 2 digits
 */
const twoDigits = (number) => `${number}`.padStart(2, "0");

/**
 * https://stackoverflow.com/questions/5129624/convert-js-date-time-to-mysql-datetime
 *
 * @param   {Date}    date
 * @returns {String}  Date formatted as YYYY-MM-DD HH:MM:SS in UTC time
 */
export const toMysqlFormat = (date) => {
  const day = twoDigits(date.getUTCDate());
  const month = twoDigits(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();
  const hours = twoDigits(date.getUTCHours());
  const minutes = twoDigits(date.getUTCMinutes());
  const seconds = twoDigits(date.getUTCSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
