import { connect } from "react-redux";
import Component from "./AssignmentsShow";
import ScrollToTop from "../../../../../../../../../decorators/ScrollToTop";
import WithData from "../../../../../../../../../decorators/WithData";
import {
  assignmentsByAssignmentGroupQuery,
  fetchAssignmentsSuccess,
} from "../../../../../../../../../modules/assignments";
import {
  projectsByAssignmentQuery,
  fetchProjectsSuccess,
} from "../../../../../../../../../modules/projects";
import {
  assignmentById,
  projectById,
  stageById,
} from "../../../../../../../../../utils/entitySelector";
import { getGraphQL } from "../../../../../../../../../../utils/fetch";

const initialQuery = `query FreelancerAssignmentEdit ($assignmentId: String, $assignmentGroupId: String, $includeArchivedAssignments: Boolean) {
  ${assignmentsByAssignmentGroupQuery}
  ${projectsByAssignmentQuery}
}`;

function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);
    dispatch(fetchAssignmentsSuccess(json.assignments));
    dispatch(fetchProjectsSuccess(json.projects));
  };
}

function mapDispatchToProps(dispatch, { match }) {
  return {
    getInitialData: () => dispatch(getInitialData(match.params)),
  };
}

function mapStateToProps(state, ownProps) {
  const {
    match: { params: { assignmentId, assignmentGroupId } = {} },
  } = ownProps;
  const assignment = assignmentById(state, assignmentId);
  const { projectId, stageId } = assignment;
  const { stageName } = stageById(state, stageId);

  const project = projectById(state, projectId);
  const listUrl = `/freelancer/projects/${projectId}/assignments/${assignmentGroupId}`;

  return {
    assignment,
    listUrl,
    project,
    stageName,
  };
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollToTop(WithData(Component)));

export default {
  Component: Container,
};
