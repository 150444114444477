import { connect } from "react-redux";
import { createSelector } from "reselect";
import PaymentList from "../components/PaymentList";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../decorators/WithData";
import {
  listToValueAndLabel,
  paymentsSelector,
  peopleFullNameSelector,
} from "../../../../../utils/entitySelector";
import parseQuery from "../../../../../utils/parseQuery";
import {
  approvePayment,
  PAYMENT_TYPE_AD_HOC_DRAFT,
} from "../../../../../modules/payments";
import { showModal, hideModal } from "../../../../../modules/modal";
import { backdatePayments } from "../../../../../modules/payments";

function getAdHocDraftDate(date) {
  if (!date) return null;

  const [y, m] = date.split("-");

  return `${m}-${y}`;
}

const paymentSearchSelector = createSelector(
  paymentsSelector,
  (state) => state.people.entities,
  (state) => state.projects.entities,
  (_, searchParams) => searchParams,
  (paymentsOriginal, people, projects, criteria) => {
    const payments = paymentsOriginal
      .map((p) => {
        const { firstName, lastName } = people[p.payeePersonId] || {};
        const { projectName, currencyCode } = projects[p.projectId] || {};
        const draftPaymentDate = getAdHocDraftDate(p.draftPaymentDate);

        return {
          ...p,
          projectName,
          currencyCode,
          firstName,
          lastName,
          draftPaymentDate,
        };
      })
      .filter((payment) => {
        if (
          criteria.type === PAYMENT_TYPE_AD_HOC_DRAFT &&
          payment.paymentType !== PAYMENT_TYPE_AD_HOC_DRAFT
        ) {
          return false;
        }
        if (criteria.personId && payment.payeePersonId !== criteria.personId)
          return false;
        if (criteria.accountId && payment.accountId !== criteria.accountId)
          return false;
        if (
          criteria.orderFormId &&
          payment.orderFormId !== criteria.orderFormId
        )
          return false;
        if (criteria.projectId && payment.projectId !== criteria.projectId)
          return false;
        if (criteria.paymentTask && payment.task !== criteria.paymentTask)
          return false;
        if (criteria.stageName && payment.stageName !== criteria.stageName)
          return false;
        if (
          criteria.startDate &&
          new Date(payment.createDate) < criteria.startDate
        )
          return false;
        if (criteria.endDate && new Date(payment.createDate) > criteria.endDate)
          return false;

        if (criteria.paymentType) {
          // null should be counted as WORKFLOW, until QCC-1374 resolved
          const paymentType = payment.paymentType || "WORKFLOW";
          if (paymentType !== criteria.paymentType) {
            return false;
          }
        }

        return true;
      })
      .sort((a, b) => a.paymentId - b.paymentId);

    const paymentTypeOptionsArr = listToValueAndLabel(
      Object.keys(
        paymentsOriginal.reduce((acc, { paymentType }) => {
          acc[paymentType] = true;
          return acc;
        }, {})
      )
    );

    return { payments, paymentTypeOptionsArr };
  }
);

/**
 * Santitize the search parameters
 *
 * @param {Object} rawParams
 * @returns {Object} sanitized data
 */
function sanitizeSearchParams(rawParams = {}) {
  const filtered = { ...rawParams };

  // Sanitize numeric parameters
  ["personId", "projectId", "orderFormId", "accountId"].forEach((field) => {
    if (typeof rawParams[field] !== "undefined") {
      filtered[field] = Number(rawParams[field]);
    }
  });

  // Sanitize dates
  const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/; // yyyy-mm-dd

  if (
    rawParams.startDate &&
    rawParams.startDate.match(dateRegex) &&
    rawParams.endDate &&
    rawParams.endDate.match(dateRegex)
  ) {
    const startDate = new Date(`${rawParams.startDate}`);
    const endDate = new Date(`${rawParams.endDate}`);
    startDate.setHours(0, 0, 0);
    endDate.setHours(23, 59, 59);
    filtered.startDate = startDate;
    filtered.endDate = endDate;
  } else {
    delete filtered.startDate;
    delete filtered.endDate;
  }

  return filtered;
}

const getPersonName = createSelector(
  peopleFullNameSelector,
  (_, personId) => personId,
  (peopleArr, personId) => {
    if (personId) {
      const person = peopleArr.find((p) => p.personId === personId);

      if (person) {
        return person.fullName;
      }
    }

    return "";
  }
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps)),
  approvePayment: (paymentId) => dispatch(approvePayment(paymentId)),
  hideModal: () => dispatch(hideModal()),
  showModal: (type) => dispatch(showModal(type)),
  backdatePayments: (payments) => dispatch(backdatePayments(payments)),
});

const mapStateToProps = (state, { location }) => {
  const searchParams = sanitizeSearchParams(parseQuery(location?.search));
  const payeeName = getPersonName(state, searchParams.personId);

  const { payments, paymentTypeOptionsArr } = paymentSearchSelector(
    state,
    searchParams
  );
  const allAdHocDrafts = payments.every(
    ({ paymentType }) => paymentType === PAYMENT_TYPE_AD_HOC_DRAFT
  );

  return {
    allAdHocDrafts,
    payments,
    payeeName,
    startDate: searchParams.startDate,
    endDate: searchParams.endDate,
    featureToggles: state.featureToggles,
    paymentTypeOptionsArr,
    searchParams,
  };
};

const PaymentListWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(PaymentList));
PaymentListWithData.getInitialData = getInitialData;
export default PaymentListWithData;
