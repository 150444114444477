const addTrainingDeliverablesToStages = (stages, assignmentData) => {
  const stagesWithTrainingData = [];

  stages.map((s, idx) => {
    const stage = { ...s };
    // only need to add counts for training stages
    if (s.stageType === "Training") {
      const prevStageIndex = idx - 1;
      // the previous stage is the production stage associated with the training stage
      const prevStage = assignmentData[stages[prevStageIndex].stageId];
      // Update the previous stage to say it has a 'connected' training stage
      stagesWithTrainingData[prevStageIndex].hasTrainingStage = true;

      // Loop over all languages and count the amount of deliverables in training
      for (const languageCode in prevStage) {
        let trainingDeliverables = 0;
        for (const personId in prevStage[languageCode]) {
          const data = prevStage[languageCode][personId];
          if (data.training && data.allocation) {
            trainingDeliverables += data.allocation;
          }
        }

        // Set the deliverable count for this language
        if (!stage.deliverableAmount) stage.deliverableAmount = {};
        stage.deliverableAmount[languageCode] = trainingDeliverables;
      }
    }
    stagesWithTrainingData.push(stage);
    return null;
  });

  return stagesWithTrainingData;
};
export default addTrainingDeliverablesToStages;
