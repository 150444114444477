import Overview from "../components/Overview";
import WithData from "../../../../../../../decorators/WithData";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import {
  workflowStagesSelector,
  projectDeliverablesSelector,
} from "../../../../../../../utils/entitySelector";

// Training stages also shouldn't be included but are technically production stages
const nonProductionStageSelector = createSelector(
  workflowStagesSelector,
  (stages) =>
    stages
      .filter((s) => ["Production", "Training"].indexOf(s.stageType) === -1)
      .slice()
      .sort((a, b) => a.stagePosition - b.stagePosition)
);

/**
 * @param {Object} state
 * @returns {Object} deadlines indexed by batchId then stageId
 */
const deadlinesByBatchAndStageSelector = createSelector(
  (state) => state.batchDeadlines,
  (batchDeadlines) =>
    batchDeadlines.reduce((acc, { batchId, stageId, deadline }) => {
      if (!acc[batchId]) acc[batchId] = {};
      acc[batchId][stageId] = deadline;
      return acc;
    }, {})
);

/**
 * @param {Object} state
 * @param {number} projectId
 * @param {number} workflowId
 */
const batchDeliverablesSelector = createSelector(
  (_state, _projectId, workflowId) => workflowId,
  projectDeliverablesSelector,
  deadlinesByBatchAndStageSelector,
  (state) => state.parentDeliverables.entities,
  (state) => state.batches,
  (state) => state.stages,
  (
    workflowId,
    projectDeliverables,
    deadlines,
    parentDeliverableEntities,
    batches,
    stages
  ) => {
    const batchOverview = batches.result.reduce((acc, batchId) => {
      const { batchName } = batches.entities[batchId];
      const batch = { batchId: Number(batchId), batchName };

      stages.result.forEach((stageId) => {
        if (stages.entities[stageId].workflowId === workflowId) {
          const deadline = deadlines[batchId] && deadlines[batchId][stageId];
          batch[stageId] = { total: 0, deadline };
        }
      });

      acc[batchId] = batch;
      return acc;
    }, {});

    // Total up deliverables
    projectDeliverables.forEach(({ currentStageId, parentDeliverableId }) => {
      const { batchId } = parentDeliverableEntities[parentDeliverableId];
      const { stageType } = stages.entities[currentStageId];
      const batch = batchOverview[batchId];

      if (stageType === "Production") {
        if (!batch[stageType]) batch[stageType] = { total: 0 };
        batch[stageType].total += 1;
      } else if (batch[currentStageId]) {
        batch[currentStageId].total += 1;
      }
    });

    return Object.values(batchOverview);
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { projectId },
    },
  } = ownProps;
  const project = state.projects.entities[projectId];
  const nonProductionStages = nonProductionStageSelector(
    state,
    project.workflowId
  );
  const batchOverview = batchDeliverablesSelector(
    state,
    project.projectId,
    project.workflowId
  );

  return { project, batchOverview, stages: nonProductionStages };
};

const ClientProjectDashboardOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Overview));
ClientProjectDashboardOverview.getInitialData = getInitialData;
export default ClientProjectDashboardOverview;
