import querystring from "querystring";

// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

export const updateFetch = (input, query = "") => {
  const { token } = querystring.parse(query.substr(1));
  return (dispatch, getState) => {
    const password = input.password || "";
    const params = { token, password };
    return fetch(`${window.__API_GATEWAY__}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  };
};
