import { getGraphQL } from "../../../../../utils/fetch";
import {
  accountsQuery,
  fetchAccountsSuccess,
} from "../../../../modules/accounts";
import {
  orderFormsQuery,
  fetchOrderFormsSuccess,
} from "../../../../modules/orderForms";
import {
  projectsQuery,
  fetchProjectsSuccess,
} from "../../../../modules/projects";

export const initialQuery = `query initialQuery {
  ${accountsQuery}
  ${orderFormsQuery}
  ${projectsQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchProjectsSuccess(json.projects));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchAccountsSuccess(json.accounts));
    });
  };
}
