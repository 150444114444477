import React, { useRef } from "react";
import styles from "./WritingAidDecorator.module.scss";

export const CORRECTION_STYLE_PREFIX = "CORRECTION-";

/**
 * Extract a correction id from an inline style name
 * (i.e. 'COMMENT-1234' => 1234)
 *
 * @param   {string}  styleName
 * @returns {number}  correctionId, or null if it is not a correction style
 */
function parseCorrection(styleName) {
  if (
    styleName.substring(0, CORRECTION_STYLE_PREFIX.length) ===
    CORRECTION_STYLE_PREFIX
  ) {
    return Number(styleName.substring(CORRECTION_STYLE_PREFIX.length));
  }

  return null;
}
const writingAidStrategy = (contentBlock, callback) => {
  contentBlock.findStyleRanges(({ style }) => {
    return style.some((name) => {
      if (name.includes("CORRECTION")) {
        return { name };
      }

      return null;
    });
  }, callback);
};

const WritingAidDecorator = (props) => {
  const { openSuggestionBox } = props;
  const ref = useRef();

  const getGrammarSuggestions = () => {
    const { corrections, contentState, children, offsetKey } = props;
    const [blockKey] = offsetKey.split("-");
    const block = contentState.getBlockForKey(blockKey);
    const { start, text } = children[0].props;

    const correctionId = block
      .getInlineStyleAt(start)
      .toArray()
      .map((styleName) => parseCorrection(styleName))
      .pop();

    const suggestion = corrections?.[blockKey]?.find(
      ({ offset }) => offset === correctionId
    );

    return { suggestion, text, start };
  };

  const onClick = () => {
    const { suggestion, text, start } = getGrammarSuggestions();

    const positionRect = ref.current.getBoundingClientRect();

    if (typeof window !== "undefined") {
      positionRect.y += window.pageYOffset;
    }

    openSuggestionBox(positionRect, suggestion, text, start);
  };

  return (
    <span ref={ref} onClick={onClick} className={styles.suggestion}>
      {props.children}
    </span>
  );
};

export default (corrections, openSuggestionBox) => ({
  component: WritingAidDecorator,
  props: { corrections, openSuggestionBox },
  strategy: writingAidStrategy,
});
