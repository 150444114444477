import { getGraphQL } from "../../../../../utils/fetch";
import {
  assignmentsByAssignmentGroupQuery,
  fetchAssignmentsSuccess,
} from "../../../../modules/assignments";
import {
  projectsByAssignmentGroupQuery,
  fetchProjectsSuccess,
} from "../../../../modules/projects";

export const deliverablesQuery = `
  query deliverablesByAssignmentGroup (
    $assignmentGroupId: String,
    $includeArchivedAssignments: Boolean
  ) {
    ${assignmentsByAssignmentGroupQuery}
    ${projectsByAssignmentGroupQuery}
  }
`;

export function getInitialData(params) {
  return async (dispatch) => {
    const json = await getGraphQL(deliverablesQuery, params);
    const { assignments, projects } = json;

    dispatch(fetchAssignmentsSuccess(assignments));
    dispatch(fetchProjectsSuccess(projects));
  };
}
