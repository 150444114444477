import { getLSItem } from "./localStorage";

export const buildTaskListUrl = (taskUrl) => {
  if (taskUrl.indexOf("stage") > -1) {
    const language = getLSItem("task-list-language-code");
    taskUrl += `?language=${language}`;
  }
  return taskUrl;
};

/**
 * Convert an object of options to a query string
 *
 * @param   {Object.<string, string>} options key: values
 * @returns {string} query string (without the ? prefix)
 */
//

export function createQueryString(options = {}) {
  return Object.entries(options)
    .filter(([_, value]) => typeof value !== "undefined")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}
