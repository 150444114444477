import React from "react";
import styles from "./TableHeaders.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import Dropdown from "../../../../../../../../components/Dropdown/v4";
import parseQuery from "../../../../../../../../utils/parseQuery";
import qs from "query-string";

export const TableHeaders = (props) => {
  const { clientStages, batches, location, history } = props;
  const clientStagesLength = clientStages.length;
  const tableHeaders = classNames({
    [styles.tableHeaders]: true,
    [styles.threeInReview]: clientStagesLength === 3,
    [styles.fourInReview]: clientStagesLength === 4,
    [styles.sixInReview]: clientStagesLength === 6,
  });

  const stages = clientStages.map(({ stageName }, i) => (
    <div key={i}>{stageName}</div>
  ));

  const handleQuery = (query, term, value) => {
    if (value) {
      return { ...query, [term]: value };
    }
    const { [term]: omit, ...queryToSend } = query;
    return queryToSend;
  };

  const updateQuerySearch = (term, value) => {
    const query = handleQuery(parseQuery(location.search), term, value);

    const search = qs.stringify(query);

    // only push a new history state if the params are different
    if (location.search !== `?${search}`) {
      history.push({ search });
    }
  };

  const filterBatch = (batchId, updateQueryString = true) => {
    if (updateQueryString) updateQuerySearch("batchId", batchId);
  };

  const dropdownBatches = batches.reduce((acc, { batchId, batchName }) => {
    acc[batchId] = batchName;
    return acc;
  }, {});

  return (
    <div>
      <div className={tableHeaders}>
        <div>
          <span>
            <Dropdown
              filterText={"filter by batch name"}
              onClick={filterBatch}
              options={dropdownBatches}
              title={"Batches"}
              mainButtonStyles={styles.batchDropdown}
            />
          </span>
        </div>
        <div className={styles.inProduction}>In production</div>
        <div className={styles.inAmends}>In amends</div>
        {stages}
        <div className={styles.approved}>Approved</div>
        <div>Total</div>
        <div>Completion</div>
      </div>
    </div>
  );
};

TableHeaders.propTypes = {
  clientStages: PropTypes.array.isRequired,
};

export default TableHeaders;
