import React from "react";
import PropTypes from "prop-types";

import { reduxForm } from "redux-form";
import {
  InputWithErrors,
  SelectWithErrors,
} from "../../../../../decorators/WithErrors";
import { QuillForm, QuillFormField } from "../../../../../components/QuillForm";

import validate from "./validate";
import { verticalTypes } from "../../../../../modules/accounts";

const AccountForm = (props) => {
  return (
    <QuillForm
      header={props.header}
      cancelText="Cancel"
      cancelHandler={props.hideModal}
      submitText="Save"
      disabled={props.submitting || !props.valid}
      handleSubmit={props.handleSubmit}
      error={props.error}
    >
      <QuillFormField
        name="accountName"
        labelText="Account name"
        required
        component={InputWithErrors}
        type="text"
        containerWidth="100%"
      />

      <QuillFormField
        name="verticalType"
        labelText="Account vertical"
        component={SelectWithErrors}
        type="text"
        options={verticalTypes.map((type) => ({ value: type, label: type }))}
      />
    </QuillForm>
  );
};

AccountForm.propTypes = {
  header: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "account",
  validate,
})(AccountForm);
