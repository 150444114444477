import { connect } from "react-redux";
import BulkQA from "../components/BulkQA.js";
import WithData from "../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { tfcByDeliverables } from "../../../../modules/taskFieldContent";
import {
  assignmentsByGroupSelector,
  projectById,
  stageById,
} from "../../../../utils/entitySelector";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
  };
};

const mapStateToProps = (state, { match: { params }, location }) => {
  const tfcByDeliverable = tfcByDeliverables(state, params.assignmentGroupId);
  const listUrl = `/admin/projects/${params.projectId}/assignments/${params.assignmentGroupId}`;
  const assignments = assignmentsByGroupSelector(
    state,
    Number(params.assignmentGroupId)
  );

  const [assignment = {}] = assignments;
  const { stageId } = assignment;

  const project = projectById(state, params.projectId);
  const stage = stageById(state, stageId);

  return {
    listUrl,
    project,
    tfcByDeliverable,
    stage,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithData(BulkQA));
