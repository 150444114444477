import React from "react";
import styles from "./TableTitles.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

export const TableTitles = (props) => {
  const { clientStagesLength } = props;
  const tableTitles = classNames({
    [styles.tableTitles]: true,
    [styles.threeInReview]: clientStagesLength === 3,
    [styles.fourInReview]: clientStagesLength === 4,
    [styles.sixInReview]: clientStagesLength === 6,
  });

  const withClient = classNames({
    [styles.withClient]: true,
    [styles.threeInReview]: clientStagesLength === 3,
    [styles.fourInReview]: clientStagesLength === 4,
    [styles.sixInReview]: clientStagesLength === 6,
  });

  return (
    <div>
      <div className={tableTitles}>
        <span className={styles.withQuill}>With Jellyfish</span>
        <span className={withClient}>With you for review</span>
      </div>
    </div>
  );
};

TableTitles.propTypes = {
  clientStagesLength: PropTypes.number,
};

export default TableTitles;
