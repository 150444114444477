import styles from "./ProjectInfo.module.scss";
import { Link } from "react-router-dom";

type PropTypes = {
  url?: string;
  projectId?: number;
};

const ProjectInfo = ({ url, projectId }: PropTypes) => {
  const linkUrl = url || `/freelancer/projects/${projectId}/project-info`;

  return (
    <Link className={styles.projectInfoButton} to={linkUrl}>
      Project information
    </Link>
  );
};

export default ProjectInfo;
