import React from "react";
import PropTypes from "prop-types";
import styles from "./NestedProjectRow.module.scss";
import { Link } from "react-router-dom";
import Icon from "../../../Icon";

const NestedProjectRow = (props) => {
  const projectRowStyles = props.admin
    ? styles.adminProjectRow
    : styles.clientProjectRow;

  const handleRestore = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.restore({ projectId: props.project.projectId });
  };

  const handleHide = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.hide({ projectId: props.project.projectId });
  };

  const handleFavourite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!props.isFavourite && !props.favouriteComponent) {
      props.favourite({ projectId: props.project.projectId });
    } else {
      props.unfavourite({ projectId: props.project.projectId });
    }
  };

  const favoriteFill = props.isFavourite ? "#DA0034" : "#FFF";
  const favoriteStroke = props.isFavourite ? "" : "#DA0034";

  return (
    <Link to={props.projectUrl} className={projectRowStyles}>
      <span>{props.project.projectName}</span>
      {props.admin && (
        <>
          {props.favouriteComponent && props.favouritesEnabled && (
            <Icon
              name="FavoritesDashboard"
              onClick={handleFavourite}
              className={styles.favourite}
              size="md"
              fill={"#FFFF00"}
            />
          )}

          {!props.archivedComponent && !props.favouriteComponent ? (
            <div>
              <span onClick={handleHide} className={styles.restore}>
                Hide
                <span className={styles.linkSeparator} />
              </span>
              <Icon
                name="FavoritesDashboard"
                onClick={handleFavourite}
                className={styles.favourite}
                size="md"
                fill={favoriteFill}
                stroke={favoriteStroke}
              />
            </div>
          ) : (
            props.restoreEnabled &&
            !props.favouriteComponent && (
              <span onClick={handleRestore} className={styles.restore}>
                Restore
              </span>
            )
          )}
        </>
      )}
    </Link>
  );
};

NestedProjectRow.propTypes = {
  admin: PropTypes.bool,
  projectUrl: PropTypes.string.isRequired,
  project: PropTypes.shape({
    projectName: PropTypes.string,
  }),
};

export default NestedProjectRow;
