import React from "react";

const BriefingFieldRowCell = ({
  text,
  expandingCell = false,
  cellStyle,
  expandingStyle,
  insideStyle,
  labelStyle,
}) => {
  return (
    <div className={`${cellStyle} ${expandingCell ? expandingStyle : ""}`}>
      <div className={`${insideStyle} ${labelStyle}`}>{text}</div>
    </div>
  );
};

export default BriefingFieldRowCell;
