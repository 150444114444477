import React, { Component } from "react";
import PropTypes from "prop-types";

class PaymentRow extends Component {
  linkToAssignment = () => {
    const {
      payment: { assignmentId, assignmentGroupId, projectId },
    } = this.props;
    this.props.history.push(
      `/freelancer/projects/${projectId}/assignments/${assignmentGroupId}/tasks/${assignmentId}`
    );
  };

  render() {
    const { payment } = this.props;

    return (
      <tr
        key={payment.paymentId}
        onClick={this.linkToAssignment}
        className={"paymentRow"}
      >
        <td data-label="Account name" className={"accountName"}>
          {payment.accountName}
        </td>
        <td data-label="Project name" className={"projectName"}>
          {payment.projectName}
        </td>
        <td data-label="Task" className={"task"}>
          {payment.paymentType === "AD_HOC" ? payment.task : payment.stageName}
        </td>
        <td data-label="Payment approval" className={"createDate"}>
          {payment.createDate}
        </td>
        <td data-label="Assignment ID" className={"assignmentId"}>
          {payment.assignmentId || "-"}
        </td>
        <td data-label="Batch name" className={"batchName"}>
          {payment.batchName || "-"}
        </td>
        <td data-label="Rate" className={"rate"}>
          {Number(payment.rate).toFixed(2)}
        </td>

        {this.props.featureToggles.QCC_1298_showVatAmount && (
          <td data-label="VAT amount" className={"vatAmount"}>
            {Number(payment.vatAmount).toFixed(2)}
          </td>
        )}

        {this.props.featureToggles.QCC_1298_showVatAmount && (
          <td data-label="Gross amount" className={"grossAmount"}>
            {Number(payment.grossAmount).toFixed(2)}
          </td>
        )}

        <td data-label="Notes" className={"notes"}>
          {payment.notes || "-"}
        </td>
      </tr>
    );
  }
}

PaymentRow.propTypes = {
  payment: PropTypes.shape({
    paymentId: PropTypes.number.isRequired,
    accountName: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    stageName: PropTypes.string,
    rate: PropTypes.number.isRequired,
    payDate: PropTypes.string,
    assignmentId: PropTypes.number,
    notes: PropTypes.string,
    task: PropTypes.string,
    paymentType: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  featureToggles: PropTypes.object.isRequired,
};

export default PaymentRow;
