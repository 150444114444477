import { upsertData, removeData } from "../utils/normalize";
import { createAction } from "redux-actions";
import { SubmissionError } from "redux-form";
import handleErrors from "../utils/handleErrors";
import { postGraphQL, getGraphQL } from "../../utils/fetch";
import { splitArrayObjects } from "../utils/splitItems";
import { filterObject } from "../utils/objects";
import { nullObject } from "../utils/nullObjects";
import {
  createTaskFieldsSuccess,
  updateTaskFieldsSuccess,
  removeTaskFieldsSuccess,
} from "./taskFields";
import {
  createBriefingFieldsSuccess,
  updateBriefingFieldsSuccess,
  removeBriefingFieldsSuccess,
} from "./briefingFields";
import {
  createKeywordGroupsSuccess,
  updateKeywordGroupsSuccess,
  removeKeywordGroupsSuccess,
} from "./keywordGroups";
import { hideModal } from "../modules/modal";
import { createProjectLanguagesSuccess } from "./projectLanguages";
import { createProjectTeamsSuccess } from "./projectTeams";

import {
  projectTaskFieldsSelector,
  projectBriefingFieldsSelector,
  projectKeywordGroupsSelector,
  filterForCreated,
  individualSelector,
} from "../utils/entitySelector";

import { RESET_INITIAL_STATE } from "./me";

const writableFields = [
  "briefLink",
  "contentTypeId",
  "editableClientStages",
  "orderFormId",
  "projectId",
  "projectName",
  "sourceLanguage",
  "workflowId",
  "currencyCode",
  "quoting",
  "clientBatchNotifications",
  "clientCodeFinancialForce",
  "projectIdFinancialForce",
];
const readOnlyFields = [
  "accountId",
  "accountName",
  "currencyCode",
  "isProjectTM",
  "orderFormId",
  "orderFormName",
  "orderFormNumber",
  "parentAccountName",
  "verticalType",
];
export const projectFields = [...writableFields, ...readOnlyFields];

// ------------------------------------
// GraphQL Queries
// ------------------------------------

const projectsByParam = (param) =>
  `projects (${param}: $${param}) { ${projectFields} }`;

export const projectsQuery = `projects { ${projectFields} }`;
export const projectQuery = `project (projectId: $projectId) { ${projectFields} }`;
export const projectByProjectIdQuery = `project (projectId: $projectId) { ${projectFields} }`;
export const projectsByAccountIdQuery = `projects (accountId: $accountId) { ${projectFields} }`;
export const projectsByOrderFormIdQuery = projectsByParam("orderFormId");
export const projectsByAssignmentGroupQuery =
  projectsByParam("assignmentGroupId");
export const projectsByAssignmentQuery = projectsByParam("assignmentId");
export const projectsByParentAccountQuery = projectsByParam("parentAccountId");

export const projectsByParentAccountIdsQuery = `projects(parentAccountIds: $parentAccountId) {
  ${projectFields}
}`;

export const projectsByProjectIds = `projects (projectIds: $projectIds) {
  ${projectFields}
}`;

// ------------------------------------
// Constants
// ------------------------------------
const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
const ARCHIVE_PROJECT_SUCCESS = "ARCHIVE_PROJECT_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchProjectSuccess = createAction(FETCH_PROJECT_SUCCESS);
export const fetchProjectsSuccess = createAction(FETCH_PROJECTS_SUCCESS);
export const createProjectSuccess = createAction(CREATE_PROJECT_SUCCESS);
export const updateProjectSuccess = createAction(UPDATE_PROJECT_SUCCESS);
export const archiveProjectSuccess = createAction(ARCHIVE_PROJECT_SUCCESS);

/**
 * Retrieve a project by its id. If it's not in the store, fetch the data
 * and add to the store before returning
 *
 * @param   {number|string} projectId
 * @returns {Object}        project
 */
export function fetchProjectById(projectId) {
  return async (dispatch, getState) => {
    const project = projectById(getState(), projectId);
    if (project !== nullObject) return project;

    const { project: projectData } = await getGraphQL(
      `query FetchProjectById ($projectId: String) {
        ${projectQuery}
      }`,
      { projectId: String(projectId) }
    );

    dispatch(fetchProjectSuccess(projectData));
    return projectData;
  };
}

export function createProject(data, history) {
  const baseUrl = `/admin/projects`;

  return async (dispatch) => {
    const {
      productionTeam = [],
      clientTeam = [],
      projectLanguages,
      ...project
    } = data;

    const compositeProjectData = {
      project: filterObject(project, writableFields),
      projectLanguages,
      projectTeams: [...productionTeam, ...clientTeam],
    };

    const query = `mutation createProject($compositeProjectData: CompositeProjectInput) {
      createProject(compositeProjectData: $compositeProjectData) {
        project { ${writableFields} },
        projectLanguages { projectId, languageCode },
        projectTeams { projectId, personId }
      }
    }`;

    try {
      const json = await postGraphQL(
        query,
        { compositeProjectData },
        "createProject"
      );
      dispatch(createProjectSuccess(json.project));
      dispatch(createProjectLanguagesSuccess(json.projectLanguages));
      dispatch(
        createProjectTeamsSuccess(
          json.projectTeams.length ? json.projectTeams : json.project.projectId
        )
      );

      history.push({
        pathname: `${baseUrl}/${json.project.projectId}/rates`,
        state: { projectCreation: true },
      });
    } catch (err: any) {
      handleErrors(err);
    }
  };
}

export function updateProject(data, history) {
  return async (dispatch, getState) => {
    const { projectLanguages, ...rest } = data;
    const { productionTeam, clientTeam, ...project } = rest;
    const { projectLanguages: currentLanguages } = getState();

    // only send new project languages
    const newLanguages = projectLanguages.filter(
      (code) =>
        !currentLanguages.some(
          ({ projectId, languageCode }) =>
            projectId === project.projectId && languageCode === code
        )
    );

    const compositeProjectData = {
      project: filterObject(project, writableFields),
      projectLanguages: newLanguages,
      projectTeams: [...productionTeam, ...clientTeam],
    };

    const query = `mutation updateProject($compositeProjectData: CompositeProjectInput){
      updateProject(compositeProjectData: $compositeProjectData) {
        project { ${writableFields} },
        projectLanguages { projectId, languageCode },
        projectTeams { projectId, personId }
      }
    }`;

    try {
      const json = await postGraphQL(
        query,
        { compositeProjectData },
        "updateProject"
      );
      dispatch(updateProjectSuccess(project));
      dispatch(createProjectLanguagesSuccess(json.projectLanguages));
      dispatch(
        createProjectTeamsSuccess(
          json.projectTeams.length ? json.projectTeams : json.project.projectId
        )
      );

      history.push(`/admin/projects/${json.project.projectId}`);
    } catch (err: any) {
      handleErrors(err);
    }
  };
}

export function archiveProject(projectId, history) {
  return (dispatch, getState) => {
    const query = `mutation archiveProject($input: ProjectInput){
      archiveProject(input: $input) {
        projectId
      }
    }`;

    return postGraphQL(query, { input: { projectId } }, "archiveProject")
      .then((json) => {
        history.push("/admin");
        dispatch(archiveProjectSuccess(projectId));
        dispatch(hideModal());
      })
      .catch((err) => {
        // TODO: show a error message somewhere
        console.log("Error archiving project", err.errors, err.message);
      });
  };
}

export function updateFields(data, history, params, location) {
  const projectCreation = location.state
    ? location.state.projectCreation
    : false;

  // hacky way to combine react-multi-select (needs {label, value})
  // and redux best practice (storing just ids, instead of the whole object)
  const taskFields = data.taskFields.map((obj, idx) => ({
    ...obj,
    taskFieldPosition: idx,
    rateBands: (obj.rateBands || []).map((v) => v.value),
  }));

  const dataWithPositions = {
    briefingFields: data.briefingFields.map((obj, idx) => ({
      ...obj,
      briefingFieldPosition: idx,
    })),
    taskFields,
    keywordGroups: data.keywordGroups.map((obj, idx) => ({
      ...obj,
      keywordGroupPosition: idx,
    })),
  };

  const projectId = Number(params.projectId);

  return (dispatch, getState) => {
    const state = getState();

    const projectBriefingFields = projectBriefingFieldsSelector(
      state,
      // @ts-ignore
      projectId
    );
    const projectKeywordGroups = projectKeywordGroupsSelector(
      state,
      // @ts-ignore
      projectId
    );
    const projectTaskFields = projectTaskFieldsSelector(
      state,
      // @ts-ignore
      projectId
    );

    const [removeBFItems, createBFItems, updateBFItems] = splitArrayObjects(
      projectBriefingFields,
      dataWithPositions.briefingFields,
      "briefingFieldId"
    );
    const [removeKGItems, createKGItems, updateKGItems] = splitArrayObjects(
      projectKeywordGroups,
      dataWithPositions.keywordGroups,
      "keywordGroupId"
    );
    const [removeTFItems, createTFItems, updateTFItems] = splitArrayObjects(
      projectTaskFields,
      dataWithPositions.taskFields,
      "taskFieldId"
    );

    const briefingFields = removeBFItems
      .map((item) => ({ ...item, archived: true }))
      .concat(createBFItems)
      .concat(updateBFItems)
      .map((item) => ({ ...item, projectId }));

    const keywordGroups = removeKGItems
      .map((item) => ({ ...item, archived: true }))
      .concat(createKGItems)
      .concat(updateKGItems)
      .map((item) => ({ ...item, projectId }));

    const taskFields = removeTFItems
      .map((item) => ({ ...item, archived: true }))
      .concat(createTFItems)
      .concat(updateTFItems)
      .map((item) => {
        const copy = { ...item, projectId };
        Object.keys(copy).forEach((key) => {
          if (copy[key] === null || copy[key] === "") delete copy[key];
        });
        return copy;
      });

    // If the keywordGroupName is empty then the keyword group should be archived
    keywordGroups.forEach((i) => {
      if (i.keywordGroupName === "") {
        i.archived = true;
      }
    });

    // Setup query args and body
    let queryArgs = `$briefingFields: [BriefingFieldInput], $taskFields: [TaskFieldInput], $projectId: Int`;
    let queryBody = `updateBriefingFields(associations: $briefingFields, projectId: $projectId) {
        briefingFieldId, projectId, briefingFieldName, briefingFieldPosition,
        briefingFieldFormat
    }
    updateTaskFields(associations: $taskFields, projectId: $projectId) {
      taskFieldId, projectId, taskFieldName, taskFieldFormat,
      taskFieldPosition, qualityCheck, minWords, maxWords,
      minCharacters, maxCharacters
    }`;
    const queryObj: any = { projectId, briefingFields, taskFields };

    // If there are keywordGroups then add them into the query
    if (keywordGroups.length !== 0) {
      queryArgs += `, $keywordGroups: [KeywordGroupInput]`;
      queryBody += `updateKeywordGroups(associations: $keywordGroups, projectId: $projectId) {
        keywordGroupId, projectId, keywordGroupName
      }`;
      queryObj.keywordGroups = keywordGroups;
    }

    // Build the final query
    const finalQuery = `mutation updateFields(${queryArgs}){
      ${queryBody}
    }`;

    return postGraphQL(finalQuery, queryObj)
      .then((json) => {
        if (keywordGroups.length !== 0) {
          const createdKGItems = filterForCreated(
            json.updateKeywordGroups,
            updateKGItems,
            "keywordGroupId"
          );
          dispatch(createKeywordGroupsSuccess(createdKGItems));
          dispatch(updateKeywordGroupsSuccess(updateKGItems));
          dispatch(removeKeywordGroupsSuccess(removeKGItems));
        }

        // we need to filter out what was updated (which wont include what was removed)
        const createdBFItems = filterForCreated(
          json.updateBriefingFields,
          updateBFItems,
          "briefingFieldId"
        );
        const createdTFItems = filterForCreated(
          json.updateTaskFields,
          updateTFItems,
          "taskFieldId"
        );

        dispatch(createBriefingFieldsSuccess(createdBFItems));
        dispatch(updateBriefingFieldsSuccess(updateBFItems));
        dispatch(removeBriefingFieldsSuccess(removeBFItems));
        dispatch(createTaskFieldsSuccess(createdTFItems));
        dispatch(updateTaskFieldsSuccess(updateTFItems));
        dispatch(removeTaskFieldsSuccess(removeTFItems));

        if (projectCreation) {
          history.push({
            pathname: `/admin/projects/${projectId}/default-assignees`,
            state: { projectCreation: true },
          });
        } else {
          history.push(`/admin/projects/${projectId}`);
        }
      })
      .catch((err) => {
        // TODO: handle errors!!
        console.log("Error creating fields", err.errors, err.message);
        if (err.errors) throw new SubmissionError({ _error: err.message });
        throw new SubmissionError({
          _error:
            "There was an error connecting to the server. Please try again later.",
        });
      });
  };
}

export function copyProject({ accountId, ...data }) {
  return async (dispatch) => {
    const fields = data.fields.map(({ value }) => value);
    const input = {
      ...data,
      fields,
    };

    const query = `mutation copyProject ($input: CopyProjectInput) {
      copyProject (input: $input) {
        project { ${writableFields} } 
      }
    }`;
    try {
      const {
        copyProject: { project },
      } = await postGraphQL(query, { input });
      dispatch(createProjectSuccess(project));
      dispatch(hideModal());
    } catch (err: any) {
      handleErrors(err);
    }
  };
}

export function fetchProjectsByOrderFormId(orderFormId: number | string) {
  const query = `query ($orderFormId: String){
    ${projectsByOrderFormIdQuery}
  }`;

  return async (dispatch: any) => {
    const json = await getGraphQL(query, { orderFormId });
    return dispatch(fetchProjectsSuccess(json.projects));
  };
}

export function fetchProjectsByAccountId(accountId) {
  return async (dispatch) => {
    try {
      const params = { accountId };

      const query = `query fetchProjectsByAccountId ($accountId: Int) {
        ${projectsByAccountIdQuery}
      }`;

      const { projects } = await getGraphQL(query, params);

      dispatch(fetchProjectSuccess(projects));
    } catch (err) {
      console.log(err);
    }
  };
}

export type ProjectType = {
  projectId: number;
  briefLink: string;
  sourceLanguage?: string;
  orderFormId: number;
  contentTypeId: number;
  workflowId: number;
  parentAccountName?: string;
};
type ProjectByIdType = (state: any, projectId: number) => ProjectType;

export const projectById: ProjectByIdType = individualSelector("projects");

// ------------------------------------
// Action Handlers
// ------------------------------------
export const projectActionHandlers = {
  [RESET_INITIAL_STATE]: () => projectInitialState,
  [FETCH_PROJECT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "projectId"),
  [FETCH_PROJECTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "projectId"),
  [CREATE_PROJECT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "projectId"),
  [UPDATE_PROJECT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "projectId"),
  [ARCHIVE_PROJECT_SUCCESS]: (state, { payload }) => removeData(state, payload),
};

export const projectInitialState = { entities: {}, result: [] };
