import React from "react";
import PropTypes from "prop-types";
import styles from "./QuillContainer.module.scss";
import QuillHeader from "../../components/QuillHeader";
import classNames from "classnames";

const QuillContainer = ({
  formContainerStyles,
  rebrandingContainer,
  hideRequiredHeader,
  header,
  children,
}) => {
  const containerStyles = classNames({
    [formContainerStyles]: formContainerStyles,
    [styles.rebrandingContainer]: rebrandingContainer,
  });

  const innerContainerStyles = classNames({
    [styles.rebrandingInnerContainer]: rebrandingContainer,
  });

  return (
    <div className={containerStyles}>
      <QuillHeader hideRequiredHeader={hideRequiredHeader} header={header} />
      <div className={innerContainerStyles}>{children}</div>
    </div>
  );
};

QuillContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  header: PropTypes.string,
  hideRequiredHeader: PropTypes.bool,
};

export default QuillContainer;
