export const parentAccountErrors = {
  parent_account_name: "Sorry a parentAccount with this name already exists.",
};

export const accountErrors = {
  unique_account_name_in_parent_account:
    "Sorry an account by this name already exists for this parent account.",
};

export const orderFormErrors = {
  order_form_number: "Sorry an order with this number already exists.",
  unique_of_name_in_account:
    "Sorry an order with this name already exists for this account.",
};

export const projectErrors = {
  unique_project_name_in_order_form:
    "Sorry a project with this name already exists for this order.",
};

export const extractErrorFromObject = (errorsObj) => {
  const { errors } = errorsObj;

  if (errors && errors.length) {
    return errors.map((e) => {
      if (e.message) return e.message;
      return e;
    });
  }

  return errorsObj;
};
