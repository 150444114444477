import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";

export const aiEnhancementQuery = `
  aiEnhancement {
    aiEnhancementId
    jobId
    projectName
    fullName
    accountName
    createDate
    status
    fileName
  }
`;

export const FETCH_AI_ENHANCEMENT_SUCCESS = "FETCH_AI_ENHANCEMENT_SUCCESS";
export const fetchAiEnhancementSuccess = createAction(
  FETCH_AI_ENHANCEMENT_SUCCESS
);

export const AiEnhancementHandler = {
  [RESET_INITIAL_STATE]: () => aiEnhancementInitialState,
  [FETCH_AI_ENHANCEMENT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "aiEnhancementId"),
};

export const aiEnhancementInitialState = { entities: {}, result: [] };
