import styles from "./Archive.module.scss";
import PropTypes from "prop-types";

const Archive = ({ archive, title, id }) => {
  const handleClick = () => archive(id);

  return (
    <div className={styles.modalConfirm} onClick={handleClick}>
      <span>{title}</span>
    </div>
  );
};

Archive.propTypes = {
  archive: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default Archive;
