import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import AssignmentsList from "../components/AssignmentsList";
import WithData from "../../../../../../../../decorators/WithData";
import { createSelector } from "reselect";
import { saveLocalContentToDBAndCreateTransitionLogs } from "../../../../../../../../modules/taskFieldContent";

import {
  assignmentsByGroupSelector,
  priorAndNextTransitionsSelector,
  projectBriefingFieldsSelector,
  briefingFieldValuesSelector,
  personById,
  assignmentGroupParentDeliverableEntitiesSelector,
} from "../../../../../../../../utils/entitySelector";

const dataSelector = createSelector(
  (state, assignments, briefingFieldValues) => assignments,
  (state, assignments, briefingFieldValues) => briefingFieldValues,
  (state, assignments, briefingFieldValues) => state.deliverables.entities,
  (assignments, briefingFieldValues, deliverableEntities) => {
    const data = [];

    assignments.forEach(
      ({
        assignmentId,
        deliverableId,
        previousAssignee,
        rate,
        stageId,
        status,
        wasAmended,
      }) => {
        if (!deliverableEntities[deliverableId]) return;

        const { currentStageId, parentDeliverableId } =
          deliverableEntities[deliverableId];

        // actionable is whether a transition can be made on the assignment
        const actionable =
          (status === "Accepted" || status === "Amends requested") &&
          currentStageId === stageId;

        // an assignment is selectable if the status is accepted/amends requested/not actioned/rejected
        const selectable = [
          "Not Actioned",
          "Accepted",
          "Amends requested",
          "Rejected",
        ].includes(status);

        data.push({
          actionable,
          assignmentId,
          briefingFieldValues: briefingFieldValues[parentDeliverableId],
          deliverableId,
          id: assignmentId,
          previousAssignee,
          rate,
          selectable,
          status,
          viewable: true,
          wasAmended,
        });
      }
    );
    return data;
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    saveLocalContentToDBAndCreateTransitionLogs: async (
      transition,
      assignmentIds,
      callbackObj
    ) => {
      const transitionLogsResponse = await dispatch(
        saveLocalContentToDBAndCreateTransitionLogs(
          transition,
          assignmentIds,
          ownProps.match.params,
          callbackObj
        )
      );

      return transitionLogsResponse;
    },
  };
};

const mapStateToProps = (state, { match: { params } }) => {
  const { projectId } = params;
  const assignmentGroupId = Number(params.assignmentGroupId);

  const assignments = assignmentsByGroupSelector(state, assignmentGroupId);
  const [assignment = {}] = assignments;
  const { stageId, deliverableId, personId } = assignment;

  const person = personById(state, personId);

  const project = state.projects.entities[projectId] || {};
  const { workflowId } = project || {};

  const briefingFields = projectBriefingFieldsSelector(
    state,
    project.projectId
  );

  const assignmentGroupParentDeliverableEntities =
    assignmentGroupParentDeliverableEntitiesSelector(state, assignments);
  const briefingFieldValues = briefingFieldValuesSelector(
    state,
    assignmentGroupParentDeliverableEntities,
    briefingFields
  );
  const data = dataSelector(state, assignments, briefingFieldValues);

  const transitions = priorAndNextTransitionsSelector(
    state,
    stageId,
    deliverableId,
    workflowId
  );

  return {
    assignmentGroupId,
    briefingFields,
    data,
    errors: state.errors,
    personName: person ? `${person.firstName} ${person.lastName}` : "",
    project,
    transitions,
  };
};

const InHouseAssignmentsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AssignmentsList));
InHouseAssignmentsList.getInitialData = getInitialData;
export default InHouseAssignmentsList;
