import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import Modal from "../../../../../../../components/Modal";
import styles from "./RatesForm.module.scss";
import RateBandsForm from "../RateBandsForm";
import LanguageRates from "./LanguageRates";
import CurrencyDisplay from "../../../../../../../components/CurrencyDisplay";

let RatesForm = (props) => {
  const {
    match,
    modal,
    stages,
    projectCreation,
    projectId,
    projectLanguages,
    rateBands,
    showModal,
    handleSubmit,
    submitting,
    valid,
    pristine,
    rateBandVolumes,
  } = props;

  return (
    <div>
      {modal.display && (
        <Modal
          body={<RateBandsForm hideModal={props.hideModal} match={match} />}
          display={modal.display}
          large
          medium
        />
      )}

      <form onSubmit={handleSubmit}>
        <div className={styles.scrollTable}>
          <table className={styles.ratesTable}>
            <thead>
              <tr>
                <td>Band</td>
                {stages.map(({ stageName, stageId }) => (
                  <td key={stageId}>
                    {stageName} (<CurrencyDisplay projectId={projectId} />)
                  </td>
                ))}
                <td>Quantity scoped</td>
              </tr>
            </thead>

            <tbody>
              {projectLanguages.map(({ languageName, languageCode }) => (
                <LanguageRates
                  key={languageCode}
                  languageCode={languageCode}
                  languageName={languageName}
                  projectId={projectId}
                  rateBands={rateBands}
                  stages={stages}
                  submitting={submitting}
                  rateBandVolume={rateBandVolumes}
                />
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles.buttonGroupContainer}>
          {projectCreation && (
            <div className={styles.buttonGroupLeft}>
              <Link
                to={{
                  pathname: `${props.baseUrl}/default-assignees`,
                  state: { projectCreation: true },
                }}
              >
                <button className={styles.buttonSecondary} type="button">
                  Skip
                </button>
              </Link>
            </div>
          )}
          <div className={styles.buttonGroupRight}>
            <button
              className={styles.button}
              disabled={submitting || !valid}
              onClick={showModal}
              type="button"
            >
              Edit bands
            </button>

            <button
              className={styles.button}
              disabled={submitting || !valid || pristine}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

RatesForm.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  match: PropTypes.object,
  modal: PropTypes.shape({
    display: PropTypes.bool,
  }),
  pristine: PropTypes.bool.isRequired,
  projectCreation: PropTypes.bool,
  projectId: PropTypes.number.isRequired,
  projectLanguages: PropTypes.array.isRequired,
  rateBands: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  stages: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

RatesForm = reduxForm({
  form: "ratesForm",
  onSubmit: async (
    { rateBandItems, volumes },
    _,
    { initialValues, submitRateBandItems }
  ) => {
    const { rateBandItems: initialItems, volumes: initialVolumes } =
      initialValues;

    const getChangedValues = (current, initial, path = []) => {
      return Object.entries(current).reduce((acc, [key, value]) => {
        const currentPath = [...path, key];
        if (typeof value === "object" && value !== null) {
          const changed = getChangedValues(
            value,
            initial[key] || {},
            currentPath
          );
          if (Object.keys(changed).length) {
            acc[key] = changed;
          }
        } else if (value !== (initial[key] || 0)) {
          acc[key] = value;
        }
        return acc;
      }, {});
    };

    const changedVolumes = getChangedValues(volumes, initialVolumes);
    const changedRates = getChangedValues(rateBandItems, initialItems);

    // return;
    await submitRateBandItems({
      rateBandItems: changedRates,
      volumes: changedVolumes,
    });
  },
})(RatesForm);

export default RatesForm;
