import queryString from "query-string";
import PropTypes from "prop-types";
import styles from "./AssigneeSelector.module.scss";
import Dropdown from "../../Dropdown/v4";

const AssigneeSelector = ({
  assignees,
  selectAssignee,
  renderArrow,
  sort,
  history,
  selectedAssignee,
}) => {
  const handleSelectAssignee = (assigneeId) => {
    const currentParams = queryString.parse(history.location.search);

    if (assigneeId) {
      currentParams.assigneeId = assigneeId;
    } else {
      delete currentParams.assigneeId;
    }

    const newSearch = queryString.stringify(currentParams);

    if (history.location.search !== `?${newSearch}`) {
      history.replace({
        search: newSearch,
      });
    }
    if (selectAssignee) selectAssignee(assigneeId);
  };

  return (
    <div className={styles.assigneeHeader}>
      <Dropdown
        filterText={"filter by assignee name"}
        onClick={handleSelectAssignee}
        options={assignees}
        title={selectedAssignee ? selectedAssignee.label : "Assignee"}
        small
      />
      <button onClick={sort} className={styles.assigneeSort}>
        {renderArrow("assignee")}
      </button>
    </div>
  );
};

AssigneeSelector.propTypes = {
  assignees: PropTypes.object,
  selectAssignee: PropTypes.func,
  renderArrow: PropTypes.func.isRequired,
  sort: PropTypes.func.isRequired,
  history: PropTypes.object,
  selectedAssignee: PropTypes.object,
};

export default AssigneeSelector;
