import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  batchesDropdownSelector,
  projectBriefingFieldsSelector,
  accountById,
  orderFormById,
  projectKeywordGroupsSelector,
  briefingFieldValuesByParentDeliverableSelector,
  sourceFieldValuesByParentDeliverableSelector,
  parentDeliverableKeywordsSelector,
  sourceFieldsSelector,
  sourceFieldsMapSelector,
  rateBandsDropdownSelector,
  parentDeliverableById,
  projectById,
  parentAccountById,
  getNextParentDeliverableIdSelector,
} from "../../../../../../../utils/entitySelector";
import { projectBriefingFieldsAsMapSelector } from "../../../../../../../modules/briefingFields";
import WithData from "../../../../../../../decorators/WithData";
import { createBatch } from "../../../../../../../modules/batches";
import { showModal, hideModal } from "../../../../../../../modules/modal";
import ParentDeliverablesEdit from "../components/ParentDeliverablesEdit";
import { getInitialData } from "../modules/getInitialData";
import {
  archiveParentDeliverable,
  moveToSourceAmends,
  updateParentDeliverable,
} from "../../../../../../../modules/parentDeliverables";

const mapDispatchToProps = (
  dispatch,
  { history, match: { params } },
  ownProps
) => {
  const parentDeliverableId = Number(params.parentDeliverableId);

  return {
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
    createBatch: (data) =>
      dispatch(createBatch(data, "parentDeliverablesForm")),
    updateParentDeliverable: (data) =>
      dispatch(updateParentDeliverable(data, history, params)),
    archiveParentDeliverable: () =>
      dispatch(archiveParentDeliverable(history, params)),
    getInitialData: () => dispatch(getInitialData(params)),
    moveToSourceAmends: () => dispatch(moveToSourceAmends(parentDeliverableId)),
  };
};

const currentBriefingFieldsValueSelector = createSelector(
  briefingFieldValuesByParentDeliverableSelector,
  (state, parentDeliverableId) => parentDeliverableId,
  (briefingFieldValuesByParentDeliverable, parentDeliverableId) =>
    briefingFieldValuesByParentDeliverable[parentDeliverableId] || {}
);

const currentSourceFieldsValueSelector = createSelector(
  sourceFieldValuesByParentDeliverableSelector,
  (state, parentDeliverableId) => parentDeliverableId,
  (sourceFieldValuesByParentDeliverable, parentDeliverableId) =>
    sourceFieldValuesByParentDeliverable[parentDeliverableId] || {}
);

const currentKeywordValuesSelector = createSelector(
  parentDeliverableKeywordsSelector,
  (state, parentDeliverableId) => parentDeliverableId,
  (keywords, parentDeliverableId) => {
    const tree = {};
    keywords.forEach((keyword) => {
      if (parentDeliverableId !== keyword.parentDeliverableId) return;
      const { keywordGroupId } = keyword;
      if (!tree[keywordGroupId]) tree[keywordGroupId] = [];
      tree[keywordGroupId].push(keyword);
    });
    return tree;
  }
);

const initialValuesSelector = createSelector(
  (briefingFields, sourceFields, keywordGroups, parentDeliverable) =>
    briefingFields,
  (briefingFields, sourceFields, keywordGroups, parentDeliverable) =>
    sourceFields,
  (briefingFields, sourceFields, keywordGroups, parentDeliverable) =>
    keywordGroups,
  (briefingFields, sourceFields, keywordGroups, parentDeliverable) =>
    parentDeliverable,
  (
    briefingFields,
    sourceFields,
    keywordGroups,
    parentDeliverable,
    currentBriefingFieldValues,
    currentSourceFieldValues,
    currentKeywords
  ) => currentBriefingFieldValues,
  (
    briefingFields,
    sourceFields,
    keywordGroups,
    parentDeliverable,
    currentBriefingFieldValues,
    currentSourceFieldValues,
    currentKeywords
  ) => currentSourceFieldValues,
  (
    briefingFields,
    sourceFields,
    keywordGroups,
    parentDeliverable,
    currentBriefingFieldValues,
    currentSourceFieldValues,
    currentKeywords
  ) => currentKeywords,
  (
    briefingFields,
    sourceFields,
    keywordGroups,
    { projectId, parentDeliverableId, batchId, rateBandId },
    currentBriefingFieldValues,
    currentSourceFieldValues,
    currentKeywords
  ) => {
    const parentDeliverableBriefingFields = [];
    const parentDeliverableSourceFields = [];
    const keywords = [];

    briefingFields.forEach(({ briefingFieldId }) => {
      const fieldValue = currentBriefingFieldValues[briefingFieldId] || "";
      parentDeliverableBriefingFields.push({
        briefingFieldId,
        fieldValue,
      });
    });

    sourceFields.forEach(({ sourceFieldId }) => {
      const sourceFieldValue = currentSourceFieldValues[sourceFieldId] || {};

      parentDeliverableSourceFields.push({
        sourceFieldId,
        fieldValue: sourceFieldValue.fieldValue || "",
        rawContent: sourceFieldValue.rawContent || "",
      });
    });

    keywordGroups.forEach(({ keywordGroupId }) => {
      const currentKeywordValues = currentKeywords[keywordGroupId];
      if (currentKeywordValues && currentKeywordValues.length) {
        keywords.push({
          keywordGroupId: keywordGroupId,
          word: currentKeywordValues.map((k) => k.word),
        });
      } else {
        keywords.push({ keywordGroupId: keywordGroupId, word: [] });
      }
    });

    return {
      projectId,
      parentDeliverableId,
      batchId,
      parentDeliverableBriefingFields,
      parentDeliverableSourceFields,
      rateBandId,
      keywords,
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  const parentDeliverableId = Number(ownProps.match.params.parentDeliverableId);

  const parentDeliverable = parentDeliverableById(state, parentDeliverableId);
  const { projectId, updateDate } = parentDeliverable;
  const project = projectById(state, projectId);
  const { projectName, sourceLanguage, orderFormId } = project;
  const { accountId, orderFormNumber } = orderFormById(state, orderFormId);
  const { accountName, parentAccountId } = accountById(state, accountId);
  const { parentAccountName } = parentAccountById(state, parentAccountId);

  const batches = batchesDropdownSelector(state, projectId);
  const briefingFields = projectBriefingFieldsSelector(state, projectId);
  const sourceFields = sourceFieldsSelector(state, projectId);
  const keywordGroups = projectKeywordGroupsSelector(state, projectId);
  const currentBriefingFieldValues = currentBriefingFieldsValueSelector(
    state,
    parentDeliverableId
  );
  const currentSourceFieldValues = currentSourceFieldsValueSelector(
    state,
    parentDeliverableId
  );
  const currentKeywords = currentKeywordValuesSelector(
    state,
    parentDeliverableId
  );
  const briefingFieldEntities = projectBriefingFieldsAsMapSelector(
    state,
    projectId
  );
  const sourceFieldEntities = sourceFieldsMapSelector(state, projectId);
  const rateBands = rateBandsDropdownSelector(state, projectId);
  const nextId = getNextParentDeliverableIdSelector(state, parentDeliverableId);

  const nextParentDeliverableUrL = nextId
    ? `/admin/projects/${projectId}/parent-deliverables/${nextId}/edit`
    : null;

  const initialValues = initialValuesSelector(
    briefingFields,
    sourceFields,
    keywordGroups,
    parentDeliverable,
    currentBriefingFieldValues,
    currentSourceFieldValues,
    currentKeywords
  );
  const storageKey = "parent-deliverables";

  return {
    accountName,
    activeTab: state.tabs[storageKey],
    batches,
    briefingFields: briefingFieldEntities,
    deliverableLinks: parentDeliverable.deliverableLinks,
    initialValues,
    keywordGroups,
    orderFormNumber,
    parentAccountName,
    parentDeliverableId,
    // project,
    projectId,
    projectName,
    rateBands,
    sourceFields: sourceFieldEntities,
    sourceLanguage,
    // this is needed because when the component uses refs and is unaware
    // when the child form has changed. Hence the form 'invalid' params does not updateParentDeliverable
    // passing in the form values allows a rerender.
    form: state.form,
    featureToggles: state.featureToggles,
    messages: state.messages,
    modal: state.modal,
    storageKey,
    updateDate,
    nextParentDeliverableUrL,
  };
};

const ParentDeliverableEditWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ParentDeliverablesEdit));
export default ParentDeliverableEditWithData;
