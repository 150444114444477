import { createAction } from "redux-actions";
import { upsertData, removeData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";
// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const keywordGroupsByProjectIdQuery = `keywordGroups(projectIds: $projectId) {
    keywordGroupId, projectId, keywordGroupName, keywordGroupPosition
  }`;

export const keywordGroupsByDeliverableIdsQuery = `keywordGroups (deliverableIds: $deliverableId) {
  keywordGroupId, projectId, keywordGroupName, keywordGroupPosition
}`;
// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_KEYWORD_GROUPS_SUCCESS = "FETCH_KEYWORD_GROUPS_SUCCESS";
export const CREATE_KEYWORD_GROUPS_SUCCESS = "CREATE_KEYWORD_GROUPS_SUCCESS";
export const UPDATE_KEYWORD_GROUPS_SUCCESS = "UPDATE_KEYWORD_GROUPS_SUCCESS";
export const REMOVE_KEYWORD_GROUPS_SUCCESS = "REMOVE_KEYWORD_GROUPS_SUCCESS";
// ------------------------------------
// Actions
// ------------------------------------
export const fetchKeywordGroupsSuccess = createAction(
  FETCH_KEYWORD_GROUPS_SUCCESS
);
export const createKeywordGroupsSuccess = createAction(
  CREATE_KEYWORD_GROUPS_SUCCESS
);
export const updateKeywordGroupsSuccess = createAction(
  UPDATE_KEYWORD_GROUPS_SUCCESS
);
export const removeKeywordGroupsSuccess = createAction(
  REMOVE_KEYWORD_GROUPS_SUCCESS
);
// ------------------------------------
// Action Handlers
// ------------------------------------
export const keywordGroupActionHandlers = {
  [RESET_INITIAL_STATE]: () => keywordGroupInitialState,
  [FETCH_KEYWORD_GROUPS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "keywordGroupId"),
  [CREATE_KEYWORD_GROUPS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "keywordGroupId"),
  [UPDATE_KEYWORD_GROUPS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "keywordGroupId"),
  [REMOVE_KEYWORD_GROUPS_SUCCESS]: (state, { payload }) =>
    payload.reduce(
      (acc, { keywordGroupId }) => removeData(acc, keywordGroupId),
      state
    ),
};

export const keywordGroupInitialState = { entities: {}, result: [] };
