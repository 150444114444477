import { getGraphQL } from "../../../../../utils/fetch";
import {
  assignmentsByAssignmentGroupQuery,
  fetchAssignmentsSuccess,
} from "../../../../modules/assignments";

import {
  projectsByAssignmentGroupQuery,
  fetchProjectsSuccess,
} from "../../../../modules/projects";

export const deliverablesQuery = `query deliverablesByAssignmentGroup($assignmentGroupId: String, $includeArchivedAssignments: Boolean ) {
  ${assignmentsByAssignmentGroupQuery}
  ${projectsByAssignmentGroupQuery}
}`;

export function getInitialData({ match: { params } }, url, cookies) {
  return async (dispatch) => {
    const { projects, assignments } = await getGraphQL(
      deliverablesQuery,
      params,
      url,
      cookies
    );
    dispatch(fetchProjectsSuccess(projects));
    dispatch(fetchAssignmentsSuccess(assignments));
  };
}
