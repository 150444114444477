import React from "react";
import PropTypes from "prop-types";
import styles from "./AssignmentGroupRowContainer.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";

import {
  convertDateToLocal,
  formatDateObject,
  formatDateString,
  getLocalTimezoneString,
} from "../../../../../utils/date";
import CurrencyDisplay from "../../../../../components/CurrencyDisplay";
import AssignmentAdjustmentBtn from "./AssignmentAdjustmentBtn";
import FeatureToggle from "../../../../../components/FeatureToggle";
import Icon from "../../../../../components/Icon";
const BASE_URL = "/freelancer";

const ActionContainer = ({ accept, reject, assignmentGroupId }) => (
  <div className={styles.actionContainer}>
    <div className={styles.actionCell}>
      <div
        className={`${styles.action} ${styles.reject}`}
        data-assignment-group-id={assignmentGroupId}
        onClick={reject}
      >
        <Icon name="Declined" className={styles.element} size="xl" />
      </div>
    </div>
    <div className={styles.actionCell}>
      <div
        className={`${styles.action} ${styles.accept}`}
        data-assignment-group-id={assignmentGroupId}
        onClick={accept}
      >
        <Icon name="Approved" className={styles.element} size="xl" />
      </div>
    </div>
  </div>
);

const PaymentMessage = ({ currencyCode, totalRate = 0, count, stageName }) => {
  return (
    <span>
      {`${count} ${stageName} task${count !== 1 ? "s" : ""}`}
      {totalRate > 0 && (
        <>
          <span> totalling </span>
          <CurrencyDisplay currencyCode={currencyCode} />
          {parseFloat(totalRate).toFixed(2)}
        </>
      )}
    </span>
  );
};

const AssignmentGroupRowContainer = ({
  header,
  assignmentGroups,
  emptyCopy,
  assignmentActions: { show, reject, accept, rejectAll, acceptAll } = {},
}) => {
  const now = new Date();

  const timezoneString = getLocalTimezoneString();

  const isCurrentOrUpcoming =
    header === "Current assignments" || header === "Upcoming assignments";

  return (
    <div className={styles.assignmentGroupRow}>
      <h2 className={styles.header}>
        <div>{header}</div>
        <div className={styles.timezone}>{timezoneString}</div>
        {show && <ActionContainer accept={acceptAll} reject={rejectAll} />}
      </h2>
      {assignmentGroups.length ? (
        assignmentGroups.map(
          ({
            accountName,
            assignmentGroupId,
            batchName,
            count,
            deadline,
            hasRequestPending,
            inTraining,
            projectName,
            stageName,
            totalRate,
            currencyCode,
            projectId,
          }) => {
            const localDeadline = convertDateToLocal(
              formatDateString(deadline)
            );
            const overdue = now > localDeadline;

            const deadlineStyles = classNames({
              [styles.deadline]: true,
              [styles.overdue]: overdue,
            });

            return (
              <Link
                key={assignmentGroupId}
                className={styles.row}
                to={`${BASE_URL}/projects/${projectId}/assignments/${assignmentGroupId}`}
              >
                <div className={styles.projectInfo}>
                  {accountName} | {projectName}
                </div>
                <div className={styles.batchName}>{batchName}</div>
                <div className={styles.taskRate}>
                  <PaymentMessage
                    totalRate={totalRate}
                    count={count}
                    stageName={stageName}
                    currencyCode={currencyCode}
                  />
                </div>
                <div className={deadlineStyles}>
                  {formatDateObject(localDeadline).substring(0, 16)}
                </div>

                {inTraining ? (
                  <div className={styles.trainingCol}>
                    <Icon
                      name="Training"
                      size="lg"
                      className={styles.centerElement}
                    />
                  </div>
                ) : (
                  <div />
                )}

                {show && (
                  <ActionContainer
                    accept={accept}
                    reject={reject}
                    assignmentGroupId={assignmentGroupId}
                  />
                )}

                {isCurrentOrUpcoming && (
                  <FeatureToggle toggle={"assignmentGroupAdjustments"}>
                    <AssignmentAdjustmentBtn
                      assignmentGroupId={assignmentGroupId}
                      hasRequestPending={hasRequestPending}
                    />
                  </FeatureToggle>
                )}
              </Link>
            );
          }
        )
      ) : (
        <div className={styles.emptyRow}>{emptyCopy}</div>
      )}
    </div>
  );
};

AssignmentGroupRowContainer.propTypes = {
  assignmentGroups: PropTypes.array.isRequired,
  assignmentActions: PropTypes.shape({
    accept: PropTypes.func,
    acceptAll: PropTypes.func,
    reject: PropTypes.func,
    rejectAll: PropTypes.func,
    show: PropTypes.bool,
  }),
  emptyCopy: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default AssignmentGroupRowContainer;
