import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./NestedAdminRow.module.scss";
import NestedRow from "../../../components/NestedAccountRow/NestedRow";
import queryString from "query-string";
import FeatureToggle from "../../FeatureToggle";
import Icon from "../../Icon";

const searchUrl = "/admin/search";
class NestedAdminRow extends Component {
  state = {
    accountId: "",
    searchTerm: "",
    searchActive: false,
  };

  search = (e) => {
    e.stopPropagation();
    this.setState({ searchTerm: e.target.value });
  };

  toggleSearch = (e) => {
    e.stopPropagation();
    this.setState({ searchActive: true });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter" && this.state.searchTerm) {
      this.handleSearch(e);
    }
  };

  handleSearch = (e) => {
    e.stopPropagation();
    const parse = {
      accountId: this.props.modalId,
      searchTerm: this.state.searchTerm,
    };
    const parsedHash = queryString.stringify(parse);
    const win = window.open(`${searchUrl}?${parsedHash}`, "_blank");
    win.focus();
  };

  handleEdit = (e) => {
    e.stopPropagation();
    this.props.handleCreateOrEdit({
      modalType: this.props.modalType,
      modalTitle: this.props.modalTitle,
      modalId: this.props.modalId,
    });
  };

  handleDelete = (e) => {
    e.stopPropagation();
    this.props.handleDelete({
      name: this.props.name,
      modalType: this.props.modalType,
      modalTitle: this.props.modalTitle,
      modalId: this.props.modalId,
    });
  };

  handleCreate = (e) => {
    e.stopPropagation();
    const data = {
      modalType: this.props.childType,
      modalTitle: this.props.childTitle,
      modalId: this.props.modalId,
    };
    this.props.handleCreateOrEdit(data, true);
  };

  handleHide = (e) => {
    e.stopPropagation();
    const { parentAccountId, orderFormId, accountId } = this.props;
    const object = { parentAccountId, orderFormId, accountId };
    this.props.hide(object);
  };

  handleRestore = (e) => {
    e.stopPropagation();
    const { parentAccountId, orderFormId, accountId } = this.props;
    const object = { parentAccountId, orderFormId, accountId };
    this.props.restore(object);
  };

  handleFavourite = (e) => {
    e.stopPropagation();
    const {
      parentAccountId,
      orderFormId,
      accountId,
      isFavourite,
      favouriteComponent,
    } = this.props;
    const object = { parentAccountId, orderFormId, accountId };
    if (!isFavourite && !favouriteComponent) {
      this.props.favourite(object);
    } else {
      this.props.unfavourite(object);
    }
  };

  handleProjectCopy = (e) => {
    e.stopPropagation();
    const data = {
      modalType: "projectCopy",
      modalTitle: "Copy project",
      modalId: this.props.modalId,
    };
    this.props.handleCreateOrEdit(data, true);
  };

  render() {
    const {
      handleRowClick,
      name,
      childTitle,
      customStyles,
      createUrl,
      archivedComponent,
      favouriteComponent,
    } = this.props;

    const isNotHomePage = archivedComponent || favouriteComponent;
    const favoriteFill = this.props.isFavourite ? "#DA0034" : "#FFF";
    const favoriteStroke = this.props.isFavourite ? "" : "#DA0034";

    return (
      <NestedRow
        customStyles={customStyles}
        handleRowClick={handleRowClick}
        name={name}
      >
        <div>
          {childTitle === "order form" &&
            !isNotHomePage && [
              <div className={styles.container}>
                <FeatureToggle toggle="searchResults">
                  <div className={styles.searchContainer}>
                    {this.state.searchActive ? (
                      [
                        <input
                          key={name}
                          className={styles.searchInput}
                          placeholder="Search here..."
                          onChange={this.search}
                          onClick={(e) => e.stopPropagation()}
                          onKeyPress={this.handleKeyPress}
                        />,
                        this.state.searchTerm && (
                          <span
                            key={this.state.searchTerm}
                            className={styles.go}
                            onClick={this.handleSearch}
                          >
                            Go
                          </span>
                        ),
                      ]
                    ) : (
                      <div
                        onClick={this.toggleSearch}
                        className={styles.searchMagnifier}
                      >
                        <svg
                          className={styles.searchIcon}
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.504 13.616l-3.79-3.223c-0.392-0.353-0.811-0.514-1.149-0.499 0.895-1.048 1.435-2.407 1.435-3.893 0-3.314-2.686-6-6-6s-6 2.686-6 6 2.686 6 6 6c1.486 0 2.845-0.54 3.893-1.435-0.016 0.338 0.146 0.757 0.499 1.149l3.223 3.79c0.552 0.613 1.453 0.665 2.003 0.115s0.498-1.452-0.115-2.003zM6 10c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
                        </svg>
                      </div>
                    )}
                  </div>
                </FeatureToggle>
              </div>,
              <FeatureToggle toggle="glossary_v2">
                <Link
                  to={`/admin/glossary/${this.props.accountId}`}
                  className={styles.createUrl}
                >
                  Glossary
                </Link>
                <span className={styles.linkSeparator}></span>
              </FeatureToggle>,
            ]}

          {!isNotHomePage && [
            <span className={styles.button} onClick={this.handleEdit}>
              Edit
            </span>,
            <span className={styles.linkSeparator}></span>,
            <span className={styles.button} onClick={this.handleDelete}>
              Delete
            </span>,
            <span className={styles.linkSeparator}></span>,
          ]}
          {/* link instead of onClick functionality if createUrl exists */}
          {!isNotHomePage &&
            (createUrl && !this.props.archivedComponent ? (
              <Link to={createUrl} className={styles.createUrl}>
                Add new {childTitle}
              </Link>
            ) : (
              !this.props.archivedComponent && (
                <span className={styles.button} onClick={this.handleCreate}>
                  Add new {childTitle}
                </span>
              )
            ))}

          <FeatureToggle toggle="projectCopy">
            {childTitle === "order form" && !isNotHomePage && (
              <>
                <span className={styles.linkSeparator}></span>
                <span
                  className={styles.button}
                  onClick={this.handleProjectCopy}
                >
                  Copy project
                </span>
              </>
            )}
          </FeatureToggle>
          {!isNotHomePage && (
            <>
              <span className={styles.linkSeparator}></span>
              <span className={styles.button} onClick={this.handleHide}>
                Hide
              </span>
              <span className={styles.linkSeparator}></span>
              <Icon
                name="FavoritesDashboard"
                onClick={this.handleFavourite}
                className={styles.favourite}
                size="md"
                fill={favoriteFill}
                stroke={favoriteStroke}
              />
            </>
          )}
          {archivedComponent && this.props.restoreEnabled && (
            <span className={styles.button} onClick={this.handleRestore}>
              Restore
            </span>
          )}

          {favouriteComponent && this.props.favouritesEnabled && (
            <Icon
              name="FavoritesDashboard"
              onClick={this.handleFavourite}
              className={styles.favourite}
              size="md"
              fill={"#FFF"}
              stroke={"#DA0034"}
            />
          )}
        </div>
      </NestedRow>
    );
  }
}

NestedAdminRow.propTypes = {
  customStyles: PropTypes.string,
  handleRowClick: PropTypes.func.isRequired,
  handleCreateOrEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  modalId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  childTitle: PropTypes.string.isRequired,
  childType: PropTypes.string.isRequired,
  createUrl: PropTypes.string,
};

export default NestedAdminRow;
