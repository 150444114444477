import { createAction } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_ERRORS = "SHOW_ERRORS";
export const HIDE_ERRORS = "HIDE_ERRORS";

// ------------------------------------
// Actions
// ------------------------------------
export const showErrors = createAction(SHOW_ERRORS);
export const hideErrors = createAction(HIDE_ERRORS);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const actionHandlers = {
  [SHOW_ERRORS]: (_, { payload }) => {
    const { error, errors, callback } = payload;

    // default state object
    const state = {
      display: true,
      callback,
      msg: payload,
    };

    // specific single error
    if (error) {
      state.msg = error;
    } else if (errors) {
      // Handle restify error objects being thrown from the backend
      if (errors[0]) {
        const { name, message } = errors[0];
        state.msg = `${name}: ${message}`;
        // Handle "BadRequestError" from backend
      } else if (errors._error) {
        state.msg = errors._error;
      }
    }

    return state;
  },
  [HIDE_ERRORS]: () => initialState,
};

export const initialState = { display: false, msg: null };

const reducer = (state = initialState, action) => {
  const handleFn = actionHandlers[action.type];
  return handleFn ? handleFn(state, action) : state;
};

export default reducer;
