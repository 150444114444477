import { getGraphQL } from "../../../../../../../../../utils/fetch";

import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../../modules/projects";
import {
  clientBatchDetailsQueryWithTransitionDate,
  fetchClientBatchDetailsSuccess,
} from "../../../../../../../../modules/clientBatchDetails";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
  multiSelectLanguageSuccess,
} from "../../../../../../../../modules/projectLanguages";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../modules/batches";
import {
  exportFormatByProjectIdQuery,
  fetchExportFormatSuccess,
} from "../../../../../../../../modules/exportFormats";
import { getQueryValuesAsArray } from "../../../../../../../../utils/parseQuery";

export const initialQuery = `query ProjectShowCompleted ($projectId: String) {
  ${accountsByProjectQuery}
  ${batchesByProjectIdQuery}
  ${clientBatchDetailsQueryWithTransitionDate}
  ${exportFormatByProjectIdQuery}
  ${orderFormsByProjectQuery}
  ${parentAccountsByProjectQuery}
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  const { projectId } = params;
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, { projectId }, url, cookies).then(
      (json) => {
        const languageCode = getQueryValuesAsArray("languageCode");

        const selectedLanguages =
          languageCode?.length > 0
            ? languageCode
            : json.projectLanguages.map((item) => item.languageCode);

        dispatch(fetchAccountsSuccess(json.accounts));
        dispatch(fetchBatchesSuccess(json.batches));
        dispatch(
          fetchClientBatchDetailsSuccess({
            data: json.clientBatchDetails,
            projectId,
          })
        );
        dispatch(fetchExportFormatSuccess(json.exportFormat));
        dispatch(fetchOrderFormsSuccess(json.orderForms));
        dispatch(fetchParentAccountsSuccess(json.parentAccounts));
        dispatch(
          fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages)
        );
        dispatch(fetchProjectSuccess(json.project));
        dispatch(multiSelectLanguageSuccess(selectedLanguages));
      }
    );
  };
}
