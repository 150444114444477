import { connect } from "react-redux";
import { createSelector } from "reselect";
import InProduction from "../components/InProduction";
import WithData from "../../../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import {
  batchesByProjectId,
  projectLanguagesWithFullNameSelector,
  workflowStagesSelector,
  projectLanguagesSelector,
  currentLanguageSelector,
  languageNamesFromLanguages,
  projectById,
} from "../../../../../../../../utils/entitySelector";
import { selectLanguageSuccess } from "../../../../../../../../modules/projectLanguages";
import { createManualReminder } from "../../../../../../../../modules/manualReminders";
import { hideModal, showModal } from "../../../../../../../../modules/modal";
import parseQuery from "../../../../../../../../utils/parseQuery";
import {
  markAssignmentGroup,
  updateAssignmentGroupDeadlines,
  markAllStageAssignmentGroups,
} from "../../../../../../../../modules/assignmentGroups";
import {
  convertDateToLocal,
  formatDateString,
  toMysqlFormat,
} from "../../../../../../../../utils/date";
import { get } from "lodash/fp";
import { showSuccessMessage } from "../../../../../../../../modules/messages";

const assignmentGroupsInProjectSelector = createSelector(
  (state, projectId) => state.assignmentGroups.entities,
  (state, projectId) => state.assignmentGroups.result,
  (state, projectId) => projectId,
  (state) => state.emailLogs.entities,
  (entities, result, projectId, emailLogs) =>
    result.reduce((arr, assignmentGroupId) => {
      if (
        entities[assignmentGroupId] &&
        entities[assignmentGroupId].projectId === projectId
      ) {
        arr[assignmentGroupId] = {
          ...entities[assignmentGroupId],
          ...emailLogs[assignmentGroupId],
        };
      }
      return arr;
    }, {})
);

const stageAssignmentDetailsSelector = createSelector(
  (projectStages, assignmentGroupEntities) => projectStages,
  (projectStages, assignmentGroupEntities) => assignmentGroupEntities,
  (projectStages, assignmentGroupEntities, batchId) => batchId,
  (projectStages, assignmentGroupEntities, batchId, languageCode) =>
    languageCode,
  (
    projectStages,
    assignmentGroupEntities,
    batchId,
    languageCode,
    filterByStatus
  ) => filterByStatus,
  (
    projectStages,
    assignmentGroupEntities,
    batchId,
    languageCode,
    filterByStatus,
    assigneeId
  ) => assigneeId,
  (
    projectStages,
    assignmentGroupEntities,
    batchId,
    languageCode,
    filterByStatus,
    assigneeId
  ) => {
    // regardless of whether assignments exist we must display stage names
    const stageAssignments = {};
    projectStages.forEach(
      ({ stageId, stageName, stageType, stagePosition }) => {
        if (
          (stageType === "Production" ||
            stageType === "Amends" ||
            stageType === "Training") &&
          !stageAssignments[stageId]
        ) {
          stageAssignments[stageId] = {
            stageId,
            stageName,
            stageType,
            stagePosition,
            marked: false,
            disabled: false,
            assignmentGroups: [],
          };
        }
      }
    );

    const isAnyAssignmentGroupMarked = Object.values(
      assignmentGroupEntities
    ).some((ag) => ag.marked);

    const isAnyNotActioned = Object.values(assignmentGroupEntities).some(
      (ag) => !ag.accepted
    );

    // Add assignmentGroups to relevant stages
    Object.keys(assignmentGroupEntities).forEach((assignmentGroupId) => {
      const assignmentGroup = assignmentGroupEntities[assignmentGroupId];
      const { stageId, deadline } = assignmentGroup;
      const localTime = convertDateToLocal(formatDateString(deadline));
      const isOverDue = localTime < new Date();

      // Update the original assignmentGroup with 'isOverDue' before any filtering
      assignmentGroup.isOverDue = isOverDue;

      const stage = stageAssignments[stageId];
      if (stage) {
        // Initially assign all relevant assignmentGroups to the stage
        stage.assignmentGroups = Object.values(assignmentGroupEntities).filter(
          (group) => group.stageId === stageId
        );

        // Filter based on the current filter status if true
        if (filterByStatus) {
          stage.assignmentGroups = stage.assignmentGroups.filter(
            (ag) =>
              ag.amendsRequested ||
              ag.rejected ||
              ag.hasRequestPending ||
              ag.isOverDue ||
              !ag.accepted
          );
        }
      }
    });

    // Check if all assignmentGroups in each stage are marked
    Object.values(stageAssignments).forEach((stage) => {
      let filteredAssignmentGroups = stage.assignmentGroups;

      // Apply filter only if languageCode and batchId are provided
      if (languageCode !== "all" || batchId !== null || assigneeId != null) {
        filteredAssignmentGroups = filteredAssignmentGroups.filter(
          (ag) =>
            (languageCode !== "all"
              ? ag.languageCode === languageCode
              : true) &&
            (batchId != null ? ag.batchId === batchId : true) &&
            (assigneeId != null ? ag.assigneeId === assigneeId : true)
        );
      }

      // Check if every assignmentGroup in the filtered list is marked
      const allMarked =
        filteredAssignmentGroups.every((ag) => ag.marked) &&
        filteredAssignmentGroups.length > 0;

      stage.disabled =
        filteredAssignmentGroups.length === 0 ||
        (isAnyAssignmentGroupMarked &&
          !filteredAssignmentGroups.some((ag) => ag.marked));

      stage.marked = allMarked; // Update the stage's marked status
    });

    return Object.keys(stageAssignments)
      .map((key) => stageAssignments[key])
      .sort((a, b) => a.stagePosition - b.stagePosition);
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
    selectLanguage: (selectedLanguage) =>
      dispatch(selectLanguageSuccess(selectedLanguage)),
    hideModal: (type) => dispatch(hideModal(type)),
    showModal: (type) => dispatch(showModal(type)),
    createManualReminder: (assignmentGroupId, projectId) =>
      dispatch(createManualReminder(assignmentGroupId, projectId)),
    markAssignmentGroups: (assignmentGroupId) =>
      dispatch(markAssignmentGroup(assignmentGroupId)),
    markAllStageAssignmentGroups: (
      stageId,
      batchId,
      languageCode,
      assigneeId
    ) =>
      dispatch(
        markAllStageAssignmentGroups(
          stageId,
          batchId,
          languageCode,
          assigneeId,
          ownProps.match.params
        )
      ),
    showSuccessMessage: (message) => dispatch(showSuccessMessage(message)),
    updateAssignmentGroups: (assignmentGroupIds, deadline) => {
      const updatedDeadline = toMysqlFormat(new Date(deadline));

      return dispatch(
        updateAssignmentGroupDeadlines(assignmentGroupIds, updatedDeadline)
      );
    },
  };
};

/**
 * @param {Object[]}  batches array of batch entities
 * @param {Object}    assignmentGroups object of assignment groups indexed by id
 * @returns {Object[]} array of batches
 */
const batchOptionsSelector = createSelector(
  (batches, _) => batches,
  (_, assignmentGroups) => assignmentGroups,
  (batches, assignmentGroups) => {
    // Get hash of relevant batch names
    const relevantBatchNames = {};
    for (const groupId in assignmentGroups) {
      const group = assignmentGroups[groupId];
      relevantBatchNames[group.batchName] = true;
    }

    return batches.filter((batch) => relevantBatchNames[batch.batchName]);
  }
);

const assigneeOptionsSelector = createSelector(
  (assignmentGroups) => assignmentGroups,
  (assignmentGroups) => {
    const assignees = {};
    for (const groupId in assignmentGroups) {
      const group = assignmentGroups[groupId];
      if (!assignees[group.assigneeId]) {
        assignees[
          group.assigneeId
        ] = `${group.assigneeFirstName} ${group.assigneeLastName}`;
      }
    }

    return assignees;
  }
);

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);
  const automatedReminderActive = state.featureToggles.automatedReminders;

  const queryString = ownProps.location && ownProps.location.search;
  const parsedQuery = parseQuery(queryString);
  const filterByStatus =
    !parsedQuery.all && state.featureToggles.assignmentGroupsByStatus;

  const project = state.projects.entities[projectId] || {};
  const languages = projectLanguagesWithFullNameSelector(state, projectId);
  const languageNames = languageNamesFromLanguages(languages);
  const projectLanguages = projectLanguagesSelector(state, projectId);
  const selectedLanguage = currentLanguageSelector(state, projectId);
  const projectStages = workflowStagesSelector(state, project.workflowId);
  const { currencyCode } = projectById(state, projectId);
  const assignmentGroupEntities = assignmentGroupsInProjectSelector(
    state,
    projectId
  );

  const messages = state.messages;

  const batches = batchesByProjectId(state, projectId);
  const batchOptions = batchOptionsSelector(batches, assignmentGroupEntities);

  const selectedBatchId = parseQuery(
    ownProps.location && ownProps.location.search
  ).batchId;
  const selectedBatch = selectedBatchId
    ? batchOptions.find((batch) => batch.batchId === Number(selectedBatchId))
    : null;

  const assigneeOptions = assigneeOptionsSelector(assignmentGroupEntities);

  let selectedAssigneeId = parseQuery(ownProps.location?.search).assigneeId;

  selectedAssigneeId = selectedAssigneeId ? Number(selectedAssigneeId) : null;

  const stageAssignmentDetails = stageAssignmentDetailsSelector(
    projectStages,
    assignmentGroupEntities,
    selectedBatchId,
    selectedLanguage,
    filterByStatus,
    selectedAssigneeId
  );

  const modalType = state.modal.type;

  const form = get("form.updateAssignmentsForm.values", state);

  return {
    project,
    projectLanguages,
    stageAssignmentDetails,
    selectedLanguage,
    selectedBatchId,
    selectedBatch,
    languageNames,
    modal: state.modal,
    batchOptions,
    currencyCode,
    modalType,
    automatedReminderActive,
    form,
    messages,
    assigneeOptions,
    selectedAssigneeId,
  };
};

const ProjectInProduction = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(InProduction));
export default ProjectInProduction;
