import { createAction } from "redux-actions";
import { fetchWithThrow } from "../../utils/fetch";
import { SubmissionError } from "redux-form";
import { authLogout } from "../boot/auth0Client";

// ------------------------------------
// Constants
// ------------------------------------
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const FETCH_ME_SUCCESS = "FETCH_ME_SUCCESS";
export const RESET_INITIAL_STATE = "CLEAR_INITIAL_STATE";
// ------------------------------------
// Actions
// ------------------------------------
export const fetchMeSuccess = createAction(FETCH_ME_SUCCESS);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const resetInitialState = createAction(RESET_INITIAL_STATE);

// This will not be used anymore with auth0 but let's keep in case of rollback

export const loginFetch = (data) => {
  return (dispatch, getState) => {
    return fetchWithThrow(`${window.__API_GATEWAY__}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      credentials: "include",
    })
      .then((json) => {
        dispatch(loginSuccess(json));
      })
      .catch((err) => {
        throw new SubmissionError({ _error: err.message || err.stack });
      });
  };
};

export const logoutFetch = (history) => {
  return async (dispatch, getState) => {
    const {
      people: { entities: peopleEntities },
      me,
    } = getState();
    const user = peopleEntities[me];
    try {
      const response = await fetch(
        `${
          window.__REACT_APP_API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY
        }/logout`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      if (response.status === 200) {
        const { featureToggles: auth0Login } = getState();

        if (auth0Login) {
          authLogout({
            logoutParams: {
              returnTo:
                window.__QUILL_CLOUD_FRONTEND__ || window.origin.location,
            },
          });
        } else {
          dispatch(logoutSuccess());
          dispatch(resetInitialState());
        }

        if (user.personType === "Client") {
          localStorage.clear();
        }
      } else {
        // TODO: show error somewhere if status isn't 200
      }
    } catch (err) {
      // TODO: show error somewhere if status isn't 200
      console.log("logout err", err);
    }
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------

export const meActionHandlers = {
  [FETCH_ME_SUCCESS]: (state, { payload }) => String(payload.personId),
  [LOGIN_SUCCESS]: (state, { payload }) => String(payload.personId),
  [LOGOUT_SUCCESS]: (state) => meInitialState,
};

export const meInitialState = "";
