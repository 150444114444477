import { ApolloClient, InMemoryCache } from "@apollo/client";

export async function createClient(): Promise<any> {
  // envs are not set immediately... #hacky
  await new Promise((resolve) => {
    setTimeout(resolve, 1);
  });

  // @ts-ignore
  // const API_GATEWAY = process.env.DOMAIN_API_GATEWAY || window.__API_GATEWAY__;
  const API_GATEWAY =
    process.env.REACT_APP_API_GATEWAY || (window as any).__API_GATEWAY__;

  const uri = `${API_GATEWAY}/graphql`;

  const client = new ApolloClient({
    uri,
    credentials: "include",
    cache: new InMemoryCache(),
  });

  return client;
}
