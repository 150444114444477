import React, { memo, useEffect, useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./Tabs.module.scss";
import useLocalStorage from "../../../hooks/useLocalStorage";

const Tabs = ({
  hideNavIfOneItem,
  initialValue,
  items,
  storageKey,
  updateStoreActiveTab,
}) => {
  const lsKey = `${storageKey}--active-tab`;
  const [item, setItem] = useLocalStorage(lsKey);

  const updateActiveTab = useCallback(
    (activeTabKey) => {
      setItem(activeTabKey); // update local state
      updateStoreActiveTab(storageKey, activeTabKey); // update store
    },
    [setItem, storageKey, updateStoreActiveTab]
  );

  // extract the key from the clicked tab item
  const onClick = (tab) => {
    updateActiveTab(tab.currentTarget.getAttribute("data-key"));
  };

  // used to update the store value on load
  useEffect(() => {
    // use intial value if there is no LS item or the item that is stored is
    // not a part of the nav items (e.g. optional TM tabs)
    const useInitialValue = !item || !items.some((i) => i.key === item);
    updateActiveTab(useInitialValue ? initialValue : item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hideNavIfOneItem && items.length <= 1) return null;

  return (
    <div className={styles.navItems}>
      {items.map(({ text, key }) => {
        const navItemStyles = classNames({
          [styles.navItem]: true,
          [styles.active]: key === item,
        });

        return (
          <div
            key={key}
            className={navItemStyles}
            data-key={key}
            onClick={onClick}
          >
            {text}
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  hideNavIfOneItem: PropTypes.bool,
  initialValue: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  storageKey: PropTypes.string.isRequired,
  updateStoreActiveTab: PropTypes.func.isRequired,
};

export default memo(Tabs);
