import { useEffect } from "react";
import MultiSelectDropdown from "../../../../../../components/MultiSelectDropdown";
import styles from "./DashboardFilters.module.scss";
import PropTypes from "prop-types";

export const DashboardFilters = (props) => {
  const {
    activeLanguages,
    languageNames,
    readyForReview,
    setActiveLanguages,
    setReadyForReview,
  } = props;

  // Reset active filters when component unmounts
  useEffect(() => () => setActiveLanguages([]), []);

  function filterLanguages(languageCodes) {
    setActiveLanguages(languageCodes);
  }

  function toggleWithClient() {
    setReadyForReview(!readyForReview);
  }

  return (
    <div className={styles.dashboardFilters}>
      <div className={styles.languageFilter}>
        <MultiSelectDropdown
          onClick={filterLanguages}
          options={languageNames}
          rightAligned
          title={`Languages (${activeLanguages.length})`}
          selectedOptions={activeLanguages}
          openUpwards
        />
      </div>

      <div className={styles.toggleDiv}>
        <span className={styles.label}>Ready for review:</span>
        <label className={styles.switch}>
          <input
            checked={readyForReview}
            onChange={toggleWithClient}
            type="checkbox"
          />
          <span className={`${styles.slider} ${styles.round}`} />
        </label>
      </div>

      <div className={styles.languageFilterPosition}>
        <MultiSelectDropdown
          onClick={filterLanguages}
          options={languageNames}
          rightAligned
          title={`Languages (${activeLanguages.length})`}
          selectedOptions={activeLanguages}
        />
      </div>
    </div>
  );
};

DashboardFilters.propTypes = {
  activeLanguages: PropTypes.array,
  languageNames: PropTypes.object.isRequired,
  readyForReview: PropTypes.bool,
  setActiveLanguages: PropTypes.func.isRequired,
  setReadyForReview: PropTypes.func.isRequired,
};

export default DashboardFilters;
