import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  accountsByPersonIdQuery,
  fetchAccountsSuccess,
} from "../../../../../../modules/accounts";
import {
  peopleByPersonIdQuery,
  fetchPersonSuccess,
} from "../../../../../../modules/people";
import {
  personAccountsQuery,
  fetchPersonAccountsSuccess,
} from "../../../../../../modules/personAccounts";
import {
  personNativeLanguagesQuery,
  fetchPersonNativeLanguagesSuccess,
} from "../../../../../../modules/personNativeLanguages";

export const initialQuery = `query PeopleEdit_fetchPersonAndAccounts ($personId: String) {
  ${accountsByPersonIdQuery}
  ${personAccountsQuery}
  ${peopleByPersonIdQuery}
  ${personNativeLanguagesQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchPersonAccountsSuccess(json.personAccounts));
      dispatch(fetchPersonSuccess(json.person));
      dispatch(fetchPersonNativeLanguagesSuccess(json.personNativeLanguages));
    });
  };
}
