import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import PageHeader from "../../../../../components/PageHeader";
import PaymentsForm from "./PaymentsForm";
import styles from "./PaymentsNew.module.scss";
import Errors from "../../../../../components/Errors";
import { formatDate } from "../../../../../utils/date";
import {
  PAYMENT_TYPE_AD_HOC,
  PAYMENT_TYPE_AD_HOC_DRAFT,
} from "../../../../../modules/payments";

class PaymentsNew extends Component {
  constructor(props) {
    super(props);
    this.submitAndRedirect = () =>
      this.setState({ redirect: true }, () => this.paymentsForm.submit());
    this.submitAndClearForm = () =>
      this.setState({ redirect: false }, () => this.paymentsForm.submit());
    this.createPayment = this.createPayment.bind(this);
    this.state = { redirect: true };
  }

  handleFormSubmissionSuccess = ({ peopleIds }) => {
    if (!this.state.redirect) {
      this.props.clearPaymentsForm();
      return;
    }

    // Redirect
    if (!this.props.featureToggles.freelancerPayments) {
      this.props.history.push("/admin");
      return;
    }

    // Redirect to freelancer's payments
    const personId = peopleIds[0];
    const now = new Date();
    const nextMonth = new Date();
    nextMonth.setMonth(now.getMonth() + 1);

    this.props.history.push(
      "/admin/payments" +
        `?personId=${personId}` +
        `&startDate=${formatDate(now)}` +
        `&endDate=${formatDate(nextMonth)}`
    );
  };

  createPayment = async (data) => {
    const isAdHocDraft = data.paymentType === PAYMENT_TYPE_AD_HOC_DRAFT;
    const paymentData = {
      ...data,
      paymentType: isAdHocDraft
        ? PAYMENT_TYPE_AD_HOC_DRAFT
        : PAYMENT_TYPE_AD_HOC,
      paymentStatus: isAdHocDraft ? "Needs Approval" : "Pending",
    };

    try {
      await this.props.createPayment(paymentData);
      this.handleFormSubmissionSuccess(data);
    } catch (err) {
      this.props.showErrors(err);
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Payments</title>
        </Helmet>
        {this.props.errors.display && <Errors />}
        <div>
          <PageHeader
            title={"Payments"}
            breadCrumbItems={[{ text: "Payments" }, { text: "New payment" }]}
          >
            <div className={styles.headerRight}>
              <Link
                to={`/admin/payments?paymentType=AD_HOC_DRAFT&createdByPersonId=${this.props.me}`}
                className={styles.paymentBtn}
              >
                View my draft ad hocs
              </Link>
              <Link to={"/admin/payments/search"} className={styles.paymentBtn}>
                Search payments
              </Link>
            </div>
          </PageHeader>
          <PaymentsForm
            ref={(r) => {
              this.paymentsForm = r;
            }}
            freelancers={this.props.freelancers}
            accounts={this.props.accounts}
            orderForms={this.props.orderForms}
            projects={this.props.projects}
            tasks={this.props.tasks}
            searchFreelancers={this.props.searchFreelancers}
            fetchOrderFormsByAccountId={this.props.fetchOrderFormsByAccountId}
            fetchProjectsByOrderFormId={this.props.fetchProjectsByOrderFormId}
            createPayment={this.props.createPayment}
            onSubmit={this.createPayment}
            currencyCode={this.props.currencyCode}
          />
          <div className={styles.buttonsContainer}>
            <button
              onClick={this.submitAndClearForm}
              className={styles.saveAndCreateAnother}
              disabled={this.paymentsForm && !this.paymentsForm.valid}
            >
              Save and create new
            </button>
            <button
              onClick={this.submitAndRedirect}
              className={styles.save}
              disabled={this.paymentsForm && !this.paymentsForm.valid}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PaymentsNew.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  errors: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }),
  freelancers: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  orderForms: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  searchFreelancers: PropTypes.func.isRequired,
  fetchOrderFormsByAccountId: PropTypes.func.isRequired,
  fetchProjectsByOrderFormId: PropTypes.func.isRequired,
  createPayment: PropTypes.func.isRequired,
  clearPaymentsForm: PropTypes.func.isRequired,
  showErrors: PropTypes.func.isRequired,
  featureToggles: PropTypes.object.isRequired,
};

export default PaymentsNew;
