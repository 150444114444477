import { connect } from "react-redux";
import Attachments from "../Attachments";
import { showModal, hideModal } from "../../../modules/modal";
import WithData from "../../../decorators/WithData";
import { createAttachments } from "../../../modules/attachments";
import { getInitialData } from "../modules/getInitialData";
import { attachmentsByParentDeliverable } from "../../../utils/entitySelector";

const mapDispatchToProps = (dispatch, { parentDeliverableId }) => {
  return {
    getInitialData: () => dispatch(getInitialData({ parentDeliverableId })),
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
    createAttachments: (file, projectId, parentDeliverableId) =>
      dispatch(createAttachments(file, projectId, parentDeliverableId)),
  };
};

const mapStateToProps = (state, { parentDeliverableId }) => {
  const attachments = attachmentsByParentDeliverable(
    state,
    parentDeliverableId
  );
  return {
    attachments,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Attachments));
