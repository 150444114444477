import React, { Component } from "react";

import PropTypes from "prop-types";
import { Field } from "redux-form";
import Input from "../../components/Input";

class WithEnterFieldArrayInput extends Component {
  addInput = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.addField();
      const regex = /(.*)\[(\d+)\]/.exec(this.props.arrayName);

      if (!regex) return;

      const nextFocusIndex = Number(regex[2]) + 1;
      setTimeout(() => {
        const element = document.querySelector(
          `input[name='${regex[1]}[${nextFocusIndex}][${this.props.fieldName}]']`
        );
        if (element) element.focus();
      });
    }
  };

  render() {
    return (
      <Field
        component={Input}
        name={`${this.props.arrayName}[${this.props.fieldName}]`}
        onKeyDown={this.addInput}
        placeholder={this.props.placeholder || ""}
        styles={this.props.styles}
        type={this.props.type}
      />
    );
  }
}

WithEnterFieldArrayInput.propTypes = {
  addField: PropTypes.func.isRequired,
  arrayName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  styles: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default WithEnterFieldArrayInput;
