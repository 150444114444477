import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./SearchResults.module.scss";
import { getLoggedInUserType } from "../../../utils/entitySelector";
import OverlayLoading from "../../../components/OverlayLoading/v2";
import { PersonType } from "../../../modules/people";
import { isRequesting } from "../../../modules/requestTracker";
import { REQUEST_NAME } from "../../../modules/search";

const SearchResults = ({ searchResults }) => {
  const personType = useSelector(getLoggedInUserType);
  const isRequestingResults = useSelector((state) => {
    return isRequesting(state, REQUEST_NAME);
  });
  const isClient = personType === PersonType.Client;

  if (isRequestingResults) {
    return <OverlayLoading />;
  }

  if (!searchResults || searchResults.length === 0) {
    return (
      <div className={styles.refineSearch}>
        Oops... there are no results. Please refine your search terms.
      </div>
    );
  }

  return searchResults.map(
    ({
      batchName,
      batchId,
      projectId,
      projectName,
      parentDeliverableId,
      projectLanguages,
      sourceFieldValue,
      briefingFieldValue,
    }) => {
      const baseUrl = `projects/${projectId}/parent-deliverables/${parentDeliverableId}`;
      const parentDeliverableUrl = isClient
        ? `/clients/${baseUrl}`
        : `/admin/${baseUrl}/edit`;

      return (
        <div className={styles.searchResult} key={parentDeliverableId}>
          <div>
            <div className={styles.linkPreview}>{parentDeliverableUrl}</div>
            <Link className={styles.searchLink} to={parentDeliverableUrl}>
              <span className={styles.searchResultItem}>{batchName}</span>
            </Link>
          </div>
          {!isClient && (
            <>
              <div className={styles.searchResultItem}>
                <span className={styles.heading}>Parent deliverable Id:</span>{" "}
                <span name="parentDeliverableId">{parentDeliverableId}</span>
              </div>
              <div className={styles.searchResultItem}>
                <span className={styles.heading}>Batch Id:</span> {batchId}
              </div>
            </>
          )}
          <div className={styles.searchResultItem}>
            <span className={styles.heading}>Project name:</span> {projectName}
          </div>
          {projectLanguages && (
            <div className={styles.searchResultItem}>
              <span className={styles.heading}>Project languages:</span>{" "}
              {projectLanguages.join(", ")}
            </div>
          )}
          <div className={styles.searchResultItem}>
            <span className={styles.heading}>Source field:</span>{" "}
            {sourceFieldValue}
          </div>
          {briefingFieldValue && (
            <div className={styles.searchResultItem}>
              <span className={styles.heading}>Briefing field:</span>{" "}
              {briefingFieldValue}
            </div>
          )}
          {isClient && (
            <>
              <div className={styles.searchResultItem}>
                <span className={styles.heading}>Quill Parent ID:</span>{" "}
                <span name="parentDeliverableId">{parentDeliverableId}</span>
              </div>
              <div className={styles.searchResultItem}>
                <span className={styles.heading}>Quill Batch ID:</span>{" "}
                {batchId}
              </div>
            </>
          )}
        </div>
      );
    }
  );
};

export default SearchResults;
