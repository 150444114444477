import Completed from "../components/Completed";
import WithData from "../../../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
});

const mapStateToProps = (state) => ({
  featureToggles: state.featureToggles,
});

const ClientProjectDashboardCompleted = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Completed));
export default ClientProjectDashboardCompleted;
