import { createAction } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const ModalType = Object.freeze({
  QualityGradingForm: "QualityGradingForm",
  UpdateAssignments: "UpdateAssignments",
  DiscardChanges: "DiscardChanges",
  ArchiveBatch: "ArchiveBatch",
  EmailLogs: "EmailLogs",
  Reassignment: "Reassignment",
  BackDatePayment: "BackDatePayment",
  ArchivePayment: "ArchivePayment",
  UpdateAssignmentGroups: "UpdateAssignmentGroups",
  ArchiveProject: "ArchiveProject",
  DeleteBatchFeedback: "DeleteBatchFeedback",
  NativeLanguageMismatchAlert: "NativeLanguageMismatchAlert",
});

// ------------------------------------
// Actions
// ------------------------------------
export const showModal = createAction(SHOW_MODAL);
export const hideModal = createAction(HIDE_MODAL);

// ------------------------------------
// Action Handlers
// ------------------------------------

export const actionHandlers = {
  [SHOW_MODAL]: (_, { payload }) => ({
    ...initialState,
    ...payload,
    display: true,
  }),
  [HIDE_MODAL]: () => initialState,
};

export const initialState = {
  data: Object.freeze({}),
  display: false,
  type: null,
};

const reducer = (state = initialState, action) => {
  const handleFn = actionHandlers[action.type];
  return handleFn ? handleFn(state, action) : state;
};
export default reducer;
