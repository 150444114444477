import { createAction } from "redux-actions";
import { upsertData, filterData, removeData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";
import { mergeAssignmentGroupsById } from "../store/mergeRows";
import { markStage } from "./stages";
import { getGraphQL, postGraphQL } from "../../utils/fetch";

// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const assignmentGroupQuery = `assignmentGroup (assignmentGroupId: $assignmentGroupId) {
  assignmentGroupId, inTraining
}`;

export const assignmentGroupsByProjectIdQuery = `assignmentGroups (projectIds: $projectId) {
  assignmentGroupId, inTraining, assignmentCount, completedAssignmentCount
}`;

export const assignmentGroupsByProjectIdQueryV2 = `
  assignmentGroups (projectIds: $projectId, filterByStatus: $filterByStatus) {
    assignmentGroupId, inTraining, assignmentCount, completedAssignmentCount,
    stageId, assigneeId, assigneeFirstName, assigneeLastName, rejected,
    accepted, batchId, batchName, languageCode, amendsRequested, deadline,
    minRate, eligibleForReassignmentCount, projectId, hasRequestPending
}`;

const updateAssignmentGroupDeadline = `mutation updateAssignmentGroupDeadline($assignmentGroupIdArray: [Int], $deadline: String!) {
  updateAssignmentGroupDeadline(assignmentGroupIdArray: $assignmentGroupIdArray, deadline: $deadline)
}`;

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_ASSIGNMENT_GROUP_SUCCESS = "FETCH_ASSIGNMENT_GROUP_SUCCESS";
export const FETCH_PROJECT_ASSIGNMENT_GROUP_SUCCESS =
  "FETCH_PROJECT_ASSIGNMENT_GROUP_SUCCESS";
export const REMOVE_ASSIGNMENT_GROUPS_BY_ID = "REMOVE_ASSIGNMENT_GROUPS_BY_ID";
export const UPDATE_ASSIGNMENT_GROUP = "UPDATE_ASSIGNMENT_GROUP";
export const UPDATE_ASSIGNMENT_GROUPS = "UPDATE_ASSIGNMENT_GROUPS";

// ------------------------------------
// Actions
// ------------------------------------

export const fetchAssignmentGroupSuccess = createAction(
  FETCH_ASSIGNMENT_GROUP_SUCCESS
);
export const fetchProjectAssignmentGroupSuccess = createAction(
  FETCH_PROJECT_ASSIGNMENT_GROUP_SUCCESS
);
export const removeAssignmentGroupsById = createAction(
  REMOVE_ASSIGNMENT_GROUPS_BY_ID
);
export const updateAssignmentGroup = createAction(UPDATE_ASSIGNMENT_GROUP);
export const updateAssignmentGroups = createAction(UPDATE_ASSIGNMENT_GROUPS);

export const markAssignmentGroup = (assignmentGroupId) => {
  return async (dispatch, getState) => {
    const {
      assignmentGroups: { entities: assignmentGroups },
    } = getState();

    // Toggle the marked property for the current assignment group
    const updatedAssignmentGroup = {
      ...assignmentGroups[assignmentGroupId],
      marked: !assignmentGroups[assignmentGroupId].marked,
    };

    dispatch(updateAssignmentGroup(updatedAssignmentGroup));
  };
};

export const markAllStageAssignmentGroups = (
  stageId,
  batchId,
  languageCode,
  assigneeId,
  { projectId }
) => {
  return async (dispatch, getState) => {
    const {
      assignmentGroups: { entities: assignmentGroups },
    } = getState();

    const stageAssignmentGroupIds = Object.values(assignmentGroups)
      .filter(
        (ag) =>
          ag.stageId === stageId &&
          ag.projectId === Number(projectId) &&
          (languageCode === "all" || ag.languageCode === languageCode) &&
          (batchId == null || ag.batchId === batchId) &&
          (assigneeId == null || ag.assigneeId === assigneeId)
      )
      .map((ag) => ag.assignmentGroupId);

    const everyStageMarked = Object.values(assignmentGroups)
      .filter(
        (ag) => ag.stageId === stageId && ag.projectId === Number(projectId)
      )
      .every((ag) => ag.marked);

    dispatch(
      updateAssignmentGroups({
        ids: stageAssignmentGroupIds,
        marked: everyStageMarked ? false : true,
      })
    );
  };
};

export function updateAssignmentGroupDeadlines(assignmentGroupIds, deadline) {
  return async (dispatch) => {
    await postGraphQL(
      updateAssignmentGroupDeadline,
      { assignmentGroupIdArray: assignmentGroupIds, deadline },
      "updateAssignmentGroupDeadline"
    );

    dispatch(
      updateAssignmentGroups({
        ids: assignmentGroupIds,
        deadline,
      })
    );

    return assignmentGroupIds;
  };
}

export function fetchAllAssignmentGroups(projectId) {
  return async (dispatch) => {
    const initialQuery = `query ProjectsShowInProduction ($projectId: String, $filterByStatus: Boolean) {
      ${assignmentGroupsByProjectIdQueryV2}
    }`;

    const json = await getGraphQL(initialQuery, {
      projectId,
      filterByStatus: false,
    });

    const assignmentGroups = mergeAssignmentGroupsById(json.assignmentGroups);

    dispatch(
      fetchProjectAssignmentGroupSuccess({
        assignmentGroups,
        projectId,
      })
    );
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

export const assignmentGroupActionHandlers = {
  [RESET_INITIAL_STATE]: () => assignmentGroupInitialState,
  // the backend returns assignment-groups in groups with different rates, so we finally merge them before inserting here
  [FETCH_ASSIGNMENT_GROUP_SUCCESS]: (state, { payload }) =>
    upsertData(state, mergeAssignmentGroupsById(payload), "assignmentGroupId"),
  [FETCH_PROJECT_ASSIGNMENT_GROUP_SUCCESS]: (state, { payload }) => {
    const projectId = Number(payload.projectId);

    // Create object of assignmentGroupId keys
    const idWhitelist = {};
    payload.assignmentGroups.forEach((group) => {
      idWhitelist[group.assignmentGroupId] = true;
    });

    // Filter out groups that no longer exist
    const filteredState = filterData(state, (group) => {
      // Don't filter out groups from other projects
      if (group.projectId !== projectId) return true;

      // Filter out groups that aren't in the new data
      return idWhitelist[group.assignmentGroupId] === true;
    });

    // Update existing groups and add new groups
    return upsertData(
      filteredState,
      payload.assignmentGroups,
      "assignmentGroupId"
    );
  },
  [REMOVE_ASSIGNMENT_GROUPS_BY_ID]: (state, { payload }) =>
    removeData(state, payload),
  [UPDATE_ASSIGNMENT_GROUP]: (state, { payload }) => {
    return upsertData(state, payload, "assignmentGroupId");
  },
  [UPDATE_ASSIGNMENT_GROUPS]: (
    { entities: oldEntities, result },
    { payload: { marked, ids, deadline } }
  ) => {
    const entities = ids.reduce(
      (acc, id) => {
        if (oldEntities[id]) {
          acc[id] = {
            ...oldEntities[id],
            ...(marked !== undefined && { marked }),
            ...(deadline !== undefined && { deadline }),
          };
        }
        return acc;
      },
      { ...oldEntities }
    );

    return { entities, result };
  },
};

export const assignmentGroupInitialState = { entities: {}, result: [] };
