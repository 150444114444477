import { getGraphQL } from "../../../../../../../../utils/fetch";
import {
  deliverableInfoByDeliverableIdQuery,
  fetchDeliverablesSuccess,
} from "../../../../../../../modules/deliverables";

export const query = `query DeliverableInfoDataRequest ($deliverableId: String) {
  ${deliverableInfoByDeliverableIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(query, params, url, cookies).then((json) => {
      dispatch(fetchDeliverablesSuccess(json.deliverable));
    });
  };
}
