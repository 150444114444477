import PropTypes from "prop-types";
import { Field } from "redux-form";
import { RemoveButton } from "../../../../../../../../components/Buttons";
import FeatureToggle from "../../../../../../../../components/FeatureToggle";
import DragButton from "../../../../../../../../components/DragButton";
import WithEnterFieldArrayInput from "../../../../../../../../components/WithEnterFieldArrayInput";
import { ReduxFormCompatibleSelect as Select } from "../../../../../../../../decorators/ReduxFormCompatible";
import ReduxMultiSelect from "../MultiSelect";
import styles from "./FieldsWithFormat.module.scss";

const FIELD_TYPE_TASK_FIELDS = "taskFields";

const FieldsWithFormat = (props) => {
  const {
    connectDropTarget,
    connectDragPreview,
    featureToggles = {},
    isLocalisationProject,
    isOver,
    type,
    rateBandOptions,
    isAiWorkflow,
    hasAiContentModel,
  } = props;

  const opacity = isOver ? 0 : 1;

  const options = [
    { label: "HTML", value: "HTML" },
    { label: "Plain text", value: "Text" },
    { label: "Image", value: "Image" },
  ];

  const isTaskFieldType = type === FIELD_TYPE_TASK_FIELDS;

  // we only show hyperlinks for briefing fields
  if (type === "briefingFields") {
    options.push({ label: "Hyperlink", value: "Hyperlink" });
    if (isAiWorkflow) {
      options.push(
        { label: "AI content model", value: "AI content model" },
        { label: "AI attribute", value: "AI attribute" }
      );
    }
  }

  if (
    featureToggles.QCC_1505_translationMemory &&
    isTaskFieldType &&
    isLocalisationProject
  ) {
    options.push(
      ...[
        {
          label: "HTML (Translation memory)",
          value: "HTML (Translation memory)",
        },
        { label: "TM (bullets)", value: "TM (bullets)" },
        { label: "TM (prose)", value: "TM (prose)" },
      ]
    );
  }

  return connectDropTarget(
    connectDragPreview(
      <div className={styles.briefFields} style={{ opacity }}>
        <WithEnterFieldArrayInput
          addField={props.addField}
          arrayName={props.name}
          fieldName={props.inputFieldName}
          placeholder={props.placeholder}
          styles={styles.field}
          type="text"
        />
        <Field
          clearable={false}
          component={Select}
          name={`${props.name}[${props.selectFieldName}]`}
          options={options}
          hasAiContentModel={hasAiContentModel}
          styles={styles.field}
          type="text"
        />

        <FeatureToggle toggle="taskFieldRateBands">
          {isTaskFieldType && (
            <Field
              clearable={false}
              component={ReduxMultiSelect}
              name={`${props.name}[rateBands]`}
              styles={styles.field}
              options={rateBandOptions}
              type="text"
            />
          )}
        </FeatureToggle>

        <DragButton connectDragSource={props.connectDragSource} />
        <RemoveButton
          disabled={props.disableRemove}
          onClick={props.removeField}
        />
      </div>
    )
  );
};

FieldsWithFormat.propTypes = {
  disableRemove: PropTypes.bool.isRequired,
  inputFieldName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  removeField: PropTypes.func.isRequired,
  selectFieldName: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default FieldsWithFormat;
