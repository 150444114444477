import gql from "graphql-tag";
import { createAction } from "redux-actions";
import { getGraphQL } from "../../utils/fetch";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";

const fields = [
  "grammarCheckIgnoreId",
  "personId",
  "taskFieldId",
  "projectId",
  "batchId",
  "ignoreType",
  "ignoreRule",
  "ignoreWord",
];

// inside of queries and mutations
export const GET_GRAMMAR_CHECK_IGNORES = `
  grammarCheckIgnores (projectId: $projectId, deliverableId: $deliverableId) {
    ${fields}
  }
`;

// mutations
export const CREATE_GRAMMAR_CHECK_MUTATION = gql`
  mutation createGrammarCheckIgnore ($input: GrammarCheckIgnoreInput) {
    createGrammarCheckIgnore (input: $input) {
      ${fields}
    }
  }
`;

// queries
export const FETCH_GRAMMAR_CHECK_IGNORES = gql`
  query fetchGrammarCheckIgnores ($projectId: String, $deliverableId: String) {
    ${GET_GRAMMAR_CHECK_IGNORES}
  }
`;

const FETCH_GRAMMAR_IGNORES_SUCCESS = "FETCH_GRAMMAR_IGNORES_SUCCESS";
export const fetchGrammarCheckIgnoresSuccess = createAction(
  FETCH_GRAMMAR_IGNORES_SUCCESS
);

export const fetchGrammarCheckIgnores = (projectId) => {
  return async (dispatch) => {
    const { grammarCheckIgnores } = await getGraphQL(
      FETCH_GRAMMAR_CHECK_IGNORES as any,
      { projectId }
    );
    dispatch(fetchGrammarCheckIgnoresSuccess(grammarCheckIgnores));
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
export const grammarCheckIgnoreActionHandlers = {
  [RESET_INITIAL_STATE]: () => grammarCheckIgnoreInitialState,
  [FETCH_GRAMMAR_IGNORES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "grammarCheckIgnoreId"),
};

export const grammarCheckIgnoreInitialState = { entities: {}, result: [] };
