import React, { useState } from "react";
import { ModalType } from "../../../../../modules/modal";
import Modal from "../../../../../components/Modal";
import styles from "./PaymentList.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PaymentBackDateModal = ({
  deselectAll,
  showModal,
  hideModal,
  checkboxStates,
  backdatePayments,
}) => {
  const [date, setStartDate] = useState(new Date());

  const paymentIdsArray = Object.keys(checkboxStates).filter((key) => {
    return checkboxStates[key].checked === true;
  });

  const paymentSelected = paymentIdsArray.length === 0;

  const handleSelected = () => {
    showModal({ type: ModalType.BackDatePayment });
  };

  const handleBackdate = async (paymentIds) => {
    const data = {
      date,
      paymentIds: paymentIdsArray,
    };

    await backdatePayments(data);
    deselectAll();
    hideModal();
  };
  return (
    <div>
      <button
        onClick={handleSelected}
        disabled={paymentSelected}
        className={styles.backdate}
      >
        Edit payment date
      </button>
      <Modal
        /* @ts-ignore */
        title="Edit payment date"
        type={ModalType.BackDatePayment}
        body={
          <div className={styles.container}>
            <DatePicker
              className={styles.datepickerInput}
              portalId="root-portal"
              popperClassName={styles.datePopper}
              selected={date}
              onChange={(date) => setStartDate(date)}
            />
            <button onClick={handleBackdate} className={styles.backdate}>
              Edit payment date
            </button>
          </div>
        }
      />
    </div>
  );
};

export default PaymentBackDateModal;
