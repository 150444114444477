import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../../../../components/PageHeader";
import Errors from "../../../../../../../../../components/Errors";
import AssignmentEditByDeliverable from "../../../../../../../../../components/AssignmentEdit/_decorators/ByDeliverable";
import BatchFeedbackButton from "../../../../../../../../../components/AssignmentEdit/_components/BatchFeedbackButton";
import ProjectInfo from "../../../../../../../../../components/PageHeader/ProjectInfo";
import styles from "./ClientDeliverable.module.scss";

export default function ClientDeliverable({
  batchId,
  batchName,
  deliverableId,
  errors,
  history,
  isCommentable,
  languageCode,
  listUrl,
  nextUrl,
  previousUrl,
  projectId,
  project: { accountName, orderFormName, projectName },
  shouldShowLanguageMismatchModal,
  stageId,
  stageName,
}) {
  return (
    <div>
      <Helmet>
        <title>Task List</title>
      </Helmet>

      {errors.display && <Errors />}

      <PageHeader
        breadCrumbItems={[
          { url: "/clients", text: `${accountName}` },
          {
            url: `/clients/projects/${projectId}`,
            text: `${orderFormName} | ${projectName}`,
          },
          { url: listUrl, text: batchName },
          { text: stageName },
        ]}
        title={`${projectName} ${batchName} tasks: ${stageName}`}
      >
        <div className={styles.headerRight}>
          <ProjectInfo url={`/clients/projects/${projectId}/project-info`} />
          <BatchFeedbackButton
            batchId={batchId}
            languageCode={languageCode}
            stageId={stageId}
            projectId={projectId}
          />
        </div>
      </PageHeader>

      <AssignmentEditByDeliverable
        batchId={batchId}
        deliverableId={deliverableId}
        history={history}
        isCommentable={isCommentable}
        isCurrentStage={false}
        isEditable={false}
        listUrl={listUrl}
        nextUrl={nextUrl}
        previousUrl={previousUrl}
        projectId={projectId}
        shouldShowLanguageMismatchModal={shouldShowLanguageMismatchModal}
        stageId={stageId}
      />
    </div>
  );
}

ClientDeliverable.propTypes = {
  batchId: PropTypes.number,
  batchName: PropTypes.string,
  deliverableId: PropTypes.number.isRequired,
  errors: PropTypes.object,
  history: PropTypes.object,
  isCommentable: PropTypes.bool,
  languageCode: PropTypes.string,
  listUrl: PropTypes.string,
  nextUrl: PropTypes.string,
  previousUrl: PropTypes.string,
  project: PropTypes.object,
  projectId: PropTypes.number.isRequired,
  shouldShowLanguageMismatchModal: PropTypes.bool,
  stageId: PropTypes.number.isRequired,
  stageName: PropTypes.string.isRequired,
};
