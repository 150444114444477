import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  InputWithErrors,
  SelectWithErrors,
} from "../../../../../decorators/WithErrors";
import { QuillForm, QuillFormField } from "../../../../../components/QuillForm";
import styles from "./ProjectForm.module.scss";
import validate from "./validations";
import FeatureToggle from "../../../../../components/FeatureToggle";

const returnNumber = (val) => Number(val) || 0;

let ProjectForm = class ProjectForm extends Component {
  constructor(props) {
    super(props);

    const {
      initialValues: { workflowId },
      workflows: { entities },
    } = props;

    this.state = {
      workflowType: workflowId && entities[workflowId].workflowType,
    };
  }

  render() {
    const {
      cancelUrl,
      clientTeam,
      contentTypes,
      editableClientStagesValues,
      currencies,
      disabledForEdits,
      error,
      handleSubmit,
      languages,
      orderFormIds,
      productionTeam,
      submitting,
      valid,
      workflows: { dropdowns: workflowDropdowns, entities: workflowEntities },
    } = this.props;

    const isLocalisationWorkflow = this.state.workflowType === "Localisation";
    return (
      <div className={styles.container}>
        <QuillForm
          cancelText="Cancel"
          cancelUrl={cancelUrl}
          disabled={submitting || !valid}
          error={error}
          handleSubmit={handleSubmit}
          header="Project information"
          submitText="Save project"
          formContainerStyles={styles.projectFormContainer}
          rebrandingContainer
        >
          <QuillFormField
            component={SelectWithErrors}
            disabled
            hidden
            name="orderFormId"
            options={orderFormIds}
            parse={returnNumber}
            type="number"
          />
          <QuillFormField
            component={InputWithErrors}
            disabled={submitting}
            labelText="Project name"
            name="projectName"
            required
            type="text"
          />
          <QuillFormField
            clearable={false}
            component={SelectWithErrors}
            disabled={submitting}
            labelText="Content type"
            name="contentTypeId"
            options={contentTypes}
            parse={returnNumber}
            required
            type="number"
          />
          <QuillFormField
            clearable={false}
            component={SelectWithErrors}
            disabled={submitting || disabledForEdits}
            labelText="Workflow"
            name="workflowId"
            onChange={(_, workflowId) => {
              if (workflowEntities[workflowId]) {
                this.setState({
                  workflowType: workflowEntities[workflowId].workflowType,
                });
              }
            }}
            options={workflowDropdowns}
            parse={returnNumber}
            required
            type="number"
          />
          <QuillFormField
            clearable={false}
            component={SelectWithErrors}
            disabled={submitting}
            labelText="Editable Client Stages"
            name="editableClientStages"
            options={editableClientStagesValues}
            required
            type="string"
          />
          <QuillFormField
            component={InputWithErrors}
            customContainerStyle={styles.inputContainer}
            labelText="quoting"
            name="quoting"
            styles={styles.checkbox}
            type="checkbox"
          />
          <QuillFormField
            component={InputWithErrors}
            disabled={submitting}
            labelText="Brief link"
            name="briefLink"
            type="text"
          />
          {isLocalisationWorkflow && (
            <QuillFormField
              clearable={false}
              component={SelectWithErrors}
              deleteRemoves={false}
              disabled={submitting}
              labelText="Source Language"
              name="sourceLanguage"
              options={languages}
              required
            />
          )}
          <QuillFormField
            clearable={false}
            component={SelectWithErrors}
            deleteRemoves={false}
            disabled={submitting}
            labelText={
              isLocalisationWorkflow ? "Target Languages" : "Languages"
            }
            multi
            name="projectLanguages"
            options={languages}
            required
          />
          <QuillFormField
            clearable={false}
            component={SelectWithErrors}
            deleteRemoves={false}
            disabled={submitting}
            labelText="Currency"
            name="currencyCode"
            options={currencies}
            required
          />
          <QuillFormField
            component={SelectWithErrors}
            disabled={submitting}
            labelText="In-house team"
            multi
            name="productionTeam"
            options={productionTeam}
          />
          <QuillFormField
            component={SelectWithErrors}
            disabled={submitting}
            labelText="Client team"
            multi
            name="clientTeam"
            options={clientTeam}
          />
          <QuillFormField
            component={InputWithErrors}
            disabled={submitting}
            labelText="Client Code (FinancialForce)"
            name="clientCodeFinancialForce"
            type="text"
          />
          <QuillFormField
            component={InputWithErrors}
            disabled={submitting}
            labelText="FF Project Id (FinancialForce)"
            name="projectIdFinancialForce"
            type="text"
          />
          <FeatureToggle toggle="clientBatchNotifications">
            <QuillFormField
              component={InputWithErrors}
              customContainerStyle={styles.inputContainer}
              labelText="Targeted client notifications (add clients per batch)"
              name="clientBatchNotifications"
              styles={styles.checkbox}
              type="checkbox"
            />
          </FeatureToggle>
        </QuillForm>
      </div>
    );
  }
};

ProjectForm.propTypes = {
  cancelUrl: PropTypes.string.isRequired,
  clientTeam: PropTypes.array.isRequired,
  contentTypes: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  disabledForEdits: PropTypes.bool,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  orderFormIds: PropTypes.array.isRequired,
  productionTeam: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  workflows: PropTypes.object.isRequired,
};

ProjectForm = reduxForm({
  form: "project",
  validate,
})(ProjectForm);

export default ProjectForm;
