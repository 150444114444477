import {
  checkValidations,
  isNotBlank,
} from "../../../../../../../utils/validations";

const validate = (values) => {
  const errors = {};

  const batchNameErrors = checkValidations(values.batchName, [
    { validate: isNotBlank, msg: "Required" },
  ]);

  if (batchNameErrors) errors.batchName = batchNameErrors;

  return errors;
};

export default validate;
