import { getGraphQL } from "../../../../../utils/fetch";
import { fetchFavouritesSuccess } from "../../../../modules/favourites";
import { fetchHiddenSuccess } from "../../../../modules/hidden";
import {
  parentAccountsQuery,
  fetchParentAccountsSuccess,
} from "../../../../modules/parentAccounts";

export const initialQuery = `query getParentAccountAndAccounts {
  ${parentAccountsQuery}
  hidden {
    hiddenId, parentAccountId, accountId, orderFormId, projectId
  }
  favourites {
    favouriteId, parentAccountId, accountId, orderFormId, projectId
  }  
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch, getState) => {
    const { parentAccounts, hidden, favourites } = await getGraphQL(
      initialQuery,
      params,
      url,
      cookies
    );
    dispatch(fetchParentAccountsSuccess(parentAccounts));
    dispatch(fetchHiddenSuccess(hidden));
    dispatch(fetchFavouritesSuccess(favourites));
  };
}
