import stateFromHTML from "../utils/stateFromHTML";
// These functions taken from draft-js-counter-plugin

function convertToPlainText(text, format) {
  if (text.length === 0) return "";

  const formatLc = format.toLowerCase();
  if (formatLc === "image") return "";
  if (formatLc === "text") return text;

  // if html change it to plain text
  return stateFromHTML(text).getPlainText("");
}

/**
 * Counts the number of words in a plaintext string
 *
 * @param {String} plaintext
 */
export function plaintextWordCount(plaintext) {
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plaintext.replace(regex, " ").trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray.length : 0;
}

/**
 * @param {String} value task field content value
 * @param {String} format task field format type
 * @param {String} text if we have a plaintext version already
 */
export function getWordCount(value, format, text) {
  const plaintext = text || convertToPlainText(value, format);
  return plaintextWordCount(plaintext);
}

export function getCharacterCount(value, format, text) {
  const plaintext = text || convertToPlainText(value, format);
  return plaintext.length;
}
