import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";

const taskFieldRateBandFields = "taskFieldId, rateBandId";

export const taskFieldRateBandsQuery = `taskFieldRateBands (projectId: $projectId) {
  ${taskFieldRateBandFields}
}`;

export const REPLACE_TASK_FIELD_RATE_BANDS_SUCCESS =
  "REPLACE_TASK_FIELD_RATE_BANDS_SUCCESS";

export const fetchTaskFieldRateBandsSuccess = createAction(
  REPLACE_TASK_FIELD_RATE_BANDS_SUCCESS
);

export const taskFieldRateBandActionHandlers = {
  [RESET_INITIAL_STATE]: () => taskFieldRateBandInitialState,
  [REPLACE_TASK_FIELD_RATE_BANDS_SUCCESS]: (_, { payload }) => {
    return payload.reduce((acc, { taskFieldId, rateBandId }) => {
      if (!acc[taskFieldId]) acc[taskFieldId] = [];
      acc[taskFieldId].push(Number(rateBandId));
      return acc;
    }, {});
  },
};

export const taskFieldRateBandInitialState = {};
