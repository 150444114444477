import { connect } from "react-redux";
import { PersonType } from "../../modules/people";
import { getLoggedInUser } from "../../utils/entitySelector";
import ClientBatchStage from "./ClientBatchStage";

const mapStateToProps = (state) => ({
  isClient: getLoggedInUser(state).personType === PersonType.Client,
});

export default connect(mapStateToProps)(ClientBatchStage);
