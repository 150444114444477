import { connect } from "react-redux";

import UnauthorizedLayout from "../components/UnauthorizedLayout";

export const mapDispatchToProps = (dispatch, ownProps) => ({});

export const mapStateToProps = (state, ownProps) => ({
  me: state.people.entities[state.me],
});

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedLayout);
