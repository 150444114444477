import { getGraphQL } from "../../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../modules/projects";
import {
  briefingFieldsByProjectIdQuery,
  fetchBriefingFieldsSuccess,
} from "../../../../../../../modules/briefingFields";
import {
  keywordGroupsByProjectIdQuery,
  fetchKeywordGroupsSuccess,
} from "../../../../../../../modules/keywordGroups";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../modules/batches";
import {
  parentDeliverableQuery,
  fetchParentDeliverableSuccess,
  fetchParentDeliverablesSuccess,
  batchParentDeliverablesByParentDeliverableIdQuery,
} from "../../../../../../../modules/parentDeliverables";
import {
  parentDeliverableBriefingFieldsByParentDeliverableIdQuery,
  fetchParentDeliverableBriefingFieldsSuccess,
} from "../../../../../../../modules/parentDeliverableBriefingFields";
import {
  keywordsByParentDeliverableIdQuery,
  fetchKeywordsSuccess,
} from "../../../../../../../modules/keywords";
import {
  sourceFieldsByProjectIdQuery,
  fetchSourceFieldsSuccess,
} from "../../../../../../../modules/sourceFields";
import {
  parentDeliverableSourceFieldsByParentDeliverableIdQuery,
  fetchParentDeliverableSourceFieldsSuccess,
} from "../../../../../../../modules/parentDeliverableSourceFields";
import {
  rateBandsByProjectIdQuery,
  fetchRateBandsSuccess,
} from "../../../../../../../modules/rateBands";
import {
  taskFieldsByProjectQuery,
  fetchTaskFieldsSuccess,
} from "../../../../../../../modules/taskFields";

export const initialQuery = `query ProjectParentDeliverablesNew($projectId: String, $parentDeliverableId: String) {
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${projectQuery}
  ${briefingFieldsByProjectIdQuery}
  ${keywordGroupsByProjectIdQuery}
  ${batchesByProjectIdQuery}
  ${parentDeliverableQuery}
  ${parentDeliverableBriefingFieldsByParentDeliverableIdQuery}
  ${keywordsByParentDeliverableIdQuery}
  ${sourceFieldsByProjectIdQuery}
  ${parentDeliverableSourceFieldsByParentDeliverableIdQuery}
  ${rateBandsByProjectIdQuery}
  ${taskFieldsByProjectQuery}
  ${batchParentDeliverablesByParentDeliverableIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchParentAccountsSuccess(json.parentAccounts));
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchBriefingFieldsSuccess(json.briefingFields));
      dispatch(fetchKeywordGroupsSuccess(json.keywordGroups));
      dispatch(fetchBatchesSuccess(json.batches));
      dispatch(fetchParentDeliverableSuccess(json.parentDeliverable));
      dispatch(fetchParentDeliverablesSuccess(json.parentDeliverables));
      dispatch(
        fetchParentDeliverableBriefingFieldsSuccess(
          json.parentDeliverableBriefingFields
        )
      );
      dispatch(fetchKeywordsSuccess(json.keywords));
      dispatch(fetchSourceFieldsSuccess(json.sourceFields));
      dispatch(
        fetchParentDeliverableSourceFieldsSuccess(
          json.parentDeliverableSourceFields
        )
      );
      dispatch(fetchRateBandsSuccess(json.rateBands));
      dispatch(fetchTaskFieldsSuccess(json.taskFields));
    });
  };
}
