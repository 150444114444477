import { createSelector } from "reselect";

const assignmentGroupsGrouped = (assignmentGroups) => {
  const tree = assignmentGroups.reduce((acc, ag) => {
    const { assignmentGroupId, rate, count } = ag;

    if (!acc[assignmentGroupId]) {
      acc[assignmentGroupId] = {
        ...ag,
        count: 0,
        totalRate: 0,
      };
    }

    acc[assignmentGroupId].count += count;
    acc[assignmentGroupId].totalRate += rate * count;

    return acc;
  }, {});

  return Object.entries(tree)
    .map(([_, assignmentGroup]) => assignmentGroup)
    .sort((a, b) => new Date(a.deadline) - new Date(b.deadline));
};

/**
 * Returns a createSelector function which filters data by a passed in function
 * @param fn the filter function for the dashboardAssignments
 */
export const createDashboardGroupingSelector = (fn) => {
  return createSelector(
    (state) => state.freelancerDashboard,
    (dashboardAssignments) =>
      assignmentGroupsGrouped(dashboardAssignments.filter(fn))
  );
};
