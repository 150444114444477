import { connect } from "react-redux";
import AIEnhancement from "../components/AIEnhancement";
import WithData from "../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { fetchProjectsByAccountId } from "../../../../../modules/projects";
import {
  accountsDropDownSelector,
  briefingFieldsDropdownSelector,
  projectsDropdownSelector,
} from "../../../../../utils/entitySelector";
import { fetchBriefingFields } from "../../../../../modules/briefingFields";
import { createSelector } from "reselect";
import { downloadCSVTemplate } from "../modules/downloadCSVTemplate";
import { processFileHandler } from "../modules/handleFile";

const AI_WORKFLOW_NAMES = [
  "AI Creation (1 Client Review)",
  "AI / AMT Localisation (1 Client Review)",
];

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
    fetchProjectsByAccountId: (accountId) =>
      dispatch(fetchProjectsByAccountId(accountId)),
    fetchBriefingFields: (projectId) => {
      dispatch(fetchBriefingFields(projectId));
    },
    downloadCSVTemplate: (projectId) =>
      dispatch(downloadCSVTemplate(projectId)),
    processFile: (data) => dispatch(processFileHandler(data, ownProps.history)),
  };
};

const workflowIdsSelector = createSelector(
  (state) => state.workflows.entities,
  (workflows) =>
    Object.values(workflows)
      .filter(({ workflowName }) => AI_WORKFLOW_NAMES.includes(workflowName))
      .map(({ workflowId }) => workflowId)
);

const projectSelector = createSelector(
  workflowIdsSelector,
  projectsDropdownSelector,
  (workflowIds, projects) => {
    return projects.filter(({ object: { workflowId } }) => {
      return workflowIds.includes(workflowId);
    });
  }
);

const mapStateToProps = (state) => {
  const accounts = accountsDropDownSelector(state);
  const projects = projectSelector(state);

  const attributeFields = briefingFieldsDropdownSelector(state).filter(
    ({ object }) => object.briefingFieldFormat === "AI attribute"
  );

  return {
    accounts,
    projects,
    attributeFields,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AIEnhancement));
