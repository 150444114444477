import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import PageHeader from "../../../../components/PageHeader";
import SearchResults from "../../../../components/Search/SearchResults";
import styles from "./Search.module.scss";
import OverlayLoading from "../../../../components/OverlayLoading/v2";
import parseQuery, { handleQuery } from "../../../../utils/parseQuery";
import qs from "query-string";

import Select from "react-select";

const Search = ({
  dataReady,
  searchResults,
  account: { accountName },
  searchTerm,
  location,
  history,
  updateSearch,
  searchArchivedValues,
}) => {
  const selectArchivedOptions = Object.values(searchArchivedValues);

  const [loading, setLoading] = useState(true);
  const [isUpdating, update] = useState(false);
  const [prevResults, setPrevResults] = useState();
  const [archivedValue, setArchivedValue] = useState(
    searchArchivedValues.UNARCHIVED
  );
  useEffect(() => {
    if (loading && searchResults.length === 0) {
      setTimeout(() => {
        setLoading(false);
      }, 20000);
    }
    if (prevResults !== searchResults) {
      update(false);
    }
    setPrevResults(searchResults);
  }, [loading, searchResults, prevResults]);

  const updateQuerySearch = (term, value) => {
    const query = handleQuery(parseQuery(location.search), term, value);

    const search = qs.stringify(query);

    // only push a new history state if the params are different
    if (location.search !== `?${search}`) {
      history.push({ search });
    }
    return query;
  };

  const handleKeyPress = async (event) => {
    //it triggers by pressing the enter key
    if (event.charCode === 13) {
      event.preventDefault();
      const searchValue = event.target.value;
      const queryParams = updateQuerySearch("searchTerm", searchValue);
      triggerUpdateSearch(queryParams, archivedValue);
    }
  };

  const handleSelectArchived = (selection) => {
    setArchivedValue(selection);
    const queryParams = handleQuery(parseQuery(location.search));
    triggerUpdateSearch(queryParams, selection.value);
  };

  const triggerUpdateSearch = (queryParams, searchArchived) => {
    const params = { ...queryParams, searchArchived };
    updateSearch(params);
    update(true);
  };

  return (
    <div>
      <Helmet>
        <title>Search results</title>
      </Helmet>
      <div>
        <PageHeader
          breadCrumbItems={[
            { url: "/admin/account", text: "Search" },
            { text: accountName },
            { text: "Search results by Batch name" },
          ]}
          title={`Search results for "${searchTerm}"`}
        >
          <div className={styles.headerRight}>
            <Select
              className="archived-select"
              clearable={false}
              labelText="Archived Status"
              name="archivedSearch"
              options={selectArchivedOptions}
              value={archivedValue}
              onChange={handleSelectArchived}
              required
            />
            <div className={styles.searchContainer}>
              <input
                className={styles.searchInput}
                onKeyPress={handleKeyPress}
              />
              <svg
                className={styles.searchIcon}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
              ></svg>
            </div>
          </div>
        </PageHeader>
      </div>
      {((loading && searchResults.length === 0) || isUpdating) &&
        !dataReady && <OverlayLoading />}

      {searchResults.length === 0 && dataReady && (
        <div className={styles.refineSearch}>
          Oops... there are no results. Please refine your search terms.
        </div>
      )}

      {searchResults.length > 0 && (
        <SearchResults searchResults={searchResults} />
      )}
    </div>
  );
};

export default Search;
