import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../modules/orderForms";

import {
  bannedWordsByProjectIdQuery,
  fetchBannedWordsSuccess,
} from "../../../../../../modules/bannedWords";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../modules/projectLanguages";

export const initialQuery = `query AdminProjectsInfo ($projectId: String) {
  ${parentAccountsByProjectQuery}
  ${accountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
  ${bannedWordsByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchParentAccountsSuccess(json.parentAccounts));
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchBannedWordsSuccess(json.bannedWords));
      dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
    });
  };
}
