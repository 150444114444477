import { createQueryString } from "../client/utils/url";

class StatusError extends Error {
  constructor(status, statusText, errors, data = null) {
    super(statusText);
    this.name = "StatusError";
    this.status = status;
    this.data = data;
    this.errors = errors;
  }
}

function checkStatus(status, statusText, json) {
  const { message, errors, data } = json;
  if (status < 200 || status > 300 || errors) {
    // we currently display stringified errors but we may want to remove this and show
    // a less detailed error later on...
    const stringifiedErrors =
      !message && statusText === "OK" && errors
        ? JSON.stringify(errors, null, 4)
        : "";
    throw new StatusError(
      status,
      stringifiedErrors || message || statusText,
      errors,
      data
    );
  } else {
    return json;
  }
}

export const fetchWithThrow = async (url, data, tries = 5) => {
  try {
    const response = await fetch(url, data);
    const contentType = response.headers.get("content-type");
    let payload;

    if (contentType && contentType.includes("application/json")) {
      payload = await response.json();
    } else {
      payload = await response.text();
    }

    if (response.status < 200 || response.status > 300) {
      throw new Error(payload);
    }

    checkStatus(response.status, response.statusText, payload);

    return payload;
  } catch (error) {
    if (tries === 0) throw error;

    await new Promise((resolve) => setTimeout(resolve, 2000));

    return fetchWithThrow(url, data, tries - 1);
  }
};

/**
 * @param   {string} query            GraphQL query
 * @param   {Object} variables        query parameters
 * @param   {string} [apiGatewayUrl]  custom base api gateway url (used for server-side render)
 * @param   {string} [cookie]         cookie data (used for server-side render)
 * @returns {Object} response data
 */
export function getGraphQL(query, variables, apiGatewayUrl, cookie) {
  return postGraphQL(query, variables, null, cookie, apiGatewayUrl);
}

/**
 * @param   {string} query          GraphQL query
 * @param   {Object} variables      query parameters
 * @param   {string} [responseKey]  subkey of returned data to return
 * @param   {string} [cookie]       cookie data (used for server-side render)
 * @param   {string} [baseUrl]      custom base api gateway url (used for
 *  server-side render)
 * @returns {Object} response data
 */
export async function postGraphQL(
  query,
  variables,
  responseKey,
  cookie,
  baseUrl = process.env.REACT_APP_API_GATEWAY || window.__API_GATEWAY__
) {
  const { data } = await fetchWithThrow(`${baseUrl}/graphql`, {
    method: "POST",
    headers: { "Content-Type": "application/json", cookie },
    body: JSON.stringify({ query, variables }),
    credentials: "include",
  });

  if (responseKey) return data[responseKey];
  return data;
}

/**
 * Send back data from the request response or throw an error
 *
 * @param {Object} response
 */
async function processResponse(response) {
  const json = await response.json();
  const { error, data } = json;

  if (error) throw new Error(error.message);

  if (data) return data;

  return json;
}

/**
 * Send a POST request
 *
 * @param   {string}  url
 * @param   {Object}  request  request body
 * @returns {Object}  data response
 */
export async function postJson(url, request) {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return processResponse(response);
}

/**
 * Send a GET request
 *
 * @param {string} url
 * @param {Object} data data to send as a querystring
 * @returns {Object} data response
 */
export async function getJson(url, data = {}) {
  const queryString = createQueryString(data);
  const response = await fetch(`${url}?${queryString}`);

  return processResponse(response);
}
