import React, { Component } from "react";
import PropTypes from "prop-types";

function renderFieldArray(HeaderComponent, FieldComponent, NewFieldAddButton) {
  class RenderFieldArray extends Component {
    componentWillMount() {
      if (this.props.fields.length === 0)
        this.props.fields.push(this.props.defaultValues || {});
    }

    addField = () => {
      const { fields, requiredFieldNames } = this.props;
      const index = fields.length - 1;
      const lastField = fields.get(index);

      // if no values in the last field, don't add a field
      if (Object.keys(lastField).length === 0) return;

      // if there are requiredFieldNames that are not there, don't add a field
      if (requiredFieldNames) {
        for (var i = 0; i < requiredFieldNames.length; i++) {
          if (!lastField.hasOwnProperty(requiredFieldNames[i])) return;
        }
      }

      return fields.push(this.props.defaultValues || {});
    };

    render() {
      const { fields, meta, ...rest } = this.props;
      return (
        <div>
          <HeaderComponent addField={this.addField} meta={meta} {...rest} />
          {fields.map((field, fieldIndex) => {
            return (
              <FieldComponent
                name={`${field}`}
                key={field}
                addField={this.addField}
                removeField={(e) => {
                  e.preventDefault();
                  fields.remove(fieldIndex);
                }}
                swapField={this.props.fields.swap}
                index={fieldIndex}
                disableRemove={fields.length === 1}
                meta={meta}
                {...rest}
              />
            );
          })}
          {meta.submitFailed && meta.error && <span>{meta.error}</span>}
          <NewFieldAddButton addField={this.addField} meta={meta} {...rest} />
        </div>
      );
    }
  }

  RenderFieldArray.propTypes = {
    fields: PropTypes.object.isRequired,
    requiredFieldNames: PropTypes.arrayOf(PropTypes.string),
    defaultValues: PropTypes.object,
    meta: PropTypes.shape({
      submitFailed: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
  };

  return RenderFieldArray;
}

export default renderFieldArray;
