import { createAction } from "redux-actions";
import {
  convertToRaw,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import he from "he";
import { upsertData, removeData } from "../utils/normalize";
import { stateFromHTML } from "draft-js-import-html";
import { isTaskFormatHTML, TaskFormat } from "../modules/taskFields";
import { RESET_INITIAL_STATE } from "./me";
import { stateToHTML } from "draft-js-export-html";

export const fields =
  "parentDeliverableSourceFieldId, parentDeliverableId, sourceFieldId, fieldValue, taskFieldId, rawContent";

export const parentDeliverableSourceFieldsByParentDeliverableIdQuery = `
  parentDeliverableSourceFields (parentDeliverableIds: $parentDeliverableId) { ${fields} }
`;
export const parentDeliverableSourceFieldsByAssignmentQuery = `
  parentDeliverableSourceFields (assignmentId: $assignmentId) { ${fields} }
`;
export const parentDeliverableSourceFieldsByAssignmentGroupIdQuery = `
  parentDeliverableSourceFields (assignmentGroupId: $assignmentGroupId) { ${fields} }
`;
export const parentDeliverableSourceFieldsByProjectIdQuery = `
  parentDeliverableSourceFields (projectId: $projectId) { ${fields} }
`;
export const parentDeliverableSourceFieldsByDeliverableIdsQuery = `
  parentDeliverableSourceFields (deliverableIds: $deliverableId) { ${fields} }
`;

/**
 * Turn an index into a specific key string for putting everything back together
 *
 * e.g. 0 => AA, 25 => AZ, 26 => BA, 27 => BB
 *
 * @param {Number} index
 */
const indexToKeyString = (index) => {
  const div = Math.floor(index / 26);
  const rem = index % 26;

  return `${String.fromCharCode(65 + div)}${String.fromCharCode(65 + rem)}`;
};

/**
 * Generate a random key for DraftJS blocks
 *
 * @returns {String} a random 5-character long string
 */
export const generateDraftJSKey = () =>
  Math.random().toString(36).substring(2, 7);

/**
 * Process blocks of text
 *
 * @param {Array} blocks - an array of blocks containing text and other properties
 * @returns {Array} an array of processed blocks
 */

const splitTextIntoProse = (text) => {
  return text.split(/(?<=\.)/); // Split on '.', followed by whitespace
};

const removePunctuationFromLastSentence = (sentences) => {
  const lastIndex = sentences.length - 1;
  sentences[lastIndex] = sentences[lastIndex].replace(/[.]$/, "");
  return sentences;
};

const processEntityRanges = (entityRanges, startOfLine, endOfLine) => {
  return entityRanges
    .filter((range) => range.offset >= startOfLine && range.offset < endOfLine)
    .map((range) => ({
      ...range,
      offset: range.offset - startOfLine,
    }));
};

const createNewBlock = (block, line, index, isBullet, key, entityRanges) => {
  const { type, ...rest } = block;
  const trimmedLine = line.trim();
  const newText = trimmedLine.replace(/^•\s*/, ""); // Remove bullet points from text

  return {
    ...rest,
    key: `${key}${index > 0 ? indexToKeyString(index) : ""}`,
    text: newText,
    type: isBullet ? "unordered-list-item" : type,
    entityRanges,
  };
};

const splitAndFormatBlock = (block, isLastBlock) => {
  const { text, key, entityRanges, type } = block;
  let cumulativeLength = 0;
  const isBullet = type === "unordered-list-item";

  let lines =
    text.startsWith("•") || text.startsWith("-") || isBullet
      ? [text]
      : splitTextIntoProse(text);

  if (isLastBlock) {
    lines = removePunctuationFromLastSentence(lines);
  }

  return lines.map((line, index) => {
    const originalLength = line.length;
    const trimmedLine = line.trim();
    const trimmedLineLength = trimmedLine.length;

    const spaceRemoved = originalLength > trimmedLineLength;
    const totalSpaces = originalLength - trimmedLineLength;
    const startOfLine = cumulativeLength + (spaceRemoved ? totalSpaces : 0);
    const endOfLine = startOfLine + trimmedLine.length;

    cumulativeLength += originalLength; // Include all spaces in cumulative length

    const newEntityRanges = processEntityRanges(
      entityRanges,
      startOfLine,
      endOfLine
    );
    const isBulletLine = trimmedLine.startsWith("•") || isBullet;

    return createNewBlock(
      block,
      line,
      index,
      isBulletLine,
      key,
      newEntityRanges
    );
  });
};

const preprocessHTML = (html, isBulk) => {
  if (isBulk) {
    return html
      .replace(/<br>/g, "\n")
      .replace(/[""]/g, '"')
      .split("\n")
      .map((line) => {
        line = line.trim();
        //Check if new line empty
        if (line === "") {
          return "<p><br></p>";
          //Check if line is html already
        } else if (/<\/?[a-z][\s\S]*>/i.test(line)) {
          return line;
          // Otherwise wrap in p
        } else {
          return `<p>${line}</p>`;
        }
      })
      .join("");
  }
  return he
    .decode(html)
    .replace(/[""]/g, '"')
    .replace(/<\/?sup>/gi, "")
    .trim();
};

const convertHTMLToBlocks = (html) => {
  const updatedContent = html.replace(/<p><br><\/p>/g, "<div>&shy;</div>");
  const blocksFromHTML = convertFromHTML(updatedContent);

  const { contentBlocks } = blocksFromHTML;

  // Remove the soft hyphen from the resulting blocks
  const processedBlocks = contentBlocks.map((block) => {
    const text = block.getText().replace(/\u00AD/g, "");
    return block.set("text", text);
  });

  const contentState = ContentState.createFromBlockArray(
    processedBlocks,
    blocksFromHTML.entityMap
  );

  return stateToHTML(contentState);
};

export function getRawContentFromHTML(html, taskFieldFormat, isBulk) {
  try {
    const isTMProse = taskFieldFormat === TaskFormat.TMProse;
    const preprocessedHTML = preprocessHTML(html, isBulk);
    const newHTML = convertHTMLToBlocks(preprocessedHTML);
    const newContentState = stateFromHTML(newHTML);
    const { blocks, entityMap } = convertToRaw(newContentState);

    const lastBlockKey = blocks[blocks.length - 1].key;

    const processedBlocks = blocks.flatMap((block) => {
      const isLastBlock = block.key === lastBlockKey;
      return isTMProse ? splitAndFormatBlock(block, isLastBlock) : [block];
    });

    return {
      entityMap,
      blocks: processedBlocks,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}

/**
 * Adds raw content from the field value to the parent deliverable source fields if any of the task types are "HTML (TM)"
 *
 * @param {Object[]} parentDeliverableSourceFields array of parent deliverable source field objects
 * @param {Object} sourceFields object with source field ids as the key for each source field
 */
export const addRawContentToParentDeliverableSourceFields = (
  parentDeliverableSourceFields,
  sourceFields
) =>
  parentDeliverableSourceFields.map((pdsf) =>
    addRawContentToParentDeliverableSourceField(pdsf, sourceFields)
  );

/**
 * Adds raw content to the parent deliverable source field if the task type is "HTML (TM)"
 *
 * @param {Object} pdsf parent deliverable source field object
 * @param {Object} sourceFields object with source field ids as the key for each source field
 * @param {Boolean} isBulk Boolean which is true for bulk uploads using csv, otherwise defaults to false
 */
export const addRawContentToParentDeliverableSourceField = (
  pdsf,
  sourceFields,
  isBulk = false
) => {
  const sourceField = sourceFields[pdsf.sourceFieldId];
  const taskFieldFormat = sourceField ? sourceField.taskFieldFormat : undefined;
  const isHTMLFormat = isTaskFormatHTML(taskFieldFormat);

  // Initialize rawContent based on the format
  const rawContent = isHTMLFormat
    ? getRawContentFromHTML(pdsf.fieldValue, taskFieldFormat, isBulk)
    : null;

  return {
    ...pdsf,
    fieldValue:
      isHTMLFormat && pdsf.fieldValue
        ? stateToHTML(convertFromRaw(rawContent))
        : pdsf.fieldValue, // Fallback to original fieldValue if html is null
    rawContent: JSON.stringify(rawContent),
  };
};

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS =
  "FETCH_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS";
export const CREATE_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS =
  "CREATE_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS";
export const REMOVE_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS =
  "REMOVE_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchParentDeliverableSourceFieldsSuccess = createAction(
  FETCH_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS
);
export const createParentDeliverableSourceFieldsSuccess = createAction(
  CREATE_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS
);
export const removeParentDeliverableSourceFieldsSuccess = createAction(
  REMOVE_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS
);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const parentDeliverableSourceFieldActionHandlers = {
  [RESET_INITIAL_STATE]: () => parentDeliverableSourceFieldInitialState,
  [FETCH_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableSourceFieldId"),
  [CREATE_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableSourceFieldId"),
  [REMOVE_PARENT_DELIVERABLE_SOURCE_FIELDS_SUCCESS]: (state, { payload }) =>
    payload.reduce(
      (acc, { parentDeliverableSourceFieldId }) =>
        removeData(acc, parentDeliverableSourceFieldId),
      state
    ),
};

export const parentDeliverableSourceFieldInitialState = {
  entities: {},
  result: [],
};
