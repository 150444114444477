export const dragSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

export const dragTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    if (monitor.isOver()) {
      props.swapField(dragIndex, hoverIndex);
      monitor.getItem().index = hoverIndex;
    }
  },
};
