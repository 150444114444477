import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "./Loading.module.scss";
import classNames from "classnames";

const Loading = ({ small }) => {
  const imgStyles = classNames({
    [styles.fadeInOut]: true,
    [styles.small]: small,
  });

  return (
    <img
      alt="Loading..."
      className={imgStyles}
      height="auto"
      src="/icons/nav/sidebar-scribe-logo.png"
      width="60px"
    />
  );
};

Loading.propTypes = {
  small: PropTypes.bool,
};

export default memo(Loading);
