import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import FieldsForm from "./FieldsForm";
import PageHeader from "../../../../../../components/PageHeader";
import styles from "./Fields.module.scss";

const Fields = (props) => {
  const {
    project: { projectId, projectName },
  } = props;
  const baseUrl = `/admin/projects/${projectId}`;
  return (
    <div>
      <Helmet>
        {" "}
        <title>Fields</title>{" "}
      </Helmet>
      <div>
        <PageHeader
          title={`${projectName} - Fields`}
          breadCrumbItems={[
            { url: `/admin`, text: "Parent accounts" },
            {
              text: `${props.parentAccount.parentAccountName} | ${props.account.accountName} | ${props.orderForm.orderFormNumber}`,
            },
            { url: `/admin/projects/${projectId}`, text: projectName },
            { text: "Fields" },
          ]}
        />
        <div className={styles.container}>
          <FieldsForm
            baseUrl={baseUrl}
            enableReinitialize
            featureToggles={props.featureToggles}
            hideModal={props.hideModal}
            isLocalisationProject={props.isLocalisationProject}
            initialValues={props.initialValues}
            modal={props.modal}
            onSubmit={props.updateFields}
            projectCreation={props.projectCreation}
            projectId={props.projectId}
            showModal={props.showModal}
            defaultTaskField={props.defaultTaskField}
            isAiWorkflow={props.isAiWorkflow}
            hasAiContentModel={props.hasAiContentModel}
          />
        </div>
      </div>
    </div>
  );
};

Fields.propTypes = {
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string.isRequired,
  }),
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
  }),
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number.isRequired,
  }),
  initialValues: PropTypes.object.isRequired,
  featureToggles: PropTypes.object,
  projectCreation: PropTypes.bool,
  project: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
  }).isRequired,
  updateFields: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  isLocalisationProject: PropTypes.bool.isRequired,
};

export default Fields;
