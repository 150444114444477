import { connect } from "react-redux";
import WithData from "../../../../../../decorators/WithData";
import {
  updateBatch,
  createBatch,
  archiveBatch,
} from "../../../../../../modules/batches";
import {
  showModal,
  hideModal,
  ModalType,
} from "../../../../../../modules/modal";
import {
  batchesByProjectId,
  projectById,
  orderFormById,
  accountById,
  parentAccountById,
} from "../../../../../../utils/entitySelector";
import ProjectBatches from "../components/ProjectBatches";
import { getInitialData } from "../modules/getInitialData";
import { getAsyncData } from "../modules/getAsyncData";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  createBatch: (data) => dispatch(createBatch(data)),
  updateBatch: (data) => dispatch(updateBatch(data)),
  archiveBatch: (batchId) => dispatch(archiveBatch(batchId)),
  getAsyncData: () => dispatch(getAsyncData(ownProps.match.params)),
  showModal: () => dispatch(showModal({ type: ModalType.ArchiveBatch })),
  hideModal: () => dispatch(hideModal()),
});

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);

  const batches = batchesByProjectId(state, projectId);
  const project = projectById(state, projectId);
  const orderForm = orderFormById(state, project.orderFormId);
  const account = accountById(state, orderForm.accountId);
  const parentAccount = parentAccountById(state, account.parentAccountId);

  return {
    account,
    batches,
    orderForm,
    parentAccount,
    project,
  };
};

const ConnectedProjectBatches = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ProjectBatches));
export default ConnectedProjectBatches;
