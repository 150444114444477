import { getGraphQL } from "../../../../../../../../../../../utils/fetch";
import {
  accountsQuery,
  fetchAccountsSuccess,
} from "../../../../../../../../../../modules/accounts";
import {
  orderFormsQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../../../../modules/projects";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../../../modules/batches";
import {
  parentDeliverablesByBatchQuery,
  fetchParentDeliverablesSuccess,
} from "../../../../../../../../../../modules/parentDeliverables";
import {
  deliverablesByBatchQuery,
  fetchDeliverablesSuccess,
} from "../../../../../../../../../../modules/deliverables";
import {
  briefingFieldsByProjectIdQuery,
  fetchBriefingFieldsSuccess,
} from "../../../../../../../../../../modules/briefingFields";
import {
  parentDeliverableBriefingFieldsByBatchQuery,
  fetchParentDeliverableBriefingFieldsSuccess,
} from "../../../../../../../../../../modules/parentDeliverableBriefingFields";
import {
  commentGroupsByBatchIdQuery,
  fetchCommentGroupsSuccess,
} from "../../../../../../../../../../modules/commentGroups";

export const initialQuery = `query ProjectsClientBatchStage ($projectId: String, $batchId: String) {
  ${accountsQuery}
  ${orderFormsQuery}
  ${projectQuery}
  ${batchesByProjectIdQuery}
  ${parentDeliverablesByBatchQuery}
  ${deliverablesByBatchQuery}
  ${briefingFieldsByProjectIdQuery}
  ${parentDeliverableBriefingFieldsByBatchQuery}
  ${commentGroupsByBatchIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchBatchesSuccess(json.batches));
      dispatch(fetchParentDeliverablesSuccess(json.parentDeliverables));
      dispatch(fetchDeliverablesSuccess(json.deliverables));
      dispatch(
        fetchParentDeliverableBriefingFieldsSuccess(
          json.parentDeliverableBriefingFields
        )
      );
      dispatch(fetchBriefingFieldsSuccess(json.briefingFields));
      dispatch(fetchCommentGroupsSuccess(json.commentGroups));
    });
  };
}
