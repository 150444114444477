import { connect } from "react-redux";
import { hideModal } from "../../modules/modal";
import QualityGradingModal from "./QualityGradingModal";

function mapDispatchToProps(dispatch) {
  const closeModal = async () =>
    dispatch((_, getState) => {
      const {
        modal: { data },
      } = getState();
      dispatch(hideModal());
      return data;
    });

  return {
    onCancel: async () => {
      const { onCancel } = await closeModal();
      onCancel();
    },
    onSubmit: async () => {
      const { onSubmit } = await closeModal();
      onSubmit();
    },
  };
}

function mapStateToProps(state) {
  const { data, type: modalType } = state.modal;

  return {
    ...data,
    modalType,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualityGradingModal);
