import {
  transitionHistoryQuery,
  fetchTransitionHistoryDetailsSuccess,
} from "../../../../../../../modules/transitionHistory";
import { getGraphQL } from "../../../../../../../../utils/fetch";

export const initialQuery = `query transitionHistoryQuerybyDeliverableId ($deliverableId: String) {
  ${transitionHistoryQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      // Layout
      dispatch(fetchTransitionHistoryDetailsSuccess(json.transitionHistory));
    });
  };
}
