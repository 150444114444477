import {
  FreelancerKeywordValidation,
  FreelancerBulkQA,
  UnauthorizedLayout,
} from "../bundles";

const routes = (_featureToggles) => [
  {
    component: FreelancerKeywordValidation,
    path: "/freelancer/keyword-validation-tool",
    exact: true,
  },
  {
    component: _featureToggles.freelancerBulkQA
      ? FreelancerBulkQA
      : UnauthorizedLayout,
    path: "/freelancer/assignments/:assignmentGroupId/all-tasks",
    exact: true,
  },
  {
    component: _featureToggles.freelancerBulkQA
      ? FreelancerBulkQA
      : UnauthorizedLayout,
    path: "/freelancer/projects/:projectId/assignments/:assignmentGroupId/all-tasks",
    exact: true,
  },
];

export default routes;
