import React from "react";
import PropTypes from "prop-types";
import styles from "./ProjectInfo.module.scss";
import Helmet from "react-helmet";
import PageHeader from "../../../../components/PageHeader";

const ProjectInfo = (props) => {
  const workflowType = props.workflow.workflowType;
  const {
    orderForm: { orderFormName, orderFormNumber },
    account: { accountName },
    project: { projectName, projectId, briefLink },
  } = props;

  let briefLinkPrefix = "";
  if (briefLink && briefLink.substr(0, 4) !== "http") briefLinkPrefix = "//";

  return (
    <div>
      <Helmet>
        <title>Project</title>
      </Helmet>
      <PageHeader
        breadCrumbItems={[
          { url: "/clients", text: `${accountName}` },
          {
            url: `/clients/projects/${projectId}`,
            text: `${orderFormName} | ${projectName}`,
          },
          { text: `Project information` },
        ]}
        title={"Project information"}
      />
      <div className={styles.container}>
        <div className={styles.header}>Brief link</div>
        <div className={styles.row}>
          {briefLink ? (
            <a
              href={`${briefLinkPrefix}${briefLink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {briefLink}
            </a>
          ) : (
            "No brief link available"
          )}
        </div>

        <div className={styles.header}>Workflow</div>
        <div className={styles.row}>{props.workflow.workflowName}</div>
        {workflowType === "Localisation" && (
          <div>
            <div className={styles.header}>Source Language</div>
            <div className={styles.row}>{props.project.sourceLanguage}</div>
          </div>
        )}
        <div className={styles.header}>
          {workflowType === "Localisation"
            ? "Target languages"
            : "Project Languages"}
        </div>
        <div className={styles.row}>
          {props.projectLanguages.map((p) => p.languageCode).join(", ")}
        </div>

        <div className={styles.header}>Banned words</div>
        {Object.keys(props.bannedWords).map((key) => {
          return (
            <div key={key} className={styles.row}>
              <div className={styles.marginRight}>{key}</div>
              <span>{props.bannedWords[key].join(", ")}</span>
            </div>
          );
        })}

        <div className={styles.header}>Order form number</div>
        <div className={styles.row}>{orderFormNumber}</div>

        <div className={styles.header}>Content type</div>
        <div className={styles.row}>{props.contentTypeName}</div>
      </div>
    </div>
  );
};

ProjectInfo.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  bannedWords: PropTypes.object.isRequired,
  contentTypeName: PropTypes.string,
  orderForm: PropTypes.shape({
    orderFormName: PropTypes.string,
    orderFormNumber: PropTypes.number,
  }),
  project: PropTypes.object.isRequired,
  projectLanguages: PropTypes.array.isRequired,
  workflow: PropTypes.object.isRequired,
};

export default ProjectInfo;
