import React from "react";
import PropTypes from "prop-types";

import { reduxForm } from "redux-form";
import {
  QuillForm,
  QuillFormFieldArray,
} from "../../../../../../../components/QuillForm";

import Modal from "../../../../../../../components/Modal";
import BriefingFieldsArray from "./BriefingFieldsArray";
import TaskFieldsArray from "./TaskFieldsArray";
import KeywordGroupsArray from "./KeywordGroupsArray";
import QualityCheck from "./QualityCheck";

import styles from "./FieldsForm.module.scss";
import validate from "./validations";

import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

let FieldsForm = (props) => {
  return (
    <QuillForm
      header="Project fields"
      cancelText={props.projectCreation ? "Skip" : "Cancel"}
      cancelUrl={
        props.projectCreation ? `${props.baseUrl}/rates` : props.baseUrl
      }
      submitText="Save fields"
      disabled={props.submitting}
      handleSubmit={props.handleSubmit}
      error={props.error}
      rebrandingContainer
    >
      <QuillFormFieldArray
        name="briefingFields"
        customLabelStyle={{ marginTop: "30px" }}
        defaultValues={{ briefingFieldFormat: "Text" }}
        requiredFieldNames={["briefingFieldName"]}
        isAiWorkflow={props.isAiWorkflow}
        hasAiContentModel={props.hasAiContentModel}
        component={BriefingFieldsArray}
      />
      <QuillFormFieldArray
        name="keywordGroups"
        defaultValues={{ keywordGroupName: "" }}
        component={KeywordGroupsArray}
      />
      <QuillFormFieldArray
        name="taskFields"
        customLabelStyle={{ marginTop: "30px" }}
        showModal={props.showModal}
        defaultValues={props.defaultTaskField}
        requiredFieldNames={["taskFieldName"]}
        component={TaskFieldsArray}
        isLocalisationProject={props.isLocalisationProject}
        featureToggles={props.featureToggles}
        projectId={props.projectId}
      />
      {props.isLocalisationProject && (
        <div className={styles.notification}>
          As this is a localisation project, we will create both source & target
          fields for all task fields.
        </div>
      )}
      {props.modal.display && (
        <Modal
          wide
          title="Task field settings"
          display={props.modal.display}
          styles={styles.modal}
          body={<QualityCheck hideModal={() => props.hideModal()} />}
        />
      )}
    </QuillForm>
  );
};

FieldsForm.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  error: PropTypes.string,
  featureToggles: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  isLocalisationProject: PropTypes.bool.isRequired,
  modal: PropTypes.shape({
    display: PropTypes.bool,
  }),
  showModal: PropTypes.func,
  projectCreation: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
};

FieldsForm = reduxForm({
  form: "fieldsForm",
  validate,
})(FieldsForm);

export default DragDropContext(HTML5Backend)(FieldsForm);
