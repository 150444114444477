import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showAppComponent } from "../../../../modules/appComponents";
import { batchCommentGroupSelector } from "../../../../modules/commentGroups";
import { stageById } from "../../../../utils/entitySelector";
import BatchFeedbackButton from "./BatchFeedbackButton";

const mapDispatchToProps = (dispatch) => ({
  showBatchFeedback: () => dispatch(showAppComponent("batchFeedbackPanel")),
});

const mapStateToProps = (state, { batchId, languageCode, stageId }) => {
  const { isFinal, stageType } = stageById(state, stageId);
  const { comments } = batchCommentGroupSelector(state, batchId, languageCode);
  const enabled =
    !isFinal && (stageType === "Client" || stageType === "Amends");

  return {
    display: enabled && !state.appComponents.batchFeedbackPanel.display,
    commentCount: comments ? comments.length : 0,
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchFeedbackButton);

Container.propTypes = {
  batchId: PropTypes.number,
  languageCode: PropTypes.string,
  stageId: PropTypes.number,
};

export default Container;
