import React, { useMemo } from "react";
import {
  isLoggedInUserAdmin,
  projectById,
  workflowStagesSelector,
  assignmentsByGroupSelector,
} from "../../../utils/entitySelector";
import Dropdown from "../../../components/Dropdown/v4";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./ManualOverrideStyles.module.scss";

const ManualOverrideTransitions = ({ disabled, onClick }) => {
  const params = useParams();
  const projectId = Number(params.projectId);
  const stageId = Number(params.stageId);
  const assignmentGroupId = Number(params.assignmentGroupId);

  const isAdmin = useSelector(isLoggedInUserAdmin);

  const { workflowId } = useSelector((state) => {
    return projectById(state, projectId);
  });

  const workflowStages = useSelector((state) => {
    return workflowStagesSelector(state, workflowId);
  });

  const assignments = useSelector((state) => {
    return assignmentsByGroupSelector(state, assignmentGroupId);
  });

  const currentStageId = useMemo(() => {
    if (stageId) {
      return stageId;
    }

    if (assignmentGroupId) {
      const [assignment] = assignments;
      return assignment.stageId;
    }
  }, [stageId, assignmentGroupId, assignments]);

  const stages = useMemo(() => {
    if (!currentStageId) return [];

    const currentStage = workflowStages.find(
      (s) => s.stageId === currentStageId
    );

    return workflowStages.filter(
      (s) => s.stagePosition < currentStage.stagePosition
    );
  }, [currentStageId, workflowStages]);

  if (!isAdmin) return null;
  if (stages.length === 0) return null;
  if (!onClick) return null;

  const stagesListItems = stages.map((s) => ({
    id: s.stageId,
    value: s.stageName,
  }));

  return (
    <Dropdown
      onClick={onClick}
      optionsArr={stagesListItems}
      disabled={disabled}
      rightAligned
      mainButtonStyles={styles.mainButtonStyles}
      titleExtraStyles={styles.titleExtraStyles}
      hideSelectAll
      openUpwards
      title={"Recall deliverables"}
    />
  );
};

export default ManualOverrideTransitions;
