import { connect } from "react-redux";
import { createSelector } from "reselect";
import { showModal, hideModal } from "../../../../modules/modal";
import ParentAccountsList from "../components/ParentAccountsList";
import WithData from "../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { getAsyncData } from "../modules/getAsyncData";
import { withRouter } from "react-router-dom";
import {
  createAccount,
  updateAccount,
  archiveAccount,
} from "../../../../modules/accounts";
import {
  createOrderForm,
  updateOrderForm,
  archiveOrderForm,
} from "../../../../modules/orderForms";

import {
  accountsDropdownSelector,
  orderFormsTreeSelectorByAccount,
  projectsTreeSelectorByOrderForm,
  orderFormsDropdownSelector,
  projectsDropdownSelector,
  parentAccountsWithoutHidden,
} from "../../../../utils/entitySelector";

import {
  fetchParentAccountRelationships,
  searchParentAccounts,
  createParentAccount,
  updateParentAccount,
  archiveParentAccount,
} from "../../../../modules/parentAccounts";

import { copyProject } from "../../../../modules/projects";
import { hide } from "../../../../modules/hidden";
import { favourite, unfavourite } from "../../../../modules/favourites";

const ORDER_FORM_ENTITIES = [
  { value: "Jellyfish UK Limited", label: "Jellyfish UK Limited" },
  { value: "Jellyfish France", label: "Jellyfish France" },
];

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData()),
    getAsyncData: () => dispatch(getAsyncData()),
    fetchParentAccountRelationships: (parentAccountId) =>
      dispatch(fetchParentAccountRelationships(parentAccountId)),
    searchParentAccounts: (input) => dispatch(searchParentAccounts(input)),
    createParentAccount: (data) => dispatch(createParentAccount(data)),
    updateParentAccount: (data) => dispatch(updateParentAccount(data)),
    archiveParentAccount: (id) => dispatch(archiveParentAccount(id)),
    createAccount: (data) => dispatch(createAccount(data)),
    updateAccount: (data) => dispatch(updateAccount(data)),
    archiveAccount: (id) => dispatch(archiveAccount(id)),
    createOrderForm: (data) => dispatch(createOrderForm(data)),
    updateOrderForm: (data) => dispatch(updateOrderForm(data)),
    archiveOrderForm: (id) => dispatch(archiveOrderForm(id)),
    copyProject: (data) => dispatch(copyProject(data)),
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
    hide: (data) => dispatch(hide(data)),
    favourite: (data) => dispatch(favourite(data)),
    unfavourite: (data) => dispatch(unfavourite(data)),
  };
};

/*
  {
    [orderFormId] : [ all projects for orderForm1 ],
    [orderFormId2] : [ all projects for orderForm2 ],
  }
*/
export const accountsTreeSelectorByParentAccount = createSelector(
  (state) => state.accounts,
  (state) => state.hidden.entities,
  (state) => state.favourites.entities,
  (accounts, hidden, favourites) => {
    const hiddenAccountIds = Object.values(hidden).reduce(
      (acc, { accountId }) => {
        if (accountId) acc.push(accountId);
        return acc;
      },
      []
    );
    const favouriteAccountIds = Object.values(favourites).reduce(
      (acc, { accountId }) => {
        if (accountId) acc.push(accountId);
        return acc;
      },
      []
    );
    const tree = {};
    accounts.result.forEach((accountId) => {
      if (!hiddenAccountIds.includes(Number(accountId))) {
        const account = accounts.entities[accountId];
        const { parentAccountId } = account;
        if (!tree[parentAccountId]) {
          tree[parentAccountId] = [];
        }
        tree[parentAccountId].push({
          ...account,
          isFavourite: favouriteAccountIds.includes(Number(accountId)),
        });
      }
    });
    return tree;
  }
);

const mapStateToProps = (state, ownProps) => {
  const accounts = accountsTreeSelectorByParentAccount(state);
  const projects = projectsTreeSelectorByOrderForm(state);
  const orderForms = orderFormsTreeSelectorByAccount(state);
  const dropDownAccounts = accountsDropdownSelector(state);
  const { accountId, orderFormId } = state.form.projectCopy?.values || {};

  const dropDownOrderFormsByAccount = orderFormsDropdownSelector(state).filter(
    (orderForm) => orderForm.object.accountId === accountId
  );
  const dropDownProjectsByOrderForm = projectsDropdownSelector(state).filter(
    (orderForm) => orderForm.object.orderFormId === orderFormId
  );

  // TODO: QCC-1955 Temporary admin user hack for First Choice
  let parentAccounts = parentAccountsWithoutHidden(state);
  if (Number(state.me) === 2670) {
    parentAccounts = parentAccounts.filter((pa) => pa.parentAccountId === 117);
    // TODO: QCC-1955 Doesn't conform to immutability but eslint won't let me save projects as "let" instead of "const"
    if (projects && projects[1]) {
      projects[1] = projects[1].filter((p) => p.projectId === 625);
    }
  }

  return {
    dropDownAccounts,
    dropDownOrderFormsByAccount,
    dropDownProjectsByOrderForm,
    orderFormEntities: ORDER_FORM_ENTITIES,
    accounts,
    orderForms,
    projects,
    parentAccounts,
    modal: state.modal,
  };
};

const ParentAccountsListWithData = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WithData(ParentAccountsList))
);
ParentAccountsListWithData.getInitialData = getInitialData;
export default ParentAccountsListWithData;
