import { getGraphQL } from "../../../../../../utils/fetch";
import {
  paymentsQuery,
  fetchPaymentsSuccess,
  paymentFields,
} from "../../../../../modules/payments";
import {
  peopleByPersonIdQuery,
  fetchPersonSuccess,
  fetchPeopleSuccess,
  fetchPeopleByIds,
} from "../../../../../modules/people";
import {
  projectsByProjectIds,
  fetchProjectSuccess,
} from "../../../../../modules/projects";

import parseQuery from "../../../../../utils/parseQuery";

export const initialQuery = `
  query fetchPayments (
    $personId: String,
    $accountId: Int,
    $orderFormId: Int,
    $projectId: Int,
    $startDate: String,
    $endDate: String,
    $paymentType: String,
    $paymentTask: String,
    $stageName: String,
  ) {
    ${paymentsQuery}
    ${peopleByPersonIdQuery}
  }
`;

const QUERY_PROJECTS_BY_IDS = `
  query fetchProjectsbyId ($projectIds: String) {
    ${projectsByProjectIds}
  }
`;

const QUERY_PAYMENTS_V2 = `
  query getPaymentsV2 ($paymentParamsInput: PaymentParamsInput) {
    paymentsV2 (paymentParamsInput: $paymentParamsInput) {
      ${paymentFields}
    }
  }
`;

async function getPayments({ queryParams, url, cookies }) {
  const { createdByPersonId } = queryParams;

  // new scalable better ad_hoc payments query
  if (createdByPersonId) {
    const searchParams = {
      paymentParamsInput: {
        createdByPersonId,
        paymentType: "AD_HOC_DRAFT",
      },
    };
    const { paymentsV2 } = await getGraphQL(QUERY_PAYMENTS_V2, searchParams);
    return { payments: paymentsV2 };
  }

  // old payments route
  return getGraphQL(initialQuery, queryParams, url, cookies);
}

export function getInitialData(params, url, cookies) {
  return async (dispatch, getState) => {
    if (!params.location) return Promise.resolve();

    const { me } = getState();

    const queryParams = parseQuery(params.location.search);

    const { payments, person } = await getPayments({
      me,
      queryParams,
      url,
      cookies,
    });
    if (person) {
      dispatch(fetchPersonSuccess(person));
    }

    dispatch(fetchPaymentsSuccess(payments));

    const personIds = Object.keys(
      payments.reduce((acc, { payeePersonId }) => {
        acc[payeePersonId] = true;
        return acc;
      }, {})
    );

    const people = await fetchPeopleByIds({ personIds });
    dispatch(fetchPeopleSuccess(people));

    if (payments) {
      const projectIds = payments.reduce((acc, { projectId }) => {
        if (!acc.includes(projectId)) acc.push(projectId);
        return acc;
      }, []);

      if (projectIds.length > 0) {
        const { projects } = await getGraphQL(
          QUERY_PROJECTS_BY_IDS,
          { projectIds },
          url,
          cookies
        );
        dispatch(fetchProjectSuccess(projects));
      }
    }
  };
}
