import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./RequestWrapper.module.scss";
import classNames from "classnames";

/**
 * requestWrapper - This decorator component can be used on any React component
 * for which user interaction should be disabled after a HTTP request is sent to
 * an external service. The wrapped component is responsible for signalling once
 * a request has been sent and once it has ended. The state that is used to
 * track requests is application wide - i.e. any component that uses this decorator
 * will be disabled until any previous requests are fulfilled IF it shares a requestID
 * with another wrapped component. This is intended to stop contradictory behaviour,
 * i.e. a user trying to 'Submit to QA' and then immediately trying to 'Request amends'.
 *
 * Implementation can be performed component or module level (startRequest can be
 * attached to event handlers on components or prior to a server request module level)
 *
 * Example implementation:
 *
 * const WrappedButton = RequestWrapper(Button)
 *
 * @param  {Component} WrappedComponent
 * @return {Component}
 */
function requestWrapper(WrappedComponent, requestID) {
  class RequestWrapper extends Component {
    startRequest = () => this.props.startRequest(requestID);

    endRequest = () => this.props.endRequest(requestID);

    processing = () => {
      const { requestTracker } = this.props;
      return !!(requestTracker && requestTracker[requestID]);
    };

    render() {
      const wrapperStyles = classNames({
        [styles.disabled]: this.processing(),
      });

      return (
        <WrappedComponent
          {...this.props}
          isProcessing={this.processing()}
          wrapperStyles={wrapperStyles}
          startRequest={this.startRequest}
          endRequest={this.endRequest}
        />
      );
    }
  }

  RequestWrapper.propTypes = {
    startRequest: PropTypes.func.isRequired,
    endRequest: PropTypes.func.isRequired,
    requestTracker: PropTypes.object.isRequired,
  };

  return RequestWrapper;
}

export default requestWrapper;
