import React from "react";
import CurrencyDisplay from "../CurrencyDisplay";

const CurrencyRateSums = ({ allAdHocDrafts, payments, payeeName, styles }) => {
  const rateSums = payments.reduce((acc, { currencyCode, rate }) => {
    if (!acc[currencyCode]) {
      acc[currencyCode] = {
        currencySum: 0,
        tasks: 0,
        currencyCode,
      };
    }

    acc[currencyCode].currencySum =
      Math.round((acc[currencyCode].currencySum + rate) * 100) / 100;

    acc[currencyCode].tasks++;
    return acc;
  }, []);

  return Object.values(rateSums).map(({ tasks, currencyCode, currencySum }) => (
    <tr className={styles} key={`${currencyCode}-${currencySum}`}>
      {!payeeName && <td />}

      {/* spacing for removed tiles for ad-hoc drafts */}
      {!allAdHocDrafts && (
        <>
          <td />
          <td />
        </>
      )}
      <td />
      <td></td>
      <td>Tasks: {tasks}</td>
      <td />
      <td>Total: </td>
      <td>
        <CurrencyDisplay currencyCode={currencyCode} />
        {currencySum}
      </td>
      <td />
      <td />
      <td />
      <td />
    </tr>
  ));
};

export default CurrencyRateSums;
