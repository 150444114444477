const MAX_DELIVERABLE_IDS_TO_SHOW = 10;

/**
 * Given a variable amount of deliverableIds return a string that shows them
 * all or a total count
 *
 * @param {number[]} deliverableIds
 * @returns {string}
 */
export function getDeliverableIdsStr(deliverableIds) {
  const { length } = deliverableIds;

  if (length === 1) {
    return `deliverableId: ${deliverableIds[0]}`;
  } else if (length <= MAX_DELIVERABLE_IDS_TO_SHOW) {
    return `deliverableIds: [${deliverableIds.join(", ")}]`;
  } else {
    return `${length} deliverables`;
  }
}
