import ProfileContainer from "./containers/Profile";
import { getInitialData } from "./modules/getInitialData";
import { peopleActionHandlers, peopleInitialState } from "../../modules/people";
import {
  personAccountActionHandlers,
  personAccountInitialState,
} from "../../modules/personAccounts";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../modules/accounts";
import {
  personNativeLanguageActionHandlers,
  personNativeLanguageInitialState,
} from "../../modules/personNativeLanguages";

const reducers = [
  {
    key: "people",
    actionHandlers: peopleActionHandlers,
    initialState: peopleInitialState,
  },
  {
    key: "personAccounts",
    actionHandlers: personAccountActionHandlers,
    initialState: personAccountInitialState,
  },
  {
    key: "accounts",
    actionHandlers: accountActionHandlers,
    initialState: accountInitialState,
  },
  {
    key: "personNativeLanguages",
    actionHandlers: personNativeLanguageActionHandlers,
    initialState: personNativeLanguageInitialState,
  },
];

export { ProfileContainer as Component };
export { reducers };
export { getInitialData };
