import { connect } from "react-redux";
import { createSelector } from "reselect";
import WordRatesForm from "./WordRatesForm";
import { updateWordRates } from "../../../../../../../modules/wordRates";

const initialValuesSelector = createSelector(
  (state) => state.wordRates,
  (_, projectLanguages) => projectLanguages,
  (wordRates, projectLanguages) => {
    return projectLanguages.reduce((acc, { projectId, languageCode }) => {
      const wordRate =
        wordRates[projectId] && wordRates[projectId][languageCode];
      acc[languageCode] = wordRate || 0;

      return acc;
    }, {});
  }
);

const mapDispatchToProps = (dispatch, { projectId, history }) => ({
  onSubmit: (data) => dispatch(updateWordRates(projectId, data, history)),
});

const mapStateToProps = (state, { projectLanguages }) => ({
  enableReinitialize: true,
  initialValues: initialValuesSelector(state, projectLanguages),
});

export default connect(mapStateToProps, mapDispatchToProps)(WordRatesForm);
