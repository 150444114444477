import { getGraphQL } from "../../../../../utils/fetch";
import { fetchAccountsSuccess } from "../../../../modules/accounts";
import { fetchHiddenSuccess } from "../../../../modules/hidden";
import { fetchOrderFormsSuccess } from "../../../../modules/orderForms";
import {
  fetchParentAccountsSuccess,
  parentAccountsQuery,
} from "../../../../modules/parentAccounts";
import { fetchProjectsSuccess } from "../../../../modules/projects";
import { fetchParentAccountRelationshipsQuery } from "../../../../modules/parentAccounts";

export const initialQuery = `query getParentAccountAndAccounts {
  ${parentAccountsQuery}
  hidden {
    hiddenId, parentAccountId, accountId, orderFormId, projectId
  } 
}`;

export function getInitialData(params) {
  return async (dispatch, getState) => {
    const {
      parentAccounts: { result: parentAccountId },
    } = getState();
    const params = { parentAccountId }; // Represents multiple parentAccountIds

    const { accounts, orderForms, projects } = await getGraphQL(
      fetchParentAccountRelationshipsQuery,
      params
    );
    const { parentAccounts, hidden } = await getGraphQL(initialQuery, params);
    dispatch(fetchParentAccountsSuccess(parentAccounts));
    dispatch(fetchAccountsSuccess(accounts));
    dispatch(fetchOrderFormsSuccess(orderForms));
    dispatch(fetchProjectsSuccess(projects));
    dispatch(fetchHiddenSuccess(hidden));
  };
}
