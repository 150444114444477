import React from "react";
import PropTypes from "prop-types";
import ProjectForm from "../../_components/ProjectForm";
import PageHeader from "../../../../../components/PageHeader";

const ProjectsNew = (props) => {
  return (
    <div>
      <PageHeader
        title={"New project"}
        breadCrumbItems={[
          { url: `/admin`, text: `Parent accounts` },
          {
            text: `${props.parentAccount.parentAccountName} | ${props.account.accountName} | ${props.orderForm.orderFormNumber}`,
          },
          { text: "New Project" },
        ]}
      />

      <ProjectForm
        cancelUrl="/admin"
        workflows={props.workflows}
        contentTypes={props.contentTypes}
        orderFormIds={props.orderFormIds}
        languages={props.languages}
        currencies={props.currencies}
        productionTeam={props.productionTeam}
        clientTeam={props.clientTeam}
        onSubmit={props.createProject}
        initialValues={props.initialValues}
        editableClientStagesValues={props.editableClientStagesValues}
      />
    </div>
  );
};

ProjectsNew.propTypes = {
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string.isRequired,
  }),
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
  }),
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number.isRequired,
  }),
  contentTypes: PropTypes.array.isRequired,
  workflows: PropTypes.object.isRequired,
  orderFormIds: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  productionTeam: PropTypes.array.isRequired,
  clientTeam: PropTypes.array.isRequired,
  createProject: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default ProjectsNew;
