import React from "react";
import PropTypes from "prop-types";

import { reduxForm } from "redux-form";
import { InputWithErrors } from "../../../../../decorators/WithErrors";
import { QuillForm, QuillFormField } from "../../../../../components/QuillForm";

import validate from "./validate";

const ParentAccountForm = (props) => {
  return (
    <QuillForm
      header={props.header}
      cancelText="Cancel"
      cancelHandler={props.hideModal}
      submitText="Save"
      disabled={props.submitting || !props.valid}
      handleSubmit={props.handleSubmit}
      error={props.error}
    >
      <QuillFormField
        name="parentAccountName"
        labelText="Parent account name"
        required
        component={InputWithErrors}
        type="text"
        containerWidth="100%"
      />
    </QuillForm>
  );
};

ParentAccountForm.propTypes = {
  header: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "parent-account",
  validate,
})(ParentAccountForm);
