import { connect } from "react-redux";
import PaymentsEdit from "../components/PaymentsEdit";
import { getInitialData } from "../../_modules/getInitialData";
import { getAsyncData } from "../../_modules/getAsyncData";
import WithData from "../../../../../../decorators/WithData";
import {
  paymentSelector,
  projectById,
} from "../../../../../../utils/entitySelector";
import { updatePayment } from "../../../../../../modules/payments";
import { getLSItem } from "../../../../../../utils/localStorage";

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    history,
    match: { params },
  } = ownProps;
  const searchString = getLSItem("payment-search-string");
  return {
    getInitialData: () => dispatch(getInitialData(params)),
    getAsyncData: () => dispatch(getAsyncData(params)),
    updatePayment: (data, successUrl) =>
      dispatch(
        updatePayment(data, history, {
          successUrl: successUrl || `/admin/payments/${searchString || ""}`,
        })
      ),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { paymentId },
    },
  } = ownProps;
  const [payment] = paymentSelector(state, Number(paymentId));
  if (!payment) return {};
  const { payeePersonId, orderFormId, projectId } = payment;
  const { currencyCode } = projectById(state, projectId);
  const payee = state.people.entities[payeePersonId];
  const orderForm = state.orderForms.entities[orderFormId];

  return { payment, payee, orderForm, currencyCode };
};

const PaymentsEditWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(PaymentsEdit));
PaymentsEditWithData.getInitialData = getInitialData;
export default PaymentsEditWithData;
