import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styles from "./Messages.module.scss";
import classNames from "classnames";
import { isArray } from "../../../../utils/dataTypes";

const TRANSITION_DURATION = 500;
const VISIBLE_DURATION = 4800;
const DURATION = VISIBLE_DURATION + TRANSITION_DURATION;

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = { containerStyles: styles.container };
    this.messagesRoot = document.getElementById("messages");
    this.container = document.createElement("div");
  }

  componentDidMount() {
    this.messagesRoot.appendChild(this.container);
    const { type } = this.props.messages;

    // We apply delays to styling so that css transitions are applied correctly
    const messageTypeStyles = classNames({
      [styles.success]: type === "TYPE_SUCCESS",
      [styles.warning]: type === "TYPE_WARNING",
      [styles.error]: type === "TYPE_ERROR",
    });

    setTimeout(
      () =>
        this.setState({
          containerStyles: `${styles.container} ${styles.inView} ${messageTypeStyles}`,
        }),
      0
    );
    this.visibleTimer = setTimeout(
      () => this.setState({ containerStyles: styles.container }),
      VISIBLE_DURATION
    );
    this.hideMessages = setTimeout(this.props.hideMessages, DURATION);
  }

  componentWillUnmount() {
    this.messagesRoot.removeChild(this.container);
    if (this.props.messages.display) {
      clearTimeout(this.hideMessages);
      clearTimeout(this.visibleTimer);
      this.props.hideMessages();
    }
  }

  hide = () => {
    this.setState({ containerStyles: styles.container });
    setTimeout(this.props.hideMessages, TRANSITION_DURATION);
  };

  renderMessages = () => {
    const { message } = this.props.messages;

    // we allow an array of messages to be passed, so render a div for each
    if (isArray(message)) {
      return message.map((m, idx) => {
        return <div key={idx}>{m}</div>;
      });
    }

    return <div>{message}</div>;
  };
  render() {
    if (!this.props.messages.display) return null;

    return ReactDOM.createPortal(
      <div className={this.state.containerStyles}>
        <div className={styles.dismissContainer} onClick={this.hide}>
          <div className={styles.dismiss}>
            <svg height="16" width="16">
              <path
                d="M16 13l-5-5 5-5V2l-2-2h-1L8 5 3 0H2L0 2v1l5 5-5 5v1l2 2h1l5-5 5 5h1l2-2v-1z"
                fill="#F9F9F9"
              />
            </svg>
          </div>
        </div>
        {this.renderMessages()}
      </div>,
      this.container
    );
  }
}

Messages.propTypes = {
  hideMessages: PropTypes.func.isRequired,
  messages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Messages;
