import { upsertData, removeData } from "../utils/normalize";
import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// GraphQL Queries
// ------------------------------------

const keywordFields = "keywordId, keywordGroupId, parentDeliverableId, word";

export const keywordsByParentDeliverableIdQuery = `keywords(parentDeliverableId: $parentDeliverableId) {
  ${keywordFields}
}`;

export const keywordsByProjectQuery = `keywords(projectId: $projectId) {
  ${keywordFields}
}`;
export const keywordsByBatchQuery = `keywords(batchId: $batchId) {
  ${keywordFields}
}`;

export const keywordsByAssignmentGroupQuery = `keywords(assignmentGroupId: $assignmentGroupId) {
  ${keywordFields}
}`;

export const keywordsByAssignmentQuery = `keywords(assignmentId: $assignmentId) {
  ${keywordFields}
}`;

export const keywordsByDeliverableIdsQuery = `keywords (deliverableIds: $deliverableId) {
  ${keywordFields}
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_KEYWORDS_SUCCESS = "FETCH_KEYWORDS_SUCCESS";
export const CREATE_KEYWORDS_SUCCESS = "CREATE_KEYWORDS_SUCCESS";
export const REMOVE_KEYWORDS_SUCCESS = "REMOVE_KEYWORDS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchKeywordsSuccess = createAction(FETCH_KEYWORDS_SUCCESS);
export const createKeywordsSuccess = createAction(CREATE_KEYWORDS_SUCCESS);
export const removeKeywordsSuccess = createAction(REMOVE_KEYWORDS_SUCCESS);

// ------------------------------------
// Selectors
// ------------------------------------

/**
 * @param   {Object}    state
 * @param   {number}    parentDeliverableId
 * @returns {string[]}  array of keywords
 */
export const keywordsForParentDeliverable = createSelector(
  (state) => state.keywords.entities,
  (_, parentDeliverableId) => parentDeliverableId,
  (keywords, parentDeliverableId) => {
    return Object.values(keywords)
      .filter(
        ({ parentDeliverableId: kwParentDeliverableId, word }) =>
          kwParentDeliverableId === parentDeliverableId &&
          word.trim().length > 0
      ) // remove any blank keywords
      .map(({ word }) => word);
  }
);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const keywordActionHandlers = {
  [RESET_INITIAL_STATE]: () => keywordInitialState,
  [FETCH_KEYWORDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "keywordId"),
  [CREATE_KEYWORDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "keywordId"),
  [REMOVE_KEYWORDS_SUCCESS]: (state, { payload }) => {
    return payload.reduce(
      (acc, { keywordId }) => removeData(acc, keywordId),
      state
    );
  },
};

export const keywordInitialState = { entities: {}, result: [] };
