import PropTypes from "prop-types";
import styles from "./QuillFormButtonsContainer.module.scss";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import classNames from "classnames";

const QuillFormButtonsContainer = (props) => {
  const onFileChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      complete: ({ data }) => {
        props.uploadForm(data);
      },
    });
  };

  const download = () => {
    props.downloadGlossary(props.formName);
  };

  const cancelDiv = (
    <button
      type="button"
      onClick={props.cancelHandler}
      className={styles.cancel}
    >
      {props.cancelText}
    </button>
  );

  const cancelButton = props.cancelUrl ? (
    <Link className={styles.cancelButtonLink} to={props.cancelUrl}>
      {cancelDiv}
    </Link>
  ) : (
    cancelDiv
  );

  const csv = props.uploadForm && props.downloadGlossary;

  const uploadFile = (
    <div className={styles.uploadFile}>
      <label htmlFor="upload" className={styles.customButton}>
        Upload CSV
      </label>
      <input
        onChange={onFileChange}
        key="input"
        accept=".csv"
        className={styles.hide}
        id="upload"
        type="file"
      />

      <>
        <label htmlFor="download" className={styles.customButton}>
          Download CSV
        </label>
        <input onClick={download} className={styles.hide} id="download" />
      </>
    </div>
  );

  const buttonContainerStyles = classNames({
    [styles.buttonsContainer]: true,
    [styles.csv]: csv,
  });

  return (
    <div
      className={`${buttonContainerStyles} ${props.customButtonsContainerStyles}`}
    >
      {csv && uploadFile}
      <div>
        {cancelButton}
        <button
          className={styles.submit}
          type="submit"
          disabled={props.disabled}
        >
          {props.submitText}
        </button>
      </div>
    </div>
  );
};

QuillFormButtonsContainer.propTypes = {
  cancelUrl: PropTypes.string,
  cancelHandler: PropTypes.func,
  submitText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  customButtonsContainerStyles: PropTypes.string,
  uploadForm: PropTypes.func,
  downloadGlossary: PropTypes.func,
};

export default QuillFormButtonsContainer;
