import React, { useState, useEffect, useRef } from "react";
import styles from "./ToggleThrough.module.scss";
import { lowerCaseCheck } from "../../utils/lowerCaseCheck";
import classNames from "classnames";

const ToggleThrough = ({ content, setWordMatches }) => {
  const [index, updateIndex] = useState(0);
  const [displayIndex, setDisplayIndex] = useState(0);
  const [wordStateMatches, setMatches] = useState([]);
  const [toggled, setToggled] = useState();
  const ref = useRef(null);

  const handleClick = (e) => {
    if (!ref.current.contains(e.target)) {
      setToggled(false);
    }
  };

  const renderSVG = (altTag, path) => (
    <svg alt={altTag} height="15" title={altTag} width="15" viewBox="0 0 12 12">
      {path}
    </svg>
  );

  const getMatches = (searchValue) => {
    return Object.values(content).reduce((acc, cur, i) => {
      Object.values(cur).forEach((val) => {
        if (searchValue && lowerCaseCheck(val.content, searchValue)) {
          acc.push({ ...val, tableIndex: i });
        }
      });
      return acc;
    }, []);
  };

  useEffect(() => {
    setWordMatches(wordStateMatches, index, toggled);
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [setWordMatches, wordStateMatches, index, content, toggled]);

  const wordMatchLength = wordStateMatches.length;

  const handleChange = ({ target: { value } }) => {
    setToggled(true);
    let wordMatches;
    wordMatches = getMatches(value);

    if (!value) {
      wordMatches = [];
    }

    setMatches(wordMatches);

    if (wordMatches.length > 0 && value) {
      setDisplayIndex(1);
    }
    if (wordMatches.length === 0) {
      setDisplayIndex(wordMatches.length);
    }
  };

  const previous = () => {
    setToggled(true);
    if (displayIndex < wordMatchLength) {
      updateIndex(index + 1);
      setDisplayIndex(displayIndex + 1);
    }

    if (wordMatchLength && displayIndex === wordMatchLength) {
      updateIndex(0);
      setDisplayIndex(1);
    }
  };

  const next = () => {
    setToggled(true);
    if (displayIndex > 1) {
      setDisplayIndex(displayIndex - 1);
      updateIndex(index - 1);
    }
    if (displayIndex === 1) {
      updateIndex(wordMatchLength - 1);
      setDisplayIndex(wordMatchLength);
    }
  };

  const inputStyles = classNames({
    [styles.searchInput]: true,
    [styles.active]: wordMatchLength > 0,
  });

  return (
    <div ref={ref} className={styles.searchContainer}>
      <div className={styles.counter}>
        <div className={styles.count}>
          {displayIndex}/{wordMatchLength}
        </div>

        <span className={styles.arrows} onClick={next}>
          {renderSVG(
            "Next",
            <path fill="#acadad" d="M12.5 10l-4.5-4.5-4.5 4.5h9z"></path>
          )}
        </span>
        <span className={styles.arrows} onClick={previous}>
          {renderSVG(
            "Previous",
            <path fill="#acadad" d="M3.5 6l4.5 4.5 4.5-4.5h-9z"></path>
          )}
        </span>
      </div>
      <input
        className={inputStyles}
        placeholder="Search term"
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
};

export default ToggleThrough;
