import { connect } from "react-redux";
import { peopleSelector } from "../../../../../../utils/entitySelector";
import PeopleList from "../components/PeopleList";
import WithData from "../../../../../../decorators/WithData";
import { searchPeople } from "../../../../../../modules/people";
import { getInitialData } from "../modules/getInitialData";

const mapDispatchToProps = (dispatch) => ({
  getInitialData: () => dispatch(getInitialData()),
  searchPeople: (input) => dispatch(searchPeople(input)),
});

const mapStateToProps = (state) => ({
  people: peopleSelector(state),
  featureToggles: state.featureToggles,
});

const PeopleListWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(PeopleList));
PeopleListWithData.getInitialData = getInitialData;
export default PeopleListWithData;
