import React, { useState, useEffect, useCallback, useRef } from "react";
import { AutoSizer, List } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import styles from "./BulkQA.module.scss";
import AssignmentEdit from "../../AssignmentEdit";
import { renderBriefingFieldValue } from "../../../utils/renderBriefingFieldValue";
import parseQuery, { handleQuery } from "../../../utils/parseQuery";
import qs from "query-string";
import classNames from "classnames";

const CELL_WIDTH = 320;
const MAX_SCROLL_HEIGHT = 320;
const TABLE_HEIGHT = "85%";
const SCREEN_WIDTH = "100%";

const BulkQA = ({
  tfcByDeliverable,
  taskFields,
  assignments,
  assignmentId: assId,
  location,
  history,
  assignment,
  wordMatches,
  index,
  isCommentable,
  isEditable,
  isActionable,
  fetchDeliverableContent,
  toggled,
  taskFieldId: tf1,
  grammarCheck,
  allowGrammarCheck,
}) => {
  const [taskFieldId, setTaskFieldId] = useState();
  const [selectedRow, setRow] = useState();
  const [tableIndex, setTableIndex] = useState();

  const headerRefs = useRef([]);
  const cellRefs = useRef([]);

  const totalWidth = CELL_WIDTH * taskFields.length;
  const dynamicWidth = totalWidth < window.innerWidth;

  const style = {
    height: TABLE_HEIGHT,
    width: dynamicWidth ? SCREEN_WIDTH : totalWidth,
  };

  const updateQuerySearch = useCallback(
    (term, value) => {
      const query = handleQuery(parseQuery(location.search), term, value);
      const search = qs.stringify(query);

      if (location.search !== `?${search}`) {
        history.push({ search });
      }
    },
    [location.search, history]
  );

  const selectCell = (taskFieldId, assignmentId, deliverableId) => {
    fetchDeliverableContent(deliverableId);
    updateQuerySearch("assignmentId", assignmentId);
    setTaskFieldId(taskFieldId);
    setRow(deliverableId);
  };

  useEffect(() => {
    if (toggled) {
      const { deliverableId, taskFieldId, tableIndex } =
        wordMatches.length > 0 && wordMatches[index];
      const { assignmentId } =
        assignments.find(
          (assignment) => assignment.deliverableId === deliverableId
        ) || {};

      if (headerRefs.current[Number(taskFieldId)]) {
        headerRefs.current[Number(taskFieldId)].scrollIntoView({
          block: "center",
          inline: "center",
        });
      }
      setTableIndex(tableIndex);
      updateQuerySearch("assignmentId", assignmentId);
      setRow(Number(deliverableId));
      setTaskFieldId(Number(taskFieldId));
    }
  }, [
    toggled,
    wordMatches,
    index,
    assignment,
    assignments,
    assId,
    tf1,
    taskFields,
    updateQuerySearch,
  ]);

  const rowRenderer = (rowProps) => {
    const { index, key, style } = rowProps;
    if (!Object.values(tfcByDeliverable[index])[0]) return null;

    const deliverableId = Object.values(tfcByDeliverable[index])[0]
      .deliverableId;

    const { assignmentId } =
      assignments.find(
        (assignment) => assignment.deliverableId === deliverableId
      ) || {};

    const rowContent = taskFields.map((tf) => {
      const handleSelectCell = (e) => {
        e.preventDefault();
        selectCell(tf.taskFieldId, assignmentId, deliverableId);
      };

      const latestContent = tfcByDeliverable[index][tf.taskFieldId];
      const isSelected =
        taskFieldId === tf.taskFieldId && deliverableId === selectedRow;

      const hasWord =
        latestContent &&
        wordMatches.some(({ cellId }) => {
          return cellId === latestContent.cellId;
        });

      const cellGrammarCheck =
        latestContent &&
        grammarCheck.find((gc) => gc.cellId === latestContent.cellId);

      const cellHasGrammarCheck =
        cellGrammarCheck &&
        !Object.values(cellGrammarCheck.corrections).every(
          (item) => item.length === 0
        );

      const cellIdExists =
        latestContent && cellRefs.current[`${deliverableId}-${tf.taskFieldId}`];
      const hasOverlap =
        cellIdExists && cellIdExists.scrollHeight > MAX_SCROLL_HEIGHT;

      const cellStyles = classNames({
        [styles.cells]: true,
        [styles.active]: isSelected,
        [styles.hasSearchTerm]: hasWord,
        [styles.dynamicWidth]: dynamicWidth,
      });

      if (tfcByDeliverable[index][tf.taskFieldId]) {
        return (
          <div
            key={tf.taskFieldId}
            ref={(el) =>
              (cellRefs.current[`${deliverableId}-${tf.taskFieldId}`] = el)
            }
            onClick={handleSelectCell}
            className={cellStyles}
          >
            <span>
              {renderBriefingFieldValue(
                latestContent.content,
                tf.taskFieldFormat
              )}
            </span>
            {cellHasGrammarCheck && <span className={styles.grammar} />}
            {hasOverlap && <span className={styles.ellipsis}>...</span>}
          </div>
        );
      } else {
        return (
          <div
            key={tf.taskFieldId}
            onClick={handleSelectCell}
            className={cellStyles}
          />
        );
      }
    });

    const rowStyles = classNames({
      [styles.rowContainer]: true,
      [styles.rowContainerActive]: deliverableId === selectedRow,
    });

    return (
      <div style={style} id={index} className={rowStyles} key={key}>
        {rowContent}
      </div>
    );
  };

  const tableStyles = classNames({
    [styles.taskFieldTable]: true,
    [styles.tablePadding]: selectedRow,
  });

  const headerStyles = classNames({
    [styles.headers]: true,
    [styles.dynamicHeaders]: dynamicWidth,
  });

  const columnStyles = classNames({
    [styles.columns]: true,
    [styles.dynamicHeaders]: dynamicWidth,
  });

  const reactVirtualizedStyles = {
    outline: "none",
    width: "",
  };

  return (
    <div className={styles.bulkQA}>
      <div className={styles.assignmentEdit}>
        {Object.keys(assignment).length > 0 ? (
          <AssignmentEdit
            allowGrammarCheck={allowGrammarCheck}
            isBulkQA={true}
            taskFieldId={taskFieldId}
            isActionable={isActionable}
            isCommentable={isCommentable}
            isEditable={isEditable}
            {...assignment}
          />
        ) : (
          <div className={styles.noAssignment}>
            Please select a deliverable to view
          </div>
        )}
      </div>
      <div className={tableStyles}>
        <div className={headerStyles}>
          {taskFields.map(({ taskFieldId, taskFieldName }) => {
            return (
              <span
                key={taskFieldId}
                ref={(el) => (headerRefs.current[taskFieldId] = el)}
                className={columnStyles}
              >
                {taskFieldName}
              </span>
            );
          })}
        </div>
        <div style={style}>
          <AutoSizer disableWidth>
            {({ height }) => {
              return (
                <List
                  height={height}
                  rowCount={tfcByDeliverable.length}
                  rowHeight={50}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  overflow="visible"
                  rowRenderer={rowRenderer}
                  width={1}
                  style={reactVirtualizedStyles}
                  scrollToIndex={tableIndex}
                />
              );
            }}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};

export default BulkQA;
