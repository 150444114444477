import { postGraphQL } from "../../utils/fetch";

const query = `mutation createTranslationMemoryResponse($context: TMRContextInput, $responses: [TMRResponseInput]) {
  createTranslationMemoryResponse(context: $context, responses: $responses) {
    data
  }
}`;

export default function createTMResponses(input) {
  return postGraphQL(query, input);
}
