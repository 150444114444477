import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import PageHeader from "../../../../../../../components/PageHeader";
import Modal from "../../../../../../../components/Modal";
import { Component as ProfileContainer } from "../../../../../../../components/Profile";
import styles from "./PeopleShow.module.scss";
import { getPeopleListUrl } from "../../../../../../../routes";
import classNames from "classnames";

const PeopleShow = ({
  archivePerson,
  featureToggles,
  fullName,
  hideModal,
  modal,
  personId,
  personType,
  showModal,
  archived,
}) => {
  // Variables declaring archive and unarchive tests and buttons
  const archiveButtonText = archived ? "Unarchive person" : "Archive person";
  const archiveText = archived ? "Unarchive" : "Archive";
  const archiveButtonClassName = classNames({
    [styles.archive]: !archived,
    [styles.unarchive]: archived,
  });

  return (
    <div>
      <PageHeader
        breadCrumbItems={[
          {
            url: getPeopleListUrl(featureToggles, personType, archived),
            text: "People",
          },
          { text: fullName },
        ]}
        title={fullName}
      />

      <ProfileContainer admin personId={personId} />

      <div className={styles.buttonContainer}>
        <button className={archiveButtonClassName} onClick={showModal}>
          {archiveButtonText}
        </button>

        {!archived && (
          // Only show the archive button if the person is not archived
          <Link to={`/admin/people/${personId}/edit`}>
            <button className={styles.edit}>Edit person</button>
          </Link>
        )}
      </div>

      {modal.display && (
        <Modal
          body={
            <div>
              Are you sure you want to {archiveText} {fullName}?
            </div>
          }
          display={modal.display}
          footer={
            <div>
              <button className={styles.modalCancel} onClick={hideModal}>
                Cancel
              </button>

              <button
                className={styles.modalConfirm}
                onClick={() => {
                  archivePerson()
                    .then(() => hideModal())
                    .catch((err) => err);
                }}
              >
                {archiveText}
              </button>
            </div>
          }
          title={`${archiveText} ${fullName}?`}
        />
      )}
    </div>
  );
};

PeopleShow.propTypes = {
  archivePerson: PropTypes.func.isRequired,
  featureToggles: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  personId: PropTypes.number.isRequired,
  personType: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  archived: PropTypes.string.isRequired,
};

export default PeopleShow;
