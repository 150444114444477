/**
 * Merges multiple assignment group entries with the same assignment group ids into one object in an array
 * @param {Array<Object>} assignmentGroups array of assignment groups (which contain different rates)
 */
export function mergeAssignmentGroupsById(assignmentGroups) {
  const assignmentGroupsMap = assignmentGroups.reduce((acc, a) => {
    const {
      assignmentCount,
      assignmentGroupId,
      completedAssignmentCount,
      eligibleForReassignmentCount,
      minRate,
    } = a;

    // reset the cummulative values for the assignment groups
    if (!acc[assignmentGroupId]) {
      acc[assignmentGroupId] = {
        ...a,
        assignmentCount: 0,
        completedAssignmentCount: 0,
        eligibleForReassignmentCount: 0,
        totalRate: 0,
        assignments: [],
      };
    }

    // update the values which change based on different rates being applied
    acc[assignmentGroupId].assignmentCount += assignmentCount;
    acc[assignmentGroupId].completedAssignmentCount += completedAssignmentCount;
    acc[
      assignmentGroupId
    ].eligibleForReassignmentCount += eligibleForReassignmentCount;
    acc[assignmentGroupId].totalRate += minRate * assignmentCount;

    // keep a track of how many assignments are at a specific rate
    acc[assignmentGroupId].assignments.push({
      assignmentCount,
      minRate,
    });

    return acc;
  }, {});

  // turn the tree back into an array (but now with no duplicate assignment group ids)
  return Object.values(assignmentGroupsMap);
}
