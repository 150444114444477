import createStore from "../store/createStore";
import { fetchConstantDataSuccess } from "../store/constantData";
import { fetchFeatureTogglesSuccess } from "../modules/featureToggles";
import { fetchMeSuccess } from "../modules/me";

const initialiseStore = (initalData) => {
  const store = createStore();

  store.dispatch(fetchConstantDataSuccess(initalData));
  const { me, featureToggles } = initalData;

  if (me) {
    store.dispatch(fetchMeSuccess(me));
  }

  if (featureToggles) {
    const formattedToggles = featureToggles.reduce(
      (memo, { name, enabled }) => ({
        ...memo,
        [name]: enabled,
      }),
      {}
    );
    store.dispatch(fetchFeatureTogglesSuccess(formattedToggles));
  }
  return store;
};

export default initialiseStore;
