import React from "react";
import PropTypes from "prop-types";
import styles from "./NestedRow.module.scss";

const NestedRow = (props) => {
  const rowStyles = props.customStyles
    ? `${styles.row} ${props.customStyles}`
    : styles.row;
  return (
    <div className={rowStyles} onClick={props.handleRowClick}>
      <div className={styles.name}>{props.name}</div>
      {props.children}
    </div>
  );
};

NestedRow.propTypes = {
  handleRowClick: PropTypes.func.isRequired,
  customStyles: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default NestedRow;
