import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import { startRequest, endRequest } from "./requestTracker";
import { showErrors } from "../modules/errors";
import {
  rateBandVolumesUpdateQuery,
  updateRateBandVolumeSuccess,
} from "./rateBandVolume";

const rateBandItemFields = `rateBandId, stageId, languageCode, rate`;

export const rateBandItemsByProjectIdQuery = `rateBandItems (projectId: $projectId) {
  ${rateBandItemFields}
}`;

export const rateBandItemsByProjectLanguageQuery = `rateBandItems (projectId: $projectId, languageCode: $languageCode) {
  ${rateBandItemFields}
}`;

export const rateBandItemsUpdateQuery = `updateRateBandItems (associations: $associations){
  ${rateBandItemFields}
}`;

const REQUEST_NAME = `updatingRateBands`;
export const REPLACE_RATE_BAND_ITEMS_SUCCESS =
  "REPLACE_RATE_BAND_ITEMS_SUCCESS";
export const FETCH_RATE_BAND_ITEMS_SUCCESS = "FETCH_RATE_BAND_ITEMS_SUCCESS";

export const replaceRateBandItemsSuccess = createAction(
  REPLACE_RATE_BAND_ITEMS_SUCCESS
);
export const fetchRateBandItemsSuccess = createAction(
  FETCH_RATE_BAND_ITEMS_SUCCESS
);

/**
 * Fetch rate band items by project and language, then dispatch to the store
 *
 * @param {number} projectId
 * @param {string} languageCode
 * @returns {function} dispatcher function
 */
export function fetchLanguageRateBandItems(projectId, languageCode) {
  return async (dispatch) => {
    const { rateBandItems } = await getGraphQL(
      `
      query fetchLanguageRateBandItems ($projectId: String, $languageCode: String) {
        ${rateBandItemsByProjectLanguageQuery}
      }
    `,
      { projectId, languageCode }
    );

    dispatch(replaceRateBandItemsSuccess(rateBandItems));
  };
}

const processAssociations = async (
  associations,
  query,
  queryVars,
  successAction,
  dispatch
) => {
  if (associations.length > 0) {
    const results = await postGraphQL(query, queryVars, successAction);
    if (results) {
      dispatch(successAction(results));
    }
  }
};

export function updateRateBandItems(
  { rateBandItems, volumes },
  history,
  params,
  location
) {
  const projectCreation = location.state
    ? location.state.projectCreation
    : false;
  const { projectId } = params;

  return async (dispatch, getState) => {
    const associations = [];
    const volumeAssociations = [];

    Object.keys(volumes).forEach((rateBandId) => {
      Object.keys(volumes[rateBandId]).forEach((languageCode) => {
        volumeAssociations.push({
          rateBandId,
          languageCode,
          volume: volumes[rateBandId][languageCode],
        });
      });
    });

    Object.keys(rateBandItems).forEach((rateBandId) => {
      Object.keys(rateBandItems[rateBandId]).forEach((stageId) => {
        Object.keys(rateBandItems[rateBandId][stageId]).forEach(
          (languageCode) => {
            associations.push({
              rateBandId: Number(rateBandId),
              stageId: Number(stageId),
              languageCode,
              rate: rateBandItems[rateBandId][stageId][languageCode],
            });
          }
        );
      });
    });

    const queryVars = { associations };
    const query = `mutation updateRateBandItems ($associations: [RateBandItemInput]){
      ${rateBandItemsUpdateQuery}
    }`;

    const volumeAssociationsQuery = `mutation updateRateBandVolumes ($associations: [RateBandVolumeInput]){
      ${rateBandVolumesUpdateQuery}
    }`;

    // disable buttons while we are processing
    dispatch(startRequest(REQUEST_NAME));

    try {
      await processAssociations(
        associations,
        query,
        queryVars,
        replaceRateBandItemsSuccess,
        dispatch
      );

      await processAssociations(
        volumeAssociations,
        volumeAssociationsQuery,
        { associations: volumeAssociations },
        updateRateBandVolumeSuccess,
        dispatch
      );

      if (projectCreation) {
        history.push({
          pathname: `/admin/projects/${projectId}/fields`,
          state: { projectCreation: true },
        });
      } else {
        history.push(`/admin/projects/${projectId}`);
      }
    } catch (err) {
      // if an error occurs, show it via the error component
      const errors = err.errors.map((error) => error.message);
      dispatch(showErrors(errors));
    } finally {
      // Always re-enable buttons after requests are done / errors handled
      dispatch(endRequest(REQUEST_NAME));
    }
  };
}
export const rateBandItemActionHandlers = {
  [RESET_INITIAL_STATE]: () => rateBandItemInitialState,
  [REPLACE_RATE_BAND_ITEMS_SUCCESS]: (state, { payload }) => {
    const newState = [...state];

    payload.forEach((rateBandItem) => {
      const { rateBandId, stageId, languageCode } = rateBandItem;

      const index = newState.indexOf(
        newState.find(
          (rbi) =>
            rbi.rateBandId === rateBandId &&
            rbi.stageId === stageId &&
            rbi.languageCode === languageCode
        )
      );

      if (index > -1) {
        newState[index] = rateBandItem;
      } else {
        newState.push(rateBandItem);
      }
    });

    return newState;
  },
  [FETCH_RATE_BAND_ITEMS_SUCCESS]: (state, { payload }) =>
    state.concat(payload),
};

export const rateBandItemInitialState = [];
