// Checks to see if an object contains any keys with a falsy value
export const objectHasEmptyKey = (object) => {
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    if (!object[keys[i]]) return true;
  }
  return false;
};

/**
 * Safely access a nested object either returning the value nested or null (no errors in the code)
 *
 * @param {*} obj the object we want to access
 * @param {Array<String|Number>} accessors an array of properties to access in the array
 * @param {*} [defaultValue]
 */
export const safeObjectAccess = (obj, accessors, defaultValue) =>
  accessors.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj) ||
  defaultValue;

/**
 * @param   {Object}    data    original data
 * @param   {string[]}  fields  whitelist of properties
 * @returns {Object}    filtered object
 */
export const filterObject = (data, fields) =>
  fields.reduce((acc, field) => {
    acc[field] = data[field];
    return acc;
  }, {});

/**
 * Merge an array of data into a data object
 *
 * @param   {Object}    data  original object of data, indexed by a key
 * @param   {Object[]}  array array of data to merge
 * @param   {string}    key   identifier key to merge the data by
 * @returns {Object}    combined data
 */
export const mergeArrayData = (data, array, key) => {
  if (array.length === 0) return data;

  return {
    ...data,
    ...array.reduce((acc, cur) => {
      acc[cur[key]] = cur;
      return acc;
    }, {}),
  };
};
