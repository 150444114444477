import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
import { upsertData } from "../utils/normalize";

export const transitionHistoryQuery = `transitionHistory (deliverableId: $deliverableId) { 
  transitionLogId, personId, firstName, lastName, fromStage, toStage, createDate, deliverableId
}`;

export const FETCH_TRANSITION_HISTORY_SUCCESS =
  "FETCH_TRANSITION_HISTORY_SUCCESS";

export const fetchTransitionHistoryDetailsSuccess = createAction(
  FETCH_TRANSITION_HISTORY_SUCCESS
);

export const transitionHistoryActionHandlers = {
  [RESET_INITIAL_STATE]: () => transitionHistoryInitialState,
  [FETCH_TRANSITION_HISTORY_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "transitionLogId"),
};

export const transitionHistoryInitialState = { entities: {}, result: [] };
