import React, { useState, useEffect } from "react";
import styles from "./TableContent.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import BatchDetails from "./BatchDetails";
import parseQuery from "../../../../../../../../utils/parseQuery";

export const TableContent = (props) => {
  const { clientStagesLength, projectId, featureToggles, location, totals } =
    props;

  const [activeBatchId, showBatch] = useState();
  const [batchDetailsId, showDetails] = useState();
  const [filteredBatches, setfilteredBatches] = useState(totals);

  useEffect(() => {
    const { batchId } = parseQuery(location.search);
    const totalfound = totals.find((b) => b.batchId === Number(batchId));
    setfilteredBatches(batchId ? [totalfound] : totals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals, location.search]);

  const isMobile = classNames({
    [styles.isMobile]: true,
  });

  const stageTotalCell = (
    stageTotal,
    stageId,
    batchId,
    languageCode,
    stageName
  ) => {
    if (stageTotal === null) return null;
    if (stageTotal === 0)
      return (
        <div className={`${styles.tableContentCell}`}>
          <span className={`${isMobile}`}>{stageName}</span>0
        </div>
      );

    const isLink = classNames({
      [styles.highlightValue]: true,
      [styles.highlightNoLink]: !languageCode,
    });

    return (
      <Link
        className={isLink}
        to={`/clients/projects/${projectId}/batches/${batchId}/stages/${stageId}/?language=${languageCode}`}
      >
        <span className={isMobile}>{stageName}</span>
        {stageTotal}
      </Link>
    );
  };

  const tableRowContent = filteredBatches.map(
    ({
      batchId,
      batchName,
      inProduction,
      inAmends,
      languageCode,
      inNew1,
      inNewContent1Stage,
      inNew2,
      inNewContent2Stage,
      inNew3,
      inNewContent3Stage,
      inAmended1,
      inAmendedContent1Stage,
      inAmended2,
      inAmendedContent2Stage,
      inAmended3,
      inAmendedContent3Stage,
      approved,
      percentageComplete,
      total,
    }) => {
      const tableContent = classNames({
        [styles.tableContent]: true,
        [styles.threeInReview]: clientStagesLength === 3,
        [styles.fourInReview]: clientStagesLength === 4,
        [styles.sixInReview]: clientStagesLength === 6,
        [styles.expanded]: batchId === activeBatchId,
      });

      const detailContent = classNames({
        [styles.details]: true,
        [styles.expandedDetails]: batchId === activeBatchId,
      });

      const expandDetails = () => {
        batchId === batchDetailsId ? showDetails() : showDetails(batchId);
        batchId === activeBatchId ? showBatch() : showBatch(batchId);
      };

      return [
        <div
          key={`${batchId}_mobile`}
          className={`${isMobile} ${styles.mobileBatchName}`}
          onClick={expandDetails}
        >
          <span>{batchName}</span>
          {batchId === activeBatchId ? (
            <svg height="15px" width="15px">
              <path d="M0 10.5l1 1 7-7 7 7 1-1-8-8-8 8z" />
            </svg>
          ) : (
            <svg height="15px" width="15px">
              <path d="M16 5.5l-1-1-7 7-7-7-1 1 8 8 8-8z" />
            </svg>
          )}
        </div>,

        <div key={batchId} className={tableContent}>
          <div
            className={`${styles.tableContentCell} ${styles.batchName} ${styles.isDesktop}`}
          >
            {batchName}
            {featureToggles.QCC_1728_batchDetails && (
              <button
                className={`${styles.showBatchDetails} ${styles.isDesktop}`}
                onClick={expandDetails}
              >
                <svg height="15px" width="15px">
                  {" "}
                  {batchId === activeBatchId ? (
                    <path d="M1 7h14v2h-14v-2z" fill="#000" />
                  ) : (
                    <path d="M16 5.5l-1-1-7 7-7-7-1 1 8 8 8-8z" fill="#000" />
                  )}
                </svg>
              </button>
            )}
          </div>
          <div
            className={`${styles.tableContentCell} ${isMobile} ${styles.inProgress}`}
          >
            With Quill
          </div>
          <div className={`${styles.tableContentCell} ${styles.inProduction}`}>
            <span className={isMobile}>In production: </span> {inProduction}
          </div>
          <div className={`${styles.tableContentCell} ${styles.inAmends}`}>
            <span className={isMobile}>In Amends:</span> {inAmends}
          </div>
          <div
            className={`${styles.tableContentCell} ${isMobile} ${styles.inProgress}`}
          >
            With you for review
          </div>
          {stageTotalCell(
            inNew1,
            inNewContent1Stage,
            batchId,
            languageCode,
            "In new content for review 1: "
          )}
          {stageTotalCell(
            inNew2,
            inNewContent2Stage,
            batchId,
            languageCode,
            "In new content for review 2: "
          )}
          {stageTotalCell(
            inNew3,
            inNewContent3Stage,
            batchId,
            languageCode,
            "In new content for review 3: "
          )}
          {stageTotalCell(
            inAmended1,
            inAmendedContent1Stage,
            batchId,
            languageCode,
            "In amended content for review 1: "
          )}
          {stageTotalCell(
            inAmended2,
            inAmendedContent2Stage,
            batchId,
            languageCode,
            "In amended content for review 2: "
          )}
          {stageTotalCell(
            inAmended3,
            inAmendedContent3Stage,
            batchId,
            languageCode,
            "In amended content for review 3: "
          )}
          <div
            className={`${styles.tableContentCell} ${isMobile} ${styles.inProgress}`}
          >
            Totals
          </div>
          <div className={`${styles.approved} ${styles.tableContentCell}`}>
            <span className={isMobile}>Approved: </span>
            {approved}
          </div>
          <div className={styles.tableContentCell}>
            <span className={isMobile}>Total: </span>
            {total}
          </div>
          <div className={styles.tableContentCell}>
            <span className={isMobile}>Percentage complete: </span>
            {percentageComplete}%
          </div>
          <div
            className={`${styles.tableContentCell} ${isMobile} ${styles.inProgress}`}
          >
            Details
          </div>
        </div>,
        <div key={`${batchId}_details`} className={detailContent}>
          <div className={`${styles.transitionHeight}`}>
            {batchId === activeBatchId && <BatchDetails batchId={batchId} />}
          </div>
        </div>,
      ];
    }
  );

  return <div>{tableRowContent}</div>;
};

TableContent.propTypes = {
  clientStagesLength: PropTypes.number,
  featureToggles: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  totals: PropTypes.array.isRequired,
};

export default TableContent;
