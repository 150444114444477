import Helmet from "react-helmet";
import PageHeader from "../../../../../components/PageHeader";
import Table from "./Table";
import styles from "./AiEnhancementList.module.scss";
import { Link } from "react-router-dom";

const headers = [
  { key: "aiEnhancementId", label: "ID" },
  { key: "jobId", label: "Job ID" },
  { key: "fileName", label: "File name" },
  { key: "fullName", label: "User" },
  { key: "accountName", label: "Account Name" },
  { key: "projectName", label: "Project Name" },
  { key: "createDate", label: "Create Date" },
  { key: "status", label: "Status" },
  { key: "Actions", label: "Actions" },
];

const AiEnhancementList = ({ aiEnhancements, download }) => {
  return (
    <div>
      <Helmet>
        <title>Input Preparation for PLP</title>
      </Helmet>

      <PageHeader
        breadCrumbItems={[
          { url: "/admin", text: "Parent Accounts" },
          { text: "Input Preparation for PLP" },
        ]}
        title="Input Preparation for PLP"
      >
        <div className={styles.pageHeaderRight}>
          <Link to="/admin/ai-enhancement/new">
            <button className={styles.submit}>Create new</button>
          </Link>
        </div>
      </PageHeader>
      <Table data={aiEnhancements} headers={headers} download={download} />
    </div>
  );
};

export default AiEnhancementList;
