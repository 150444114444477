import ProjectsInfo from "./containers/ProjectsInfo";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../../../modules/accounts";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../../../modules/orderForms";
import {
  parentAccountActionHandlers,
  parentAccountInitialState,
} from "../../../../../modules/parentAccounts";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../../../modules/projects";
import {
  bannedWordActionHandlers,
  bannedWordInitialState,
} from "../../../../../modules/bannedWords";
import {
  projectLanguageActionHandlers,
  projectLanguageInitialState,
} from "../../../../../modules/projectLanguages";

export default {
  Component: ProjectsInfo,
  reducers: [
    {
      key: "parentAccounts",
      actionHandlers: parentAccountActionHandlers,
      initialState: parentAccountInitialState,
    },
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
    {
      key: "bannedWords",
      actionHandlers: bannedWordActionHandlers,
      initialState: bannedWordInitialState,
    },
    {
      key: "projectLanguages",
      actionHandlers: projectLanguageActionHandlers,
      initialState: projectLanguageInitialState,
    },
  ],
};
