import PeopleList from "./containers/PeopleList";
import {
  peopleActionHandlers,
  peopleInitialState,
} from "../../../../../modules/people";

export default {
  Component: PeopleList,
  reducers: [
    {
      key: "people",
      actionHandlers: peopleActionHandlers,
      initialState: peopleInitialState,
    },
  ],
};
