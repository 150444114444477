import React from "react";
import PropTypes from "prop-types";
import styles from "./ArrowRenderer.module.scss";
import Icon from "../../../components/Icon";

const ArrowRenderer = ({ onMouseDown, isOpen }) => {
  return (
    <div className={styles.container}>
      <Icon name="ChevronDown" fill={"#717171"} />
    </div>
  );
};

ArrowRenderer.propTypes = {
  onMouseDown: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default ArrowRenderer;
