import React, { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import styles from "./MigrationMaintenanceBanner.module.scss";

const DISMISSED_KEY = "migration-maintenance-banner--dismissed--v2";

function MigrationMaintenanceBanner() {
  const [isDismissed, setIsDismissed] = useLocalStorage(DISMISSED_KEY);

  // don't show banner after 6pm 13th October 2021
  // only if a user hasn't seen it beforehand
  const cutOffShowDate = new Date(2021, 11, 23, 7, 30);
  const currentDate = new Date();

  const [showBanner, setShowBanner] = useState(() => {
    const isCurDateBeforeCutOff = currentDate < cutOffShowDate;
    return isDismissed !== "true" && isCurDateBeforeCutOff;
  });

  const dismissBanner = () => {
    setShowBanner(false);
    // @ts-ignore
    setIsDismissed("true");
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className={styles.migrationBanner}>
      <div>
        J+ Scribe will be briefly offline for routine maintenance on Thursday
        23rd December between 07:00 GMT and 07:30 GMT
      </div>
      <div>
        Please reach out to your Account or Project Manager if you have any
        questions
      </div>
      <div className={styles.dismissContainer} onClick={dismissBanner}>
        <div className={styles.dismiss}>
          <svg height="16" width="16">
            <path
              d="M16 13l-5-5 5-5V2l-2-2h-1L8 5 3 0H2L0 2v1l5 5-5 5v1l2 2h1l5-5 5 5h1l2-2v-1z"
              fill="#F9F9F9"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default MigrationMaintenanceBanner;
