import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./Tabs.module.scss";

function Tabs({ items, activeTab, onClick }) {
  return (
    <div className={styles.nav}>
      {items.map((item) => {
        const isActiveTab = item.name === activeTab;

        const containerStyle = classNames({
          [styles.navItem]: true,
          [styles.active]: isActiveTab,
        });

        const linkStyle = classNames({
          [styles.navLink]: true,
          [styles.activeLink]: isActiveTab,
        });

        return (
          <div
            key={item.name}
            className={containerStyle}
            onClick={() => onClick(item.name)}
          >
            <Link className={linkStyle} to={item.to}>
              {item.label}
            </Link>
          </div>
        );
      })}
    </div>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tabs;
