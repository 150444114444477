import { connect } from "react-redux";
import Component from "./DeliverableEdit";
import WithData from "../../../../../../../../../../decorators/WithData";
import {
  batchByIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../../../modules/batches";
import {
  deliverableWithAdjacentIds,
  fetchDeliverablesSuccess,
} from "../../../../../../../../../../modules/deliverables";
import {
  projectQuery,
  fetchProjectsSuccess,
} from "../../../../../../../../../../modules/projects";
import {
  batchById,
  deliverableById,
  stageById,
  projectById,
} from "../../../../../../../../../../utils/entitySelector";
import { getGraphQL } from "../../../../../../../../../../../utils/fetch";

const initialQuery = `query AdminClientDeliverable ($batchId: String, $deliverableId: String, $projectId: String) {
  ${batchByIdQuery}
  ${deliverableWithAdjacentIds}
  ${projectQuery}
}`;

function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);
    dispatch(fetchBatchesSuccess(json.batch));
    dispatch(fetchDeliverablesSuccess(json.deliverable));
    dispatch(fetchProjectsSuccess(json.project));
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInitialData: (params) => dispatch(getInitialData(params)),
  };
}

function mapStateToProps(state, ownProps) {
  const {
    match: { params = {} },
    history,
    errors = {},
  } = ownProps;
  const { batchId, deliverableId, projectId, stageId } = params;

  const batch = batchById(state, batchId);
  const deliverable = deliverableById(state, deliverableId);
  const project = projectById(state, projectId);
  const stage = stageById(state, stageId);

  const {
    currentStageId,
    languageCode,
    nextIdInBatch,
    prevIdInBatch,
  } = deliverable;
  const baseUrl = `/admin/projects/${projectId}/batches/${batchId}/stages/${currentStageId}`;
  const listUrl = languageCode && `${baseUrl}?language=${languageCode}`;
  const nextUrl = nextIdInBatch && `${baseUrl}/deliverables/${nextIdInBatch}`;
  const previousUrl =
    prevIdInBatch && `${baseUrl}/deliverables/${prevIdInBatch}`;

  return {
    batch,
    deliverableId: Number(deliverableId),
    errors,
    history,
    isCommentable: !stage.isFinal,
    languageCode,
    listUrl,
    nextUrl,
    previousUrl,
    projectId: Number(projectId),
    project,
    stage,
  };
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Component));

export default {
  Component: Container,
};
