import { connect } from "react-redux";
import WithClient from "../components/WithClient";
import WithData from "../../../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
});

const mapStateToProps = (state, ownProps) => ({
  projectId: Number(ownProps.match.params.projectId),
  featureToggles: state.featureToggles,
});

const ProjectWithClient = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(WithClient));
export default ProjectWithClient;
