import { getGraphQL } from "../../../../../../../utils/fetch";

import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
  multiSelectLanguageSuccess,
} from "../../../../../../modules/projectLanguages";
import { personNativeLanguagesQuery } from "../../../../../../modules/personNativeLanguages";

export const initialQuery = `query ClientDashboardFilter ($projectId: String, $personId: String) {
  ${projectLanguagesByProjectIdQuery}
  ${personNativeLanguagesQuery}
}`;

export function getInitialData(props, url, cookies) {
  const { projectId } = props;

  return async (dispatch, getState) => {
    const personId = getState().me;

    const json = await getGraphQL(
      initialQuery,
      { projectId, personId },
      url,
      cookies
    );
    const nativeLanguages = json.personNativeLanguages.map(
      (language) => language.nativeLanguage
    );
    const projectLanguages = json.projectLanguages.map(
      (language) => language.languageCode
    );
    const isAnyCommonLanguage = projectLanguages.some((languageCode) =>
      nativeLanguages.includes(languageCode)
    );
    dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
    dispatch(
      multiSelectLanguageSuccess(
        isAnyCommonLanguage ? nativeLanguages : projectLanguages
      )
    );
  };
}
