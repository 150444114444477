import Completed from "../components/Completed";
import WithData from "../../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import { getAsyncData } from "../modules/getAsyncData";
import { fetchClientBatchDetailsWithLanguage } from "../../../../../../../modules/clientBatchDetails";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  getAsyncData: () => dispatch(getAsyncData(ownProps.match.params)),
  onFilterLanguage: (selectedLanguages) =>
    dispatch(
      fetchClientBatchDetailsWithLanguage(
        ownProps.match.params?.projectId,
        selectedLanguages
      )
    ),
});

const ClientProjectDashboardCompleted = connect(
  null,
  mapDispatchToProps
)(WithData(Completed));
export default ClientProjectDashboardCompleted;
