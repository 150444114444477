import React from "react";
import styles from "./QuillHeader.module.scss";

type Props = {
  className?: string;
  header: string;
  hideRequiredHeader?: boolean;
  note?: string;
  tags?: string;
};

const QuillHeader = (props: Props) => {
  return (
    <div className={`${styles.headerContainer} ${props.className}`}>
      <div className={styles.title}>{props.header}</div>
      {props.tags && <div className={styles.tags}>{props.tags}</div>}
    </div>
  );
};

export default QuillHeader;
