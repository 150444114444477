import React from "react";
import PropTypes from "prop-types";
import VersionHistory from "../../../../components/VersionHistory";

const ClientVersionHistory = ({ versionHistory }) => {
  return <VersionHistory isClient versionHistory={versionHistory} />;
};

ClientVersionHistory.propTypes = {
  versionHistory: PropTypes.array.isRequired,
};

export default ClientVersionHistory;
