import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const personAccountsQuery = `personAccounts (personId: $personId) {
  personId, accountId
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_PERSON_ACCOUNTS_SUCCESS = "FETCH_PERSON_ACCOUNTS_SUCCESS";
export const DELETE_PERSON_ACCOUNTS = "PURGE_PERSON_ACCOUNTS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPersonAccountsSuccess = createAction(
  FETCH_PERSON_ACCOUNTS_SUCCESS
);
export const deletePersonAccounts = createAction(DELETE_PERSON_ACCOUNTS);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const personAccountActionHandlers = {
  [RESET_INITIAL_STATE]: () => personAccountInitialState,
  [FETCH_PERSON_ACCOUNTS_SUCCESS]: (state, { payload }) =>
    payload.reduce((acc, pa) => {
      if (
        acc.some(
          (a) => a.personId === pa.personId && a.accountId === pa.accountId
        )
      ) {
        return acc;
      }
      return acc.concat(pa);
    }, state),
  [DELETE_PERSON_ACCOUNTS]: (state, { payload }) => {
    if (!payload.account) return state;

    const personId = payload.personId;
    const accounts = payload.account.split(",").map((a) => Number(a));
    const personAccounts = [];

    // loop over state and remove all personAccounts which shouldn't be there for this personId
    state.map((personAccount) => {
      if (
        personAccount.personId !== personId ||
        accounts.includes(personAccount.accountId)
      ) {
        personAccounts.push(personAccount);
      }
      return personAccount;
    });

    return personAccounts;
  },
};

// ------------------------------------
// Initial State
// ------------------------------------

export const personAccountInitialState = [];
