import React from "react";
import PropTypes from "prop-types";
import styles from "./QuillForm.module.scss";
import classNames from "classnames";
import QuillHeader from "../../components/QuillHeader";
import QuillFormButtonsContainer from "./QuillFormButtonsContainer";

export const QuillInnerFormContainer = ({
  containerStyle,
  className,
  children,
}) => <div className={`${containerStyle} ${className}`}>{children}</div>;

const QuillForm = ({
  rebrandingContainer,
  header,
  headerNote,
  handleSubmit,
  hideRequiredHeader,
  children,
  errorContainerStyles,
  cancelHandler,
  cancelText,
  cancelUrl,
  buttonContainerStyles,
  disabled,
  submitText,
  error,
  hideButtons,
  uploadForm,
  downloadGlossary,
  formName,
  updateDate,
}) => {
  const containerStyles = classNames({
    [styles.formContainer]: true,
    [styles.wider]: formName === "GlossaryForm",
  });

  const innerFormContainerStyles = classNames({
    [styles.innerFormContainer]: rebrandingContainer,
  });

  return (
    <div className={containerStyles}>
      <QuillHeader
        header={header}
        hideRequiredHeader={hideRequiredHeader}
        note={headerNote}
        tags={updateDate ? `Edited: ${updateDate}` : ""}
      />
      <form onSubmit={handleSubmit}>
        <div className={innerFormContainerStyles}>{children}</div>
        {error ? (
          <div
            className={`${styles.errorContainer} ${errorContainerStyles || ""}`}
          >
            {error}
          </div>
        ) : null}
        {!hideButtons && (
          <QuillFormButtonsContainer
            cancelHandler={cancelHandler}
            cancelText={cancelText}
            cancelUrl={cancelUrl}
            customButtonsContainerStyles={buttonContainerStyles || ""}
            disabled={disabled}
            submitText={submitText}
            uploadForm={uploadForm}
            downloadGlossary={downloadGlossary}
            formName={formName}
          />
        )}
      </form>
    </div>
  );
};

QuillForm.propTypes = {
  buttonContainerStyles: PropTypes.string,
  cancelHandler: PropTypes.func,
  cancelText: PropTypes.string,
  cancelUrl: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  errorContainerStyles: PropTypes.string,
  formContainerStyles: PropTypes.string,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  headerNote: PropTypes.string,
  hideButtons: PropTypes.bool,
  hideRequiredHeader: PropTypes.bool,
  submitText: PropTypes.string,
};

export default QuillForm;
