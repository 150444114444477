import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./ProjectsEdit.module.scss";
import ProjectForm from "../../../_components/ProjectForm";
import Modal from "../../../../../../components/Modal";
import PageHeader from "../../../../../../components/PageHeader";

class ProjectsEdit extends Component {
  constructor(props) {
    super(props);
    if (props.initialError) {
      props.showModal();
    }
  }

  render() {
    const {
      initialValues: { projectId, projectName },
    } = this.props;

    return (
      <div>
        <PageHeader
          title={"Edit project"}
          breadCrumbItems={[
            { url: `/admin`, text: "Parent accounts" },
            {
              text: `${this.props.parentAccount.parentAccountName} | ${this.props.account.accountName} | ${this.props.orderForm.orderFormNumber}`,
            },
            { url: `/admin/projects/${projectId}`, text: `${projectName}` },
            { text: "Edit project" },
          ]}
        />
        <ProjectForm
          cancelUrl={`/admin/projects/${projectId}`}
          contentTypes={this.props.contentTypes}
          workflows={this.props.workflows}
          orderFormIds={this.props.orderFormIds}
          languages={this.props.languages}
          currencies={this.props.currencies}
          initialValues={this.props.initialValues}
          productionTeam={this.props.productionTeam}
          clientTeam={this.props.clientTeam}
          onSubmit={this.props.updateProject}
          editableClientStagesValues={this.props.editableClientStagesValues}
          enableReinitialize
          disabledForEdits
        />
        {this.props.modal.display && (
          <Modal
            display={this.props.modal.display}
            disableBackgroundDismiss
            body={
              <div>
                {this.props.initialError}
                <button
                  className={styles.button}
                  onClick={this.props.hideModal}
                >
                  Ok.
                </button>
              </div>
            }
          />
        )}
      </div>
    );
  }
}

ProjectsEdit.propTypes = {
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string,
  }),
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number,
  }),
  contentTypes: PropTypes.array.isRequired,
  workflows: PropTypes.object.isRequired,
  orderFormIds: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  initialValues: PropTypes.shape({
    projectId: PropTypes.number,
    projectName: PropTypes.string,
    projectLanguages: PropTypes.array,
  }).isRequired,
  updateProject: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  initialError: PropTypes.string,
  productionTeam: PropTypes.array.isRequired,
  clientTeam: PropTypes.array.isRequired,
};

export default ProjectsEdit;
