import styles from "./LinkDecorator.module.scss";
import PropTypes from "prop-types";

function findLinkEntities(contentBlock, callback, contentState) {
  let entityKey;
  contentBlock.findEntityRanges(
    (character) => {
      entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    },
    (start, end) => {
      const entity = contentBlock.getEntityAt(start);
      const text = contentBlock.getText().slice(start, end);
      const { target, title } = contentState.getEntity(entityKey).getData();

      contentState.mergeEntityData(entity, { target, title, text });
      callback(start, end);
    }
  );
}

const Link = (props) => {
  const { url, title } = props.contentState
    .getEntity(props.entityKey)
    .getData();
  const openLink = () => window.open(url, "_blank");
  return (
    <a
      className={styles.link}
      href={url}
      rel="noopener noreferrer"
      title={title}
    >
      {props.children}
      <span className={styles.url} onClick={openLink}>
        {url}
      </span>
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.array.isRequired,
  entityKey: PropTypes.string.isRequired,
  contentState: PropTypes.object.isRequired,
};

export default {
  strategy: findLinkEntities,
  component: Link,
};
