import React from "react";
import PropTypes from "prop-types";
import {
  QuillContainer,
  QuillField,
} from "../../../../../../../components/QuillContainer";
import CurrencyDisplay from "../../../../../../../components/CurrencyDisplay";
import { paymentTaskLabels } from "./../../../../../../../../client/modules/payments";

const PaymentsShowForm = (props) => {
  const { payee, payment, orderForm, currencyCode } = props;

  return (
    <QuillContainer header="Payment" hideRequiredHeader rebrandingContainer>
      {payee && (
        <QuillField
          labelText="Content creator"
          value={`${payee.firstName} ${payee.lastName}`}
        />
      )}
      <QuillField labelText="Account" value={payment.accountName} />
      {orderForm && (
        <QuillField labelText="Order form" value={orderForm.orderFormName} />
      )}
      <QuillField labelText="Project" value={payment.projectName} />
      {payment.task && (
        <QuillField
          labelText="Task"
          value={paymentTaskLabels[payment.task] || payment.task}
        />
      )}
      {payment.stageName && (
        <QuillField labelText="Task" value={payment.stageName} />
      )}
      <QuillField labelText="Approved date" value={payment.createDate} />
      {payment.deliverableId && (
        <QuillField labelText="Deliverable ID" value={payment.deliverableId} />
      )}
      {payment.batchName && (
        <QuillField labelText="Batch" value={payment.batchName} />
      )}
      <CurrencyDisplay
        isFormField
        text="Rate"
        rate={payment.rate}
        currencyCode={currencyCode}
      />
      <QuillField labelText="Type" value={payment.paymentType || "WORKFLOW"} />
      {payment.notes && <QuillField labelText="Notes" value={payment.notes} />}
    </QuillContainer>
  );
};

PaymentsShowForm.propTypes = {
  payment: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    task: PropTypes.string,
    stageName: PropTypes.string,
    createDate: PropTypes.string.isRequired,
    assignmentId: PropTypes.number,
    batchName: PropTypes.string,
    rate: PropTypes.number,
    paymentType: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  payee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  orderForm: PropTypes.shape({
    orderFormName: PropTypes.string,
  }),
};

export default PaymentsShowForm;
