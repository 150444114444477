import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import {
  QuillForm,
  QuillFormField,
} from "../../../../../../components/QuillForm";
import { SelectWithErrors } from "../../../../../../decorators/WithErrors";
import { PAYMENT_TYPES_DROPDOWN } from "../../../../../../modules/payments";
import { getYearMonthDropdownOptions } from "../../../../../../utils/date";
import styles from "./PaymentsForm.module.scss";
import validate from "./validations";

const monthList = getYearMonthDropdownOptions(2018);

let PaymentsForm = class PaymentsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: "",
      orderFormId: "",
      paymentType: null,
      projectId: null,
    };
  }

  freelancerSearch = debounce((input) => {
    if (input.length > 1) {
      if (this.props.searchFreelancers) {
        this.props.searchFreelancers(input);
      }
    }
  }, 150);

  createDropdown = ({
    fieldName,
    key,
    placeholder,
    options,
    onInputChange,
    onChange,
    required,
    disabled,
  }) => (
    <div className={styles.formGroup}>
      <QuillFormField
        labelText={fieldName}
        required={required}
        placeholder={placeholder}
        name={key}
        component={SelectWithErrors}
        options={options}
        clearable={false}
        disabled={disabled || this.props.submitting}
        onInputChange={onInputChange}
        onChange={onChange}
      />
    </div>
  );

  /* Reset the state and redux-form values */
  resetOrderFormId = () => {
    this.props.change("orderFormId", null);
    this.setState({ orderFormId: "" });
    this.resetProjectId();
  };

  resetProjectId = () => {
    this.props.change("projectId", null);
    this.props.change("stageName", null);
    this.setState({ projectId: "" });
  };

  /* Update state and redux-form values */
  updateAccount = (e, accountId) => {
    if (this.state.accountId !== accountId) {
      this.setState({ accountId });
      this.resetOrderFormId();

      if (accountId) {
        this.props.fetchOrderFormsByAccountId(accountId);
      }
    }
  };

  updateOrderForm = (e, orderFormId) => {
    if (this.state.orderFormId !== orderFormId) {
      this.setState({ orderFormId });
      this.resetProjectId();

      if (orderFormId) {
        this.props.fetchProjectsByOrderFormId(orderFormId);
      }
    }
  };

  updatePaymentType = (e, paymentType) => {
    if (this.state.paymentType !== paymentType) {
      this.setState({ paymentType });
    }
  };

  updateProject = (e, projectId) => {
    if (this.state.projectId !== projectId) {
      this.setState({ projectId });
      this.props.change("stageName", null);
    }
  };

  render() {
    const hasAccountId = this.props.values.accountId;
    const hasOrderFormId = this.props.values.orderFormId;
    return (
      <div>
        <div className={styles.containerForm}>
          <QuillForm
            rebrandingContainer
            header="Payment search"
            disabled={this.props.submitting || !this.props.valid}
            error={this.props.error}
            hideButtons
          >
            {this.createDropdown({
              fieldName: "Content creator",
              key: "personId",
              placeholder: "Search...",
              options: this.props.freelancers,
              onInputChange: this.freelancerSearch,
              required: true,
            })}
            {this.createDropdown({
              fieldName: "Month",
              key: "month",
              placeholder: "Select month...",
              options: monthList,
              required: true,
            })}
            {this.createDropdown({
              fieldName: "Account",
              key: "accountId",
              placeholder: "Search...",
              options: this.props.accounts,
              onInputChange: this.accountSearch,
              onChange: this.updateAccount,
            })}
            {this.createDropdown({
              fieldName: `Order form ${
                !hasAccountId ? "(account required)" : ""
              }`,
              key: "orderFormId",
              disabled: !hasAccountId,
              placeholder: "Search...",
              options: this.props.orderForms,
              onChange: this.updateOrderForm,
            })}
            {this.createDropdown({
              fieldName: `Project ${
                !hasOrderFormId ? "(order form required)" : ""
              }`,
              key: "projectId",
              placeholder: "Search...",
              disabled: !hasOrderFormId,
              options: this.props.projects,
              onChange: this.updateProject,
            })}
            {this.createDropdown({
              fieldName: "Type",
              key: "paymentType",
              placeholder: "Search...",
              options: PAYMENT_TYPES_DROPDOWN,
              onChange: this.updatePaymentType,
            })}

            {this.state.paymentType === "AD_HOC"
              ? this.createDropdown({
                  fieldName: "Task",
                  key: "paymentTask",
                  placeholder: "Search...",
                  options: this.props.tasks,
                })
              : undefined}

            {this.state.paymentType === "WORKFLOW"
              ? this.createDropdown({
                  fieldName: "Stage",
                  key: "stageName",
                  placeholder: "Search...",
                  options: this.props.stages,
                })
              : undefined}
          </QuillForm>
        </div>
      </div>
    );
  }
};

PaymentsForm.propTypes = {
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  change: PropTypes.func.isRequired,
  searchFreelancers: PropTypes.func.isRequired,
  fetchOrderFormsByAccountId: PropTypes.func.isRequired,
  fetchProjectsByOrderFormId: PropTypes.func.isRequired,
  freelancers: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  orderForms: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
};

formValueSelector("paymentsSearchForm");
PaymentsForm = connect((state) => ({
  values: state?.form?.paymentsSearchForm?.values || {},
  prevPaymentValue:
    state.form.paymentsForm &&
    state.form.paymentsForm.values &&
    state.form.paymentsForm.values.payment,
}))(PaymentsForm);

PaymentsForm = reduxForm({
  form: "paymentsSearchForm",
  validate,
})(PaymentsForm);

export default PaymentsForm;
