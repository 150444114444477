import { connect } from "react-redux";
import { getTranslationMemoryContext } from "../../../../../modules/translations";
import GlossaryTab from "./GlossaryTab";

const mapStateToProps = (state, { deliverableId, projectId }) => ({
  translationMemoryContext: getTranslationMemoryContext(
    state,
    deliverableId,
    projectId
  ),
});

export default connect(mapStateToProps)(GlossaryTab);
