import React, { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./GlossaryTab.module.scss";
import useDebounce from "../../../../../hooks/useDebounce";
import { fetchGlossaryMatches } from "../../../../../modules/translations";
import SearchResults from "./SearchResults";

const GlossaryTab = ({ translationMemoryContext, isGlossary }) => {
  const input = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const minCharCount = 4;

  useEffect(() => {
    input.current.focus();
  }, []);

  // fetch similar terms when the debounced search text changes
  useEffect(() => {
    if (debouncedSearchTerm.length >= minCharCount) {
      fetchGlossaryMatches({ ...translationMemoryContext, isGlossary }, [
        debouncedSearchTerm,
      ]).then((translations) => {
        setSearchResults(translations);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [debouncedSearchTerm, translationMemoryContext, isGlossary]);

  const onChange = ({ target: { value } }) => {
    setSearchTerm(value);

    // only show the loading spinner if there is a search term and the previous searched term is different
    setLoading(value.length > 0 && debouncedSearchTerm !== value);

    // if search term is blank then reset the returned results
    if (value.length === 0) {
      setSearchResults([]);
    }
  };

  const searchIconStyles = classNames({
    [styles.searchIcon]: true,
    [styles.hide]: searchTerm.length > 0,
  });

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <input ref={input} className={styles.search} onChange={onChange} />
        {/* eslint-disable max-len */}
        <svg className={searchIconStyles} viewBox="0 0 16 16">
          <path d="M15.5 13.62l-3.79-3.23a1.7 1.7 0 0 0-1.15-.5 6 6 0 1 0-.67.68c-.01.33.15.75.5 1.15l3.22 3.78c.56.62 1.46.67 2 .12s.5-1.45-.1-2zM6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
        </svg>
        {/* eslint-enable max-len */}
      </div>

      <SearchResults
        isLoading={isLoading}
        minCharCount={minCharCount}
        searchResults={searchResults}
        searchTerm={searchTerm}
        isGlossary={isGlossary}
      />
    </div>
  );
};

GlossaryTab.propTypes = {
  translationMemoryContext: PropTypes.object.isRequired,
};

export default memo(GlossaryTab);
