import { getGraphQL } from "../../../../../../../../utils/fetch";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../modules/projects";
import {
  projectTotalsQuery,
  fetchProjectTotalsDetailsSuccess,
} from "../../../../../../../modules/projectTotals";

export const initialQuery = `query ClientProjectOverview ($projectId: String) {
  ${projectTotalsQuery}
  ${projectQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);
    dispatch(fetchProjectTotalsDetailsSuccess(json.projectTotals));
    dispatch(fetchProjectSuccess(json.project));
  };
}
