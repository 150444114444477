import { fetchInitialData } from "../../server/services/fetchInitialData";

const getInitalData = async () => {
  if (process.env.NODE_ENV === "development") {
    return fetchInitialData();
  }

  const initalDataInput = document.getElementById("initalData");
  const { value }: any = initalDataInput || {};
  return value ? JSON.parse(value) : {}; // should never be nothing??
};

export default getInitalData;
