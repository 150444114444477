import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  QuillForm,
  QuillFormField,
} from "../../../../../../../components/QuillForm";
import { QuillField } from "../../../../../../../components/QuillContainer";
import {
  InputWithErrors,
  DatePickerWithErrors,
} from "../../../../../../../decorators/WithErrors";
import { rateParser } from "../../../../utils/fieldParsers";

import validate from "./validations";
import CurrencyDisplay from "../../../../../../../components/CurrencyDisplay";
import {
  PAYMENT_TYPE_AD_HOC_DRAFT,
  paymentTaskLabels,
} from "../../../../../../../modules/payments";

let PaymentsEditForm = (props) => {
  const {
    baseUrl,
    payment,
    disabled,
    handleSubmit,
    payee,
    orderForm,
    error,
    valid,
    currencyCode,
  } = props;

  const isAdHocDraft = payment.paymentType === PAYMENT_TYPE_AD_HOC_DRAFT;

  return (
    <QuillForm
      header="Edit payment"
      cancelText="Cancel"
      cancelUrl={baseUrl}
      submitText={isAdHocDraft ? "Save draft" : "Save payment"}
      handleSubmit={handleSubmit}
      error={error}
      disabled={!valid || disabled}
      hideRequiredHeader
      rebrandingContainer
    >
      {payee && (
        <QuillField
          labelText="Freelancer"
          value={`${payee.firstName} ${payee.lastName}`}
        />
      )}
      <QuillField labelText="Account" value={payment.accountName} />
      {orderForm && (
        <QuillField labelText="Order form" value={orderForm.orderFormName} />
      )}
      <QuillField labelText="Project" value={payment.projectName} />
      {payment.task && (
        <QuillField labelText="Task" value={paymentTaskLabels[payment.task]} />
      )}
      {payment.stageName && (
        <QuillField labelText="Task" value={payment.stageName} />
      )}
      <QuillFormField
        component={DatePickerWithErrors}
        name="createDate"
        labelText="Approved date"
        value={payment.createDate}
      />
      {payment.deliverableId && (
        <QuillField labelText="Deliverable ID" value={payment.deliverableId} />
      )}
      {payment.batchName && (
        <QuillField labelText="Batch" value={payment.batchName} />
      )}
      <CurrencyDisplay
        name="newRate"
        text="Updated rates -"
        isInputField={true}
        currencyCode={currencyCode}
        parsedRate={rateParser(props.prevRateValue)}
      />
      <QuillField labelText="Type" value={payment.paymentType || "WORKFLOW"} />
      {payment.notes && <QuillField labelText="Notes" value={payment.notes} />}
      <QuillFormField
        labelText="Reason for change"
        name="reason"
        component={InputWithErrors}
        disabled={disabled}
        maxLength={255}
      />
    </QuillForm>
  );
};

PaymentsEditForm = reduxForm({
  form: "paymentsEdit",
})(PaymentsEditForm);

PaymentsEditForm = connect((state, ownProps) => {
  const { createDate } = ownProps.payment;

  return {
    prevRateValue:
      state.form.paymentsEdit &&
      state.form.paymentsEdit.values &&
      state.form.paymentsEdit.values.newRate,
    initialValues: {
      paymentId: Number(ownProps.match.params.paymentId),
      newRate: ownProps.payment.rate,
      createDate: new Date(createDate),
    },
    validate: (values) =>
      validate({
        ...values,
        prevRate: ownProps.payment.rate,
        prevCreateDate: createDate,
      }),
  };
})(PaymentsEditForm);

PaymentsEditForm.propTypes = {
  payment: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    task: PropTypes.string,
    stageName: PropTypes.string,
    createDate: PropTypes.string.isRequired,
    assignmentId: PropTypes.number,
    batchName: PropTypes.string,
    rate: PropTypes.number,
    paymentType: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  payee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  orderForm: PropTypes.shape({
    orderFormName: PropTypes.string,
  }),
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  prevRateValue: PropTypes.string,
};

export default PaymentsEditForm;
