import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { Component as ProfileContainer } from '../../../../../components/Profile'
import PropTypes from 'prop-types'
import PageHeader from '../../../../../components/PageHeader'
import styles from './ProfileShow.module.scss'

const ProfileShow = ({ fullName, personId }) => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Profile</title>
      </Helmet>

      <div>
        <PageHeader title={fullName} />
      </div>

      <ProfileContainer freelancerProfile personId={personId} />

      <div className={styles.buttonContainer}>
        <Link to={`/freelancer/profile/edit`} className={styles.link}>
          <button className={styles.button}>Edit profile</button>
        </Link>
      </div>
    </div>
  )
}

ProfileShow.propTypes = {
  fullName: PropTypes.string.isRequired,
  personId: PropTypes.number.isRequired,
}

export default ProfileShow
