import ClientAccounts from "./containers/ClientAccounts";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../modules/accounts";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../modules/orderForms";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../modules/projects";
import {
  personAccountActionHandlers,
  personAccountInitialState,
} from "../../../modules/personAccounts";

export default {
  Component: ClientAccounts,
  reducers: [
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
    {
      key: "personAccounts",
      actionHandlers: personAccountActionHandlers,
      initialState: personAccountInitialState,
    },
  ],
};
