import Papa from "papaparse";
import { postGraphQL } from "../../../../../../utils/fetch";

export function processFileHandler(data, history) {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedAttributeFieldIds =
      state.form["AIEnhancementForm"].values?.aiAttributes;
    const { file, projectId, fileName } = data;

    return new Promise((resolve, reject) => {
      if (!projectId) {
        reject(new Error("Please select a project."));
        return;
      }

      if (selectedAttributeFieldIds?.length === 0) {
        reject(new Error("Please select at least one attribute field."));
        return;
      }

      Papa.parse(file, {
        header: true, // Parse the CSV with header
        complete: async (results) => {
          const rows = results.data;

          if (!rows.some((row) => row.URL)) {
            reject(new Error("No URLs present in the CSV rows."));
            return;
          }

          const validRows = rows.filter((row) => row.URL); // Filter out rows with no URL value
          const headers = results.meta.fields; // Get all headers from the CSV file

          const input = {
            projectId,
            selectedAttributeFieldIds,
            rows: validRows.flatMap(
              (
                row,
                index // Use flatMap to merge all objects into a single array
              ) =>
                headers.map((fieldName, pos) => ({
                  rowId: index,
                  briefingFieldName: fieldName,
                  briefingFieldValue: row[fieldName],
                  briefingFieldPosition: pos,
                }))
            ),
            fileName,
          };

          try {
            const query = `
              mutation enhanceAiContent($selectedAttributeFieldIds: [Int], $projectId: Int!, $rows: [RowInput], $fileName: String) {
                enhanceAiContent(selectedAttributeFieldIds: $selectedAttributeFieldIds, projectId: $projectId, rows: $rows, fileName: $fileName) {
                  aiEnhancementId
                  jobId
                  projectName
                  fullName
                  accountName
                  createDate
                  status,
                  fileName
                }
              }
            `;

            const response = await postGraphQL(
              query,
              input,
              "enhanceAiContent"
            );
            resolve(response);
          } catch (error) {
            reject(error);
          }
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };
}
