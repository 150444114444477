import Task from "./Task";
import { connect } from "react-redux";
import { stageById } from "../../../../utils/entitySelector";
import { taskCommentGroupsSelectorFactory } from "../../../../modules/commentGroups";
import { fetchGrammarCheckIgnores } from "../../../../modules/grammarCheckIgnores";

const RTL_LANGUAGES = [
  "ar-AE",
  "ar-BH",
  "ar-DZ",
  "ar-EG",
  "ar-IQ",
  "ar-JO",
  "ar-KW",
  "ar-LB",
  "ar-LY",
  "ar-MA",
  "ar-OM",
  "ar-QA",
  "ar-SA",
  "ar-SD",
  "ar-SY",
  "ar-TN",
  "ar-YE",
  "fa-IR",
  "he-IL",
  "ur-PK",
  "ur-IN",
];

const ALIGNMENT_CONFIG = Object.freeze(
  RTL_LANGUAGES.reduce(
    (config, code) => {
      config[code] = { alignment: "right", direction: "RTL" };
      return config;
    },
    { default: { alignment: "left", direction: "LTR" } }
  )
);

const mapDispatchToProps = (dispatch) => ({
  fetchGrammarCheckIgnores: (projectId) =>
    dispatch(fetchGrammarCheckIgnores(projectId)),
});

const mapStateToPropsFactory = () => {
  const taskCommentGroupsSelector = taskCommentGroupsSelectorFactory();

  return (
    state,
    { deliverableId, stageId, taskFieldId, grammarChecks, languageCode }
  ) => {
    const { isFinal: isFinalStage } = stageById(state, stageId);
    const filteredGrammarChecks = grammarChecks.find(
      (cor) => cor.cellId === `${taskFieldId}-${deliverableId}`
    );

    return {
      filteredGrammarChecks,
      commentGroups: taskCommentGroupsSelector(
        state,
        deliverableId,
        stageId,
        taskFieldId
      ),
      dualDecorator: state.featureToggles.dualDecorator,
      isFinalStage,
      textAlignment:
        ALIGNMENT_CONFIG[languageCode]?.alignment ||
        ALIGNMENT_CONFIG.default.alignment,
      textDirectionality:
        ALIGNMENT_CONFIG[languageCode]?.direction ||
        ALIGNMENT_CONFIG.default.direction,
    };
  };
};

export default connect(mapStateToPropsFactory, mapDispatchToProps, null, {
  forwardRef: true,
})(Task);
