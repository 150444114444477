import { upsertData, removeData } from "../utils/normalize";
import { createAction } from "redux-actions";
import { postGraphQL } from "../../utils/fetch";
import { change, reset, SubmissionError } from "redux-form";
import {
  createKeywordsSuccess,
  removeKeywordsSuccess,
} from "../modules/keywords";
import { checkValidations, isNotBlank } from "../utils/validations";
import removeHTML from "../utils/removeHTML";
import { showSuccessMessage, showWarningMessage } from "../modules/messages";
import { extractErrorFromObject } from "../utils/errors";
import { startRequest, endRequest } from "./requestTracker";

import {
  parentDeliverableBriefingFieldsByParentDeliverableSelector,
  parentDeliverableSourceFieldsByParentDeliverableSelector,
  parentDeliverableKeywordsSelector,
  primaryStageSelector,
} from "../utils/entitySelector";

import {
  createParentDeliverableBriefingFieldsSuccess,
  parentDeliverableBriefingFieldFields,
  removeParentDeliverableBriefingFieldsSuccess,
} from "../modules/parentDeliverableBriefingFields";
import {
  fields as parentDeliverableSourceFieldFields,
  createParentDeliverableSourceFieldsSuccess,
  removeParentDeliverableSourceFieldsSuccess,
  addRawContentToParentDeliverableSourceFields,
} from "../modules/parentDeliverableSourceFields";

import { RESET_INITIAL_STATE } from "./me";
export const REQUEST_SOURCE_AMENDS = "requestSourceAmends";
// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const parentDeliverableFields = [
  "parentDeliverableId",
  "projectId",
  "batchId",
  "rateBandId",
  "updateDate",
];

export const deliverableLinkFields = [
  "deliverableId",
  "freelancerName",
  "isApproved",
  "languageName",
  "stageId",
  "stageName",
  "url",
];

export const parentDeliverableQuery = `parentDeliverable (parentDeliverableId: $parentDeliverableId) { 
  ${parentDeliverableFields}, 
  deliverableLinks {
    ${deliverableLinkFields}
  }
}`;
export const parentDeliverablesByProjectQuery = `parentDeliverables (projectId: $projectId) { ${parentDeliverableFields} }`;
export const parentDeliverablesByBatchQuery = `parentDeliverables (batchId: $batchId) { ${parentDeliverableFields} }`;
export const parentDeliverablesByAssignmentGroupQuery = `parentDeliverables(
  assignmentGroupId: $assignmentGroupId, includeArchivedAssignments: $includeArchivedAssignments) {
    ${parentDeliverableFields}
}`;
export const parentDeliverablesByDeliverableQuery = `parentDeliverables(deliverableId: $deliverableId) {
  ${parentDeliverableFields}
}`;
export const parentDeliverablesByAssignmentQuery = `parentDeliverables(assignmentId: $assignmentId) {
  ${parentDeliverableFields}
}`;

export const batchParentDeliverablesByParentDeliverableIdQuery = `parentDeliverables(parentDeliverableId: $parentDeliverableId) {
  ${parentDeliverableFields}
}`;

export const updateParentDeliverablesQuery = `mutation updateParentDeliverables (
  $parentDeliverable: ParentDeliverableInput,
  $parentDeliverableBriefingFields: [ParentDeliverableBriefingFieldInput],
  $parentDeliverableSourceFields: [ParentDeliverableSourceFieldInput],
  $keywords: [KeywordInput]) {
    updateParentDeliverable (
      parentDeliverable: $parentDeliverable,
      parentDeliverableBriefingFields: $parentDeliverableBriefingFields,
      parentDeliverableSourceFields: $parentDeliverableSourceFields,
      keywords: $keywords) {
        parentDeliverable {
          ${parentDeliverableFields}
        }
        parentDeliverableBriefingFields {
          ${parentDeliverableBriefingFieldFields}
        }
        parentDeliverableSourceFields {
          ${parentDeliverableSourceFieldFields}
        }
        keywords {
          keywordId, keywordGroupId, parentDeliverableId, word
        }
      }
}`;

const moveToSourceAmendsQuery = `mutation moveToSourceAmends($parentDeliverableId: Int){ 
  moveToSourceAmends(parentDeliverableId: $parentDeliverableId) {
    ${deliverableLinkFields}
  }
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_PARENT_DELIVERABLE_SUCCESS =
  "FETCH_PARENT_DELIVERABLE_SUCCESS";
export const FETCH_PARENT_DELIVERABLES_SUCCESS =
  "FETCH_PARENT_DELIVERABLES_SUCCESS";
export const FETCH_PARENT_DELIVERABLES_BY_PERSON_ID_SUCCESS =
  "FETCH_PARENT_DELIVERABLES_BY_PERSON_ID_SUCCESS";
const CREATE_PARENT_DELIVERABLE_SUCCESS = "CREATE_PARENT_DELIVERABLE_SUCCESS";
const CREATE_PARENT_DELIVERABLES_SUCCESS = "CREATE_PARENT_DELIVERABLES_SUCCESS";
const UPDATE_PARENT_DELIVERABLE_SUCCESS = "UPDATE_PARENT_DELIVERABLE_SUCCESS";
const ARCHIVE_PARENT_DELIVERABLE_SUCCESS = "ARCHIVE_PARENT_DELIVERABLE_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchParentDeliverableSuccess = createAction(
  FETCH_PARENT_DELIVERABLE_SUCCESS
);
export const fetchParentDeliverablesSuccess = createAction(
  FETCH_PARENT_DELIVERABLES_SUCCESS
);
export const createParentDeliverableSuccess = createAction(
  CREATE_PARENT_DELIVERABLE_SUCCESS
);
export const createParentDeliverablesSuccess = createAction(
  CREATE_PARENT_DELIVERABLES_SUCCESS
);
export const updateParentDeliverableSuccess = createAction(
  UPDATE_PARENT_DELIVERABLE_SUCCESS
);
export const archiveParentDeliverableSuccess = createAction(
  ARCHIVE_PARENT_DELIVERABLE_SUCCESS
);
export const fetchParentDeliverablesByPersonIdSuccess = createAction(
  FETCH_PARENT_DELIVERABLES_BY_PERSON_ID_SUCCESS
);

export function clearParentDeliverablesForm() {
  return (dispatch, getState) => {
    const batchId = getState().form.parentDeliverablesForm.values.batchId;
    dispatch(reset("parentDeliverablesForm"));
    dispatch(change("parentDeliverablesForm", "batchId", batchId));
  };
}

export function createParentDeliverable(data, history, params) {
  /* eslint-disable prefer-const */
  let { parentDeliverableBriefingFields, keywords, languages, ...rest } = data;
  const { parentDeliverableSourceFields, ...parentDeliverable } = rest;
  /* eslint-enable prefer-const */

  return (dispatch, getState) => {
    const state = getState();
    const { projectId } = params;
    const {
      projects,
      sourceFields: { entities: sourceFields },
    } = state;
    const project = projects.entities[projectId];
    const primaryStageId = primaryStageSelector(state, project.workflowId);
    const deliverables = languages.map((languageCode) => ({
      languageCode,
      currentStageId: primaryStageId,
    }));

    parentDeliverableBriefingFields = parentDeliverableBriefingFields.map(
      (field) => {
        if (removeHTML(field.fieldValue) === "") {
          field.fieldValue = "";
          return field;
        }
        return field;
      }
    );

    const briefingFieldsNotBlank = parentDeliverableBriefingFields.some(
      (field) => field.fieldValue
    ); // checks if all parent deliverable briefing fields are empty
    const keywordFieldsNotBlank = keywords.some((keyword) => keyword.word[0]); // checks if all keyword fields are empty
    const fieldErrors = checkValidations(
      keywordFieldsNotBlank || briefingFieldsNotBlank,
      [
        {
          validate: isNotBlank,
          msg: "All keyword and briefing fields cannot be left blank at the same time.",
        },
      ]
    );

    if (fieldErrors) {
      throw new SubmissionError({ _error: fieldErrors });
    }

    keywords = keywords.reduce((acc, val) => {
      if (val.word.constructor !== Array) return acc;
      val.word.forEach((word) => {
        acc.push({ ...val, word });
      });
      return acc;
    }, []);

    // we add the featureToggles to initialValues to check in validations.js, so we need to remove it here
    delete parentDeliverable.featureToggles;

    const parentDeliverables = [
      {
        parentDeliverable,
        parentDeliverableBriefingFields,
        parentDeliverableSourceFields:
          addRawContentToParentDeliverableSourceFields(
            parentDeliverableSourceFields,
            sourceFields
          ),
        keywords,
        deliverables,
      },
    ];

    const query = `mutation createParentDeliverablesWithDependencies ($input: [ParentDeliverablesInput]) {
      createParentDeliverables (input: $input) {
        parentDeliverables { ${parentDeliverableFields} },
        keywords { keywordId, keywordGroupId, parentDeliverableId, word },
        parentDeliverableBriefingFields { parentDeliverableBriefingFieldId, parentDeliverableId, briefingFieldId, fieldValue },
        parentDeliverableSourceFields { parentDeliverableSourceFieldId, parentDeliverableId, sourceFieldId, fieldValue },
        deliverables { deliverableId, parentDeliverableId, languageCode, currentStageId, allocated }
      }
    }`;

    return postGraphQL(query, { input: parentDeliverables })
      .then((json) => {
        const {
          parentDeliverables,
          keywords,
          parentDeliverableBriefingFields,
          parentDeliverableSourceFields,
        } = json.createParentDeliverables;
        dispatch(createParentDeliverablesSuccess(parentDeliverables));
        dispatch(
          createParentDeliverableBriefingFieldsSuccess(
            parentDeliverableBriefingFields
          )
        );
        dispatch(createKeywordsSuccess(keywords));
        dispatch(
          createParentDeliverableSourceFieldsSuccess(
            parentDeliverableSourceFields
          )
        );
      })
      .catch((err) => {
        console.log(
          "Error creating parentDeliverable",
          err.errors,
          err.message
        );
        if (err.errors) throw new SubmissionError({ _error: err.message });
        throw new SubmissionError({
          _error:
            "There was an error connecting to the server. Please try again later.",
        });
      });
  };
}

export function updateParentDeliverable(data, history, params) {
  const {
    parentDeliverableBriefingFields,
    parentDeliverableSourceFields,
    keywords,
    ...parentDeliverable
  } = data;

  const keywordParams = [];
  keywords.forEach(({ word, ...k }) => {
    if (word.length) {
      keywordParams.push({ ...k, word: word.join(",") });
    }
  });

  if (parentDeliverable.rateBandId === null) {
    delete parentDeliverable.rateBandId;
  }

  return (dispatch, getState) => {
    const state = getState();
    const {
      sourceFields: { entities: sourceFields },
    } = state;

    return postGraphQL(updateParentDeliverablesQuery, {
      parentDeliverable,
      parentDeliverableBriefingFields,
      parentDeliverableSourceFields:
        addRawContentToParentDeliverableSourceFields(
          parentDeliverableSourceFields,
          sourceFields
        ),
      keywords: keywordParams,
    })
      .then((json) => {
        const {
          updateParentDeliverable: {
            parentDeliverable,
            parentDeliverableBriefingFields,
            parentDeliverableSourceFields,
            keywords,
          },
        } = json;

        const oldParentDeliverableBriefingFields =
          parentDeliverableBriefingFieldsByParentDeliverableSelector(
            state,
            parentDeliverable.parentDeliverableId
          );
        const oldKeywords = parentDeliverableKeywordsSelector(
          state,
          parentDeliverable.parentDeliverableId
        );
        const oldParentDeliverableSourceFields =
          parentDeliverableSourceFieldsByParentDeliverableSelector(
            state,
            parentDeliverable.parentDeliverableId
          );
        // remove first so that we don't grab the newly created keywords / parent deliverable briefing fields
        dispatch(
          removeParentDeliverableBriefingFieldsSuccess(
            oldParentDeliverableBriefingFields
          )
        );
        dispatch(removeKeywordsSuccess(oldKeywords));

        dispatch(updateParentDeliverableSuccess(parentDeliverable));
        dispatch(
          createParentDeliverableBriefingFieldsSuccess(
            parentDeliverableBriefingFields
          )
        );
        dispatch(createKeywordsSuccess(keywords));
        dispatch(
          removeParentDeliverableSourceFieldsSuccess(
            oldParentDeliverableSourceFields
          )
        );
        dispatch(
          createParentDeliverableSourceFieldsSuccess(
            parentDeliverableSourceFields
          )
        );
      })
      .catch((err) => {
        console.log(
          "Error updating parentDeliverable",
          err.errors,
          err.message,
          err.stack
        );
        if (err.errors) throw new SubmissionError({ _error: err.message });
        throw new SubmissionError({
          _error:
            "There was an error connecting to the server. Please try again later.",
        });
      });
  };
}

export function archiveParentDeliverable(history, params) {
  const { projectId, parentDeliverableId } = params;

  return (dispatch, getState) => {
    const query = `mutation archiveParentDeliverable($input: ParentDeliverableInput) {
      archiveParentDeliverable(input: $input) {
        parentDeliverableId
      }
    }`;

    return postGraphQL(
      query,
      { input: { parentDeliverableId } },
      "archiveParentDeliverable"
    )
      .then((json) => {
        const url = `/admin/projects/${projectId}/briefing`;
        history.push(url);
        dispatch(archiveParentDeliverableSuccess(parentDeliverableId));
      })
      .catch((err) => {
        console.log(
          "Error archiving parentDeliverable",
          err.errors,
          err.message
        );
        if (err.errors) throw new SubmissionError({ _error: err.message });
        throw new SubmissionError({
          _error:
            "There was an error connecting to the server. Please try again later.",
        });
      });
  };
}

export function moveToSourceAmends(parentDeliverableId) {
  return async (dispatch, getState) => {
    try {
      const { parentDeliverables } = getState();

      // disable buttons while we are processing
      dispatch(startRequest(REQUEST_SOURCE_AMENDS));

      const deliverableLinks = await postGraphQL(
        moveToSourceAmendsQuery,
        { parentDeliverableId },
        "moveToSourceAmends"
      );

      // new links
      const parentDeliverable = {
        ...parentDeliverables.entities[parentDeliverableId],
        deliverableLinks,
      };

      dispatch(updateParentDeliverableSuccess(parentDeliverable));
      dispatch(showSuccessMessage("Source amends query was successful!"));
    } catch (err) {
      const errors = extractErrorFromObject(err);
      dispatch(showWarningMessage(errors));
    } finally {
      // Always re-enable buttons after requests are done / errors handled
      dispatch(endRequest(REQUEST_SOURCE_AMENDS));
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

export const parentDeliverableActionHandlers = {
  [RESET_INITIAL_STATE]: () => parentDeliverableInitialState,
  [FETCH_PARENT_DELIVERABLE_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableId"),
  [FETCH_PARENT_DELIVERABLES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableId"),
  [CREATE_PARENT_DELIVERABLE_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableId"),
  [CREATE_PARENT_DELIVERABLES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableId"),
  [UPDATE_PARENT_DELIVERABLE_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableId"),
  [ARCHIVE_PARENT_DELIVERABLE_SUCCESS]: (state, { payload }) =>
    removeData(state, payload),
  [FETCH_PARENT_DELIVERABLES_BY_PERSON_ID_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableId"),
};

export const parentDeliverableInitialState = { entities: {}, result: [] };
