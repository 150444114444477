import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { isChrome, isServerRender } from "../../../../../utils/dom";
import validate from "./validations";
import LoginButton from "../LoginButton";
import { InputWithErrors } from "../../../../../decorators/WithErrors";
import styles from "./LoginForm.module.scss";

const Form = (props) => {
  if (props.auth0Login) {
    return <LoginButton auth0Login={props.auth0Login} />;
  }
  return (
    <form noValidate onSubmit={props.handleSubmit}>
      <div>
        <div>
          <label className={styles.label}>Email</label>
          <div className={styles.input}>
            <Field component={InputWithErrors} name="email" type="email" />
          </div>

          <label className={styles.label}>Password</label>
          <div className={styles.input}>
            <Field
              component={InputWithErrors}
              name="password"
              type="password"
            />
          </div>
          {props.error && <div>{props.error}</div>}

          <LoginButton
            disabled={!props.valid || props.submitting}
            type="submit"
          />

          {!isServerRender() && !isChrome() && (
            <p className={styles.chromeWarning}>
              Please ensure J+ Scribe is used in Google Chrome for best results.
            </p>
          )}
        </div>
      </div>
    </form>
  );
};

Form.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool,
};

const LoginForm = reduxForm({
  form: "login",
  initialValues: { email: "", password: "" },
  validate,
})(Form);

export default LoginForm;
