import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./WordsChecker.module.scss";

class WordList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllWords: false,
    };
  }

  showAllWords = () => {
    this.setState({ showAllWords: !this.state.showAllWords });
  };

  render() {
    const { showAllWords } = this.state;
    const {
      highlightClass,
      emptyMessage,
      noUsedMessage,
      wordsArray,
    } = this.props;

    const usedWords = wordsArray
      .filter((i) => i.hasWord)
      .map(({ word }) => word);
    const unUsedWords = wordsArray
      .filter((i) => !i.hasWord)
      .map(({ word }) => word);

    return (
      <div>
        {usedWords.length === 0 && unUsedWords.length > 0 && !showAllWords && (
          <span className={styles.requiredWords}>{noUsedMessage}</span>
        )}

        <span className={styles[highlightClass]}>{usedWords.join(", ")}</span>

        {showAllWords && usedWords.length > 0 && unUsedWords.length > 0 && (
          <span>, </span>
        )}

        {showAllWords && (
          <span className={styles.missingWord}>{unUsedWords.join(", ")}</span>
        )}

        {unUsedWords.length || usedWords.length > 0 ? (
          <div className={styles.showAll} onClick={this.showAllWords}>
            {!showAllWords ? "Show all..." : "Show less"}
          </div>
        ) : (
          <span className={styles.requiredWords}>{emptyMessage}</span>
        )}
      </div>
    );
  }
}

WordList.propTypes = {
  wordsArray: PropTypes.arrayOf(
    PropTypes.shape({
      word: PropTypes.string,
      hasWord: PropTypes.bool.isRequired,
    })
  ),
  highlightClass: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  noUsedMessage: PropTypes.string.isRequired,
};

export default WordList;
