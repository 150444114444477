import { getGraphQL, postGraphQL } from "../../utils/fetch";
import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";

export const hiddenFields = [
  "hiddenId",
  "parentAccountId",
  "accountId",
  "orderFormId",
  "accountId",
];

export const hiddenQuery = `query hiddenQuery {
  hidden {
    hiddenId, parentAccountId, accountId, orderFormId, projectId
  } 
}`;

const HIDDEN_SUCCESS = "HIDDEN_SUCCESS";
export const fetchHiddenSuccess = createAction(HIDDEN_SUCCESS);

export const hiddenActionHandlers = {
  [RESET_INITIAL_STATE]: () => hiddenInitialState,
  [HIDDEN_SUCCESS]: (state, { payload }) => {
    return upsertData(hiddenInitialState, payload, "hiddenId");
  },
};

export const hiddenInitialState = { entities: {}, result: [] };

export function restore(object) {
  return async (dispatch, getState) => {
    const query = `mutation restore ($input: HiddenInput){
      restore (input: $input) {
        parentAccountId
      }
    }`;
    try {
      await postGraphQL(query, { input: object }, "restore");
      const { hidden } = await getGraphQL(hiddenQuery, {});
      dispatch(fetchHiddenSuccess(hidden));
    } catch (err) {}
  };
}

export function hide(object) {
  console.log("hide ~ object", object);
  return async (dispatch, getState) => {
    const query = `mutation hide ($input: HiddenInput){
      hide (input: $input) {
        parentAccountId
      }
    }`;
    try {
      await postGraphQL(query, { input: object }, "hide");
      const { hidden } = await getGraphQL(hiddenQuery, {});
      dispatch(fetchHiddenSuccess(hidden));
    } catch (err) {}
  };
}
