import { connect } from "react-redux";
import {
  languagesDropdownSelector,
  accountsDropDownSelector,
} from "../../../../../utils/entitySelector";

/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

import PeopleNew from "../components/PeopleNew";
import { searchAccounts } from "../../../../../modules/accounts";
/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

import { createPerson } from "../../../../../modules/people";

const mapDispatchToProps = (dispatch, ownProps) => ({
  createPerson: (data) => dispatch(createPerson(data, ownProps.history)),
  searchAccounts: (input) => dispatch(searchAccounts(input)),
});

const mapStateToProps = (state, ownProps) => {
  const languages = languagesDropdownSelector(state);
  const accounts = accountsDropDownSelector(state);

  return {
    languages,
    accounts,
  };
};

const PeopleNewWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleNew);
export default PeopleNewWithData;
