import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../components/PageHeader";
import OverlayLoading from "../../../../../../components/OverlayLoading/v1";
import styles from "./ExportForm.module.scss";

import ExportFormatForm from "./ExportFormatForm";

class ExportFormat extends Component {
  render() {
    const {
      account: { accountName },
      dataReady,
      isLocalisationProject,
      history,
      orderForm: { orderFormNumber },
      project: { projectId, projectName },
      parentAccount: { parentAccountName },
      taskFields,
      briefingFieldList,
    } = this.props;

    const baseUrl = `/admin/projects/${projectId}`;

    if (!dataReady) return <OverlayLoading />;

    const onSubmit = (values) => {
      let docExportName = null;
      let docExportTaskFieldId = null;
      let docExportBriefingFieldId = null;

      if (values.exportNameConfiguration === "DELIVERABLE_ID") {
        docExportName = "DELIVERABLE_ID";
      } else {
        const tf = taskFields.find(
          (tf) => tf.taskFieldId === values.exportNameConfiguration
        );

        if (tf) {
          docExportName = "TASK_FIELD_ID";
          docExportTaskFieldId = tf.taskFieldId;
        } else {
          const bf = briefingFieldList.find(
            (bf) => bf.briefingFieldId === values.exportNameConfiguration
          );

          if (bf) {
            docExportName = "BRIEFING_FIELD_ID";
            docExportBriefingFieldId = bf.briefingFieldId;
          }
        }
      }

      values.docExportName = docExportName;
      values.docExportBriefingFieldId = docExportBriefingFieldId;
      values.docExportTaskFieldId = docExportTaskFieldId;

      this.props.updateExportFormat(
        {
          projectId,
          ...values,
        },
        history
      );
    };

    return (
      <div>
        <Helmet>
          <title>Export format</title>
        </Helmet>

        <PageHeader
          breadCrumbItems={[
            { url: `/admin`, text: "Parent accounts" },
            {
              text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
            },
            { url: `${baseUrl}`, text: projectName },
            { text: "Export format" },
          ]}
          title={`${projectName} - Export format`}
        />

        <div className={styles.filterContainer}>
          <ExportFormatForm
            initialValues={this.props.initialValues}
            isLocalisationProject={isLocalisationProject}
            onSubmit={onSubmit}
            projectId={projectId}
            taskFields={taskFields}
            briefingFieldList={briefingFieldList}
          />
        </div>
      </div>
    );
  }
}

ExportFormat.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  dataReady: PropTypes.bool,
  initialValues: PropTypes.object,
  isLocalisationProject: PropTypes.bool,
  messages: PropTypes.object,
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number,
  }),
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string,
  }),
  project: PropTypes.shape({
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  }),
  updateExportFormat: PropTypes.func,
  taskFields: PropTypes.arrayOf(
    PropTypes.shape({
      taskFieldId: PropTypes.number,
      taskFieldName: PropTypes.string,
    })
  ),
  briefingFieldList: PropTypes.arrayOf(
    PropTypes.shape({
      briefingFieldId: PropTypes.number,
      briefingFieldName: PropTypes.string,
    })
  ),
};

export default ExportFormat;
