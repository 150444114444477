import { createAction } from "redux-actions";
import { upsertData, removeData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";

export const TaskFormat = Object.freeze({
  HTML: "HTML",
  Text: "Text",
  Image: "Image",
  TM: "HTML (Translation memory)",
  TMBullets: "TM (bullets)",
  TMProse: "TM (prose)",
});

export const isTaskFormatTMBullets = (format) =>
  format === TaskFormat.TM || format === TaskFormat.TMBullets;

export const isTaskFormatTMProse = (format) => format === TaskFormat.TMProse;

export const isTaskFormatTM = (format) =>
  isTaskFormatTMBullets(format) || isTaskFormatTMProse(format);

export const isTaskFormatHTML = (format) =>
  format === TaskFormat.HTML || isTaskFormatTM(format);

// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const taskFieldsByProjectQuery = `taskFields (projectId: $projectId) {
    taskFieldId, projectId, taskFieldName, taskFieldFormat,
    taskFieldPosition, qualityCheck, minWords, maxWords,
    minCharacters, maxCharacters, aiContent
  }`;

export const taskFieldsByAssignmentGroupQuery = `taskFields (assignmentGroupId: $assignmentGroupId) {
    taskFieldId, projectId, taskFieldName, taskFieldFormat,
    taskFieldPosition, qualityCheck, minWords, maxWords,
    minCharacters, maxCharacters, aiContent
  }`;
export const taskFieldsByAssignmentQuery = `taskFields (assignmentId: $assignmentId) {
    taskFieldId, projectId, taskFieldName, taskFieldFormat,
    taskFieldPosition, qualityCheck, minWords, maxWords,
    minCharacters, maxCharacters, aiContent
  }`;

export const taskFieldsByDeliverableIdsQuery = `taskFields (deliverableIds: $deliverableId) {
  taskFieldId, projectId, taskFieldName, taskFieldFormat,
  taskFieldPosition, qualityCheck, minWords, maxWords,
  minCharacters, maxCharacters, aiContent
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_TASK_FIELDS_SUCCESS = "FETCH_TASK_FIELDS_SUCCESS";
export const UPDATE_TASK_FIELDS_SUCCESS = "UPDATE_TASK_FIELDS_SUCCESS";
export const REMOVE_TASK_FIELDS_SUCCESS = "REMOVE_TASK_FIELDS_SUCCESS";
export const CREATE_TASK_FIELDS_SUCCESS = "CREATE_TASK_FIELDS_SUCCESS";
// ------------------------------------
// Actions
// ------------------------------------
export const fetchTaskFieldsSuccess = createAction(FETCH_TASK_FIELDS_SUCCESS);
export const updateTaskFieldsSuccess = createAction(UPDATE_TASK_FIELDS_SUCCESS);
export const removeTaskFieldsSuccess = createAction(REMOVE_TASK_FIELDS_SUCCESS);
export const createTaskFieldsSuccess = createAction(CREATE_TASK_FIELDS_SUCCESS);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const taskFieldActionHandlers = {
  [RESET_INITIAL_STATE]: () => taskFieldInitialState,
  [FETCH_TASK_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "taskFieldId"),
  [CREATE_TASK_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "taskFieldId"),
  [UPDATE_TASK_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "taskFieldId"),
  [REMOVE_TASK_FIELDS_SUCCESS]: (state, { payload }) => {
    return payload.reduce(
      (acc, { taskFieldId }) => removeData(acc, taskFieldId),
      state
    );
  },
};

export const taskFieldInitialState = { entities: {}, result: [] };
