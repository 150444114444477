import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./SearchInput.module.scss";
import { getLoggedInUserType } from "../../../utils/entitySelector";
import { PersonType } from "../../../modules/people";
import parseQuery, { handleQuery } from "../../../utils/parseQuery";
import qs from "query-string";

const SearchInput = ({ placeholder = "" }) => {
  const history = useHistory();
  const location = useLocation();
  const personType = useSelector(getLoggedInUserType);

  const [inputText, setInputText] = useState(() => {
    const queryParams = parseQuery(location.search);
    return queryParams.searchTerm;
  });

  const onChange = (event) => {
    const searchValue = event.target.value;
    setInputText(searchValue);
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13 && inputText) {
      event.preventDefault();

      const queryParams = parseQuery(location.search);
      const updatedQueryParams = handleQuery(
        queryParams,
        "searchTerm",
        inputText
      );
      const updatedQueryString = qs.stringify(updatedQueryParams);

      const searchUrl =
        personType === PersonType.Client
          ? `/clients/search?${updatedQueryString}`
          : `/admin/search?${updatedQueryString}`;

      history.push(searchUrl);
    }
  };

  return (
    <div className={styles.searchContainer}>
      <input
        className={styles.searchInput}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        value={inputText}
      />
      <svg
        className={styles.searchIcon}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        {/* eslint-disable max-len */}
        <path d="M15.504 13.616l-3.79-3.223c-0.392-0.353-0.811-0.514-1.149-0.499 0.895-1.048 1.435-2.407 1.435-3.893 0-3.314-2.686-6-6-6s-6 2.686-6 6 2.686 6 6 6c1.486 0 2.845-0.54 3.893-1.435-0.016 0.338 0.146 0.757 0.499 1.149l3.223 3.79c0.552 0.613 1.453 0.665 2.003 0.115s0.498-1.452-0.115-2.003zM6 10c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
        {/* eslint-enable max-len */}
      </svg>
    </div>
  );
};

export default SearchInput;
