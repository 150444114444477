import React, { Component } from "react";
import PropTypes from "prop-types";
import PageHeader from "../../../../components/PageHeader";
import NestedAccountRow from "../../../../components/NestedAccountRow";
import styles from "./ClientAccounts.module.scss";
import FeatureToggle from "../../../../components/FeatureToggle";
import SearchInput from "../../../../components/Search/SearchInput";

class ClientAccounts extends Component {
  render() {
    return (
      <div>
        <PageHeader title={"Accounts"}>
          <FeatureToggle toggle="clientSearch">
            <div className={styles.headerRight}>
              <SearchInput placeholder="search briefing and source fields" />
            </div>
          </FeatureToggle>
        </PageHeader>

        <div className={styles.container}>
          {this.props.accounts.map((account) => (
            <NestedAccountRow
              key={account.accountId}
              account={account}
              projects={this.props.projects}
              orderForms={this.props.orderForms}
            />
          ))}
        </div>
      </div>
    );
  }
}

ClientAccounts.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountName: PropTypes.string,
      accountId: PropTypes.number,
    })
  ).isRequired,
  projects: PropTypes.object.isRequired,
  orderForms: PropTypes.object.isRequired,
};

export default ClientAccounts;
