import React from "react";
import PropTypes from "prop-types";
import styles from "./CharacterCount.module.scss";

function CharacterCount({ count }) {
  return (
    <div className={styles.characterCount}>
      <span className={styles.label}>Characters: </span>
      <span className={styles.count}>{count}</span>
    </div>
  );
}

CharacterCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default CharacterCount;
