import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";
import {
  fetchParentDeliverablesSuccess,
  parentDeliverablesByBatchQuery,
} from "./parentDeliverables";
// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const deliverableFields = [
  "deliverableId",
  "parentDeliverableId",
  "languageCode",
  "currentStageId",
  "allocated",
  "createDate",
];

export const deliverablesByProjectQuery = `deliverables (projectId: $projectId) {
  ${deliverableFields}
}`;

export const deliverablesByBatchQuery = `deliverables (batchId: $batchId) {
  ${deliverableFields}
}`;

export const deliverablesByBatchStageLangQuery = `deliverables (batchId: $batchId, stageId: $stageId, languageCode: $languageCode) {
  ${deliverableFields}
}`;

export const deliverablesByAssignmentGroupQuery = `deliverables (
  assignmentGroupId: $assignmentGroupId, includeArchivedAssignments: $includeArchivedAssignments) {
    ${deliverableFields}
}`;

export const deliverablesByAssignmentQuery = `deliverables (assignmentId: $assignmentId) {
  ${deliverableFields}
}`;

export const deliverablesByDeliverableIdQuery = `deliverables (deliverableIds: $deliverableId) {
  ${deliverableFields}
}`;

export const deliverableInfoByDeliverableIdQuery = `deliverable (deliverableId: $deliverableId, includeAssignments: true) {
  ${deliverableFields}, 
  assignments { assignee, deadline, stageId, stageName }
}`;

const adjacentIdsFields = `batchGroupDeliverableIds, nextIdInBatch, prevIdInBatch, transitionIds`;

const plagiarismFields = `plagiarismTasks {
  taskFieldId,
  status,
  result {
    avgPercentage,
    results {
      url,
      percentage
    }
  }
}`;

export const deliverableWithAdjacentIds = `deliverable (deliverableId: $deliverableId, includeAssignments: false) {
  ${deliverableFields},
  ${adjacentIdsFields},
  ${plagiarismFields}
}`;

const deliverablesByIdsQuery = `query ($deliverableId: String) {
  ${deliverablesByDeliverableIdQuery}
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_DELIVERABLES_SUCCESS = "FETCH_DELIVERABLES_SUCCESS";
export const UPDATE_DELIVERABLE_SUCCESS = "UPDATE_DELIVERABLE_SUCCESS";
export const UPDATE_DELIVERABLES_SUCCESS = "UPDATE_DELIVERABLES_SUCCESS";
export const FETCH_DELIVERABLES_BY_PERSON_ID_SUCCESS =
  "FETCH_DELIVERABLES_SUCCESS";
export const CREATE_DELIVERABLES_SUCCESS = "CREATE_DELIVERABLES_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchDeliverablesSuccess = createAction(
  FETCH_DELIVERABLES_SUCCESS
);
export const updateDeliverableSuccess = createAction(
  UPDATE_DELIVERABLE_SUCCESS
);
export const updateDeliverablesSuccess = createAction(
  UPDATE_DELIVERABLES_SUCCESS
);
export const createDeliverablesSuccess = createAction(
  CREATE_DELIVERABLES_SUCCESS
);

const WORKFLOW_REVERT_RESPONSE_KEY = "runWorkflowRevert";

const workflowRevertQuery = `mutation runWorkflowRevert ($deliverableIds: [Int], $stageId: Int){
  runWorkflowRevert (deliverableIds: $deliverableIds, stageId: $stageId){
    deliverableIds
  }
}`;

export function runWorkflowRevert(request) {
  return async (dispatch) => {
    const { deliverableIds } = await postGraphQL(
      workflowRevertQuery,
      request,
      WORKFLOW_REVERT_RESPONSE_KEY
    );

    if (deliverableIds.length !== 0) {
      // refetch  changed deliverables to update the UI
      const params = { deliverableId: deliverableIds.join(",") };
      const { deliverables } = await getGraphQL(deliverablesByIdsQuery, params);

      await dispatch(fetchDeliverablesSuccess(deliverables));
    }

    return deliverableIds;
  };
}

export function fetchDeliverablesByBatchId(batchId) {
  return async (dispatch) => {
    try {
      const params = { batchId };
      const query = `query fetchParentDeliverablesAndBriefingFieldsByBatchId ($batchId: String) {
        ${deliverablesByBatchQuery}
        ${parentDeliverablesByBatchQuery}
      }`;

      const { deliverables, parentDeliverables } = await getGraphQL(
        query,
        params
      );

      dispatch(fetchDeliverablesSuccess(deliverables));
      dispatch(fetchParentDeliverablesSuccess(parentDeliverables));
    } catch (err) {
      console.log(err);
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

export const deliverableActionHandlers = {
  [RESET_INITIAL_STATE]: () => deliverableInitialState,
  [FETCH_DELIVERABLES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "deliverableId"),
  [CREATE_DELIVERABLES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "deliverableId"),
  [UPDATE_DELIVERABLE_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "deliverableId"),
  [UPDATE_DELIVERABLES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "deliverableId"),
};

export const deliverableInitialState = { entities: {}, result: [] };
