import { connect } from "react-redux";
import BulkQA from "../components/BulkQA";
import WithData from "../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import {
  assignmentsByGroupSelector,
  assignmentById,
  projectTaskFieldsSelector,
  getLoggedInUser,
} from "../../../utils/entitySelector";
import queryString from "query-string";
import { PersonType } from "../../../modules/people";
import { getLatestContentByDeliverableId } from "../../../modules/taskFieldContent";
import { getAssignmentStatus } from "../../../modules/assignments";
import { createSelector } from "reselect";

const grammarCheckSelector = createSelector(
  (state) => state.webSpellChecker.entities,
  (corrections) => {
    return Object.values(corrections);
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    stage: { stageId },
  } = ownProps;
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
    fetchDeliverableContent: (deliverableId) =>
      dispatch(getLatestContentByDeliverableId(deliverableId, stageId)),
  };
};

const mapStateToProps = (state, { match: { params }, location, project }) => {
  const {
    featureToggles: { adminGrammarCheck, freelancerGrammarCheck },
  } = state;
  const { personType } = getLoggedInUser(state);
  const allowGrammarCheck =
    (personType === PersonType.Admin && adminGrammarCheck) ||
    (personType === PersonType.Freelancer && freelancerGrammarCheck);
  const { assignmentId } = queryString.parse(location.search);
  const assignment = assignmentById(state, assignmentId);
  const { isActionable, isCommentable, isEditable } = getAssignmentStatus(
    state,
    assignment
  );
  const taskFields = projectTaskFieldsSelector(
    state,
    Number(project.projectId)
  );

  const assignments = assignmentsByGroupSelector(
    state,
    Number(params.assignmentGroupId)
  );

  const grammarCheck = grammarCheckSelector(state);

  const [taskField = {}] = taskFields;
  const { taskFieldId } = taskField;

  return {
    allowGrammarCheck,
    isCommentable,
    isEditable,
    isActionable,
    assignments,
    assignment,
    taskFields,
    taskFieldId,
    assignmentId,
    grammarCheck,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithData(BulkQA));
