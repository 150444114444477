import { connect } from "react-redux";
import TransitionHistory from "../TransitionHistory.js";
import { showModal, hideModal } from "../../../../../../../modules/modal";
import { transitionHistoryByDeliverableId } from "../../../../../../../utils/entitySelector";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../../../decorators/WithData";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
    getInitialData: () => dispatch(getInitialData(ownProps)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const transitionHistory = transitionHistoryByDeliverableId(
    state,
    ownProps.deliverableId
  );

  return {
    transitionHistory,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(TransitionHistory));
