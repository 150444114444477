import { connect } from "react-redux";

import {
  projectLanguagesSelector,
  projectRatesSelector,
  chargeableProjectStagesSelector,
} from "../../../../../../../utils/entitySelector";
import { createSelector } from "reselect";
import Rates from "../components/Rates";
import WithData from "../../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";

import { updateRates } from "../../../../../../../modules/rates";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    updateRates: (data) =>
      dispatch(
        updateRates(
          data,
          ownProps.history,
          ownProps.match.params,
          ownProps.location
        )
      ),
  };
};

/**
 @description - we store our data like so:
  {
    en-gb: {
      stage_id: 15,
      ...
    }
  }
 where stage_id is replaced with the actual stage_ids
 Hence the initial value selector has to recreate this structure
*/
const initialValuesSelector = createSelector(
  projectLanguagesSelector,
  projectRatesSelector,
  (state, projectId, stages) => stages,
  (projectLanguages, projectRates, stages) => {
    const tree = {};
    const defaultRate = 10;
    projectLanguages.forEach(({ languageCode }) => {
      if (!tree.hasOwnProperty(languageCode)) tree[languageCode] = {};

      stages.forEach((stage) => {
        if (!tree[languageCode].hasOwnProperty(stage.stageId)) {
          tree[languageCode][stage.stageId] = defaultRate;
        }
      });
    });

    projectRates.forEach((rate) => {
      if (!tree.hasOwnProperty(rate.languageCode)) tree[rate.languageCode] = {};
      tree[rate.languageCode][rate.stageId] = rate.amount;
    });

    return tree;
  }
);

const mapStateToProps = (state, ownProps) => {
  const projectCreation = ownProps.location.state
    ? ownProps.location.state.projectCreation
    : false;
  const projectId = Number(ownProps.match.params.projectId);

  const project = state.projects.entities[projectId];
  const stages = chargeableProjectStagesSelector(state, project.workflowId);
  const projectLanguages = projectLanguagesSelector(state, projectId);
  const initialValues = initialValuesSelector(state, Number(projectId), stages);

  const orderForm = state.orderForms.entities[project.orderFormId];
  const account = state.accounts.entities[orderForm.accountId];
  const parentAccount = state.parentAccounts.entities[account.parentAccountId];

  return {
    account,
    orderForm,
    parentAccount,
    projectCreation,
    projectLanguages,
    project,
    stages,
    initialValues,
  };
};

const ProjectRates = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Rates));
ProjectRates.getInitialData = getInitialData;
export default ProjectRates;
