import React from "react";
import PropTypes from "prop-types";
import styles from "./NestedOrderFormRow.module.scss";
import NestedRow from "../../../components/NestedAccountRow/NestedRow";
import NestedAdminRow from "../../../components/NestedAccountRow/NestedAdminRow";
import Collapsible from "../../../decorators/Collapsible";

import NestedProjectRow from "./NestedProjectRow";

const NestedOrderFormRow = (props) => {
  const Row = props.admin ? NestedAdminRow : NestedRow;
  const orderFormContainerStyles = props.admin
    ? styles.adminOrderFormContainer
    : styles.clientOrderFormContainer;
  const orderFormRowStyles = props.admin
    ? styles.adminOrderFormRow
    : styles.clientOrderFormRow;

  return (
    <div className={orderFormContainerStyles}>
      <Row
        hide={props.hide}
        restore={props.restore}
        restoreEnabled={props.restoreEnabled}
        favouritesEnabled={props.favouritesEnabled}
        archivedComponent={props.archivedComponent}
        handleRowClick={props.toggle}
        handleCreateOrEdit={props.handleCreateOrEdit}
        handleDelete={props.handleDelete}
        name={props.orderForm.orderFormName}
        modalId={props.orderForm.orderFormId}
        orderFormId={props.orderForm.orderFormId}
        modalType="orderForm"
        modalTitle="order form"
        childTitle="project"
        childType="project"
        customStyles={orderFormRowStyles}
        createUrl={`/admin/order-forms/${props.orderForm.orderFormId}/projects/new`}
        isFavourite={props.isFavourite}
        favourite={props.favourite}
        unfavourite={props.unfavourite}
        favouriteComponent={props.favouriteComponent}
      />

      {props.open &&
        (props.projects[props.orderForm.orderFormId] || []).map((project) => {
          const projectUrl = props.admin
            ? `/admin/projects/${project.projectId}`
            : `/clients/projects/${project.projectId}/overview`;
          return (
            <NestedProjectRow
              hide={props.hide}
              restore={props.restore}
              archivedComponent={props.archivedComponent}
              restoreEnabled={
                props.projectIds && props.projectIds.includes(project.projectId)
              }
              favouritesEnabled={
                props.projectIds && props.projectIds.includes(project.projectId)
              }
              admin={props.admin}
              key={project.projectId}
              projectUrl={projectUrl}
              project={project}
              isFavourite={project.isFavourite}
              favourite={props.favourite}
              unfavourite={props.unfavourite}
              favouriteComponent={props.favouriteComponent}
            />
          );
        })}
    </div>
  );
};

NestedOrderFormRow.propTypes = {
  admin: PropTypes.bool,
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number,
    orderFormName: PropTypes.string,
    orderFormId: PropTypes.number,
  }),
  projects: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleCreateOrEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default Collapsible(NestedOrderFormRow);
