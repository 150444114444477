import { getGraphQL } from "../../../../../../../../../../../utils/fetch";

import {
  batchByIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../../../modules/batches";

export const initialQuery = `query batchByIdQuery ($batchId: String) {
  ${batchByIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);
    dispatch(fetchBatchesSuccess(json.batch));
  };
}
