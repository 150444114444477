import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../modules/projects";
import {
  defaultAssigneesByProjectIdQuery,
  fetchDefaultAssigneesSuccess,
} from "../../../../../../modules/defaultAssignees";
import {
  peopleByDefaultAssigneeQuery,
  fetchPeopleSuccess,
} from "../../../../../../modules/people";
import {
  rateBandsByProjectIdQuery,
  fetchRateBandsSuccess,
} from "../../../../../../modules/rateBands";

import {
  quotedRatesByProjectIdQuery,
  fetchQuotedRatesSuccess,
} from "../../../../../../modules/quotedRates";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../modules/orderForms";

export const initialQuery = `query projectsQuotes_getInitialData ($projectId: String){
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${projectQuery}
  ${defaultAssigneesByProjectIdQuery}
  ${peopleByDefaultAssigneeQuery}
  ${rateBandsByProjectIdQuery}
  ${quotedRatesByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const {
      accounts,
      defaultAssignees,
      orderForms,
      parentAccounts,
      peopleByDefaultAssignee,
      project,
      quotedRates,
      rateBands,
    } = await getGraphQL(initialQuery, params, url, cookies);

    dispatch(fetchParentAccountsSuccess(parentAccounts));
    dispatch(fetchAccountsSuccess(accounts));
    dispatch(fetchOrderFormsSuccess(orderForms));
    dispatch(fetchProjectSuccess(project));
    dispatch(fetchDefaultAssigneesSuccess(defaultAssignees));
    dispatch(fetchPeopleSuccess(peopleByDefaultAssignee));
    dispatch(fetchRateBandsSuccess(rateBands));
    dispatch(fetchQuotedRatesSuccess(quotedRates));
  };
}
