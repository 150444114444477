import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../components/PageHeader";
import PaymentsEditForm from "./PaymentsEditForm";
import styles from "./PaymentsEdit.module.scss";
import { PAYMENT_TYPE_AD_HOC_DRAFT } from "../../../../../../modules/payments";

const PaymentsEdit = class PaymentsEdit extends Component {
  render() {
    const { payment, updatePayment, history } = this.props;
    const { paymentId, paymentComplete } = payment || {};

    const baseUrl = `/admin/payments/${payment ? paymentId : ""}`;
    if (paymentComplete) {
      return history.push(baseUrl);
    }

    const isAdHocDraft = payment.paymentType === PAYMENT_TYPE_AD_HOC_DRAFT;

    const updatePaymentFn = async (data) => {
      updatePayment(data, isAdHocDraft ? baseUrl : null);
    };

    return (
      <div>
        <Helmet>
          <title>Payment info</title>
        </Helmet>

        <PageHeader
          title={"Edit payment"}
          breadCrumbItems={[{ url: baseUrl, text: "Payment info" }]}
        />

        {payment ? (
          <div className={styles.paymentFormContainer}>
            <PaymentsEditForm
              {...this.props}
              baseUrl={baseUrl}
              disabled={paymentComplete}
              onSubmit={updatePaymentFn}
            />
          </div>
        ) : (
          <div className={styles.notice}>No payment available</div>
        )}
      </div>
    );
  }
};

PaymentsEdit.propTypes = {
  payment: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    task: PropTypes.string,
    stageName: PropTypes.string,
    createDate: PropTypes.string.isRequired,
    assignmentId: PropTypes.number,
    batchName: PropTypes.string,
    rate: PropTypes.number,
    paymentType: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  payee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  orderForm: PropTypes.shape({
    orderFormName: PropTypes.string,
  }),
  error: PropTypes.string,
  updatePayment: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default PaymentsEdit;
