import React, { useState } from "react";
import { RichUtils, EditorState, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Toolbar from "../../DraftJS/Toolbar";
import linkPlugin from "../../AssignmentEdit/Tasks/_plugins/LinkEditor/LinkDecorator";

function getRawContentFromHTML(html) {
  if (!html || html.length === 0) return EditorState.createEmpty();

  try {
    const contentState = stateFromHTML(html);
    return EditorState.createWithContent(contentState);
  } catch (e) {
    return EditorState.createEmpty();
  }
}

function getInitialEditorState({ text, rawContent }) {
  try {
    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  } catch (_err) {}

  // plaintext
  const trimmed = text.replace(/<p><br>\n<\/p>/g, "<p><br></p>");
  const editorState = getRawContentFromHTML(trimmed);
  return editorState;
}

const SimpleEditor = ({ input, text, rawContent }) => {
  const [editorState, setEditorState] = useState(() => {
    return getInitialEditorState({ text, rawContent });
  });

  const [decorators] = useState(() => {
    return [linkPlugin];
  });

  const updateEditorState = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const html = stateToHTML(contentState);

    input.onChange(html);
  };

  /**
   * SimpleEditor is only used for HTML briefing/task fields so all Rich text
   * commands are good
   */
  const handleKeyCommandFunc = (command, newEditorState) => {
    const editorState = RichUtils.handleKeyCommand(newEditorState, command);

    if (editorState) {
      setEditorState(editorState);
      return "handled";
    }

    return "not-handled";
  };

  return (
    <div>
      <Toolbar
        editorState={editorState}
        setEditorState={updateEditorState}
        showLinkButton
      />
      {editorState && (
        <Editor
          editorState={editorState}
          onChange={updateEditorState}
          handleKeyCommand={handleKeyCommandFunc}
          spellCheck={false}
          decorators={decorators}
        />
      )}
    </div>
  );
};

export default SimpleEditor;
