import React from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "./TextArea";
import debounce from "lodash/debounce";
const isIE = !!window.MSInputMethodContext && !!document.documentMode;

export default class InputCell extends React.Component {
  static defaultProps = {
    render: () => {},
    onShowEditOptions: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      showEditOptions: false,
    };
    this.updateEditorStateDebounced = debounce(this.updateEditorState, 500);
  }
  onChange = (e) => {
    const newValue = e.target.value;
    this.setState(
      {
        value: newValue,
      },
      () => {
        // Call the debounced editor state update after state has been set
        this.updateEditorStateDebounced();
      }
    );
  };

  updateEditorState = () => {
    const {
      row,
      cell,
      th,
      // columns,
      column,
      blockProps: {
        editCell,
        editColumn,
        getEditorState,
        setEditorState,
        columns,
      },
    } = this.props;

    const { value } = this.state;

    if (th) {
      setEditorState(
        editColumn({
          editorState: getEditorState(),
          columns,
          column: { ...column, value },
          block: this.props.block,
        })
      );
    } else {
      setEditorState(
        editCell({
          row,
          cell: { ...cell, value },
          editorState: getEditorState(),
          block: this.props.block,
        })
      );
    }
  };

  onFocus = () => {
    this.props.onToggleReadOnly(true);
    this.props.onShowEditOptions(true);
    this.setState({
      ...this.state,
      showEditOptions: true,
    });
  };
  onBlur = () => {
    this.props.onToggleReadOnly(false);
    this.props.onShowEditOptions(false);
    this.setState({
      ...this.state,
      showEditOptions: false,
    });
    this.props.onChange(this.state.value);
  };
  render() {
    if (isIE) {
      return (
        <div className={this.props.theme.cellWrapper}>
          <texarea
            disabled={this.props.notEditable}
            className={this.props.textAreaStyle}
            style={{ resize: "none" }}
            type="text"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={this.state.value}
            onChange={this.onChange}
          />
          {this.props.render(this.state)}
        </div>
      );
    }
    return (
      <div className={this.props.theme.cellWrapper}>
        <TextareaAutosize
          disabled={this.props.notEditable}
          useCacheForDOMMeasurements
          className={this.props.textAreaStyle}
          style={{ resize: "none", width: "100%" }}
          type="text"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={this.state.value}
          onChange={this.onChange}
        />
        {this.props.render(this.state)}
      </div>
    );
  }
}

InputCell.propTypes = {
  theme: PropTypes.shape({
    cellWrapper: PropTypes.string,
  }),
  notEditable: PropTypes.bool,
  textAreaStyle: PropTypes.string,
  render: PropTypes.func,
  onToggleReadOnly: PropTypes.func,
  onShowEditOptions: PropTypes.func,
};
