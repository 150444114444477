import ClientBatchStage from "../../../../../../../../../components/ClientBatchStage";
import WithData from "../../../../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import parseQuery from "../../../../../../../../../utils/parseQuery";
import {
  batchById,
  batchParentDeliverableEntitiesSelector,
  batchDeliverablesSelector,
  priorAndNextTransitionsSelector,
  projectBriefingFieldsSelector,
  projectById,
  stageById,
} from "../../../../../../../../../utils/entitySelector";
import { createTransitionLogsFromDeliverables } from "../../../../../../../../../modules/transitionLogs";
import { BriefingFieldFormat } from "../../../../../../../../../modules/briefingFields";
import { unresolvedCommentGroupsSelector } from "../../../../../../../../../modules/commentGroups";
import { StageType } from "../../../../../../../../../modules/stages";

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match,
    location: { search },
  } = ownProps;
  const parsedSearch = parseQuery(search);
  const { language: languageCode } = parsedSearch;

  const params = {
    ...match.params,
    languageCode,
  };

  return {
    getInitialData: () => dispatch(getInitialData(params)),
    createTransitionLogsFromDeliverables: (transition, selectedItems) =>
      dispatch(createTransitionLogsFromDeliverables(transition, selectedItems)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { batchId, stageId, projectId } = ownProps.match.params;
  const parsedSearch = parseQuery(ownProps.location.search);
  const selectedLanguage = parsedSearch.language;

  const stage = stageById(state, stageId);
  const { batchName } = batchById(state, batchId);
  const project = projectById(state, projectId);
  const { editableClientStages } = project;
  const allowClientChanges = editableClientStages === "CLIENT_REVIEW";

  // for clients, amends stage type has no transition
  const transitions =
    stage.stageType === StageType.Amends
      ? []
      : priorAndNextTransitionsSelector(
          state,
          Number(stageId),
          null,
          null,
          null,
          false
        );

  const batchParentDeliverableEntities = batchParentDeliverableEntitiesSelector(
    state,
    Number(batchId)
  );

  const briefingFields = projectBriefingFieldsSelector(
    state,
    Number(projectId)
  ).filter(
    (bf) => bf.briefingFieldFormat !== BriefingFieldFormat["AI attribute"]
  );

  const deliverables = batchDeliverablesSelector(
    state,
    batchParentDeliverableEntities,
    briefingFields,
    stage,
    selectedLanguage
  );

  const { accountName, orderFormName } = project;

  const unresolvedCommentGroups = unresolvedCommentGroupsSelector(
    state.commentGroups
  );

  const shouldShowLanguageMismatchModal =
    state.personNativeLanguages?.length > 0
      ? !state.personNativeLanguages.some(
          (language) => language.nativeLanguage === selectedLanguage
        )
      : false;

  return {
    accountName,
    allowClientChanges,
    baseUrlRoot: "clients",
    batchName,
    briefingFields,
    deliverables,
    me: state.me,
    orderFormName,
    project,
    stageName: stage.stageName,
    transitions,
    unresolvedCommentGroups,
    shouldShowLanguageMismatchModal,
  };
};

const ClientBatchStageWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ClientBatchStage));
export default ClientBatchStageWithData;
