import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import RequestWrapper from "../../../decorators/RequestWrapper";
import { TRANSITION_REQUEST } from "../../../modules/requestTracker";
import styles from "./TransitionButton.module.scss";

const TransitionButton = (props) => {
  const {
    disable,
    processTransition,
    wrapperStyles,
    wasModifiedByClient,
    altButtonStyle,
    ...transition
  } = props;

  const handleClick = () => processTransition(transition);

  const buttonStyles = classNames({
    [wrapperStyles]: true,
    [styles.buttonForwardStyles]: props.forwardTransition,
    [styles.buttonCustomStyle]: props.forwardTransition && props.altButtonStyle,
    [styles.buttonStyles]: !props.forwardTransition,
  });

  const wasModifiedAndNotApproved =
    wasModifiedByClient && transition.transitionName !== "Approve";

  const shouldDisable = disable || wasModifiedAndNotApproved;
  return (
    <button
      title={
        wasModifiedAndNotApproved
          ? "Please discard changes if you'd like to request amends"
          : ""
      }
      disabled={shouldDisable}
      onClick={handleClick}
      className={buttonStyles}
    >
      {transition.transitionName}
    </button>
  );
};

TransitionButton.propTypes = {
  forwardTransition: PropTypes.bool.isRequired,
  disable: PropTypes.bool,
  processTransition: PropTypes.func.isRequired,
  wrapperStyles: PropTypes.string,
};

export const WrappedTransitionButton = RequestWrapper(
  TransitionButton,
  TRANSITION_REQUEST
);
export default TransitionButton;
