import { createSelector } from "reselect";
import { createAction } from "redux-actions";
import { postGraphQL } from "../../utils/fetch";
import { upsertData } from "../utils/normalize";
import { quotedRatesByProjectIdSelector } from "../utils/entitySelector";
import { RESET_INITIAL_STATE } from "./me";
import { showErrorMessage, showSuccessMessage } from "./messagesV2";

const QUOTED_RATES_RESPONSE_KEY = "updateQuotedRates";

const quotedRatesFields = `quotedRateId, projectId, personId, languageCode, stageId, rateBandId, rate`;

export const quotedRatesByProjectIdQuery = `quotedRates (projectId: $projectId) {
  ${quotedRatesFields}
}`;

export const quotedRatesUpdateQuery = `updateQuotedRates (associations: $associations){
  ${quotedRatesFields}
}`;

export const FETCH_QUOTED_RATES_SUCCESS = "FETCH_QUOTED_RATES_SUCCESS";
export const UPDATE_QUOTED_RATES_SUCCESS = "UPDATE_QUOTED_RATES_SUCCESS";
export const fetchQuotedRatesSuccess = createAction(FETCH_QUOTED_RATES_SUCCESS);
export const updateQuotedRatesSuccess = createAction(
  UPDATE_QUOTED_RATES_SUCCESS
);

export function updateQuotedRates(associations) {
  return async (dispatch) => {
    try {
      const queryVars = { associations };
      const query = `mutation updateQuotedRates ($associations: [QuotedRatesInput]){
        ${quotedRatesUpdateQuery}
      }`;

      const results = await postGraphQL(
        query,
        queryVars,
        QUOTED_RATES_RESPONSE_KEY
      );

      dispatch(updateQuotedRatesSuccess(results));

      dispatch(showSuccessMessage("Rates saved"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
    }
  };
}

export const quotedRatesByProjectId = createSelector(
  quotedRatesByProjectIdSelector,
  (quotedRates) => {
    return quotedRates.reduce(
      (acc, { languageCode, stageId, personId, rateBandId, rate }) => {
        if (!acc[languageCode]) acc[languageCode] = {};
        if (!acc[languageCode][stageId]) acc[languageCode][stageId] = {};
        if (!acc[languageCode][stageId][personId])
          acc[languageCode][stageId][personId] = {};
        if (!acc[languageCode][stageId][personId][rateBandId])
          acc[languageCode][stageId][personId][rateBandId] = rate;

        return acc;
      },
      {}
    );
  }
);

export const quotedRatesActionHandlers = {
  [RESET_INITIAL_STATE]: () => quotedRatesInitialState,
  [FETCH_QUOTED_RATES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "quotedRateId"),
  [UPDATE_QUOTED_RATES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "quotedRateId"),
};

export const quotedRatesInitialState = { entities: {}, result: [] };
