import { StageType } from "../../../../../../../../../modules/stages";

export const getStageMaximums = ({
  assignees,
  stages,
  defaultAssignees,
  languageCodes,
  numParentDeliverables,
  unallocatedLanguages,
}) => {
  return stages.reduce((acc, { stageId, stageType }, idx) => {
    acc[stageId] = {};
    languageCodes.forEach((languageCode) => {
      if (stageType === StageType.Training) {
        const prevStageId = stages[idx - 1].stageId;
        const prevStageDefaultAssignees =
          defaultAssignees[prevStageId]?.[languageCode] || [];

        // count the amount of allocations that are also
        // in training in the previous production stage
        const trainingTotal = prevStageDefaultAssignees.reduce(
          (acc, { personId }) => {
            const assignee = assignees[prevStageId][languageCode][personId];
            if (!assignee.training) {
              return acc;
            }

            return acc + assignee.allocation;
          },
          0
        );

        acc[stageId][languageCode] = trainingTotal;
      } else {
        // non training stages always have the maximum to assign
        acc[stageId][languageCode] =
          languageCodes?.length > 1
            ? unallocatedLanguages[languageCode]
            : numParentDeliverables;
      }
    });

    return acc;
  }, {});
};
