import { connect } from "react-redux";
import {
  languagesDropdownSelector,
  workflowsDropdownSelector,
  contentTypesDropdownSelector,
  orderFormsDropdownSelector,
  projectTeamsCandidateSelector,
  transformToDropdownFormat,
  currenciesDropdownSelector,
} from "../../../../../utils/entitySelector";

import ProjectsNew from "../components/ProjectsNew";
import WithData from "../../../../../decorators/WithData";

import { getInitialData } from "../modules/getInitialData";
import { createProject } from "../../../../../modules/projects";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  createProject: (data) =>
    dispatch(createProject(data, ownProps.history, ownProps.match.params)),
});

const mapStateToProps = (state, ownProps) => {
  const { orderForms, accounts, parentAccounts, featureToggles } = state;
  const { editableInReview } = featureToggles;

  const orderFormId = Number(ownProps.match.params.orderFormId);
  const orderFormIds = orderFormsDropdownSelector(state);
  const workflows = workflowsDropdownSelector(state);
  const contentTypes = contentTypesDropdownSelector(state);
  const languages = languagesDropdownSelector(state);
  const currencies = currenciesDropdownSelector(state);
  const productionTeam = transformToDropdownFormat(
    projectTeamsCandidateSelector(state),
    "personId",
    ["firstName", "lastName"]
  );
  const clientTeam = transformToDropdownFormat(
    projectTeamsCandidateSelector(state, "Client"),
    "personId",
    ["firstName", "lastName"]
  );

  const english = languages.filter((a) => a.label === "English (British)");
  const others = languages.filter((a) => a.label !== "English (British)");

  const initialValues = {
    orderFormId,
    currencyCode: "GBP",
    editableClientStages: "NONE",
  };

  const orderForm = orderForms.entities[orderFormId];
  const account = accounts.entities[orderForm.accountId];
  const parentAccount = parentAccounts.entities[account.parentAccountId];

  const editableClientStagesValues = [
    { value: "NONE", label: "None" },
    { value: "POST_APPROVAL", label: "Editable post approval" },
  ];

  if (editableInReview) {
    editableClientStagesValues.push({
      value: "CLIENT_REVIEW",
      label: "Editable in review",
    });
  }

  return {
    account,
    currencies,
    orderForm,
    parentAccount,
    orderFormIds,
    workflows,
    contentTypes,
    initialValues,
    productionTeam,
    clientTeam,
    languages: english.concat(others),
    editableClientStagesValues,
  };
};

const ProjectsNewWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ProjectsNew));
export default ProjectsNewWithData;
