import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Input.module.scss";

class Input extends Component {
  focus() {
    this.refs.inputRef.focus();
  }

  render() {
    const {
      input,
      type,
      customStyles,
      meta,
      styles: propStyles,
      ...rest
    } = this.props;

    const isCheckbox = type === "checkbox";
    const hasError = meta?.visited && meta?.invalid && !meta?.active;

    const inputStyle = classNames({
      [styles.input]: true,
      [styles.formCheckInput]: isCheckbox,
      [styles.formControl]: !isCheckbox,
      [styles.border]: !isCheckbox,
      [propStyles]: !isCheckbox && propStyles,
      [customStyles]: customStyles,
      [styles.error]: hasError,
    });

    const inputElem = (
      <input
        ref="inputRef"
        className={inputStyle}
        id={input.name}
        type={type}
        {...rest}
        {...input}
      />
    );

    if (!isCheckbox) return inputElem;

    return (
      <div className={styles.checkboxContainer}>
        {inputElem}
        <label htmlFor={input.name}>
          <span className="hoverShadow" />
        </label>
      </div>
    );
  }
}

Input.propTypes = {
  customStyles: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
  }).isRequired,
  meta: PropTypes.object,
  styles: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default Input;
