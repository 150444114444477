import { createAction } from "redux-actions";
import { upsertData, removeData } from "../utils/normalize";
import { postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";
import {
  rateBandsByProjectIdSelector,
  filterForCreated,
} from "../utils/entitySelector";
import { startRequest, endRequest } from "./requestTracker";
import { showErrors } from "../modules/errors";
import { splitArrayObjects } from "../utils/splitItems";
import { hideModal } from "./modal";

const rateBandFields = "rateBandId, rateBandName, projectId, archived";

export const rateBandsByProjectIdQuery = `rateBands (projectId: $projectId) {
  ${rateBandFields}
}`;

const REQUEST_NAME = `updatingRateBands`;
export const FETCH_RATE_BANDS_SUCCESS = "FETCH_RATE_BANDS_SUCCESS";
const CREATE_RATE_BANDS_SUCCESS = "CREATE_RATE_BANDS_SUCCESS";
const UPDATE_RATE_BANDS_SUCCESS = "UPDATE_RATE_BANDS_SUCCESS";
const REMOVE_RATE_BANDS_SUCCESS = "REMOVE_RATE_BANDS_SUCCESS";

export const fetchRateBandsSuccess = createAction(FETCH_RATE_BANDS_SUCCESS);
const createRateBandsSuccess = createAction(CREATE_RATE_BANDS_SUCCESS);
const updateRateBandsSuccess = createAction(UPDATE_RATE_BANDS_SUCCESS);
const removeRateBandsSuccess = createAction(REMOVE_RATE_BANDS_SUCCESS);

export function updateRateBands(values, history, params) {
  const projectId = Number(params.projectId);
  const rateBandsFromForm = values.rateBands;

  return async (dispatch, getState) => {
    const state = getState();
    // @ts-ignore
    const rateBandsInState = rateBandsByProjectIdSelector(state, projectId);

    // calculate which items have been removed/created/updated
    const [removeRBItems, createRBItems, updateRBItems] = splitArrayObjects(
      rateBandsInState,
      rateBandsFromForm,
      "rateBandId"
    );

    // build a list of objects to send to the server to soft delete/add/update the items
    const rateBandsItems = removeRBItems
      // for removed items, send { archived: true } to soft delete
      .map((item) => ({ ...item, archived: true }))
      .concat(createRBItems)
      .concat(updateRBItems)
      // add projectId to all created items
      .map((item) => ({ ...item, projectId }))
      // remove items that have no name (spaces don't count as valid names)
      .filter(
        (item) => item.rateBandName && item.rateBandName.trim().length !== 0
      );

    const queryVars = { associations: rateBandsItems, projectId };
    const query = `mutation updateRateBands ($projectId: Int, $associations: [RateBandInput]){
      updateRateBands (projectId: $projectId, associations: $associations){
        rateBandId, rateBandName, projectId
      }
    }`;

    // disable buttons while we are processing
    dispatch(startRequest(REQUEST_NAME));

    try {
      // server returns updated and created rows
      const rateBands = await postGraphQL(query, queryVars, "updateRateBands");
      // figure out which rows were created by subtracting the updated rows from the returned results
      const createdRBItems = filterForCreated(
        rateBands,
        updateRBItems,
        "rateBandId"
      );

      // update state and page data
      dispatch(createRateBandsSuccess(createdRBItems));
      dispatch(updateRateBandsSuccess(updateRBItems));
      dispatch(removeRateBandsSuccess(removeRBItems));

      // after we have successfully updated the rate bands close the modal
      dispatch(hideModal());
    } catch (err: any) {
      // if an error occurs, show it via the error component
      const errors = err.errors.map((error) => error.message);
      dispatch(showErrors(errors));
    } finally {
      // Always re-enable buttons after requests are done / errors handled
      dispatch(endRequest(REQUEST_NAME));
    }
  };
}

export const rateBandActionHandlers = {
  [RESET_INITIAL_STATE]: () => rateBandInitialState,
  [FETCH_RATE_BANDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "rateBandId"),
  [CREATE_RATE_BANDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "rateBandId"),
  [UPDATE_RATE_BANDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "rateBandId"),
  [REMOVE_RATE_BANDS_SUCCESS]: (state, { payload }) =>
    payload.reduce((acc, { rateBandId }) => removeData(acc, rateBandId), state),
};

export const rateBandInitialState = { entities: {}, result: [] };
