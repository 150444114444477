import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../../../../../../components/Modal/components/Modal";
import Input from "../../../../../../../../../../components/Input";
import styles from "./AdminRequestModal.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import OverlayLoading from "../../../../../../../../../../components/OverlayLoading/v2";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { updateAssignmentGroup } from "../../../../../../../../../../modules/assignmentGroups";
import ReassignmentForm from "../../../../_components/ReassignmentForm";
import { createReassignments } from "../../../../../../../../../../modules/assignments";
import { projectById } from "../../../../../../../../../../modules/projects";
import {
  GET_ADJUSTMENT_QUERY_GQL,
  GET_ASSIGNMENT_GROUP_DELIVERABLES,
  GET_DELIVERABLE,
  UPDATE_ADJUSTMENT_GQL,
} from "../../../../../../../../../../modules/assignmentGroupAdjustment";
import Icon from "../../../../../../../../../../components/Icon";

const DeadlineTabs = {
  EXTENSION: "EXTENSION",
  REASSIGNMENT: "REASSIGNMENT",
  REJECTION: "REJECTED_REQUEST",
};

type DateTypes = {
  extensionDate?: string;
  iso8601date?: string;
};

export function formatAdjustmentDate(date: string | null): DateTypes {
  if (!date) return {};

  const [y, m, d, hour, min] = date.split(/-| |:/g);

  const extensionDate = `${d}/${m}/${y} ${hour}:${min}`;
  const iso8601date = `${y}-${m}-${d}T${hour}:${min}`;

  return { extensionDate, iso8601date };
}

function Tab({ children, onClick, isActive }) {
  const tabStyles = classNames({
    [styles.tab]: true,
    [styles.active]: isActive,
  });
  return (
    <div onClick={onClick} className={tabStyles}>
      {children}
    </div>
  );
}

function AdminRequestModal(props) {
  const {
    setIsOpen,
    assignmentGroupId,
    projectId,
    assigneeName,
    stageName,
    oldDeadline,
  } = props;

  const dispatch = useDispatch();
  const [deadlineTab, setDeadlineTab] = useState(DeadlineTabs.EXTENSION);
  const [deadline, setDeadline] = useState("");
  const [reason, setReason] = useState("");
  const [isNextStagesVisible, setIsNextStagesVisible] = useState(false);
  const [nextStagesDeadline, setNextStagesDeadline] = useState({});
  const [isUpdatingAdjustment, setUpdatingAdjustment] = useState(false); // NOSONAR

  const inputRefs = useRef([]);

  const project = useSelector((state) => projectById(state, projectId));

  const assignmentGroup = useSelector(
    // @ts-ignore
    (state) => state.assignmentGroups.entities[assignmentGroupId]
  );

  const reassignmentData = useSelector((state) => {
    // @ts-ignore
    return state?.form?.assignmentsForm?.values;
  });

  const isExtensionTab = deadlineTab === DeadlineTabs.EXTENSION;
  const isReassignmentTab = deadlineTab === DeadlineTabs.REASSIGNMENT;
  const isRejectionTab = deadlineTab === DeadlineTabs.REJECTION;

  const { data, loading } = useQuery(GET_ADJUSTMENT_QUERY_GQL, {
    variables: { assignmentGroupId },
  });

  const { data: deliverablesData } = useQuery(
    GET_ASSIGNMENT_GROUP_DELIVERABLES,
    {
      variables: { assignmentGroupId: String(assignmentGroupId) },
    }
  );

  const deliverableId = deliverablesData?.deliverables?.[0]?.deliverableId;

  const { data: deliverableData } = useQuery(GET_DELIVERABLE, {
    variables: {
      deliverableId: String(deliverableId),
      includeAssignments: true,
    },
    skip: !deliverableId,
  });

  const getNextStages = () => {
    const { assignments } = deliverableData?.deliverable || {};
    if (assignments) {
      const currentStageIndex = assignments.findIndex(
        (item) => item.stageName === stageName
      );
      return assignments.slice(currentStageIndex + 1);
    }

    return [];
  };

  const nextStages = getNextStages();

  useEffect(() => {
    if (deliverableData && nextStages?.length > 0) {
      const nextStagesDeadlineData = nextStages.reduce(
        (acc, item) => ({
          ...acc,
          [item.stageId]:
            new Date(data?.assignmentGroupAdjustment?.deadline) >
            new Date(item.deadline)
              ? data?.assignmentGroupAdjustment?.deadline
              : item.deadline,
        }),
        {}
      );
      setNextStagesDeadline(nextStagesDeadlineData);
    }
  }, [deliverableData, data?.assignmentGroupAdjustment]);

  useEffect(() => {
    // Check if data is loaded and available
    if (loading || !data) {
      return;
    }

    const { deadline, adjustmentType } = data.assignmentGroupAdjustment;
    const { iso8601date } = formatAdjustmentDate(deadline);

    // Update / Set the deadline state based on the `isExtensionTab` flag
    if (isExtensionTab) {
      updateDeadline({ target: { value: iso8601date } });
    } else {
      setDeadline("");
    }
    if (adjustmentType === "CANCELLATION") {
      setDeadlineTab(DeadlineTabs.REASSIGNMENT);
    }
  }, [loading, data, isExtensionTab]);

  const [updateAdjustment] = useMutation(UPDATE_ADJUSTMENT_GQL);

  const setExtension = () => {
    setDeadlineTab(DeadlineTabs.EXTENSION);
  };

  const setReassignment = () => {
    setDeadlineTab(DeadlineTabs.REASSIGNMENT);
  };

  const setRejection = () => {
    setDeadlineTab(DeadlineTabs.REJECTION);
  };

  const updateReason = (e) => {
    setReason(e.target.value);
  };

  const updateDeadline = (e) => {
    const newValue = e.target.value;
    const formattedValue = newValue ? `${newValue.replace(/T/, " ")}:00` : "";
    setDeadline(formattedValue);
  };

  const submitReassigned = async () => {
    const { assignees, deadlines } = reassignmentData;

    const input = {
      projectId,
      assignmentGroupId,
      assignees,
      deadlines,
      responseNote: reason,
    };
    dispatch(createReassignments(input));
  };

  const submit = async () => {
    if (isReassignmentTab) {
      return submitReassigned();
    }

    if (isExtensionTab && nextStages?.length > 0) {
      // validate all deadlines of current stage and next stages
      let previousStageDeadline = deadline;
      const invalidDeadlineIndex = nextStages.findIndex((stage, index) => {
        const currentStageDeadline = nextStagesDeadline[stage.stageId];
        if (new Date(previousStageDeadline) > new Date(currentStageDeadline))
          return true;
        else {
          previousStageDeadline = currentStageDeadline;
          return false;
        }
      });
      if (
        invalidDeadlineIndex >= 0 &&
        inputRefs.current?.[invalidDeadlineIndex]
      ) {
        const inputEleRef = inputRefs.current[invalidDeadlineIndex];
        inputEleRef.refs.inputRef.setCustomValidity(
          "Please select a date equal to or after the date of the stage before."
        );
        inputEleRef.refs.inputRef.reportValidity();
        return;
      }
    }

    const { assignmentGroupAdjustmentId } = assignmentGroupAdjustment;

    const input = {
      assignmentGroupAdjustmentId,
      assignmentGroupId,
      responseType: deadlineTab,
      responseNote: reason,
      ...(isExtensionTab
        ? {
            responseDeadline: deadline,
            nextStages: nextStages
              ? nextStages.map((stage) => ({
                  assignmentGroupId: stage.assignmentGroupId,
                  responseDeadline: nextStagesDeadline[stage.stageId],
                }))
              : [],
          }
        : {}),
    };

    setUpdatingAdjustment(true);

    const response = await updateAdjustment({
      variables: { input },
    });

    setUpdatingAdjustment(false);

    if (
      !response.errors &&
      response.data?.updateAssignmentGroupAdjustment?.actioned
    ) {
      setIsOpen(false);
      dispatch(
        updateAssignmentGroup({
          assignmentGroupId,
          hasRequestPending: false,
        })
      );
    }
  };

  // deadline is only required when it's an extension
  const isSubmitDisabled = isExtensionTab && deadline.length === 0;

  if (loading) {
    return <OverlayLoading />;
  }

  const { assignmentGroupAdjustment } = data;

  const { extensionDate, iso8601date } = formatAdjustmentDate(
    assignmentGroupAdjustment.deadline
  );

  const isAdjustmentExtension =
    assignmentGroupAdjustment.adjustmentType === "EXTENSION";

  const isAdjustmentCancellation =
    assignmentGroupAdjustment.adjustmentType === "CANCELLATION";

  const toggleNextStages = () => {
    setIsNextStagesVisible((prevState) => !prevState);
  };

  const updateNextStageDeadline = (e, stageId) => {
    const newValue = e.target.value;
    const formattedValue = newValue ? `${newValue.replace(/T/, " ")}:00` : "";
    setNextStagesDeadline({ ...nextStagesDeadline, [stageId]: formattedValue });
  };

  return (
    <>
      {isUpdatingAdjustment && <OverlayLoading />}
      <Modal
        medium
        display
        hideModal={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        padding30
        title={"Adjust assignment"}
        modalExtraStyles={styles.adminRequestModal}
        body={
          <div>
            <div>
              <span>{assigneeName} has requested to </span>
              {isAdjustmentExtension ? (
                <span>extend the deadline to {extensionDate} </span>
              ) : (
                <span>cancel their assignment </span>
              )}
              <span>for the following reason:</span>

              <div>&nbsp;</div>
              <div>"{assignmentGroupAdjustment.reason}"</div>
            </div>

            <div className={styles.deadlineTabs}>
              {isAdjustmentExtension && (
                <>
                  <Tab onClick={setExtension} isActive={isExtensionTab}>
                    Extend deadline
                  </Tab>
                  <Tab onClick={setReassignment} isActive={isReassignmentTab}>
                    Reassign work
                  </Tab>
                  <Tab onClick={setRejection} isActive={isRejectionTab}>
                    Reject request
                  </Tab>
                </>
              )}
              {isAdjustmentCancellation && (
                <>
                  <Tab onClick={setReassignment} isActive={isReassignmentTab}>
                    Reassign work
                  </Tab>
                  <Tab onClick={setRejection} isActive={isRejectionTab}>
                    Reject request
                  </Tab>
                </>
              )}
            </div>

            {isExtensionTab && (
              <>
                <div
                  className={classNames(styles.card, styles.currentStageCard)}
                >
                  <div>
                    <h3>{stageName}</h3>
                    <p>
                      <b>{assigneeName}</b>
                    </p>
                    <small>Old deadline: {oldDeadline}</small>
                  </div>
                  <div>
                    <span>New deadline</span>
                    <Input
                      type="datetime-local"
                      input={{ name: "current-stage-deadline" }}
                      defaultValue={deadline}
                      customStyles={styles.customInputStyles}
                      max="9999-12-31T00:00"
                      onChange={updateDeadline}
                    />
                  </div>
                </div>
                {nextStages?.length > 0 && (
                  <p
                    className={`${styles.nextStages} ${classNames({
                      [styles.iconRotated]: isNextStagesVisible,
                    })}`}
                    onClick={toggleNextStages}
                  >
                    <Icon name="ChevronDown" />
                    Update deadline for all next stages
                  </p>
                )}
                {isNextStagesVisible &&
                  nextStages?.length > 0 &&
                  nextStages.map((stage, index) => (
                    <div className={styles.card} key={stage.stageId}>
                      <div>
                        <h3>{stage.stageName}</h3>
                        <p>
                          <b>{stage.assignee}</b>
                        </p>
                        <small>Old deadline: {stage.deadline}</small>
                      </div>
                      <div>
                        <span>New deadline</span>
                        <Input
                          type="datetime-local"
                          input={{ name: `stage-${stage.stageId}-deadline` }}
                          value={nextStagesDeadline[stage.stageId]}
                          customStyles={styles.customInputStyles}
                          max="9999-12-31T00:00"
                          onChange={(e) =>
                            updateNextStageDeadline(e, stage.stageId)
                          }
                          ref={(el) => (inputRefs.current[index] = el)}
                        />
                      </div>
                    </div>
                  ))}
              </>
            )}

            {isReassignmentTab && (
              <ReassignmentForm
                // @ts-ignore
                useModal={false}
                project={project}
                assignmentGroup={assignmentGroup}
                match={{ params: { projectId } }}
                isInlineForm
              />
            )}

            <div className={styles.footerSection}>
              {!isExtensionTab && (
                <div className={styles.requestContainer}>
                  <div className={styles.requestText}>
                    Note to {assigneeName}
                  </div>
                  <textarea
                    onChange={updateReason}
                    className={styles.requestReason}
                    value={reason}
                  />
                </div>
              )}

              <div className={styles.btnContainer}>
                <button
                  disabled={isSubmitDisabled}
                  className={styles.submit}
                  type="submit"
                  onClick={submit}
                >
                  {isExtensionTab ? "Confirm changes and notify" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}

export default AdminRequestModal;
