import { binarySearch, sortedInsert } from "./splitItems";

function resolveId(entity, key) {
  return typeof key === "function" ? key(entity) : String(entity[key]);
}

function upsertArrayData(state, entities, key) {
  // keep track of new data
  const newEntities = {};
  const newIds = [];

  entities.forEach((entity) => {
    const id = resolveId(entity, key);
    const itemIndex = binarySearch(state.result, id);
    if (itemIndex === -1) {
      newIds.push(id);
      newEntities[id] = entity;
    } else {
      const updatedEntity = Object.assign({}, state.entities[id], entity);
      newEntities[id] = updatedEntity;
    }
  });

  const nextEntities = Object.assign({}, state.entities, newEntities);

  const nextResult = newIds.reduce(
    (acc, newId) => sortedInsert(acc, newId),
    state.result
  );
  return { entities: nextEntities, result: nextResult };
}

function upsertSingleData(state, entity, key) {
  const id = resolveId(entity, key);
  const itemIndex = binarySearch(state.result, id);
  const nextEntity =
    itemIndex === -1 ? entity : Object.assign({}, state.entities[id], entity);
  const result =
    itemIndex === -1 ? sortedInsert(state.result, id) : state.result;
  const entities = Object.assign({}, state.entities, { [id]: nextEntity });

  return { entities, result };
}

/**
 * @param {Object}          state
 * @param {Object}          entity
 * @param {string|function} key the name of the unique property or a function that
 *  takes an entity and returns a derived unique id
 */
export function upsertData(state, payload, key) {
  if (Array.isArray(payload)) {
    return upsertArrayData(state, payload, key);
  } else {
    return upsertSingleData(state, payload, key);
  }
}

/**
 * Filters data in the state
 *
 * @param {Object} state          the state for a given resource
 * @param {Object} state.entities entities indexed by their id
 * @param {Object} state.result   entity ids
 * @param {function} filter       filter function, should return true for
 *  entities that should remain in the data
 */
export function filterData(state, filter) {
  const entities = {};

  // Only add entities that pass filter function
  Object.keys(state.entities).forEach((id) => {
    const entity = state.entities[id];
    if (filter(entity)) {
      entities[id] = entity;
    }
  });

  return {
    entities,
    result: Object.keys(entities),
  };
}

export function removeData(state, id) {
  /*  we create a new entities object by destructuring
      and using ... to get the rest of the keys, 'removed' is unused
      eslint-disable no-unused-vars */
  const { [String(id)]: removed, ...rest } = state.entities;
  /* eslint-enable */
  return { entities: rest, result: Object.keys(rest) };
}
