import PropTypes from "prop-types";
import styles from "./UnauthorizedLayout.module.scss";
import Helmet from "react-helmet";
import { renderRoutes } from "react-router-config";
import QuillRedirect from "../../../../components/QuillRedirect";

const getRedirectPath = (personType) => {
  switch (personType) {
    case "Freelancer":
      return "/freelancer";
    case "Client":
      return "/clients";
    case "In-house":
      return "/admin";
    default:
      return null;
  }
};

export const UnauthorizedLayout = (props) => {
  const { me, route } = props;
  const redirectPath = me && getRedirectPath(me.personType);

  if (redirectPath) {
    return <QuillRedirect to={redirectPath} />;
  }

  // This prevents the login page loading on redirect from auth0 (makes it smoother)
  const routes = route.routes.filter(({ path }) => {
    return me?.personType === "unknown" ? path === "/contact" : true;
  });

  if (routes.lengh === 0) {
    return;
  }

  return (
    <>
      <div className={styles.container}>
        <Helmet defaultTitle="J+ Scribe">
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div className={styles.content}>{renderRoutes(routes)}</div>
      </div>
    </>
  );
};

UnauthorizedLayout.propTypes = {
  me: PropTypes.shape({
    personType: PropTypes.string.isRequired,
  }),
  route: PropTypes.object.isRequired,
};

export default UnauthorizedLayout;
