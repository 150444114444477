import React from "react";
import PropTypes from "prop-types";
import { core, marginLeft } from "./ClientLayout.module.scss";
import Nav from "../../../../components/Nav";
import Helmet from "react-helmet";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import QuillRedirect from "../../../../components/QuillRedirect";
import MigrationMaintenanceBanner from "../../../../components/MigrationMaintenanceBanner";

export const ClientLayout = (props) => {
  if (!props.me) {
    return <QuillRedirect to="/" />;
  }
  if (props.me.personType === "In-house") {
    return <Redirect to="/admin" />;
  }
  if (props.me.personType === "Freelancer") {
    return <Redirect to="/freelancer" />;
  }

  return (
    <div className={core}>
      <Helmet defaultTitle="J+ Scribe - Client">
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <MigrationMaintenanceBanner />

      <Nav
        home="/clients"
        items={[
          {
            url: "/clients",
            iconName: "Home",
            active: props.location.pathname === "/clients",
            hoverText: "Dashboard",
          },
        ]}
        logout={props.logout}
        personType={props.me.personType}
      />
      <div className={marginLeft}>{renderRoutes(props.route.routes)}</div>
    </div>
  );
};

ClientLayout.propTypes = {
  me: PropTypes.shape({
    personType: PropTypes.string.isRequired,
  }),
  route: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default ClientLayout;
