import React from "react";
import PropTypes from "prop-types";

import styles from "./KeywordGroupsArray.module.scss";
import RenderFieldArray from "../_decorators/RenderFieldArray";

import { RemoveButton } from "../../../../../../../../components/Buttons";
import DragButton from "../../../../../../../../components/DragButton";
import WithEnterFieldArrayInput from "../../../../../../../../components/WithEnterFieldArrayInput";

import { DragSource, DropTarget } from "react-dnd";
import DraggableHelpers from "../DraggableHelpers";
import flow from "lodash/flow";
import NewFieldAddButton from "./../NewFieldAddButton/NewFieldAddButton";

const KeywordLabel = (props) => (
  <div className={styles.container}>
    <label className={styles.quillHeader} htmlFor="briefing">
      Keyword Groups
    </label>
  </div>
);

const KeywordGroups = (props) => {
  const { connectDropTarget, connectDragPreview, isOver } = props;
  const opacity = isOver ? 0 : 1;

  return connectDropTarget(
    connectDragPreview(
      <div className={styles.keywordGroups} style={{ opacity }}>
        <WithEnterFieldArrayInput
          addField={props.addField}
          arrayName={props.name}
          fieldName="keywordGroupName"
          placeholder="Enter keyword field name, eg Encouraged words, Primary keyword"
          type="text"
        />
        <DragButton connectDragSource={props.connectDragSource} />
        <RemoveButton
          disabled={props.disableRemove}
          onClick={props.removeField}
        />
      </div>
    )
  );
};

KeywordGroups.propTypes = {
  addField: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  disableRemove: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  removeField: PropTypes.func.isRequired,
};

const DraggableKeywordGroups = flow(
  DropTarget(
    "keywordGroups",
    DraggableHelpers.dragTarget,
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
    })
  ),
  DragSource(
    "keywordGroups",
    DraggableHelpers.dragSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    })
  )
)(KeywordGroups);

const KeywordGroupsArray = RenderFieldArray(
  KeywordLabel,
  DraggableKeywordGroups,
  NewFieldAddButton
);

export default KeywordGroupsArray;
