import PropTypes from "prop-types";
import React, { Component } from "react";
import { renderRoutes } from "react-router-config";
import { generateDownload } from "../../../../../../utils/files";
import Errors from "../../../../../components/Errors";
import OverlayLoading from "../../../../../components/OverlayLoading/v2";
import PageHeader from "../../../../../components/PageHeader";
import { fullMonthNames, getMonthRange } from "../../../../../utils/date";
import { getLSItem, setLSItem } from "../../../../../utils/localStorage";
import styles from "./Layout.module.scss";

const paymentActiveYear = "PAYMENT_ACTIVE_YEAR";
const paymentActiveMonth = "PAYMENT_ACTIVE_MONTH";

// get all the previous payment years from 2017
const getPreviousYears = () => {
  const years = [];
  const date = new Date();
  const year = date.getFullYear();
  const startYear = 2017;

  for (let y = year; y >= startYear; y--) {
    years.push(y);
  }

  return years;
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      activeMonth: new Date().getMonth(),
      activeYear: new Date().getFullYear(),
      paymentsLoading: false,
    };
  }

  setStartDate = (event) => this.setState({ startDate: event.target.value });
  setEndDate = (event) => this.setState({ endDate: event.target.value });

  componentDidMount() {
    if (!getLSItem(paymentActiveYear) && !getLSItem(paymentActiveMonth)) {
      this.updateSearch(this.state.activeYear, this.state.activeMonth);
    } else {
      this.updateSearch(
        getLSItem(paymentActiveYear),
        getLSItem(paymentActiveMonth)
      );
    }
    this.renderQuery();
  }

  componentDidUpdate() {
    this.renderQuery();
  }

  renderQuery = () => {
    const { search } = this.props.location;
    if (
      !search &&
      getLSItem(paymentActiveMonth) &&
      getLSItem(paymentActiveYear)
    ) {
      this.props.history.push(
        this.getSearchUrl(
          getLSItem(paymentActiveMonth),
          getLSItem(paymentActiveYear)
        )
      );
    }
  };

  processRemittanceRequest = () => {
    const {
      location: { search },
    } = this.props;
    if (search) return this.generateRemittance();
  };

  generateRemittance = () => {
    const startDateInput = document.getElementById("date-start");
    const endDateInput = document.getElementById("date-end");
    const startDate = startDateInput && startDateInput.value;
    const endDate = endDateInput && endDateInput.value;

    this.props.generateRemittance({ startDate, endDate }).then((response) => {
      if (response instanceof Error) {
        return this.props.showErrors([
          "Could not generate a PDF. Please ensure you have payments available",
        ]);
      }
      const href = window.URL.createObjectURL(response);
      const filename = href.split("/").pop();
      generateDownload(filename, href);
    });
  };

  getSearchUrl = (month, year) => {
    const baseUrl = "/freelancer/payments";
    const { start, end } = getMonthRange(month, year);
    return `${baseUrl}?start=${start}&end=${end}`;
  };

  updateSearch = async (activeYear = "", activeMonth = "") => {
    this.setState({ paymentsLoading: true });
    await this.props.fetchPayments(this.getSearchUrl(activeMonth, activeYear));
    this.setState({ activeYear, activeMonth });
    setLSItem(paymentActiveYear, activeYear);
    setLSItem(paymentActiveMonth, activeMonth);
    this.setState({ paymentsLoading: false });
  };

  onYearChange = (e) => {
    this.updateSearch(e.target.value, this.state.activeMonth);
    if (this.state.activeMonth) {
      this.props.history.push(
        this.getSearchUrl(this.state.activeMonth, Number(e.target.value))
      );
    }
  };

  onMonthChange = (e) => {
    this.updateSearch(this.state.activeYear, e.target.value);
    this.props.history.push(
      this.getSearchUrl(e.target.value, this.state.activeYear)
    );
  };

  dateWarning = () => {
    if (!this.state.activeMonth && this.state.activeYear) {
      return <div className={styles.dateWarning}>Please select month</div>;
    }

    if (!this.state.activeYear && this.state.activeMonth) {
      return <div className={styles.dateWarning}>Please select year</div>;
    }

    if (!this.state.activeYear && !this.state.activeMonth) {
      return (
        <div className={styles.dateWarning}>Please select year and month</div>
      );
    }
  };

  render() {
    const showAll = !this.state.activeYear || !this.state.activeMonth;

    const monthArray = fullMonthNames.map((month, i) => (
      <option key={i} value={i}>
        {month}
      </option>
    ));

    const yearArray = getPreviousYears().map((year, i) => (
      <option key={i} value={year}>
        {year}
      </option>
    ));

    if (this.state.paymentsLoading) return <OverlayLoading />;

    return (
      <div>
        <PageHeader title={"Payments"} />

        {this.props.errors.display && <Errors />}

        <div className={styles.nav}>
          <select
            className={styles.dropYear}
            onChange={this.onYearChange}
            value={this.state.activeYear}
          >
            <option value={""}>Year</option>
            {yearArray}
          </select>

          <select
            className={styles.dropMonth}
            onChange={this.onMonthChange}
            value={this.state.activeMonth}
          >
            <option value={""}>Month</option>
            {monthArray}
          </select>
        </div>

        {this.dateWarning()}
        {renderRoutes(this.props.route.routes)}

        <div className={styles.buttonContainer}>
          {!showAll && (
            <button
              className={styles.secondaryBtn}
              onClick={this.processRemittanceRequest}
            >
              Download remittance
            </button>
          )}
          <a
            className={styles.paymentQuery}
            href="https://help.jplus.io/en/articles/7843736-j-scribe-payment-process"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={styles.defaultBtn}>Payment information</button>
          </a>
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  errors: PropTypes.shape({
    display: PropTypes.bool.isRequired,
    msg: PropTypes.string,
  }),
  generateRemittance: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
  payments: PropTypes.array,
  route: PropTypes.object.isRequired,
  showErrors: PropTypes.func.isRequired,
};

export default Layout;
