// Here is where you can define configuration overrides based on the execution environment.
// Supply a key to the default export matching the NODE_ENV that you wish to target, and
// the base configuration will apply your overrides before exporting itself.
module.exports = {
  local: (config) => ({
    compiler: {
      devtool: "cheap-module-source-map",
      public_path: process.env.DOMAIN_FRONTEND,
    },
    endpoints: {
      frontend: {
        __API_GATEWAY__: process.env.DOMAIN_API_GATEWAY,
        __QUILL_CLOUD_FRONTEND__: process.env.DOMAIN_FRONTEND,
      },
      backend: {
        apiGateway: process.env.DOMAIN_API_GATEWAY,
      },
    },
  }),

  e2e: (config) => ({
    compiler: {
      devtool: "cheap-module-source-map",
      public_path: `http://127.0.0.1:3005/`,
    },
    endpoints: {
      frontend: {
        __API_GATEWAY__: "http://127.0.0.1:5005",
        __QUILL_CLOUD_FRONTEND__: "127.0.0.1:3005",
      },
      backend: {
        // api-gateway-service host is defined by docker-compose
        apiGateway: "http://api-gateway-service:5005",
      },
    },
  }),

  development: (config) => ({
    compiler: {
      devtool: "cheap-module-source-map",
      public_path: process.env.DOMAIN_FRONTEND,
    },
    endpoints: {
      frontend: {
        __API_GATEWAY__: process.env.DOMAIN_API_GATEWAY || process.env.DOMAIN_API_GATEWAY_EXTERNAL,
        __QUILL_CLOUD_FRONTEND__: process.env.DOMAIN_FRONTEND,
        __SENTRY_DSN__: process.env.SENTRY_DSN,
        __GA_TAG__: process.env.GA_TAG,
        __PROJECT_ID__: process.env.PROJECT_ID,
      },
      backend: {
        apiGateway: process.env.DOMAIN_API_GATEWAY || process.env.DOMAIN_API_GATEWAY_INTERNAL,
      },
    },
  }),
  test: (config) => ({
    endpoints: {
      frontend: {
        __API_GATEWAY__: config.env.DOMAIN_API_GATEWAY,
        __QUILL_CLOUD_FRONTEND__: config.env.DOMAIN_FRONTEND,
      },
    },
  }),

  staging: (config) => ({
    endpoints: {
      frontend: {
        __API_GATEWAY__: "https://api-gateway.staging.quill-cloud.com",
        __QUILL_CLOUD_FRONTEND__:
          "https://quill-cloud-frontend.staging.quill-cloud.com",
      },
    },
  }),

  production: (config) => ({
    endpoints: {
      frontend: {
        __API_GATEWAY__: process.env.DOMAIN_API_GATEWAY || process.env.DOMAIN_API_GATEWAY_EXTERNAL,
        __QUILL_CLOUD_FRONTEND__: process.env.DOMAIN_FRONTEND,
        __GA_TAG__: process.env.GA_TAG,
        __PROJECT_ID__: process.env.PROJECT_ID,
      },
      backend: {
        apiGateway: process.env.DOMAIN_API_GATEWAY || process.env.DOMAIN_API_GATEWAY_INTERNAL,
      },
    },

    compiler: {
      public_path: "/",
      stats: {
        chunks: true,
        chunkModules: true,
        colors: true,
      },
    },
  }),
};
