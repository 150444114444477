import { createAction } from "redux-actions";
import { createSelector } from "reselect";

// ------------------------------------
// Constants
// ------------------------------------

export const START_REQUEST = "START_REQUEST";
export const END_REQUEST = "END_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

export const startRequest = createAction(START_REQUEST);
export const endRequest = createAction(END_REQUEST);

// ------------------------------------
// Trackers
// ------------------------------------

export const TRANSITION_REQUEST = "TransitionRequest";

// ------------------------------------
// Action Handlers
// ------------------------------------

export const isRequesting = createSelector(
  (state: any) => state.requestTracker,
  (_, request) => request,
  (requests, request) => requests[request]
);

export const actionHandlers = {
  [START_REQUEST]: (state, { payload }) => ({
    ...state,
    [payload]: true,
  }),
  [END_REQUEST]: (state, { payload }) => ({
    ...state,
    [payload]: false,
  }),
};

export const initialState = {};

const reducer = (state = initialState, action) => {
  const handleFn = actionHandlers[action.type];
  return handleFn ? handleFn(state, action) : state;
};

export default reducer;
