import React, { useEffect, useRef } from "react";
import { createGrammarCheckIgnore } from "../../../../../modules/webSpellChecker";
import FeatureToggle from "../../../../FeatureToggle";
import styles from "./WritingAidSuggestions.module.scss";

const WIDTH = 350;
const MIN_LEFT = 60;
const TRIANGLE_SIZE = 8;

const WritingAidSuggestions = ({
  addToDictionary,
  applySuggestion,
  suggestion,
  text,
  onClose,
  position,
  start,
  taskFieldId,
  projectId,
  refetchGrammarChecks,
}) => {
  const boxRef = useRef();

  const handleClickOutside = (e) => {
    // @ts-ignore
    if (boxRef.current !== null && !boxRef.current.contains(e.target)) {
      onClose();
    }
  };

  const handleSuggestion = (e) => {
    const {
      target: { textContent },
    } = e;
    applySuggestion(textContent, text, start);
  };

  const handleAddToDictionary = () => {
    addToDictionary(text);
  };

  const { suggestions, message, type, rule } = suggestion || {};
  const params = {
    text,
    type,
    rule,
    projectId,
  };

  const createIgnore = async (params) => {
    await createGrammarCheckIgnore(params);

    onClose();

    await refetchGrammarChecks();
  };

  const handleIgnore = async () => {
    await createIgnore({
      ...params,
      taskFieldId,
    });
  };
  const handleIgnoreAll = async () => {
    await createIgnore(params);
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    if (position && position.left) {
      // Position main box
      const box = boxRef.current as any;
      if (box && box.style) {
        const boxX = position.left - WIDTH + position.width / 2 + WIDTH / 2;
        const maxLeft =
          typeof window === "undefined"
            ? WIDTH
            : window.innerWidth - WIDTH - 10;

        box.style.top = `${position.bottom + TRIANGLE_SIZE}px`;
        box.style.left = `${Math.min(maxLeft, Math.max(MIN_LEFT, boxX))}px`;
        box.style.display = "block";
      }
    }
  });

  return (
    // @ts-ignore
    <div key="box" ref={boxRef} className={styles.suggestionGroup}>
      {suggestions ? (
        <>
          <div className={styles.suggestionType}>{message}</div>
          {suggestions.map((sug) => {
            return (
              <div
                key={sug}
                onClick={handleSuggestion}
                className={styles.suggestion}
              >
                {sug}
              </div>
            );
          })}
          <FeatureToggle toggle="userDictionary">
            <div
              className={styles.addToDictionary}
              onClick={handleAddToDictionary}
            >
              Add to dictionary
            </div>
          </FeatureToggle>

          <FeatureToggle toggle="grammarCheckIgnore">
            <div className={styles.ignore}>
              <div className={styles.ignoreOption} onClick={handleIgnore}>
                Ignore
              </div>
              <div className={styles.ignoreOption} onClick={handleIgnoreAll}>
                Ignore all
              </div>
            </div>
          </FeatureToggle>
        </>
      ) : (
        <div className={styles.noSuggestion}>No suggestions</div>
      )}
    </div>
  );
};

export default WritingAidSuggestions;
