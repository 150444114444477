import { connect } from 'react-redux'
import Approved from '../components/Approved'
import WithData from '../../../../../decorators/WithData'

import { getInitialData } from '../../../_modules/getInitialData'
import { createDashboardGroupingSelector } from '../../_selectors/dashboard'

const getApprovedAssignmentGroups = createDashboardGroupingSelector(
  ({ status }) => status === 'Approved'
)

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () =>
      dispatch(getInitialData({ includeArchivedAssignments: true })),
  }
}

const mapStateToProps = (state, ownProps) => {
  const approvedAssignmentGroups = getApprovedAssignmentGroups(state)

  return {
    approvedAssignmentGroups,
  }
}

const DashboardApproved = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Approved))
DashboardApproved.getInitialData = getInitialData
export default DashboardApproved
