import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { SelectWithErrors } from "../../../../../../../decorators/WithErrors";
import styles from "./DefaultAssigneesForm.module.scss";
import debounce from "lodash/debounce";

class Form extends Component {
  constructor() {
    super();
    this.state = {
      initialOptions: [],
    };
  }

  componentWillMount() {
    const { people, assignees } = this.props;
    const defaultAssigneeIds = Object.values(assignees).map(
      ({ personId }) => personId
    );
    const initialOptions = people.filter(({ object }) =>
      defaultAssigneeIds.includes(object.personId)
    );
    this.setState({ initialOptions });
  }

  debouncedSearch = debounce((input) => this.props.searchPeople(input), 150);

  handleInputChange = (input, languageStageId) => {
    const { initialOptions } = this.state;
    const { people } = this.props;

    this.debouncedSearch(input, languageStageId);

    const newOptions = people
      .filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()))
      .slice(0, 10);

    const uniqueOptions = [
      ...new Map(
        [...initialOptions, ...newOptions].map((item) => [item["value"], item])
      ).values(),
    ];

    this.setState({
      initialOptions: initialOptions.length ? uniqueOptions : people,
      input,
    });

    return input;
  };

  render() {
    const {
      handleSubmit,
      people,
      submitting,
      projectLanguages,
      stages,
      valid,
      error,
    } = this.props;
    const { input, initialOptions } = this.state;

    return (
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className="select-wrapper">
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <div>Language</div>
                </th>
                {stages.map(({ stageId, stageName }) => (
                  <th key={stageId}>
                    <div>{stageName}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {projectLanguages.map(({ languageCode }, index) => (
                <tr key={index}>
                  <td>
                    <div>{languageCode}</div>
                  </td>
                  {stages.map(({ stageId }) => (
                    <td key={`${languageCode}${stageId}`}>
                      <div>
                        <Field
                          rebrandingContainer
                          key={`${languageCode}[${stageId}]`}
                          component={SelectWithErrors}
                          disabled={submitting}
                          multi
                          name={`${languageCode}[${stageId}]`}
                          onChange={() => this.setState({ input: "" })}
                          onInputChange={(input) =>
                            this.handleInputChange(input)
                          }
                          onSelectResetsInput={!input}
                          options={
                            initialOptions.length ? initialOptions : people
                          }
                          type="number"
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div>{error && <strong>{error}</strong>}</div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.button}
              disabled={submitting || !valid}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  assignees: PropTypes.object.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  people: PropTypes.array.isRequired,
  projectLanguages: PropTypes.array.isRequired,
  searchPeople: PropTypes.func.isRequired,
  stages: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

const DefaultAssigneesForm = reduxForm({
  form: "defaultAssignees",
})(Form);

export default DefaultAssigneesForm;
