import { connect } from "react-redux";
import PropTypes from "prop-types";
import ByAssignment from "./ByAssignment";
import { adjacentAssignmentUrlsSelector } from "../../../../routes";
import { navigableAssignmentsSelector } from "../../../../utils/entitySelector";
import { getAssignmentStatus } from "../../../../modules/assignments";

const mapStateToProps = (state, { assignment, listUrl }) => {
  const { nextUrl, previousUrl } = adjacentAssignmentUrlsSelector(
    state,
    listUrl,
    assignment
  );
  const deliverableIds = navigableAssignmentsSelector(state, assignment).map(
    ({ deliverableId }) => deliverableId
  );

  const { isActionable, isCommentable, isEditable } = getAssignmentStatus(
    state,
    assignment
  );

  return {
    deliverableIds,
    isActionable,
    isCommentable,
    isEditable,
    nextUrl,
    previousUrl,
  };
};

const WithContainer = connect(mapStateToProps)(ByAssignment);

WithContainer.propTypes = {
  assignment: PropTypes.object.isRequired,
  listUrl: PropTypes.string.isRequired,
};

export default WithContainer;
