import ProjectInfo from "./containers/ProjectInfo";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../modules/projects";
import {
  bannedWordActionHandlers,
  bannedWordInitialState,
} from "../../../modules/bannedWords";
import {
  projectLanguageActionHandlers,
  projectLanguageInitialState,
} from "../../../modules/projectLanguages";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../modules/orderForms";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../modules/accounts";

export default {
  Component: ProjectInfo,
  reducers: [
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
    {
      key: "bannedWords",
      actionHandlers: bannedWordActionHandlers,
      initialState: bannedWordInitialState,
    },
    {
      key: "projectLanguages",
      actionHandlers: projectLanguageActionHandlers,
      initialState: projectLanguageInitialState,
    },
  ],
};
