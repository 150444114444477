import BulkUploads from "./containers/BulkUploads";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../../../modules/accounts";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../../../modules/orderForms";
import {
  parentAccountActionHandlers,
  parentAccountInitialState,
} from "../../../../../modules/parentAccounts";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../../../modules/projects";
import {
  projectLanguageActionHandlers,
  projectLanguageInitialState,
} from "../../../../../modules/projectLanguages";
import {
  keywordGroupActionHandlers,
  keywordGroupInitialState,
} from "../../../../../modules/keywordGroups";
import {
  briefingFieldActionHandlers,
  briefingFieldInitialState,
} from "../../../../../modules/briefingFields";
import {
  parentDeliverableActionHandlers,
  parentDeliverableInitialState,
} from "../../../../../modules/parentDeliverables";
import {
  batchActionHandlers,
  batchInitialState,
} from "../../../../../modules/batches";
import {
  keywordActionHandlers,
  keywordInitialState,
} from "../../../../../modules/keywords";
import {
  parentDeliverableBriefingFieldActionHandlers,
  parentDeliverableBriefingFieldInitialState,
} from "../../../../../modules/parentDeliverableBriefingFields";
import {
  parentDeliverableSourceFieldActionHandlers,
  parentDeliverableSourceFieldInitialState,
} from "../../../../../modules/parentDeliverableSourceFields";
import {
  deliverableActionHandlers,
  deliverableInitialState,
} from "../../../../../modules/deliverables";
import {
  sourceFieldActionHandlers,
  sourceFieldInitialState,
} from "../../../../../modules/sourceFields";

export default {
  Component: BulkUploads,
  reducers: [
    {
      key: "parentAccounts",
      actionHandlers: parentAccountActionHandlers,
      initialState: parentAccountInitialState,
    },
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
    {
      key: "projectLanguages",
      actionHandlers: projectLanguageActionHandlers,
      initialState: projectLanguageInitialState,
    },
    {
      key: "briefingFields",
      actionHandlers: briefingFieldActionHandlers,
      initialState: briefingFieldInitialState,
    },
    {
      key: "sourceFields",
      actionHandlers: sourceFieldActionHandlers,
      initialState: sourceFieldInitialState,
    },
    {
      key: "keywordGroups",
      actionHandlers: keywordGroupActionHandlers,
      initialState: keywordGroupInitialState,
    },
    {
      key: "parentDeliverables",
      actionHandlers: parentDeliverableActionHandlers,
      initialState: parentDeliverableInitialState,
    },
    {
      key: "batches",
      actionHandlers: batchActionHandlers,
      initialState: batchInitialState,
    },
    {
      key: "parentDeliverableBriefingFields",
      actionHandlers: parentDeliverableBriefingFieldActionHandlers,
      initialState: parentDeliverableBriefingFieldInitialState,
    },
    {
      key: "keywords",
      actionHandlers: keywordActionHandlers,
      initialState: keywordInitialState,
    },
    {
      key: "deliverables",
      actionHandlers: deliverableActionHandlers,
      initialState: deliverableInitialState,
    },
    {
      key: "parentDeliverableSourceFields",
      actionHandlers: parentDeliverableSourceFieldActionHandlers,
      initialState: parentDeliverableSourceFieldInitialState,
    },
  ],
};
