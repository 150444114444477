import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const sourceFieldsByProjectIdQuery = `sourceFields (projectId: $projectId) {
  sourceFieldId, taskFieldId, projectId, taskFieldName, taskFieldPosition, taskFieldFormat
}`;

export const sourceFieldsByAssignmentIdQuery = `sourceFields (assignmentId: $assignmentId) {
  sourceFieldId, taskFieldId, projectId, taskFieldName, taskFieldPosition, taskFieldFormat
}`;

export const sourceFieldsByDeliverableIdsQuery = `sourceFields (deliverableIds: $deliverableId) {
  sourceFieldId, taskFieldId, projectId, taskFieldName, taskFieldPosition, taskFieldFormat
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_SOURCE_FIELDS_SUCCESS = "FETCH_SOURCE_FIELDS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchSourceFieldsSuccess = createAction(
  FETCH_SOURCE_FIELDS_SUCCESS
);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const sourceFieldActionHandlers = {
  [RESET_INITIAL_STATE]: () => sourceFieldInitialState,
  [FETCH_SOURCE_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "sourceFieldId"),
};

export const sourceFieldInitialState = { entities: {}, result: [] };
