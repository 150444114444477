import React from "react";
import PropTypes from "prop-types";
import PersonForm from "../../../../../components/PersonForm";
import PageHeader from "../../../../../components/PageHeader";

const ProfileEdit = (props) => {
  const { person } = props;
  const fullName = `${person.firstName} ${person.lastName}`;

  return (
    <div>
      <PageHeader title={fullName} />
      <PersonForm
        initialValues={props.person}
        header="Edit profile"
        enableReinitialize
        freelancerProfile
        onSubmit={props.updateProfile}
        cancelUrl={`/freelancer/profile`}
      />
    </div>
  );
};
ProfileEdit.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  person: PropTypes.shape({
    personId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    payPalEmail: PropTypes.string,
    vatNumber: PropTypes.string,
    ukResident: PropTypes.bool,
    vatRegistered: PropTypes.bool,
    companyRegNumber: PropTypes.string,
    businessName: PropTypes.string,
    bankDetails: PropTypes.string,
  }).isRequired,
};

export default ProfileEdit;
