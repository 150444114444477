import { createAssignmentsSuccess } from "../../../../../../../modules/assignments";
import { updateDeliverablesSuccess } from "../../../../../../../modules/deliverables";
import { convertDate, formatDateObject } from "../../../../../../../utils/date";
import { postGraphQL } from "../../../../../../../../utils/fetch";
import handleErrors from "../../../../../../../utils/handleErrors";
import { get } from "lodash";

const AI_WORKFLOW_NAMES = [
  "AI Creation (1 Client Review)",
  "AI Creation (2 Client Reviews)",
  "AI / AMT Localisation (1 Client Review)",
];

function getRate({
  deliverableRatesMap,
  quotedRates = {},
  deliverableId,
  personId,
  stageId,
  langCode,
  language,
}) {
  // first see if a quoted rate exists
  const { rateBandId } = deliverableRatesMap[deliverableId] || {};
  const quotedRate = get(quotedRates, [
    langCode,
    stageId,
    personId,
    rateBandId,
  ]);
  if (quotedRate) {
    return quotedRate;
  }

  // fallback to rate band rate
  return get(
    deliverableRatesMap,
    [deliverableId, stageId, langCode],
    language[personId].rate
  );
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function adjustShuffle(currentStagePeople, previousStagePeople) {
  for (let i = 0; i < currentStagePeople.length; i++) {
    if (currentStagePeople[i][0] === previousStagePeople[i]) {
      const swapIndex = (i + 1) % currentStagePeople.length;
      [currentStagePeople[i], currentStagePeople[swapIndex]] = [
        currentStagePeople[swapIndex],
        currentStagePeople[i],
      ];
    }
  }
  return currentStagePeople;
}

export function createAssignments(data, history, params, options) {
  const { assignees, deadlines, filteredProjectLanguages, randomised } = data;
  const { deliverableIds, stages, deliverableRatesMap, quotedRates } = options;
  const { projectId } = params;

  const assignmentsTree = {};

  let previousOrder = {};

  stages.forEach((stage, index) => {
    const stageId = stage.stageId;
    const isTrainingStage = stage.stageType === "Training";

    assignmentsTree[stageId] = {
      assignments: [],
      trainingDeliverableIds: [],
    };

    Object.keys(assignees?.[stageId] || [])
      .filter((langCode) => filteredProjectLanguages.has(langCode))
      .forEach((langCode) => {
        const people = assignees[stageId][langCode];
        const availableDeliverables = [...deliverableIds.unallocated[langCode]];

        let peopleEntries = Object.entries(people);

        if (randomised[stageId]) {
          peopleEntries = shuffleArray(peopleEntries);
          // Avoid placing people at the same index as in previous stage
          if (previousOrder[langCode]) {
            peopleEntries = adjustShuffle(
              peopleEntries,
              previousOrder[langCode]
            );
          }
          // Save current order for next stage
          previousOrder[langCode] = peopleEntries.map(([personId]) => personId);
        } else if (previousOrder[langCode]) {
          peopleEntries = peopleEntries.sort((a, b) => {
            const aIndex = previousOrder[langCode].indexOf(a[0]);
            const bIndex = previousOrder[langCode].indexOf(b[0]);
            return aIndex - bIndex;
          });

          previousOrder[langCode] = previousOrder[langCode];
        } else {
          previousOrder[langCode] = peopleEntries.map(([personId]) => personId);
        }

        for (const [personId, personData] of peopleEntries) {
          const { training, allocation } = personData;

          for (let i = 0; i < allocation; i++) {
            let deliverableId;
            if (isTrainingStage) {
              deliverableId =
                assignmentsTree[
                  stages[index - 1].stageId
                ].trainingDeliverableIds.shift();
            } else {
              deliverableId = availableDeliverables.shift();
            }

            const rate = getRate({
              deliverableRatesMap,
              deliverableId,
              quotedRates,
              personId,
              stageId,
              langCode,
              language: people,
            });

            assignmentsTree[stageId].assignments.push({
              deliverableId,
              personId,
              rate,
              stageId,
              deadline: formatDateObject(convertDate(deadlines[stageId], true)),
              inTraining: training,
              languageCode: langCode,
            });

            if (!isTrainingStage && training) {
              assignmentsTree[stageId].trainingDeliverableIds.push(
                deliverableId
              );
            }
          }
        }
      });
  });

  const stageIds = Object.keys(assignmentsTree);
  const assignments = stageIds.reduce((acc, key) => {
    acc.push(...assignmentsTree[key].assignments);
    return acc;
  }, []);

  return async (dispatch, getState) => {
    const state = getState();
    const { workflowId } = state.projects.entities[projectId] || {};
    const { workflowName, workflowType } =
      state.workflows.entities[workflowId] || {};

    const query = `mutation createAssignments($assignments: [AssignmentInput]) {
      createAssignments(assignments: $assignments) {
        assignmentId, stageId, personId, deliverableId, rate, deadline, status
      }
    }`;

    try {
      const json = await postGraphQL(
        query,
        { assignments },
        "createAssignments"
      );
      dispatch(createAssignmentsSuccess(json));

      const tree = assignments.reduce((acc, { deliverableId }) => {
        acc[deliverableId] = true;
        return acc;
      }, {});
      const deliverableIds = Object.keys(tree);

      const allocatedDeliverables = deliverableIds.map((deliverableId) => ({
        deliverableId: Number(deliverableId),
        allocated: true,
      }));
      dispatch(updateDeliverablesSuccess(allocatedDeliverables));

      if (AI_WORKFLOW_NAMES.includes(workflowName)) {
        const newQuery = `mutation generateContent($deliverableIds: [Int!], $workflowType: String) {
          generateContent(deliverableIds: $deliverableIds, workflowType: $workflowType)
        }`;
        const json = await postGraphQL(
          newQuery,
          { deliverableIds, workflowType },
          "generateContent"
        );
        dispatch(createAssignmentsSuccess(json));
      }

      const url = `/admin/projects/${projectId}`;
      history.push(url);
    } catch (err) {
      handleErrors(err);
    }
  };
}
