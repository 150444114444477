import { FETCH_CONSTANT_DATA_SUCCESS } from "../store/constantData";

export const stageCommentGroupActionHandlers = {
  [FETCH_CONSTANT_DATA_SUCCESS]: (state, { payload }) =>
    payload.stageCommentGroups,
};

// ------------------------------------
// Action Handlers
// ------------------------------------

export const initialState = [];

const reducer = (state = initialState, action) => {
  const handleFn = stageCommentGroupActionHandlers[action.type];
  return handleFn ? handleFn(state, action) : state;
};
export default reducer;
