import { createAction } from "redux-actions";
import handleErrors from "../utils/handleErrors";
import { postGraphQL } from "../../utils/fetch";
import { upsertData, removeData } from "../utils/normalize";
import { projectDefaultAssigneesSelector } from "../utils/entitySelector";
import { RESET_INITIAL_STATE } from "./me";
// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const defaultAssigneesByProjectIdQuery = `defaultAssignees(projectIds: $projectId) {
  defaultAssigneeId, projectId, personId, languageCode, stageId
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_DEFAULT_ASSIGNEES_SUCCESS =
  "FETCH_DEFAULT_ASSIGNEES_SUCCESS";
export const CREATE_DEFAULT_ASSIGNEES_SUCCESS =
  "CREATE_DEFAULT_ASSIGNEES_SUCCESS";
export const REMOVE_DEFAULT_ASSIGNEES_SUCCESS =
  "REMOVE_DEFAULT_ASSIGNEES_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchDefaultAssigneesSuccess = createAction(
  FETCH_DEFAULT_ASSIGNEES_SUCCESS
);
export const createDefaultAssigneesSuccess = createAction(
  CREATE_DEFAULT_ASSIGNEES_SUCCESS
);
export const removeDefaultAssigneesSuccess = createAction(
  REMOVE_DEFAULT_ASSIGNEES_SUCCESS
);

export function updateDefaultAssignees(data, history, params) {
  const defaultAssignees = [];
  const { projectId } = params;

  Object.keys(data).forEach((languageCode) => {
    Object.keys(data[languageCode]).forEach((stageId) => {
      data[languageCode][stageId].map((personId) => {
        defaultAssignees.push({ personId, projectId, languageCode, stageId });
        return personId;
      });
    });
  });

  return (dispatch, getState) => {
    const query = `mutation updateDefaultAssignees($associations: [DefaultAssigneeInput], $projectId: Int){
      updateDefaultAssignees(associations: $associations, projectId: $projectId) {
        defaultAssigneeId, personId, stageId, projectId, languageCode
      }
    }`;

    return postGraphQL(
      query,
      { projectId, associations: defaultAssignees },
      "updateDefaultAssignees"
    )
      .then((json) => {
        const oldDefaultAssignees = projectDefaultAssigneesSelector(
          getState(),
          Number(projectId)
        );
        dispatch(createDefaultAssigneesSuccess(json));
        dispatch(removeDefaultAssigneesSuccess(oldDefaultAssignees));

        history.push(`/admin/projects/${projectId}/briefing`);
      })
      .catch((err) => handleErrors(err));
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
export const defaultAssigneeActionHandlers = {
  [RESET_INITIAL_STATE]: () => defaultAssigneeInitialState,
  [FETCH_DEFAULT_ASSIGNEES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "defaultAssigneeId"),
  [CREATE_DEFAULT_ASSIGNEES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "defaultAssigneeId"),
  [REMOVE_DEFAULT_ASSIGNEES_SUCCESS]: (state, { payload }) => {
    return payload.reduce(
      (acc, { defaultAssigneeId }) => removeData(acc, defaultAssigneeId),
      state
    );
  },
};

export const defaultAssigneeInitialState = { entities: {}, result: [] };
