import {
  AdminLayout,
  AdminVersionHistory,
  ParentAccountsList,
  ProjectsNew,
  ProjectsInfo,
  ProjectsShowLayout,
  ProjectsShowBriefingV2,
  ProjectsShowInProductionV2,
  ProjectsShowCompletedV2,
  ProjectsBannedWords,
  ProjectsBulkUploads,
  ProjectsEdit,
  ProjectsExportFormat,
  ProjectsFields,
  ProjectsRatesV1,
  ProjectsRatesV2,
  ProjectsQuotesV1,
  ProjectBatches,
  ProjectsDefaultAssignees,
  ProjectsAssignmentsNew,
  ProjectsAssignmentsList,
  ProjectsAssignmentsShowV2,
  ProjectsWithClientV2,
  ProjectsClientBatchStage,
  ProjectsClientDeliverableV2,
  ParentDeliverablesNew,
  ParentDeliverablesEdit,
  PeopleNew,
  PeopleListV2,
  PeopleShowV2,
  PeopleEdit,
  PaymentList,
  PaymentsNew,
  PaymentsSearch,
  PaymentsShow,
  PaymentsEdit,
  FreelancerAssignments,
  ClientLayout,
  ClientAccounts,
  ClientBatchStage,
  ClientProjectInfo,
  ClientDeliverableV2,
  ClientVersionHistory,
  UnauthorizedLayout,
  Login,
  Update,
  Reset,
  Contact,
  FreelancerLayout,
  FreelancerPaymentLayout,
  FreelancerPaymentList,
  FreelancerDashboardLayout,
  FreelancerDashboardInProgress,
  FreelancerDashboardApproved,
  FreelancerProfileShow,
  FreelancerProfileEdit,
  FreelancerTasksV2,
  FreelancerProjects,
  FreelancerVersionHistory,
  Search,
} from "./bundles";

import { createSelector } from "reselect";
import { navigableAssignmentsSelector } from "../utils/entitySelector";

import adminRoutes from "./Admin/routes";
import clientRoutes from "./Client/routes";
import freelancerRoutes from "./Freelancer/routes";

/* eslint-disable max-len */

/**
 * @param   {Object.<string, boolean>} featureToggles active feature toggles
 * @returns {Object} route definitions
 */
export default (featureToggles = {}) => [
  {
    component: AdminLayout,
    path: "/admin",
    routes: [
      ...adminRoutes(featureToggles),
      { component: ParentAccountsList, path: "/admin", exact: true },
      { component: Search, path: "/admin/search", exact: true },
      {
        component: ProjectsNew,
        path: "/admin/order-forms/:orderFormId/projects/new",
        exact: true,
      },
      {
        component: ProjectsInfo,
        path: "/admin/projects/:projectId/project-info",
        exact: true,
      },
      {
        component: ProjectsBannedWords,
        path: "/admin/projects/:projectId/banned-words",
        exact: true,
      },
      {
        component: ProjectsEdit,
        path: "/admin/projects/:projectId/edit",
        exact: true,
      },
      {
        component: featureToggles.QCC_1081_rateBands
          ? ProjectsRatesV2
          : ProjectsRatesV1,
        path: "/admin/projects/:projectId/rates",
        exact: true,
      },
      {
        component: ProjectsQuotesV1,
        path: "/admin/projects/:projectId/quotes",
        exact: true,
      },
      {
        component: ProjectBatches,
        path: "/admin/projects/:projectId/project-batches",
        exact: true,
      },
      {
        component: ProjectsExportFormat,
        path: "/admin/projects/:projectId/export-format",
        exact: true,
      },
      {
        component: ProjectsFields,
        path: "/admin/projects/:projectId/fields",
        exact: true,
      },
      {
        component: ProjectsDefaultAssignees,
        path: "/admin/projects/:projectId/default-assignees",
        exact: true,
      },
      {
        component: ProjectsAssignmentsNew,
        path: "/admin/projects/:projectId/assignments/new",
        exact: true,
      },
      {
        component: ProjectsAssignmentsList,
        path: "/admin/projects/:projectId/assignments/:assignmentGroupId",
        exact: true,
      },
      {
        component: ProjectsAssignmentsShowV2,
        path: "/admin/projects/:projectId/assignments/:assignmentGroupId/tasks/:assignmentId",
        exact: true,
      },
      {
        component: ProjectsBulkUploads,
        path: "/admin/projects/:projectId/bulk-uploads",
        exact: true,
      },
      {
        component: ParentDeliverablesNew,
        path: "/admin/projects/:projectId/parent-deliverables/new",
        exact: true,
      },
      {
        component: ParentDeliverablesEdit,
        path: getParentDeliverableEditUrl(":projectId", ":parentDeliverableId"),
        exact: true,
      },
      {
        component: AdminVersionHistory,
        path: "/admin/deliverable/:deliverableId/version-history",
        exact: true,
      },
      {
        component: ProjectsClientBatchStage,
        path: "/admin/projects/:projectId/batches/:batchId/stages/:stageId",
        exact: true,
      },
      {
        component: ProjectsClientDeliverableV2,
        path: "/admin/projects/:projectId/batches/:batchId/stages/:stageId/deliverables/:deliverableId",
        exact: true,
      },
      {
        component: ProjectsShowLayout,
        path: "/admin/projects/:projectId",
        routes: [
          {
            component: ProjectsShowInProductionV2,
            path: "/admin/projects/:projectId",
            exact: true,
          },
          {
            component: ProjectsShowBriefingV2,
            path: "/admin/projects/:projectId/briefing",
            exact: true,
          },
          {
            component: ProjectsShowCompletedV2,
            path: "/admin/projects/:projectId/completed",
            exact: true,
          },
          {
            component: ProjectsWithClientV2,
            path: "/admin/projects/:projectId/with-client",
            exact: true,
          },
        ],
      },
      {
        component: PeopleListV2,
        path: "/admin/people/list/:activeTab",
        exact: true,
      },
      { component: PeopleNew, path: "/admin/people/new", exact: true },
      {
        component: PeopleShowV2,
        path: "/admin/people/:personId",
        exact: true,
      },
      {
        component: PeopleEdit,
        path: "/admin/people/:personId/edit",
        exact: true,
      },
      { component: PaymentList, path: "/admin/payments", exact: true },
      { component: PaymentsNew, path: "/admin/payments/new", exact: true },
      {
        component: PaymentsSearch,
        path: "/admin/payments/search",
        exact: true,
      },
      {
        component: PaymentsShow,
        path: "/admin/payments/:paymentId",
        exact: true,
      },
      {
        component: PaymentsEdit,
        path: "/admin/payments/:paymentId/edit",
        exact: true,
      },
    ],
  },
  {
    component: ClientLayout,
    path: "/clients",
    routes: [
      { component: ClientAccounts, path: "/clients", exact: true },
      {
        component: ClientBatchStage,
        path: "/clients/projects/:projectId/batches/:batchId/stages/:stageId",
        exact: true,
      },
      {
        component: ClientProjectInfo,
        path: "/clients/projects/:projectId/project-info",
        exact: true,
      },
      {
        component: ClientDeliverableV2,
        path: "/clients/projects/:projectId/batches/:batchId/stages/:stageId/deliverables/:deliverableId",
        exact: true,
      },
      {
        component: ClientVersionHistory,
        path: "/clients/deliverable/:deliverableId/stage/:stageId/version-history",
        exact: true,
      },
      ...clientRoutes(featureToggles),
    ],
  },
  {
    component: FreelancerLayout,
    path: "/freelancer",
    routes: [
      ...freelancerRoutes(featureToggles),
      {
        component: FreelancerAssignments,
        path: "/freelancer/projects/:projectId/assignments/:assignmentGroupId",
        exact: true,
      },
      {
        component: FreelancerTasksV2,
        path: "/freelancer/projects/:projectId/assignments/:assignmentGroupId/tasks/:assignmentId",
        exact: true,
      },

      // Old paths
      {
        component: FreelancerAssignments,
        path: "/freelancer/assignments/:assignmentGroupId",
        exact: true,
      },
      {
        component: FreelancerTasksV2,
        path: "/freelancer/assignments/:assignmentGroupId/tasks/:assignmentId",
        exact: true,
      },
      {
        component: FreelancerVersionHistory,
        path: "/freelancer/deliverable/:deliverableId/stage/:stageId/version-history",
        exact: true,
      },
      {
        component: FreelancerProjects,
        path: "/freelancer/projects/:projectId/project-info",
        exact: true,
      },
      {
        component: featureToggles.freelancerPayments
          ? FreelancerPaymentLayout
          : UnauthorizedLayout,
        path: "/freelancer/payments",
        routes: [
          {
            component: FreelancerPaymentList,
            path: "/freelancer/payments",
            exact: true,
          },
        ],
      },
      {
        component: featureToggles.freelancerProfile
          ? FreelancerProfileShow
          : UnauthorizedLayout,
        path: "/freelancer/profile",
        exact: true,
      },
      {
        component: featureToggles.freelancerProfile
          ? FreelancerProfileEdit
          : UnauthorizedLayout,
        path: "/freelancer/profile/edit",
        exact: true,
      },
      {
        component: FreelancerDashboardLayout,
        path: "/freelancer",
        routes: [
          {
            component: FreelancerDashboardInProgress,
            path: "/freelancer",
            exact: true,
          },
          {
            component: FreelancerDashboardApproved,
            path: "/freelancer/approved",
            exact: true,
          },
        ],
      },
    ],
  },
  {
    component: UnauthorizedLayout,
    path: "/",
    routes: [
      { path: "/", component: Login, exact: true },
      { path: "/reset", component: Reset, exact: true },
      { path: "/update", component: Update, exact: true },
      { path: "/contact", component: Contact, exact: true },
    ],
  },
];

/**
 * @param {number|string}  projectId
 * @param {number|string}  parentDeliverableId
 *
 * @returns {string}
 */
export const getParentDeliverableEditUrl = (projectId, parentDeliverableId) =>
  `/admin/projects/${projectId}/parent-deliverables/${parentDeliverableId}/edit`;

/**
 * Returns the peopleList url
 *
 * @param {object} [featureToggles = {}]  the current feature toggle values
 * @param {string} [personType = Freelancer] the person type list to show (Freelancer,
 *  Client, In-House)
 * @param {enum} [archived = false] To check the person is in archived or not
 */
export const getPeopleListUrl = (
  featureToggles = {},
  personType = "Freelancer",
  archived = false
) => {
  let peopleListTab;

  if (archived) {
    peopleListTab = "archived";
  } else {
    peopleListTab =
      personType === "In-house" ? "in-house" : personType.toLowerCase() + "s";
  }
  return `/admin/people/list/${peopleListTab}`;
};

/**
 * Generate a url for the given assignment
 *
 * @param   {string}  groupUrl    url of the assignment group
 * @param   {Object}  assignment
 * @returns {string}  url
 */
function getAssignmentUrl(groupUrl, { assignmentId }) {
  return `${groupUrl}/tasks/${assignmentId}`;
}

/**
 * Get the urls for the next and previous assignments
 *
 * @param {Object} state
 * @param {string} groupUrl
 * @param {Object} assignment
 *
 * @returns {Object} object containing 'nextUrl' and 'previousUrl' url strings
 */
export const adjacentAssignmentUrlsSelector = createSelector(
  (_, groupUrl) => groupUrl,
  (_a, _b, assignment) => assignment,
  (state, _, assignment) => navigableAssignmentsSelector(state, assignment),
  (groupUrl, assignment, assignments) => {
    const assignmentIndex = assignments.findIndex(
      ({ assignmentId }) => assignmentId === assignment.assignmentId
    );

    const previousAssignment = assignments[assignmentIndex - 1];
    const nextAssignment = assignments[assignmentIndex + 1];

    return {
      nextUrl: nextAssignment && getAssignmentUrl(groupUrl, nextAssignment),
      previousUrl:
        previousAssignment && getAssignmentUrl(groupUrl, previousAssignment),
    };
  }
);

/* eslint-enable max-len */
