import styles from "./KeywordGroupsArray.module.scss";
import WithEnterFieldArrayInput from "../../../../../../components/WithEnterFieldArrayInput";
import renderFieldArray from "../RenderFieldArray";

const KeywordLabel = (props) => {
  return (
    <div className={styles.container}>
      <label
        key={props.languageCode}
        className={styles.quillHeader}
        htmlFor="briefing"
      >
        <b>
          {props.languageCode} {props.isSource && "(Source)"}
        </b>
      </label>
    </div>
  );
};

const KeywordGroups = (props) => {
  return (
    <WithEnterFieldArrayInput
      key={props.languageCode}
      addField={props.addField}
      arrayName={props.name}
      fieldName={props.languageCode}
      placeholder={props.placeholder}
      selectedLanguages={props.selectedLanguages}
      type="text"
    />
  );
};

const KeywordGroupsArray = renderFieldArray(KeywordLabel, KeywordGroups);

export default KeywordGroupsArray;
