import React, { useCallback, useState, useEffect } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { hideMessages } from "../../../modules/messagesV2";
import styles from "./Messages.module.scss";

const TRANSITION_DURATION = 500;
const VISIBLE_DURATION = 3000;
const DURATION = VISIBLE_DURATION + TRANSITION_DURATION;

/**
 * Get an array of messages either from the passed in array or extracted
 * from a single string by newline characters
 *
 * @param {string|string[]} message
 *
 * @returns {string[]} array of messages to show
 */
function extractMessages(message) {
  if (!message) return [];

  if (Array.isArray(message)) return message;

  return [message];
}

function Messages({ display, type, message, hideMessages }) {
  const [visible, setVisible] = useState(false);

  const hide = useCallback(() => {
    setTimeout(() => {
      hideMessages();
    }, DURATION);
  }, [hideMessages]);

  useEffect(() => {
    if (!display && visible) {
      setVisible(false);
    }

    if (display && !visible) {
      setVisible(true);
      hide();
    }
  }, [display, hide, hideMessages, visible]);

  const messageTypeStyles = classNames({
    [styles.success]: type === "TYPE_SUCCESS",
    [styles.warning]: type === "TYPE_WARNING",
    [styles.error]: type === "TYPE_ERROR",
  });

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.inView]: visible,
    [messageTypeStyles]: true,
  });

  const messages = extractMessages(message);

  return (
    <div className={containerStyles}>
      <div className={styles.dismissContainer} onClick={hideMessages}>
        <div className={styles.dismiss}>
          <svg height="16" width="16">
            <path
              d="M16 13l-5-5 5-5V2l-2-2h-1L8 5 3 0H2L0 2v1l5 5-5 5v1l2 2h1l5-5 5 5h1l2-2v-1z"
              fill="#F9F9F9"
            />
          </svg>
        </div>
      </div>

      {messages.map((message) => (
        <div key={message}>{message}</div>
      ))}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  hideMessages: () => dispatch(hideMessages()),
});

const mapStateToProps = (state) => ({
  ...state.messagesV2,
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
