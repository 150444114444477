import { connect } from "react-redux";

import ClientLayout from "../components/ClientLayout";
import { logoutFetch } from "../../../../modules/me";

export const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => dispatch(logoutFetch()),
});

export const mapStateToProps = (state, ownProps) => ({
  me: state.people.entities[state.me],
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientLayout);
