import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, FieldArray } from "redux-form";
import Input from "../../../../../../../../components/Input";
import styles from "./RateBandsForm.module.scss";
import {
  AddButton,
  RemoveButton,
} from "../../../../../../../../components/Buttons";
import { QuillForm } from "../../../../../../../../components/QuillForm";
import validate from "./validations";

class RenderRateBands extends Component {
  addField = () => this.props.fields.push({ rateBandName: "" });

  componentDidMount() {
    // when the modal is opened we should add a blank row for the next rate band
    this.addField();
  }

  render() {
    const { fields, meta } = this.props;
    return (
      <div>
        {fields.map((member, index) => {
          const lastInput = index === fields.length - 1;
          return (
            <div key={index} className={styles.rateBandContainer}>
              <Field
                autoComplete="off"
                component={Input}
                name={`${member}.rateBandName`}
                onChange={lastInput ? this.addField : null}
                placeholder="Enter rate band name..."
                type="text"
              />
              {lastInput ? (
                <AddButton onClick={this.addField} styles={styles.addButton} />
              ) : (
                <RemoveButton
                  disabled={fields.length === 1}
                  onClick={() => fields.remove(index)}
                />
              )}
            </div>
          );
        })}

        {meta.error && <span>{meta.error}</span>}
        {meta.submitFailed && meta.error && <span>{meta.error}</span>}
      </div>
    );
  }
}

RenderRateBands.propTypes = {
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    name: PropTypes.string.isRequired,
    push: PropTypes.func,
  }),
  meta: PropTypes.shape({
    error: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
};

const RateBandsForm = (props) => (
  <div className={styles.rateBandsForm}>
    <QuillForm
      cancelHandler={props.hideModal}
      cancelText="Cancel"
      disabled={props.isRequesting || !props.valid}
      error={props.error}
      formContainerStyles={styles.formContainerStyles}
      handleSubmit={props.handleSubmit}
      header={"Rate bands"}
      hideRequiredHeader
      submitText="Save"
    >
      <FieldArray component={RenderRateBands} name="rateBands" />
    </QuillForm>
  </div>
);

RateBandsForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  isRequesting: PropTypes.bool,
  valid: PropTypes.bool,
};

export default reduxForm({
  form: "rateBands",
  validate,
})(RateBandsForm);
