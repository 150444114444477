import { createAction } from "redux-actions";
import handleErrors from "../utils/handleErrors";
import { postGraphQL, getGraphQL } from "../../utils/fetch";
import { upsertData, removeData } from "../utils/normalize";
import { hideModal } from "./modal";
import { RESET_INITIAL_STATE } from "./me";

export const accountFields = [
  "parentAccountId",
  "accountId",
  "accountName",
  "verticalType",
];
export const verticalTypes = Object.freeze(["Fashion", "Travel"]);

// ------------------------------------
// GraphQL Queries
// ------------------------------------
const accountsByParam = (param) =>
  `accounts (${param}: $${param}) { ${accountFields} }`;

export const accountByIdQuery = `account(accountId: $accountId) { ${accountFields} }`;
export const accountsQuery = `accounts { ${accountFields} }`;
export const accountsByPersonIdQuery = `accounts(personId: $personId) { ${accountFields} }`;
export const accountsByParentAccountIdQuery = `accounts(parentAccountIds: $parentAccountId) { ${accountFields} }`;
export const accountsByAssignmentGroupIdQuery = `accounts (assignmentGroupId: $assignmentGroupId) { ${accountFields} }`;
export const accountsByOrderFormQuery = `accounts (orderFormId: $orderFormId) { ${accountFields} }`;
export const accountsByProjectQuery = `accounts (projectId: $projectId) { ${accountFields} }`;

export const accountsByProjectIdQuery = accountsByParam("projectId");

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_ACCOUNT_SUCCESS = "FETCH_ACCOUNT_SUCCESS";
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const ARCHIVE_ACCOUNT_SUCCESS = "ARCHIVE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAccountSuccess = createAction(FETCH_ACCOUNT_SUCCESS);
export const fetchAccountsSuccess = createAction(FETCH_ACCOUNTS_SUCCESS);
export const createAccountSuccess = createAction(CREATE_ACCOUNT_SUCCESS);
export const archiveAccountSuccess = createAction(ARCHIVE_ACCOUNT_SUCCESS);
export const updateAccountSuccess = createAction(UPDATE_ACCOUNT_SUCCESS);

export function searchAccounts(input) {
  return (dispatch, getState) => {
    const query = `query searchAccounts($search: String){
      accounts(search: $search) {
        ${accountFields}
      }
    }`;

    return getGraphQL(query, { search: input }).then((json) =>
      dispatch(fetchAccountsSuccess(json.accounts))
    );
  };
}

export function createAccount(data) {
  return (dispatch, getState) => {
    const query = `mutation createAccount($input: AccountInput){
      createAccount(input: $input) {
        ${accountFields}
      }
    }`;

    return postGraphQL(query, { input: data }, "createAccount")
      .then((json) => {
        dispatch(createAccountSuccess(json));
        dispatch(hideModal());
      })
      .catch((err) => handleErrors(err));
  };
}

export function updateAccount(data) {
  return (dispatch, getState) => {
    const query = `mutation updateAccount($input: AccountInput){
      updateAccount(input: $input) {
        parentAccountId, accountId, accountName, verticalType
      }
    }`;

    return postGraphQL(query, { input: data }, "updateAccount")
      .then((json) => {
        dispatch(updateAccountSuccess(json));
        dispatch(hideModal());
      })
      .catch((err) => handleErrors(err));
  };
}

export function archiveAccount(accountId) {
  return (dispatch, getState) => {
    const query = `mutation archiveAccount($input: AccountInput){
      archiveAccount(input: $input) {
        accountId
      }
    }`;

    return postGraphQL(query, { input: { accountId } }, "archiveAccount").then(
      (json) => {
        dispatch(archiveAccountSuccess(accountId));
      }
    );
  };
}
// ------------------------------------
// Action Handlers
// ------------------------------------
export const accountActionHandlers = {
  [RESET_INITIAL_STATE]: () => accountInitialState,
  [FETCH_ACCOUNT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "accountId"),
  [FETCH_ACCOUNTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "accountId"),
  [CREATE_ACCOUNT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "accountId"),
  [UPDATE_ACCOUNT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "accountId"),
  [ARCHIVE_ACCOUNT_SUCCESS]: (state, { payload }) => removeData(state, payload),
};

export const accountInitialState = { entities: {}, result: [] };
