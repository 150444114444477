import { getGraphQL, postGraphQL } from "../../utils/fetch";
import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";

export const favouriteFields = [
  "favouriteId",
  "parentAccountId",
  "accountId",
  "orderFormId",
  "accountId",
];

export const favouriteQuery = `query favouriteQuery {
  favourites {
    favouriteId, parentAccountId, accountId, orderFormId, projectId
  } 
}`;

const FAVOURITE_SUCCESS = "FAVOURITE_SUCCESS";
export const fetchFavouritesSuccess = createAction(FAVOURITE_SUCCESS);

export const favouritesActionHandlers = {
  [RESET_INITIAL_STATE]: () => favouritesInitialState,
  [FAVOURITE_SUCCESS]: (state, { payload }) => {
    return upsertData(favouritesInitialState, payload, "favouriteId");
  },
};

export const favouritesInitialState = { entities: {}, result: [] };

export function unfavourite(object) {
  return async (dispatch, getState) => {
    const query = `mutation unfavourite ($input: FavouriteInput){
      unfavourite (input: $input) {
        parentAccountId
      }
    }`;
    try {
      await postGraphQL(query, { input: object }, "unfavourite");
      const { favourites } = await getGraphQL(favouriteQuery, {});
      dispatch(fetchFavouritesSuccess(favourites));
    } catch (err) {}
  };
}

export function favourite(object) {
  return async (dispatch, getState) => {
    const query = `mutation favourite ($input: FavouriteInput){
      favourite (input: $input) {
        parentAccountId, accountId, orderFormId, projectId
      }
    }`;
    try {
      await postGraphQL(query, { input: object }, "favourite");
      const { favourites } = await getGraphQL(favouriteQuery, {});
      dispatch(fetchFavouritesSuccess(favourites));
    } catch (err) {}
  };
}
