import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
import { createSelector } from "reselect";

// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const projectLanguagesByProjectIdQuery =
  "projectLanguages(projectIds: $projectId) { projectId, languageCode }";
// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_PROJECT_LANGUAGES_BY_PROJECT_ID_SUCCESS =
  "FETCH_PROJECT_LANGUAGES_BY_PROJECT_ID_SUCCESS";
const CREATE_PROJECT_LANGUAGES_SUCCESS = "CREATE_PROJECT_LANGUAGES_SUCCESS";
const SELECT_LANGUAGE_SUCCESS = "SELECT_LANGUAGE_SUCCESS";
const MULTI_SELECT_LANGUAGE_SUCCESS = "MULTI_SELECT_LANGUAGE_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchProjectLanguagesByProjectIdSuccess = createAction(
  FETCH_PROJECT_LANGUAGES_BY_PROJECT_ID_SUCCESS
);
export const createProjectLanguagesSuccess = createAction(
  CREATE_PROJECT_LANGUAGES_SUCCESS
);
export const selectLanguageSuccess = createAction(SELECT_LANGUAGE_SUCCESS);
export const multiSelectLanguageSuccess = createAction(
  MULTI_SELECT_LANGUAGE_SUCCESS
);

export const getProjectLangaugesByProjectId = createSelector(
  (state) => state.projectLanguages,
  (state) => state.languages.entities,
  (projectLanguages, languages) => {
    return projectLanguages.reduce((acc, { projectId, languageCode }) => {
      if (!acc[projectId]) acc[projectId] = [];

      const { languageName } = languages[languageCode];
      acc[projectId].push(languageName);
      return acc;
    }, {});
  }
);

// ------------------------------------
// Action Handlers
// ------------------------------------
// Project languages does not follow the same structures as other data because there is no projectLanguageId
// hence we store the data as is
export const projectLanguageActionHandlers = {
  [RESET_INITIAL_STATE]: () => projectLanguageInitialState,
  [FETCH_PROJECT_LANGUAGES_BY_PROJECT_ID_SUCCESS]: (state, { payload }) => {
    return payload.reduce((acc, pl) => {
      if (
        acc.some(
          (a) =>
            a.projectId === pl.projectId && a.languageCode === pl.languageCode
        )
      ) {
        return acc;
      }
      return acc.concat(pl);
    }, state);
  },
  [CREATE_PROJECT_LANGUAGES_SUCCESS]: (state, { payload }) => {
    return payload.reduce((acc, pl) => {
      if (
        acc.some(
          (a) =>
            a.projectId === pl.projectId && a.languageCode === pl.languageCode
        )
      ) {
        return acc;
      }
      return acc.concat(pl);
    }, state);
  },
  [SELECT_LANGUAGE_SUCCESS]: (state, { payload }) => {
    const newlanguages = [];
    state.forEach((lang, index) => {
      const obj = {};
      obj.languageCode = lang.languageCode;
      obj.projectId = lang.projectId;
      if (payload === lang.languageCode) {
        obj.selected = true;
      }
      newlanguages.push(obj);
    });
    return newlanguages;
  },
  [MULTI_SELECT_LANGUAGE_SUCCESS]: (state, { payload }) => {
    const newLanguages = [];
    state.forEach((lang) => {
      const obj = {};
      obj.languageCode = lang.languageCode;
      obj.projectId = lang.projectId;
      if (payload.includes(lang.languageCode)) {
        obj.selected = true;
      }
      newLanguages.push(obj);
    });
    return newLanguages;
  },
};

export const projectLanguageInitialState = [];
