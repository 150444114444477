import React from "react";
import PropTypes from "prop-types";
import { core, marginLeft } from "./FreelancerLayout.module.scss";
import Nav from "../../../../components/Nav";
import Helmet from "react-helmet";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import QuillRedirect from "../../../../components/QuillRedirect";
import MigrationMaintenanceBanner from "../../../../components/MigrationMaintenanceBanner";

export const FreelancerLayout = (props) => {
  if (!props.me) {
    return <QuillRedirect to="/" />;
  }
  if (props.me.personType === "Client") return <Redirect to="/clients" />;
  if (props.me.personType === "In-house") return <Redirect to="/admin" />;

  const navItems = [
    {
      url: "/freelancer",
      iconName: "Home",
      active: ["/freelancer", "/freelancer/approved"].includes(
        props.location.pathname
      ),
      hoverText: "Dashboard",
    },
  ];

  if (props.featureToggles.freelancerProfile) {
    navItems.push({
      url: "/freelancer/profile",
      active: props.location.pathname === "/freelancer/profile",
      iconName: "People",
      hoverText: "Profile",
    });
  }

  navItems.push({
    url: "/freelancer/payments",
    active: props.location.pathname === "/freelancer/payments",
    iconName: "Payments",
    hoverText: "Payments",
  });

  if (props.featureToggles.QCC_1658_creatorHubNavLink) {
    navItems.push({
      url: "//help.scribe.jplus.io/en/",
      iconName: "HelpdeskCreator",
      hoverText: "J+ Scribe Help Center",
    });
  }

  return (
    <div className={core}>
      <Helmet defaultTitle="J+ Scribe - Creator">
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
      </Helmet>

      <MigrationMaintenanceBanner />

      <Nav
        home="/freelancer"
        items={navItems}
        logout={props.logout}
        personType={props.me.personType}
      />
      <div className={marginLeft}>{renderRoutes(props.route.routes)}</div>
    </div>
  );
};

FreelancerLayout.propTypes = {
  featureToggles: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  me: PropTypes.shape({
    personType: PropTypes.string.isRequired,
  }),
  route: PropTypes.object.isRequired,
};

export default FreelancerLayout;
