import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./BulkUploads.module.scss";
import Helmet from "react-helmet";
import { SelectWithErrors } from "../../../../../../decorators/WithErrors";
import { reduxForm, Field } from "redux-form";
import PageHeader from "../../../../../../components/PageHeader";
import {
  QuillForm,
  QuillFormField,
} from "../../../../../../components/QuillForm";
import OverlayLoading from "../../../../../../components/OverlayLoading/v1";
import CreateBatchRow from "../../../../../../components/Buttons/CreateBatchRow";

const BulkUploads = class BulkUploads extends Component {
  state = {
    submitButton: null,
    active: !!this.props.initialValues.batchId,
    batchId: this.props.initialValues.batchId,
    errors: [],
    batchName: "",
    showLoader: false,
  };

  handleReadError = (e) => {
    this.setState({
      /* eslint-disable max-len */
      errors: [
        "There was a problem reading your file. This may be a problem with an unsupported browser or an invalid file. Please check your file and if the problem persists contact Quill.",
      ],
      /* eslint-enable max-len */
    });
  };

  handleFileRead = (e) => {
    const fileAsString = e.target.result;
    const {
      projectId,
      briefingFields,
      sourceFields,
      keywordGroups,
      projectLanguages,
      primaryStageId,
      languagesField,
      rateBands,
      featureToggles,
    } = this.props;

    const { batchId } = this.state;
    const numNewLinesRegex = fileAsString.match(/\n/g);

    // handles windows
    let replacedString = fileAsString.replace(/\r\n/g, "\n");

    if (!numNewLinesRegex) {
      replacedString = replacedString.replace(/\r/g, "\n");
    }

    const data = {
      projectId,
      briefingFields,
      sourceFields,
      keywordGroups,
      file: replacedString,
      batchId,
      projectLanguages,
      primaryStageId,
      languagesField,
      rateBands,
    };

    this.props
      .processFile(data, featureToggles)
      .then(() => this.setSuccess())
      .catch((err) => this.setErrors(err));
  };

  processFileHandler = (e) => {
    e.preventDefault();
    this.setState({ errors: [], success: null, showLoader: true });

    const file = document.getElementById("upload").files[0];

    const reader = new FileReader();
    reader.onload = this.handleFileRead;
    reader.onerror = this.handleReadError;
    reader.readAsText(file);
  };

  updateSubmitButton = (e) => {
    const file = e.currentTarget.files[0];

    const submitButton = file ? (
      <div
        key="submit-button"
        className={styles.button}
        onClick={this.processFileHandler}
      >
        Upload deliverables
      </div>
    ) : null;

    this.setState({ submitButton });
  };

  /**
   * Create a list of errors from an error object
   *
   * @param {Object|Array} errs
   */
  setErrors = (errs) => {
    this.removeErrorHandlers = {};

    let errors = [];

    try {
      // Create bound functions to remove each error message (we define them here
      // in order to prevent unnecessary re-rendering, which would occur if we
      // bound the functions inside the render method)
      if (errs instanceof Array) {
        errs.forEach((_, idx) => {
          this.removeErrorHandlers[idx] = this.removeError.bind(this, idx);
        });

        errors = errs;
      } else if (errs === Object(errs)) {
        // if the error array happens to be returned as an object just use the values as a temporary fix until we fix this up
        errors = Object.values(errs);
      }
    } catch (err) {
      // If we get an error while processing the errors we need to show
      // something rather than still show the spinning wheel
      errors.push(`An unkown error occured: ${JSON.stringify(err)}`);
    }

    this.setState({ submitButton: null, errors, showLoader: false }, () => {
      document.getElementById("upload").value = "";
    });
  };

  setSuccess = () => {
    const success = "Upload successful";
    this.setState({
      submitButton: null,
      success,
      active: null,
      showLoader: false,
    });
  };

  removeError(idx) {
    const errors = [...this.state.errors];
    errors.splice(idx, 1);
    this.setState({ errors });
  }

  updateState = (e, batchId) => {
    this.setState({ batchId, active: !!batchId });
  };

  render() {
    const {
      isLocalisation,
      project: { projectId, projectName },
    } = this.props;

    return (
      <div>
        <Helmet>
          {" "}
          <title>Project - Bulk uploads</title>{" "}
        </Helmet>
        <PageHeader
          breadCrumbItems={[
            { url: "/admin", text: "Parent accounts" },
            {
              text: `${this.props.parentAccount.parentAccountName} | ${this.props.account.accountName} | ${this.props.orderForm.orderFormNumber}`,
            },
            { url: `/admin/projects/${projectId}`, text: `${projectName}` },
            { text: "Bulk upload" },
          ]}
          title={`${projectName} - Bulk upload`}
        />
        {this.state.showLoader ? <OverlayLoading /> : null}
        <div className={styles.container}>
          <QuillForm header="Bulk upload" hideButtons rebrandingContainer>
            <CreateBatchRow projectId={projectId} />

            <QuillFormField
              component={SelectWithErrors}
              name="batchId"
              onChange={this.updateState}
              options={this.props.batches}
              type="number"
              value={this.state.batchId}
            />

            <div className={styles.successContainer}>
              {this.state.success && (
                <div className={styles.success}>
                  <span>{this.state.success}</span>
                </div>
              )}
            </div>
            <div className={styles.errorContainer}>
              {this.state.errors.map((e, idx) => (
                <div key={idx} className={styles.error}>
                  <span
                    className={styles.remove}
                    onClick={this.removeErrorHandlers[idx]}
                  >
                    <svg
                      height="16"
                      version="1.1"
                      viewBox="0 0 16 16"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* eslint-disable max-len */}
                      <path d="M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z" />
                      {/* eslint-enable max-len */}
                    </svg>
                  </span>
                  <span>{this.state.errors[idx]}</span>
                </div>
              ))}
            </div>
            {this.props.featureToggles.QCC_1077_selectLanguage && (
              <div>
                <label className={styles.languagesLabel}>LANGUAGES</label>
                <p className={styles.batchSubText}>
                  For {isLocalisation ? "localisation" : "creation"} into:
                </p>
                <Field
                  name="languages"
                  component={SelectWithErrors}
                  options={this.props.projectLanguagesNames}
                  multi
                />
              </div>
            )}
            <div>
              <div className={`${styles.row} ${styles.right}`}>
                <button
                  className={styles.secondaryButton}
                  onClick={this.props.downloadCSVTemplate}
                >
                  Download template
                </button>
                {this.state.active && [
                  <input
                    key="input"
                    accept=".csv"
                    className={`${styles.greyscaleButton} ${styles.padding} ${styles.marginLeft}`}
                    id="upload"
                    onChange={this.updateSubmitButton}
                    type="file"
                  />,
                  this.state.submitButton,
                ]}
              </div>
            </div>
          </QuillForm>
        </div>
      </div>
    );
  }
};

BulkUploads.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  batches: PropTypes.array,
  briefingFields: PropTypes.array,
  downloadCSVTemplate: PropTypes.func,
  featureToggles: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({
    batchId: PropTypes.number,
  }),
  isLocalisation: PropTypes.bool.isRequired,
  keywordGroups: PropTypes.array,
  languagesField: PropTypes.object,
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number,
  }),
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string,
  }),
  primaryStageId: PropTypes.number,
  processFile: PropTypes.func,
  project: PropTypes.object,
  projectId: PropTypes.number,
  projectLanguages: PropTypes.array,
  rateBands: PropTypes.array,
  sourceFields: PropTypes.array,
};

export default reduxForm({
  form: "bulkUploadsForm",
  enableReinitialize: true,
})(BulkUploads);
