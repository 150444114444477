import { getGraphQL } from "../../../../../utils/fetch";
import parseQuery from "../../../../utils/parseQuery";
import {
  searchByAccountIdAndSearchTerm,
  fetchSearchSuccess,
} from "../../../../modules/search";
import {
  accountByIdQuery,
  fetchAccountSuccess,
} from "../../../../modules/accounts";

export const searchQuery = `query batchSearch ($accountId: Int, $searchTerm: String) {
  ${searchByAccountIdAndSearchTerm}
}`;

export const accountQuery = `query accountById ($accountId: Int) {
  ${accountByIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    if (!params.location) return Promise.resolve();
    const searchParams = parseQuery(params.location.search);

    const { account } = await getGraphQL(
      accountQuery,
      searchParams,
      url,
      cookies
    );
    dispatch(fetchAccountSuccess(account));

    const { batchSearch } = await getGraphQL(
      searchQuery,
      searchParams,
      url,
      cookies
    );
    dispatch(fetchSearchSuccess(batchSearch));
  };
}
