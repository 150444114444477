import React from "react";
import PropTypes from "prop-types";
import styles from "./ButtonTemplate.module.scss";
import classNames from "classnames";

const buttonTemplate = ({ buttonText, btnStyles }) => {
  const ButtonTemplate = (props) => {
    const buttonStyles = classNames({
      [styles.button]: true,
      [props.styles]: props.styles,
      [btnStyles]: btnStyles,
    });

    return (
      <button
        type="button"
        disabled={props.disabled}
        className={buttonStyles}
        onClick={props.onClick}
      >
        {buttonText}
      </button>
    );
  };

  ButtonTemplate.propTypes = {
    styles: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };

  return ButtonTemplate;
};

const AddButton = buttonTemplate({ buttonText: "+", btnStyles: styles.add });
const RemoveButton = buttonTemplate({
  buttonText: "−",
  btnStyles: styles.remove,
});

export { AddButton, RemoveButton };
