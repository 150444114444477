const removeHTML = (html) => {
  let strippedHtml = html;
  if (html && typeof html === "string") {
    strippedHtml = html.replace(/<[^>]+>/g, "");
    return strippedHtml;
  }
  return html;
};

export default removeHTML;
