import { useEffect, useState } from "react";
import styles from "./ColorPickerDropDown.module.scss";

export const colors = [
  { hex: "#4E575E", label: "Default" },
  { hex: "#FFFFFF", label: "White" },
  { hex: "#000000", label: "Black" },
  { hex: "#FF0000", label: "Red" },
  { hex: "#FFFF00", label: "Yellow" },
  { hex: "#008000", label: "Green" },
  { hex: "#0000FF", label: "Blue" },
  { hex: "#FF00FF", label: "Fuchsia" },
];

export const colorInlineStyles = colors.reduce((styles, color) => {
  if (color.label !== "Default") {
    styles[`COLOR_${color.hex}`] = {
      style: { color: color.hex },
    };
  }
  return styles;
}, {});

const ColorPickerDropdown = ({ onColorSelect, editorState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(colors[0].hex); // Default color

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const currentStyle = editorState?.getCurrentInlineStyle();
    const colorStyle =
      currentStyle?.filter((style) => style.startsWith("COLOR_")).first() ||
      null;
    const color = colorStyle
      ? colorStyle.slice(6).toLowerCase()
      : colors[0].hex;

    setSelectedColor(color);
  }, [editorState]);

  const handleColorSelect = (event, color) => {
    event.preventDefault();
    setSelectedColor(color);
    onColorSelect(color);
  };

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div onMouseDown={toggleDropdown} className={styles.buttonContainer}>
      <span className={styles.buttonBase}>
        <svg
          stroke={selectedColor === "#FFFFFF" ? "#4e575e" : "none"} // Conditional stroke color
          strokeWidth={selectedColor === "#FFFFFF" ? "0.5" : "0"}
          fill={selectedColor}
          viewBox="0 0 24 24"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 18h14v3H5zm7.5-14h-1c-.401 0-.764.24-.921.609L5.745 16h2.173l1.273-3h5.604l1.268 3h2.171L13.421 4.61A1 1 0 0 0 12.5 4zm-2.46 7 1.959-4.616L13.95 11h-3.91z" />
        </svg>
      </span>
      {isOpen && (
        <div className={styles.dropdown} onClick={stopPropagation}>
          {colors.map((color) => (
            <div
              key={color.hex}
              className={styles.colorSwatch}
              style={{
                backgroundColor: color.hex,
                border: color.label === "White" ? "1px solid #ddd" : null,
              }}
              onMouseDown={(e) => handleColorSelect(e, color.hex)}
              title={`${color.label} - ${color.hex}`} // Tooltip
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorPickerDropdown;
