import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { convertDateToLocal, formatDateObject } from "../../utils/date";
import styles from "./LocalTime.module.scss";

export default function LocalTime({ isDeadline, small, utcDateString }) {
  const localTime = convertDateToLocal(utcDateString);
  const overdue = isDeadline && new Date() > localTime;

  const deadlineStyles = classNames({
    [styles.small]: small,
    [styles.overdue]: overdue,
  });

  return (
    <span className={deadlineStyles}>
      {formatDateObject(localTime).substring(0, 16).replace(/-/g, "\u2011")}
    </span>
  );
}

LocalTime.propTypes = {
  isDeadline: PropTypes.bool,
  small: PropTypes.bool,
  utcDateString: PropTypes.string.isRequired,
};
