import FreelancerLayout from "./containers/FreelancerLayout";
import { meActionHandlers, meInitialState } from "../../../modules/me";
import {
  peopleActionHandlers,
  peopleInitialState,
} from "../../../modules/people";

export default {
  Component: FreelancerLayout,
  reducers: [
    {
      key: "me",
      actionHandlers: meActionHandlers,
      initialState: meInitialState,
    },
    {
      key: "people",
      actionHandlers: peopleActionHandlers,
      initialState: peopleInitialState,
    },
  ],
};
