import { upsertData, removeData } from "../utils/normalize";
import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const parentDeliverableBriefingFieldFields = `parentDeliverableBriefingFieldId, parentDeliverableId, briefingFieldId, fieldValue`;

export const parentDeliverableBriefingFieldsByParentDeliverableIdQuery = `parentDeliverableBriefingFields (parentDeliverableIds: $parentDeliverableId) {
    ${parentDeliverableBriefingFieldFields}
}`;
export const parentDeliverableBriefingFieldsByBatchQuery = `parentDeliverableBriefingFields (batchId: $batchId) {
    ${parentDeliverableBriefingFieldFields}
}`;
export const parentDeliverableBriefingFieldsByAssignmentGroupQuery = `parentDeliverableBriefingFields (assignmentGroupId: $assignmentGroupId) {
    ${parentDeliverableBriefingFieldFields}
}`;
export const parentDeliverableBriefingFieldsByAssignmentQuery = `parentDeliverableBriefingFields (assignmentId: $assignmentId) {
    ${parentDeliverableBriefingFieldFields}
}`;
export const parentDeliverableBriefingFieldsByDeliverableIdsQuery = `parentDeliverableBriefingFields (deliverableIds: $deliverableId) {
  ${parentDeliverableBriefingFieldFields}
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS =
  "FETCH_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS";
export const CREATE_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS =
  "CREATE_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS";
export const REMOVE_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS =
  "REMOVE_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchParentDeliverableBriefingFieldsSuccess = createAction(
  FETCH_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS
);
export const createParentDeliverableBriefingFieldsSuccess = createAction(
  CREATE_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS
);
export const removeParentDeliverableBriefingFieldsSuccess = createAction(
  REMOVE_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS
);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const parentDeliverableBriefingFieldActionHandlers = {
  [RESET_INITIAL_STATE]: () => parentDeliverableBriefingFieldInitialState,
  [FETCH_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS]: (state, { payload }) =>
    // Not returning on one line because it looks bad, easier to read like
    payload.reduce(
      (acc, v) => {
        acc.entities[v.parentDeliverableBriefingFieldId] = v;
        acc.result.push(v.parentDeliverableBriefingFieldId);
        return acc;
      },
      { entities: {}, result: [] }
    ),
  [CREATE_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentDeliverableBriefingFieldId"),
  [REMOVE_PARENT_DELIVERABLE_BRIEFING_FIELDS_SUCCESS]: (state, { payload }) => {
    return payload.reduce(
      (acc, { parentDeliverableBriefingFieldId }) =>
        removeData(acc, parentDeliverableBriefingFieldId),
      state
    );
  },
};

export const parentDeliverableBriefingFieldInitialState = {
  entities: {},
  result: [],
};
