import { SubmissionError } from "redux-form";

export default function handleErrors(err, customMessage = {}) {
  if (err.errors) {
    if (window.__DEV__)
      console.log(`Error:`, JSON.stringify(err.errors, null, 4));

    if (err.errors.length) {
      err.errors.forEach((e) => {
        if (e.name === "DuplicateError") {
          throw new SubmissionError({
            _error: customMessage.duplicate || e.message,
          });
        }

        if (e.name === "PartialError") {
          throw new SubmissionError({
            _error: customMessage.partial || e.message,
          });
        }

        if (e.name === "BadRequestError") {
          throw new SubmissionError({ _error: e.message });
        }

        if (e.name === "RangeError") {
          throw new SubmissionError({
            _error: customMessage.exceeded || e.message,
          });
        }

        if (e.name === "Unauthorized") {
          throw e;
        }

        throw new SubmissionError({ _error: e.message });
      });
    }
  } else {
    console.log("error", err.message, err.name);
    if (err.name === "TypeError" && err.message === "Failed to fetch") {
      throw new SubmissionError({
        _error:
          "Could not connect to the internet. " +
          "Please check your internet connection and try again at a later time.",
      });
    }
  }
}
