import React from "react";
import PropTypes from "prop-types";
import { QuillForm, QuillFormField } from "../../../QuillForm";
import { InputWithErrors } from "../../../../decorators/WithErrors";
import { reduxForm } from "redux-form";
import { currentISODate } from "../../../../utils/date";
import validate from "./validations";
import CurrencyDisplay from "../../../CurrencyDisplay";

let UpdateAssignmentsForm = ({
  canUpdateRates,
  handleSubmit,
  hideModal,
  isRequesting,
  valid,
  currencyCode,
}) => {
  return (
    <QuillForm
      header={"Update assignments"}
      cancelText="Cancel"
      cancelHandler={hideModal}
      submitText="Save"
      disabled={isRequesting || !valid}
      handleSubmit={handleSubmit}
      hideRequiredHeader
    >
      {canUpdateRates && (
        <CurrencyDisplay
          name="rate"
          text="Rate"
          isInputField={true}
          currencyCode={currencyCode}
        />
      )}
      <QuillFormField
        labelText="Updated deadlines"
        name="deadline"
        component={InputWithErrors}
        type="datetime-local"
        min={currentISODate()}
        max="9999-12-31T00:00"
      />
    </QuillForm>
  );
};

UpdateAssignmentsForm = reduxForm({
  form: "updateAssignmentsForm",
  validate,
})(UpdateAssignmentsForm);

UpdateAssignmentsForm.propTypes = {
  canUpdateRates: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  hideModal: PropTypes.func,
  isRequesting: PropTypes.bool,
  valid: PropTypes.bool,
};

export default UpdateAssignmentsForm;
