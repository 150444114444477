import { createAction } from "redux-actions";
import { upsertData, removeData } from "../utils/normalize";
import { postGraphQL } from "../../utils/fetch";
import { SubmissionError } from "redux-form";
import { projectRatesSelector } from "../utils/entitySelector";
import { RESET_INITIAL_STATE } from "./me";
// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const ratesByProjectIdQuery = `rates(projectId: $projectId) {
  rateId, amount, projectId, stageId, languageCode
}`;

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_RATES_SUCCESS = "FETCH_RATES_SUCCESS";
export const CREATE_RATES_SUCCESS = "CREATE_RATES_SUCCESS";
export const REMOVE_RATES_SUCCESS = "REMOVE_RATES_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------

export const fetchRatesSuccess = createAction(FETCH_RATES_SUCCESS);
const createRatesSuccess = createAction(CREATE_RATES_SUCCESS);
const removeRatesSuccess = createAction(REMOVE_RATES_SUCCESS);

export function updateRates(data, history, params, location) {
  const projectCreation = location.state
    ? location.state.projectCreation
    : false;
  const { projectId } = params;
  // turn the hash map into an array of rate objects
  const rates = Object.keys(data).reduce(
    (acc, languageCode) =>
      acc.concat(
        Object.keys(data[languageCode]).map((stageId) => ({
          languageCode,
          stageId,
          projectId,
          amount: data[languageCode][stageId],
        }))
      ),
    []
  );
  return (dispatch, getState) => {
    const query = `mutation updateRates($associations: [RateInput], $projectId: Int){
      updateRates(associations: $associations, projectId: $projectId) {
        rateId, amount, projectId, stageId, languageCode
      }
    }`;

    return postGraphQL(query, { associations: rates, projectId }, "updateRates")
      .then((json) => {
        const oldRates = projectRatesSelector(getState(), Number(projectId));
        dispatch(removeRatesSuccess(oldRates));
        dispatch(createRatesSuccess(json));
        if (projectCreation) {
          history.push({
            pathname: `/admin/projects/${projectId}/default-assignees`,
            state: { projectCreation: true },
          });
        } else {
          history.push(`/admin/projects/${projectId}`);
        }
      })
      .catch((err) => {
        console.log("Error updating rates", err.errors, err.message);
        if (err.errors) throw new SubmissionError({ _error: err.message });
        throw new SubmissionError({
          _error:
            "There was an error connecting to the server. Please try again later.",
        });
      });
  };
}
// ------------------------------------
// Action Handlers
// ------------------------------------

export const rateActionHandlers = {
  [RESET_INITIAL_STATE]: () => rateInitialState,
  [FETCH_RATES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "rateId"),
  [CREATE_RATES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "rateId"),
  [REMOVE_RATES_SUCCESS]: (state, { payload }) => {
    return payload.reduce((acc, { rateId }) => removeData(acc, rateId), state);
  },
};

export const rateInitialState = { entities: {}, result: [] };
