import {
  freelancerDashboardActionHandlers,
  freelancerDashboardInitialState,
} from "../../../modules/freelancerDashboard";

export default [
  {
    key: "freelancerDashboard",
    actionHandlers: freelancerDashboardActionHandlers,
    initialState: freelancerDashboardInitialState,
  },
];
