import * as serviceWorker from "./serviceWorker";
import "react-select/dist/react-select.css";
import "../src/client/decorators/ReduxFormCompatible/ReduxFormCompatible.scss";
import "../node_modules/draft-js/dist/Draft.css";

require("react-hot-loader/patch");
require("./client");

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
