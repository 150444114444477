import Show from "../components/Show";
import WithData from "../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import {
  projectBannedWordsSelector,
  projectLanguagesSelector,
} from "../../../../../../utils/entitySelector";
import { createSelector } from "reselect";

const orderByLanguage = createSelector(
  (entity, property) => entity,
  (entity, property) => property,
  (entity, property) => {
    const tree = {};
    entity.forEach((e) => {
      if (!tree[e.languageCode]) tree[e.languageCode] = [];
      tree[e.languageCode].push(e[property]);
    });
    return tree;
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { projectId },
    },
  } = ownProps;
  const project = state.projects.entities[projectId] || {};
  const workflow = state.workflows.entities[project.workflowId] || {};
  const projectLanguages = projectLanguagesSelector(state, Number(projectId));
  const bannedWords = orderByLanguage(
    projectBannedWordsSelector(state, Number(projectId)),
    "word"
  );
  return { project, workflow, projectLanguages, bannedWords };
};

const FreelancerProjects = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Show));
FreelancerProjects.getInitialData = getInitialData;
export default FreelancerProjects;
