import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../../components/PageHeader";
import Errors from "../../../../../../../components/Errors";
import Tabs from "../../../../../../../components/Tabs/v2";
import RatesForm from "../../_components/RatesForm";
import WordRatesForm from "../../_components/WordRatesForm";
import Messages from "../../../../../../../components/Messages/v1";

const RateTabs = {
  RateBands: "RateBands",
  WordRate: "WordRate",
};

const Rates = (props) => {
  const {
    account: { accountName },
    activeTab,
    errors,
    history,
    messages,
    orderForm: { orderFormNumber },
    parentAccount: { parentAccountName },
    project: { isProjectTM, projectId, projectName },
    storageKey,
  } = props;

  const baseUrl = `/admin/projects/${projectId}`;

  const rateBandTabItems = [{ key: RateTabs.RateBands, text: "Rate bands" }];

  // only projects with access to TM should see the word rate tab
  if (isProjectTM) {
    rateBandTabItems.push({
      key: RateTabs.WordRate,
      text: "Word rate (Translation Memory)",
    });
  }

  return (
    <div>
      <Helmet>
        {" "}
        <title>Project Rates</title>{" "}
      </Helmet>
      {errors && errors.display && <Errors />}
      {messages.display && <Messages />}

      <PageHeader
        breadCrumbItems={[
          { url: "/admin", text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { url: baseUrl, text: projectName },
          { text: "Rates" },
        ]}
        title={`Rates - ${projectName}`}
      />

      <Tabs
        hideNavIfOneItem
        initialValue={RateTabs.RateBands}
        items={rateBandTabItems}
        storageKey={storageKey}
      />

      {activeTab === RateTabs.RateBands && (
        <RatesForm
          baseUrl={baseUrl} // needed since data is retrieved async
          enableReinitialize
          hideModal={props.hideModal}
          initialValues={props.initialValues}
          match={props.match}
          modal={props.modal}
          projectCreation={props.projectCreation}
          projectId={projectId}
          projectLanguages={props.projectLanguages}
          rateBands={props.rateBands}
          showModal={props.showModal}
          stages={props.stages}
          submitRateBandItems={props.submitRateBandItems}
        />
      )}

      {activeTab === RateTabs.WordRate && (
        <WordRatesForm
          history={history}
          projectId={projectId}
          projectLanguages={props.projectLanguages}
        />
      )}
    </div>
  );
};

Rates.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
  }),
  activeTab: PropTypes.string,
  errors: PropTypes.shape({
    display: PropTypes.bool,
  }),
  hideModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  match: PropTypes.object,
  messages: PropTypes.shape({
    display: PropTypes.bool,
  }),
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number.isRequired,
  }),
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string.isRequired,
  }),
  project: PropTypes.object.isRequired,
  projectCreation: PropTypes.bool,
  projectLanguages: PropTypes.array.isRequired,
  rateBands: PropTypes.array,
  showModal: PropTypes.func.isRequired,
  stages: PropTypes.array.isRequired,
  storageKey: PropTypes.string.isRequired,
  submitRateBandItems: PropTypes.func.isRequired,
};

export default Rates;
