import React from "react";
import PropTypes from "prop-types";
import styles from "./Overview.module.scss";
import OverlayLoading from "../../../../../../../components/OverlayLoading/v1";
import LocalTime from "../../../../../../../components/LocalTime";

export const Overview = (props) => {
  const { batchOverview, dataReady } = props;

  if (!dataReady) return <OverlayLoading />;

  if (batchOverview.length === 0) {
    return (
      <div className={styles.emptyRow}>
        This view will show you the status of batches as they move through
        production and review.
      </div>
    );
  }

  return (
    <div>
      <div className={`${styles.headerFont} ${styles.header}`}>
        <div className={styles.column}>Batch</div>
        <div className={styles.column}>In production</div>
        {props.stages.map(({ stageId, stageName }) => (
          <div key={stageId} className={styles.column}>
            {stageName}
          </div>
        ))}
      </div>

      {batchOverview.map((batch) => {
        return (
          <div className={styles.row} key={batch.batchId}>
            <div className={styles.column}>
              <div className={styles.paddingRight}>{batch.batchName}</div>
            </div>
            <div className={styles.column}>
              {batch["Production"] ? batch["Production"].total : 0}
            </div>
            {props.stages.map(({ stageId }) => (
              <div key={stageId} className={styles.column}>
                {batch[stageId] ? batch[stageId].total : 0}

                {batch[stageId] && batch[stageId].deadline && (
                  <div className={styles.deadline}>
                    Feedback due:{" "}
                    <LocalTime
                      utcDateString={batch[stageId].deadline}
                      isDeadline
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

Overview.propTypes = {
  batchOverview: PropTypes.arrayOf(
    PropTypes.shape({
      batchId: PropTypes.number.isRequired,
      batchName: PropTypes.string.isRequired,
      Production: PropTypes.object.isRequired,
    })
  ).isRequired,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      stageId: PropTypes.number.isRequired,
      stageName: PropTypes.string.isRequired,
    })
  ),
  dataReady: PropTypes.bool.isRequired,
};

export default Overview;
