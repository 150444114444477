import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { isLoggedInUserAdmin } from "../../../utils/entitySelector";
import Dropdown from "../../Dropdown/v4";
import MultiSelectDropdown from "../../MultiSelectDropdown";
import FeatureToggle from "../../../components/FeatureToggle";
import ClientStage from "./ClientStage";
import styles from "./ProgressTab.module.scss";

export function DownloadColumn({ showFinal }) {
  const isAdmin = useSelector(isLoggedInUserAdmin);

  // always show downloads for approved, conditionally for with client stages
  if (showFinal) {
    return <span className={styles.download}>Downloads</span>;
  }

  if (!isAdmin) return null;

  return (
    <FeatureToggle toggle={"withClientDownloads"}>
      <span className={styles.download}>Downloads</span>
    </FeatureToggle>
  );
}

export const ProgressTab = ({
  batches,
  stageData,
  languageNames,
  showFinal,
  projectId,
  baseUrl,
  history,
  location,
  isMultiSelectLanguageFilter,
  onFilterLanguage,
  selectedLanguages,
  setSelectedLanguages,
}) => {
  const getBatchObject = (batchId) => {
    return batchId && batches.find((b) => b.batchId === batchId);
  };
  const getLanguageObject = (languageCode) => {
    return (
      languageCode && {
        languageCode,
        languageName: languageNames[languageCode],
      }
    );
  };

  const [selectedBatch, setSelectedBatch] = useState();

  useEffect(() => {
    const { batchId } = queryString.parse(location.search);
    filterBatch(Number(batchId), false);

    const isAllLanguageSelected =
      selectedLanguages.length === Object.keys(languageNames).length;
    updateQuerySearch(
      "languageCode",
      isAllLanguageSelected ? [] : selectedLanguages,
      { arrayFormat: "comma" }
    );
  }, []);

  // Reset active filters when component unmounts
  useEffect(() => () => setSelectedLanguages([]), []);

  // use the selected batch to filter the original stage data
  const filteredStageData = useMemo(() => {
    return stageData.map((stage) => {
      let batchData = [...stage.batchData].filter((b) =>
        !selectedBatch ? true : b.batchId === selectedBatch.batchId
      );
      // if onFilterLanguage is there, then filtering is handled from parent component / backend
      if (!onFilterLanguage) {
        const isAllSelected =
          selectedLanguages.length === 0 ||
          selectedLanguages.length === Object.keys(languageNames).length;

        if (!isAllSelected) {
          batchData = batchData.filter((b) =>
            selectedLanguages.includes(b.languageCode)
          );
        }
      }
      return {
        ...stage,
        batchData,
      };
    });
  }, [
    selectedBatch,
    selectedLanguages,
    languageNames,
    stageData,
    onFilterLanguage,
  ]);

  const dropdownBatches = useMemo(() => {
    const ids = stageData.reduce((acc, { batchData }) => {
      batchData.forEach(({ batchId, batchName }) => {
        acc[batchId] = batchName;
      });
      return acc;
    }, {});

    return ids;
  }, [stageData]);

  const updateQuerySearch = (term, values, options) => {
    const query = queryString.parse(location.search, options);

    const valuesArray = Array.isArray(values) ? values : [values];

    if (valuesArray.length === 0) {
      delete query[term];
    } else {
      query[term] = valuesArray;
    }

    const search = queryString.stringify(query, options);

    if (location.search !== `?${search}`) {
      history.push({ search: `?${search}` });
    }
  };

  const filterBatch = (batchId, updateQueryString = true) => {
    if (updateQueryString) updateQuerySearch("batchId", batchId);
    setSelectedBatch(getBatchObject(batchId));
  };

  const filterLanguage = (languageCodes, updateQueryString = true) => {
    if (updateQueryString) {
      const isAllSelected =
        languageCodes.length === Object.keys(languageNames).length;
      updateQuerySearch("languageCode", isAllSelected ? [] : languageCodes, {
        arrayFormat: "comma",
      });
    }
    setSelectedLanguages(languageCodes);
  };

  return (
    <div>
      <div className={styles.headerControls}>
        <span className={styles.batch}>
          <Dropdown
            filterText={"filter by batch name"}
            onClick={filterBatch}
            options={dropdownBatches}
            title={selectedBatch ? selectedBatch.batchName : "Batches"}
            shouldUpdateDropdownOptions={true}
          />
        </span>
        <span className={styles.counts}>Deliverables</span>

        {/* only show feedback due for in progress deliverables */}
        {!showFinal && <span className={styles.feedbackDue}>Feedback due</span>}
        <span className={styles.languageCode}>
          {isMultiSelectLanguageFilter ? (
            <MultiSelectDropdown
              onClick={filterLanguage}
              options={languageNames}
              rightAligned
              title={`Languages (${selectedLanguages.length})`}
              selectedOptions={selectedLanguages}
            />
          ) : (
            <Dropdown
              onClick={(languageCode, updateQueryString) =>
                filterLanguage(
                  languageCode ? [languageCode] : Object.keys(languageNames),
                  updateQueryString
                )
              }
              options={languageNames}
              rightAligned
              small
              title={
                selectedLanguages?.length === Object.keys(languageNames)?.length
                  ? "Language"
                  : getLanguageObject(selectedLanguages[0])?.languageName ||
                    "Language"
              }
            />
          )}
        </span>

        <DownloadColumn showFinal={showFinal} />
      </div>

      <div className={styles.flexContainer}>
        {filteredStageData.map(({ batchData, stageId, stageName }) => (
          <ClientStage
            key={stageId}
            baseUrl={baseUrl}
            batchData={batchData}
            projectId={projectId}
            showFinal={showFinal}
            stageId={stageId}
            stageName={stageName}
          />
        ))}
      </div>
    </div>
  );
};

ProgressTab.propTypes = {
  baseUrl: PropTypes.string,
  batches: PropTypes.array,
  history: PropTypes.object,
  languageNames: PropTypes.object,
  location: PropTypes.object,
  projectId: PropTypes.number,
  showFinal: PropTypes.bool,
  stageData: PropTypes.arrayOf(
    PropTypes.shape({
      batchData: PropTypes.arrayOf(
        PropTypes.shape({
          batchId: PropTypes.number.isRequired,
          batchName: PropTypes.string.isRequired,
          deliverableCount: PropTypes.number.isRequired,
          languageCode: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        })
      ).isRequired,
      stageId: PropTypes.number.isRequired,
    })
  ).isRequired,
  isMultiSelectLanguageFilter: PropTypes.bool,
  onFilterLanguage: PropTypes.func,
  selectedLanguages: PropTypes.array,
  setSelectedLanguages: PropTypes.func,
};

export default ProgressTab;
