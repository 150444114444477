import { getGraphQL } from "../../../../../../../../utils/fetch";

import {
  clientBatchDetailsWithLanguageQuery,
  fetchClientBatchDetailsSuccess,
} from "../../../../../../../modules/clientBatchDetails";
import { multiSelectLanguageSuccess } from "../../../../../../../modules/projectLanguages";
import { getQueryValuesAsArray } from "../../../../../../../utils/parseQuery";

const initialQuery = `query clientBatchDetails($projectId: String, $languageCode: [String]){
    ${clientBatchDetailsWithLanguageQuery}
  }`;

export function getAsyncData(params, url, cookies) {
  const { projectId } = params;
  return (dispatch, getState) => {
    const state = getState();
    const languageCode = getQueryValuesAsArray("languageCode");

    // if language exists in url query params then take languages from query params or use person native languages
    const selectedLanguages =
      languageCode?.length > 0
        ? languageCode
        : state.personNativeLanguages.map((item) => item.nativeLanguage);

    const selectedLanguagesInProject = state.projectLanguages
      .map((language) => language.languageCode)
      .filter((languageCode) => selectedLanguages.includes(languageCode));

    return getGraphQL(
      initialQuery,
      { projectId, languageCode: selectedLanguagesInProject },
      url,
      cookies
    ).then((json) => {
      dispatch(
        fetchClientBatchDetailsSuccess({
          data: json.clientBatchDetails,
          projectId,
        })
      );
      dispatch(multiSelectLanguageSuccess(selectedLanguages));
    });
  };
}
