import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./ParentDeliverablesNew.module.scss";
import Helmet from "react-helmet";
import ParentDeliverablesForm from "../../_components/ParentDeliverablesForm";
import PageHeader from "../../../../../../../components/PageHeader";

class ParentDeliverablesNew extends Component {
  constructor(props) {
    super(props);

    this.state = { redirect: true };

    this.submitAndRedirect = () =>
      this.setState({ redirect: true }, () =>
        this.parentDeliverablesForm.submit()
      );

    this.submitAndClearForm = () =>
      this.setState({ redirect: false }, () =>
        this.parentDeliverablesForm.submit()
      );
  }

  handleFormSubmissionSuccess = () => {
    const { history, projectId, clearParentDeliverablesForm } = this.props;
    if (this.state.redirect) {
      history.push(`/admin/projects/${projectId}/briefing`);
    } else {
      clearParentDeliverablesForm();
    }
  };

  render() {
    const {
      accountName,
      parentAccountName,
      orderFormNumber,
      projectId,
      projectName,
      sourceLanguage,
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>New parent deliverable</title>
        </Helmet>
        <PageHeader
          breadCrumbItems={[
            { url: "/admin", text: "Parent accounts" },
            {
              text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
            },
            { url: `/admin/projects/${projectId}`, text: `${projectName}` },
            { text: "New parent deliverable" },
          ]}
          title={`${projectName} - New parent deliverable`}
        />
        <ParentDeliverablesForm
          ref={(r) => {
            this.parentDeliverablesForm = r;
          }}
          batches={this.props.batches}
          briefingFields={this.props.briefingFields}
          clientDeadlineStages={this.props.clientDeadlineStages}
          createBatch={this.props.createBatch}
          enableReinitialize
          featureToggles={this.props.featureToggles}
          header="Create a new parent deliverable"
          hideModal={this.props.hideModal}
          initialValues={this.props.initialValues}
          keywordGroups={this.props.keywordGroups}
          modal={this.props.modal}
          onSubmit={this.props.createParentDeliverable}
          onSubmitSuccess={this.handleFormSubmissionSuccess}
          projectId={projectId}
          projectSourceLanguage={sourceLanguage}
          rateBands={this.props.rateBands}
          showModal={this.props.showModal}
          sourceFields={this.props.sourceFields}
          projectLanguagesNames={this.props.projectLanguagesNames}
        />
        <div className={styles.buttonsContainer}>
          <button
            className={styles.saveAndCreateAnother}
            disabled={
              this.parentDeliverablesForm && this.parentDeliverablesForm.invalid
            }
            onClick={this.submitAndClearForm}
          >
            Save and create another
          </button>
          <button
            className={styles.save}
            disabled={
              this.parentDeliverablesForm && this.parentDeliverablesForm.invalid
            }
            onClick={this.submitAndRedirect}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

ParentDeliverablesNew.propTypes = {
  accountName: PropTypes.string.isRequired,
  batches: PropTypes.array.isRequired,
  briefingFields: PropTypes.object.isRequired,
  clearParentDeliverablesForm: PropTypes.func.isRequired,
  clientDeadlineStages: PropTypes.array.isRequired,
  createBatch: PropTypes.func.isRequired,
  createParentDeliverable: PropTypes.func.isRequired,
  featureToggles: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  initialValues: PropTypes.shape({
    keywords: PropTypes.array.isRequired,
    parentDeliverableBriefingFields: PropTypes.array.isRequired,
    projectId: PropTypes.number.isRequired,
  }).isRequired,
  keywordGroups: PropTypes.array.isRequired,
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
  orderFormNumber: PropTypes.number.isRequired,
  parentAccountName: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  rateBands: PropTypes.array,
  showModal: PropTypes.func.isRequired,
  sourceFields: PropTypes.object,
  sourceLanguage: PropTypes.string,
  projectLanguagesNames: PropTypes.array,
};

export default ParentDeliverablesNew;
