import { connect } from "react-redux";
import { hideErrors } from "../../../modules/errors";
import Errors from "../components/Errors";

const mapDispatchToProps = (dispatch, ownProps) => ({
  hideErrors: () => dispatch(hideErrors()),
});

const mapStateToProps = (state) => {
  const { errors } = state;
  return { errors };
};

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
