import { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ExportFormatForm.module.scss";
import { reduxForm, Field } from "redux-form";
import {
  QuillFormField,
  QuillInnerFormContainer,
} from "../../../../../../../components/QuillForm";
import QuillHeader from "../../../../../../../components/QuillHeader";
import {
  SelectWithErrors,
  InputWithErrors,
} from "../../../../../../../decorators/WithErrors";
import { connect } from "react-redux";
import classNames from "classnames";
import FeatureToggle from "../../../../../../../components/FeatureToggle";

const KEEP = "Keep";
const REMOVE = "Remove";

const getFilters = (isLocalisationProject) => {
  const filters = [
    {
      key: "bold",
      text: "Bold (<b>, <strong>)",
      options: [KEEP, REMOVE, "Convert to <b>", "Convert to <strong>"],
    },
    {
      key: "bullets",
      text: "Bullets (*, <ul>, <li>)",
      options: [KEEP, REMOVE, "Convert to bullets", "Convert to <ul>, <li>"],
    },
    {
      key: "emptyLinebreaks",
      text: "Empty lines (<p><br /></p>)",
      options: [KEEP, REMOVE],
    },
    {
      key: "linebreaks",
      text: "Line breaks (<p>, <br />, new lines)",
      options: [KEEP, REMOVE, "Convert to new lines", "Convert to <br />"],
    },
    {
      key: "special",
      text: "Special characters (&amp;)",
      options: [KEEP, REMOVE],
    },
    {
      key: "other",
      text: "All other HTML tags",
      options: [KEEP, REMOVE],
    },
    {
      key: "briefingFields",
      text: "Briefing field columns",
      options: [KEEP, REMOVE],
    },
    {
      key: "formatting",
      text: "HTML formatting",
      options: ["Multiline", "Single line"],
    },
  ];

  if (isLocalisationProject) {
    filters.push({
      key: "sourceFields",
      text: "Source field columns",
      options: [KEEP, REMOVE],
    });
  }

  return filters;
};

const TEXT_TO_VALUE = {
  Keep: "KEEP",
  Remove: "REMOVE",
  "Convert to <b>": "CONVERT_TO_B_TAG",
  "Convert to <strong>": "CONVERT_TO_STRONG_TAG",
  "Convert to bullets": "CONVERT_TO_BULLETS",
  "Convert to <ul>, <li>": "CONVERT_TO_UL_LI",
  "Convert to <br />": "CONVERT_TO_BR_TAG",
  "Convert to new lines": "CONVERT_TO_NEW_LINES",
  Multiline: "MULTILINE",
  "Single line": "SINGLELINE",
};

const ExportFormatForm = ({
  handleSubmit,
  isLocalisationProject,
  isDocExportEnabled,
  submitting,
  valid,
  taskFields,
  briefingFieldList,
  onSubmit,
}) => {
  const docExportNameOptions = useMemo(
    () => [
      { label: "Deliverable ID", value: "DELIVERABLE_ID" },
      ...taskFields.map(({ taskFieldName, taskFieldId }) => ({
        value: taskFieldId,
        label: taskFieldName,
      })),
      ...briefingFieldList.map(({ briefingFieldName, briefingFieldId }) => ({
        value: briefingFieldId,
        label: briefingFieldName,
      })),
    ],
    [taskFields, briefingFieldList]
  );

  const filters = useMemo(() => {
    return getFilters(isLocalisationProject);
  }, [isLocalisationProject]);

  const docExportBriefingFields = {
    key: "docExportBriefingFields",
    text: "Briefing field columns",
    options: [KEEP, REMOVE],
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <QuillHeader header={"Export format filters"} hideRequiredHeader />
      <QuillInnerFormContainer className={styles.filtersInnerContainer}>
        <div className={styles.filtersContainer}>
          {filters.map(({ key, text, options }, rowId) => {
            const isLastRow = rowId + 1 === filters.length;
            const lastRowStyles = classNames({
              [styles["is-last"]]: isLastRow,
            });

            return (
              <Fragment key={text}>
                <div className={lastRowStyles}>{text}</div>
                <div className={lastRowStyles}>
                  {options.map((option, columnId) => {
                    const labelStyles = classNames({
                      [styles[`col-${columnId + 1}`]]: true,
                    });

                    return (
                      <div className={styles.radioStyle}>
                        <Field
                          component="input"
                          id={`${columnId}-${rowId}`}
                          name={key}
                          type="radio"
                          value={TEXT_TO_VALUE[option]}
                        />

                        <label
                          key={columnId}
                          htmlFor={`${columnId}-${rowId}`}
                          className={labelStyles}
                        >
                          <span>{option}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Fragment>
            );
          })}
        </div>
      </QuillInnerFormContainer>
      <FeatureToggle toggle="docExport">
        <QuillHeader
          header={"Google Doc Export"}
          hideRequiredHeader
          className={styles.docExportHeader}
        />
        <QuillInnerFormContainer>
          <QuillFormField
            component={InputWithErrors}
            customContainerStyle={styles.inputContainer}
            labelText="Enable download in Google Doc format"
            name="docExport"
            styles={styles.checkbox}
            type="checkbox"
          />

          <div className={styles.enabledContainer}>
            <QuillFormField
              clearable={false}
              component={SelectWithErrors}
              deleteRemoves={false}
              disabled={submitting || !isDocExportEnabled}
              labelText="EXPORT NAME CONFIGURATION"
              name="exportNameConfiguration"
              options={docExportNameOptions}
              required
            />
          </div>

          <QuillInnerFormContainer className={styles.filtersInnerContainer}>
            <div className={styles.filtersContainerDoc}>
              <>
                <div>{docExportBriefingFields.text}</div>
                <div className={styles.radioKeys}>
                  {docExportBriefingFields.options.map((option, columnId) => {
                    const labelStyles = classNames({
                      [styles[`col-${columnId + 1}`]]: true,
                    });

                    return (
                      <div className={styles.radioStyle}>
                        <Field
                          component="input"
                          id={`${columnId}-briefing`}
                          name={docExportBriefingFields.key}
                          type="radio"
                          value={TEXT_TO_VALUE[option]}
                          disabled={submitting || !isDocExportEnabled}
                        />

                        <label
                          key={columnId}
                          htmlFor={`${columnId}-briefing`}
                          className={labelStyles}
                        >
                          <span>{option}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </>
            </div>
          </QuillInnerFormContainer>
        </QuillInnerFormContainer>
      </FeatureToggle>
      <div className={styles.buttonContainer}>
        <div className={styles.alignRight}>
          <button
            className={styles.submit}
            disabled={submitting || !valid}
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

ExportFormatForm.propTypes = {
  handleSubmit: PropTypes.func,
  isLocalisationProject: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  taskFields: PropTypes.array,
  briefingFieldList: PropTypes.array,
  onSubmit: PropTypes.func,
};

const ConnectExportFormatForm = reduxForm({
  form: "ExportFormatForm",
})(ExportFormatForm);

export default connect((state, ownProps) => ({
  isDocExportEnabled: state.form?.ExportFormatForm?.values?.docExport || false,
}))(ConnectExportFormatForm);
