import { connect } from "react-redux";
import { createSelector } from "reselect";
import Briefing from "../components/Briefing";
import WithData from "../../../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import {
  projectBriefingFieldsSelector,
  batchesByProjectId,
} from "../../../../../../../../utils/entitySelector";
import { fetchParentDeliverablesAndBriefingFieldsByBatchId } from "../modules/getAsyncData";

// TODO: Refactor this huge thing to select way more efficiently...
const tableDataSelector2 = createSelector(
  projectBriefingFieldsSelector,
  (state) => state.batches.entities,
  (state, projectId, parentDeliverables) => parentDeliverables,
  (state, projectId, parentDeliverables, parentDeliverableBriefingFields) =>
    parentDeliverableBriefingFields,
  (state) => state.featureToggles["attachmentUpload"],

  (
    projectBriefingFields,
    batches,
    parentDeliverables,
    parentDeliverableBriefingFields,
    attachmentToggle
  ) => {
    const data = {
      headers: ["Parent Deliverable Id"], // batchId is already displayed on the briefing page
    };

    if (attachmentToggle) data.headers.push("Attachments");

    // build the headers from the briefing fields
    projectBriefingFields.forEach((briefingField) => {
      data.headers.push(briefingField.briefingFieldName);
    });

    // build a map with base briefingFields for each parentDeliverable
    let parentDeliverableMap = parentDeliverables.reduce(
      (acc, parentDeliverable) => {
        const { batchId, parentDeliverableId } = parentDeliverable;
        const { batchName } = batches[batchId];

        // add the batchName and parentDeliverableId (first 2 columns on Briefing tab)
        acc[parentDeliverableId] = {
          batchId,
          briefingFields: [batchName, parentDeliverableId],
        };

        if (attachmentToggle)
          acc[parentDeliverableId].briefingFields.push("Attachments");

        return acc;
      },
      {}
    );

    // We have the briefing fields on the project but not in an object with the briefing field ids as keys
    const briefingFieldsById = projectBriefingFields.reduce((acc, bf) => {
      acc[bf.briefingFieldId] = bf;
      return acc;
    }, {});

    // build a map of briefing fields to parent deliverables so we can fill in the blanks
    const briefingFieldMap = parentDeliverableBriefingFields.reduce(
      (acc, bf) => {
        const { briefingFieldId, parentDeliverableId } = bf;

        if (!acc[parentDeliverableId]) acc[parentDeliverableId] = {};
        if (!acc[parentDeliverableId][briefingFieldId])
          acc[parentDeliverableId][briefingFieldId] = bf;

        return acc;
      },
      {}
    );

    // add in blank fields for value we don't have
    parentDeliverables.forEach(({ parentDeliverableId }) => {
      if (!briefingFieldMap[parentDeliverableId])
        briefingFieldMap[parentDeliverableId] = {};
      projectBriefingFields.forEach(({ briefingFieldId }) => {
        if (!briefingFieldMap[parentDeliverableId][briefingFieldId]) {
          briefingFieldMap[parentDeliverableId][briefingFieldId] = {
            briefingFieldId,
            parentDeliverableId,
            fieldValue: "",
          };
        }
      });
    });

    // convet an object of objects into a flat array
    parentDeliverableBriefingFields = Object.entries(briefingFieldMap).reduce(
      (acc, [parentDeliverableId, bf]) => {
        return acc.concat(Object.entries(bf).map(([_, bf]) => bf));
      },
      []
    );

    // Make sure we add the briefing fields in order of their position on the project template
    parentDeliverableBriefingFields = parentDeliverableBriefingFields
      .filter(({ briefingFieldId: bfId }) => briefingFieldsById[bfId])
      .sort(({ briefingFieldId: idA }, { briefingFieldId: idB }) => {
        return (
          briefingFieldsById[idA].briefingFieldPosition -
          briefingFieldsById[idB].briefingFieldPosition
        );
      });

    // for each parentDeliverableBriefingField we need to add to our existing briefingField object (if the briefingField is not archived)
    parentDeliverableMap = parentDeliverableBriefingFields.reduce(
      (acc, parentDeliverableBriefingField) => {
        const { briefingFieldId, parentDeliverableId, fieldValue } =
          parentDeliverableBriefingField;

        // if our briefingField has a parentDeliverable
        if (acc[parentDeliverableId]) {
          // and it's briefingFieldId is not archived (the selector doens't return archived briefingFields)
          if (
            projectBriefingFields.some(
              (briefingField) =>
                briefingField.briefingFieldId === briefingFieldId
            )
          ) {
            acc[parentDeliverableId].briefingFields.push(fieldValue);
          }
        }

        return acc;
      },
      parentDeliverableMap
    );

    // flatten into an object with keys as batchId (for easy access via batch selector)
    const flattened = Object.keys(parentDeliverableMap).reduce(
      (acc, parentDeliverableId) => {
        const { batchId, briefingFields } =
          parentDeliverableMap[parentDeliverableId];
        // flattened by batchId
        if (!acc[batchId]) acc[batchId] = [];

        // push all of the briefingFields for this parentDeliverable
        acc[batchId].push(briefingFields);

        return acc;
      },
      {}
    );

    // return our data with our rows sorted by batch
    data.rows = { ...flattened };

    return data;
  }
);

const parentDeliverablesByBatchesSelector = createSelector(
  (state) => state.parentDeliverables.entities,
  (state) => state.parentDeliverables.result.map((v) => Number(v)),
  (state) => state.parentDeliverableBriefingFields.entities,
  (state) => state.parentDeliverableBriefingFields.result.map((v) => Number(v)),
  (state, batches) => batches.map((batch) => batch.batchId),
  (pdEntities, pdResult, pdbfEntities, pdbfResult, batchIds) => {
    // get all of the parentDeliverableId's for all the batches we have selected (for this project)
    const parentDeliverableIds = pdResult.filter((parentDeliverableId) => {
      return batchIds.includes(pdEntities[parentDeliverableId].batchId);
    });

    // get all of the parentDeliverableBriefingFieldId's for all the parentDeliverables for all the batches on the project
    const parentDeliverableBriefingFieldIds = pdbfResult.filter(
      (parentDeliverableBriefingFieldId) =>
        parentDeliverableIds.includes(
          pdbfEntities[parentDeliverableBriefingFieldId].parentDeliverableId
        )
    );

    // turn Id's into entities
    const parentDeliverables = parentDeliverableIds.map((id) => pdEntities[id]);
    const parentDeliverableBriefingFields =
      parentDeliverableBriefingFieldIds.map((id) => pdbfEntities[id]);

    return { parentDeliverables, parentDeliverableBriefingFields };
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    fetchParentDeliverablesAndBriefingFieldsByBatchId: (batchId) =>
      dispatch(fetchParentDeliverablesAndBriefingFieldsByBatchId(batchId)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);

  const batches = batchesByProjectId(state, projectId);

  const { parentDeliverables, parentDeliverableBriefingFields } =
    parentDeliverablesByBatchesSelector(state, batches);

  const tableData = tableDataSelector2(
    state,
    projectId,
    parentDeliverables,
    parentDeliverableBriefingFields
  );

  return {
    batches,
    parentDeliverableBriefingFields,
    parentDeliverables,
    projectId,
    tableData,
  };
};

const ProjectShowBriefing = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Briefing));
export default ProjectShowBriefing;
