const ColorSpan = ({ contentState, children, offsetKey }) => {
  const [blockKey] = offsetKey.split("-");
  const block = contentState.getBlockForKey(blockKey);
  const { start } = children[0].props;

  const style = block
    .getInlineStyleAt(start)
    .find((s) => s.startsWith("COLOR_"));

  const color = style ? style.slice(6).toLowerCase() : "inherit";

  return <span style={{ color }}>{children}</span>;
};

const ColorStrategy = (contentBlock, callback) => {
  contentBlock.findStyleRanges(
    (character) => {
      const hasColorStyle = character.getStyle().some((style) => {
        return style.startsWith("COLOR_");
      });
      return hasColorStyle;
    },
    (start, end) => {
      return callback(start, end);
    }
  );
};

export default {
  strategy: ColorStrategy,
  component: ColorSpan,
};
