import { connect } from "react-redux";
import FreelancerVersionHistory from "../components/FreelancerVersionHistory";
import { versionHistoryByDeliverableId } from "../../../../../../utils/entitySelector";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../../decorators/WithData";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    versionHistory: versionHistoryByDeliverableId(
      state,
      Number(ownProps.match.params.deliverableId)
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(FreelancerVersionHistory));
