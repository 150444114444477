import React from "react";
import PropTypes from "prop-types";
import styles from "./ProjectsInfo.module.scss";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../components/PageHeader";

const ProjectsInfo = (props) => {
  const workflowType = props.workflow.workflowType;
  const { accountName, orderFormNumber, parentAccountName } = props;

  let briefLinkPrefix = "";
  if (
    props.project.briefLink &&
    props.project.briefLink.substr(0, 4) !== "http"
  )
    briefLinkPrefix = "//";

  return (
    <div>
      <Helmet>
        <title>Project</title>
      </Helmet>
      <PageHeader
        title={"Edit project"}
        breadCrumbItems={[
          { url: "/admin", text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          {
            url: `/admin/projects/${props.project.projectId}`,
            text: `${props.project.projectName}`,
          },
          { text: "Project information" },
        ]}
      />
      <div className={styles.container}>
        <h1>{props.project.projectName}</h1>

        <div className={styles.header}>Brief link</div>
        <div className={styles.row}>
          {props.project.briefLink ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${briefLinkPrefix}${props.project.briefLink}`}
            >
              {props.project.briefLink}
            </a>
          ) : (
            "No brief link available"
          )}
        </div>

        <div className={styles.header}>Workflow</div>
        <div className={styles.row}>{props.workflow.workflowName}</div>
        {workflowType === "Localisation" && (
          <div>
            <div className={styles.header}>Source Language</div>
            <div className={styles.row}>{props.project.sourceLanguage}</div>
          </div>
        )}
        <div className={styles.header}>
          {workflowType === "Localisation"
            ? "Target languages"
            : "Project Languages"}
        </div>
        <div className={styles.row}>
          {props.projectLanguages.map((p) => p.languageCode).join(", ")}
        </div>

        <div className={styles.header}>Banned words</div>
        {Object.keys(props.bannedWords).map((key) => {
          return (
            <div className={styles.row} key={key}>
              <div className={styles.marginRight}>{key}</div>
              <span>{props.bannedWords[key].join(", ")}</span>
            </div>
          );
        })}

        <div className={styles.header}>Order form number</div>
        <div className={styles.row}>{props.orderFormNumber}</div>

        <div className={styles.header}>Content type</div>
        <div className={styles.row}>{props.contentTypeName}</div>
      </div>
    </div>
  );
};

ProjectsInfo.propTypes = {
  accountName: PropTypes.string,
  contentTypeName: PropTypes.string.isRequired,
  bannedWords: PropTypes.object.isRequired,
  parentAccountName: PropTypes.string,
  project: PropTypes.object.isRequired,
  projectLanguages: PropTypes.array.isRequired,
  orderFormNumber: PropTypes.number,
  workflow: PropTypes.object.isRequired,
};

export default ProjectsInfo;
