import React from "react";
import PropTypes from "prop-types";
import Redbox from "redbox-react";
import style from "redbox-react/lib/style";

const ErrorReporter = ({ error }) => (
  <Redbox
    error={error}
    style={{
      redbox: {
        ...style.redbox,
        background: "rgb(179, 215, 255)",
        color: "black",
        fontWeight: "bold",
      },
      linkToFile: {
        ...style.linkToFile,
        color: "black",
        fontWeight: "normal",
      },
    }}
  />
);

ErrorReporter.propTypes = {
  error: PropTypes.object,
};

export default ErrorReporter;
