import { connect } from "react-redux";
import Component from "./AssignmentsShow";
import ScrollToTop from "../../../../../../../../decorators/ScrollToTop";
import WithData from "../../../../../../../../decorators/WithData";
import {
  assignmentsByAssignmentGroupQuery,
  fetchAssignmentsSuccess,
} from "../../../../../../../../modules/assignments";
import {
  projectsByAssignmentQuery,
  fetchProjectsSuccess,
} from "../../../../../../../../modules/projects";
import {
  assignmentById,
  projectById,
} from "../../../../../../../../utils/entitySelector";
import { getGraphQL } from "../../../../../../../../../utils/fetch";

const initialQuery = `query AdminAssignmentEdit ($assignmentId: String, $assignmentGroupId: String, $includeArchivedAssignments: Boolean) {
  ${assignmentsByAssignmentGroupQuery}
  ${projectsByAssignmentQuery}
}`;

function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);
    dispatch(fetchAssignmentsSuccess(json.assignments));
    dispatch(fetchProjectsSuccess(json.projects));
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInitialData: (params) => dispatch(getInitialData(params)),
  };
}

function mapStateToProps(state, ownProps) {
  const {
    match: { params: { assignmentId, projectId, assignmentGroupId } = {} },
  } = ownProps;
  const assignment = assignmentById(state, assignmentId);
  const project = projectById(state, projectId);
  const listUrl = `/admin/projects/${project.projectId}/assignments/${assignmentGroupId}`;

  return {
    assignment,
    listUrl,
    project,
  };
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollToTop(WithData(Component)));

export default {
  Component: Container,
};
