import { getGraphQL } from "../../../../../../utils/fetch";
import {
  aiEnhancementQuery,
  fetchAiEnhancementSuccess,
} from "../../../../../modules/AiEnhancement";

export const initialQuery = `query AiEnhancementsQuery {
  ${aiEnhancementQuery}
}`;

export function getInitialData(data) {
  return async (dispatch) => {
    const {
      match: { params },
    } = data;

    const { aiEnhancement } = await getGraphQL(initialQuery, params);

    dispatch(fetchAiEnhancementSuccess(aiEnhancement));
  };
}
