import ClientAccounts from "../components/ClientAccounts";
import WithData from "../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import {
  accountsSelector,
  projectsTreeSelectorByOrderForm,
  orderFormsTreeSelectorByAccount,
} from "../../../../utils/entitySelector";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const projects = projectsTreeSelectorByOrderForm(state);
  const orderForms = orderFormsTreeSelectorByAccount(state);
  const accounts = accountsSelector(state);

  return { accounts, orderForms, projects };
};

const ClientAccountsWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ClientAccounts));
export default ClientAccountsWithData;
