import React, { Component } from "react";
import PropTypes from "prop-types";
import range from "lodash/range";
import styles from "./Pagination.module.scss";
import classNames from "classnames";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = { pager: {}, resultsPerPage: props.pageSize || 10 };
    this.limit10 = this.changePageLimits.bind(this, 10);
    this.limit50 = this.changePageLimits.bind(this, 50);
    this.limit100 = this.changePageLimits.bind(this, 100);
  }

  changePageLimits = (resultsPerPage) => {
    this.setState({ resultsPerPage }, () => {
      this.setPage(1);
    });
  };

  componentWillMount() {
    // set page if items array isn't empty
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.items !== prevProps.items) {
      this.setPage(this.props.initialPage);
    }
  }

  componentWillReceiveProps(nextProps) {
    // reset page if items array has changed
    if (this.props.items !== nextProps.items) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage(page) {
    if (page < 1) return;

    const { items } = this.props;
    const pageSize = this.state.resultsPerPage || this.props.pageSize;
    let { pager } = this.state;

    // get new pager object for specified page
    pager = this.getPager(items.length, page, pageSize);

    // get new page of items from items array
    const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    // update state
    this.setState({ pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfItems);
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    const TOTAL_PAGES = 10;

    let startPage, endPage;
    if (totalPages <= TOTAL_PAGES) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }

  render() {
    const { pager } = this.state;

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    const limit10Styles = classNames({
      [styles.active]: this.state.resultsPerPage === 10,
    });
    const limit50Styles = classNames({
      [styles.active]: this.state.resultsPerPage === 50,
    });
    const limit100Styles = classNames({
      [styles.active]: this.state.resultsPerPage === 100,
    });

    return (
      <div className={styles.container}>
        <ul className={styles.pagination}>
          <li className={pager.currentPage === 1 ? styles.disabled : ""}>
            {/* eslint-disable jsx-a11y/anchor-is-valid*/}
            <a onClick={() => this.setPage(1)}>First</a>
          </li>
          <li className={pager.currentPage === 1 ? styles.disabled : ""}>
            <a onClick={() => this.setPage(pager.currentPage - 1)}>Previous</a>
          </li>
          {pager.pages.map((page, index) => (
            <li
              key={index}
              className={pager.currentPage === page ? styles.active : ""}
            >
              <a onClick={() => this.setPage(page)}>{page}</a>
            </li>
          ))}
          <li
            className={
              pager.currentPage === pager.totalPages ? styles.disabled : ""
            }
          >
            <a onClick={() => this.setPage(pager.currentPage + 1)}>Next</a>
          </li>
          <li
            className={
              pager.currentPage === pager.totalPages ? styles.disabled : ""
            }
          >
            <a onClick={() => this.setPage(pager.totalPages)}>Last</a>
          </li>
        </ul>
        <div className={styles.limitsContainer}>
          <div> Results per page</div>
          <span data-limit className={limit10Styles} onClick={this.limit10}>
            10
          </span>
          <span> | </span>
          <span data-limit className={limit50Styles} onClick={this.limit50}>
            50
          </span>
          <span> | </span>
          <span data-limit className={limit100Styles} onClick={this.limit100}>
            100
          </span>
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
};

Pagination.defaultProps = {
  initialPage: 1,
};

export default Pagination;
