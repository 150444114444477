import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { renderRoutes } from "react-router-config";
import DownloadCSVOrExcel from "../../../../../../components/DownloadCSVOrExcel/v2";
import FeatureToggle from "../../../../../../components/FeatureToggle";
import PageHeader from "../../../../../../components/PageHeader";
import ProjectInfo from "../../../../../../components/PageHeader/ProjectInfo";
import makeCreateLink from "../../../../../../routes/_layouts/DashboardNav";
import DashboardFilters from "../../DashboardFilters";
import styles from "./Layout.module.scss";

const getDefaultLocation = (path) => {
  if (path.indexOf("overview") > -1) {
    return "overview";
  } else if (path.indexOf("completed") > -1) {
    return "completed";
  }

  return "inProgress";
};

const Layout = ({
  location: { pathname },
  project: { accountName, orderFormName, projectName, workflowId },
  route: { routes },
  projectId,
}) => {
  const [active, setActive] = useState(getDefaultLocation(pathname));
  const toggleTab = (e) => {
    setActive(e.target.dataset.nav);
  };

  const baseUrl = `/clients/projects/${projectId}`;

  const createNavItemStyles = (tab) =>
    classNames({
      [styles.active]: active === tab,
      [styles.navItem]: true,
    });

  const overviewStyles = createNavItemStyles("overview");
  const inProgressStyles = createNavItemStyles("inProgress");
  const completedStyles = createNavItemStyles("completed");

  const createLink = makeCreateLink(toggleTab);

  return (
    <div className={styles.content}>
      <PageHeader
        breadCrumbItems={[
          { url: "/clients", text: accountName },
          { text: `${orderFormName} | ${projectName}` },
        ]}
        title={projectName}
      >
        <div className={styles.infoContainer}>
          <ProjectInfo url={`${baseUrl}/project-info`} />
        </div>
      </PageHeader>
      <div className={styles.nav}>
        {workflowId === 29
          ? null
          : createLink(
              "Overview",
              `${baseUrl}/overview`,
              overviewStyles,
              "overview"
            )}
        {createLink("In progress", baseUrl, inProgressStyles, "inProgress")}
        {createLink(
          "Completed",
          `${baseUrl}/completed`,
          completedStyles,
          "completed"
        )}

        {active === "overview" && <DashboardFilters projectId={projectId} />}

        {active === "completed" && (
          <div className={styles.mergedOutputsContainer}>
            <FeatureToggle toggle="merged-outputs">
              <DownloadCSVOrExcel projectId={projectId} isMergedOutputs />
            </FeatureToggle>
          </div>
        )}
      </div>
      {renderRoutes(routes)}
    </div>
  );
};

Layout.propTypes = {
  featureToggles: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  project: PropTypes.shape({
    accountName: PropTypes.string,
    orderFormName: PropTypes.string,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  }).isRequired,
  route: PropTypes.object.isRequired,
};

export default Layout;
