import { getDeliverableIdsStr } from "../notifications/utils";

export function createPlagiarismMessage(deliverableIds = []) {
  const deliverableMessage = getDeliverableIdsStr(deliverableIds);

  return [
    `${deliverableMessage} submitted for a plagiarism check.`,
    "You will be notified if plagiarism is detected.",
  ];
}
