import { getGraphQL, postGraphQL } from "../../utils/fetch";
import { upsertData, removeData } from "../utils/normalize";
import handleErrors from "../utils/handleErrors";
import { createAction } from "redux-actions";
import { LOGIN_SUCCESS, RESET_INITIAL_STATE, FETCH_ME_SUCCESS } from "./me";
import { deletePersonAccounts } from "./personAccounts";
import {
  replacePersonNativeLanguagesSuccess,
  removePersonNativeLanguagesSuccess,
} from "./personNativeLanguages";
import {
  replacePersonRolesSuccess,
  removePersonRolesSuccess,
} from "./personRoles";

export const PersonType = Object.freeze({
  Admin: "In-house",
  Client: "Client",
  Freelancer: "Freelancer",
});

// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const personFields = [
  "address",
  "archived",
  "bankDetails",
  "businessName",
  "companyRegNumber",
  "email",
  "firstName",
  "lastName",
  "paymentMethod",
  "payPalEmail",
  "personId",
  "personType",
  "role",
  "ukResident",
  "vatNumber",
  "vatRegistered",
];

const readOnlyFields = ["fullName", "roles", "languages"];

export const peopleByDefaultAssigneeQuery = `peopleByDefaultAssignee(projectIds: $projectId) {
  personId, firstName, lastName, email
}`;

export const peopleByPersonIdQuery = `person (personId: $personId) {
  ${personFields}
}`;

export const peopleQuery = `people {
  personId, firstName, lastName, email, vatNumber, archived, personType, role, paymentMethod, payPalEmail
}`;

export const peopleQueryV2 = `peopleV2 {
  email,
  firstName,
  fullName,
  languages,
  lastName,
  paymentMethod,
  payPalEmail
  personId,
  roles,
  personType,
  vatNumber,
  archived
}`;

export const peopleByProjectAssignmentsQuery = `peopleByAssignment (projectIds: $projectId) {
  personId, firstName, lastName, email
}`;

export const peopleByPeopleIdsQuery = `peopleByIds (personIds: $personIds) {
  personId, firstName, lastName, email, personType
}`;

export const peopleByAssignmentQuery = `peopleByAssignment (assignmentId: $assignmentId) {
  personId, firstName, lastName, email
}`;

export const peopleByAssignmentGroupQuery = `peopleByAssignment (assignmentGroupId: $assignmentGroupId) {
  personId, firstName, lastName, email
}`;

export const commentersAssignmentIdQuery = `people (commentersAssignmentId: $assignmentId) {
  personId, firstName, lastName
}`;

export const commentersAssignmentGroupQuery = `people (commentersAssignmentGroupId: $assignmentGroupId) {
  personId, firstName, lastName
}`;

export const commentersDeliverableIdQuery = `people (commentersDeliverableId: $deliverableId) {
  personId, firstName, lastName
}`;

export const commentersByBatchIdQuery = `people (commentersBatchId: $batchId) {
  personId, firstName, lastName
}`;

export const QUERY_PEOPLE_BY_IDS = `
  query FetchPeopleByIds ($personIds: String){
    ${peopleByPeopleIdsQuery}
  }
`;

// ------------------------------------
// --- Helper functions
// ------------------------------------

export async function fetchPeopleByIds({ personIds }) {
  if (!personIds || personIds.length === 0) {
    return Promise.resolve([]);
  }

  const { peopleByIds } = await getGraphQL(QUERY_PEOPLE_BY_IDS, {
    personIds,
  });

  return peopleByIds;
}

// ------------------------------------
// Constants
// ------------------------------------
const FETCH_PERSON_SUCCESS = "FETCH_PERSON_SUCCESS";
const FETCH_PEOPLE_SUCCESS = "FETCH_PEOPLE_SUCCESS";
const ARCHIVE_PERSON_SUCCESS = "ARCHIVE_PERSON_SUCCESS";
const UPDATE_PERSON_SUCCESS = "UPDATE_PERSON_SUCCESS";
export const CREATE_PEOPLE_SUCCESS = "CREATE_PEOPLE_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPersonSuccess = createAction(FETCH_PERSON_SUCCESS);
export const fetchPeopleSuccess = createAction(FETCH_PEOPLE_SUCCESS);
export const updatePersonSuccess = createAction(UPDATE_PERSON_SUCCESS);
export const createPersonSuccess = createAction(CREATE_PEOPLE_SUCCESS);
export const archivePersonSuccess = createAction(ARCHIVE_PERSON_SUCCESS);

export const searchPeople = (input) => {
  return async (dispatch, getState) => {
    const query = `query searchPeople($search: String){
      people(search: $search) {
        personId, firstName, lastName, email
      }
    }`;

    const json = await getGraphQL(query, { search: input });
    dispatch(fetchPeopleSuccess(json.people));
  };
};

export function createPerson(data, history) {
  return (dispatch, getState) => {
    const query = `mutation createPerson($person: PersonInput, $personNativeLanguages: [PersonNativeLanguagesInput]) {
      createPerson (person: $person, personNativeLanguages: $personNativeLanguages) {
        person { personId, firstName, lastName, email, personType, account, role, paymentMethod, payPalEmail, vatNumber },
        personNativeLanguages { personId, nativeLanguage }
      }
    }`;

    const { nativeLanguage, ...person } = data;
    const personNativeLanguages = nativeLanguage.map((nL) => ({
      nativeLanguage: nL,
    }));
    return postGraphQL(query, { person, personNativeLanguages }, "createPerson")
      .then((json) => {
        dispatch(createPersonSuccess(json.person));
        dispatch(
          replacePersonNativeLanguagesSuccess({
            personNativeLanguages: json.personNativeLanguages,
            personId: json.person.personId,
          })
        );
        history.push(`/admin/people/${json.person.personId}`);
      })
      .catch((err) => handleErrors(err));
  };
}

export function updatePerson(data, history, { successUrl } = {}) {
  return (dispatch, getState) => {
    const query = `mutation updatePerson($person: PersonInput, $personNativeLanguages: [PersonNativeLanguagesInput]) {
      updatePerson(person: $person, personNativeLanguages: $personNativeLanguages) {
        person { personId, firstName, lastName, email, personType, account, role, paymentMethod, payPalEmail, vatNumber },
        personNativeLanguages { personId, nativeLanguage }
        personRoles { personId, role }
      }
    }`;

    const { nativeLanguage, ...person } = data;
    const { personId } = person;
    const personNativeLanguages = nativeLanguage
      ? nativeLanguage.map((nL) => ({ personId, nativeLanguage: nL }))
      : null;

    // Delete calculated fields
    readOnlyFields.forEach((field) => delete person[field]);

    return postGraphQL(query, { person, personNativeLanguages }, "updatePerson")
      .then((json) => {
        dispatch(updatePersonSuccess(json.person));
        dispatch(deletePersonAccounts(json.person));
        dispatch(replacePersonRolesSuccess(json.personRoles));
        dispatch(
          replacePersonNativeLanguagesSuccess({
            personId,
            personNativeLanguages: json.personNativeLanguages,
          })
        );
        history.push(successUrl || `/admin/people/${json.person.personId}`);
      })
      .catch((err) => handleErrors(err));
  };
}

export function archivePerson(personId, history) {
  return (dispatch, getState) => {
    const query = `mutation archivePerson($input: PersonInput) {
      archivePerson(input: $input) {
        personId, firstName, lastName, email, personType, role, paymentMethod, payPalEmail, vatNumber
      }
    }`;
    return postGraphQL(query, { input: { personId } }, "archivePerson")
      .then((json) => {
        history.push("/admin/people/list/freelancers");
        dispatch(archivePersonSuccess(personId));
        dispatch(removePersonNativeLanguagesSuccess(personId));
        dispatch(removePersonRolesSuccess(personId));
      })
      .catch((err) => {
        // TODO: show a error message somewhere
        console.log("Error archiving person", err.errors, err.message);
        return err;
      });
  };
}

export function searchFreelancers(input) {
  return (dispatch, getState) => {
    const query = `query searchFreelancers($search: String, $personType: String){
      people(search: $search, personType: $personType) {
        ${personFields}
      }
    }`;

    return getGraphQL(query, { search: input, personType: "Freelancer" }).then(
      (json) => {
        dispatch(fetchPeopleSuccess(json.people));
      }
    );
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
export const peopleActionHandlers = {
  [RESET_INITIAL_STATE]: () => peopleInitialState,
  [FETCH_ME_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "personId"),
  [LOGIN_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "personId"),
  [FETCH_PERSON_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "personId"),
  [FETCH_PEOPLE_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "personId"),
  [ARCHIVE_PERSON_SUCCESS]: (state, { payload }) => removeData(state, payload),
  [UPDATE_PERSON_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "personId"),
  [CREATE_PEOPLE_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "personId"),
};

export const peopleInitialState = { entities: {}, result: [] };
