import { connect } from "react-redux";
import ProfileEdit from "../components/ProfileEdit";
import { getInitialData } from "../../../../../components/Profile";
import WithData from "../../../../../decorators/WithData";
import { updatePerson } from "../../../../../modules/people";
import {
  personNativeLanguagesByPersonId,
  personRolesSelector,
} from "../../../../../utils/entitySelector";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () =>
      dispatch((dispatch, getState) => {
        const { me: personId } = getState();
        return dispatch(getInitialData({ personId }));
      }),
    updateProfile: (data) =>
      dispatch(
        updatePerson(data, ownProps.history, {
          successUrl: "/freelancer/profile",
        })
      ),
  };
};

const mapStateToProps = (state, ownProps) => {
  const personId = Number(state.me);
  const personNativeLanguages = personNativeLanguagesByPersonId(
    state,
    personId
  ).map(({ nativeLanguage }) => nativeLanguage);
  const personRoles = personRolesSelector(state, personId);
  const personRolesDropdown = personRoles.map(({ role }) => role);
  const person = {
    ...state.people.entities[personId],
    nativeLanguage: personNativeLanguages,
    role: personRolesDropdown,
  };

  return {
    personId,
    person,
  };
};

const ProfileEditWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ProfileEdit));
export default ProfileEditWithData;
