import React, { Component } from "react";
import PropTypes from "prop-types";
import ErrorMessage from "../../components/ErrorMessage";

/*
  Wrap your view component inside the redux connect decorator
  like so:

  connect(mapStateToProps, mapDispatchToProps)(WithData(Show))
  ---------------------------------------------^^^^^^^

  This will result in your page fetching data from a props.getInitialData
  every time you visit the page. When the data has been fetched a 'dataReady'
  prop is passed to your component. You can handle how you want to display the loading
  on each individual page.
  (i.e. display a giant loading screen or display loading indicator
    wherever specific data is updating)

*/

function withData(WrappedComponent) {
  class WithData extends Component {
    state = {
      error: false,
      ready: false,
      asyncFetched: false,
    };

    isUnmounted = false;

    setState(state) {
      if (!this.isUnmounted) {
        super.setState(state);
      }
    }

    componentDidMount = async () => {
      const params = this.props.match && this.props.match.params;
      await this.getInitialData(params);
      this.getAsyncData();
    };

    componentWillUnmount() {
      this.isUnmounted = true;
    }

    getInitialData = async (params) => {
      const getInitialData =
        this.props.getInitialData || (() => Promise.resolve());
      try {
        await getInitialData(params);
        return this.setState({ ready: true });
      } catch (err) {
        this.setState({ error: true, errorMessage: err.stack });
      }
    };

    getAsyncData = async () => {
      const fetchAsync = this.props.getAsyncData || (() => Promise.resolve());
      try {
        await fetchAsync();
        this.setState({ asyncFetched: true });
      } catch (err) {
        this.setState({ error: true, errorMessage: err.stack });
      }
    };

    render() {
      if (this.state.error) {
        return (
          <ErrorMessage
            footer="Please try again at a later time."
            main={this.state.errorMessage}
            title="Sorry there was an error retrieving data:"
          />
        );
      }

      return (
        <WrappedComponent
          {...this.props}
          asyncFetched={this.state.asyncFetched}
          dataReady={this.state.ready}
        />
      );
    }
  }

  WithData.propTypes = {
    getAsyncData: PropTypes.func,
    getInitialData: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }),
  };

  return WithData;
}

export default withData;
