import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Input from "../../../../../../../../components/Input";
import { Field } from "redux-form";
import styles from "./LanguageRates.module.scss";

const returnNumber = (val) => Number(val) || 0;

/**
 * Renders the rate band rates for a given language
 */
const LanguageRates = ({
  fetchLanguageRatesAndVolumes,
  languageCode,
  languageName,
  projectId,
  stages,
  submitting,
  rateBands,
}) => {
  const languageColSpan = stages.length + 2;
  const [isOpen, setOpen] = useState(false);

  // Fetch data
  const hasFetched = useRef(false);
  useEffect(() => {
    if (!hasFetched.current && isOpen) {
      fetchLanguageRatesAndVolumes(projectId, languageCode);
      hasFetched.current = true;
    }
  }, [
    fetchLanguageRatesAndVolumes,
    isOpen,
    languageCode,
    projectId,
    hasFetched,
  ]);

  return [
    <tr
      key={languageCode}
      onClick={() => setOpen(!isOpen)}
      className={styles.languageCellHeader}
    >
      <td className={styles.languageCell} colSpan={languageColSpan}>
        <div className={styles.inner}>
          {languageName}
          {<span className={styles.expand}>{isOpen ? "–" : "+"}</span>}
        </div>
      </td>
    </tr>,
    isOpen &&
      rateBands.map(({ rateBandId, rateBandName, volume }) => (
        <tr key={rateBandId}>
          <td>{rateBandName}</td>
          {stages.map(({ stageId }) => (
            <td key={stageId}>
              <Field
                component={Input}
                disabled={submitting}
                max="999"
                min="0"
                name={`rateBandItems[${rateBandId}][${stageId}][${languageCode}]`}
                parse={returnNumber}
                step="0.01"
                type="number"
              />
            </td>
          ))}
          <td>
            <Field
              component={Input}
              disabled={submitting}
              min="0"
              name={`volumes[${rateBandId}][${languageCode}]`}
              parse={returnNumber}
              step="1"
              type="number"
            />
          </td>
        </tr>
      )),
  ];
};

LanguageRates.prototypes = {
  languageCode: PropTypes.string.isRequired,
  languageName: PropTypes.string.isRequired,
  stages: PropTypes.arrayOf({
    stageId: PropTypes.number.isRequired,
  }).isRequired,
  submitting: PropTypes.bool,
  projectId: PropTypes.number.isRequired,
  rateBands: PropTypes.arrayOf(
    PropTypes.shape({
      rateBandId: PropTypes.number.isRequired,
      rateBandName: PropTypes.string.isRequired,
    })
  ),
  rateBandVolume: PropTypes.object.isRequired,
};

export default memo(LanguageRates);
