import React from "react";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import parseQuery from "../../utils/parseQuery";

const QuillRedirect = ({ to }) => {
  const { search, pathname } = useLocation();
  const { redirect } = parseQuery(search);

  // different logic for direction between login -> layout page
  const redirectToLoginPage = to === "/";

  // encode any query params to live through the login page redirect
  if (redirectToLoginPage) {
    const redirectStr = `${pathname}${search}`;
    const urlEncoded = encodeURIComponent(redirectStr);
    return <Redirect to={`/?redirect=${urlEncoded}`} />;
  }

  // if a redirect is present, decode safe URL otherwise use default
  const urlDecoded = redirect ? decodeURIComponent(redirect) : to;

  return <Redirect to={urlDecoded} />;
};

export default QuillRedirect;
