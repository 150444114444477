import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";
import { fetchEmailLogsSuccess, emailLogsQueryByProjectId } from "./emailLogs";
import { showSuccessMessage } from "./messagesV2";
import { emailTemplates } from "./automatedReminders";

const MANUAL_REMINDER_SUCCESS = "MANUAL_REMINDER_SUCCESS";
export const fetchManualReminderSuccess = createAction(MANUAL_REMINDER_SUCCESS);

export const manualRemindersActionHandlers = {
  [RESET_INITIAL_STATE]: () => manualRemindersInitialState,
  [MANUAL_REMINDER_SUCCESS]: (state, { payload }) => {
    return upsertData(state, payload, "manualReminderId");
  },
};

const fetchLogsQuery = `query refetchLogsQuery ($projectId: String) {
  ${emailLogsQueryByProjectId}
}`;

export function createManualReminder(assignmentGroupId, projectId) {
  return async (dispatch) => {
    const query = `mutation createManualReminder ($assignmentGroupId: Int) {
      createManualReminder (assignmentGroupId: $assignmentGroupId) 
      {
       assignmentGroupId, email, emailTemplate
      }
      
    }`;
    await postGraphQL(query, { assignmentGroupId }, "createManualReminder");
    const { emailLogs } = await getGraphQL(fetchLogsQuery, { projectId });
    const { email, emailTemplate } = emailLogs.find(
      (el) => el.assignmentGroupId === assignmentGroupId
    );

    dispatch(
      showSuccessMessage(
        `An ${emailTemplates[emailTemplate]} reminder was sent to ${email}`
      )
    );
    dispatch(fetchEmailLogsSuccess(emailLogs));
  };
}

export const manualRemindersInitialState = { entities: {}, result: [] };
