import ProjectsClientBatchStage from "../../../../../../../../../../components/ClientBatchStage";
import WithData from "../../../../../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { createTransitionLogsFromDeliverables } from "../../../../../../../../../../modules/transitionLogs";
import parseQuery from "../../../../../../../../../../utils/parseQuery";
import {
  accountById,
  batchById,
  orderFormById,
  projectById,
  stageById,
  batchParentDeliverableEntitiesSelector,
  projectBriefingFieldsSelector,
  adminBatchDeliverablesSelector,
} from "../../../../../../../../../../utils/entitySelector";
import { getTransitionsForListPage } from "../../../../../../../../../../utils/entitySelectors/transitions";
import { getInitialData } from "../modules/getInitialData";
import { unresolvedCommentGroupsSelector } from "../../../../../../../../../../modules/commentGroups";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    createTransitionLogsFromDeliverables: (transition, selectedItems) =>
      dispatch(createTransitionLogsFromDeliverables(transition, selectedItems)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { batchId, stageId, projectId } = ownProps.match.params;
  const parsedSearch = parseQuery(ownProps.location.search);

  const { batchName } = batchById(state, batchId);
  const project = projectById(state, projectId);
  const stage = stageById(state, stageId);
  const { accountId, orderFormName } = orderFormById(
    state,
    project.orderFormId
  );
  const { accountName } = accountById(state, accountId);

  const transitions = getTransitionsForListPage(state, stageId);

  const selectedLanguage = parsedSearch.language;

  const batchParentDeliverableEntities = batchParentDeliverableEntitiesSelector(
    state,
    Number(batchId)
  );
  const briefingFields = projectBriefingFieldsSelector(
    state,
    Number(projectId)
  );
  const deliverables = adminBatchDeliverablesSelector(
    state,
    batchParentDeliverableEntities,
    briefingFields,
    stage,
    selectedLanguage
  );

  const unresolvedCommentGroups = unresolvedCommentGroupsSelector(
    state.commentGroups
  );

  return {
    accountName,
    baseUrlRoot: "admin",
    batchName,
    briefingFields,
    deliverables,
    me: state.me,
    orderFormName,
    project,
    stageName: stage.stageName,
    transitions,
    unresolvedCommentGroups,
  };
};

const ProjectsClientBatchStageWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ProjectsClientBatchStage));
ProjectsClientBatchStageWithData.getInitialData = getInitialData;
export default ProjectsClientBatchStageWithData;
