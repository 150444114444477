import React from "react";
import PropTypes from "prop-types";
import styles from "./QuillField.module.scss";

const QuillField = (props) => {
  const { labelText, value, customStyles, children } = props;

  return (
    <div className={styles.container}>
       <div className={styles.label}>{labelText}</div>
      <div className={customStyles || styles.value}>{children || value}</div>
    </div>
  );
};

QuillField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelText: PropTypes.string,
  customStyles: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default QuillField;
