import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === "LOGIN_SUCCESS" || action.type === "FETCH_ME_SUCCESS") {
      return action.payload;
    }

    return action;
  },
});

export default sentryReduxEnhancer;
