import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styles from "./Errors.module.scss";

class Errors extends Component {
  constructor(props) {
    super(props);
    this.state = { containerStyles: styles.container };
    this.errorsRoot = document.getElementById("errors");
    this.container = document.createElement("div");
  }

  componentDidMount() {
    this.errorsRoot.appendChild(this.container);

    // We apply delays to styling so that css transitions are applied correctly
    setTimeout(
      () =>
        this.setState({
          containerStyles: `${styles.container} ${styles.inView}`,
        }),
      0
    );
  }

  componentWillUnmount() {
    this.errorsRoot.removeChild(this.container);
    if (this.props.errors.display) {
      this.props.hideErrors();
    }
  }

  /**
   * Optional callback handler if the error has a follow on action
   */
  callbackHandler = () => {
    const {
      errors: {
        callback: { fn, data },
      },
      hideErrors,
    } = this.props;

    // run the callback with any specified data
    fn(data);

    // fade out of view
    this.setState({
      containerStyles: styles.container,
    });

    setTimeout(hideErrors, 1000);
  };

  renderMessages = () => {
    const {
      errors: { msg, callback },
    } = this.props;
    const messages = Array.isArray(msg) ? msg : [msg];

    // default error messages
    const errorRows = messages.map((msg, idx) => (
      <p key={idx} className={styles.errorText}>
        {msg}
      </p>
    ));

    // optionally add an error row which has a callback (useful for passing a function from another component)
    if (callback) {
      errorRows.push(
        <p
          key="callback-fn"
          className={`${styles.errorText} ${styles.onClick}`}
          onClick={this.callbackHandler}
        >
          {callback.message}
        </p>
      );
    }

    return errorRows;
  };

  render() {
    /* eslint-disable max-len */
    if (!this.props.errors.display) return null;

    return ReactDOM.createPortal(
      <div className={this.state.containerStyles}>
        <div className={styles.header}>
          <p className={styles.errorText}>An error occurred:</p>

          <div className={styles.dismiss} onClick={this.props.hideErrors}>
            <svg height="20" viewBox="0 0 16 16" width="20">
              <path
                d="M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z"
                fill="#F9F9F9"
              />
            </svg>
          </div>
        </div>

        {this.renderMessages()}
      </div>,
      this.container
    );
    /* eslint-enable max-len */
  }
}

Errors.propTypes = {
  errors: PropTypes.shape({
    display: PropTypes.bool.isRequired,
    msg: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired,
    ]),
  }),
  hideErrors: PropTypes.func,
};

export default Errors;
