import React from "react";
import classNames from "classnames";
import BatchFormInputs from "./BatchFormInputs/BatchFormInputs";
import styles from "./index.module.scss";
import Icon from "../../../../../../../../components/Icon";

function getInitialValues(inputKey: string | string[], inputValue: string) {
  if (typeof inputKey === "string") return { [inputKey]: inputValue };

  // if the key is not a string it's only used for defaultDeadlines
  return {
    defaultDeadlines: {
      [inputKey[1]]: inputValue,
    },
  };
}

function getKey(key: string | string[]): string {
  if (typeof key === "string") return key;

  // only a default deadline input will have an array as the key
  return `defaultDeadlines[${key[1]}]`;
}

type BatchInputType = {
  setActiveField: (arg0: string) => void;
  updateOrCreate: Function;
  activeField: string;
  label: string;
  keyValue: string | string[] | any[];
  inputValue: string;
  inputType: string;
  readOnlyValue: string;
};

export function BatchInput({
  setActiveField,
  updateOrCreate,
  activeField,
  label,
  keyValue,
  inputValue,
  inputType,
  readOnlyValue,
}: BatchInputType) {
  const inputKey = getKey(keyValue);
  const initialValues = getInitialValues(keyValue, inputValue);

  const isBeingEdited = classNames({
    [styles.inputForm]: true,
    [styles.active]: inputKey === activeField,
  });

  const editField = (e) => {
    const inputKey = e.currentTarget.getAttribute("data-input-key");
    setActiveField(inputKey);
  };

  return (
    <div
      key={inputKey}
      className={isBeingEdited}
      data-input-key={inputKey}
      onClick={editField}
    >
      <span className={styles.inputLabel}>{label}:</span>
      {inputKey === activeField ? (
        <BatchFormInputs
          // @ts-ignore
          form={inputKey}
          initialValues={initialValues}
          inputKey={inputKey}
          inputValue={inputValue}
          onSubmit={updateOrCreate}
          setActiveField={setActiveField}
          type={inputType}
        />
      ) : (
        <div key={inputKey} className={styles.editButtonWidth}>
          <span className={styles.newFieldValue}>{readOnlyValue}</span>
          <Icon name="Edit" size="md" fill="#353F54" />
        </div>
      )}
    </div>
  );
}
