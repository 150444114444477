import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";

const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
export const updateLocation = createAction(LOCATION_CHANGE);

export const locationActionHandlers = {
  [RESET_INITIAL_STATE]: () => locationInitialState,
  [LOCATION_CHANGE]: (state, { payload }) => String(payload),
};

export const locationInitialState = {};
