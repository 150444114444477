import React, { Component } from "react";
import PropTypes from "prop-types";
import OverlayLoading from "../../../../../../../../components/OverlayLoading/v1";
import AssignmentHeaders from "./AssignmentHeaders";
import StageAssignments from "./StageAssignments";
import ReassignmentForm from "../../_components/ReassignmentForm";
import Modal from "../../../../../../../../components/Modal";
import UpdateAssignmentsForm from "../../../../../../../../components/BriefingFieldContainer/components/UpdateAssignmentsForm";
import { ModalType } from "../../../../../../../../modules/modal";
import { formatDateObject } from "../../../../../../../../utils/date";
import Messages from "../../../../../../../../components/Messages/v1";

class InProduction extends Component {
  constructor(props) {
    super(props);
    this.hideModal = this.setModalState.bind(this, false);

    this.state = {
      showReassignmentModal: false,
      selectedAssignment: null,
      filter: 0,
      overdueFilter: false,
      selectedBatchId: null,
      selectedAssignmentGroups: [],
      stageStates: null,
      allSelected: false,
    };
  }

  get assigmentGroupIds() {
    return this.props.stageAssignmentDetails.flatMap(({ assignmentGroups }) => {
      return assignmentGroups.map(({ assignmentGroupId }) => assignmentGroupId);
    });
  }

  setModalState = (showReassignmentModal, selectedAssignment = null) => {
    const batchId =
      selectedAssignment.batchId && Number(selectedAssignment.batchId);
    const { showModal, hideModal } = this.props;
    this.setState(
      { showReassignmentModal, selectedAssignment, selectedBatchId: batchId },
      () => {
        if (showReassignmentModal) {
          showModal({ type: "Reassignment" });
        } else {
          hideModal();
        }
      }
    );
  };

  setReminderState = (type, selectedReminder = null) => {
    const batchId =
      selectedReminder.batchId && Number(selectedReminder.batchId);
    const { showModal, hideModal } = this.props;
    this.setState({ selectedReminder, selectedBatchId: batchId }, () => {
      if (selectedReminder) {
        showModal({ type });
      } else {
        hideModal();
      }
    });
  };

  toggleSort = (sortName) => {
    this.setState((prevState) => ({
      overdueFilter: sortName ? !prevState.overdueFilter : false,
      filter: sortName ? 0 : (prevState.filter + 1) % 3,
    }));
  };

  initializeStateFromProps(stages) {
    return stages.reduce((acc, stage) => {
      acc[stage.stageId] = {
        selected: false,
        groups: stage.assignmentGroups.reduce((groupAcc, group) => {
          groupAcc[group.assignmentGroupId] = false;
          return groupAcc;
        }, {}),
      };
      return acc;
    }, {});
  }

  handleGroupChange = async (groupId) => {
    this.props.markAssignmentGroups(groupId);
  };

  handleStageChange = (stageId) => {
    this.props.markAllStageAssignmentGroups(
      stageId,
      this.props.selectedBatchId,
      this.props.selectedLanguage,
      this.props.selectedAssigneeId
    );
  };

  getMarkedAssignmentGroupIds() {
    let markedAssignmentGroupIds = [];

    this.props.stageAssignmentDetails.forEach((stage) => {
      stage.assignmentGroups.forEach((group) => {
        if (group.marked) {
          markedAssignmentGroupIds.push(group.assignmentGroupId);
        }
      });
    });

    return markedAssignmentGroupIds;
  }

  removeAllMarkedIds(assignmenGroupIds) {
    assignmenGroupIds.forEach((id) => this.props.markAssignmentGroups(id));
  }

  updateAssignmentGroups = async () => {
    const {
      form: values,
      hideModal,
      showSuccessMessage,
      updateAssignmentGroups,
    } = this.props;

    const markedAssignmentGroupIds = this.getMarkedAssignmentGroupIds();

    try {
      // ids are returned if they are successfully updated
      const updatedAssignmentGroupIds = await updateAssignmentGroups(
        markedAssignmentGroupIds,
        values.deadline
      );
      // build a list of messages to show based on which fields were changed
      const messages = [];
      if (values.deadline)
        messages.push(
          `The deadline for assignment groups was changed to: ${formatDateObject(
            new Date(values.deadline)
          )}`
        );

      showSuccessMessage(messages);
      hideModal();
      this.removeAllMarkedIds(updatedAssignmentGroupIds);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    if (
      !this.props.dataReady ||
      !this.props.projectLanguages ||
      this.props.projectLanguages.length === 0
    )
      return <OverlayLoading />;

    const baseUrl = `/admin/projects/${this.props.project.projectId}`;
    const { filter } = this.state;
    const { messages } = this.props;

    const selectedFilter =
      filter === 0 ? "normal" : filter === 1 ? "alphabetical" : "reverse";

    return (
      <div>
        {messages.display && <Messages />}
        <Modal
          body={
            <UpdateAssignmentsForm
              assignmentGroups
              hideModal={this.props.hideModal}
              onSubmit={this.updateAssignmentGroups}
            />
          }
          type={ModalType.UpdateAssignmentGroups}
        />
        {this.props.modal.display && this.state.showReassignmentModal && (
          <ReassignmentForm
            assignmentGroup={this.state.selectedAssignment}
            selectedBatchId={this.state.selectedBatchId}
            location={this.props.location}
            match={this.props.match}
            project={this.props.project}
          />
        )}
        <AssignmentHeaders
          batches={this.props.batchOptions}
          filter={selectedFilter}
          history={this.props.history}
          languageNames={this.props.languageNames}
          location={this.props.location}
          overdueFilter={this.state.overdueFilter}
          projectId={this.props.project.projectId}
          projectLanguages={this.props.projectLanguages}
          selectBatch={this.selectBatch}
          selectedBatch={this.props.selectedBatch}
          selectLanguage={this.props.selectLanguage}
          automatedReminderActive={this.props.automatedReminderActive}
          toggleSort={this.toggleSort}
          assigneeOptions={this.props.assigneeOptions}
          selectedAssigneeId={this.props.selectedAssigneeId}
        />
        {this.props.stageAssignmentDetails.map((stage) => (
          <StageAssignments
            handleStageChange={this.handleStageChange}
            handleGroupChange={this.handleGroupChange}
            key={stage.stageId}
            {...this.props.stageAssignmentDetails.find(
              (s) => s.stageId === stage.stageId
            )}
            baseUrl={baseUrl}
            filter={selectedFilter}
            overdueFilter={this.state.overdueFilter}
            selectedBatch={this.props.selectedBatch}
            selectedLanguage={this.props.selectedLanguage}
            selectedAssigneeId={this.props.selectedAssigneeId}
            createManualReminder={this.props.createManualReminder}
            setModalState={this.setModalState}
            setReminderState={this.setReminderState}
            currencyCode={this.props.currencyCode}
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
            modalType={this.props.modalType}
            selectedReminder={this.state.selectedReminder}
            automatedReminderActive={this.props.automatedReminderActive}
            stageDisabled={stage.disabled}
          />
        ))}
      </div>
    );
  }
}

InProduction.propTypes = {
  assigneeOptions: PropTypes.object,
  batchOptions: PropTypes.array.isRequired,
  dataReady: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  languageNames: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
  project: PropTypes.shape({
    projectId: PropTypes.number,
  }).isRequired,
  projectLanguages: PropTypes.array.isRequired,
  selectLanguage: PropTypes.func,
  selectedAssigneeId: PropTypes.number,
  selectedBatch: PropTypes.object,
  selectedLanguage: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  stageAssignmentDetails: PropTypes.arrayOf(
    PropTypes.shape({
      assignmentGroups: PropTypes.array.isRequired,
      stageId: PropTypes.number.isRequired,
      stageName: PropTypes.string.isRequired,
      stagePosition: PropTypes.number.isRequired,
      stageType: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default InProduction;
