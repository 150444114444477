import { getGraphQL } from "../../../../utils/fetch";
import {
  attachmentsByParentDeliverableId,
  fetchAttachmentsSuccess,
} from "../../../modules/attachments";

export const initialQuery = `query ProjectShowBriefing($parentDeliverableId: String) {
  ${attachmentsByParentDeliverableId}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const { attachments } = await getGraphQL(
      initialQuery,
      params,
      url,
      cookies
    );
    dispatch(fetchAttachmentsSuccess(attachments));
  };
}
