// intercomBootWithStore.ts
interface PeopleEntity {
  firstName: string;
  lastName: string;
  email: string;
  personType: string;
}

interface People {
  entities: Record<string, PeopleEntity | undefined>;
}

interface Store {
  getState: () => {
    people: People;
    me: string;
  };
}

const intercomBootWithStore = (store: Store) => {
  const { people, me } = store.getState();
  const { firstName, lastName, email, personType } = people.entities[me] || {};

  if (email && personType !== "Client") {
    setTimeout(() => {
      if ((window as any).Intercom) {
        (window as any).Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id:
            process.env.REACT_APP_INTERCOM_ID ||
            (window as any).__REACT_APP_INTERCOM_ID__,
          name: `${firstName} ${lastName}`, // Full Name
          email: `${email}`, // Email Address,
          user_id: `scribe_${email}`, // User ID
        });

        (window as any).Intercom("update");
      }
    }, 1000); // Delay of 1 second
  }
};

export default intercomBootWithStore;
