import { createAction } from "redux-actions";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import handleErrors from "../utils/handleErrors";
import { removeData, upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";
import { hideModal } from "./modal";
// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const orderFormFields = [
  "accountId",
  "orderFormId",
  "orderFormNumber",
  "orderFormName",
  "entity",
];

export const orderFormByIdQuery = `orderForm (orderFormId: $orderFormId) { ${orderFormFields} }`;
export const orderFormsByAccountIdQuery = `orderForms (accountIds: $accountId) { ${orderFormFields} }`;
export const orderFormsByAssignmentGroupIdQuery = `orderForms (assignmentGroupId: $assignmentGroupId) {
  ${orderFormFields}
}`;
export const orderFormsByParentAccountQuery = `orderForms (parentAccountIds: $parentAccountId) { ${orderFormFields} }`;
export const orderFormsQuery = `orderForms {
  ${orderFormFields}
}`;

export const orderFormsByProjectQuery = `orderForms (projectId: $projectId) {
  ${orderFormFields}
}`;

export const orderFormsByDeliverableQuery = `orderForms (deliverableId: $deliverableId) {
  ${orderFormFields}
}`;

// ------------------------------------
// Constants
// ------------------------------------
const FETCH_ORDER_FORM_SUCCESS = "FETCH_ORDER_FORM_SUCCESS";
const FETCH_ORDER_FORMS_SUCCESS = "FETCH_ORDER_FORMS_SUCCESS";
const FETCH_ORDER_FORMS_BY_PERSON_ID_SUCCESS =
  "FETCH_ORDER_FORMS_BY_PERSON_ID_SUCCESS";
export const CREATE_ORDER_FORM_SUCCESS = "CREATE_ORDER_FORM_SUCCESS";
const UPDATE_ORDER_FORM_SUCCESS = "UPDATE_ORDER_FORM_SUCCESS";
const ARCHIVE_ORDER_FORM_SUCCESS = "ARCHIVE_ORDER_FORM_SUCCESS";
// ------------------------------------
// Actions
// ------------------------------------
export const fetchOrderFormSuccess = createAction(FETCH_ORDER_FORM_SUCCESS);
export const fetchOrderFormsSuccess = createAction(FETCH_ORDER_FORMS_SUCCESS);
export const createOrderFormSuccess = createAction(CREATE_ORDER_FORM_SUCCESS);
export const updateOrderFormSuccess = createAction(UPDATE_ORDER_FORM_SUCCESS);
export const archiveOrderFormSuccess = createAction(ARCHIVE_ORDER_FORM_SUCCESS);
export const fetchOrderFormsByPersonIdSuccess = createAction(
  FETCH_ORDER_FORMS_BY_PERSON_ID_SUCCESS
);

export function createOrderForm(data) {
  return (dispatch, getState) => {
    const query = `mutation createOrderForm($input: OrderFormInput){
      createOrderForm(input: $input) {
        accountId, orderFormId, orderFormNumber, orderFormName, entity
      }
    }`;
    return postGraphQL(query, { input: data }, "createOrderForm")
      .then((json) => {
        dispatch(createOrderFormSuccess(json));
        dispatch(hideModal());
      })
      .catch((err) => handleErrors(err));
  };
}

export function updateOrderForm(data) {
  return (dispatch, getState) => {
    const query = `mutation updateOrderForm($input: OrderFormInput){
      updateOrderForm(input: $input) {
        accountId, orderFormId, orderFormNumber, orderFormName, entity
      }
    }`;
    return postGraphQL(query, { input: data }, "updateOrderForm")
      .then((json) => {
        dispatch(updateOrderFormSuccess(json));
        dispatch(hideModal());
      })
      .catch((err) => handleErrors(err));
  };
}

export function archiveOrderForm(orderFormId) {
  return (dispatch, getState) => {
    const query = `mutation archiveOrderForm($input: OrderFormInput){
      archiveOrderForm(input: $input) {
        orderFormId
      }
    }`;
    return postGraphQL(
      query,
      { input: { orderFormId } },
      "archiveOrderForm"
    ).then((json) => {
      dispatch(archiveOrderFormSuccess(orderFormId));
      dispatch(hideModal());
    });
  };
}

export function fetchOrderFormsByAccountId(accountId) {
  const query = `query ($accountId: Int){
    ${orderFormsByAccountIdQuery}
  }`;
  return (dispatch) => {
    return getGraphQL(query, { accountId }).then((json) => {
      dispatch(fetchOrderFormsSuccess(json.orderForms));
    });
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
export const orderFormActionHandlers = {
  [RESET_INITIAL_STATE]: () => orderFormInitialState,
  [FETCH_ORDER_FORM_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "orderFormId"),
  [FETCH_ORDER_FORMS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "orderFormId"),
  [CREATE_ORDER_FORM_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "orderFormId"),
  [UPDATE_ORDER_FORM_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "orderFormId"),
  [ARCHIVE_ORDER_FORM_SUCCESS]: (state, { payload }) =>
    removeData(state, payload),
  [FETCH_ORDER_FORMS_BY_PERSON_ID_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "orderFormId"),
};

export const orderFormInitialState = { entities: {}, result: [] };
