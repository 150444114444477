import { connect } from "react-redux";
import Layout from "../components/Layout";
import { hideModal, showModal } from "../../../../../../../modules/modal";
import {
  archiveProject,
  projectById,
} from "../../../../../../../modules/projects";
import { fetchAllAssignmentGroups } from "../../../../../../../modules/assignmentGroups";

const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);
  return {
    archiveProject: () => dispatch(archiveProject(projectId, ownProps.history)),
    fetchAllAssignmentGroups: () =>
      dispatch(fetchAllAssignmentGroups(projectId, ownProps.location)), // add params
    hideModal: () => dispatch(hideModal()),
    showModal: (type) => dispatch(showModal({ type })),
  };
};

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match?.params?.projectId);

  const project = projectById(state, projectId);
  const orderForm = state.orderForms.entities[project.orderFormId] || {};
  const account = state.accounts.entities[orderForm.accountId] || {};
  const parentAccount =
    state.parentAccounts.entities[account.parentAccountId] || {};

  const noAssignmentGroupsMarked = Object.values(
    state.assignmentGroups.entities
  ).every(({ marked }) => !marked);

  return {
    // TODO: QCC-1955 Temporary admin user hack for First Choice
    me: Number(state.me),
    account,
    featureToggles: state.featureToggles,
    orderForm,
    parentAccount,
    projectId,
    project,
    noAssignmentGroupsMarked,
    modal: state.modal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
