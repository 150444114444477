import React from "react";
import NestedParentAccountRow from "../../ParentAccountsList/components/NestedParentAccountRow";
import Helmet from "react-helmet";
import parseQuery, { handleQuery } from "../../../../utils/parseQuery";
import qs from "query-string";
import PageHeader from "../../../../components/PageHeader";

const Archived = ({
  parentAccounts,
  history,
  location,
  accounts,
  orderForms,
  projects,
  restore,
  parentAccountIds,
  accountIds,
  orderFormIds,
  projectIds,
}) => {
  const updateUrl = (searchObject) => {
    const search = qs.stringify(searchObject);

    // only push a new history state if the params are different
    if (location.search !== `?${search}`) {
      history.push({ search });
    }
  };

  const updateQuerySearch = (term, value) => {
    const searchObject = parseQuery(location.search);
    const key = searchObject[term];

    if (term === "searchTerm") {
      updateUrl({ ...searchObject, searchTerm: value });
    } else {
      if (!key) {
        const newQuery = handleQuery(searchObject, term, value);
        updateUrl({ ...newQuery, latestAccount: value });
      } else {
        delete searchObject[term];
        delete searchObject["latestAccount"];
        updateUrl(searchObject);
      }
    }
  };
  const setExpandedId = (parentAccountName, parentAccountId) => {
    updateQuerySearch(parentAccountName, parentAccountId);
  };
  return (
    <div>
      <Helmet>
        {" "}
        <title>Archived Page</title>{" "}
      </Helmet>
      <PageHeader title={"Archive"} />

      <div>
        {parentAccounts.map((parentAccount) => {
          return (
            <NestedParentAccountRow
              key={parentAccount.parentAccountId}
              restoreEnabled={parentAccountIds.includes(
                parentAccount.parentAccountId
              )}
              accountIds={accountIds}
              accounts={accounts}
              orderFormIds={orderFormIds}
              projectIds={projectIds}
              orderForms={orderForms}
              projects={projects}
              defaultCollapsed
              archivedComponent
              setExpandedId={setExpandedId}
              restore={restore}
              searchObject={parseQuery(location.search)}
              {...parentAccount}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Archived;
