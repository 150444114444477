import React from "react";
import styles from "./PaymentList.module.scss";


const PaymentCheckBox = ({ paymentId, toggleItem, checkboxStates }) => {
  return (
    <div>
      <div className={styles.checkboxContainer}>
        <input
          checked={checkboxStates[paymentId]?.checked}
          id={paymentId}
          onChange={toggleItem}
          onClick={(e) => e.stopPropagation()}
          type="checkbox"
        />
        <label
          className={styles.label}
          htmlFor={paymentId}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

export default PaymentCheckBox;
