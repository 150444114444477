import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./QuillFormInput.module.scss";
import { Field, FieldArray } from "redux-form";
import classNames from "classnames";

function QuillFormInputDecorator(WrappedComponent) {
  class QuillFormInputDecorator extends Component {
    render() {
      const {
        hidden,
        required,
        name,
        labelText,
        customContainerStyle,
        extraContainerStyles = "",
        customLabelStyles,
        currencySymbol,
        ...rest
      } = this.props;
      const labelStyles =
        customLabelStyles ||
        (required ? styles.quillRequiredLabel : styles.quillLabel);
      const baseContainerStyles = hidden
        ? styles.hidden
        : customContainerStyle || styles.labelInputContainer;

      const containerStyles = classNames({
        [baseContainerStyles]: true,
        [extraContainerStyles]: true,
      });

      return (
        <div className={containerStyles}>
          {labelText && (
            <label className={labelStyles} htmlFor={name}>
              <span>{labelText}</span>
            </label>
          )}
          {currencySymbol ? (
            <div className={styles.currencySymbolContainer}>
              <span className={styles.currencySymbol}>{currencySymbol}</span>
              <WrappedComponent
                customContainerStyle={customContainerStyle}
                name={name}
                {...rest}
              />
            </div>
          ) : (
            <WrappedComponent name={name} {...rest} />
          )}
        </div>
      );
    }
  }
  QuillFormInputDecorator.propTypes = {
    customContainerStyle: PropTypes.string,
    customLabelStyles: PropTypes.string,
    hidden: PropTypes.bool,
    labelText: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
  };

  return QuillFormInputDecorator;
}

export const QuillFormField = QuillFormInputDecorator(Field);
export const QuillFormFieldArray = QuillFormInputDecorator(FieldArray);
