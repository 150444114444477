import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../../../components/Dropdown";
import styles from "./NativeLanguageSelector.module.scss";

const NativeLanguageSelector = (props) => {
  return (
    <Dropdown title={props.title} containerStyles={props.containerStyles}>
      <div
        className={styles.dropdownItem}
        key={"all"}
        onClick={() => props.selectLanguage(null)}
      >
        {"All"}
      </div>
      {props.filteredLanguages.map((lang, i) => (
        <div
          key={i}
          className={styles.dropdownItem}
          data-value={lang}
          value={lang}
          onClick={() => {
            props.selectLanguage(lang);
          }}
        >
          {lang}
        </div>
      ))}
    </Dropdown>
  );
};

NativeLanguageSelector.propTypes = {
  filteredLanguages: PropTypes.array,
  selectLanguage: PropTypes.func,
  title: PropTypes.string.isRequired,
  containerStyles: PropTypes.string,
};

export default NativeLanguageSelector;
