import React, { useState } from "react";
import { reduxForm } from "redux-form";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../components/PageHeader";
import AutomatedRemindersInput from "./AutomatedRemindersInput";
import styles from "./AutomatedReminders.module.scss";
import { emailTemplates } from "../../../../../../modules/automatedReminders";

let AutomatedReminders = (props) => {
  const {
    project: { projectName, projectId },
    parentAccount: { parentAccountName },
    account: { accountName },
    orderForm: { orderFormNumber },
    stages,
    automatedReminders,
    submitting,
    reminderTypes,
  } = props;

  const [isEdit, setEdit] = useState(false);

  const handleEdit = (event) => {
    event.preventDefault();
    setEdit(!isEdit);
  };

  const handleSubmit = () => {
    props.handleSubmit();
    setEdit(!isEdit);
  };

  return (
    <div>
      <Helmet>
        <title>Automated Reminders</title>
      </Helmet>
      <PageHeader
        breadCrumbItems={[
          { url: "/admin", text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { url: `/admin/projects/${projectId}`, text: projectName },
          { text: "Automated reminders" },
        ]}
        title={`${projectName} - Automated reminders`}
      />
      <form>
        <table>
          <thead>
            <tr>
              <th>Stages</th>
              {Object.keys(emailTemplates).map((key) => (
                <th className={styles.capitalize}>{emailTemplates[key]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isEdit ? (
              stages.map(({ stageName, stageId }) => {
                return (
                  <tr>
                    <td>{stageName}</td>
                    {reminderTypes.map((rt) => {
                      const ar = automatedReminders.find(
                        (ar) => ar.stageId === stageId && ar.reminderType === rt
                      );
                      if (ar) {
                        return <td>{ar.minutesTrigger}</td>;
                      } else return <td>0</td>;
                    })}
                  </tr>
                );
              })
            ) : (
              <AutomatedRemindersInput
                submitting={submitting}
                stages={stages}
                reminderTypes={reminderTypes}
              />
            )}
          </tbody>
        </table>
        <div className={styles.buttonsContainer}>
          {!isEdit ? (
            <button className={styles.edit} onClick={handleEdit}>
              Edit
            </button>
          ) : (
            <div>
              <button className={styles.cancel} onClick={handleEdit}>
                Cancel
              </button>
              <button
                className={styles.save}
                type="button"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

// @ts-ignore
AutomatedReminders = reduxForm({
  form: "automatedRemindersForm",
  enableReinitialize: true,
  onSubmit: async (
    { automatedReminders },
    _,
    { initialValues, updateAutomatedReminders, reminderTypes, stages, project }
  ) => {
    const { automatedReminders: initialItems } = initialValues;

    const changedValues = reminderTypes.reduce((acc, cur) => {
      stages.forEach((st) => {
        if (
          automatedReminders[cur][st.stageId] !== initialItems[cur][st.stageId]
        ) {
          acc.push({
            projectId: project.projectId,
            reminderType: cur,
            stageId: st.stageId,
            minutesTrigger: automatedReminders[cur][st.stageId],
          });
        }
      });
      return acc;
    }, []);

    await updateAutomatedReminders(changedValues);
  },
})(AutomatedReminders);

export default AutomatedReminders;
