import BatchDetails from "../components/BatchDetails";
import WithData from "../../../../../../../../../../decorators/WithData";
import { connect } from "react-redux";

import { getInitialData } from "../modules/getInitialData";
import { batchById } from "../../../../../../../../../../utils/entitySelector";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
  };
};

const mapStateToProps = (state, { batchId }) => {
  const batch = batchById(state, batchId);
  return {
    batch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(BatchDetails));
