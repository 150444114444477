import { connect } from "react-redux";
import WithData from "../../../../../decorators/WithData";
import { showErrors } from "../../../../../modules/errors";
import { fetchOrderFormsByAccountId } from "../../../../../modules/orderForms";
import {
  clearPaymentsForm,
  paymentTasks,
} from "../../../../../modules/payments";
import { searchFreelancers } from "../../../../../modules/people";
import { fetchProjectsByOrderFormId } from "../../../../../modules/projects";
import { isArray } from "../../../../../utils/dataTypes";
import {
  accountsDropDownSelector,
  listToValueAndLabelMap,
  orderFormsDropdownSelector,
  personDropdownSelector,
  projectsDropdownSelector,
  projectsSelector,
  stageNamesDropdownSelector,
} from "../../../../../utils/entitySelector";
import PaymentsSearch from "../components/PaymentsSearch";
import { getInitialData } from "../modules/getInitialData";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  fetchOrderFormsByAccountId: (accountIds) =>
    dispatch(fetchOrderFormsByAccountId(accountIds)),
  fetchProjectsByOrderFormId: (orderFormId) =>
    dispatch(fetchProjectsByOrderFormId(orderFormId)),
  searchFreelancers: (input) => dispatch(searchFreelancers(input)),
  clearPaymentsForm: () => dispatch(clearPaymentsForm()),
  showErrors: (err) => {
    let errorMessage = err.message;
    if (err && err.errors && isArray(err.errors) && err.errors.length) {
      errorMessage = `${err.errors[0].name}: ${err.errors[0].message}`;
    }
    dispatch(showErrors(errorMessage));
  },
});

const mapStateToProps = (state, ownProps) => {
  const accounts = accountsDropDownSelector(state);
  const errors = state.errors;
  const tasks = listToValueAndLabelMap(paymentTasks);
  const freelancers = personDropdownSelector(state).filter(
    (person) => person.object.personType === "Freelancer"
  );

  let orderForms = [];
  let projects = [];
  let project;

  const paymentsSearchForm = state.form.paymentsSearchForm;

  if (paymentsSearchForm && paymentsSearchForm.values) {
    const { accountId, orderFormId, projectId } = paymentsSearchForm.values;

    orderForms = orderFormsDropdownSelector(state).filter(
      (orderForm) => orderForm.object.accountId === accountId
    );

    projects = projectsDropdownSelector(state).filter(
      (project) => project.object.orderFormId === orderFormId
    );

    if (projectId) {
      project = projectsSelector(state).find(
        (project) => project.projectId === projectId
      );
    }
  }

  const stages = stageNamesDropdownSelector(
    state,
    project && project.workflowId
  );

  return {
    accounts,
    errors,
    freelancers,
    orderForms,
    projects,
    stages,
    tasks,
    me: state.me,
  };
};

const PaymentsSearchWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(PaymentsSearch));
export default PaymentsSearchWithData;
