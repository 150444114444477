import { createSelector } from "reselect";
import {
  getLoggedInUser,
  stageById,
  transitionsSelector,
} from "../entitySelector";
import { nullArray } from "../nullObjects";
import { PersonType } from "../../modules/people";
import { StageType } from "../../modules/stages";

/**
 * Get all of the transitions at a stage (inc. recalls)
 *
 * @param state
 * @param stageId
 */
export const getTransitionsAtStageId = createSelector(
  transitionsSelector,
  (_, stageId) => Number(stageId),
  (transitions, stageId) =>
    transitions.filter(({ fromStageId }) => fromStageId === stageId)
);

/**
 * Remove all recall transitions from a list of transitions
 */
const removeRecallTransitions = createSelector(
  (transitions) => transitions,
  (transitions) => transitions.filter((t) => !t.transitionName.match(/recall/i))
);

/**
 * Get all of the transitions to be displayed on the list page for a stage (does not inc. recalls)
 *
 * @param state
 * @param stageId
 */
export const getTransitionsForListPage = createSelector(
  getTransitionsAtStageId,
  (transitions) => removeRecallTransitions(transitions)
);

/**
 * @param   {Object}    state
 * @param   {number}    deliverableId
 * @param   {number}    stageId
 *
 * @returns {Object[]}  array of transitions
 */
export const getDeliverableTransitions = createSelector(
  getLoggedInUser,
  (state, deliverableId) => state.deliverables.entities[deliverableId],
  (state, _delId, stageId) => stageById(state, stageId),
  (state) => state.transitions.entities,
  ({ personType }, deliverable, { stageType }, allTransitions) => {
    if (
      !deliverable ||
      !deliverable.transitionIds ||
      (personType === PersonType.Client && stageType === StageType.Amends)
    ) {
      // No transitions
      return nullArray;
    }

    const transitions = deliverable.transitionIds.map(
      (transitionId) => allTransitions[transitionId]
    );

    if (personType === PersonType.Admin) return transitions;

    // only admins can see recall transitions
    const recallRemoved = removeRecallTransitions(transitions);

    return recallRemoved.length > 0 ? recallRemoved : nullArray;
  }
);
