import { matchPath } from "react-router";
import { injectReducer } from "../store/createStore";

function findMatchingRoute(url, routes, cumulativeReducers = []) {
  for (const route of routes) {
    if (route.routes) {
      const [match, finalRoute, finalReducers] = findMatchingRoute(
        url,
        route.routes,
        cumulativeReducers
      );

      if (match) {
        const allReducers = route.component.reducers
          ? finalReducers.concat(route.component.reducers)
          : finalReducers;

        return [match, finalRoute, allReducers];
      }
    }

    const match = matchPath(url, route, { exact: true });
    if (match) {
      return [match, route, cumulativeReducers];
    }
  }
  return [null, null, null];
}

function injectReducers(reducers) {
  reducers.forEach((reducer) => injectReducer(reducer));
}

const getPageData = (route, match, store) =>
  route.component.getInitialData
    ? () =>
        store.dispatch(
          route.component.getInitialData(
            match.params,
            window.API_GATEWAY,
            document.cookie
          )
        )
    : () => Promise.resolve();

const pageData = (routes, store) => {
  const [match, route, cumulativeReducers] = findMatchingRoute(
    window.location.pathname,
    routes
  );

  injectReducers(cumulativeReducers);

  if (route.component.reducers) {
    injectReducers(route.component.reducers);
  }

  return getPageData(route, match, store);
};

export default pageData;
