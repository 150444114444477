import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./StageAssignments.module.scss";
import AssignmentGroupRow from "./AssignmentGroupRow";
import FeatureToggle from "../../../../../../../../../components/FeatureToggle";
import classNames from "classnames";

const StageAssignments = (props) => {
  const [open, toggleOpen] = useState(true);

  const {
    assignmentGroups,
    baseUrl,
    filter,
    setModalState,
    stageId,
    stageName,
    stageType,
    selectedAssigneeId,
    selectedBatch,
    selectedLanguage,
    overdueFilter,
    currencyCode,
    setReminderState,
    selectedReminder,
    createManualReminder,
    automatedReminderActive,
    marked,
    stageDisabled,
  } = props;

  const [headerCheckboxChecked] = useState(false);

  const toggle = () => toggleOpen(!open);

  const sortedAssignmentGroups = useMemo(() => {
    return assignmentGroups
      .filter(({ archived, languageCode, batchName, assigneeId }) => {
        return !(
          archived ||
          (selectedLanguage !== "all" && languageCode !== selectedLanguage) ||
          (selectedBatch && selectedBatch.batchName !== batchName) ||
          (selectedAssigneeId != null && selectedAssigneeId !== assigneeId)
        );
      })
      .sort((a, b) => {
        if (overdueFilter && filter === "normal")
          return a.deadline.localeCompare(b.deadline);

        if (!overdueFilter) {
          if (filter === "normal")
            return a.assignmentGroupId - b.assignmentGroupId;
          if (filter === "alphabetical")
            return a.assigneeFirstName.localeCompare(b.assigneeFirstName);
          if (filter === "reverse")
            return b.assigneeFirstName.localeCompare(a.assigneeFirstName);
        }
        return false;
      });
  }, [
    assignmentGroups,
    filter,
    overdueFilter,
    selectedBatch,
    selectedLanguage,
    selectedAssigneeId,
  ]);

  // If it's a training stage and no assignments are available then do not display anything
  if (stageType === "Training" && assignmentGroups.length === 0) return null;

  const completedAssignments = sortedAssignmentGroups.reduce((acc, group) => {
    return acc + group.completedAssignmentCount;
  }, 0);

  const stageHeaderStyles = classNames({
    [styles.stageHeader]: true,
    [styles.automatedReminders]: automatedReminderActive,
  });

  return (
    <div className={`stageAssignments-stageId-${stageId}`}>
      <div
        key={stageId}
        className={
          open
            ? `${stageHeaderStyles}`
            : `${stageHeaderStyles} ${styles.closed}`
        }
        onClick={toggle}
      >
        <div>
          <FeatureToggle toggle="selected">
            <div className={styles.checkboxContainer}>
              <input
                checked={marked}
                id={stageId}
                disabled={stageDisabled}
                onChange={() => props.handleStageChange(stageId)}
                onClick={(e) => e.stopPropagation()}
                type="checkbox"
              />
              <label
                className={styles.label}
                htmlFor={stageId}
                onClick={(e) => e.stopPropagation()}
              ></label>
            </div>
          </FeatureToggle>
        </div>
        <div className={styles.stageName}>{stageName}</div>
        {/* <div>lol</div> */}
        <div className={styles.countBubbleCotnainer}>
          <div className={styles.countBubble}>{completedAssignments}</div>
        </div>
        <svg className={styles.toggle} height="15px" width="15px">
          {" "}
          {open ? (
            <path d="M1 7h14v2h-14v-2z" fill="#fff" />
          ) : (
            <path d="M16 5.5l-1-1-7 7-7-7-1 1 8 8 8-8z" fill="#fff" />
          )}
        </svg>
        <div className={styles.reassign} />
      </div>
      {open &&
        sortedAssignmentGroups.map((assignmentGroup) => (
          <AssignmentGroupRow
            currencyCode={currencyCode}
            key={assignmentGroup.assignmentGroupId}
            baseUrl={baseUrl}
            open={open}
            setModalState={setModalState}
            setReminderState={setReminderState}
            selectedReminder={selectedReminder}
            createManualReminder={createManualReminder}
            automatedReminderActive={automatedReminderActive}
            headerCheckboxChecked={headerCheckboxChecked}
            handleGroupChange={props.handleGroupChange}
            disabled={stageDisabled}
            stageName={stageName}
            {...assignmentGroup}
          />
        ))}
    </div>
  );
};

StageAssignments.propTypes = {
  assignmentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      accepted: PropTypes.bool.isRequired,
      amendsRequested: PropTypes.bool.isRequired,
      archived: PropTypes.bool,
      assigneeFirstName: PropTypes.string.isRequired,
      assigneeId: PropTypes.number.isRequired,
      assigneeLastName: PropTypes.string.isRequired,
      assignmentCount: PropTypes.number.isRequired,
      assignmentGroupId: PropTypes.number.isRequired,
      batchName: PropTypes.string.isRequired,
      completedAssignmentCount: PropTypes.number.isRequired,
      deadline: PropTypes.string.isRequired,
      inTraining: PropTypes.bool.isRequired,
      languageCode: PropTypes.string.isRequired,
      minRate: PropTypes.string.isRequired,
      rejected: PropTypes.bool.isRequired,
      stageId: PropTypes.number.isRequired,
    })
  ),
  baseUrl: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  overdueFilter: PropTypes.bool.isRequired,
  selectedAssignee: PropTypes.object,
  selectedAssigneeId: PropTypes.number,
  selectedBatch: PropTypes.object,
  selectedLanguage: PropTypes.string,
  setModalState: PropTypes.func.isRequired,
  stageId: PropTypes.number.isRequired,
  stageName: PropTypes.string.isRequired,
  stageType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default StageAssignments;
