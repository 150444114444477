import React, { Component } from "react";
import PropTypes from "prop-types";
import AssignmentsForm from "../../../../../../../../../components/AssignmentsForm";
import styles from "./ReassignmentForm.module.scss";
import Modal from "../../../../../../../../../components/Modal";
import {
  convertDate,
  formatDateObject,
} from "../../../../../../../../../utils/date";
import { ModalType } from "../../../../../../../../../modules/modal";

class ReassignmentForm extends Component {
  render() {
    const { useModal = true } = this.props;

    if (useModal) {
      return (
        <Modal
          type={ModalType.Reassignment}
          body={this.renderReassignmentForm()}
          large
          onClickCallback={this.hideModal}
        />
      );
    }

    return this.renderReassignmentForm();
  }

  renderReassignmentForm = () => {
    const { languageCode, eligibleForReassignmentCount, stageId, assigneeId } =
      this.props.assignmentGroup;
    const trainingStage =
      this.props.stageEntities[stageId].stageType === "Training";
    const initialValues = this.createInitialValues();

    const { orderFormId, isInlineForm } = this.props;

    const assignees = initialValues.assignees[stageId][languageCode];
    const assigneesAvailable = Object.keys(assignees).reduce((acc, key) => {
      const assignee = initialValues.assignees[stageId][languageCode][key];
      if (Number(key) !== assigneeId && assignee.stageId === stageId) acc++;
      return acc;
    }, 0);

    let content = <div>There are no people to reassign this work to.</div>;
    const { combinedStages } = this.combineStages();
    const availableLanguages = this.props.projectLanguages.filter(
      (l) => l.languageCode === languageCode
    );

    if (assigneesAvailable) {
      content = (
        <AssignmentsForm
          cancelHandler={this.hideModal}
          defaultAssignees={this.props.defaultAssignees}
          featureToggles={this.props.featureToggles}
          header="Reassignments"
          hideRequiredHeader
          isInlineForm={isInlineForm}
          initialValues={this.createInitialValues()}
          numParentDeliverables={eligibleForReassignmentCount}
          onSubmit={this.createReassignments}
          orderFormId={orderFormId}
          people={this.props.peopleEntities}
          projectLanguages={availableLanguages} // we now send a person to only be removed for the first stage instead of all stages
          reassignment
          reassignmentAssignee={assigneeId}
          selectedAssignment={this.props.assignmentGroup}
          selectedBatchId={this.props.selectedBatchId}
          stages={combinedStages}
          submitText="Reassign work"
          trainingStage={trainingStage}
        />
      );
    }

    return (
      <div>
        {!isInlineForm && (
          <>
            <div>
              Reassigning {this.props.assignmentGroup.batchName} from{" "}
              {this.props.assignmentGroup.name}
            </div>
            <div className={styles.small}>
              Available for reassignment:{" "}
              {this.props.assignmentGroup.eligibleForReassignmentCount}
            </div>
          </>
        )}
        {content}
      </div>
    );
  };

  createReassignments = (data) => {
    const {
      projectId,
      assignmentGroup: { stageId },
      stageEntities,
    } = this.props;
    const trainingStage = stageEntities[stageId].stageType === "Training";
    return this.props.createReassignments({
      ...data,
      projectId,
      trainingStage,
    });
  };

  combineStages() {
    const { stageId } = this.props.assignmentGroup;
    const reassignmentStage = this.props.stageEntities[stageId];
    const associatedStagePosition =
      reassignmentStage.stageType === "Training"
        ? reassignmentStage.stagePosition - 1
        : reassignmentStage.stagePosition + 1;
    let associatedStage = this.props.stages.find(
      (s) => s.stagePosition === associatedStagePosition
    );
    const combinedStages = [reassignmentStage];

    // There are no associated trainer stages for client stages
    if (associatedStage.stageType !== "Training") {
      associatedStage = null;
    } else {
      combinedStages.push(associatedStage);
    }

    return { combinedStages, reassignmentStage, associatedStage };
  }

  createInitialValues = () => {
    const {
      match: {
        params: { projectId },
      },
    } = this.props;
    const { stageId, languageCode, deadline, assignmentGroupId } =
      this.props.assignmentGroup;
    const deadlineLocalString = formatDateObject(convertDate(deadline), {
      includeSeparator: true,
    });
    const { combinedStages } = this.combineStages();

    // matches pattern in AssignmentForm
    const assignees = combinedStages.reduce((acc, val) => {
      if (!acc[val.stageId]) {
        acc[val.stageId] = { [languageCode]: {} };
      }

      const defaultAssignees =
        this.props.defaultAssignees?.[val.stageId]?.[languageCode];
      if (!defaultAssignees) return acc;

      const trainingStage =
        this.props.stageEntities[val.stageId].stageType === "Training";

      defaultAssignees.forEach(({ personId, stageId: personStageId }) => {
        if (
          this.props.assignmentGroup.assigneeId !== personId ||
          trainingStage
        ) {
          acc[val.stageId][languageCode][personId] = {
            stageId: personStageId,
            amount: null,
            trainer: trainingStage,
          };
        }
      });
      return acc;
    }, {});

    return {
      projectId,
      assignmentGroupId,
      assignees,
      deadlines: { [stageId]: deadlineLocalString },
    };
  };

  hideModal = () => {
    this.setModalState(false);
  };

  setModalState = (bool, selectedAssignment = null) => {
    const { showModal, hideModal } = this.props;
    this.setState({ showReassignmentModal: bool, selectedAssignment }, () => {
      if (bool) {
        showModal();
      } else {
        hideModal();
      }
    });
  };
}

ReassignmentForm.propTypes = {
  assignmentGroup: PropTypes.object.isRequired,
  createReassignments: PropTypes.func.isRequired,
  defaultAssignees: PropTypes.object.isRequired,
  featureToggles: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  }).isRequired,
  orderFormId: PropTypes.number,
  peopleEntities: PropTypes.object.isRequired,
  projectLanguages: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  stageEntities: PropTypes.object.isRequired,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      stageId: PropTypes.number.isRequired,
      stagePosition: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default ReassignmentForm;
