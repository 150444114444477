import { getGraphQL, getJson } from "../../../../../utils/fetch";
import {
  accountByIdQuery,
  fetchAccountSuccess,
} from "../../../../modules/accounts";
import {
  mergeGlossary,
  updateGlossarySuccess,
} from "../../../../modules/glossary";
import {
  parentAccountsQuery,
  fetchParentAccountsSuccess,
} from "../../../../modules/parentAccounts";

export const initialQuery = `query getParentAccountAndAccounts {
  ${parentAccountsQuery}
}`;

export const accountQuery = `query accountById ($accountId: Int) {
  ${accountByIdQuery}
}`;

const translationUrl = () =>
  `${
    window.__REACT_APP_API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY
  }/translation-memory`;

export function mergeBySourceSegmentId(data) {
  const mergedResults = {};

  data.forEach((item) => {
    const { sourceSegmentId } = item;

    if (!mergedResults[sourceSegmentId]) {
      mergedResults[sourceSegmentId] = { ...item };
    } else {
      Object.keys(item).forEach((key) => {
        if (!mergedResults[sourceSegmentId].hasOwnProperty(key)) {
          mergedResults[sourceSegmentId][key] = item[key];
        }
      });
    }
  });

  return Object.values(mergedResults);
}

export function getInitialData(data, url, cookies) {
  return async (dispatch) => {
    const {
      match: { params },
    } = data;

    const glossaryTranslations = await getJson(`${translationUrl()}/glossary`, {
      accountId: params.accountId,
    });

    const mergedGlossary = mergeBySourceSegmentId(glossaryTranslations);

    const { account } = await getGraphQL(accountQuery, params, url, cookies);
    const { parentAccounts } = await getGraphQL(
      initialQuery,
      params,
      url,
      cookies
    );

    dispatch(updateGlossarySuccess(mergedGlossary));
    dispatch(fetchAccountSuccess(account));
    dispatch(fetchParentAccountsSuccess(parentAccounts));
  };
}
