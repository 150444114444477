import React from "react";
import PropTypes from "prop-types";
import OverlayLoading from "../../../../../components/OverlayLoading/v1";
import AssignmentGroupRowContainer from "../../_components/AssignmentGroupRowContainer";

const Approved = ({ dataReady, approvedAssignmentGroups }) => {
  if (!dataReady) return <OverlayLoading />;

  return (
    <div>
      <AssignmentGroupRowContainer
        header={"Approved assignments"}
        assignmentGroups={approvedAssignmentGroups}
        emptyCopy={
          "Any tasks you’ve submitted, and have been approved, will appear here."
        }
      />
    </div>
  );
};

Approved.propTypes = {
  dataReady: PropTypes.bool.isRequired,
  approvedAssignmentGroups: PropTypes.array,
};

export default Approved;
