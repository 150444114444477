import Helmet from "react-helmet";
import PageHeader from "../../../../../components/PageHeader";
import Errors from "../../../../../components/Errors";
import AIEnhancementForm from "./AIEnhancementForm";

const BulkQAdmin = (props) => {
  const {
    errors,
    accounts,
    projects,
    attributeFields,
    fetchProjectsByAccountId,
    fetchBriefingFields,
    downloadCSVTemplate,
    processFile,
    history,
  } = props;

  return (
    <>
      <Helmet>
        <title>AI Enhancement</title>
      </Helmet>
      {errors && errors.display && <Errors />}

      <PageHeader
        breadCrumbItems={[
          { url: `/admin`, text: "Parent Accounts" },
          { url: `/admin/ai-enhancement`, text: "AI Enhancement List" },
          { text: "Create new" },
        ]}
        title={"AI Enhancement"}
      ></PageHeader>

      <AIEnhancementForm
        enableReinitialize
        accounts={accounts}
        projects={projects}
        attributeFields={attributeFields}
        fetchProjectsByAccountId={fetchProjectsByAccountId}
        fetchBriefingFields={fetchBriefingFields}
        downloadCSVTemplate={downloadCSVTemplate}
        processFile={processFile}
      />
    </>
  );
};

export default BulkQAdmin;
