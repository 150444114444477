import React from "react";
import styles from "./BatchDetails.module.scss";
import PropTypes from "prop-types";
import {
  dateFields,
  textFields,
} from "../../../../../../../../../../modules/batches";
import { convertDateToLocal } from "../../../../../../../../../../utils/date";

const BatchDetails = ({ batch }) => {
  const renderTextFields = textFields.map(({ label, field }) => {
    if (batch[field]) {
      return (
        <div key={field} className={styles.fieldContainer}>
          <span className={styles.label}>{label}:</span>
          <span className={styles.fieldValue}>{batch[field]}</span>
        </div>
      );
    }
    return null;
  });

  const renderDateFields = dateFields.map(({ label, field }) => {
    const dateValue = batch[field];

    const localDate = dateValue && convertDateToLocal(new Date(dateValue));

    const readOnlyDate =
      dateValue &&
      localDate.toLocaleDateString("en-GB", {
        hour: "numeric",
        minute: "numeric",
      });

    if (dateValue) {
      return (
        <div key={field} className={styles.fieldContainer}>
          <span className={styles.label}>{label}:</span>
          <span className={styles.fieldValue}>{readOnlyDate}</span>
        </div>
      );
    }
    return null;
  });

  return (
    <div className={styles.detailsContainer}>
      {renderTextFields}
      {renderDateFields}
    </div>
  );
};

BatchDetails.propTypes = {
  batch: PropTypes.object.isRequired,
};

export default BatchDetails;
