import React from "react";
import PropTypes from "prop-types";

import { Field, reduxForm } from "redux-form";
import { CreatableWithErrors } from "../../../../../../../decorators/WithErrors";

import styles from "./BannedWordsForm.module.scss";

import validate from "./validations";

let BannedWordsForm = (props) => {
  const fields = props.projectLanguages.map(({ languageCode: lang }) => {
    return (
      <div className={styles.formGroup} key={lang}>
        <label className={styles.quillHeader} htmlFor={lang}>
          {lang}
        </label>
        <Field
          name={lang}
          component={CreatableWithErrors}
          type="text"
          multi
          styles={"creatable-only"}
          options={[]}
          disabled={props.submitting}
        />
      </div>
    );
  });
  return (
    <form onSubmit={props.handleSubmit}>
      {fields}
      <div>{props.error && <strong>{props.error}</strong>}</div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.button}
          type="submit"
          disabled={props.submitting || !props.valid}
        >
          Save
        </button>
      </div>
    </form>
  );
};

BannedWordsForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  projectLanguages: PropTypes.array.isRequired,
};

BannedWordsForm = reduxForm({
  form: "project",
  validate,
})(BannedWordsForm);

export default BannedWordsForm;
