import { createAction } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_FEATURE_TOGGLES_SUCCESS = "FETCH_FEATURE_TOGGLES_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFeatureTogglesSuccess = createAction(
  FETCH_FEATURE_TOGGLES_SUCCESS
);

export const featureToggleHandlers = {
  [FETCH_FEATURE_TOGGLES_SUCCESS]: (state, { payload }) => payload,
};

export const initialState = {};

const reducer = (state = initialState, action) => {
  const handleFn = featureToggleHandlers[action.type];
  return handleFn ? handleFn(state, action) : state;
};

export default reducer;
