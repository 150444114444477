import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import RatesForm from "./RatesForm";
import PageHeader from "../../../../../../../components/PageHeader";

const Rates = (props) => {
  const {
    project: { projectId, projectName },
  } = props;
  const baseUrl = `/admin/projects/${projectId}`;
  return (
    <div>
      <Helmet>
        {" "}
        <title>Project Rates</title>{" "}
      </Helmet>
      <div>
        <PageHeader
          title={`${projectName} - Rates`}
          breadCrumbItems={[
            { url: `/admin`, text: "Parent accounts" },
            {
              text: `${props.parentAccount.parentAccountName} | ${props.account.accountName} | ${props.orderForm.orderFormNumber}`,
            },
            { url: `${baseUrl}`, text: projectName },
            { text: "Rates" },
          ]}
        />
      </div>
      <RatesForm
        enableReinitialize // needed since data is retrieved async
        baseUrl={baseUrl}
        projectCreation={props.projectCreation}
        initialValues={props.initialValues}
        projectLanguages={props.projectLanguages}
        stages={props.stages}
        onSubmit={props.updateRates}
      />
    </div>
  );
};

Rates.propTypes = {
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string.isRequired,
  }),
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
  }),
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number.isRequired,
  }),
  projectCreation: PropTypes.bool,
  project: PropTypes.object.isRequired,
  projectLanguages: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  stages: PropTypes.array.isRequired,
  updateRates: PropTypes.func.isRequired,
};

export default Rates;
