import { connect } from "react-redux";
import Search from "../components/Search";
import WithData from "../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { searchSelector, accountById } from "../../../../utils/entitySelector";
import { updateSearch } from "../../../../modules/search";
import queryString from "query-string";

const searchArchivedValues = {
  BOTH: { value: "both", label: "Both" },
  ARCHIVED: { value: "archived", label: "Archived" },
  UNARCHIVED: { value: "unarchived", label: "Unarchived" },
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
    updateSearch: (search) => dispatch(updateSearch(search)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const searchResults = searchSelector(state);
  const { accountId, searchTerm } = queryString.parse(ownProps.location.search);
  const { location, history } = ownProps;
  const account = accountById(state, accountId);

  return {
    searchResults,
    account,
    searchTerm,
    location,
    history,
    searchArchivedValues,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithData(Search));
