import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import NestedAccountRow from "../../../../../components/NestedAccountRow";
import NestedAdminRow from "../../../../../components/NestedAccountRow/NestedAdminRow";
import styles from "./NestedParentAccountRow.module.scss";

const NestedParentAccountRow = forwardRef((props, ref) => {
  const { latestAccount, ...expandedAccounts } = props.searchObject || {};

  const handleRowClick = () => {
    props.setExpandedId(props.parentAccountName, props.parentAccountId);
  };

  return (
    <div ref={ref} className={styles.parentAccountRowContainer}>
      <NestedAdminRow
        hide={props.hide}
        restore={props.restore}
        restoreEnabled={props.restoreEnabled}
        favouritesEnabled={props.favouritesEnabled}
        archivedComponent={props.archivedComponent}
        handleRowClick={handleRowClick}
        handleCreateOrEdit={props.handleCreateOrEdit}
        handleDelete={props.handleDelete}
        name={props.parentAccountName}
        modalId={props.parentAccountId}
        modalType="parentAccount"
        modalTitle="parent account"
        childTitle="account"
        childType="account"
        parentAccountId={props.parentAccountId}
        isFavourite={props.isFavourite}
        favourite={props.favourite}
        unfavourite={props.unfavourite}
        favouriteComponent={props.favouriteComponent}
      />
      {Object.values(expandedAccounts).map((id) => {
        return (
          Number(id) === props.parentAccountId &&
          props.accounts[id]?.map((account) => (
            <NestedAccountRow
              admin
              hide={props.hide}
              restore={props.restore}
              orderFormIds={props.orderFormIds}
              projectIds={props.projectIds}
              restoreEnabled={
                props.accountIds && props.accountIds.includes(account.accountId)
              }
              favouritesEnabled={
                props.accountIds && props.accountIds.includes(account.accountId)
              }
              accountId={props.accountId}
              archivedComponent={props.archivedComponent}
              key={account.accountId}
              account={account}
              projects={props.projects}
              orderForms={props.orderForms}
              handleCreateOrEdit={props.handleCreateOrEdit}
              handleDelete={props.handleDelete}
              isFavourite={account.isFavourite}
              favourite={props.favourite}
              unfavourite={props.unfavourite}
              favouriteComponent={props.favouriteComponent}
            />
          ))
        );
      })}
    </div>
  );
});

NestedParentAccountRow.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  parentAccountName: PropTypes.string.isRequired,
  parentAccountId: PropTypes.number.isRequired,
  accounts: PropTypes.object.isRequired,
  orderForms: PropTypes.object.isRequired,
  projects: PropTypes.object.isRequired,
  handleCreateOrEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default NestedParentAccountRow;
