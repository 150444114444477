import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";

type GenericSelectorWithReferenceType = (
  entity: string,
  referenceKey: string,
  options: {
    sort?: {
      keys?: any[];
      dirs?: any;
    };
    sortDesc?: any;
    asEntity?: any;
    filter?: any;
  }
) => (state: any, id: number) => any;

export const genericSelectorWithReference: GenericSelectorWithReferenceType = (
  entity,
  referenceKey,
  { sort, sortDesc, asEntity, filter } = {}
) => {
  return createSelector(
    (state) => state[entity].entities,
    (state) => state[entity].result,
    (_, referenceId: number | string) => referenceId,
    (entities, result, referenceId) => {
      const tree = {};
      result.forEach((id) => {
        if (entities[id] && entities[id][referenceKey] === referenceId) {
          tree[id] = entities[id];
        }
      });

      if (asEntity) return tree;

      const arr = Object.keys(tree).map((id) => entities[id]);
      const filtered = filter ? filter(arr) : arr;

      if (sortDesc) {
        return filtered.sort((a, b) => b[sortDesc] - a[sortDesc]);
      } else if (sort) {
        return orderBy(filtered, sort.keys, sort.dirs);
      }

      return filtered;
    }
  );
};
