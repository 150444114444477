import Tabs from "./Tabs";
import { connect } from "react-redux";
import { updateActiveTab } from "../../../modules/tabs";

const mapDispatchToProps = (dispatch) => ({
  updateStoreActiveTab: (storageKey, value) =>
    dispatch(updateActiveTab({ storageKey, value })),
});

export default connect(null, mapDispatchToProps)(Tabs);
