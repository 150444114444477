import React from "react";
import PropTypes from "prop-types";
import { Field, FieldArray } from "redux-form";
import Input from "../../../../../../../../components/Input";
import styles from "./QualityCheck.module.scss";

const renderCheckBox = (props) => (
  <div className={styles.checkboxContainer}>
    <Input {...props} />
    <label htmlFor={props.id} />
  </div>
);

renderCheckBox.propTypes = {
  id: PropTypes.string,
};

const renderFields = ({ fields }) => {
  return (
    <div>
      <table className={styles.table}>
        <thead>
          <th />
          <th>Min words</th>
          <th>Max words</th>
          <th>Min chars</th>
          <th>Max chars</th>
          <th>Keyword check</th>
          <th>AI Content</th>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr>
              <td>{fields.get(index).taskFieldName}</td>
              <td>
                <Field
                  component={Input}
                  type="number"
                  name={`${field}[minWords]`}
                />
              </td>
              <td>
                <Field
                  component={Input}
                  type="number"
                  name={`${field}[maxWords]`}
                />
              </td>
              <td>
                <Field
                  component={Input}
                  type="number"
                  name={`${field}[minCharacters]`}
                />
              </td>
              <td>
                <Field
                  component={Input}
                  type="number"
                  name={`${field}[maxCharacters]`}
                />
              </td>
              <td>
                <Field
                  id={`${field}[qualityCheck]`}
                  className={styles.checkboxInput}
                  component={renderCheckBox}
                  type="checkbox"
                  name={`${field}[qualityCheck]`}
                />
              </td>
              <td>
                <Field
                  id={`${field}[aiContent]`}
                  className={styles.checkboxInput}
                  component={renderCheckBox}
                  type="checkbox"
                  name={`${field}[aiContent]`}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

renderFields.propTypes = {
  fields: PropTypes.object.isRequired,
};

const QualityCheck = (props) => {
  return (
    <div className={styles.qualityCheck}>
      <div>
        <FieldArray name="taskFields" component={renderFields} />
      </div>
      <div>{props.error && <strong>{props.error}</strong>}</div>
      <div className={styles.alignRight}>
        <button onClick={props.hideModal} className={styles.submit}>
          Save
        </button>
      </div>
    </div>
  );
};

QualityCheck.propTypes = {
  error: PropTypes.string,
  hideModal: PropTypes.func,
};

export default QualityCheck;
