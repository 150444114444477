import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

export const plagiarismStatus = {
  PENDING: "PENDING",
  COMPLETE: "COMPLETE",
  QUEUED: "QUEUED",
  CREATED: "CREATED",
  FAILED: "FAILED",
};

function PlagiarismMessage(props) {
  const { status, result } = props;

  const isPending = status !== plagiarismStatus.COMPLETE;

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.pending]: isPending,
    [styles.completed]: !isPending,
  });

  if (isPending) {
    return (
      <div className={containerStyles}>
        <div className={styles.text}>
          Please wait – plagiarism check in progress.
        </div>
      </div>
    );
  }

  if (!result) return null;

  const { results, avgPercentage } = result;
  const aboveThreshold = avgPercentage > 20;

  // if plagiarism check is finished and hasn't failed, don't show anything
  if (!aboveThreshold) return null;

  return (
    <div className={containerStyles}>
      <div className={styles.aboveThreshold}>
        <div className={styles.warning}>
          Please note that you can only edit the content when all plagiarism
          checks are complete.
        </div>

        <ul>
          <li>Content exceeds the 20% threshold for acceptable plagiarism.</li>
          <li>
            Similar content has been detected at these URLs with the following
            match percentages:
          </li>
          <li>
            Please review your content and remove this plagiarism before you
            resubmit.
          </li>
        </ul>

        {results.map(({ url, percentage }) => (
          <div key={`${url}-${percentage}`} className={styles.row}>
            {percentage}% -{" "}
            <a target="_blank" rel="noopener noreferrer" href={url}>
              {url}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlagiarismMessage;
