import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../decorators/WithData";
import AiEnhancementList from "../components/AiEnhancementList";
import { createQueryString } from "../../../../../utils/url";
import { showSuccessMessage } from "../../../../../modules/messagesV2";
import { createSelector } from "reselect";

const aiEnhancementsSelector = (state) => state.aiEnhancement.entities;

const sortedAiEnhancementsSelector = createSelector(
  (state) => state.aiEnhancement.entities,
  (aiEnhancements) =>
    Object.values(aiEnhancements).sort(
      (a: any, b: any) => b.aiEnhancementId - a.aiEnhancementId
    )
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps)),
  download: async ({ jobId, fileName }) => {
    const params = {
      jobId,
      fileName,
      format: "csv",
    };

    const queryString = createQueryString(params);
    const API_GATEWAY =
      (window as any).__API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY;

    (window.location as Location).assign(
      `${API_GATEWAY}/ai-enhancement/report?${queryString}`
    );

    dispatch(showSuccessMessage("Download successful"));
  },
});

const mapStateToProps = (state) => {
  const aiEnhancements = sortedAiEnhancementsSelector(state);
  return {
    aiEnhancements,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AiEnhancementList));
