import gql from "graphql-tag";

export const CLIENT_BATCH_NOTIFICATIONS_QUERY = gql`
  query getClientBatchNotifications($batchId: Int) {
    clientBatchNotifications(batchId: $batchId) {
      batchId
      stageId
      personId
      enabled
    }
  }
`;

export const UPDATE_CLIENT_BATCH_NOTIFICATIONS = gql`
  mutation updateClientBatchNotifications(
    $input: ClientBatchNotificationsInput
  ) {
    clientBatchNotifications(input: $input) {
      batchId
      stageId
      personId
      enabled
    }
  }
`;
