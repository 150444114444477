import React from "react";
import { Link } from "react-router-dom";

const makeCreateLink = (toggleTab) => (
  text,
  link,
  linkStyles,
  dataAttribute,
  key
) => (
  <Link
    key={key}
    className={linkStyles}
    data-nav={dataAttribute}
    onClick={toggleTab}
    to={link}
  >
    {text}
  </Link>
);

export default makeCreateLink;
