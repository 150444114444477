export const unallocatedParentDeliverables = (
  parentDeliverables,
  deliverables,
  assignmentLanguageCodes
) => {
  let parentDeliverableCount = 0;

  deliverables.forEach(({ allocated, languageCode, parentDeliverableId }) => {
    if (
      assignmentLanguageCodes &&
      assignmentLanguageCodes.includes(languageCode) &&
      !allocated &&
      parentDeliverables[parentDeliverableId]
    ) {
      parentDeliverableCount++;
    }
  });

  return parentDeliverableCount;
};
