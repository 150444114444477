export const matchHTMLWords = (content, wordsToUnderline = [], styles) => {
  // If wordsToUnderline is empty, return the content as is
  if (wordsToUnderline.length === 0) {
    return content;
  }

  // Create a regex to match the words
  const regex = new RegExp(`\\b(${wordsToUnderline.join("|")})\\b`, "gi");

  const contentDiv = document.createElement("div");
  contentDiv.innerHTML = content;
  // Traverse the DOM and apply underlining to text nodes outside html tags (like <a> tags)
  const traverseAndUnderline = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      node.textContent = node.textContent.replace(regex, (matched) => {
        return `<span class="${styles.underline}">${matched}</span>`;
      });
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      Array.from(node.childNodes).forEach(traverseAndUnderline);
    }
  };

  traverseAndUnderline(contentDiv);

  // When getting the innerHTML, the `<` and `>` characters inside a <span>
  // will be automatically escaped and represented as `&lt;` and `&gt;` respectively.
  return contentDiv.innerHTML.replace(/&lt;|&gt;/g, (match) =>
    match === "&lt;" ? "<" : ">"
  );
};
