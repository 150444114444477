import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const gCloudQuery = `gCloud { JWT }`;

// ------------------------------------
// Constants
// ------------------------------------
export const CREATE_JWT_SUCCESS = "CREATE_JWT_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const createJWTSuccess = createAction(CREATE_JWT_SUCCESS);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const gCloudActionHandlers = {
  [RESET_INITIAL_STATE]: () => gCloudInitialState,
  [CREATE_JWT_SUCCESS]: (state, { payload }) =>
    Object.assign({}, state, { JWT: payload }),
};

export const gCloudInitialState = { JWT: null };
