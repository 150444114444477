import DefaultAssignees from "../components/DefaultAssignees";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  projectLanguagesSelector,
  projectDefaultAssigneesSelector,
  personDropdownSelector,
  workflowStagesSelectorByTypes,
} from "../../../../../../utils/entitySelector";

import WithData from "../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { searchPeople } from "../../../../../../modules/people";
import { updateDefaultAssignees } from "../../../../../../modules/defaultAssignees";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    updateDefaultAssignees: (data) =>
      dispatch(
        updateDefaultAssignees(data, ownProps.history, ownProps.match.params)
      ),
    searchPeople: (input) => dispatch(searchPeople(input)),
  };
};

/**
 @description - we store our data like so:
  {
    en-gb: {
      stage_id: [...],
      ...
    }
  }
 where stage_id is replaced with the actual stage_ids
 Hence the initial value selector has to recreate this structure
*/
const initialValuesSelector = createSelector(
  projectLanguagesSelector,
  projectDefaultAssigneesSelector,
  (state, projectId, stages) => stages,
  (projectLanguages, projectDefaultAssignees, stages) => {
    const tree = {};

    projectLanguages.forEach(({ languageCode }) => {
      if (!tree.hasOwnProperty(languageCode)) tree[languageCode] = {};

      stages.forEach((stage) => {
        if (!tree[languageCode].hasOwnProperty(stage.stageId)) {
          tree[languageCode][stage.stageId] = [];
        }
      });
    });

    projectDefaultAssignees.forEach((defaultAssignee) => {
      const { languageCode, stageId, personId } = defaultAssignee;
      if (!tree.hasOwnProperty(languageCode)) tree[languageCode] = {};
      if (!tree[languageCode].hasOwnProperty(stageId))
        tree[languageCode][stageId] = [];
      tree[languageCode][stageId].push(personId);
    });

    return tree;
  }
);

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);
  const project = state.projects.entities[projectId] || {};

  const stageTypes = ["Production", "Amends", "Training"];

  const stages = workflowStagesSelectorByTypes(
    state,
    project?.workflowId,
    stageTypes
  );
  const people = personDropdownSelector(state);
  const projectLanguages = projectLanguagesSelector(state, Number(projectId));
  const initialValues = initialValuesSelector(state, Number(projectId), stages);

  const orderForm = state.orderForms.entities[project?.orderFormId] || {};
  const account = state.accounts.entities[orderForm?.accountId] || {};
  const assignees = state.defaultAssignees.entities;
  const parentAccount =
    state.parentAccounts.entities[account?.parentAccountId] || {};

  return {
    assignees,
    account,
    initialValues,
    orderForm,
    parentAccount,
    project,
    projectLanguages,
    stages,
    people,
  };
};

const ProjectDefaultAssignees = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(DefaultAssignees));
ProjectDefaultAssignees.getInitialData = getInitialData;
export default ProjectDefaultAssignees;
