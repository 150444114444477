import PaymentList from "./containers/PaymentList";
import {
  paymentActionHandlers,
  paymentInitialState,
} from "../../../../modules/payments";

export default {
  Component: PaymentList,
  reducers: [
    {
      key: "payments",
      actionHandlers: paymentActionHandlers,
      initialState: paymentInitialState,
    },
  ],
};
