import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./InlineComments.module.scss";
import { SelectionState } from "draft-js";

export const COMMENT_STYLE_PREFIX = "COMMENT-";

/**
 * Extract a comment group id from an inline style name
 * (i.e. 'COMMENT-1234' => 1234)
 *
 * @param   {string}  styleName
 * @returns {number}  commentGroupId, or null if it is not a comment style
 */
export const parseCommentGroupId = (styleName) => {
  if (
    styleName.substring(0, COMMENT_STYLE_PREFIX.length) === COMMENT_STYLE_PREFIX
  ) {
    return Number(styleName.substring(COMMENT_STYLE_PREFIX.length));
  }

  return null;
};

/**
 * Creates a strategy function to find COMMENT-<groupId> inline styles
 *
 * @param   {Object}    commentGroups
 * @returns {function}  DraftJS strategy
 */
const createInlineCommentStrategy = (commentGroups) => {
  /**
   * @param {ContentBlock}  contentBlock  DraftJS content block to check
   * @param {function}      callback      callback to call with the start and end
   *  index of comment ranges
   */
  return (contentBlock, callback) => {
    contentBlock.findStyleRanges(
      ({ style }) =>
        style.some((name) => {
          const commentGroupId = parseCommentGroupId(name);
          return commentGroupId && commentGroups[commentGroupId];
        }),
      callback
    );
  };
};

/**
 * Highlight commented content
 */
class InlineCommentsComponent extends Component {
  ref = React.createRef();
  state = {
    open: false,
  };

  closeBox = () => {
    this.setState({ open: false });
  };

  getCommentGroupDetails = () => {
    const { commentGroups, contentState, children, offsetKey } = this.props;

    // Get comment group id from style
    const [blockKey] = offsetKey.split("-");
    const block = contentState.getBlockForKey(blockKey);
    const { start } = children[0].props;

    const commentGroupId = block
      .getInlineStyleAt(start)
      .toArray()
      .map((styleName) => parseCommentGroupId(styleName))
      .filter((id) => id && commentGroups[id])
      .pop();

    return { commentGroupId, blockKey, block };
  };

  onClick = () => {
    const { openCommentBox } = this.props;
    const { commentGroupId, blockKey, block } = this.getCommentGroupDetails();

    // Create selection
    const selection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: block.getLength(),
      isBackward: false,
    });

    const positionRect = this.ref.current.getBoundingClientRect();

    if (typeof window !== "undefined") {
      positionRect.y += window.pageYOffset;
    }

    openCommentBox(positionRect, "inline", commentGroupId, selection);
  };

  render() {
    const { commentGroups } = this.props;
    const { commentGroupId } = this.getCommentGroupDetails();

    const isResolved = commentGroups[commentGroupId].resolved;

    return (
      <span
        ref={this.ref}
        className={!isResolved ? styles.inlineComment : styles.resolvedComment}
        onClick={this.onClick}
      >
        {this.props.children}
      </span>
    );
  }
}

InlineCommentsComponent.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        start: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
  commentGroups: PropTypes.object.isRequired,

  // Draftjs
  contentState: PropTypes.object.isRequired,
  offsetKey: PropTypes.string.isRequired,
  openCommentBox: PropTypes.func.isRequired,
};

/**
 * @param   {Object}    commentGroups
 * @param   {function}  openCommentBox
 * @returns {Object}    DraftJS decorator object
 */
export default (commentGroups, openCommentBox) => ({
  component: InlineCommentsComponent,
  props: { commentGroups, openCommentBox },
  strategy: createInlineCommentStrategy(commentGroups),
});
