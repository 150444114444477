import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./PanelToggle.module.scss";

const PanelToggle = ({ isPanelOpen, onClick }) => {
  const renderSVG = (altTag, path) => (
    <svg alt={altTag} height="16" title={altTag} width="10">
      {path}
    </svg>
  );

  const toggleStyles = classNames({
    [styles.toggle]: true,
    [styles.isPanelOpen]: isPanelOpen,
  });

  return (
    <div className={styles.container}>
      <div className={toggleStyles} onClick={onClick}>
        {isPanelOpen
          ? renderSVG("Hide panel", <path d="M2 0l-2 2 6 6-6 6 2 2 8-8-8-8z" />)
          : renderSVG(
              "Show panel",
              <path d="M8 16l2-2-6-6 6-6-2-2-8 8 8 8z" />
            )}
      </div>
    </div>
  );
};

PanelToggle.propTypes = {
  isPanelOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default memo(PanelToggle);
