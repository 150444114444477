import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../modules/batches";

export const asyncQuery = `query batchesByProjectId ($projectId: String){
  ${batchesByProjectIdQuery}
}`;
export function getAsyncData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(asyncQuery, params, url, cookies).then((json) => {
      dispatch(fetchBatchesSuccess(json.batches));
    });
  };
}
