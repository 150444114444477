import { connect } from "react-redux";
import PaymentsShow from "../components/PaymentsShow";
import { getInitialData } from "../../_modules/getInitialData";
import { getAsyncData } from "../../_modules/getAsyncData";
import WithData from "../../../../../../decorators/WithData";
import {
  paymentSelector,
  projectById,
} from "../../../../../../utils/entitySelector";
import {
  approvePayment,
  archivePayment,
} from "../../../../../../modules/payments";
import {
  showModal,
  hideModal,
  ModalType,
} from "../../../../../../modules/modal";

const mapDispatchToProps = (dispatch, ownProps) => ({
  showModal: (type) => dispatch(showModal({ type: ModalType.ArchivePayment })),
  hideModal: (type) => dispatch(hideModal(type)),
  archivePayment: (paymentId) => dispatch(archivePayment(paymentId)),
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  getAsyncData: () => dispatch(getAsyncData(ownProps.match.params)),
  approvePayment: (paymentId) => dispatch(approvePayment(paymentId)),
});

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { paymentId },
    },
  } = ownProps;
  const [payment] = paymentSelector(state, Number(paymentId));

  if (!payment) return {};

  const { payeePersonId, orderFormId, projectId } = payment;
  const payee = state.people.entities[payeePersonId];
  const orderForm = state.orderForms.entities[orderFormId];
  const { currencyCode } = projectById(state, projectId);

  return {
    payment,
    payee,
    orderForm,
    modal: state.modal,
    currencyCode,
  };
};

const PaymentsShowWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(PaymentsShow));
PaymentsShowWithData.getInitialData = getInitialData;
export default PaymentsShowWithData;
