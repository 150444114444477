import React, { Component } from "react";
import PropTypes from "prop-types";
import validate from "./validations";
import { Field, reduxForm } from "redux-form";
import Input from "../../../../../components/Input";
import styles from "./UpdateForm.module.scss";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { passwordCount: 0 };
  }

  render() {
    const passwordMatchingErrorStyle =
      this.props.error && this.props.error.hasError ? styles.error : {};
    const passwordConstraintsErrorStyle =
      this.props.error &&
      this.props.error.password &&
      this.state.passwordCount > 0
        ? styles.error
        : {};

    return (
      <form
        noValidate
        onSubmit={this.props.handleSubmit}
        className={styles.updateForm}
      >
        <label className={styles.label}>Password:</label>
        <Field
          styles={styles.input}
          name="password"
          type="password"
          component={Input}
          onChange={(_, password) => {
            this.setState({ passwordCount: password.length });
          }}
        />
        <label className={styles.label}>Confirm Password:</label>
        <Field
          styles={styles.input}
          name="confirmPassword"
          type="password"
          component={Input}
        />

        <div className={`${styles.prompt} ${passwordMatchingErrorStyle}`}>
          {this.props.error && this.props.error.message}
        </div>
        <div className={`${styles.prompt} ${passwordConstraintsErrorStyle}`}>
          Password must be at least 8 characters.
        </div>

        <button
          type="submit"
          className={styles.button}
          disabled={this.props.pristine || this.props.submitting}
        >
          Update
        </button>
      </form>
    );
  }
}

Form.propTypes = {
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const UpdateForm = reduxForm({
  form: "update",
  validate,
})(Form);

export default UpdateForm;
