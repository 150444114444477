import React, { Component } from "react";
import DeliverableInfo from "./DeliverableInfo";
import TransitionHistory from "./TransitionHistory";
import PropTypes from "prop-types";
import styles from "./DeliverableTab.module.scss";
import { Link } from "react-router-dom";

class DeliverableTab extends Component {
  state = {
    showTransitionHistory: false,
  };

  toggleTransitionHistory = () => {
    this.setState({ showTransitionHistory: !this.state.showTransitionHistory });
  };

  render() {
    const historyIcon = (
      <svg height="15px" width="15px">
        {/* eslint-disable max-len */}
        <path d="M11.5 7c-2.485 0-4.5 2.015-4.5 4.5s2.015 4.5 4.5 4.5c2.485 0 4.5-2.015 4.5-4.5s-2.015-4.5-4.5-4.5zM11.5 14.688c-1.758 0-3.188-1.43-3.188-3.188s1.43-3.188 3.188-3.188 3.188 1.43 3.188 3.188-1.43 3.188-3.188 3.188z" />
        <path d="M12 11v-2h-1v3h3v-1z" />
        <path d="M5.5 7c3.038 0 5.5-1.119 5.5-2.5s-2.462-2.5-5.5-2.5-5.5 1.119-5.5 2.5v8c0 1.381 2.462 2.5 5.5 2.5 0.503 0 0.99-0.031 1.452-0.088-0.287-0.381-0.526-0.8-0.711-1.246-0.244 0.014-0.491 0.022-0.741 0.022-1.242 0-2.429-0.181-3.342-0.51-0.763-0.275-1.074-0.562-1.158-0.677v-2.594c0.995 0.643 2.64 1.062 4.5 1.062 0.114 0 0.226-0.002 0.338-0.005 0.043-0.459 0.141-0.902 0.287-1.324-0.206 0.010-0.415 0.016-0.625 0.016-1.242 0-2.429-0.181-3.342-0.51-0.762-0.275-1.074-0.562-1.158-0.677v-2.531c0.995 0.643 2.64 1.062 4.5 1.062zM2.402 3.823c0.846-0.329 1.946-0.51 3.098-0.51s2.252 0.181 3.098 0.51c0.707 0.275 0.995 0.562 1.074 0.677-0.078 0.115-0.367 0.402-1.074 0.677-0.846 0.329-1.946 0.51-3.098 0.51s-2.252-0.181-3.098-0.51c-0.707-0.275-0.996-0.562-1.074-0.677 0.078-0.115 0.367-0.402 1.074-0.677z" />
        {/* eslint-disable max-len */}
      </svg>
    );

    const renderLink = (
      deliverableId,
      stageId,
      isDeliverableApproved,
      personType
    ) => {
      let versionHistoryLink;

      if (personType === "Freelancer") {
        if (isDeliverableApproved)
          versionHistoryLink = `/freelancer/deliverable/${deliverableId}/stage/${stageId}/version-history`;
        else return null;
      }

      if (personType === "In-house")
        versionHistoryLink = `/admin/deliverable/${deliverableId}/version-history`;

      if (personType === "Client")
        versionHistoryLink = `/clients/deliverable/${deliverableId}/stage/${stageId}/version-history`;

      return (
        <Link
          className={styles.linkButton}
          target="_blank"
          to={versionHistoryLink}
        >
          Version history
          {historyIcon}
        </Link>
      );
    };

    const {
      deliverableId,
      personType,
      projectId,
      transitions,
      stageId,
      isDeliverableApproved,
    } = this.props;

    return (
      <div className={styles.scrollingContainer}>
        <DeliverableInfo deliverableId={deliverableId} projectId={projectId} />
        {personType === "In-house" && (
          <div>
            <div
              className={styles.linkButton}
              onClick={this.toggleTransitionHistory}
            >
              Transition history
              {historyIcon}
            </div>

            {this.state.showTransitionHistory && (
              <TransitionHistory
                deliverableId={deliverableId}
                toggleTransitionHistory={this.toggleTransitionHistory}
                transitions={transitions}
              />
            )}
          </div>
        )}
        {renderLink(deliverableId, stageId, isDeliverableApproved, personType)}
      </div>
    );
  }
}

DeliverableTab.propTypes = {
  deliverableId: PropTypes.number.isRequired,
  isDeliverableApproved: PropTypes.bool,
  personType: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  stageId: PropTypes.number.isRequired,
  transitions: PropTypes.array.isRequired,
};

export default DeliverableTab;
