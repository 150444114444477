import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import styles from "./styles.module.scss";
import LinkButton from "../../AssignmentEdit/Tasks/_plugins/LinkEditor/LinkButton";
import ColorPickerButton from "../../AssignmentEdit/Tasks/_plugins/ColorPicker/ColorPickerButton";
import FeatureToggle from "../../FeatureToggle";
import { AddTable } from "../../AssignmentEdit/Tasks/_plugins/draft-js-table-plugin";

const inlineStyles = [
  { text: "B", inlineStyle: "BOLD" },
  { text: "I", inlineStyle: "ITALIC" },
  { text: "U", inlineStyle: "UNDERLINE" },
];

const blockStyles = [
  { text: "#", blockType: "ordered-list-item" },
  { text: "•", blockType: "unordered-list-item" },
  { text: "H1", blockType: "header-one" },
  { text: "H2", blockType: "header-two" },
  { text: "H3", blockType: "header-three" },
];

const allOptions = [...inlineStyles, ...blockStyles];

const Toolbar = ({
  editorState,
  setEditorState,
  disabled,
  onlyInlineStyles,
  showLinkButton,
}) => {
  const buttons = onlyInlineStyles ? inlineStyles : allOptions;

  return (
    <div className={styles.toolbar}>
      {buttons.map(({ inlineStyle, blockType, text, id }) => {
        return (
          <Button
            key={id}
            disabled={disabled}
            editorState={editorState}
            setEditorState={setEditorState}
            inlineStyle={inlineStyle}
            blockType={blockType}
            text={text}
          />
        );
      })}
      <FeatureToggle toggle="colorPicker">
        <ColorPickerButton
          disabled={disabled}
          editorState={editorState}
          updateEditorState={setEditorState}
        />
      </FeatureToggle>

      <FeatureToggle toggle="draftJsTablePlugin">
        <AddTable
          disabled={disabled}
          editorState={editorState}
          onChange={setEditorState}
        />
      </FeatureToggle>

      {showLinkButton && (
        <LinkButton
          disabled={disabled}
          editorState={editorState}
          updateEditorState={setEditorState}
        />
      )}
    </div>
  );
};

Toolbar.propTypes = {
  disabled: PropTypes.bool,
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
};

export default Toolbar;
