import React, { useState } from "react";
import Modal from "../../../../../components/Modal/components/Modal";
import styles from "./AssignmentAdjustmentBtn.module.scss";
import Input from "../../../../../components/Input";
import classNames from "classnames";
import { useMutation } from "@apollo/client";
import { updateFreelancerDashboardAssignmentGroupSuccess } from "../../../../../modules/freelancerDashboard";
import { useDispatch } from "react-redux";
import { CREATE_ADJUSTMENT_QUERY_GQL } from "../../../../../modules/assignmentGroupAdjustment";
import Icon from "../../../../../components/Icon";
const DeadlineTabs = {
  EXTENSION: "EXTENSION",
  CANCELLATION: "CANCELLATION",
};

type ModalProps = {
  assignmentGroupId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  submit: (input: object) => void;
};

function AdjustmentModal(props: ModalProps) {
  const { assignmentGroupId, isOpen, setIsOpen, submit } = props;

  const [deadlineTab, setDeadlineTab] = useState(DeadlineTabs.EXTENSION);
  const [deadline, setDeadline] = useState("");
  const [reason, setReason] = useState("");

  const isExtension = deadlineTab === DeadlineTabs.EXTENSION;
  const isCancellation = deadlineTab === DeadlineTabs.CANCELLATION;

  const setExtension = () => {
    setDeadlineTab(DeadlineTabs.EXTENSION);
  };
  const setCancellation = () => {
    setDeadlineTab(DeadlineTabs.CANCELLATION);
  };

  const submitModal = () => {
    const adjustmentType = deadlineTab;
    const input = {
      assignmentGroupId,
      adjustmentType,
      reason,
      ...(isExtension ? { deadline } : {}),
    };
    submit(input);
  };

  const updateReason = (e) => {
    setReason(e.target.value.substr(0, 140));
  };

  const updateDeadline = (e) => {
    const replaced = e.target.value.replace(/T/, " ");
    setDeadline(replaced);
  };

  // deadline is only required when it's an extension
  const isSubmitDisabled =
    reason.length === 0 || (isExtension && deadline.length === 0);

  return (
    <Modal
      display={isOpen}
      hideModal={() => {
        setIsOpen(false);
      }}
      title={"Request adjustment to assignment"}
      body={
        <div>
          <div>
            Under exceptional circumstances you can request a deadline extension
            or cancellation of the assignment which you initially accepted
          </div>

          <div className={styles.deadlineTabs}>
            <div
              onClick={setExtension}
              className={`${styles.tab} ${isExtension ? styles.active : ""} `}
            >
              Request extension
            </div>
            <div
              onClick={setCancellation}
              className={`${styles.tab} ${isCancellation ? styles.active : ""}`}
            >
              Cancel assignment
            </div>
          </div>

          {isExtension && (
            <div>
              <div>
                Please note extensions may not be granted and your assignment
                may be reassigned
              </div>
              <div className={styles.deadlineTime}>
                New deadline request (in United Kingdom time)
                <Input
                  type="datetime-local"
                  input={{ name: "ab" }}
                  customStyles={styles.customInputStyles}
                  max="9999-12-31T00:00"
                  onChange={updateDeadline}
                />
              </div>
            </div>
          )}

          {isCancellation && (
            <div>
              Please note, it may not be possible to cancel your assignment
            </div>
          )}

          <div className={styles.requestContainer}>
            <div className={styles.requestText}>Reason for request*</div>
            {/* <span className={styles.small}>(140 characters)</span> */}
            <textarea
              onChange={updateReason}
              className={styles.requestReason}
              value={reason}
            />
            <div className={styles.small}>{reason.length}/140 characters</div>
          </div>

          <div className={styles.btnContainer}>
            <button
              disabled={isSubmitDisabled}
              className={styles.submit}
              type="submit"
              onClick={submitModal}
            >
              Submit
            </button>
          </div>
        </div>
      }
    />
  );
}

export default function AssignmentAdjustmentBtn(props) {
  const { assignmentGroupId, hasRequestPending } = props;

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [createAdjustment] = useMutation(CREATE_ADJUSTMENT_QUERY_GQL);

  const openDeadlineModeration = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const submit = async (input) => {
    const response = await createAdjustment({
      variables: { input },
    });

    if (response?.data?.createAssignmentGroupAdjustment) {
      // successful creation of adjustment
      const { createAssignmentGroupAdjustment } = response.data;

      if (
        assignmentGroupId === createAssignmentGroupAdjustment.assignmentGroupId
      ) {
        setIsOpen(false);
        dispatch(
          updateFreelancerDashboardAssignmentGroupSuccess({
            assignmentGroupId,
            hasRequestPending: true,
          })
        );
      }
    }
  };

  const btnStyles = classNames({
    [styles.deadlineModeration]: true,
    [styles.pending]: hasRequestPending,
  });

  return (
    <div className={btnStyles}>
      <AdjustmentModal
        isOpen={isOpen}
        submit={submit}
        setIsOpen={setIsOpen}
        assignmentGroupId={assignmentGroupId}
      />

      <div className={styles.container} onClick={openDeadlineModeration}>
        <div className={styles.tooltip}>
          <div>
            {hasRequestPending
              ? "Request extension or cancellation"
              : "Request adjustment to assignment"}
          </div>
          <div className={styles.triangle}></div>
        </div>
        <Icon name="EditCalendar" size="lg" />
      </div>
    </div>
  );
}
