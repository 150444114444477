import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

const DownloadPopup = ({
  shouldOpenUpwards,
  dropdownMenuStyles,
  children,
  coords: { right, top, height },
}) => {
  const [mount] = useState(() => document.getElementById("root"));
  const [el] = useState(() => document.createElement("div"));

  useEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  const popupPosition = {
    position: "absolute",

    // this looks wrong but actually positions to the right side of the button
    // and then offsets the full width of the dropdown items
    left: `${right}px`,
    transform: "translateX(-100%)",

    // if we're lower on the page it should position above instead of below
    ...(shouldOpenUpwards
      ? {
          top: `${top}px`,
          transform: "translate(-100%, -100%)",
        }
      : {
          top: `${top + height}px`,
        }),
  };

  return createPortal(
    <div className={dropdownMenuStyles} style={popupPosition}>
      {children}
    </div>,
    el
  );
};

export default DownloadPopup;
