import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";

export const automatedReminderFields = [
  "automatedReminderId",
  "projectId",
  "stageId",
  "minutesTrigger",
  "reminderType",
];

export const emailTemplates = {
  beforeDeadline: "before deadline",
  afterDeadline: "after deadline",
  afterRequestAmends: "after amends request",
};

export const automatedRemindersByProjectQuery = `automatedReminders (projectId: $projectId) { 
  ${automatedReminderFields}
}`;
const AUTOMATED_REMINDER_SUCCESS = "AUTOMATED_REMINDER_SUCCESS";
export const fetchAutomatedReminderSuccess = createAction(
  AUTOMATED_REMINDER_SUCCESS
);

export const automatedRemindersActionHandlers = {
  [RESET_INITIAL_STATE]: () => automatedRemindersInitialState,
  [AUTOMATED_REMINDER_SUCCESS]: (state, { payload }) => {
    return upsertData(state, payload, "automatedReminderId");
  },
};

export function updateAutomatedReminders(values) {
  return async (dispatch) => {
    const query = `mutation createAutomatedReminders ($input: [AutomatedReminderInput]) {
      createAutomatedReminders (input: $input) 
      {
       projectId, automatedReminderId, minutesTrigger, reminderType, stageId
      }
      
    }`;
    const { createAutomatedReminders } = await postGraphQL(query, {
      input: values,
    });

    dispatch(fetchAutomatedReminderSuccess(createAutomatedReminders));
  };
}

export const automatedRemindersInitialState = { entities: {}, result: [] };
