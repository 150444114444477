/**
 * @param val the value input into the payment form
 * @param prevRate the last valid rate of the field
 * @returns a payment value (up to 4 digits + 2 decimal places eg £0000.00)
 */
export const rateParser = (prevRate = 9999) => {
  return (val) => {
    let num = val;
    const index = val.indexOf(".");

    // if there is a dot, then only get the last 2 values after the dot (2 decimal places)
    if (index > -1) {
      num = val.substr(0, index + 3);
    }

    // if the number is greater than 9999 then use the max value provided
    if (Number(num || 0) > 9999) {
      num = prevRate;
    }

    return num;
  };
};
