import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./MultiSelectDropdown.module.scss";
import useOutsideClick from "../../hooks/useOutsideClick";
import fuzzysort from "fuzzysort";
import Icon from "../Icon";

const normalizeStr = (str) => str.replace(/_/g, " ");
const MAX_CHARS = 30;
const MAX_CHARS_SMALL = 15;

const MultiSelectDropdown = (props) => {
  const {
    options,
    optionsArr,
    disabled,
    onClick,
    openUpwards,
    hideSelectAll,
    filterText,
    rightAligned,
    small,
    mainButtonStyles,
    titleExtraStyles,
    mainContainerExtraStyles,
    selectedOptions,
    dropdownMenuExtraStyles,
  } = props;

  // give the title a max char limit
  const title = useMemo(() => {
    const { title } = props;

    if (small && title.length >= MAX_CHARS_SMALL) {
      return title.substring(0, MAX_CHARS_SMALL - 3) + "...";
    } else if (!small && title.length > MAX_CHARS) {
      return title.substring(0, MAX_CHARS - 3) + "...";
    }
    return title;
  }, [props, small]);

  const node = useRef();
  const filterNode = useRef();

  const [hidden, setHidden] = useState(true);
  const containerOnClick = (e) => {
    e.preventDefault();

    if (disabled) return;

    setHidden(!hidden);
  };

  const processedOptions = useMemo(() => {
    if (optionsArr) return optionsArr;

    return Object.entries(options).map(([id, value]) => ({
      id,
      value,
      normalized: normalizeStr(value),
    }));
  }, [options, optionsArr]);

  const [filteredOptions, setFilteredOptions] = useState(
    optionsArr || processedOptions
  );

  useEffect(() => {
    setFilteredOptions(processedOptions);
  }, [processedOptions]);

  useOutsideClick(node, () => {
    setHidden(true);
  });

  const inputFocus = (e) => {
    e.preventDefault();
  };

  const onSelect = (selectedOption) => {
    const index = selectedOptions.indexOf(selectedOption);
    if (index >= 0) {
      const updatedSelectedOptions = [...selectedOptions];
      updatedSelectedOptions.splice(index, 1);
      onClick(updatedSelectedOptions);
    } else {
      onClick([...selectedOptions, selectedOption]);
    }
  };

  const onSelectAll = () => {
    if (selectedOptions.length === filteredOptions.length) {
      onClick([]);
    } else {
      onClick(filteredOptions.map((option) => option.id));
    }
  };

  const onChange = ({ target: { value } }) => {
    if (value.length < 1) {
      return setFilteredOptions(processedOptions);
    }

    const normalizedSearchStr = normalizeStr(value);

    const results = fuzzysort
      .go(normalizedSearchStr, processedOptions, { key: "normalized" })
      .map((r) => r.obj);

    setFilteredOptions(results);
  };

  // if the dropdown is opened and we have a filter search input then focus it
  useEffect(() => {
    if (!hidden && filterNode?.current) {
      filterNode.current.focus();
    }
  }, [hidden]);

  const mainContainerStyles = classNames({
    [styles.mainContainerStyles]: true,
    [styles.active]: !hidden,
    [styles.openUpwards]: openUpwards,
    [styles.disabled]: disabled,
    [styles.filterText]: !!filterText,
    [mainContainerExtraStyles]: true,
  });
  const containerStyles = classNames({
    [styles.containerStyles]: true,
    [styles.active]: !hidden,
    [mainButtonStyles]: true,
    [styles.neutralButton]: true,
  });

  const dropdownStyles = classNames({
    [styles.dropdown]: true,
    [styles.hidden]: hidden,
    [styles.rightAligned]: rightAligned,
    [styles.small]: small,
    [dropdownMenuExtraStyles]: true,
  });

  const titleStyles = classNames({
    [styles.titleStyles]: true,
    [styles.disabled]: disabled,
    [titleExtraStyles]: true,
  });

  return (
    <span ref={node} className={mainContainerStyles}>
      <button className={containerStyles} onClick={containerOnClick}>
        <span className={titleStyles}>{title}</span>
        <Icon name="DropdownArrow" />
      </button>

      <div className={dropdownStyles}>
        {!!filterText && (
          <div className={styles.dropdownItem}>
            <input
              ref={filterNode}
              className={styles.dropdownFilter}
              onChange={onChange}
              onClick={inputFocus}
              placeholder={filterText}
            />
          </div>
        )}

        {!hideSelectAll && (
          <button
            onClick={onSelectAll}
            className={`${styles.selectAllWrapper} ${styles.neutralButton}`}
          >
            <input
              checked={filteredOptions.length === selectedOptions.length}
              onChange={onSelectAll}
              type="checkbox"
            />
            <div className={styles.selectAll}>Select all</div>
          </button>
        )}

        {filteredOptions.map(({ id, value }) => {
          const checked = selectedOptions.includes(id);
          return (
            <button
              onClick={() => onSelect(id)}
              className={`${styles.dropdownItemWrapper} ${styles.neutralButton}`}
              key={id}
            >
              <input
                checked={checked}
                onChange={() => onSelect(id)}
                type="checkbox"
              />
              <div key={id} className={styles.dropdownItem} data-id={id}>
                {value}
              </div>
            </button>
          );
        })}
      </div>
    </span>
  );
};

MultiSelectDropdown.propTypes = {
  filterText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.object,
  optionsArr: PropTypes.array,
  rightAligned: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string.isRequired,
  hideSelectAll: PropTypes.bool,
  selectedOptions: PropTypes.array,
  dropdownMenuExtraStyles: PropTypes.string,
  disabled: PropTypes.bool,
  openUpwards: PropTypes.bool,
  mainButtonStyles: PropTypes.string,
  titleExtraStyles: PropTypes.string,
  mainContainerExtraStyles: PropTypes.string,
};

export default MultiSelectDropdown;
