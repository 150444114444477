import { createAction } from "redux-actions";
import {
  fetchAssignmentsByGroup,
  unsubmittedAssignmentsInGroupSelector,
} from "./assignments";
import { showModal, ModalType } from "./modal";
import { fetchProjectById } from "./projects";
import { PersonType } from "./people";
import {
  stageById,
  assignmentsByGroupSelector,
  getLoggedInUser,
} from "../utils/entitySelector";
import { createSelector } from "reselect";
import { postGraphQL } from "../../utils/fetch";
import { nullObject } from "../utils/nullObjects";

export const personOverallGradesQuery = `personOverallGrades (personId: $personId) {
  personId, qualityGrade
}`;

export const orderFormGradesQuery = `orderFormGrades (orderFormId: $orderFormId) {
  personId, qualityGrade
}`;

export class GradeNotSubmitted extends Error {
  constructor() {
    super();
    // a workaround to make `instanceof GradeNotSubmitted` work in ES5
    // see: https://github.com/babel/babel/issues/4485
    this.constructor = GradeNotSubmitted;
    this.__proto__ = GradeNotSubmitted.prototype; // eslint-disable-line no-proto
  }
}

/**
 * Indicates whether freelancer grades should be displayed to the current
 * logged-in user
 *
 * @param   {Object}  state
 * @returns {boolean} true if the grades should be displayed, otherwise false
 */
export const shouldDisplayGrades = createSelector(
  getLoggedInUser,
  ({ featureToggles }) => featureToggles,
  ({ personType }, featureToggles) =>
    (personType === PersonType.Admin &&
      featureToggles.QCC_1798_freelancerGrading) ||
    (personType === PersonType.Freelancer &&
      featureToggles.QCC_1911_displayGradesToFreelancers)
);

/**
 * Displays the quality grading form if the last deliverables in the group are
 * being transitioned forward
 *
 * @param {Object}    transition
 * @param {number}    assignmentGroupId
 * @param {number[]}  deliverableIds
 * @returns {Promise} resolved when the grades are submitted (or if form
 *  isn't required), otherwise rejected
 */
export const displayGradingFormIfApplicable = (
  transition,
  assignmentGroupId,
  deliverableIds
) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (!state.featureToggles.QCC_1798_freelancerGrading) return;

    // Only grade on forward transitions
    const { forwardTransition, fromStageId } = transition;
    if (!forwardTransition) return;

    // Check current stage references a stage to grade
    const { gradeStageId } = stageById(state, fromStageId);
    if (!gradeStageId) return;
    const stageToGrade = stageById(state, gradeStageId);

    // Get all assignments that are eligible for grading
    await dispatch(fetchAssignmentsByGroup(assignmentGroupId));
    const eligibleAssignments = assignmentsToGradeSelector(
      getState(),
      assignmentGroupId,
      gradeStageId
    );
    if (eligibleAssignments.length === 0) return;

    const deliverableIsTransitioning = deliverableIds.reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {});

    // If the remaining tasks in the group are all being transitioned
    if (
      eligibleAssignments.every(
        ({ deliverableId }) => deliverableIsTransitioning[deliverableId]
      )
    ) {
      const loggedInUserId = Number(state.me);
      const previousAssignees = assignmentsByGroupSelector(
        state,
        assignmentGroupId
      ).reduce(
        (
          acc,
          {
            deliverableId,
            previousAssigneeId: personId,
            previousAssignee: personFullName,
            projectId,
          }
        ) => {
          if (personId === loggedInUserId) return acc;

          if (!acc[personId]) {
            acc[personId] = { personId, personFullName, deliverableIds: [] };
          }

          acc[personId].deliverableIds.push(deliverableId);
          return acc;
        },
        {}
      );

      const peopleToGrade = Object.values(previousAssignees);

      if (peopleToGrade.length === 0) return;

      const { projectId } = eligibleAssignments[0];

      return new Promise((resolve, reject) => {
        dispatch(
          showModal({
            type: ModalType.QualityGradingForm,
            data: {
              onCancel: () => reject(new GradeNotSubmitted()),
              onSubmit: resolve,
              peopleToGrade,
              projectId,
              stageToGrade,
              transition,
            },
          })
        );
      });
    }
  };
};

/**
 * Retrieve assignments that are eligible for grading
 *
 * @param   {Object}    state
 * @param   {number}    assignmentGroupId the assignment group id being graded
 * @param   {number}    gradeStageId      the stage id being graded
 *
 * @returns {Object[]}  eligible assignments to grade
 */
const assignmentsToGradeSelector = createSelector(
  unsubmittedAssignmentsInGroupSelector,
  (state) => state.stages.entities,
  (_s, _a, gradeStageId) => gradeStageId,
  (unsubmittedAssignments, stages, gradeStageId) =>
    unsubmittedAssignments.filter(({ previousStageIds }) =>
      // Only include if no previous stage has already graded this assignment
      previousStageIds.every((stageId) => {
        const stage = stages[stageId];
        return stage.gradeStageId !== gradeStageId;
      })
    )
);

/**
 * Submit quality grades for a given set of deliverables
 *
 * @param {number}    projectId       the project being reviewed
 * @param {number}    reviewerStageId the stage the review is reviewing from
 * @param {number}    forStageId      the stage being reviewed
 * @param {Object[]}  peopleGrades    array of previous assignees to grade, including:
 *  { personId, deliverableIds: [], objectiveGrade, briefGrade }
 */
export const submitGrades = (
  projectId,
  reviewerStageId,
  forStageId,
  peopleGrades
) => {
  return async (dispatch, getState) => {
    const { me } = getState();
    const reviewerPersonId = Number(me);

    // Project info
    const {
      accountId,
      verticalType: accountVertical,
      orderFormId,
    } = await dispatch(fetchProjectById(projectId));

    // Combine project info with people grades
    const qualityGrades = {
      accountId,
      accountVertical,
      orderFormId,
      projectId,
      forStageId,
      reviewerStageId,
      reviewerPersonId,
      grades: peopleGrades.map(
        ({
          briefGrade,
          deliverableIds,
          objectiveGrade,
          personId: forPersonId,
        }) => ({
          deliverableIds,
          briefGrade,
          forPersonId,
          objectiveGrade,
        })
      ),
    };

    await postGraphQL(
      `mutation createAllocationGrade($qualityGrades: AllocationGradeInput) {
      createAllocationGrade(qualityGrades: $qualityGrades)
    }`,
      { qualityGrades }
    );
  };
};

// ------------------------------------
// Selectors
// ------------------------------------

/**
 * Returns a person's overall grades across all projects
 *
 * @param {Object}  state
 * @param {number}  personId
 *
 * @returns {Object}  { qualityGrade }
 */
export const personOverallGradesSelector = createSelector(
  (state) => state.grades.overall,
  (_, personId) => personId,
  (grades, personId) => grades[personId] || nullObject
);

/**
 * Returns grades for a given order form, indexed by person id
 *
 * @param {Object}  state
 * @param {number}  orderFormId
 *
 * @returns {Object}  { [personId]: { qualityGrade } }
 */
export const orderFormPersonGradesSelector = createSelector(
  (state) => state.grades.orderForms,
  (_, orderFormId) => orderFormId,
  (grades, orderFormId) => grades[orderFormId] || nullObject
);

// ------------------------------------
// Actions
// ------------------------------------
const FETCH_OVERALL_GRADE_SUCCESS = "FETCH_OVERALL_GRADE_SUCCESS";
export const fetchOverallGradeSuccess = createAction(
  FETCH_OVERALL_GRADE_SUCCESS
);

const FETCH_ORDER_FORM_GRADES_SUCCESS = "FETCH_ORDER_FORM_GRADES_SUCCESS";
export const fetchOrderFormGradesSuccess = createAction(
  FETCH_ORDER_FORM_GRADES_SUCCESS
);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const gradesActionHandlers = {
  [FETCH_OVERALL_GRADE_SUCCESS]: (state, { payload }) => {
    const { personId } = payload;

    return {
      ...state,
      overall: {
        ...state.overall,
        [personId]: payload,
      },
    };
  },
  [FETCH_ORDER_FORM_GRADES_SUCCESS]: (state, { payload }) => {
    const { orderFormId, orderFormGrades } = payload;
    const newGrades = orderFormGrades.reduce((acc, cur) => {
      acc[cur.personId] = cur;
      return acc;
    }, {});

    const { orderForms: allOrderFormGrades } = state;
    const existingGrades = allOrderFormGrades[orderFormId];

    return {
      ...state,
      orderForms: {
        ...allOrderFormGrades,
        [orderFormId]: { ...existingGrades, ...newGrades },
      },
    };
  },
};

export const gradesInitialState = { overall: {}, orderForms: {} };
