import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";

export const emailLogFields = [
  "emailLogId",
  "email",
  "assignmentGroupId",
  "emailTemplate",
  "createDate",
];

export const emailLogsQueryByProjectId = `emailLogs (projectId: $projectId) { 
  ${emailLogFields}
}`;

const EMAIL_LOGS_SUCCESS = "EMAIL_LOGS_SUCCESS";
export const fetchEmailLogsSuccess = createAction(EMAIL_LOGS_SUCCESS);

export const emailLogsActionHandlers = {
  [RESET_INITIAL_STATE]: () => emailLogsInitialState,
  [EMAIL_LOGS_SUCCESS]: (state, { payload }) => {
    return upsertData(state, payload, "assignmentGroupId");
  },
};

export const emailLogsInitialState = { entities: {}, result: [] };
