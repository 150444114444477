import { createAction } from "redux-actions";

const SELECT_BATCH_LANG = "SELECT_BATCH_LANG";
export const selectBatchlang = createAction(SELECT_BATCH_LANG);
const DESELECT_BATCH_LANG = "DESELECT_BATCH_LANG";
export const deselectBatchlang = createAction(DESELECT_BATCH_LANG);

export const downloadActionHandlers = {
  [SELECT_BATCH_LANG]: (
    state,
    { payload: { batchId, languageCode, stageId } }
  ) => {
    const batchLang = `${batchId},${languageCode}`;
    return {
      ...state,
      [stageId]: {
        ...state[stageId],
        [batchLang]: true,
      },
    };
  },
  [DESELECT_BATCH_LANG]: (
    state,
    { payload: { batchId, languageCode, stageId } }
  ) => {
    const batchLang = `${batchId},${languageCode}`;

    // first remove the stage object
    const { [stageId]: stageData, ...rest } = state;
    // then remove the batchLang from the object
    const { [batchLang]: _removed, ...stageRest } = stageData;

    // if any batch langs exist add them back in
    const newStageObj =
      Object.keys(stageRest).length > 0 ? { [stageId]: stageRest } : {};

    return {
      ...rest,
      ...newStageObj,
    };
  },
};

export const downloadInitialState = {};
