import {
  versionHistoryQuery,
  fetchVersionHistoryDetailsSuccess,
} from "../../../../../../modules/versionHistory";
import { getGraphQL } from "../../../../../../../utils/fetch";

export const initialQuery = `query versionHistoryQueryByDeliverableId ($deliverableId: Int, $stageId: Int) {
  ${versionHistoryQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchVersionHistoryDetailsSuccess(json.versionHistory));
    });
  };
}
