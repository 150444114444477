import { connect } from "react-redux";
import ImageEditor from "../components/ImageEditor";

const mapStateToProps = (state) => ({
  gCloud: state.gCloud,
});

const ConnectedImageEditor = connect(mapStateToProps, null, null, {
  forwardRef: true,
})(ImageEditor);
export default ConnectedImageEditor;
