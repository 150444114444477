import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../../../../components/PageHeader";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import OverlayLoading from "../../../../../../components/OverlayLoading/v2";
import Messages from "../../../../../../components/Messages/v1";
import { showSuccessMessage } from "../../../../../../modules/messages";
import parseQuery from "../../../../../../utils/parseQuery";
import styles from "./ProjectBatches.module.scss";
import BatchForms from "./BatchForms/BatchForms";

const ProjectBatches = (props) => {
  const {
    account: { accountName },
    createBatch,
    dataReady,
    history,
    location,
    orderForm: { orderFormNumber },
    parentAccount: { parentAccountName },
    project: { projectName, projectId },
    updateBatch,
    archiveBatch,
    showModal,
    hideModal,
  } = props;

  const baseUrl = `/admin/projects/${projectId}`;
  const newBatchDefaults = { batchId: 0, batchName: "", projectId };

  const [activeBatchId, setActiveBatchId] = useState(null);
  const [hasNewBatchBeenCreated, setHasNewBatchBeenCreated] = useState(false);
  const messages = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const searchParams = parseQuery(location.search);

  /**
   * Show a success message once a batch has been created
   */
  const showNewBatchSuccessMessage = useCallback(
    (batchName) => {
      const message = `Batch "${batchName}" was successful created!`;
      dispatch(showSuccessMessage(message));
    },
    [dispatch]
  );

  // initially add a "new batch" row to the batches
  const [batches, setBatches] = useState([newBatchDefaults, ...props.batches]);

  /**
   * Once the props change, check to see if a new batch has been created and
   * whether we still need to populate a new batch button
   */
  useEffect(() => {
    if (!hasNewBatchBeenCreated) {
      setBatches([newBatchDefaults, ...props.batches]);
    } else {
      setBatches(props.batches);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batches, hasNewBatchBeenCreated]);

  /**
   * Process the click of a batch row
   */
  const onClick = useCallback(
    (e) => {
      const batchId = Number(e.currentTarget.getAttribute("data-id"));

      setActiveBatchId(batchId === activeBatchId ? null : batchId);
    },
    [activeBatchId]
  );

  /**
   * When saving a field we might be creating or updating so figure out here
   * how to process the batch
   *
   * @param {Object} batchDetails
   */
  const updateOrCreate = async (batchDetails) => {
    const batch = { ...batchDetails, projectId };

    // if the batchId exists then we're updating a batch rather than creating
    if (batch.batchId !== 0) {
      return updateBatch(batch);
    }

    const batchId = await createBatch(batch);

    showNewBatchSuccessMessage(batch.batchName);

    // hide the new batch row
    setHasNewBatchBeenCreated(true);
    setActiveBatchId(batchId);

    history.replace({ search: "" });
  };

  /**
   * If the URL contains a query string to open a new batch, wait until
   * after the first render so it autofocuses the batchName field
   */
  useEffect(() => {
    if (searchParams.new) {
      setActiveBatchId(0);
    }
  }, [searchParams.new]);

  if (!dataReady) return <OverlayLoading />;

  return (
    <div>
      {messages.display && <Messages />}

      <PageHeader
        breadCrumbItems={[
          { url: "/admin", text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { url: baseUrl, text: projectName },
          { text: "Batches" },
        ]}
        title={`${projectName} - Batches`}
      />

      {batches.map((batch) => {
        const { batchId, batchName } = batch;
        const isActiveBatch = batchId === activeBatchId;
        const isNewBatch = batchId === 0;

        const batchRowStyles = classNames({
          [styles.batchRow]: true,
          [styles.expanded]: isActiveBatch,
          [styles.newBatch]: isNewBatch,
        });

        return (
          <div key={batchId} className={batchRowStyles}>
            <div
              className={styles.batchName}
              data-id={batchId}
              onClick={onClick}
            >
              {isNewBatch ? "New batch" : batchName}
            </div>

            {isActiveBatch && (
              <div className={styles.formContainer}>
                <BatchForms
                  batch={batch}
                  projectId={projectId}
                  updateOrCreate={updateOrCreate}
                  archiveBatch={archiveBatch}
                  showModal={showModal}
                  hideModal={hideModal}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

ProjectBatches.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  batches: PropTypes.array.isRequired,
  createBatch: PropTypes.func.isRequired,
  dataReady: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  messages: PropTypes.shape({
    display: PropTypes.bool,
  }),
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number,
  }),
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string,
  }),
  project: PropTypes.shape({
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  }),
  updateBatch: PropTypes.func.isRequired,
};

export default ProjectBatches;
