import {
  AtomicBlockUtils,
  EditorState,
  ContentBlock,
  genKey,
  SelectionState,
  ContentState,
} from "draft-js";
import * as types from "../constants";

export default function addTable(editorState, { columns, rows }) {
  const contentState = editorState.getCurrentContent();

  const columnsMapped = Array.from({ length: columns }).map((_, i) => ({
    key: `Column${i}`,
    value: `Column ${i + 1}`,
  }));

  const rowsMapped = Array.from({ length: rows }).map((_, i) => ({
    key: `Row${i}`,
    value: Array.from({ length: columns }).map((__, j) => ({
      key: `Row${i}Cell${j}`,
      value: `Cell ${j}`,
    })),
  }));

  const contentStateWithEntity = contentState.createEntity(
    types.TABLETYPE,
    "MUTABLE", // Use 'MUTABLE' to allow editing within the block
    { columns: columnsMapped, rows: rowsMapped }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  // Insert the atomic block with the table entity
  let newEditorState = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    " "
  );

  // Move the selection to a new unstyled block after the atomic block
  const newContentState = newEditorState.getCurrentContent();
  const blockMap = newContentState.getBlockMap();
  const selectionKey = newEditorState.getSelection().getAnchorKey();
  const blockKeys = blockMap.keySeq().toArray();
  const insertionIndex = blockKeys.findIndex((k) => k === selectionKey);

  // Create a new unstyled block
  const newBlockKey = genKey();
  const newBlock = new ContentBlock({
    key: newBlockKey,
    type: "unstyled",
    text: "",
  });

  // Insert the new block after the atomic block
  const blocksBefore = blockMap.toSeq().take(insertionIndex + 1);
  const blocksAfter = blockMap.toSeq().skip(insertionIndex + 1);
  const newBlockMap = blocksBefore
    .concat([[newBlockKey, newBlock]], blocksAfter)
    .toOrderedMap();

  // Create a new content state with the updated block map
  const finalContentState = newContentState.merge({
    blockMap: newBlockMap,
  });

  // Push the new content state into the editor state
  newEditorState = EditorState.push(
    newEditorState,
    finalContentState,
    "insert-fragment"
  );

  // Move the selection to the new unstyled block
  const newSelection = SelectionState.createEmpty(newBlockKey);
  newEditorState = EditorState.forceSelection(newEditorState, newSelection);

  return newEditorState;
}
