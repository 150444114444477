import { createAction } from "redux-actions";
import { reset } from "redux-form";
import { createSelector } from "reselect";
import { upsertData, filterData } from "../utils/normalize";
import handleErrors from "../utils/handleErrors";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import {
  fetchAssignmentGroupSuccess,
  removeAssignmentGroupsById,
  assignmentGroupsByProjectIdQueryV2,
} from "./assignmentGroups";
import { hideModal } from "./modal";
import { RESET_INITIAL_STATE } from "./me";
import { updateFreelancerDashboardStatusSuccess } from "./freelancerDashboard";
import { convertDate, formatDateObject } from "../utils/date";
import { getLoggedInUser } from "../utils/entitySelector";
import { StageType } from "./stages";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const fields = [
  "actionable",
  "archived",
  "assignmentGroupId",
  "assignmentId",
  "batchId",
  "createDate",
  "deadline",
  "deliverableId",
  "isCurrentStage",
  "isEditable",
  "languageCode",
  "personId",
  "previousAssignee",
  "previousAssigneeId",
  "personFullName",
  "projectId",
  "rate",
  "reassigned",
  "stageId",
  "stageName",
  "status",
  "wasAmended",
];

export const assignmentsByDeliverableIdQuery = `assignments (deliverableId: $deliverableId) {
  ${fields}
}`;

export const assignmentsByProjectQuery = `assignments (projectIds: $projectId) {
  ${fields}
}`;

export const nextAndPriorAssignmentsByAssignmentQuery = `nextAndPriorAssignments (assignmentId: $assignmentId) {
  ${fields}
}`;

export const nextAndPriorAssignmentsByAssignmentGroupQuery = `nextAndPriorAssignments (assignmentGroupId: $assignmentGroupId) {
  ${fields}
}`;

export const assignmentsByAssignmentGroupQuery = `assignments (assignmentGroupId: $assignmentGroupId, includeArchivedAssignments: $includeArchivedAssignments) {
  ${fields}
}`;

export const assignmentQuery = `assignment (assignmentId: $assignmentId) {
  ${fields}
}`;

export const processAssignmentQuery = `mutation processAssignments($assignmentGroupIds: String, $status: String) {
  processAssignment(assignmentGroupIds: $assignmentGroupIds, status: $status) {
    assignmentGroupId, status
  }
}`;

const updateAssignmentsQuery = `mutation updateAssignments($assignmentIds: String, $values: AssignmentUpdatedValuesInput) {
  updateAssignments(assignmentIds: $assignmentIds, values: $values) {
    assignmentIds
  }
}`;

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_ASSIGNMENTS_SUCCESS = "FETCH_ASSIGNMENTS_SUCCESS";
export const FETCH_ASSIGNMENTS_BY_GROUP_SUCCESS =
  "FETCH_ASSIGNMENTS_BY_GROUP_SUCCESS";
export const CREATE_ASSIGNMENTS_SUCCESS = "CREATE_ASSIGNMENTS_SUCCESS";
export const CREATE_REASSIGNMENTS_SUCCESS = "CREATE_REASSIGNMENTS_SUCCESS";
export const UPDATE_ASSIGNMENT_SUCCESS = "UPDATE_ASSIGNMENT_SUCCESS";
export const UPDATE_ASSIGNMENTS_SUCCESS = "UPDATE_ASSIGNMENTS_SUCCESS";
export const PROCESS_ASSIGNMENTS_SUCCESS = "PROCESS_ASSIGNMENTS_SUCCESS";
const UPDATE_REASSIGNED_ASSIGNMENTS = "UPDATE_REASSIGNED_ASSIGNMENTS";

export const AssignmentStatus = Object.freeze({
  Accepted: "Accepted",
  AmendsRequested: "Amends requested",
  Approved: "Approved",
  NotActioned: "Not Actioned",
  Rejected: "Rejected",
  Submitted: "Submitted",
});

// ------------------------------------
// Actions
// ------------------------------------

export const fetchAssignmentsSuccess = createAction(FETCH_ASSIGNMENTS_SUCCESS);
export const fetchAssignmentsByGroupSuccess = createAction(
  FETCH_ASSIGNMENTS_BY_GROUP_SUCCESS
);
export const createAssignmentsSuccess = createAction(
  CREATE_ASSIGNMENTS_SUCCESS
);
export const createReassignmentsSuccess = createAction(
  CREATE_REASSIGNMENTS_SUCCESS
);
export const updateAssignmentSuccess = createAction(UPDATE_ASSIGNMENT_SUCCESS);
export const updateAssignmentsSuccess = createAction(
  UPDATE_ASSIGNMENTS_SUCCESS
);
export const processAssignmentsSuccess = createAction(
  PROCESS_ASSIGNMENTS_SUCCESS
);
const updateReassignedAssignments = createAction(UPDATE_REASSIGNED_ASSIGNMENTS);

export function clearAssignmentsForm() {
  return (dispatch, getState) => {
    dispatch(reset("assignmentsForm"));
  };
}

type ReassignmentsType = {
  projectId: number;
  assignmentGroupId: number;
  assignees: object;
  deadlines: object;
  responseNote?: string;
};

export function createReassignments(data: ReassignmentsType) {
  return async (dispatch, getState) => {
    const state = getState();
    const stages = state.stages.entities;

    const { projectId, assignmentGroupId, assignees, deadlines, responseNote } =
      data;

    /* form data is sent in an array with a count of the number to reassign by assignmentGroupId
     * therefore we must iterate through our assignments by group and grab only the number of
     * assignments reassigned for each entry */

    const reassignments: any[] = [];
    Object.keys(assignees).forEach((stageId) => {
      Object.keys(assignees[stageId]).forEach((languageCode) => {
        Object.keys(assignees[stageId][languageCode]).forEach((personId) => {
          const reassignment = assignees[stageId][languageCode][personId];
          const trainer = stages[stageId].stageType === StageType.Training;

          const {
            allocation,
            rate = 0,
            training: inTraining = 0,
          } = reassignment;

          if (allocation > 0) {
            reassignments.push({
              personId,
              rate,
              inTraining,
              trainer,
              allocation,
              deadline: formatDateObject(convertDate(deadlines[stageId], true)),
            });
          }
        });
      });
    });

    const query = `
      mutation createReassignments(
        $assignments: [ReassignmentInput],
        $assignmentGroupId: AssignmentGroupIdInput,
        $responseNote: String
      ) {
        createReassignments(
          assignments: $assignments,
          assignmentGroupId: $assignmentGroupId,
          responseNote: $responseNote
        ) {
          assignments {
            assignmentId, stageId, personId, deliverableId, rate, deadline, status, reassigned, createDate, assignmentGroupId, archived
          },
          archivedAssignments {
            assignmentId, stageId, personId, deliverableId, rate, deadline, status, reassigned, createDate, assignmentGroupId, archived
          }
        }
      }
    `;

    return postGraphQL(
      query,
      {
        assignmentGroupId: { assignmentGroupId },
        assignments: reassignments,
        responseNote,
      },
      "createReassignments"
    )
      .then((json) => {
        const combinedAssignments = json.assignments.concat(
          json.archivedAssignments
        );
        dispatch(updateAssignmentsSuccess(combinedAssignments));
        // we want to remove all of the existing rows for the assignment group (if they still exist they will be re added)
        dispatch(removeAssignmentGroupsById(assignmentGroupId));
        dispatch(hideModal());
      })
      .then(() => {
        /* To handle V2 scenario: we need to retrieve assignmentGroups in their
         * revised form and override the previous assignment group dispatch */
        const query = `query refetchAssignmentGroups ($projectId: String) {
          ${assignmentGroupsByProjectIdQueryV2}
        }`;

        getGraphQL(query, { projectId }).then((json) => {
          dispatch(fetchAssignmentGroupSuccess(json.assignmentGroups));
        });
      })
      .catch((err) => handleErrors(err));
  };
}

export function processAssignments(assignmentGroupIds, status) {
  return (dispatch, getState) => {
    if (assignmentGroupIds.length === 0) return;

    return postGraphQL(processAssignmentQuery, {
      assignmentGroupIds: assignmentGroupIds.join(","),
      status,
    })
      .then((json) =>
        dispatch(
          updateFreelancerDashboardStatusSuccess({ assignmentGroupIds, status })
        )
      )
      .catch((err) =>
        console.log(
          `Error with updating status to ${status} for assignment group ids: '${assignmentGroupIds}'`,
          err.errors,
          err.message
        )
      );
  };
}

export function updateAssignments(assignmentIds, values) {
  return async (dispatch) => {
    const { assignmentIds: result } = await postGraphQL(
      updateAssignmentsQuery,
      { assignmentIds, values },
      "updateAssignments"
    );
    const ids = result.split(",").map((id) => Number(id));
    dispatch(updateReassignedAssignments(ids));
    return ids;
  };
}

/**
 * Fetches all assignments within an assignment group and dispatches them to
 * the store
 *
 * @param {number}      assignmentGroupId
 * @return {Function}   async dispatcher function
 */
export function fetchAssignmentsByGroup(assignmentGroupId) {
  return async (dispatch) => {
    const { assignments } = await getGraphQL(
      `query AssignmentsByAssignmentGroup (
        $assignmentGroupId: String,
        $includeArchivedAssignments: Boolean
      ) {
        assignments (assignmentGroupId: $assignmentGroupId, includeArchivedAssignments: $includeArchivedAssignments) {
          ${fields}, previousStageIds
        }
      }`,
      { assignmentGroupId, includeArchivedAssignments: false }
    );

    await dispatch(fetchAssignmentsSuccess(assignments));
  };
}

// ------------------------------------
// Selectors
// ------------------------------------

/**
 * Calculates an assignment's status for the current user
 *
 * @param   {Object}  state
 * @param   {Object}  assignment
 * @returns {Object}  object containing 'isActionable', 'isCommentable', 'isEditable'
 */
export const getAssignmentStatus = createSelector(
  getLoggedInUser,
  (state) => state.stages.entities,
  (_s, assignment) => assignment,
  (loggedInUser, stages, assignment) => {
    if (!assignment.stageId) return {};

    const { isCurrentStage, personId, stageId } = assignment;
    const { isPrimary, isFinal } = stages[stageId];
    const { personType } = loggedInUser;
    const isEditable =
      assignment.isEditable &&
      (assignment.personId === personId || personType === "In-house");

    const isCommentable = isCurrentStage && !isPrimary && !isFinal;

    return {
      isActionable: assignment.actionable,
      isCommentable,
      isEditable,
    };
  }
);

/**
 * Returns assignments that haven't been transitioned forward from this group
 *
 * @param   {Object} state
 * @param   {number} assignmentGroupId
 * @returns {Object[]}  assignments that haven't yet been submitted
 */
export const unsubmittedAssignmentsInGroupSelector = createSelector(
  (state: any) => state.assignments.entities as any[],
  (_, assignmentGroupId) => assignmentGroupId,
  (assignments, assignmentGroupId) => {
    return Object.values(assignments).filter(
      (a: any) =>
        a.assignmentGroupId === assignmentGroupId &&
        !a.archived &&
        !a.reassigned &&
        a.status !== AssignmentStatus.Submitted &&
        a.status !== AssignmentStatus.Approved &&
        a.status !== AssignmentStatus.AmendsRequested
    );
  }
);

// ------------------------------------
// Action Handlers
// ------------------------------------

export const assignmentActionHandlers = {
  [RESET_INITIAL_STATE]: () => assignmentInitialState,
  [FETCH_ASSIGNMENTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "assignmentId"),
  [CREATE_ASSIGNMENTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "assignmentId"),
  [FETCH_ASSIGNMENTS_BY_GROUP_SUCCESS]: (
    state,
    { payload: { assignments, assignmentGroupId } }
  ) => {
    const assignmentsById = assignments.reduce((acc, cur) => {
      acc[cur.assignmentId] = acc;
      return acc;
    }, {});

    // Remove assignments in group that aren't present in new data
    const filteredState = filterData(
      state,
      (a) =>
        a.assignmentGroupId !== assignmentGroupId ||
        !assignmentsById[a.assignmentId]
    );

    // Update data
    return upsertData(filteredState, assignments, "assignmentId");
  },
  [UPDATE_ASSIGNMENT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "assignmentId"),
  [UPDATE_ASSIGNMENTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "assignmentId"),
  [PROCESS_ASSIGNMENTS_SUCCESS]: (state, { payload }) => {
    const status = payload.length > 0 ? payload[0].status : null;
    const assignmentGroupIds = payload.map((o) => o.assignmentGroupId);

    const assignmentIds: any[] = [];
    Object.keys(state.entities).forEach((id) => {
      if (assignmentGroupIds.includes(state.entities[id].assignmentGroupId)) {
        assignmentIds.push(id);
      }
    });

    return assignmentGroupIds.reduce((acc, id) => {
      return assignmentIds.reduce((acc2, p) => {
        return upsertData(
          acc2,
          { ...state.entities[p], status },
          "assignmentId"
        );
      }, acc);
    }, state);
  },
  [UPDATE_REASSIGNED_ASSIGNMENTS]: (
    { entities: oldEntities, result },
    { payload }
  ) => {
    const entities = payload.reduce(
      (acc, id) => {
        acc[id] = { ...acc[id], reassigned: true };
        return acc;
      },
      { ...oldEntities }
    );

    return { entities, result };
  },
};

export const assignmentInitialState = { entities: {}, result: [] };
