import React from "react";
import PageHeader from "../../../components/PageHeader";
import ProjectInfo from "../../../components/PageHeader/ProjectInfo";
import styles from "./ClientPageHeader.module.scss";

const ClientPageHeader = ({
  accountName,
  orderFormName,
  projectName,
  projectId,
  title,
}) => {
  const baseUrl = `/clients/projects/${projectId}`;

  return (
    <PageHeader
      breadCrumbItems={[
        { url: "/clients", text: accountName },
        { text: `${orderFormName} | ${projectName}` },
      ]}
      title={title || projectName}
    >
      <div className={styles.infoContainer}>
        <ProjectInfo url={`${baseUrl}/project-info`} />
      </div>
    </PageHeader>
  );
};

export default ClientPageHeader;
