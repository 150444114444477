import {
  isNotBlank,
  validLength,
  passwordMatch,
  checkValidations,
} from "../../../../../utils/validations";

const validate = (values) => {
  const errors = {};

  const passwordErrors = checkValidations(values.password, [
    { validate: isNotBlank, msg: "Cannot be blank" },
    {
      validate: validLength({ min: 8 }),
      msg: "Password needs to be at least 8 characters",
    },
  ]);

  const confirmErrors = checkValidations(values.confirmPassword, [
    { validate: isNotBlank, msg: "Cannot be blank" },
  ]);

  if (passwordErrors) {
    errors._error = {
      hasError: true,
      message: "",
      password: passwordErrors,
    };
  }

  if (confirmErrors) {
    errors.confirmPassword = confirmErrors;
  } else if (!passwordMatch(values.password, values.confirmPassword)) {
    errors._error = {
      ...errors._error,
      hasError: true,
      message: `These passwords don't match.`,
    };
  }

  return errors;
};

export default validate;
