import { connect } from 'react-redux'
import InProgress from '../components/InProgress'
import WithData from '../../../../../decorators/WithData'

import { getInitialData } from '../../../_modules/getInitialData'
import { processAssignments } from '../../../../../modules/assignments'

import { createDashboardGroupingSelector } from '../../_selectors/dashboard'

// Create the selectors for getting the different groups of assignment groups
const getNewAssignmentGroups = createDashboardGroupingSelector(
  ({ status }) => status === 'Not Actioned'
)
const getCurrentAssignmentGroups = createDashboardGroupingSelector(
  ({ assignmentStageId, deliverableStageId, status }) =>
    (status === 'Accepted' || status === 'Amends requested') &&
    assignmentStageId === deliverableStageId
)
const getUpcomingAssignmentsGroups = createDashboardGroupingSelector(
  ({ assignmentStageId, deliverableStageId, status }) =>
    status === 'Accepted' && assignmentStageId !== deliverableStageId
)
const getSubmittedAssignmentGroups = createDashboardGroupingSelector(
  ({ status }) => status === 'Submitted'
)

const mapDispatchToProps = (dispatch) => {
  return {
    getInitialData: () => dispatch(getInitialData()),
    processAssignments: (assignmentGroupIds, status) =>
      dispatch(processAssignments(assignmentGroupIds, status)),
  }
}

const mapStateToProps = (state, ownProps) => {
  const newAssignmentGroups = getNewAssignmentGroups(state)

  const currentAssignmentGroups = getCurrentAssignmentGroups(state)

  const upcomingAssignmentGroups = getUpcomingAssignmentsGroups(state)

  const submittedAssignmentGroups = getSubmittedAssignmentGroups(state)

  return {
    newAssignmentGroups,
    currentAssignmentGroups,
    upcomingAssignmentGroups,
    submittedAssignmentGroups,
  }
}

const DashboardInProgress = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(InProgress))
export default DashboardInProgress
