import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import styles from "./Nav.module.scss";
import classNames from "classnames";

const Nav = (props) => {
  const navItems = props.items.map((item, idx) => {
    const isLinkExternal = item.url.substr(0, 2) === "//";
    const navItemsStyles = classNames({
      [styles.navItems]: true,
      [styles.active]: item.active,
    });

    const itemFill = item.active ? "#fff" : "#B3B3B3";
    const linkElement = (
      <div className={navItemsStyles}>
        <Icon size="lg" name={item.iconName} fill={itemFill} />
        <div className={styles.tag} data-hover>
          {item.hoverText}
        </div>
      </div>
    );

    return isLinkExternal ? (
      <a
        rel="noopener noreferrer"
        key={idx}
        className={styles.link}
        href={item.url}
        target={"_blank"}
      >
        {linkElement}
      </a>
    ) : (
      <Link key={idx} className={styles.link} to={item.url}>
        {linkElement}
      </Link>
    );
  });

  return (
    <div className={styles.nav}>
      <Link className={styles.logoContainer} to={props.home}>
        <img
          alt="logo"
          className={styles.logo}
          src="/icons/nav/sidebar-scribe-logo.svg"
        />
      </Link>
      <div className={styles.navItemsContainer}>{navItems}</div>
      <div className={styles.logout} onClick={props.logout}>
        <Icon
          size="lg"
          alt="logout"
          name="Logout"
          className={styles.img}
          fill="#B3B3B3"
        />
        <div className={styles.tag} data-hover>
          Logout
        </div>
      </div>
    </div>
  );
};

Nav.propTypes = {
  home: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  logout: PropTypes.func.isRequired,
};

export default Nav;
