import { createAction } from "redux-actions";

export const SELECT_DROPDOWN_ITEM = "SELECT_DROPDOWN_ITEM";

export const selectDropdownItem = createAction(SELECT_DROPDOWN_ITEM);

export const dropdownActionHandlers = {
  [SELECT_DROPDOWN_ITEM]: (state, { payload: { name, value } }) => ({
    ...state,
    [name]: value,
  }),
};

export const dropdownInitialState = {};

const reducer = (state = dropdownInitialState, action) => {
  const handleFn = dropdownActionHandlers[action.type];
  return handleFn ? handleFn(state, action) : state;
};

export default reducer;
