/* eslint key-spacing:0 spaced-comment:0 */
const endpoints = require("./endpoints");
const deepmerge = require("deepmerge");
const environments = require("./environments");
const debugFn = require("debug");

const debug = debugFn("app:config");

const env = process.env.NODE_ENV || "local";
const shouldBuildProd = env === "production" || env === "staging";
const shouldBuildDev = env === "development" || env === "local";

debug("Creating default configuration for env:" + process.env.NODE_ENV);

// ========================================================
// Default Configuration
// ========================================================
const config = {
  env: env,

  // ----------------------------------
  // Project Structure
  // ----------------------------------
  paths: {
    client: "client",
    dist: "dist",
    server: "server",
    test: "tests",
    web: "web", //client code is built to dist/web while server code mimics this code base
  },

  // ----------------------------------
  // Endpoints
  // ----------------------------------
  // frontend routes are injected into webpack to be used as global vars
  // backend routes are not injected into webpack to hide this detail from the client
  // NOTE: Ensure that any server-side requests point to the backend URLs
  endpoints,

  // ------------------------------------
  // Server config
  // ------------------------------------

  server: {
    port: process.env.PORT || 3000,
    universal: "main.server.js",
    cookie: {
      sid: "production-editor:sid",
      key: "_qp:ac",
      sessionExpiry: 28800000,
      rememberExpiry: 1209600000,
      domain: "." + process.env.DOMAIN_FRONTEND_SHORT,
      secret: "some_random_secret_no_one_will_guess",
    },
  },

  // ----------------------------------
  // Globals injected into webpack
  // ----------------------------------
  globals: {
    "process.env": {
      NODE_ENV: shouldBuildProd
        ? JSON.stringify("production")
        : JSON.stringify(env),
    },
    NODE_ENV: shouldBuildProd ? "production" : env,
    __DEV__: shouldBuildDev,
    __STAGING__: env === "staging",
    __PROD__: shouldBuildProd,
    __TEST__: env === "test",
    __COVERAGE__: env === "test",
  },

  // ----------------------------------
  // Compiler Configuration
  // ----------------------------------
  compiler: {
    babel: {
      cacheDirectory: true,
      plugins: ["transform-object-rest-spread", "transform-runtime"],
      presets: [["env", { modules: false }], "stage-2", "react"],
    },
    devtool: shouldBuildDev ? "cheap-eval-source-map" : "source-map",
    quiet: false,
    public_path: "/",
    stats: {
      chunks: false,
      chunkModules: false,
      colors: true,
    },
  },
};

// ========================================================
// Environment Configuration
// ========================================================
debug(`Looking for environment overrides for NODE_ENV "${config.env}".`);
let final = config;
const overrides = environments[config.env];
if (overrides) {
  debug("Found overrides, applying to default configuration.");
  //@ts-ignore
  final = deepmerge(config, overrides(config));
} else {
  debug("No environment overrides found, defaults will be used.");
}
module.exports = final;
