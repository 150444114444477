import React from "react";
import { connect } from "react-redux";
import { isRequesting } from "../../../../../../../../modules/requestTracker";
import {
  moveToSourceAmends,
  REQUEST_SOURCE_AMENDS,
} from "../../../../../../../../modules/parentDeliverables";
import { hasSourceAmendsStage } from "../../../../../../../../modules/stages";
import {
  parentDeliverableById,
  projectById,
} from "../../../../../../../../utils/entitySelector";
import styles from "./UsefulQueries.module.scss";

type Props = {
  isRequestingSourceAmends: boolean;
  moveToSourceAmends: () => void;
  showSourceAmendsBtn: boolean;
};
const UsefulQueries = ({
  isRequestingSourceAmends,
  moveToSourceAmends,
  showSourceAmendsBtn,
}: Props) => {
  if (!showSourceAmendsBtn) return null;

  return (
    <div className={styles.usefulQueries}>
      <div className={styles.header}>Useful queries</div>
      <div className={styles.queries}>
        <button
          className={styles.btn}
          disabled={isRequestingSourceAmends}
          onClick={moveToSourceAmends}
        >
          Move to source amends
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch, { parentDeliverableId }) => ({
  moveToSourceAmends: () => dispatch(moveToSourceAmends(parentDeliverableId)),
});

const mapStateToProps = (state, { parentDeliverableId }) => {
  // @ts-ignore
  const { projectId } = parentDeliverableById(state, parentDeliverableId);
  // @ts-ignore
  const { workflowId } = projectById(state, projectId);

  return {
    isRequestingSourceAmends: isRequesting(state, REQUEST_SOURCE_AMENDS),
    showSourceAmendsBtn: hasSourceAmendsStage(state, workflowId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsefulQueries);
