import { connect } from "react-redux";
import { createSelector } from "reselect";
import AutomatedReminders from "../components/AutomatedReminders";
import WithData from "../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { getAssignableWorkflowStages } from "../../../../../../modules/stages";
import { updateAutomatedReminders } from "../../../../../../modules/automatedReminders";
import {
  accountById,
  orderFormById,
  parentAccountById,
  projectById,
} from "../../../../../../utils/entitySelector";

const reminderTypes = ["beforeDeadline", "afterDeadline", "afterRequestAmends"];

const automatedRemindersSelector = createSelector(
  (state) => state.automatedReminders.entities,
  (automatedReminders) => Object.values(automatedReminders)
);

const initialValuesSelector = createSelector(
  (automatedReminders) => automatedReminders,
  (automatedReminders, stages) => stages,
  (automatedReminders, stages, reminderTypes) => reminderTypes,
  (automatedReminders, stages, reminderTypes) => {
    // redux form needs keys to start with strings, so it couldn't be the rateBandId

    const tree = { automatedReminders: {} };
    reminderTypes.forEach((rt) => {
      if (!tree["automatedReminders"][rt]) {
        tree["automatedReminders"][rt] = {};
        Object.values(stages).forEach((st) => {
          if (!tree["automatedReminders"][rt][st.stageId]) {
            tree["automatedReminders"][rt][st.stageId] = 0;
            automatedReminders.forEach(
              ({ reminderType, stageId, minutesTrigger }) => {
                if (rt === reminderType && stageId === st.stageId) {
                  tree["automatedReminders"][rt][st.stageId] = minutesTrigger;
                }
              }
            );
          }
        });
      }
    });
    return tree;
  }
);

const mapDispatchToProps = (dispatch) => ({
  getInitialData: (params) => dispatch(getInitialData(params)),
  updateAutomatedReminders: (data) => dispatch(updateAutomatedReminders(data)),
});

const mapStateToProps = (state, ownProps) => {
  const {
    match: { params },
  } = ownProps;
  const projectId = Number(params.projectId);
  const project = projectById(state, projectId) || {};
  const stages = getAssignableWorkflowStages(state, project.workflowId);
  const orderForm = orderFormById(state, project.orderFormId);
  const account = accountById(state, orderForm.accountId);
  const parentAccount = parentAccountById(state, account.parentAccountId);
  const automatedReminders = automatedRemindersSelector(state);
  const initialValues = initialValuesSelector(
    automatedReminders,
    stages,
    reminderTypes
  );

  return {
    project,
    stages,
    orderForm,
    account,
    parentAccount,
    automatedReminders,
    initialValues,
    reminderTypes,
  };
};

const ConnectedAutomatedReminders = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AutomatedReminders));
export default ConnectedAutomatedReminders;
