import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "./SearchResults.module.scss";
import LoadingV2 from "../../../../../Loading/v2";

const SearchResults = ({
  isLoading,
  minCharCount,
  searchResults,
  searchTerm,
  isGlossary,
}) => {
  if (searchTerm.length !== 0 && searchTerm.length < minCharCount) {
    return (
      <div className={styles.error}>
        Search text needs to be at least {minCharCount} characters
      </div>
    );
  }

  if (isLoading)
    return (
      <div className={styles.loadingContainer}>
        <LoadingV2 small />
      </div>
    );

  if (searchTerm.length > 0 && searchResults.length === 0) {
    return (
      <div className={styles.message}>
        No results for search term:
        <div className={styles.term}>{searchTerm}</div>
      </div>
    );
  }

  if (searchTerm.length === 0)
    return (
      <div className={styles.message}>
        Enter a term to search in the {isGlossary ? "glossary" : "TM database"}
      </div>
    );

  return (
    <div className={styles.searchResults}>
      {searchResults.map(({ source, translated }) => (
        <div key={`${source}-${translated}`} className={styles.result}>
          <div className={styles.source}>{source}</div>
          <div className={styles.translated}>{translated}</div>
        </div>
      ))}
    </div>
  );
};

SearchResults.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  minCharCount: PropTypes.number.isRequired,
  searchResults: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default memo(SearchResults);
