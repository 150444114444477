import React, { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import Select from "react-select";
import { stageById } from "../../../../../../../../modules/stages";
import { UPDATE_CLIENT_BATCH_NOTIFICATIONS } from "../../../../../../../../modules/clientBatchNotifications";
import ValueComponent from "../../../../../../../../decorators/ReduxFormCompatible/ValueComponent";
import classNames from "classnames";
import styles from "./index.module.scss";

type ValueType = {
  label: string;
  value: number;
};

type ClientLanguagesType = {
  stageId: number;
  batchId: number;
  clientBatchNotifications: {
    stageId: number;
    personId: number;
    enabled: boolean;
  }[];
  projectClientsList: ValueType[];
  disabled: boolean;
};

export function ClientLanguagesSelector({
  stageId,
  batchId,
  disabled,
  projectClientsList,
  clientBatchNotifications = [],
}: ClientLanguagesType) {
  const { stageName } = useSelector((state) => stageById(state, stageId));
  const [updateClientNotifications] = useMutation(
    UPDATE_CLIENT_BATCH_NOTIFICATIONS
  );

  const filteredStageClients = useMemo(() => {
    return clientBatchNotifications.filter(
      (cbn) => cbn.enabled && cbn.stageId === stageId
    );
  }, [clientBatchNotifications, stageId]);

  const [values, setValues] = useState<ValueType[]>(() => {
    return projectClientsList.filter(({ value }) => {
      return filteredStageClients.some(({ personId }) => {
        return personId === value;
      });
    });
  });

  function updateValues(updatedValues: ValueType[]) {
    setValues(updatedValues);

    const personIds = updatedValues.map(({ value }) => value);
    const input = { batchId, stageId, personIds };
    updateClientNotifications({
      variables: { input },
    });
  }

  const headerStyles = classNames({
    [styles.stageName]: true,
    [styles.disabled]: disabled,
  });

  return (
    <div key={stageId}>
      <div className={headerStyles}>{stageName} (notifications)</div>

      {/* @ts-ignore */}
      <Select
        options={projectClientsList}
        multi
        disabled={disabled}
        onChange={updateValues}
        closeOnSelect={false}
        value={values}
        valueComponent={ValueComponent}
      />
    </div>
  );
}

type PeopleWithLanguagesType = (state: any, personIds: number[]) => ValueType[];
export const peopleWithLanguagesByIds: PeopleWithLanguagesType = createSelector(
  (state: any) => state.people.entities,
  (state: any) => state.personNativeLanguages,
  (_, personIds) => personIds,
  (peopleEntities, personNativeLanguages, personIds) => {
    return personIds.map((personId) => {
      const { firstName, lastName } = peopleEntities[personId] || {};
      const nativeLanguages = personNativeLanguages.reduce((acc, pnl) => {
        if (pnl.personId === personId) {
          acc.push(pnl.nativeLanguage);
        }
        return acc;
      }, []);

      return {
        label: `${firstName} ${lastName} (${nativeLanguages.join(", ")})`,
        value: personId,
      };
    });
  }
);
