import { connect } from "react-redux";
import Component from "./ClientDeliverable";
import WithData from "../../../../../../../../../decorators/WithData";
import {
  batchByIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../../modules/batches";
import {
  deliverableWithAdjacentIds,
  fetchDeliverablesSuccess,
} from "../../../../../../../../../modules/deliverables";
import {
  projectQuery,
  fetchProjectsSuccess,
} from "../../../../../../../../../modules/projects";

import {
  deliverableById,
  projectById,
  stageById,
  batchById,
} from "../../../../../../../../../utils/entitySelector";
import { getGraphQL } from "../../../../../../../../../../utils/fetch";
import {
  personNativeLanguagesQuery,
  fetchPersonNativeLanguagesSuccess,
} from "../../../../../../../../../modules/personNativeLanguages";

const initialQuery = `query ClientAssignmentEdit ($batchId: String, $deliverableId: String, $projectId: String, $personId: String) {
  ${batchByIdQuery}
  ${deliverableWithAdjacentIds}
  ${projectQuery}
  ${personNativeLanguagesQuery}
}`;

function getInitialData(params, url, cookies) {
  return async (dispatch, getState) => {
    const personId = getState().me;
    const json = await getGraphQL(
      initialQuery,
      { ...params, personId },
      url,
      cookies
    );
    dispatch(fetchBatchesSuccess(json.batch));
    dispatch(fetchProjectsSuccess(json.project));
    dispatch(fetchDeliverablesSuccess(json.deliverable));
    dispatch(fetchPersonNativeLanguagesSuccess(json.personNativeLanguages));
  };
}

function mapDispatchToProps(dispatch, { match }) {
  return {
    getInitialData: () => dispatch(getInitialData(match.params)),
  };
}

function mapStateToProps(state, ownProps) {
  const {
    match: { params = {} },
    history,
    errors = {},
  } = ownProps;
  const { deliverableId, projectId } = params;

  const project = projectById(state, projectId);
  const { batchId, batchName } = batchById(state, params.batchId);
  const { isFinal, stageId, stageName, stageType } = stageById(
    state,
    params.stageId
  );

  const { currentStageId, languageCode, nextIdInBatch, prevIdInBatch } =
    deliverableById(state, deliverableId);

  const baseUrl = `/clients/projects/${projectId}/batches/${batchId}/stages/${currentStageId}`;
  const listUrl = languageCode && `${baseUrl}?language=${languageCode}`;
  const nextUrl = nextIdInBatch && `${baseUrl}/deliverables/${nextIdInBatch}`;
  const previousUrl =
    prevIdInBatch && `${baseUrl}/deliverables/${prevIdInBatch}`;

  const shouldShowLanguageMismatchModal =
    state.personNativeLanguages?.length > 0
      ? !state.personNativeLanguages.some(
          (language) => language.nativeLanguage === languageCode
        )
      : false;

  return {
    batchId,
    batchName,
    deliverableId: Number(deliverableId),
    errors,
    history,
    isCommentable: !isFinal && stageType === "Client",
    languageCode,
    listUrl,
    nextUrl,
    previousUrl,
    projectId: Number(projectId),
    project,
    shouldShowLanguageMismatchModal,
    stageId,
    stageName,
  };
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Component));

export default {
  Component: Container,
};
