import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import handleErrors from "../utils/handleErrors";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";
import { reset } from "redux-form";
import { showErrorMessage, showSuccessMessage } from "./messagesV2";
import { extractErrorFromObject } from "../utils/errors";
import { listToValueAndLabel } from "../utils/entitySelector";
import { toMysqlFormat } from "../utils/date";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const paymentFields = `
  paymentId, payeePersonId, rate, createDate, payDate, paymentComplete, firstName, batchName,
  lastName, task, taskFieldName, projectName, accountId, accountName, paymentType, projectId,
  stageName, assignmentId, assignmentGroupId, orderFormId, notes, grossAmount, vatAmount, archivedBy, paymentStatus,
  deliverableId, currencyCode, draftPaymentDate
`;

// resolvers

export const paymentsQuery = `payments (
  accountId: $accountId,
  orderFormId: $orderFormId,
  personId: $personId,
  projectId: $projectId,
  endDate: $endDate,
  startDate: $startDate,
  paymentType: $paymentType,
  paymentTask: $paymentTask,
  stageName: $stageName,
) {
  ${paymentFields}
}`;

export const paymentsByPersonQuery = `payments (byPerson: $byPerson) {
  ${paymentFields}
}`;

export const paymentsByPersonAndDateRangeQuery = `payments (byPerson: $byPerson, startDate: $startDate, endDate: $endDate) {
  ${paymentFields}
}`;

export const paymentsByPaymentIdsQuery = `payments (paymentIds: $paymentId) {
  ${paymentFields}
}`;

// full queries

export const QUERY_PAYMENT_BY_ID = `query paymentById ($paymentId: String) {
  ${paymentsByPaymentIdsQuery}
}`;

// mutations

export const MUTATION_APPROVE_PAYMENT = `
  mutation approvePayment ($paymentId: Int) {
    approvePayment (paymentId: $paymentId) {
      ${paymentFields}
    }
  }
`;

// ------------------------------------
// Constants
// ------------------------------------
const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
const ARCHIVE_PAYMENT_SUCCESS = "ARCHIVE_PERSON_SUCCESS";

export const paymentTasks = [
  { value: "Editorial Lead tasks", label: "Quality Lead tasks" },
  { value: "Test reviewing", label: "Test reviewing" },
  { value: "Test creation", label: "Test creation" },
  { value: "Additional QA", label: "Additional QA" },
  { value: "Additional amends", label: "Additional amends" },
  { value: "Editorial training", label: "Content training" },
  { value: "Project training - trainer", label: "Project training - trainer" },
  { value: "Project training - trainee", label: "Project training - trainee" },
  { value: "Network resourcing", label: "Network resourcing" },
  { value: "Video creation", label: "Video creation" },
  { value: "Infographic creation", label: "Infographic creation" },
  { value: "Design creation", label: "Design creation" },
  { value: "Coding", label: "Coding" },
  { value: "Script writing", label: "Script writing" },
  { value: "Project management", label: "Project management" },
  { value: "Content uploading", label: "Content uploading" },
  { value: "Pre-sales samples", label: "Pre-sales samples" },
  { value: "Fact checking", label: "Fact checking" },
  { value: "Additional transcreation", label: "Additional transcreation" },
  { value: "Ideation", label: "Ideation" },
  { value: "Briefing day", label: "Briefing day" },
  { value: "Additional translation", label: "Additional translation" },
  { value: "Additional writing", label: "Additional writing" },
  { value: "Additional editing", label: "Additional editing" },
  {
    value: "Additional editorial training",
    label: "Additional editorial training",
  },
  { value: "Keyword research", label: "Keyword research" },
  { value: "Keyword QA", label: "Keyword QA" },
];

// export payment task labels for use in the UI
export const paymentTaskLabels = Object.fromEntries(
  paymentTasks.map((task) => [task.value, task.label])
);

export const PAYMENT_TYPE = {
  AD_HOC: "AD_HOC",
  AD_HOC_DRAFT: "AD_HOC_DRAFT",
  WORKFLOW: "WORKFLOW",
  TRANSLATION_MEMORY: "TRANSLATION_MEMORY",
};

export const PAYMENT_TYPES_DROPDOWN = listToValueAndLabel([
  PAYMENT_TYPE.WORKFLOW,
  PAYMENT_TYPE.TRANSLATION_MEMORY,
  PAYMENT_TYPE.AD_HOC,
  PAYMENT_TYPE.AD_HOC_DRAFT,
]);

export const PAYMENT_TYPE_REGULAR = "REGULAR";
export const PAYMENT_TYPE_AD_HOC = "AD_HOC";
export const PAYMENT_TYPE_AD_HOC_DRAFT = "AD_HOC_DRAFT";
export const PAYMENT_TYPE_OPTIONS = [
  {
    label: "Approved (default)",
    value: PAYMENT_TYPE_REGULAR,
  },
  {
    label: "Draft",
    value: PAYMENT_TYPE_AD_HOC_DRAFT,
  },
];

/**
 * Returns an array of the next 4 year/month combinations to populate
 * ad hoc draft payment months
 */
function getAdHocPaymentDates() {
  const date = new Date();
  const baseYear = date.getFullYear();
  const baseMonth = date.getMonth();

  const arr = [];
  for (let i = 0; i < 4; i++) {
    const month = baseMonth + i;
    const isNextYear = month >= 12;

    const year = baseYear + (isNextYear ? 1 : 0);

    const nextDate = new Date(baseYear, month, 1);
    const monthName = nextDate.toLocaleString("default", { month: "short" });

    const monthValuePre = month - (isNextYear ? 12 : 0) + 1; // js month to SQL ... mess
    const monthValue = monthValuePre < 10 ? `0${monthValuePre}` : monthValuePre;

    arr.push({
      label: `${year} ${monthName}`,
      value: `${year}-${monthValue}`,
    });
  }

  return arr;
}

export const DRAFT_PAYMENT_DATE_OPTIONS = getAdHocPaymentDates();

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPaymentsSuccess = createAction(FETCH_PAYMENTS_SUCCESS);
export const updatePaymentSuccess = createAction(UPDATE_PAYMENT_SUCCESS);
export const archivePaymentSuccess = createAction(ARCHIVE_PAYMENT_SUCCESS);

async function getPayment({ paymentId }) {
  return getGraphQL(QUERY_PAYMENT_BY_ID, { paymentId });
}

export function clearPaymentsForm() {
  return (dispatch) => {
    dispatch(reset("paymentsForm"));
  };
}

// Only used for ADHOC payments right now (But may be extended for other payments later)
export function createPayment(data) {
  const {
    notes,
    draftPaymentDate,
    paymentType,
    paymentStatus,
    peopleIds,
    projectId,
    rate,
    task,
  } = data;
  const payeePeopleIds = peopleIds.join(",");

  return (_, getState) => {
    const query = `mutation createPayment($input: CreatePaymentInput) {
      createPayment(input: $input) {
        payeePeopleIds, rate, createdByPersonId, paymentType, projectId, task, notes
      }
    }`;

    const { me } = getState();
    const input = {
      payeePeopleIds,
      rate,
      createdByPersonId: me,
      paymentType,
      paymentStatus,
      draftPaymentDate: `${draftPaymentDate}-01 00:00:00`,
      projectId,
      task,
      notes,
    };

    return postGraphQL(query, { input }, "createPayment");
  };
}

export function updatePayment(
  { createDate, ...data },
  history,
  { successUrl }
) {
  return (dispatch, getState) => {
    const input = {
      ...data,
      createDate: toMysqlFormat(createDate),
    };

    const query = `mutation updatePayment($input: UpdatePaymentInput) {
      updatePayment(input: $input) {
        paymentId, rate, createDate
      }
    }`;

    return postGraphQL(query, { input }, "updatePayment")
      .then((json) => {
        dispatch(updatePaymentSuccess(json));
        history.push(successUrl);

        return json;
      })
      .catch((err) =>
        handleErrors(err, { exceeded: "You entered a rate that is too high." })
      );
  };
}

export function fetchPayments(dateString) {
  const paymentQuery = `query freelancerFetchPayments ($byPerson: Int, $startDate: String, $endDate: String) {
    ${paymentsByPersonAndDateRangeQuery}
  }`;

  const regex = new RegExp(/[^\d|\-|&]/, "g");
  const formattedDate = dateString.replace(regex, "");
  const [startDate, endDate] = formattedDate.split("&");

  return async (dispatch) => {
    const { payments } = await getGraphQL(paymentQuery, { endDate, startDate });
    dispatch(fetchPaymentsSuccess(payments));
  };
}

export function backdatePayments({ date, ...data }) {
  const input = {
    ...data,
    date: toMysqlFormat(date),
  };

  return async (dispatch) => {
    const query = `mutation updatePayment($input: PaymentBackdateInput) {
      backdatePayments(input: $input) {
        paymentId, createDate
      }
    }`;

    const { backdatePayments } = await postGraphQL(query, { input });
    dispatch(updatePaymentSuccess(backdatePayments));
    dispatch(showSuccessMessage(`Payments were backdated to ${date}`));
  };
}

export function archivePayment(paymentId) {
  return (dispatch) => {
    const query = `mutation archivePayment($input: UpdatePaymentInput) {
      archivePayment(input: $input) {
        paymentId, paymentStatus
      }
    }`;

    return postGraphQL(query, { input: { paymentId } }, "archivePayment")
      .then(dispatch(archivePaymentSuccess(paymentId)))
      .catch((err) => err);
  };
}

export function approvePayment(paymentId) {
  return async (dispatch) => {
    try {
      const { approvePayment } = await getGraphQL(MUTATION_APPROVE_PAYMENT, {
        paymentId,
      });

      // frontend sanity check if backend returned a successful ID
      if (paymentId === approvePayment?.paymentId) {
        // backend route is garbage needs to refetch...
        const { payments } = await getPayment({ paymentId });

        // should only be one payment....
        if (payments?.length >= 1) {
          const [payment] = payments;
          dispatch(updatePaymentSuccess(payment));
          dispatch(showSuccessMessage("Draft payment successfully approved"));
        } else {
          dispatch(
            showErrorMessage(
              "Draft payment could not be approved (Please contact the tech team if this persists)"
            )
          );
        }
      }
    } catch (err) {
      dispatch(showErrorMessage(extractErrorFromObject(err)));
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

export const paymentActionHandlers = {
  [RESET_INITIAL_STATE]: () => paymentInitialState,
  [ARCHIVE_PAYMENT_SUCCESS]: (state, { payload: paymentId }) =>
    upsertData(state, { paymentId, paymentStatus: "Archived" }, "paymentId"),
  [FETCH_PAYMENTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "paymentId"),
  [UPDATE_PAYMENT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "paymentId"),
};

export const paymentInitialState = { entities: {}, result: [] };
