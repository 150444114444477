import React, { Component } from "react";

import { injectReducer } from "store/createStore";

function asyncComponent(getComponentAndReducers) {
  return class AsyncComponent extends Component {
    static Component = null;
    static ReducersLoaded = false;

    constructor() {
      super();
      this.mounted = false;
      this.state = {
        Component: AsyncComponent.Component,
        ReducersLoaded: AsyncComponent.ReducersLoaded,
      };
    }

    static loadComponentAndReducers() {
      return getComponentAndReducers()
        .then((m) => m.default)
        .then(({ Component, reducers }) => {
          AsyncComponent.Component = Component;
          if (reducers) {
            reducers.forEach((reducer) => injectReducer(reducer));
            AsyncComponent.ReducersLoaded = true;
          }
          return Component;
        });
    }

    componentWillMount() {
      if (this.state.Component === null || !this.state.ReducersLoaded) {
        AsyncComponent.loadComponentAndReducers().then((Component) => {
          if (this.mounted) {
            this.setState({ Component, ReducersLoaded: true });
          }
        });
      }
    }

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const { Component } = this.state;

      if (Component !== null) {
        return <Component {...this.props} />;
      }
      return null; // or <div /> with a loading spinner, etc..
    }
  };
}

export default asyncComponent;
