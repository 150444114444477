import asyncComponent from "decorators/AsyncComponent";

// ========================================================
// Admin bundles
// ========================================================

export const AdminLayout = asyncComponent(() =>
  import(/* webpackChunkName: "AdminLayout" */ "./Admin/_layout")
);

export const ParentAccountsList = asyncComponent(() =>
  import(
    /* webpackChunkName: "ParentAccountsList" */ "./Admin/ParentAccountsList"
  )
);

export const ProjectsNew = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectsNew" */ "./Admin/Projects/ProjectsNew")
);

export const ProjectsInfo = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsInfo" */
    "./Admin/Projects/Id/ProjectsInfo"
  )
);

export const ProjectsBannedWords = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsBannedWords" */
    "./Admin/Projects/Id/BannedWords"
  )
);

export const ParentDeliverablesNew = asyncComponent(() =>
  import(
    /* webpackChunkName: "ParentDeliverablesNew" */
    "./Admin/Projects/Id/ParentDeliverables/ParentDeliverablesNew"
  )
);
export const ParentDeliverablesEdit = asyncComponent(() =>
  import(
    /* webpackChunkName: "ParentDeliverablesEdit" */
    "./Admin/Projects/Id/ParentDeliverables/ParentDeliverablesEdit"
  )
);

export const ProjectsDefaultAssignees = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsRates" */
    "./Admin/Projects/Id/DefaultAssignees"
  )
);

export const ProjectsEdit = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsEdit" */
    "./Admin/Projects/Id/ProjectsEdit"
  )
);

export const ProjectsFields = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectsFields" */ "./Admin/Projects/Id/Fields")
);

export const ProjectsRatesV1 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsRatesV1" */
    "./Admin/Projects/Id/Rates/v1"
  )
);
export const ProjectsRatesV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsRatesV2" */
    "./Admin/Projects/Id/Rates/v2"
  )
);
export const ProjectsQuotesV1 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsQuotesV1" */
    "./Admin/Projects/Id/Quotes/v1"
  )
);
export const ProjectBatches = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectBatches" */
    "./Admin/Projects/Id/ProjectBatches"
  )
);
export const ProjectsExportFormat = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsExportFormat" */
    "./Admin/Projects/Id/ExportFormat"
  )
);

export const ProjectsShowLayout = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsShowLayout" */
    "./Admin/Projects/Id/Show/_layout"
  )
);
export const ProjectsShowBriefingV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsShowBriefingV2" */
    "./Admin/Projects/Id/Show/Briefing/v2"
  )
);

export const ProjectsShowInProductionV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsShowInProductionV2" */
    "./Admin/Projects/Id/Show/InProduction/v2"
  )
);

export const ProjectsShowCompletedV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsShowCompletedV2" */
    "./Admin/Projects/Id/Show/Completed/v2"
  )
);

export const ProjectsAssignmentsNew = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsAssignmentsNew" */
    "./Admin/Projects/Id/Assignments/AssignmentsNew"
  )
);

export const ProjectsAssignmentsList = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsAssignmentsList" */
    "./Admin/Projects/Id/Assignments/Id/AssignmentsList"
  )
);

export const ProjectsAssignmentsShowV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsAssignmentsShowV2" */
    "./Admin/Projects/Id/Assignments/Id/AssignmentsShow/v2"
  )
);

export const ProjectsBulkUploads = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsBulkUploads" */
    "./Admin/Projects/Id/BulkUploads"
  )
);

export const ProjectsWithClientV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsWithClientV2" */
    "./Admin/Projects/Id/Show/WithClient/v2"
  )
);

export const ProjectsClientBatchStage = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsClientBatchStage" */
    "./Admin/Projects/Id/Batches/Id/Stages/Id/List"
  )
);

export const ProjectsClientDeliverableV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ProjectsClientDeliverableV2" */
    "./Admin/Projects/Id/Batches/Id/Stages/Id/Deliverable/v2"
  )
);

export const AdminVersionHistory = asyncComponent(() =>
  import(
    /* webpackChunkName: "DeliverableVersionHistory" */
    "./Admin/Deliverable/Id/AdminVersionHistory"
  )
);

export const PeopleNew = asyncComponent(() =>
  import(/* webpackChunkName: "PeopleNew" */ "./Admin/People/PeopleNew")
);

export const PeopleListV2 = asyncComponent(() =>
  import(/* webpackChunkName: "PeopleListV2" */ "./Admin/People/PeopleList/v2")
);

export const PeopleShowV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "PeopleShowV2" */ "./Admin/People/Id/PeopleShow/v2"
  )
);

export const PeopleEdit = asyncComponent(() =>
  import(/* webpackChunkName: "PeopleEdit" */ "./Admin/People/Id/PeopleEdit")
);

/** PAYMENTS **/
export const PaymentList = asyncComponent(() =>
  import(/* webpackChunkName: "PaymentList" */ "./Admin/Payments/PaymentList")
);
export const PaymentsNew = asyncComponent(() =>
  import(/* webpackChunkName: "PaymentsNew" */ "./Admin/Payments/PaymentsNew")
);
export const PaymentsSearch = asyncComponent(() =>
  import(
    /* webpackChunkName: "PaymentsSearch" */ "./Admin/Payments/PaymentsSearch"
  )
);
export const PaymentsShow = asyncComponent(() =>
  import(
    /* webpackChunkName: "PaymentsShow" */ "./Admin/Payments/Id/PaymentsShow"
  )
);
export const PaymentsEdit = asyncComponent(() =>
  import(
    /* webpackChunkName: "PaymentsEdit" */ "./Admin/Payments/Id/PaymentsEdit"
  )
);
export const Search = asyncComponent(() =>
  import(/* webpackChunkName: "Search" */ "./Admin/Search")
);

export const AdminKeywordValidation = asyncComponent(
  import(
    /* webpackChunkName: "AdminKeywordValidation" */ "./Admin/KeywordValidation"
  )
);

export const AdminBulkQA = asyncComponent(
  import(/* webpackChunkName: "AdminKeywordValidation" */ "./Admin/BulkQA")
);

export const AdminGlossary = asyncComponent(
  import(/* webpackChunkName: "AdminKeywordValidation" */ "./Admin/Glossary")
);

// ========================================================
// Freelancer bundles
// ========================================================

export const FreelancerLayout = asyncComponent(() =>
  import(/* webpackChunkName: "FreelancerLayout" */ "./Freelancer/_layout")
);
export const FreelancerPaymentList = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerPaymentList" */ "./Freelancer/Payments/PaymentList"
  )
);
export const FreelancerAssignments = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerAssignments" */ "./Freelancer/Dashboard/Assignments/Id/List"
  )
);
export const FreelancerTasksV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerTasksV2" */ "./Freelancer/Dashboard/Assignments/Id/Tasks/Id/Show/v2"
  )
);
export const FreelancerDashboardLayout = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerDashboardLayout" */ "./Freelancer/Dashboard/_layout"
  )
);
export const FreelancerPaymentLayout = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerPaymentLayout" */ "./Freelancer/Payments/_layout"
  )
);
export const FreelancerDashboardInProgress = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerDashboardInProgress" */ "./Freelancer/Dashboard/InProgress"
  )
);
export const FreelancerDashboardApproved = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerDashboardApproved" */ "./Freelancer/Dashboard/Approved"
  )
);
export const FreelancerProfileShow = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerProfileShow" */ "./Freelancer/Profile/ProfileShow"
  )
);
export const FreelancerProfileEdit = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerProfileEdit" */ "./Freelancer/Profile/ProfileEdit"
  )
);
export const FreelancerProjects = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerProjects" */ "./Freelancer/Projects/Id/Show"
  )
);
export const FreelancerVersionHistory = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreelancerVersionHistory" */ "./Freelancer/Deliverable/Id/FreelancerVersionHistory"
  )
);
export const FreelancerKeywordValidation = asyncComponent(
  import(
    /* webpackChunkName: "FreelancerKeywordValidation" */ "./Freelancer/KeywordValidation"
  )
);
export const FreelancerBulkQA = asyncComponent(
  import(
    /* webpackChunkName: "FreelancerKeywordValidation" */ "./Freelancer/BulkQA"
  )
);

// ========================================================
// Client bundles
// ========================================================

export const ClientLayout = asyncComponent(() =>
  import(/* webpackChunkName: "ClientLayout" */ "./Client/_layout")
);

export const ClientAccounts = asyncComponent(() =>
  import(/* webpackChunkName: "ClientAccounts" */ "./Client/ClientAccounts")
);

export const ClientProjectDashboard = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientProjectDashboard" */
    "./Client/Project/Dashboard/_layout"
  )
);

export const ClientProjectDashboardInProgressV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientProjectDashboardInProgressV2" */
    "./Client/Project/Dashboard/InProgress/v2"
  )
);

export const ClientProjectDashboardCompletedV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientProjectDashboardCompletedV2" */
    "./Client/Project/Dashboard/Completed/v2"
  )
);

export const ClientProjectDashboardOverviewV1 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientProjectDashboardOverviewV1" */
    "./Client/Project/Dashboard/Overview/v1"
  )
);

export const ClientProjectDashboardOverviewV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientProjectDashboardOverviewV2" */
    "./Client/Project/Dashboard/Overview/v2"
  )
);

export const ClientBatchStage = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientBatchStage" */
    "./Client/Project/Batches/Id/Stages/Id/Show"
  )
);

export const ClientProjectInfo = asyncComponent(() =>
  import(/* webpackChunkName: "ClientProjectInfo" */ "./Client/ProjectInfo")
);

export const ClientDeliverableV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientDeliverableV2" */
    "./Client/Project/Batches/Id/Stages/Id/Deliverable/v2"
  )
);

export const ClientVersionHistory = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientDeliverableV2" */
    "./Client/ClientVersionHistory/DeliverableId"
  )
);

export const ClientSearch = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientSearch" */
    "./Client/Search"
  )
);

export const ClientParentDeliverables = asyncComponent(() =>
  import(
    /* webpackChunkName: "ClientParentDeliverables" */
    "./Client/Project/ParentDeliverables"
  )
);

// ========================================================
// Authentication bundles
// ========================================================

export const UnauthorizedLayout = asyncComponent(() =>
  import(/* webpackChunkName: "UnauthorizedLayout" */ "./Unauthorized/_layout")
);
export const Login = asyncComponent(() =>
  import(/* webpackChunkName: "Login" */ "./Unauthorized/Login")
);
export const Update = asyncComponent(() =>
  import(/* webpackChunkName: "Update" */ "./Unauthorized/Update")
);
export const Reset = asyncComponent(() =>
  import(/* webpackChunkName: "Reset" */ "./Unauthorized/Reset")
);
export const Contact = asyncComponent(() =>
  import(/* webpackChunkName: "Contact" */ "./Unauthorized/Contact")
);
