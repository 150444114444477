import { checkValidations, isNotBlank } from "../../../../../utils/validations";

const validate = (values) => {
  const errors = {};
  const projectNameErrors = checkValidations(values.projectName, [
    { validate: isNotBlank, msg: "This field is required" },
  ]);
  const orderFormIdErrors = checkValidations(values.orderFormId, [
    { validate: isNotBlank, msg: "This field is required" },
  ]);
  const contentTypeIdErrors = checkValidations(values.contentTypeId, [
    { validate: isNotBlank, msg: "This field is required" },
  ]);
  const workflowIdErrors = checkValidations(values.workflowId, [
    { validate: isNotBlank, msg: "This field is required" },
  ]);
  const projectLanguagesErrors = checkValidations(values.projectLanguages, [
    { validate: isNotBlank, msg: "This field is required" },
  ]);

  if (projectNameErrors) errors.projectName = projectNameErrors;
  if (contentTypeIdErrors) errors.contentTypeId = contentTypeIdErrors;
  if (orderFormIdErrors) errors.orderFormId = orderFormIdErrors;
  if (workflowIdErrors) errors.workflowId = workflowIdErrors;
  if (projectLanguagesErrors) errors.projectLanguages = projectLanguagesErrors;

  return errors;
};

export default validate;
