import { connect } from "react-redux";
import { hideModal } from "../../../modules/modal";
import Modal from "../components/Modal";

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
});

const mapStateToProps = ({ modal }, { type }) => {
  const display =
    typeof type === "undefined"
      ? modal.display
      : modal.display && type === modal.type;

  return {
    ...modal,
    display,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
