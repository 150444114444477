import { createSelector } from "reselect";
import { connect } from "react-redux";
import NativeLanguageSelector from "../components/NativeLanguageSelector";
import { peopleSelector } from "../../../utils/entitySelector";

const nativeLanguageSelector = createSelector(peopleSelector, (people) =>
  Object.keys(
    people.reduce((acc, { languages }) => {
      languages && languages.map((language) => (acc[language] = true));
      return acc;
    }, {})
  )
    .filter((item) => item !== undefined)
    .sort()
);

const mapStateToProps = (state) => {
  const filteredLanguages = nativeLanguageSelector(state);

  return {
    filteredLanguages,
  };
};

export default connect(mapStateToProps)(NativeLanguageSelector);
