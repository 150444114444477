import { connect } from "react-redux";
import TaskTM from "./TaskTM";
import { taskCommentGroupsSelectorFactory } from "../../../../modules/commentGroups";
import { createTranslation } from "../../../../modules/translations";

function mapDispatchToProps(dispatch) {
  return {
    createTranslation: (...args) => dispatch(createTranslation(...args)),
  };
}

const mapStateToPropsFactory = () => {
  const taskCommentGroupsSelector = taskCommentGroupsSelectorFactory();

  return function mapStateToProps(
    state,
    { deliverableId, stageId, taskFieldId }
  ) {
    const { maxWords, minWords, qualityCheck } = state.taskFields.entities[
      taskFieldId
    ];

    return {
      commentGroups: taskCommentGroupsSelector(
        state,
        deliverableId,
        stageId,
        taskFieldId
      ),
      qualityChecks: { maxWords, minWords, qualityCheck },
    };
  };
};

export default connect(mapStateToPropsFactory, mapDispatchToProps, null, {
  forwardRef: true,
})(TaskTM);
