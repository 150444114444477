import React, { useState } from "react";
import Helmet from "react-helmet";
import BulkQA from "../../../../components/BulkQA";
import PageHeader from "../../../../components/PageHeader";
import Errors from "../../../../components/Errors";
import ProjectInfo from "../../../../components/PageHeader/ProjectInfo";
import styles from "./BulkQA.module.scss";
import ToggleThrough from "../../../../components/ToggleThrough";
import FreelancerGuard, {
  Permissions,
} from "../../../../components/Permissions/Guards/FreelancerGuard";
import OverlayLoading from "../../../../components/OverlayLoading/v2";
import { Redirect } from "react-router";

type location = {
  pathname: string;
};

interface PropTypes {
  errors: {
    display: boolean;
  };
  listUrl: string;
  stage: {
    stageName: string;
  };
  history: object;
  project: {
    accountName: string;
    projectId: number;
    projectName: string;
  };
  tfcByDeliverable: any;
  match: {
    params: object;
  };
  location: location;
  dataReady: boolean;
  asyncFetched: boolean;
  assignmentGroupId: number;
}

function BulkQAFreelancer(props: PropTypes) {
  const {
    errors,
    tfcByDeliverable,
    listUrl,
    history,
    match,
    location,
    stage,
    project,
    dataReady,
    asyncFetched,
    assignmentGroupId,
  } = props;
  const [index, setIndex] = useState();
  const [words, setWords] = useState([]);
  const [toggled, setToggle] = useState();

  if (!dataReady && !asyncFetched) return <OverlayLoading />;

  const { accountName, projectId, projectName } = project;
  const { stageName } = stage;

  const currentPath = location.pathname;
  const updatedPath = `/freelancer/projects/${projectId}/assignments/${assignmentGroupId}/all-tasks`;

  const setWordMatches = (wordMatches, index, toggled) => {
    setWords(wordMatches);
    setIndex(index);
    setToggle(toggled);
  };

  if (currentPath !== updatedPath) {
    return <Redirect to={updatedPath} />;
  }

  return (
    <FreelancerGuard
      permission={Permissions.FREELANCER_BULK_QA}
      redirectTo={listUrl}
    >
      <>
        <Helmet>
          <title>Batch view</title>
        </Helmet>
        {errors && errors.display && <Errors />}

        <div className={styles.flex}>
          <PageHeader
            breadCrumbItems={[
              { url: "/freelancer", text: "Dashboard" },
              { text: `${stageName} | ${projectName} | ${accountName}` },
              { url: listUrl, text: "Assignment list" },
              { text: "Batch view" },
            ]}
            title="Batch view"
          >
            <div className={styles.headerRight}>
              <ProjectInfo projectId={projectId} />
              <ToggleThrough
                setWordMatches={setWordMatches}
                content={tfcByDeliverable}
              />
            </div>
          </PageHeader>
        </div>
        <BulkQA
          // @ts-ignore
          toggled={toggled}
          index={index}
          wordMatches={words}
          project={project}
          tfcByDeliverable={tfcByDeliverable}
          history={history}
          match={match}
          location={location}
          stage={stage}
        />
      </>
    </FreelancerGuard>
  );
}

export default BulkQAFreelancer;
