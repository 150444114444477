import { connect } from "react-redux";
import AttachmentsDisplay from "../components/AttachmentsDisplay";
import WithData from "../../../decorators/WithData";
import { archiveAttachment } from "../../../modules/attachments";

const mapDispatchToProps = (dispatch) => ({
  archiveAttachment: (attachmentId) =>
    dispatch(archiveAttachment(attachmentId)),
});

export default connect(null, mapDispatchToProps)(WithData(AttachmentsDisplay));
