import InProgress from "../components/InProgress";
import WithData from "../../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
});

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { projectId },
    },
  } = ownProps;
  const project = state.projects.entities[projectId];

  return {
    project,
    projectId,
    featureToggles: state.featureToggles,
  };
};

const ClientProjectDashboardInProgress = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(InProgress));
export default ClientProjectDashboardInProgress;
