import { formatDate } from "../../../../../utils/date";

export function generateRemittance(params, date) {
  const regex = new RegExp(/[^\d|\-|&]/, "g");
  const formattedDate = params.location.search.replace(regex, "");
  let [startDate, endDate] = formattedDate.split("&");

  // If a date has been passed in, then format it to YYYY:MM:DD format, and set
  // the end date as the last day of the target month
  if (date.startDate && date.endDate) {
    startDate = formatDate(date.startDate);
    endDate = formatDate(date.endDate);
  }

  const url = new URL(`${window.__API_GATEWAY__}/remittance`);
  params = { startDate, endDate };
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );

  return fetch(url, {
    credentials: "include",
  })
    .then((response) => {
      if (!response.ok) throw new Error();
      return response.blob();
    })
    .catch((err) => err);
}
