import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./PaymentsForm.module.scss";
import { reduxForm, formValueSelector } from "redux-form";
import {
  SelectWithErrors,
  TextAreaWithErrors,
} from "../../../../../../decorators/WithErrors";
import {
  QuillForm,
  QuillFormField,
} from "../../../../../../components/QuillForm";
import debounce from "lodash/debounce";
import { connect } from "react-redux";
import validate from "./validations";
import { rateParser } from "../../../utils/fieldParsers";
import CurrencyDisplay from "../../../../../../components/CurrencyDisplay";
import {
  DRAFT_PAYMENT_DATE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  PAYMENT_TYPE_REGULAR,
} from "../../../../../../modules/payments";

let PaymentsForm = class PaymentsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: "",
      currentNoteLength: 0,
      freelancerInput: "",
      orderFormId: "",
      projectId: "",
    };

    this.freelancerSearch = debounce((freelancerInput) => {
      this.setState({ freelancerInput });

      if (freelancerInput.length > 1) {
        if (props.searchFreelancers) {
          props.searchFreelancers(freelancerInput);
        }
      }
    }, 150);
  }

  createDropdown = ({
    fieldName,
    key,
    placeholder,
    options,
    onInputChange,
    onChange,
    onSelectResetsInput,
    required,
    multi,
  }) => (
    <div className={styles.formGroup}>
      <QuillFormField
        clearable={false}
        component={SelectWithErrors}
        disabled={this.props.submitting}
        labelText={fieldName}
        multi={multi}
        name={key}
        onChange={onChange}
        onInputChange={onInputChange}
        onSelectResetsInput={onSelectResetsInput}
        options={options}
        placeholder={placeholder}
        required={required}
      />
    </div>
  );

  /* Reset the state and redux-form values */
  resetOrderFormId = () => {
    this.props.change("orderFormId", null);
    this.setState({ orderFormId: "" });
  };
  resetProjectId = () => {
    this.props.change("projectId", null);
    this.setState({ projectId: "" });
  };
  resetFreelancerInput = () => {
    this.setState({ freelancerInput: "" });
  };

  /* Update state and redux-form values */
  updateAccount = (e, accountId) => {
    if (this.state.accountId !== accountId) {
      this.setState({ accountId });
      this.resetOrderFormId();
      this.resetProjectId();
      this.props.fetchOrderFormsByAccountId(accountId);
    }
  };
  updateOrderForm = (e, orderFormId) => {
    if (this.state.orderFormId !== orderFormId) {
      this.setState({ orderFormId });
      this.resetProjectId();
      this.props.fetchProjectsByOrderFormId(orderFormId);
    }
  };
  updateProject = (e, projectId) => this.setState({ projectId });

  render() {
    const MAX_NOTE_LENGTH = 100;
    const textStyle =
      this.state.currentNoteLength > MAX_NOTE_LENGTH
        ? styles.exceeded
        : styles.notExceeded;

    return (
      <div>
        <div className={styles.containerForm}>
          <QuillForm
            rebrandingContainer
            disabled={this.props.submitting || !this.props.valid}
            error={this.props.error}
            header="New payment"
            hideButtons
          >
            {/* eslint-disable max-len */}
            {this.createDropdown({
              fieldName: "Content creator",
              key: "peopleIds",
              placeholder: "Search...",
              options: this.props.freelancers,
              onInputChange: this.freelancerSearch,
              onChange: this.resetFreelancerInput,
              onSelectResetsInput: !this.state.freelancerInput,
              required: true,
              multi: true,
            })}
            {this.createDropdown({
              fieldName: "Account",
              key: "accountId",
              placeholder: "Search...",
              options: this.props.accounts,
              onInputChange: this.accountSearch,
              onChange: this.updateAccount,
              required: true,
            })}
            {this.createDropdown({
              fieldName: "Order Form",
              key: "orderFormId",
              placeholder: "Search...",
              options: this.props.orderForms,
              onChange: this.updateOrderForm,
              required: true,
            })}
            {this.createDropdown({
              fieldName: "Project",
              key: "projectId",
              placeholder: "Search...",
              options: this.props.projects,
              onChange: this.updateProject,
              required: true,
            })}
            {this.createDropdown({
              fieldName: "Task",
              key: "task",
              placeholder: "Search...",
              options: this.props.tasks,
              required: true,
            })}
            {/* eslint-enable max-len */}
            <div className={styles.smallNote}>
              If you need to pay the content creator for a task which is not
              currently on this list, please contact the tech team.
            </div>

            <div className={styles.formGroupFlex}>
              <QuillFormField
                labelText={"Payment Type"}
                options={PAYMENT_TYPE_OPTIONS}
                required={true}
                name={"paymentType"}
                component={SelectWithErrors}
                clearable={false}
                extraContainerStyles={styles.formGroupInput}
              />
              {this.props.values?.paymentType !== PAYMENT_TYPE_REGULAR && (
                <QuillFormField
                  labelText={"Payment Date"}
                  options={DRAFT_PAYMENT_DATE_OPTIONS}
                  required={true}
                  name={"draftPaymentDate"}
                  component={SelectWithErrors}
                  clearable={false}
                  extraContainerStyles={styles.formGroupInput}
                />
              )}
            </div>

            <CurrencyDisplay
              name="rate"
              isInputField={true}
              currencyCode={this.props.currencyCode}
              parsedRate={rateParser(this.props.prevRateValue)}
            />
            <QuillFormField
              component={TextAreaWithErrors}
              hideErrors
              labelText="Notes"
              name="notes"
              onChange={(e) => {
                this.setState({ currentNoteLength: e.target.value.length });
              }}
              type="textarea"
            />
            <div className={styles.noteInfo}>
              <div className={styles.smallNote}>
                Please be aware that these notes will be visible to the content
                creator being paid.
              </div>
              <div className={styles.filler} />
              <div className={styles.noteLength}>
                <span className={textStyle}>
                  {this.state.currentNoteLength}
                </span>{" "}
                / {MAX_NOTE_LENGTH}
              </div>
            </div>
          </QuillForm>
        </div>
      </div>
    );
  }
};

PaymentsForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.bool,
  fetchOrderFormsByAccountId: PropTypes.func.isRequired,
  fetchProjectsByOrderFormId: PropTypes.func.isRequired,
  freelancers: PropTypes.array.isRequired,
  orderForms: PropTypes.array.isRequired,
  prevRateValue: PropTypes.string,
  projects: PropTypes.array.isRequired,
  searchFreelancers: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  tasks: PropTypes.array.isRequired,
  valid: PropTypes.bool.isRequired,
};

formValueSelector("paymentsForm");
PaymentsForm = connect((state) => ({
  values: state.form?.paymentsForm?.values || {},
  prevRateValue:
    state.form.paymentsForm &&
    state.form.paymentsForm.values &&
    state.form.paymentsForm.values.rate,
}))(PaymentsForm);

PaymentsForm = reduxForm({
  form: "paymentsForm",
  validate,
  enableReinitialize: true,
  initialValues: {
    paymentType: PAYMENT_TYPE_REGULAR,
    draftPaymentDate: DRAFT_PAYMENT_DATE_OPTIONS[0].value,
  },
})(PaymentsForm);

export default PaymentsForm;
