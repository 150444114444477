import { connect } from "react-redux";
import Favourites from "../components/Favourites";
import WithData from "../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import {
  favouriteIdSelector,
  favouriteAccountSelector,
  favouriteOrderFormSelector,
  favouriteProjectSelector,
  favouriteParentAccountSelector,
} from "../../../../utils/entitySelector";
import { unfavourite } from "../../../../modules/favourites";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
    unfavourite: (data) => dispatch(unfavourite(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const parentAccounts = favouriteParentAccountSelector(state);
  const accounts = favouriteAccountSelector(state);
  const orderForms = favouriteOrderFormSelector(state);
  const projects = favouriteProjectSelector(state);

  // @ts-ignore
  const { parentAccountIds, orderFormIds, accountIds, projectIds } =
    favouriteIdSelector(state);
  return {
    parentAccounts,
    accounts,
    orderForms,
    projects,
    parentAccountIds,
    orderFormIds,
    accountIds,
    projectIds,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Favourites));
