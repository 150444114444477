import { getGraphQL } from "../../../../../../../../../utils/fetch";

import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../../../modules/parentAccounts";
import {
  accountsQuery,
  fetchAccountsSuccess,
} from "../../../../../../../../modules/accounts";
import {
  orderFormsQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../../modules/projects";
import {
  clientBatchDetailsQuery,
  fetchClientBatchDetailsSuccess,
} from "../../../../../../../../modules/clientBatchDetails";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
  multiSelectLanguageSuccess,
} from "../../../../../../../../modules/projectLanguages";
import {
  batchDeadlinesByProjectIdQuery,
  fetchBatchDeadlinesSuccess,
} from "../../../../../../../../modules/batchDeadlines";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../modules/batches";
import { getQueryValuesAsArray } from "../../../../../../../../utils/parseQuery";

export function getInitialData(params, url, cookies) {
  const { projectId } = params;

  return async (dispatch, getState) => {
    const { featureToggles } = getState();

    const initialQuery = `query ProjectShowWithClient ($projectId: String) {
      ${parentAccountsByProjectQuery}
      ${accountsQuery}
      ${orderFormsQuery}
      ${projectQuery}
      ${clientBatchDetailsQuery}
      ${projectLanguagesByProjectIdQuery}
      ${
        !featureToggles.QCC_1728_batchDetails
          ? batchDeadlinesByProjectIdQuery
          : ""
      }
      ${batchesByProjectIdQuery}
    }`;

    const json = await getGraphQL(initialQuery, { projectId }, url, cookies);

    const languageCode = getQueryValuesAsArray("languageCode");

    const selectedLanguages =
      languageCode?.length > 0
        ? languageCode
        : json.projectLanguages.map((item) => item.languageCode);

    dispatch(fetchParentAccountsSuccess(json.parentAccounts));
    dispatch(fetchAccountsSuccess(json.accounts));
    dispatch(fetchOrderFormsSuccess(json.orderForms));
    dispatch(fetchProjectSuccess(json.project));
    dispatch(
      fetchClientBatchDetailsSuccess({
        data: json.clientBatchDetails,
        projectId,
      })
    );
    dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
    dispatch(fetchBatchesSuccess(json.batches));
    dispatch(multiSelectLanguageSuccess(selectedLanguages));

    if (!featureToggles.QCC_1728_batchDetails) {
      dispatch(fetchBatchDeadlinesSuccess(json.batchDeadlines));
    }
  };
}
