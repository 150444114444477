import { EditorState, RichUtils, Modifier } from "draft-js";
import ColorPickerDropdown from "./ColorPickerDropdown";

const colors = [
  { hex: "#FFFFFF", rgb: "rgb(255, 255, 255)" },
  { hex: "#000000", rgb: "rgb(0, 0, 0)" },
  { hex: "#FF0000", rgb: "rgb(255, 0, 0)" },
  { hex: "#FFFF00", rgb: "rgb(255, 255, 0)" },
  { hex: "#008000", rgb: "rgb(0, 128, 0)" },
  { hex: "#0000FF", rgb: "rgb(0, 0, 255)" },
  { hex: "#FF00FF", rgb: "rgb(255, 0, 255)" },
];

const ColorPickerButton = ({ editorState, updateEditorState }) => {
  const applyColor = (color) => {
    const selection = editorState.getSelection();

    const nextContentState = colors.reduce((contentState, { hex }) => {
      return Modifier.removeInlineStyle(
        contentState,
        selection,
        `COLOR_${hex}`
      );
    }, editorState.getCurrentContent());

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );

    const currentStyle = editorState.getCurrentInlineStyle();

    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(`COLOR_${color}`)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        `COLOR_${color}`
      );
    }

    updateEditorState(nextEditorState);
  };

  return (
    <ColorPickerDropdown
      editorState={editorState}
      onColorSelect={(color) => applyColor(color)}
    />
  );
};

export default ColorPickerButton;
