import { connect } from "react-redux";
import Layout from "../components/Layout";
import { generateRemittance } from "../../PaymentList/modules/generateRemittance";
import { showModal, hideModal } from "../../../../../modules/modal";
import { showErrors } from "../../../../../modules/errors";
import { unarchivedPaymentsSelector } from "../../../../../utils/entitySelector";
import { fetchPayments } from "../../../../../modules/payments";

const mapDispatchToProps = (dispatch, ownProps) => ({
  generateRemittance: (date) => generateRemittance(ownProps, date),
  fetchPayments: (ownProps) => dispatch(fetchPayments(ownProps)),
  showErrors: (errors) => dispatch(showErrors(errors)),
  showModal: () => dispatch(showModal()),
  hideModal: () => dispatch(hideModal()),
});

const mapStateToProps = (state, ownProps) => {
  const payments = unarchivedPaymentsSelector(state);

  const { errors, modal } = state;
  return { errors, modal, payments };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
