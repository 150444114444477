import styles from "./Table.module.scss";

const Table = ({ data, headers, download }) => {
  const handleDownload = async (data) => {
    await download(data);
  };

  return (
    <div className={styles.table}>
      <div className={styles.tableHeader}>
        {headers.map((header, index) => (
          <div key={index} className={styles.tableCell}>
            {header.label}
          </div>
        ))}
      </div>
      <div className={styles.tableBody}>
        {data.map((row, rowIndex) => (
          <div key={rowIndex} className={styles.tableRow}>
            {headers.map((header, cellIndex) => (
              <div key={cellIndex} className={styles.tableCell}>
                {header.key !== "Actions" ? (
                  row[header.key]
                ) : (
                  <button
                    onClick={() =>
                      handleDownload({
                        jobId: row["jobId"],
                        fileName: row["fileName"],
                      })
                    }
                    className={styles.downloadButton}
                  >
                    Download
                  </button>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
