import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getGraphQL } from "../../../utils/fetch";
import PageHeader from "../PageHeader";
import styles from "./index.module.scss";
import KeywordResults from "./KeywordResults";

const KEY_CODE_ENTER = 13;
const DEFAULT_DATABASE_CODE = "uk";
const KEYWORD_INPUT_NAME = "keyword";

const initialQuery = `query KeywordValidationTool_initialData {
  semrushLanguages {
    databaseCode
    databaseRegion
  }
}`;

const fetchKeywordsQuery = `query getKeywords ($searchTerm: String, $personId: ID, $databaseCode: String) {
  semrush (searchTerm: $searchTerm, personId: $personId, databaseCode: $databaseCode) {
    keyword
    searchVolume
  }
}`;

const KeywordValidationTool = () => {
  const [databaseCode, setDatabaseCode] = useState(DEFAULT_DATABASE_CODE);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState();
  const [results, setResults] = useState();
  const [databaseRegions, setDatabaseRegions] = useState([]);
  const personId = useSelector((state) => state.me);

  const searchEnabled = keyword.length !== 0;

  useEffect(() => {
    getGraphQL(initialQuery).then(({ semrushLanguages }) => {
      const regions = semrushLanguages.map((lang) => ({
        value: lang.databaseCode,
        label: lang.databaseRegion,
      }));
      setDatabaseRegions(regions);
    });
  }, [setDatabaseRegions]);

  const onChange = (e) => {
    if (e === null) return;

    if (e.target && e.target.name === KEYWORD_INPUT_NAME) {
      // native input has event.target.value
      setKeyword(e.target.value);
    } else {
      // react select uses event.value
      setDatabaseCode(e.value);
    }
  };

  const onKeyPress = (e) => {
    if (e.charCode === KEY_CODE_ENTER) {
      searchKeywords();
    }
  };

  const searchKeywords = () => {
    if (searchEnabled) {
      setLoading(true);

      getGraphQL(fetchKeywordsQuery, {
        searchTerm: keyword,
        personId,
        databaseCode,
      })
        .then(({ semrush }) => {
          setResults(semrush);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const searchIconStyles = classNames({
    [styles.search]: true,
    [styles.enabled]: searchEnabled,
  });

  return (
    <div>
      <PageHeader title="Keyword Validation Tool" />
      <div className={styles.controls}>
        <Select
          clearable={false}
          className={"md w-340"}
          labelText="Archived Status"
          placeholder={"select database"}
          options={databaseRegions}
          value={databaseCode}
          onChange={onChange}
        />
        <input
          name={KEYWORD_INPUT_NAME}
          className={styles.searchInput}
          placeholder="keyword"
          onKeyPress={onKeyPress}
          onChange={onChange}
        />
        <div className={searchIconStyles} onClick={searchKeywords}>
          <svg
            className={styles.searchIcon}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
          >
            {/* eslint-disable max-len */}
            <path d="M15.504 13.616l-3.79-3.223c-0.392-0.353-0.811-0.514-1.149-0.499 0.895-1.048 1.435-2.407 1.435-3.893 0-3.314-2.686-6-6-6s-6 2.686-6 6 2.686 6 6 6c1.486 0 2.845-0.54 3.893-1.435-0.016 0.338 0.146 0.757 0.499 1.149l3.223 3.79c0.552 0.613 1.453 0.665 2.003 0.115s0.498-1.452-0.115-2.003zM6 10c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
            {/* eslint-enable max-len */}
          </svg>
        </div>
      </div>

      <KeywordResults results={results} loading={loading} keyword={keyword} />
    </div>
  );
};

export default KeywordValidationTool;
