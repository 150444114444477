import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./BriefingFieldRow.module.scss";
import classNames from "classnames";
import removeHTML from "../../../../utils/removeHTML";
import { rowGridStyles } from "../../utils/gridHelpers";
import { Link } from "react-router-dom";
import BriefingFieldRowCell from "../../../BriefingFieldRowCell/components/BriefingFieldRowCell";

class BriefingFieldRow extends Component {
  toggleItem = () => {
    this.props.toggleItem(this.props.id);
  };

  render() {
    const {
      actionable,
      archived,
      baseUrl,
      briefingFieldValues,
      checked,
      deliverableId,
      id,
      previousAssignee,
      rate,
      selectable,
      status,
      wasModified,
      wasAmended,
    } = this.props;

    let statusText = status;

    const isArchivedAndApproved = archived && status && status === "Approved";

    if (status === "Accepted" && wasAmended) {
      statusText = "Accepted (Amended)";
    }

    const rowStyles = classNames({
      [styles.rowContainer]: true,
      [styles.isArchivedAndApproved]: isArchivedAndApproved,
      [styles.checked]: checked,
      [styles.selectable]: selectable,
      [styles.notActionable]: !actionable,
      [styles.modifiedByClient]: wasModified,
    });
    const gridStyles = rowGridStyles(briefingFieldValues.length, {
      includePreviousAssignee: !!previousAssignee,
      includeRates: !!rate,
      includeStatus: !!status,
      includeDeliverableId: !!deliverableId,
    });

    const checkboxCellStyles = classNames({
      [styles.cell]: true,
      [styles.centered]: true,
      [styles.hidden]: !selectable,
    });
    const labelStyle = classNames({
      [styles.neutralLabel]: true,
      [styles.acceptedLabel]:
        statusText === "Accepted" ||
        statusText === "Accepted (Amended)" ||
        statusText === "Amends requested",
      [styles.acceptedNotActionableLabel]:
        statusText === "Accepted" && !actionable,
      [styles.notAcceptedLabel]: statusText === "Not accepted",
      [styles.notActionableLabel]: statusText === "Not Actioned",
    });

    const checkboxInput = selectable ? (
      <div className={checkboxCellStyles}>
        <div className={styles.checkboxContainer}>
          <input
            checked={checked}
            id={id}
            onChange={this.toggleItem}
            onClick={(e) => e.stopPropagation()}
            type="checkbox"
          />
          <label
            className={styles.label}
            htmlFor={id}
            onClick={(e) => e.stopPropagation()}
          >
            <span className="hoverShadow"></span>
          </label>
        </div>
      </div>
    ) : (
      <div className={checkboxCellStyles} />
    );

    const rowContent = (
      <div
        title={wasModified ? "Changes were made by client" : ""}
        className={rowStyles}
        style={gridStyles}
      >
        {checkboxInput}
        {deliverableId && (
          <BriefingFieldRowCell
            text={deliverableId}
            cellStyle={styles.cell}
            expandingStyle={styles.expanding}
            insideStyle={styles.inside}
          />
        )}
        {previousAssignee && (
          <BriefingFieldRowCell
            text={previousAssignee}
            expandingCell={true}
            cellStyle={styles.cell}
            expandingStyle={styles.expanding}
            insideStyle={styles.inside}
          />
        )}
        {rate && (
          <BriefingFieldRowCell
            text={rate}
            isRate
            cellStyle={styles.cell}
            expandingStyle={styles.expanding}
            insideStyle={styles.inside}
          />
        )}
        {status && (
          <BriefingFieldRowCell
            text={statusText}
            expandingCell={false}
            cellStyle={styles.cell}
            labelStyle={labelStyle}
          />
        )}

        {briefingFieldValues.map((fieldValue, idx) => (
          <div key={idx} className={styles.cell}>
            <div className={styles.inside}>{removeHTML(fieldValue)}</div>
          </div>
        ))}
      </div>
    );

    // if the row is archived but approved we still show the row but omit the link
    if (isArchivedAndApproved) {
      return <div className={styles.row}>{rowContent}</div>;
    }

    // default with link
    return (
      <div className={styles.row}>
        <Link to={`${baseUrl}/${id}`}>{rowContent}</Link>
      </div>
    );
  }
}

BriefingFieldRow.propTypes = {
  actionable: PropTypes.bool,
  archived: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  briefingFieldValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  checked: PropTypes.bool,
  deliverableId: PropTypes.number,
  id: PropTypes.number.isRequired, // either a deliverableId or an assignmentId
  previousAssignee: PropTypes.string,
  rate: PropTypes.object,
  selectable: PropTypes.bool,
  status: PropTypes.string,
  toggleItem: PropTypes.func.isRequired,
  wasAmended: PropTypes.bool,
};

export default BriefingFieldRow;
