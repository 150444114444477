import { getGraphQL } from "../../../../../utils/fetch";
import { fetchParentAccountRelationshipsQuery } from "../../../../modules/parentAccounts";
import { fetchAccountsSuccess } from "../../../../modules/accounts";
import { fetchOrderFormsSuccess } from "../../../../modules/orderForms";
import { fetchProjectsSuccess } from "../../../../modules/projects";

export function getAsyncData(_, url, cookies) {
  return async (dispatch, getState) => {
    const {
      parentAccounts: { result: parentAccountId },
    } = getState();
    const params = { parentAccountId }; // Represents multiple parentAccountIds

    const json = await getGraphQL(
      fetchParentAccountRelationshipsQuery,
      params,
      url,
      cookies
    );
    dispatch(fetchAccountsSuccess(json.accounts));
    dispatch(fetchOrderFormsSuccess(json.orderForms));
    dispatch(fetchProjectsSuccess(json.projects));
  };
}
