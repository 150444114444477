import ProjectsInfo from "../components/ProjectsInfo";
import WithData from "../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import {
  projectBannedWordsSelector,
  projectLanguagesSelector,
} from "../../../../../../utils/entitySelector";
import { createSelector } from "reselect";

const orderByLanguage = createSelector(
  (entity, property) => entity,
  (entity, property) => property,
  (entity, property) => {
    const tree = {};
    entity.forEach((e) => {
      if (!tree[e.languageCode]) tree[e.languageCode] = [];
      tree[e.languageCode].push(e[property]);
    });
    return tree;
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { projectId },
    },
  } = ownProps;
  const project = state.projects.entities[projectId] || {};
  const workflow = state.workflows.entities[project.workflowId] || {};
  const projectLanguages = projectLanguagesSelector(state, Number(projectId));
  const bannedWords = orderByLanguage(
    projectBannedWordsSelector(state, Number(projectId)),
    "word"
  );
  const { accountId, orderFormNumber } =
    state.orderForms.entities[project.orderFormId] || {};
  const contentTypeName = project
    ? state.contentTypes.entities[project.contentTypeId].contentTypeName
    : null;

  const { accountName, parentAccountId } =
    state.accounts.entities[accountId] || {};
  const { parentAccountName } =
    state.parentAccounts.entities[parentAccountId] || {};

  return {
    accountName,
    parentAccountName,
    project,
    workflow,
    projectLanguages,
    bannedWords,
    orderFormNumber,
    contentTypeName,
  };
};

const InHouseProjectsInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ProjectsInfo));
InHouseProjectsInfo.getInitialData = getInitialData;
export default InHouseProjectsInfo;
