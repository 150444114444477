import BulkUploads from "../components/BulkUploads";
import WithData from "../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import { processFileHandler } from "../modules/fileHandler";
import { generateDownload } from "../../../../../../../utils/files";
import {
  clientDeadlineStagesSelector,
  projectBriefingFieldsSelector,
  projectSourceFieldsSelector,
  projectKeywordGroupsSelector,
  batchesDropdownSelector,
  projectLanguagesSelector,
  primaryStageSelector,
  rateBandsByProjectIdSelector,
} from "../../../../../../utils/entitySelector";
import { showModal, hideModal } from "../../../../../../modules/modal";
import parseQuery from "../../../../../../utils/parseQuery";

export const downloadCSVTemplateHandler = (
  filename,
  briefingFields,
  sourceFields,
  keywordGroups,
  rateBands,
  featureToggles,
  e
) => {
  if (e) e.preventDefault();
  const briefingFieldNames = briefingFields.map((b) => b.briefingFieldName);
  const sourceFieldNames = sourceFields.map(
    ({ taskFieldName }) => `${taskFieldName} (source)`
  );
  const keywordGroupNames = keywordGroups.map((k) => k.keywordGroupName);

  let data = "";
  const delimiter = ",";
  const columns = [
    ...briefingFieldNames,
    ...sourceFieldNames,
    ...keywordGroupNames,
  ];

  if (
    featureToggles &&
    featureToggles.QCC_1081_rateBands &&
    rateBands.length > 1
  ) {
    columns.push("Band");
  }

  columns.forEach((c, idx) => {
    data += idx + 1 === columns.length ? `"${c}"\n` : `"${c}"${delimiter}`;
  });

  const csvContent = `data:text/csv;charset=utf-8,${data}`;
  const encodedUri = encodeURI(csvContent);
  generateDownload(`${filename}-template.csv`, encodedUri);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    processFile: (data, featureToggles) =>
      dispatch(processFileHandler(data, featureToggles)),
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
  };
};

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);
  const project = state.projects.entities[projectId] || {};
  const { projectName, workflowId } = project;
  const projectLanguages = projectLanguagesSelector(state, project.projectId);
  const primaryStageId = primaryStageSelector(state, workflowId);
  const batches = batchesDropdownSelector(state, projectId);
  const briefingFields = projectBriefingFieldsSelector(state, projectId);
  const sourceFields = projectSourceFieldsSelector(state, projectId);
  const rateBands = rateBandsByProjectIdSelector(state, projectId);
  const keywordGroups = projectKeywordGroupsSelector(state, projectId);
  const downloadCSVTemplate = downloadCSVTemplateHandler.bind(
    this,
    projectName,
    briefingFields,
    sourceFields,
    keywordGroups,
    rateBands,
    state.featureToggles
  );

  const orderForm = state.orderForms.entities[project.orderFormId] || {};
  const account = state.accounts.entities[orderForm.accountId] || {};
  const parentAccount =
    state.parentAccounts.entities[account.parentAccountId] || {};

  const languagesField =
    state.form.bulkUploadsForm && state.form.bulkUploadsForm.values;

  const clientDeadlineStages = clientDeadlineStagesSelector(state, workflowId);
  const { workflowType } = state.workflows.entities[workflowId] || {};
  const isLocalisation = workflowType === "Localisation";

  const batchId = parseQuery(ownProps.location.search, "batchId", Number);

  const languagesEntities = state.languages.entities;
  const projectLanguagesNames = [];
  const languages = [];

  for (const { languageCode } of projectLanguages) {
    projectLanguagesNames.push({
      value: languageCode,
      label: `${
        languagesEntities[languageCode]?.languageName || languageCode
      }: ${languageCode}`,
    });
    languages.push(languageCode);
  }

  const initialValues = { batchId, languages };

  return {
    account,
    batches,
    briefingFields,
    clientDeadlineStages,
    downloadCSVTemplate,
    isLocalisation,
    initialValues,
    keywordGroups,
    languagesField,
    orderForm,
    parentAccount,
    primaryStageId,
    project,
    projectId,
    projectLanguages,
    projectLanguagesNames,
    rateBands,
    sourceFields,
    featureToggles: state.featureToggles,
    modal: state.modal,
  };
};

const ProjectShowBriefing = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(BulkUploads));
ProjectShowBriefing.getInitialData = getInitialData;
export default ProjectShowBriefing;
