import { getDeliverableIdsStr } from "../notifications/utils";

/**
 * Take a transition name and return it in a human readable format
 *
 * @param {string} transitionName
 * @returns {string} human readable name of transition
 */
function getTransitionStr(transitionName) {
  const isSubmitTransition = /submit/i.exec(transitionName);
  const isRequestAmendsTransition = /amends/i.exec(transitionName);

  if (isSubmitTransition) {
    return "submitted";
  } else if (isRequestAmendsTransition) {
    return "requested for amends";
  }

  return "transitioned";
}

export function createTransitionSuccessMessage(deliverableIds, transitionName) {
  const transitionString = getTransitionStr(transitionName);
  const deliverablesString = getDeliverableIdsStr(deliverableIds);

  return `${deliverablesString} ${transitionString}`;
}

export function createTransitionWarningMessage(count, total, transitionName) {
  const transitionString = getTransitionStr(transitionName);

  return `Only ${count}/${total} deliverables were ${transitionString}`;
}
