import { getGraphQL } from "../../../../../../../../../../utils/fetch";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../../../../modules/projectLanguages";
import {
  peopleByProjectAssignmentsQuery,
  peopleByDefaultAssigneeQuery,
  fetchPeopleSuccess,
} from "../../../../../../../../../modules/people";
import {
  defaultAssigneesByProjectIdQuery,
  fetchDefaultAssigneesSuccess,
} from "../../../../../../../../../modules/defaultAssignees";
import {
  ratesByProjectIdQuery,
  fetchRatesSuccess,
} from "../../../../../../../../../modules/rates";
import {
  assignmentsByAssignmentGroupQuery,
  fetchAssignmentsSuccess,
} from "../../../../../../../../../modules/assignments";

export const asyncQuery = `query ReassignmentForm ($projectId: String, $assignmentGroupId: String, $includeArchivedAssignments: Boolean) {
  ${projectQuery}
  ${peopleByProjectAssignmentsQuery}
  ${peopleByDefaultAssigneeQuery}
  ${projectLanguagesByProjectIdQuery}
  ${defaultAssigneesByProjectIdQuery}
  ${ratesByProjectIdQuery}
  ${assignmentsByAssignmentGroupQuery}
}`;

export function getAsyncData(props) {
  const params = {
    projectId: props.project.projectId,
    assignmentGroupId: props.assignmentGroup.assignmentGroupId,
  };

  return async (dispatch) => {
    const json = await getGraphQL(asyncQuery, params);

    dispatch(fetchProjectSuccess(json.project));
    dispatch(fetchPeopleSuccess(json.peopleByAssignment));
    dispatch(fetchPeopleSuccess(json.peopleByDefaultAssignee));
    dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
    dispatch(fetchAssignmentsSuccess(json.assignments));
    dispatch(fetchDefaultAssigneesSuccess(json.defaultAssignees));
    dispatch(fetchRatesSuccess(json.rates));
  };
}
