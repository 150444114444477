import { connect } from "react-redux";
import { createSelector } from "reselect";
import ExportFormat from "../components/ExportFormat";
import WithData from "../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { updateExportFormat } from "../../../../../../modules/exportFormats";
import {
  accountById,
  isProjectLocalisation,
  orderFormById,
  parentAccountById,
  projectById,
  projectTaskFieldsSelector,
  projectBriefingFieldsSelector,
  exportFormatById,
} from "../../../../../../utils/entitySelector";

const initialValuesSelector = createSelector(
  exportFormatById,
  projectTaskFieldsSelector,
  projectBriefingFieldsSelector,
  (exportFormat, taskFields, briefingFieldList) => {
    const {
      docExportName,
      docExportTaskFieldId,
      docExportBriefingFields,
      docExportBriefingFieldId,
    } = exportFormat;

    let exportNameConfiguration = null;

    if (docExportName === "DELIVERABLE_ID") {
      exportNameConfiguration = "DELIVERABLE_ID";
    } else {
      const tf = taskFields.find(
        (item) => item.taskFieldId === docExportTaskFieldId
      );
      if (tf) {
        exportNameConfiguration = tf.taskFieldId;
      } else {
        const bf = briefingFieldList.find(
          (item) => item.briefingFieldId === docExportBriefingFieldId
        );
        exportNameConfiguration = bf?.briefingFieldId || null;
      }
    }

    return {
      ...exportFormat,
      exportNameConfiguration,
      docExportBriefingFields: docExportBriefingFields || "REMOVE",
    };
  }
);

const mapDispatchToProps = (dispatch) => ({
  getInitialData: (params) => dispatch(getInitialData(params)),
  updateExportFormat: (values, history) =>
    dispatch(updateExportFormat(values, history)),
});

const mapStateToProps = (state, ownProps) => {
  const {
    history,
    match: { params },
  } = ownProps;
  const projectId = Number(params.projectId);

  const project = projectById(state, projectId);
  const orderForm = orderFormById(state, project.orderFormId);
  const account = accountById(state, orderForm.accountId);
  const parentAccount = parentAccountById(state, account.parentAccountId);
  const isLocalisationProject = isProjectLocalisation(state, projectId);
  const initialValues = initialValuesSelector(state, projectId);
  const taskFields = projectTaskFieldsSelector(state, projectId);
  const briefingFieldList = projectBriefingFieldsSelector(state, projectId);

  return {
    account,
    isLocalisationProject,
    initialValues,
    history,
    orderForm,
    params,
    parentAccount,
    project,
    taskFields,
    briefingFieldList,
  };
};

const ConnectedExportFormat = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ExportFormat));
export default ConnectedExportFormat;
