import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./TextArea.module.scss";
import classNames from "classnames";

class TextArea extends Component {
  focus() {
    this.refs.textAreaRef.focus();
  }

  render() {
    /* eslint-disable no-unused-vars */
    const { input, type, meta, styles: propStyles, ...rest } = this.props;
    /* eslint-enable no-unused-vars */

    const textAreaStyle = classNames({
      [styles.formCheckInput]: type === "checkbox",
      [styles.formControl]: type !== "checkbox",
      [styles.border]: type !== "checkbox",
      [propStyles]: type !== "checkbox" && propStyles,
    });

    return (
      <textarea
        ref="textAreaRef"
        {...rest}
        {...input}
        type={type}
        className={textAreaStyle}
      />
    );
  }
}

TextArea.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
  }).isRequired,
  styles: PropTypes.string,
  type: PropTypes.string,
};

export default TextArea;
