import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import Helmet from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import { CREATE_TOMMY_OUTPUT_FILES_GQL } from "./mutations";
import styles from "./TommyOutputs.module.scss";
import TommyOutputsForm from "./TommyOutputsForm";

const TommyOutputs = () => {
  const [createTommyOutputFiles] = useMutation(CREATE_TOMMY_OUTPUT_FILES_GQL);
  const [fileId, setFileId] = useState(null);
  const [error, setError] = useState<any>(null);

  const onSubmit = async (values) => {
    setFileId(null);
    setError(null);
    const { batchIds } = values;

    try {
      const { data } = await createTommyOutputFiles({
        variables: { batchIds },
      });

      const {
        createTommyOutputFiles: { fileId: responseFileId },
      } = data;

      if (responseFileId) {
        setFileId(responseFileId);
      }
    } catch (err: any) {
      if (err.graphQLErrors) {
        setError(err.graphQLErrors[0].message);
      }
    }
  };

  const fileURL = fileId ? `https://drive.google.com/file/d/${fileId}` : "";

  return (
    <div>
      <Helmet>
        <title>Tommy output files</title>
      </Helmet>
      <PageHeader
        breadCrumbItems={[
          { url: "/admin", text: "Admin" },
          { text: "Tommy output files" },
        ]}
        title={"Tommy output file creation"}
      />
      <div className={styles.container}>
        <div className={styles.info}>
          <div>
            <span className={styles.required}>Required</span> task field names:
            "<span className={styles.required}>New product name</span>", "
            <span className={styles.required}>Description</span>" and "
            <span className={styles.required}>Bullets</span>"{" "}
          </div>
          <div>
            <span className={styles.required}>Required</span> briefing field
            names: "<span className={styles.required}>Style number</span>"
          </div>
        </div>

        <div className={styles.fields}>
          <TommyOutputsForm onSubmit={onSubmit} />
        </div>

        {fileId && (
          <div className={styles.infoBox}>
            <div className={styles.fileLocation}>
              Google Drive file (opens in a new tab)
            </div>
            <a
              href={fileURL}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.fileId}
            >
              {fileURL}
            </a>
          </div>
        )}

        {error && (
          <div className={styles.infoBox}>
            <div>
              An error occured (if this keeps persisting, please contact the
              tech team)
            </div>
            <div>
              <span className={styles.required}>error</span>:{" "}
              <span className={styles.error}>
                {JSON.stringify(error, null, 2)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default {
  Component: TommyOutputs,
};
