import { connect } from "react-redux";
import PaymentList from "../components/PaymentList";
import WithData from "../../../../../decorators/WithData";
import { unarchivedPaymentsSelector } from "../../../../../utils/entitySelector";

const mapStateToProps = (state) => {
  const payments = unarchivedPaymentsSelector(state);

  return {
    payments,
    featureToggles: state.featureToggles,
  };
};

const PaymentListWithData = connect(
  mapStateToProps,
  null
)(WithData(PaymentList));
export default PaymentListWithData;
