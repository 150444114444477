import { getGraphQL } from "../../../../utils/fetch";
import { fetchFreelancerDashboardSuccess } from "../../../modules/freelancerDashboard";

export const freelancerDashboardQuery = `
  freelancerDashboard (personId: $personId, includeArchivedAssignments: $includeArchivedAssignments) {
    accountName, projectName, batchName, stageName, assignmentStageId, rate,
    deadline, inTraining, assignmentGroupId, status, deliverableStageId, count,
    projectId, currencyCode, hasRequestPending
  }
`;

export const initialQuery = `query FreelancerDashboardInProgressData ($personId: String, $includeArchivedAssignments: Boolean) {
  ${freelancerDashboardQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchFreelancerDashboardSuccess(json.freelancerDashboard));
    });
  };
}
