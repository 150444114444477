import React from "react";
import PropTypes from "prop-types";

function syncComponent(chunkName, mod) {
  const Component = mod.default ? mod.default.Component : mod.Component; // es6 module compat
  const reducers = mod.default ? mod.default.reducers : mod.reducers; // es6 module compat

  function SyncComponent(props) {
    if (props.staticContext && props.staticContext.splitPoints) {
      props.staticContext.splitPoints.push(chunkName);
    }

    return <Component {...props} />;
  }

  SyncComponent.getInitialData = Component.getInitialData;
  SyncComponent.reducers = reducers;

  SyncComponent.propTypes = {
    staticContext: PropTypes.object,
  };

  return SyncComponent;
}

export default syncComponent;
