import { connect } from "react-redux";

import {
  projectLanguagesSelector,
  chargeableProjectStagesSelector,
  rateBandsByProjectIdSelector,
  rateBandItemsSelector,
} from "../../../../../../../utils/entitySelector";
import { createSelector } from "reselect";
import Rates from "../components/Rates";
import WithData from "../../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { showModal, hideModal } from "../../../../../../../modules/modal";

import { updateRateBandItems } from "../../../../../../../modules/rateBandItems";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    submitRateBandItems: (data) =>
      dispatch(
        updateRateBandItems(
          data,
          ownProps.history,
          ownProps.match.params,
          ownProps.location
        )
      ),
  };
};

const initialValuesSelector = createSelector(
  (rateBands, stages, projectLanguages) => rateBands,
  (rateBands, stages, projectLanguages) => stages,
  (rateBands, stages, projectLanguages) => projectLanguages,
  (rateBands, stages, projectLanguages, rateBandItems) => rateBandItems,
  (rateBands, stages, projectLanguages, rateBandItems, rateBandVolumes) =>
    rateBandVolumes,
  (rateBands, stages, projectLanguages, rateBandItems, rateBandVolumes) => {
    // redux form needs keys to start with strings, so it couldn't be the rateBandId
    const tree = { rateBandItems: {}, volumes: {} };
    rateBands.forEach(({ rateBandId, rate }) => {
      if (!tree["rateBandItems"][rateBandId])
        tree["rateBandItems"][rateBandId] = {};
      tree["volumes"][rateBandId] = {};

      stages.forEach(({ stageId }) => {
        if (!tree["rateBandItems"][rateBandId][stageId])
          tree["rateBandItems"][rateBandId][stageId] = {};

        projectLanguages.forEach(({ languageCode }) => {
          const volume =
            (rateBandVolumes[rateBandId] &&
              rateBandVolumes[rateBandId][languageCode]) ||
            0;

          if (!tree["volumes"][rateBandId][languageCode]) {
            tree["volumes"][rateBandId][languageCode] = volume;
          }

          const r =
            (rateBandItems[rateBandId] &&
              rateBandItems[rateBandId][stageId] &&
              rateBandItems[rateBandId][stageId][languageCode]) ||
            0;
          if (!tree["rateBandItems"][rateBandId][stageId][languageCode])
            tree["rateBandItems"][rateBandId][stageId][languageCode] = r;
        });
      });
    });

    return tree;
  }
);

const projectLanguagesWithNameSelector = createSelector(
  projectLanguagesSelector,
  (state, projectId) => state.languages.entities,
  (projectLanguages, languageEntities) => {
    return projectLanguages.map((language) => {
      const { languageCode } = language;
      const languageName =
        (languageEntities[languageCode] &&
          languageEntities[languageCode].languageName) ||
        "";
      return { ...language, languageName };
    });
  }
);

export const rateBandVolumesSelector = createSelector(
  (state) => state.rateBandVolumes,
  (state, rateBands) => rateBands,
  (rateBandVolumes, rateBands) => {
    const rateBandIds = rateBands.map(({ rateBandId }) => rateBandId);

    return rateBandVolumes
      .filter((rateBandVolume) =>
        rateBandIds.includes(rateBandVolume.rateBandId)
      )
      .reduce((acc, { rateBandId, languageCode, volume }) => {
        if (!acc[rateBandId]) {
          acc[rateBandId] = {};
        }
        if (!acc[rateBandId][languageCode]) {
          acc[rateBandId][languageCode] = volume;
        }

        return acc;
      }, {});
  }
);

const mapStateToProps = (state, ownProps) => {
  const projectCreation = ownProps.location.state
    ? ownProps.location.state.projectCreation
    : false;
  const projectId = Number(ownProps.match.params.projectId);

  const project = state.projects.entities[projectId] || {};
  const stages = chargeableProjectStagesSelector(state, project.workflowId);
  const projectLanguages = projectLanguagesWithNameSelector(state, projectId);

  const orderForm = state.orderForms.entities[project.orderFormId] || {};
  const account = state.accounts.entities[orderForm.accountId] || {};
  const parentAccount =
    state.parentAccounts.entities[account.parentAccountId] || {};

  const rateBands = rateBandsByProjectIdSelector(state, projectId).filter(
    (rb) => !rb.archived
  );

  const rateBandItems = rateBandItemsSelector(state, rateBands, projectId);
  const rateBandVolumes = rateBandVolumesSelector(state, rateBands);

  const initialValues = initialValuesSelector(
    rateBands,
    stages,
    projectLanguages,
    rateBandItems,
    rateBandVolumes
  );

  const errors = state.errors;

  const storageKey = "rate-tabs";
  const activeTab = state.tabs[storageKey];

  return {
    account,
    activeTab,
    errors,
    initialValues,
    messages: state.messages,
    modal: state.modal,
    orderForm,
    parentAccount,
    projectCreation,
    projectLanguages,
    project,
    rateBandItems,
    rateBands,
    storageKey,
    stages,
  };
};

const ProjectRates = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Rates));
ProjectRates.getInitialData = getInitialData;
export default ProjectRates;
