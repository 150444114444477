/**
 * Returns the bounding rectangle of a DOM element
 *
 * @param   {DOMElement} el
 * @returns {Object} position and size, including top, bottom, left, right,
 *  width and height
 */
export function getBounds(el) {
  const {
    top,
    left,
    bottom,
    right,
    width,
    height,
  } = el.getBoundingClientRect();
  const xOffset = typeof window !== "undefined" ? window.scrollX : 0;
  const yOffset = typeof window !== "undefined" ? window.scrollY : 0;

  return {
    left: left + xOffset,
    right: right + xOffset,
    top: top + yOffset,
    bottom: bottom + yOffset,
    width,
    height,
  };
}

/**
 * @author  https://stackoverflow.com/a/13348618
 * @returns {boolean} true if the browser is desktop Chrome, otherwise false
 */
export function isChrome() {
  if (isServerRender()) return false;

  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    // is Google Chrome on IOS
    return false;
  } else if (
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    // is Google Chrome
    return true;
  } else {
    // not Google Chrome
    return false;
  }
}

/**
 * @returns {boolean} true if the page is being rendered server-side
 */
export function isServerRender() {
  return typeof window === "undefined";
}
