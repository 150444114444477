import React from "react";
import PropTypes from "prop-types";
import styles from "./DragButton.module.scss";
import Icon from "../Icon";

const DragButton = (props) =>
  props.connectDragSource(
    <div className={styles.dragButton}>
      <Icon name="Drag700" size="lg" />
    </div>
  );

DragButton.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
};

export default DragButton;
