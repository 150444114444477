import React from "react";
import PropTypes from "prop-types";
import VersionHistory from "../../../../../components/VersionHistory";

const AdminVersionHistory = ({ versionHistory }) => {
  return <VersionHistory versionHistory={versionHistory} />;
};

AdminVersionHistory.propTypes = {
  versionHistory: PropTypes.array.isRequired,
};

export default AdminVersionHistory;
