import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showWarningMessage } from "../../../../../../../modules/messages";
import { dateFields, textFields } from "../../../../../../../modules/batches";
import {
  getAssignableWorkflowStages,
  StageType,
} from "../../../../../../../modules/stages";
import {
  toInputString,
  convertDateToLocal,
} from "../../../../../../../utils/date";
import { projectById } from "../../../../../../../utils/entitySelector";
import styles from "./BatchForms.module.scss";
import { workflowClientStages } from "../../../../../../../modules/stages";
import Modal from "../../../../../../../components/Modal";
import FeatureToggle from "../../../../../../../components/FeatureToggle";
import { projectTeamsClientsSelector } from "../../../../../../../modules/projectTeams";
import { useQuery } from "@apollo/client";
import { CLIENT_BATCH_NOTIFICATIONS_QUERY } from "../../../../../../../modules/clientBatchNotifications";
import {
  ClientLanguagesSelector,
  peopleWithLanguagesByIds,
} from "./ClientLanguagesSelector";
import { BatchInput } from "./BatchInput";
import { createSelector } from "reselect";
import { ModalType } from "../../../../../../../modules/modal";

type InputReadOnly = {
  input: string;
  readOnlyValue: string;
};

type ClientReviewParamType = (state: any, workflowId: number) => StageType[];
const getClientReviewStages: ClientReviewParamType = createSelector(
  workflowClientStages,
  (workflowStages) => workflowStages.filter(({ isFinal }) => !isFinal)
);

function getInputAndReadOnlyFromDate(deadline: string): InputReadOnly {
  const localDate = deadline && convertDateToLocal(new Date(deadline));
  const readOnlyValue =
    localDate &&
    localDate.toLocaleDateString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });
  const input = localDate && toInputString(new Date(localDate));

  return { input, readOnlyValue };
}

type BatchFormsProps = {
  batch: {
    batchId: number;
    defaultDeadlines: {
      stageId: number;
      deadline: string;
    }[];
  };
  projectId: number;
  updateOrCreate: Function;
  archiveBatch: Function;
  hideModal: () => void;
  showModal: () => void;
};

const BatchForms = ({
  batch,
  projectId,
  updateOrCreate,
  archiveBatch,
  hideModal,
  showModal,
}: BatchFormsProps) => {
  const [activeField, setActiveField] = useState("");
  const dispatch = useDispatch();
  const { batchId } = batch;
  const isNewBatch = batchId === 0;

  const handleArchiveBatch = async () => {
    await archiveBatch(batchId);
    hideModal();
  };
  // @ts-ignore
  const project = useSelector((state) => projectById(state, projectId));
  const { workflowId } = project;

  const stages = useSelector((state) =>
    // @ts-ignore
    getAssignableWorkflowStages(state, workflowId)
  );

  const clientReviewStages = useSelector((state) =>
    getClientReviewStages(state, workflowId)
  );

  // turn the array of deadlines into a map for easy lookup
  const defaultDeadlines = useMemo(() => {
    if (!batch.defaultDeadlines) return {};

    return batch.defaultDeadlines.reduce((acc, { stageId, deadline }) => {
      acc[stageId] = deadline;
      return acc;
    }, {});
  }, [batch.defaultDeadlines]);

  const projectClientsList = useSelector((state) => {
    // @ts-ignore
    const clientIds = projectTeamsClientsSelector(state, projectId);

    return peopleWithLanguagesByIds(state, clientIds);
  });

  const { data } = useQuery(CLIENT_BATCH_NOTIFICATIONS_QUERY, {
    variables: { batchId },
    skip: isNewBatch,
    fetchPolicy: "no-cache",
  });
  const { clientBatchNotifications } = data || {};

  // set the batchName field to be active if we've opened the new batch row
  useEffect(() => {
    if (isNewBatch) {
      setActiveField("batchName");
    }
  }, [isNewBatch, setActiveField]);

  const updateOrCreateFn = (batch) => {
    if (isNewBatch && !batch.batchName) {
      return dispatch(
        showWarningMessage("You must first save the batch name!")
      );
    }

    updateOrCreate({ ...batch, batchId });
  };

  const baseUrl = `/admin/projects/${projectId}`;
  const bulkUrl = `${baseUrl}/bulk-uploads?batchId=${batchId}`;
  const newPdUrl = `${baseUrl}/parent-deliverables/new?batchId=${batchId}`;

  if (!isNewBatch && !data) {
    return <div>loading...</div>;
  }

  return (
    <div className={styles.form}>
      {textFields.map(({ label, field }) => (
        <BatchInput
          key={field}
          label={label}
          keyValue={field}
          inputValue={batch[field]}
          inputType={"text"}
          readOnlyValue={batch[field]}
          activeField={activeField}
          updateOrCreate={updateOrCreateFn}
          setActiveField={setActiveField}
        />
      ))}

      {dateFields.map(({ label, field }) => {
        const dateValue = batch[field];
        const { input, readOnlyValue } = getInputAndReadOnlyFromDate(dateValue);

        return (
          <BatchInput
            key={field}
            label={label}
            keyValue={field}
            inputValue={input}
            inputType={"datetime-local"}
            readOnlyValue={readOnlyValue}
            activeField={activeField}
            updateOrCreate={updateOrCreateFn}
            setActiveField={setActiveField}
          />
        );
      })}

      <hr />

      {stages.map(({ stageId, stageName }) => {
        const deadline = defaultDeadlines[stageId];
        const { input, readOnlyValue } = getInputAndReadOnlyFromDate(deadline);
        const key = ["defaultDeadlines", stageId];

        return (
          <BatchInput
            key={stageName}
            label={stageName}
            keyValue={key}
            inputValue={input}
            inputType={"datetime-local"}
            readOnlyValue={readOnlyValue}
            activeField={activeField}
            updateOrCreate={updateOrCreateFn}
            setActiveField={setActiveField}
          />
        );
      })}

      {!isNewBatch && (
        <>
          <FeatureToggle toggle="clientBatchNotifications">
            {project.clientBatchNotifications && (
              <>
                <hr />

                <div className={styles.clientBatchNotifications}>
                  {clientReviewStages.map(({ stageId }) => (
                    <ClientLanguagesSelector
                      key={stageId}
                      batchId={batchId}
                      stageId={stageId}
                      clientBatchNotifications={clientBatchNotifications}
                      projectClientsList={projectClientsList}
                      disabled={!project.clientBatchNotifications}
                    />
                  ))}
                </div>
              </>
            )}
          </FeatureToggle>

          <hr />
          <Link className={styles.btn} to={bulkUrl}>
            Bulk uploads
          </Link>
          <Link className={styles.btn} to={newPdUrl}>
            New parent deliverable
          </Link>
          <button className={styles.btnDelete} onClick={showModal}>
            Delete
          </button>
          <Modal
            body={<div>Are you sure you want to archive this batch?</div>}
            footer={
              <div>
                <button className={styles.modalCancel} onClick={hideModal}>
                  Cancel
                </button>
                <button
                  className={styles.modalConfirm}
                  onClick={handleArchiveBatch}
                >
                  Archive
                </button>
              </div>
            }
            onClickCallback={hideModal}
            type={ModalType.ArchiveBatch}
          />
        </>
      )}
    </div>
  );
};

export default BatchForms;
