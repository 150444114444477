import { getGraphQL } from "../../../../../../../../../utils/fetch";
import queryString from "query-string";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../../../modules/projectLanguages";
import {
  assignmentGroupsByProjectIdQueryV2,
  fetchProjectAssignmentGroupSuccess,
} from "../../../../../../../../modules/assignmentGroups";
import {
  ratesByProjectIdQuery,
  fetchRatesSuccess,
} from "../../../../../../../../modules/rates";
import {
  batchesByProjectIdAndStatusQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../modules/batches";
import { mergeAssignmentGroupsById } from "../../../../../../../../store/mergeRows";
import {
  emailLogsQueryByProjectId,
  fetchEmailLogsSuccess,
} from "../../../../../../../../modules/emailLogs";

export const initialQuery = `query ProjectsShowInProduction ($projectId: String, $batchStatus: String, $filterByStatus: Boolean) {
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
  ${assignmentGroupsByProjectIdQueryV2}
  ${ratesByProjectIdQuery}
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${batchesByProjectIdAndStatusQuery}
  ${emailLogsQueryByProjectId}
}`;

export function getInitialData(ownProps) {
  const {
    match: { params },
    location,
  } = ownProps;
  const search = queryString.parse(location.search);
  const fetchAll = !!search.all;

  return async (dispatch, getState) => {
    const { featureToggles } = getState();
    const json = await getGraphQL(initialQuery, {
      ...params,
      batchStatus: "inProduction",
      filterByStatus: featureToggles.assignmentGroupsByStatus && !fetchAll,
    });

    const assignmentGroups = mergeAssignmentGroupsById(json.assignmentGroups);

    dispatch(fetchProjectSuccess(json.project));
    dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
    dispatch(
      fetchProjectAssignmentGroupSuccess({
        assignmentGroups,
        projectId: params.projectId,
      })
    );
    dispatch(fetchRatesSuccess(json.rates));
    dispatch(fetchBatchesSuccess(json.batches));

    // Needed for layout
    dispatch(fetchParentAccountsSuccess(json.parentAccounts));
    dispatch(fetchAccountsSuccess(json.accounts));
    dispatch(fetchOrderFormsSuccess(json.orderForms));
    dispatch(fetchEmailLogsSuccess(json.emailLogs));
  };
}
