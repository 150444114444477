import { connect } from "react-redux";
import ProfileComponent from "../components/Profile";
import WithData from "../../../decorators/WithData";
import {
  personOverallGradesSelector,
  shouldDisplayGrades,
} from "../../../modules/grades";
import { getInitialData } from "../modules/getInitialData";
import {
  accountsFromPersonAccountsSelector,
  accountsDropDownSelector,
  personById,
  personNativeLanguagesByPersonId,
  personRolesSelector,
} from "../../../utils/entitySelector";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const personId = Number(ownProps.personId);
  const person = personById(state, personId);
  const languages = state.languages.entities;
  const personAccounts = accountsFromPersonAccountsSelector(
    personId,
    state,
    "accountName"
  );
  const accounts = accountsDropDownSelector(state);
  const personNativeLanguages = personNativeLanguagesByPersonId(
    state,
    personId
  );
  const { qualityGrade } = personOverallGradesSelector(state, personId);
  const personRoles = personRolesSelector(state, personId);

  return {
    accounts,
    languages,
    person,
    personAccounts,
    personNativeLanguages,
    personRoles,
    qualityGrade: shouldDisplayGrades(state) && qualityGrade,
  };
};

const PeopleShowWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ProfileComponent));
PeopleShowWithData.getInitialData = getInitialData;
export default PeopleShowWithData;
