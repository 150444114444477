import React, { useMemo } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import AllocationNumber from "../AllocationNumber";
import AvailableHeader from "../AvailableHeader";
import { DEFAULT_COLUMN_COUNT } from "../StageSection";

const StageHeader = (props) => {
  const {
    defaultAssignees,
    languageData,
    languageCode,
    stage: { stageId, hasTrainingStage },
    featureToggles,
    reassignment,
    languageName,
    totalAllocations,
    numberToAssign,
  } = props;

  const allSelected = useMemo(() => {
    const defaultAssignes = Object.values(languageData);
    return defaultAssignes.every((value) => value.available);
  }, [languageData]);

  const showAvailable = !!featureToggles.allocationScaling && !reassignment;
  const showGrading = !!featureToggles.QCC_1798_freelancerGrading;
  const showTraining = !!hasTrainingStage;

  const inputCount =
    DEFAULT_COLUMN_COUNT +
    (showAvailable ? 1 : 0) +
    (showGrading ? 1 : 0) +
    (showTraining ? 1 : 0);

  const gridStyles = classNames({
    [styles.gridHeader]: true,
    [styles.gridInputs1]: inputCount === 1,
    [styles.gridInputs2]: inputCount === 2,
    [styles.gridInputs3]: inputCount === 3,
    [styles.gridInputs4]: inputCount === 4,
  });

  const remainingAllocations =
    numberToAssign < totalAllocations
      ? `over assigned by ${totalAllocations - numberToAssign}`
      : numberToAssign - totalAllocations;

  const allocationStyles = classNames({
    [styles.allocation]: true,
    [styles.warn]: remainingAllocations !== 0,
  });

  return (
    <div className={gridStyles}>
      {reassignment ? (
        // spacing div
        <div />
      ) : (
        <div className={styles.languageNameStyles}>
          <span>{`${languageCode} - ${languageName}`}</span>
          <span
            className={allocationStyles}
          >{`(${remainingAllocations})`}</span>
        </div>
      )}

      {showAvailable && (
        <AvailableHeader
          defaultAssignees={defaultAssignees}
          languageCode={languageCode}
          stageId={stageId}
          allSelected={allSelected}
        />
      )}

      {showGrading && (
        <div className={styles.grade} title="Quality grade">
          Q*
        </div>
      )}

      {hasTrainingStage && <div className={styles.training}>T</div>}

      <AllocationNumber
        reassignment={reassignment}
        stageId={stageId}
        featureToggles={featureToggles}
      />
    </div>
  );
};

export default StageHeader;
