import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";

export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB";

export const updateActiveTab = createAction(UPDATE_ACTIVE_TAB);

export const tabsActionHandlers = {
  [RESET_INITIAL_STATE]: () => tabsInitialState,
  [UPDATE_ACTIVE_TAB]: (state, { payload: { storageKey, value } }) => ({
    ...state,
    [storageKey]: value,
  }),
};

export const tabsInitialState = {};
