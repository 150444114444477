import React, { memo } from "react";
import PropTypes from "prop-types";
import { ModalType } from "../../modules/modal";
import Modal from "../../components/Modal";
import QualityGradingForm from "./QualityGradingForm";

const QualityGradingModal = ({
  modalType,
  onCancel,
  onSubmit,
  peopleToGrade,
  projectId,
  reviewerStageId,
  stageToGrade,
  transition,
}) => {
  if (modalType !== ModalType.QualityGradingForm || !reviewerStageId) {
    return null;
  }

  return (
    <Modal
      key="modal"
      body={
        <div>
          <QualityGradingForm
            onCancel={onCancel}
            onSubmitSuccess={onSubmit}
            peopleToGrade={peopleToGrade}
            projectId={projectId}
            reviewerStageId={reviewerStageId}
            stageToGrade={stageToGrade}
            transitionName={transition && transition.transitionName}
          />
        </div>
      }
      onClickCallback={onCancel}
      title={
        "Please grade the content creators on their work by selecting the statements that apply:"
      }
      type={ModalType.QualityGradingForm}
      wide
    />
  );
};

QualityGradingModal.propTypes = {
  modalType: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  peopleToGrade: PropTypes.array,
  projectId: PropTypes.number,
  reviewerStageId: PropTypes.number,
  stageToGrade: PropTypes.object,
  transition: PropTypes.object,
};

export default memo(QualityGradingModal);
