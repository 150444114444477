import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import classNames from "classnames";
import SimpleEditor from "../../../../../../../../components/SimpleEditor/v4";
import withErrors, {
  InputWithErrors,
  TextAreaWithErrors,
} from "../../../../../../../../decorators/WithErrors";
import { isTaskFormatHTML } from "../../../../../../../../modules/taskFields";
import styles from "./FieldArray.module.scss";
import OverlayLoading from "../../../../../../../../components/OverlayLoading/v1";

const componentList = {
  input: InputWithErrors,
  textarea: TextAreaWithErrors,
};

const FieldArray = ({
  fields,
  title,
  labelKey,
  fieldKey,
  entityId,
  entities,
  componentType,
}) => {
  const [loading, setLoading] = useState(true);
  const formFields = fields.getAll() || [];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, []);

  if (loading) {
    return <OverlayLoading />; // Show a loading indicator while fetching data
  }

  const component =
    componentType && componentList[componentType]
      ? componentList[componentType]
      : InputWithErrors;

  /**
    mapSourceFieldToPosition: using the sourceFieldIds this function creates a map between
    the taskFieldPosition of a sourceField and the index of the sourceField in the FieldArray
   **/

  const mapSourceFieldToPosition = (sourceFieldEntities, formFields) => {
    const sourceFieldIdToIndex = {};
    const sourceFieldPositions = {};
    Object.keys(sourceFieldEntities)
      .map((sourceFieldId) => Number(sourceFieldId))
      .sort((a, b) => a - b)
      .forEach((sourceFieldId, index) => {
        sourceFieldIdToIndex[sourceFieldId] = index;
      });

    formFields.forEach((field, index) => {
      for (const sourceFieldId in sourceFieldEntities) {
        if (sourceFieldEntities[sourceFieldId]["taskFieldPosition"] === index) {
          sourceFieldPositions[index] = sourceFieldIdToIndex[sourceFieldId];
        }
      }
    });
    return sourceFieldPositions;
  };

  const map = mapSourceFieldToPosition(entities, formFields);
  const formFieldIds = Object.entries(entities)
    .sort((a, b) => a[1].briefingFieldPosition - b[1].briefingFieldPosition)
    .map(([id]) => id);

  return (
    <div>
      <label className={styles.label}>{title}</label>
      {fields.map((htmlName, index) => {
        const briefingFieldFormat =
          entities[formFieldIds[index]]["briefingFieldFormat"];
        if (briefingFieldFormat === undefined) {
          index = map[index];
          htmlName = `${fields.name}[${index}]`;
        }

        const current = fields.get(index);
        if (!current) return null;

        const sourceFieldFormat =
          entities[formFieldIds[index]]["taskFieldFormat"];
        const currentEntityId = current[entityId];

        const { fieldValue, rawContent } = fields.get(index) || {};

        const isHTML =
          briefingFieldFormat === "HTML" || isTaskFormatHTML(sourceFieldFormat);

        const fieldStyles = classNames({
          [styles.field]: true,
          [styles.isHTML]: isHTML,
        });

        return (
          <div key={htmlName}>
            <div className={styles.fieldLabel}>
              <span>{entities[currentEntityId][labelKey]}</span>
            </div>
            <div className={fieldStyles}>
              <Field
                component={isHTML ? SimpleEditor : component}
                props={{
                  text: fieldValue,
                  ...(isHTML ? { rawContent } : {}),
                }}
                name={`${htmlName}[${fieldKey}]`}
                type="text"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

FieldArray.propTypes = {
  componentType: PropTypes.string,
  entities: PropTypes.object.isRequired,
  entityId: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  labelKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const FieldArrayWithErrors = withErrors(FieldArray);
export { FieldArrayWithErrors };
