import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../modules/projectLanguages";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../modules/batches";
import {
  projectTeamsByProjectIdQuery,
  fetchProjectTeamsByProjectIdSuccess,
} from "../../../../../../modules/projectTeams";
import {
  fetchPeopleSuccess,
  personFields,
} from "../../../../../../modules/people";
import { fetchPersonNativeLanguagesSuccess } from "../../../../../../modules/personNativeLanguages";
import { pick } from "lodash";

export const initialQuery = `query batchesByProjectId ($projectId: String){
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
  ${batchesByProjectIdQuery}
  ${projectTeamsByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);

    dispatch(fetchParentAccountsSuccess(json.parentAccounts));
    dispatch(fetchAccountsSuccess(json.accounts));
    dispatch(fetchOrderFormsSuccess(json.orderForms));
    dispatch(fetchProjectSuccess(json.project));
    dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
    dispatch(fetchBatchesSuccess(json.batches));
    dispatch(fetchProjectTeamsByProjectIdSuccess(json.projectTeams));

    const people = json.projectTeams.map((pt) => pick(pt.person, personFields));
    dispatch(fetchPeopleSuccess(people));

    const personNativeLanguages = json.projectTeams.reduce((acc, pt) => {
      if (pt.person.personNativeLanguages) {
        acc.push(...pt.person.personNativeLanguages);
      }
      return acc;
    }, []);
    dispatch(fetchPersonNativeLanguagesSuccess(personNativeLanguages));
  };
}
