import Overview from "../components/Overview";
import WithData from "../../../../../../../decorators/WithData";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { appComponentSelector } from "../../../../../../../modules/appComponents";
import { getInitialData } from "../modules/getInitialData";
import { currentLanguagesSelector } from "../../../../../../../utils/entitySelector";
import { projectById } from "../../../../../../../modules/projects";
import { projectTotals } from "../../../../../../../modules/projectTotals";

const workflowStagesByTypeSelector = createSelector(
  (state) => state.stages.entities,
  (_, workflowId) => workflowId,
  (_, _workflowId, stageType) => stageType,
  (stages, workflowId, stageType) => {
    return Object.values(stages).filter(
      (stage) =>
        stage.workflowId === workflowId &&
        stage.stageType === stageType &&
        !stage.isFinal
    );
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { projectId },
    },
    location,
    history,
  } = ownProps;
  const { readyForReview } = appComponentSelector(state, "overviewList");

  const activeLanguages = currentLanguagesSelector(state, projectId);
  const { workflowId } = projectById(state, projectId);
  const clientStages = workflowStagesByTypeSelector(
    state,
    workflowId,
    "Client"
  );
  const totals = projectTotals(
    state,
    projectId,
    activeLanguages,
    readyForReview
  );

  return {
    clientStages,
    projectId: Number(projectId),
    totals,
    location,
    history,
  };
};

const ClientProjectDashboardOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Overview));
export default ClientProjectDashboardOverview;
