import { createAction } from "redux-actions";
import { getWordCount } from "../utils/counts";
import { projectTaskFieldsSelector } from "../utils/entitySelector";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// Constants
// ------------------------------------
export const TOGGLE_LOADING_STATE = "TOGGLE_LOADING_STATE";
export const CREATE_EDITOR_STATE = "CREATE_EDITOR_STATE";
export const UPDATE_EDITOR_STATE = "UPDATE_EDITOR_STATE";

// ------------------------------------
// Actions
// ------------------------------------
export const createEditorState = createAction(CREATE_EDITOR_STATE);
export const updateEditorTaskFieldSuccess = createAction(UPDATE_EDITOR_STATE);
export const toggleLoadingState = createAction(TOGGLE_LOADING_STATE);

export function updateEditorTaskField({
  taskFieldId,
  content,
  rawContent,
  taskFieldFormat,
  plainText,
}) {
  return (dispatch, getState) => {
    const payload = {
      taskFieldId,
      content,
      wordCount: getWordCount(content, taskFieldFormat, plainText),
      rawContent,
    };
    return dispatch(updateEditorTaskFieldSuccess(payload));
  };
}

export function switchEditorState(deliverableId) {
  return (dispatch, getState) => {
    const state = getState();
    const parentDeliverableId =
      state.deliverables.entities[deliverableId].parentDeliverableId;
    const projectId =
      state.parentDeliverables.entities[parentDeliverableId].projectId;
    const taskFields = projectTaskFieldsSelector(state, projectId);
    const taskFieldContent = [];

    // only use latest taskfield by deliverableId
    const taskFieldContentByTaskField = state.taskFieldContent.result.reduce(
      (acc, taskFieldContentId) => {
        const tfc = state.taskFieldContent.entities[taskFieldContentId];
        if (tfc.deliverableId !== deliverableId) return acc;
        const { taskFieldId } = tfc;

        if (!acc[taskFieldId] || tfc.createDate > acc[taskFieldId].createDate)
          acc[taskFieldId] = tfc;

        return acc;
      },
      {}
    );

    Object.keys(taskFieldContentByTaskField).forEach((taskFieldId) => {
      taskFieldContent.push(taskFieldContentByTaskField[taskFieldId]);
    });

    return dispatch(createEditorState({ taskFieldContent, taskFields }));
  };
}
// ------------------------------------
// Action Handlers
// ------------------------------------
export const editorActionHandlers = {
  [RESET_INITIAL_STATE]: () => editorInitialState,
  [CREATE_EDITOR_STATE]: (
    state,
    { payload: { taskFieldContent, taskFields } }
  ) => {
    const newState = {};
    taskFieldContent.forEach((tfc) => {
      newState[tfc.taskFieldId] = {
        taskFieldId: tfc.taskFieldId,
        content: tfc.content,
        rawContent: tfc.rawContent,
      };
    });

    taskFields.forEach((tf) => {
      const editorTaskField = newState[tf.taskFieldId];
      if (editorTaskField) {
        newState[tf.taskFieldId] = {
          ...editorTaskField,
          wordCount: getWordCount(editorTaskField.content, tf.taskFieldFormat),
        };
      }
    });

    return newState;
  },

  [UPDATE_EDITOR_STATE]: (state, { payload }) => {
    // TODO: Replace all calls to this handler with an array
    return payload.constructor === Array
      ? payload.reduce((acc, p) => {
          const priorEntity = acc[p.taskFieldId];
          const newEntity = Object.assign({}, priorEntity, {
            content: p.content,
          });
          const entities = Object.assign({}, acc, {
            [p.taskFieldId]: newEntity,
          });
          return Object.assign({}, entities);
        }, state)
      : Object.assign({}, state, { [payload.taskFieldId]: payload });
  },
  [TOGGLE_LOADING_STATE]: (state, { payload }) => {
    return Object.assign({}, state, { loading: payload });
  },
};

export const editorInitialState = {};
