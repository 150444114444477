const validate = (values) => {
  const errors = {};

  const hasDeadlineChanged =
    values.deadline && values.deadline !== values.originalDeadline;

  // we must change at least one value
  if (!values.rate && !hasDeadlineChanged) {
    errors._error = true;
  }

  return errors;
};

export default validate;
