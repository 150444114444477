import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./TaskNavigation.module.scss";
import Icon from "../../../Icon";

/**
 * Handles navigation between tasks including rendering the next/prev button
 * and implement left/right arrow keyboard shortcuts
 */
function TaskNavigation({
  currentDeliverableId,
  deliverableIds,
  history,
  nextUrl,
  previousUrl,
}) {
  /**
   * Navigates to next/prev assignments based on a left/right keyup event
   */
  const shortcutListener = (e) => {
    const { keyCode, shiftKey } = e;

    /**
     * Only apply the shortcuts if the user is focused on the body
     * (i.e. they are not focused on an input element)
     */
    if (e.composedPath && e.composedPath()[0].localName !== "body") {
      return;
    }

    // Left-arrow
    if (previousUrl && keyCode === 37 && !shiftKey) {
      history.push(previousUrl);
    }

    // Right-arrow
    if (nextUrl && keyCode === 39 && !shiftKey) {
      history.push(nextUrl);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keyup", shortcutListener);

      return () => {
        window.removeEventListener("keyup", shortcutListener);
      };
    }
  });

  const prevText = (
    <span className={styles.item}>
      <Icon name="ArrowLeft" fill={previousUrl ? "#da0034" : "#4e575e"} />
      Previous
    </span>
  );
  const nextText = (
    <span className={styles.item}>
      Next
      <Icon name="ArrowRight" fill={nextUrl ? "#da0034" : "#4e575e"} />
    </span>
  );

  const position = deliverableIds.indexOf(currentDeliverableId);

  return (
    <div className={styles.taskNavigation}>
      {previousUrl ? <Link to={previousUrl}>{prevText}</Link> : prevText}
      {nextUrl ? <Link to={nextUrl}>{nextText}</Link> : nextText}

      {position > -1 && (
        <span>
          ({position + 1}/{deliverableIds.length})
        </span>
      )}
    </div>
  );
}

TaskNavigation.propTypes = {
  currentDeliverableId: PropTypes.number,
  deliverableIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  history: PropTypes.object.isRequired,
  nextUrl: PropTypes.string,
  previousUrl: PropTypes.string,
};

TaskNavigation.defaultProps = {
  deliverableIds: [],
};

export default memo(TaskNavigation);
