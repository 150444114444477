import { connect } from "react-redux";

import AdminLayout from "../components/AdminLayout";
import { logoutFetch } from "../../../../modules/me";

export const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => dispatch(logoutFetch()),
});

export const mapStateToProps = (state, ownProps) => ({
  me: state.people.entities[state.me],
  featureToggles: state.featureToggles,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
