import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
import { upsertData } from "../utils/normalize";

export const versionHistoryQuery = `versionHistory (deliverableId: $deliverableId, stageId: $stageId) { 
  deliverableId,
  stages {
    stageId,
    stageName,
    stagePosition,
  },
  tasks {
    taskFieldName,
    taskFieldFormat,
    taskFieldId
  },
  stageContent {
    taskFieldId
    stageId,
    content
  },
}`;

export const FETCH_VERSION_HISTORY_SUCCESS = "FETCH_VERSION_HISTORY_SUCCESS";

export const fetchVersionHistoryDetailsSuccess = createAction(
  FETCH_VERSION_HISTORY_SUCCESS
);

export const versionHistoryActionHandlers = {
  [RESET_INITIAL_STATE]: () => versionHistoryInitialState,
  [FETCH_VERSION_HISTORY_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "deliverableId"),
};

export const versionHistoryInitialState = { entities: {}, result: [] };
