import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../../../../../../components/Dropdown'
import styles from './AccountFilter.module.scss'

const AccountFilter = (props) => {
  return (
    <Dropdown
      title="Client"
      containerStyles={styles.container}
      buttonStyles={styles.buttonStyles}
    >
      <div
        className={styles.dropdownItem}
        key={'all'}
        onClick={() => props.setSelectedAccount('all')}
      >
        All
      </div>
      {Array.from(props.accounts).map((account) => (
        <div
          className={styles.dropdownItem}
          key={account}
          onClick={() => props.setSelectedAccount(account)}
        >
          {account}
        </div>
      ))}
    </Dropdown>
  )
}

AccountFilter.propTypes = {
  accounts: PropTypes.instanceOf(Set),
  setSelectedAccount: PropTypes.func.isRequired,
}

export default AccountFilter
