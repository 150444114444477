import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
} from "draft-js";
const { hasCommandModifier } = KeyBindingUtil;

/**
 * Returns a basic draft js raw content template
 *
 * @param {String} type block style type
 * @param {String} key block key
 */
const defaultBlockTemplate = ({
  inlineStyleRanges = [],
  key,
  text = "",
  type,
}) => ({
  blocks: [
    {
      key,
      text,
      type,
      depth: 0,
      inlineStyleRanges,
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
});

/**
 * Creates a raw content entity from one or more DraftJS content blocks
 *
 * @param {Object|Array} block one or more DraftJS blocks
 */
export const createRawContent = (blocks) => ({
  blocks: Array.isArray(blocks) ? blocks : [blocks],
  entityMap: {},
});

/**
 * Creates an editor state
 *
 * @param {String|Object} initialRawContent
 * @param {Object} compositeDecorators
 * @param {Object} [defaultBlock] default block settings
 */
export const createEditorState = (
  initialRawContent,
  compositeDecorators,
  defaultBlock
) => {
  const rawContent =
    typeof initialRawContent === "string"
      ? JSON.parse(initialRawContent)
      : initialRawContent;

  const editorState = rawContent
    ? EditorState.createWithContent(
        convertFromRaw(rawContent),
        compositeDecorators
      )
    : defaultBlock
    ? EditorState.createWithContent(
        convertFromRaw(defaultBlockTemplate(defaultBlock)),
        compositeDecorators
      )
    : EditorState.createEmpty(compositeDecorators);

  return editorState;
};

/**
 * A function to extract the text from an editor state
 *
 * @param {Object} editorState
 */
export const editorStateToText = (editorState) => {
  try {
    return editorState.getCurrentContent().getPlainText();
  } catch (err) {
    return "";
  }
};

/**
 * @param   {EditorState} editorState
 * @returns {Object}      raw content for the editor state's content
 */
export const editorStateToRaw = (editorState) => {
  return convertToRaw(editorState.getCurrentContent());
};

/**
 * @param   {EditorState} editorState
 * @returns {Object}      the editor state's first block of content
 */
export const getFirstBlock = (editorState) => {
  const { blocks } = editorStateToRaw(editorState);
  return blocks[0];
};

/**
 * @param {Object} editorState the editor state to add the style to
 * @param {string} styleName the style name to add
 * @param {Object} [selection] draft js selection to apply the style to
 * @param {string} [changeType] the change type
 *
 * @returns {Object} an updated editor state
 */
export const addInlineStyle = (
  editorState,
  styleName,
  selection,
  changeType
) => {
  const newContentState = Modifier.applyInlineStyle(
    editorState.getCurrentContent(),
    selection || editorState.getSelection(),
    styleName
  );

  return EditorState.push(editorState, newContentState, changeType);
};

/**
 * @param {Object} editorState the editor state to remove the style from
 * @param {string} styleName the style name to remove
 * @param {Object} [selection] draft js selection to apply the style to
 * @param {string} [changeType] the change type
 *
 * @returns {Object} an updated editor state
 */
export const removeInlineStyle = (
  editorState,
  styleName,
  selection,
  changeType = "remove-inline-style"
) => {
  const newContentState = Modifier.removeInlineStyle(
    editorState.getCurrentContent(),
    selection || editorState.getSelection(),
    styleName
  );

  return EditorState.push(editorState, newContentState, changeType);
};

export const replaceText = (editorState, text, selection) => {
  const newContentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    text === "(omit)" ? "" : text
  );

  return EditorState.push(editorState, newContentState);
};

/*
    In readOnly mode draft-js doesn't call onChange so our inline feedback handlers are not called.
    To get around this we can pass a custom keyBindingFn that returns a command
    that isn't ever handled and hence all input is blocked from entering the editor.
    However copying and text selection (and therefore commenting) are still allowed.
    https://github.com/facebook/draft-js/issues/690#issuecomment-337266110
  */
export const handleReadOnly = (e) => {
  const cKey = e.keyCode === 67;
  const rKey = e.keyCode === 82;

  // Check for the copy command or browser refresh
  if (hasCommandModifier(e) && (cKey || rKey)) {
    return getDefaultKeyBinding(e);
  }
  return "not-handled-command";
};

/**
 * Performs convertFromRaw on a rawContent block but handles edge cases of no blocks available
 *
 * @param {Object} rawContent
 * @returns {ContentState}
 */
export const safeConvertFromRaw = (rawContent) => {
  try {
    return convertFromRaw(rawContent);
  } catch (err) {
    return ContentState.createFromText("");
  }
};
