import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Errors from "../../../../../../../../components/Errors";
import BriefingFieldContainer from "../../../../../../../../components/BriefingFieldContainer";
import PageHeader from "../../../../../../../../components/PageHeader";
import { useQuery } from "@apollo/client";
import { GET_ADJUSTMENT_QUERY_GQL } from "../../../../../../../../modules/assignmentGroupAdjustment";

const AssignmentsList = ({
  assignmentGroupId,
  briefingFields,
  data,
  errors,
  personName,
  project: {
    accountName,
    orderFormNumber,
    parentAccountName,
    projectId,
    projectName,
    currencyCode,
  },
  saveLocalContentToDBAndCreateTransitionLogs,
  transitions,
}) => {
  const baseUrl = `/admin/projects/${projectId}/assignments/${assignmentGroupId}/tasks`;

  const adjustmentQueryData = useQuery(GET_ADJUSTMENT_QUERY_GQL, {
    variables: { assignmentGroupId },
  });
  const { assignmentGroupAdjustment } = adjustmentQueryData?.data || {};

  return (
    <div>
      <Helmet>
        {" "}
        <title>Assignment List</title>{" "}
      </Helmet>
      {errors.display && <Errors />}
      <PageHeader
        breadCrumbItems={[
          { url: "/admin", text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { url: `/admin/projects/${projectId}`, text: projectName },
          { text: `Assignment list (${personName})` },
        ]}
        title={projectName}
      />

      <BriefingFieldContainer
        assignmentGroupAdjustment={assignmentGroupAdjustment}
        assignmentGroupId={assignmentGroupId}
        baseUrl={baseUrl}
        briefingFields={briefingFields}
        data={data}
        exportContent
        isInProduction
        processMultipleTransitions={saveLocalContentToDBAndCreateTransitionLogs}
        projectId={projectId}
        currencyCode={currencyCode}
        transitions={transitions}
      />
    </div>
  );
};

AssignmentsList.propTypes = {
  assignmentGroupId: PropTypes.number.isRequired,
  briefingFields: PropTypes.array,
  data: PropTypes.array,
  errors: PropTypes.shape({
    display: PropTypes.bool.isRequired,
    msg: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired,
    ]),
  }),
  personName: PropTypes.string.isRequired,
  project: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    orderFormNumber: PropTypes.string.isRequired,
    parentAccountName: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
  }).isRequired,
  saveLocalContentToDBAndCreateTransitionLogs: PropTypes.func.isRequired,
  transitions: PropTypes.arrayOf(
    PropTypes.shape({
      fromStageId: PropTypes.number.isRequired,
      payStageId: PropTypes.number,
      toStageId: PropTypes.number.isRequired,
      transitionId: PropTypes.number.isRequired,
      transitionName: PropTypes.string.isRequired,
      workflowId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default AssignmentsList;
