import { getGraphQL } from "../../../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../../../modules/projectLanguages";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../../modules/batches";
import {
  briefingFieldsByProjectIdQuery,
  fetchBriefingFieldsSuccess,
} from "../../../../../../../../modules/briefingFields";

export const initialQuery = `query ProjectShowBriefing($projectId: String) {
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
  ${briefingFieldsByProjectIdQuery}
  ${batchesByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch, getState) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);

    dispatch(fetchParentAccountsSuccess(json.parentAccounts));
    dispatch(fetchAccountsSuccess(json.accounts));
    dispatch(fetchOrderFormsSuccess(json.orderForms));
    dispatch(fetchProjectSuccess(json.project));
    dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
    dispatch(fetchBriefingFieldsSuccess(json.briefingFields));
    dispatch(fetchBatchesSuccess(json.batches));
  };
}
