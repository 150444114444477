import { getGraphQL } from "../../../../../../../../utils/fetch";
import {
  assignmentsByAssignmentGroupQuery,
  nextAndPriorAssignmentsByAssignmentGroupQuery,
  fetchAssignmentsSuccess,
  fetchAssignmentsByGroupSuccess,
} from "../../../../../../../modules/assignments";
import {
  assignmentGroupQuery,
  fetchAssignmentGroupSuccess,
} from "../../../../../../../modules/assignmentGroups";
import {
  parentDeliverablesByAssignmentGroupQuery,
  fetchParentDeliverablesSuccess,
} from "../../../../../../../modules/parentDeliverables";
import {
  deliverablesByAssignmentGroupQuery,
  fetchDeliverablesSuccess,
} from "../../../../../../../modules/deliverables";
import {
  briefingFieldsByAssignmentGroupQuery,
  fetchBriefingFieldsSuccess,
} from "../../../../../../../modules/briefingFields";
import {
  projectsByAssignmentGroupQuery,
  fetchProjectsSuccess,
} from "../../../../../../../modules/projects";
import {
  parentDeliverableBriefingFieldsByAssignmentGroupQuery,
  fetchParentDeliverableBriefingFieldsSuccess,
} from "../../../../../../../modules/parentDeliverableBriefingFields";
import {
  accountsByAssignmentGroupIdQuery,
  fetchAccountsSuccess,
} from "../../../../../../../modules/accounts";
import {
  orderFormsByAssignmentGroupIdQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../modules/orderForms";

export const initialQuery = `query freelancerAssignmentList_getInitialData (
  $assignmentGroupId: String, $includeArchivedAssignments: Boolean) {
    ${assignmentsByAssignmentGroupQuery}
    ${nextAndPriorAssignmentsByAssignmentGroupQuery}
    ${assignmentGroupQuery}
    ${parentDeliverablesByAssignmentGroupQuery}
    ${deliverablesByAssignmentGroupQuery}
    ${parentDeliverableBriefingFieldsByAssignmentGroupQuery}
    ${briefingFieldsByAssignmentGroupQuery}
    ${projectsByAssignmentGroupQuery}
    ${accountsByAssignmentGroupIdQuery}
    ${orderFormsByAssignmentGroupIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    // for freelancer list we want to include any archived assignments (but only show them if they are approved)
    return getGraphQL(
      initialQuery,
      { ...params, includeArchivedAssignments: true },
      url,
      cookies
    ).then((json) => {
      dispatch(fetchProjectsSuccess(json.projects));
      dispatch(fetchBriefingFieldsSuccess(json.briefingFields));
      dispatch(fetchParentDeliverablesSuccess(json.parentDeliverables));
      dispatch(
        fetchParentDeliverableBriefingFieldsSuccess(
          json.parentDeliverableBriefingFields
        )
      );
      dispatch(fetchDeliverablesSuccess(json.deliverables));
      dispatch(
        fetchAssignmentsByGroupSuccess({
          assignments: json.assignments,
          assignmentGroupId: params.assignmentGroupId,
        })
      );
      dispatch(fetchAssignmentsSuccess(json.nextAndPriorAssignments));
      dispatch(fetchAssignmentGroupSuccess(json.assignmentGroup));
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
    });
  };
}
