import { getGraphQL } from "../../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../../modules/projectLanguages";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../modules/batches";
import {
  defaultAssigneesByProjectIdQuery,
  fetchDefaultAssigneesSuccess,
} from "../../../../../../../modules/defaultAssignees";
import {
  parentDeliverablesByProjectQuery,
  fetchParentDeliverablesSuccess,
} from "../../../../../../../modules/parentDeliverables";
import {
  deliverablesByProjectQuery,
  fetchDeliverablesSuccess,
} from "../../../../../../../modules/deliverables";
import {
  peopleByDefaultAssigneeQuery,
  fetchPeopleSuccess,
} from "../../../../../../../modules/people";
import {
  rateBandsByProjectIdQuery,
  fetchRateBandsSuccess,
} from "../../../../../../../modules/rateBands";
import {
  rateBandItemsByProjectIdQuery,
  fetchRateBandItemsSuccess,
} from "../../../../../../../modules/rateBandItems";
import {
  quotedRatesByProjectIdQuery,
  fetchQuotedRatesSuccess,
} from "../../../../../../../modules/quotedRates";

export const initialQuery = `query ProjectAssignmentsNew ($projectId: String) {
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${projectQuery}
  ${peopleByDefaultAssigneeQuery}
  ${projectLanguagesByProjectIdQuery}
  ${batchesByProjectIdQuery}
  ${rateBandsByProjectIdQuery}
  ${rateBandItemsByProjectIdQuery}
  ${defaultAssigneesByProjectIdQuery}
  ${quotedRatesByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);

    const {
      parentAccounts,
      accounts,
      orderForms,
      project,
      peopleByDefaultAssignee,
      projectLanguages,
      batches,
      defaultAssignees,
      rateBands,
      rateBandItems,
      quotedRates,
    } = json;

    dispatch(fetchParentAccountsSuccess(parentAccounts));
    dispatch(fetchAccountsSuccess(accounts));
    dispatch(fetchOrderFormsSuccess(orderForms));
    dispatch(fetchProjectSuccess(project));
    dispatch(fetchPeopleSuccess(peopleByDefaultAssignee));
    dispatch(fetchProjectLanguagesByProjectIdSuccess(projectLanguages));
    dispatch(fetchBatchesSuccess(batches));
    dispatch(fetchDefaultAssigneesSuccess(defaultAssignees));
    dispatch(fetchRateBandsSuccess(rateBands));
    dispatch(fetchRateBandItemsSuccess(rateBandItems));
    dispatch(fetchQuotedRatesSuccess(quotedRates));
  };
}
