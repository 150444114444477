import React from "react";
import DatePicker from "react-datepicker";
import styles from "./DatePicker.module.scss";

const FieldDatePicker = (props) => {
  const {
    input: { value, onChange },
  } = props;

  return (
    <DatePicker
      value={value}
      className={styles.datePicker}
      dateFormat="dd/MM/yyyy"
      selected={value || ""}
      onChange={onChange}
    />
  );
};

export default FieldDatePicker;
