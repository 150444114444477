import {
  checkValidations,
  isNotBlank,
  isNotBlankArray,
} from "../../../../../../../utils/validations";

const validate = (
  { batchId, languages, rateBandId, featureToggles } = {},
  { edit } = {}
) => {
  const errors = {};

  const batchIdErrors = checkValidations(batchId, [
    { validate: isNotBlank, msg: "Required" },
  ]);

  if (batchIdErrors) errors.batchId = batchIdErrors;

  let languageErrors;

  if (!edit) {
    languageErrors = checkValidations(languages, [
      { validate: isNotBlankArray, msg: "Required" },
    ]);
  }

  if (languageErrors) errors.languages = languageErrors;

  if (featureToggles && featureToggles.QCC_1081_rateBands) {
    const rateBandIdErrors = checkValidations(rateBandId, [
      { validate: isNotBlank, msg: "Required" },
    ]);
    if (rateBandIdErrors) errors.rateBandId = rateBandIdErrors;
  }

  return errors;
};

export default validate;
