import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { ValidationError } from "../../../../utils/validations";
import styles from "./GradeField.module.scss";

// Prefix before option values, as redux-form doesn't like numeric radio values
const OPTION_PREFIX = "grade-";

// Derive a field name
const getFieldName = (gradeName, personId) => `${gradeName}-person-${personId}`;

/**
 * A group of radio button options for a grade type i.e. Quality grade or
 * Brief-specific grade
 */
const GradeField = ({ label, name, options, personId }) => (
  <div className={styles.gradeField}>
    <h3 className={styles.name}>{label}</h3>

    <div className={styles.options}>
      {options.map(({ label, value }) => (
        <label key={value} className={styles.option}>
          <Field
            component="input"
            name={getFieldName(name, personId)}
            type="radio"
            value={`${OPTION_PREFIX}${value}`}
          />

          <span className={styles.optionText}>{label}</span>
        </label>
      ))}
    </div>
  </div>
);

GradeField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  personId: PropTypes.number.isRequired,
};

/**
 * Parse a submitted grade for a person
 *
 * @param {Object}    the grade type
 * @param {number}    personId
 * @param {Object}    values form submission, in field : value pairs
 * @throws ValidationError if any values are missing
 * @returns {number}  the numeric grade value
 */
export const parseGrade = ({ name }, personId, values) => {
  const fieldName = getFieldName(name, personId);
  const grade = values[fieldName];

  if (typeof grade === "undefined") {
    throw new ValidationError(fieldName, "Value is required");
  }

  return Number(grade.substring(OPTION_PREFIX.length));
};

export default GradeField;
