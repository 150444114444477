import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_FREELANCER_DASHBOARD_SUCCESS =
  "FETCH_FREELANCER_DASHBOARD_SUCCESS";
export const UPDATE_FREELANCER_DASHBOARD_STATUS_SUCCESS =
  "UPDATE_FREELANCER_DASHBOARD_STATUS_SUCCESS";
export const UPDATE_FREELANCER_DASHBOARD_ASSIGNMENT_GROUP_SUCCESS =
  "UPDATE_FREELANCER_DASHBOARD_ASSIGNMENT_GROUP_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFreelancerDashboardSuccess = createAction(
  FETCH_FREELANCER_DASHBOARD_SUCCESS
);
export const updateFreelancerDashboardStatusSuccess = createAction(
  UPDATE_FREELANCER_DASHBOARD_STATUS_SUCCESS
);
export const updateFreelancerDashboardAssignmentGroupSuccess = createAction(
  UPDATE_FREELANCER_DASHBOARD_ASSIGNMENT_GROUP_SUCCESS
);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const freelancerDashboardActionHandlers = {
  [RESET_INITIAL_STATE]: () => freelancerDashboardInitialState,
  [FETCH_FREELANCER_DASHBOARD_SUCCESS]: (_, { payload }) => payload,
  [UPDATE_FREELANCER_DASHBOARD_ASSIGNMENT_GROUP_SUCCESS]: (
    state,
    { payload: { assignmentGroupId, ...updates } }
  ) => {
    return state.map((row) => ({
      ...row,
      ...(row.assignmentGroupId === assignmentGroupId ? updates : {}),
    }));
  },
  [UPDATE_FREELANCER_DASHBOARD_STATUS_SUCCESS]: (
    state,
    { payload: { assignmentGroupIds, status } }
  ) => {
    return state.reduce((acc, s) => {
      const shouldChangeStatus =
        assignmentGroupIds.indexOf(s.assignmentGroupId) === -1;
      const newStatus = shouldChangeStatus ? s.status : status;
      acc.push({ ...s, status: newStatus });
      return acc;
    }, []);
  },
};

export const freelancerDashboardInitialState = [];
