import React from "react";
import OverlayLoading from "../../components/OverlayLoading/v2";
import styles from "./KeywordResults.module.scss";

const KeywordResults = ({ loading, keyword, results }) => {
  if (loading) {
    return <OverlayLoading />;
  }

  if (!results) {
    return (
      <div className={styles.message}>
        Please select your database region and search for a keyword
      </div>
    );
  }

  if (results.length === 0) {
    return (
      <div className={styles.message}>No results for keyword "{keyword}"</div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.header}`}>
        <div className={`${styles.cell} ${styles.sm}`}>#</div>
        <div className={styles.cell}>Keyword</div>
        <div className={styles.cell}>Volume</div>
      </div>
      {results.map(({ keyword, searchVolume }, idx) => {
        return (
          <div key={`${keyword}${searchVolume}`} className={styles.row}>
            <div className={`${styles.cell} ${styles.sm}`}>{idx + 1}</div>
            <div className={styles.cell}>{keyword}</div>
            <div className={styles.cell}>{searchVolume}</div>
          </div>
        );
      })}
    </div>
  );
};

export default KeywordResults;
