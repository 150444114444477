import React, { useState } from "react";
import Tooltip from "../../../../../../../../../../components/Tooltip/Tooltip";
import { ModalType } from "../../../../../../../../../../modules/modal";
import Modal from "../../../../../../../../../../components/Modal";
import Icon from "../../../../../../../../../../components/Icon";
import {
  convertDateToLocal,
  formatDateString,
} from "../../../../../../../../../../utils/date";
import styles from "./AssignmentGroupRow.module.scss";
import { emailTemplates } from "../../../../../../../../../../modules/automatedReminders";

const AutomatedReminders = ({
  selectReminder,
  reminderType,
  createDate,
  email,
  projectId,
  assignmentGroupId,
  createManualReminder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const test = createDate && convertDateToLocal(formatDateString(createDate));

  const update = reminderType
    ? `Last email was sent ${emailTemplates[reminderType]} on ${test} to ${email}`
    : "No automated reminders have been sent yet";

  const handleSelected = (e) => {
    e.preventDefault();
    selectReminder(assignmentGroupId);
    setIsOpen(!isOpen);
  };

  const handleReminder = async () => {
    await createManualReminder(assignmentGroupId, projectId);
    setIsOpen(!isOpen);
  };

  return (
    <div onClick={handleSelected}>
      <Tooltip content={update}>
        <Icon name="Notifications" size="lg" />
      </Tooltip>
      {isOpen && (
        <Modal
          title="Automated Reminders"
          type={ModalType.EmailLogs}
          body={
            <div>
              <div className={styles.reminderText}>{update}</div>
              <button
                className={styles.manualReminder}
                onClick={handleReminder}
              >
                Send
              </button>
            </div>
          }
        />
      )}
    </div>
  );
};

export default AutomatedReminders;
