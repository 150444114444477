import React, { useCallback } from "react";
import { MultiSelect } from "react-multi-select-component";
import styles from "./index.module.scss";
import classNames from "classnames";

const ReduxMultiSelect = ({
  input: { value, onChange },
  options,
  copyProject,
}) => {
  const onSelected = useCallback(
    (values) => {
      onChange(values);
    },
    [onChange]
  );

  // take a list of redux form label/values and return just the label
  const valueRenderer = useCallback(
    (values) => {
      if (options.length === values.length) return "All";

      return values.map((v) => v.label).join(", ");
    },
    [options.length]
  );

  const multiSelectStyles = classNames({
    [styles.tfRateBands]: !copyProject,
  });

  return (
    <MultiSelect
      className={multiSelectStyles}
      options={options}
      value={value || []}
      onChange={onSelected}
      disableSearch
      ClearSelectedIcon
      valueRenderer={valueRenderer}
      labelledBy={""}
    />
  );
};

export default ReduxMultiSelect;
