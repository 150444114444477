import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import useHover from "../../../../hooks/useHover";
import Tooltip from "../Tooltip";
import { connect, useDispatch } from "react-redux";
import { change } from "redux-form";

const AllocationNumber = ({
  reassignment,
  active,
  stageId,
  featureToggles,
}) => {
  const [hoverRef, isHovered] = useHover();
  const dispatch = useDispatch();

  const showAllocationScaling =
    !!featureToggles.allocationScaling && !reassignment;

  const toggleActive = () => {
    dispatch(
      change("assignmentsForm", `assistedDistributions[${stageId}]`, !active)
    );
  };

  const svgStyles = classNames({
    [styles.numberSvg]: true,
    [styles.active]: active,
  });
  const numberContainerStyles = classNames({
    [styles.numberContainer]: true,
    [styles.hoverStyle]: showAllocationScaling,
  });

  return (
    <div ref={hoverRef} className={styles.container} onClick={toggleActive}>
      <div className={numberContainerStyles}>
        #
        {showAllocationScaling && (
          <svg className={svgStyles} viewBox="0 -256 1792 1792">
            <path d="M1145.492 645.424q0-106-75-181t-181-75q-106 0-181 75t-75 181q0 106 75 181t181 75q106 0 181-75t75-181zm512-109v222q0 12-8 23t-20 13l-185 28q-19 54-39 91 35 50 107 138 10 12 10 25t-9 23q-27 37-99 108t-94 71q-12 0-26-9l-138-108q-44 23-91 38-16 136-29 186-7 28-36 28h-222q-14 0-24.5-8.5t-11.5-21.5l-28-184q-49-16-90-37l-141 107q-10 9-25 9-14 0-25-11-126-114-165-168-7-10-7-23 0-12 8-23 15-21 51-66.5t54-70.5q-27-50-41-99l-183-27q-13-2-21-12.5t-8-23.5v-222q0-12 8-23t19-13l186-28q14-46 39-92-40-57-107-138-10-12-10-24 0-10 9-23 26-36 98.5-107.5t94.5-71.5q13 0 26 10l138 107q44-23 91-38 16-136 29-186 7-28 36-28h222q14 0 24.5 8.5t11.5 21.5l28 184q49 16 90 37l142-107q9-9 24-9 13 0 25 10 129 119 165 170 7 8 7 22 0 12-8 23-15 21-51 66.5t-54 70.5q26 50 41 98l183 28q13 2 21 12.5t8 23.5z" />
          </svg>
        )}
      </div>

      {showAllocationScaling && isHovered && (
        <Tooltip>
          {active
            ? "Turn off assisted distribution"
            : "Activate assisted distribution"}
        </Tooltip>
      )}
    </div>
  );
};

const mapStateToProps = (state, { stageId }) => {
  const formValues = state.form.assignmentsForm?.values;
  const active = formValues?.assistedDistributions?.[stageId];

  return {
    active,
  };
};

export default connect(mapStateToProps)(AllocationNumber);
