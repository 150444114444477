import { connect } from "react-redux";
import WithData from "../../../../../../../decorators/WithData";
import { showModal, hideModal } from "../../../../../../../modules/modal";
import { archivePerson } from "../../../../../../../modules/people";
import { personById } from "../../../../../../../utils/entitySelector";
import PeopleShow from "../components/PeopleShow";

const mapDispatchToProps = (dispatch, ownProps) => {
  const personId = Number(ownProps.match.params.personId);

  return {
    archivePerson: () => dispatch(archivePerson(personId, ownProps.history)),
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
  };
};

const mapStateToProps = (state, { match: { params } }) => {
  const personId = Number(params.personId);
  const { fullName, personType, archived } = personById(state, personId);
  const { modal, featureToggles } = state;

  return {
    featureToggles,
    fullName,
    modal,
    personId,
    personType,
    archived,
  };
};

const PeopleShowWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(PeopleShow));
export default PeopleShowWithData;
