import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./DeliverableLinks.module.scss";
import {
  getLoggedInUserType,
  stageById,
} from "../../../../../../../../utils/entitySelector";
import { PersonType } from "../../../../../../../../modules/people";
import { StageType } from "../../../../../../../../modules/stages";

const AdminLinks = ({ deliverableLinks }) => (
  <>
    <table className={styles.deliverablesTable}>
      <thead>
        <tr>
          <th className={styles.header}>Deliverable ID</th>
          <th className={styles.header}>Content creator name</th>
          <th className={styles.header}>Language</th>
          <th className={styles.header}>Stage</th>
          <th className={styles.header}>URL</th>
        </tr>
      </thead>
      <tbody>
        {deliverableLinks.map(
          ({ freelancerName, deliverableId, languageName, stageName, url }) => [
            <tr>
              <td
                key={`deliverable-id-${deliverableId}`}
                className={styles.cell}
              >
                {deliverableId}
              </td>
              <td key={`freelancer-${freelancerName}`} className={styles.cell}>
                {freelancerName}
              </td>
              <td key={`language-${languageName}`} className={styles.cell}>
                {languageName}
              </td>
              <td key={`stage-${stageName}`} className={styles.cell}>
                {stageName}
              </td>
              <td key={`url-${url}`} className={styles.cell}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </td>
            </tr>,
          ]
        )}
      </tbody>
    </table>
  </>
);

const ClientDeliverableRow = ({
  projectId,
  batchId,
  languageName,
  stageId,
}) => {
  const { isFinal, stageName, stageType } = useSelector((state) =>
    stageById(state, stageId)
  );
  const isProduction = [StageType.Production, StageType.Traning].includes(
    stageType
  );

  const baseUrl = `/clients/projects/${projectId}`;
  const { url, text } = isFinal
    ? {
        url: `${baseUrl}/completed?batchId=${batchId}`,
        text: "View completed deliverables",
      }
    : {
        url: `${baseUrl}?batchId=${batchId}`,
        text: "View in progress deliverables",
      };

  return (
    <>
      <div className={styles.cell}>{languageName}</div>
      <div className={styles.cell}>
        {isProduction ? "Production" : stageName}
      </div>
      <div className={styles.cell}>
        {isProduction ? "N/A" : <Link to={url}>{text}</Link>}
      </div>
    </>
  );
};

const ClientLinks = ({ deliverableLinks, batchId, projectId }) => (
  <>
    <div className={styles.header}>Language</div>
    <div className={styles.header}>Current stage</div>
    <div className={styles.header}>Batch URLs</div>

    {deliverableLinks.map(({ deliverableId, languageName, stageId }) => (
      <ClientDeliverableRow
        key={deliverableId}
        languageName={languageName}
        stageId={stageId}
        batchId={batchId}
        projectId={projectId}
      />
    ))}
  </>
);

const DeliverableLinks = ({ deliverableLinks = [], projectId, batchId }) => {
  const personType = useSelector(getLoggedInUserType);

  if (personType === PersonType.Client) {
    return (
      <div className={`${styles.deliverableLinks} ${styles.client}`}>
        <ClientLinks
          projectId={projectId}
          batchId={batchId}
          deliverableLinks={deliverableLinks}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.deliverableLinks} ${styles.admin}`}>
      <AdminLinks deliverableLinks={deliverableLinks} />
    </div>
  );
};

DeliverableLinks.propTypes = {
  deliverableLinks: PropTypes.arrayOf(
    PropTypes.shape({
      deliverableId: PropTypes.number.isRequired,
      freelancerName: PropTypes.string.isRequired,
      languageName: PropTypes.string.isRequired,
      stageName: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default DeliverableLinks;
