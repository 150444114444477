import { connect } from "react-redux";
import { createSelector } from "reselect";
import Transitions from "../components/Transitions";
import { isLoggedInUserAdmin } from "../../../utils/entitySelector";
import { AI_STAGES } from "../../../modules/stages";

const orderTransitions = createSelector(
  (transitions) => transitions,
  (transitions) =>
    transitions.sort((a, b) => a.forwardTransition - b.forwardTransition)
);

const mapStateToProps = (state, ownProps) => {
  const {
    featureToggles: { aiRevert },
    stages: { entities: stages },
  } = state;
  const orderedTransitions = orderTransitions(ownProps.transitions);
  const isAdmin = isLoggedInUserAdmin(state);

  const { toStageId } =
    orderedTransitions.find(
      ({ isForwardTransition }) => !isForwardTransition
    ) || {};

  const aiStageId =
    AI_STAGES.includes(stages[toStageId]?.stageName) && toStageId;

  return {
    isAdmin,
    orderedTransitions,
    aiStageId,
    aiRevert,
  };
};

const TransitionsWithFeatureToggles = connect(mapStateToProps)(Transitions);
export default TransitionsWithFeatureToggles;
