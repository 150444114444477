import React from "react";
import styles from "./AttachmentThumbnails.module.scss";

const AttachmentThumbnails = ({ attachments = [] }) => {
  const attachmentImages = attachments.map(({ attachmentUrl, fileName }, i) => {
    return (
      <a
        key={i}
        className={styles.attachmentImage}
        href={attachmentUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={attachmentUrl} alt={fileName} width="35" height="auto" />
      </a>
    );
  });

  return (
    <div>
      <h4>Attachments:</h4>
      {attachments.length > 0 ? (
        attachmentImages
      ) : (
        <div className={styles.noAttachments}>
          There are no attachments for this parent deliverable
        </div>
      )}
    </div>
  );
};

export default AttachmentThumbnails;
