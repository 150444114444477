import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../../../../../Modal";
import styles from "./TransitionHistory.module.scss";

class TransitionHistory extends Component {
  componentDidMount() {
    this.props.showModal();
  }

  closeModal = () => {
    this.props.toggleTransitionHistory();
    this.props.hideModal();
  };

  render() {
    const { transitionHistory } = this.props;
    const tableHeaders = (
      <tr>
        <th>First name</th>
        <th>Last name</th>
        <th>From stage</th>
        <th>To stage</th>
        <th>Create date</th>
      </tr>
    );

    const tableRow = transitionHistory.map(
      (
        {
          transitionLogId,
          firstName,
          lastName,
          fromStage,
          toStage,
          createDate,
        },
        i
      ) => {
        return (
          <tr key={transitionLogId}>
            <td>{firstName}</td>
            <td>{lastName}</td>
            <td>{fromStage}</td>
            <td>{toStage}</td>
            <td className={styles.noWrap}>{createDate}</td>
          </tr>
        );
      }
    );

    return (
      <Modal
        title={`Transition History`}
        onClickCallback={this.props.hideModal}
        wide
        body={
          transitionHistory.length ? (
            <table>
              <tbody>
                {tableHeaders}
                {tableRow}
              </tbody>
            </table>
          ) : (
            "No transition history"
          )
        }
        footer={
          <button className={styles.modalCancel} onClick={this.closeModal}>
            Close
          </button>
        }
      />
    );
  }
}

TransitionHistory.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  toggleTransitionHistory: PropTypes.func.isRequired,
  transitionHistory: PropTypes.array.isRequired,
};

export default TransitionHistory;
