import { connect } from "react-redux";
import gql from "graphql-tag";
import { pick } from "lodash";
import { getGraphQL } from "../../../../utils/fetch";
import WithData from "../../../decorators/WithData";
import ClientPageHeader from "./ClientPageHeader";
import { fetchProjectSuccess, projectFields } from "../../../modules/projects";
import {
  fetchOrderFormSuccess,
  orderFormFields,
} from "../../../modules/orderForms";
import { fetchAccountSuccess, accountFields } from "../../../modules/accounts";
import {
  projectById,
  accountById,
  orderFormById,
} from "../../../utils/entitySelector";

const query = gql`
  query ClientPageHeader_getInitialData($projectId: String) {
    project(projectId: $projectId) {
      ${projectFields}

      orderForm {
        ${orderFormFields}

        account {
          ${accountFields}
        }
      }
    }
  }
`;

export function getInitialData(params) {
  return async (dispatch) => {
    const json = await getGraphQL(query, params);

    const project = pick(json.project, projectFields);
    const orderForm = pick(json.project.orderForm, orderFormFields);
    const account = pick(json.project.orderForm.account, accountFields);

    dispatch(fetchProjectSuccess(project));
    dispatch(fetchOrderFormSuccess(orderForm));
    dispatch(fetchAccountSuccess(account));
  };
}

const mapDispatchToProps = (dispatch, { projectId }) => ({
  getInitialData: () => dispatch(getInitialData({ projectId })),
});

const mapStateToProps = (state, { projectId }) => {
  const { projectName, orderFormId } = projectById(state, projectId);
  const { orderFormName, accountId } = orderFormById(state, orderFormId);
  const { accountName } = accountById(state, accountId);

  return {
    accountName,
    projectName,
    orderFormName,
  };
};

const ConnectedClientPageHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ClientPageHeader));
ConnectedClientPageHeader.getInitialData = getInitialData;

export default ConnectedClientPageHeader;
