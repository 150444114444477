import { getGraphQL } from "../../../../../../../../../utils/fetch";
import {
  assignmentsByAssignmentGroupQuery,
  nextAndPriorAssignmentsByAssignmentGroupQuery,
  fetchAssignmentsSuccess,
} from "../../../../../../../../modules/assignments";
import {
  assignmentGroupQuery,
  fetchAssignmentGroupSuccess,
} from "../../../../../../../../modules/assignmentGroups";
import {
  parentDeliverablesByAssignmentGroupQuery,
  fetchParentDeliverablesSuccess,
} from "../../../../../../../../modules/parentDeliverables";
import {
  deliverablesByAssignmentGroupQuery,
  fetchDeliverablesSuccess,
} from "../../../../../../../../modules/deliverables";
import {
  briefingFieldsByProjectIdQuery,
  fetchBriefingFieldsSuccess,
} from "../../../../../../../../modules/briefingFields";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../../modules/projects";
import {
  parentDeliverableBriefingFieldsByAssignmentGroupQuery,
  fetchParentDeliverableBriefingFieldsSuccess,
} from "../../../../../../../../modules/parentDeliverableBriefingFields";
import {
  peopleByProjectAssignmentsQuery,
  fetchPeopleSuccess,
} from "../../../../../../../../modules/people";

export const initialQuery = `query ProjectAssignmentsList (
  $projectId: String, $assignmentGroupId: String, $includeArchivedAssignments: Boolean) {
  ${projectQuery}
  ${assignmentsByAssignmentGroupQuery}
  ${nextAndPriorAssignmentsByAssignmentGroupQuery}
  ${assignmentGroupQuery}
  ${parentDeliverablesByAssignmentGroupQuery}
  ${deliverablesByAssignmentGroupQuery}
  ${parentDeliverableBriefingFieldsByAssignmentGroupQuery}
  ${briefingFieldsByProjectIdQuery}
  ${peopleByProjectAssignmentsQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchAssignmentsSuccess(json.assignments));
      dispatch(fetchBriefingFieldsSuccess(json.briefingFields));
      dispatch(fetchParentDeliverablesSuccess(json.parentDeliverables));
      dispatch(
        fetchParentDeliverableBriefingFieldsSuccess(
          json.parentDeliverableBriefingFields
        )
      );
      dispatch(fetchDeliverablesSuccess(json.deliverables));
      dispatch(fetchAssignmentsSuccess(json.assignments));
      dispatch(fetchAssignmentsSuccess(json.nextAndPriorAssignments));
      dispatch(fetchAssignmentGroupSuccess(json.assignmentGroup));
      dispatch(fetchPeopleSuccess(json.peopleByAssignment));
    });
  };
}
