import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { Provider } from "react-redux";
import AppContainer from "../utils/AppContainer";
import Messages from "../components/Messages/v2";
import { ApolloProvider } from "@apollo/client";
import { createClient } from "./apolloClient";
import { Auth0Provider } from "@auth0/auth0-react";

const render = async (routes, store) => {
  const client = await createClient();

  ReactDOM.render(
    <AppContainer>
      <Auth0Provider
        domain={
          window.__REACT_APP_AUTH0_DOMAIN__ ||
          process.env.REACT_APP_AUTH0_DOMAIN
        }
        clientId={
          window.__REACT_APP_AUTH0_CLIENT_ID__ ||
          process.env.REACT_APP_AUTH0_CLIENT_ID
        }
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <ApolloProvider client={client}>
          <Provider store={store}>
            <Messages />
            <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
          </Provider>
        </ApolloProvider>
      </Auth0Provider>
    </AppContainer>,
    document.getElementById("root")
  );
};

export default render;
