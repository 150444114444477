export const resetFetch = (data) => {
  return (dispatch, getState) => {
    return fetch(`${window.__API_GATEWAY__}/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };
};
