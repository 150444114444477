import { checkValidations, isNotBlank } from "../../../../../utils/validations";

const validate = (values) => {
  const errors = {};
  const parentAccountNameErrors = checkValidations(values.parentAccountName, [
    { validate: isNotBlank, msg: "Required" },
  ]);

  if (parentAccountNameErrors)
    errors.parentAccountName = parentAccountNameErrors;

  return errors;
};

export default validate;
