import React, { Component } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "../../../../../../components/Tabs";
import styles from "./PeopleList.module.scss";
import PageHeader from "../../../../../../components/PageHeader";
import { setLSItem, getLSItem } from "../../../../../../utils/localStorage";
import NativeLanguageSelector from "../../../../../../components/NativeLanguageSelector";
import RoleSelector from "../../../../../../components/RoleSelector";
import { createSelector } from "reselect";
import PersonTypeDropdown from "./PersonTypeDropdown";
import Icon from "../../../../../../components/Icon";

export const TABS_CONFIG = [
  {
    personType: "Freelancer",
    route: "freelancers",
    label: "Content Creator",
    hasFilters: true,
  },
  { personType: "Client", route: "client", label: "Client" },
  { personType: "In-house", route: "in-house", label: "In-house" },
  {
    personType: "Archived",
    route: "archived",
    label: "Archived",
    isArchived: true,
  },
];

const ROLE_KEY = "admin-people-role-filter";
const LANGUAGE_KEY = "admin-people-language-filter";
const ARCHIVED_PEOPLE_TYPE_KEY = "archive-people-type-filter";

const normalizeSearch = (term) => {
  return term
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

class PeopleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      language: null,
      role: null,
      archivedPersonType: null,
    };
    this.baseUrl = "/admin/people";
  }

  /**
   * @type {string}
   */
  get activeRoute() {
    return this.props.match.params.activeTab;
  }

  handleSelectArchivedPersonType = (archivedPersonType) => {
    this.setState({ archivedPersonType });
    setLSItem(ARCHIVED_PEOPLE_TYPE_KEY, archivedPersonType);
  };

  componentDidMount() {
    this.changeTab(this.activeRoute);
  }

  changeTab = (activeTab) => {
    if (activeTab === "freelancers") {
      this.setState({
        activeTab,
        language: getLSItem(LANGUAGE_KEY),
        role: getLSItem(ROLE_KEY),
        archivedPersonType: null,
      });
    } else if (activeTab === "archived") {
      this.setState({
        activeTab,
        language: null,
        role: null,
        archivedPersonType: getLSItem(ARCHIVED_PEOPLE_TYPE_KEY),
      });
    } else {
      this.setState({
        activeTab,
        language: null,
        role: null,
        archivedPersonType: null,
      });
    }
    // Store the active tab for coming back to this page
    setLSItem("admin-people-active-tab", activeTab);
  };

  search = (e) => {
    const search = normalizeSearch(e.currentTarget.value);
    this.setState({ search });
  };

  link = (e) => {
    const personId = e.currentTarget.getAttribute("data-id");
    this.props.history.push(`${this.baseUrl}/${personId}`);
  };

  peopleSelector = createSelector(
    (people) => people,
    (_a, activePersonType) => activePersonType,
    (_a, _b, role) => role,
    (_a, _b, _c, language) => language,
    (_a, _b, _c, _d, search) => search,
    (_a, _b, _c, _d, _e, dropDownPersonType) => dropDownPersonType,
    (people, activePersonType, role, language, search, dropDownPersonType) => {
      return people.filter(
        ({ firstName, lastName, personType, languages, roles, archived }) => {
          const { personType: mappedPersonType } =
            TABS_CONFIG.find(({ label }) => label === dropDownPersonType) || {};
          if (
            normalizeSearch(`${firstName} ${lastName}`).indexOf(search) === -1
          ) {
            return false;
          }

          if (activePersonType === "Archived") {
            // If in the Archived tab, check the dropdown person type if it's selected
            if (dropDownPersonType && personType !== mappedPersonType) {
              // this will be content creator
              return false;
            }
            return archived;
          } else if (personType !== activePersonType) {
            return false;
          }

          if (archived) {
            return false;
          }

          if (role && !roles.includes(role)) {
            return false;
          }

          if (
            language &&
            languages.length > 0 &&
            !languages.includes(language)
          ) {
            return false;
          }
          return true;
        }
      );
    }
  );

  render() {
    const { personType: activePersonType } =
      TABS_CONFIG.find(({ route }) => route === this.activeRoute) ||
      "freelancers";

    const isFreelancer = this.activeRoute === "freelancers";
    const archivedTab = this.activeRoute === "archived";

    const filteredPeople = this.peopleSelector(
      this.props.people,
      activePersonType,
      this.state.role,
      this.state.language,
      this.state.search,
      this.state.archivedPersonType
    );

    const baseUrl = `/admin/people/list/`;

    return (
      <div>
        <Helmet>
          <title>List people</title>
        </Helmet>

        <div>
          <PageHeader title={"People"}>
            <div className={styles.headerRight}>
              <div className={styles.searchContainer}>
                <input
                  className={styles.searchInput}
                  onChange={this.search}
                  placeholder={"Search"}
                />
                <Icon
                  name="SearchIcon"
                  className={styles.searchIcon}
                  size="md"
                />
              </div>
              <Link to="/admin/people/new" className={styles.button}>
                New person
              </Link>
            </div>
          </PageHeader>

          <Tabs
            baseUrl={baseUrl}
            activeTab={this.activeRoute}
            onClick={this.changeTab}
            items={TABS_CONFIG.map(({ route, label }) => ({
              to: `${baseUrl}${route}`,
              name: route,
              label,
            }))}
          />

          <table className={styles.table}>
            {this.props.featureToggles.QCC_1072_peopleFilter ? (
              <thead>
                <tr>
                  <th key={0}>Name</th>
                  {archivedTab && [
                    <th key={0}>
                      <PersonTypeDropdown
                        title={this.state.archivedPersonType || "Person type"}
                        selectPersonType={this.handleSelectArchivedPersonType}
                      />
                    </th>,
                  ]}
                  {isFreelancer && [
                    <th key={1}>
                      <RoleSelector
                        title={this.state.role || "All roles"}
                        selectRole={(role) => {
                          this.setState({ role });
                          setLSItem(ROLE_KEY, role);
                        }}
                      />
                    </th>,
                    <th key={2}>
                      <NativeLanguageSelector
                        title={this.state.language || "All languages"}
                        selectLanguage={(language) => {
                          this.setState({ language });
                          setLSItem(LANGUAGE_KEY, language);
                        }}
                      />
                    </th>,
                  ]}
                </tr>
              </thead>
            ) : (
              <thead>
                <tr>
                  <th key={0}>Name</th>
                  {archivedTab && [<th key={1}>Person Type</th>]}
                  {isFreelancer && [
                    <th key={1}>Languages</th>,
                    <th key={2}>Roles</th>,
                  ]}
                </tr>
              </thead>
            )}

            <tbody>
              {filteredPeople.map(
                ({ personId, fullName, roles, languages, personType }) => (
                  <tr data-id={personId} key={personId} onClick={this.link}>
                    <td>{`${fullName}`}</td>
                    {archivedTab && [
                      <td>
                        {TABS_CONFIG.find(
                          (tab) => tab.personType === personType
                        )?.label || personType}
                      </td>,
                    ]}
                    {isFreelancer && [
                      <td>{roles && roles.join(", ")}</td>,
                      <td>{languages && languages.join(", ")}</td>,
                    ]}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

PeopleList.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      personId: PropTypes.number.isRequired,
      fullName: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      languages: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  featureToggles: PropTypes.object.isRequired,
};

export default PeopleList;
