import { getGraphQL } from "../../../../utils/fetch";
import {
  taskFieldContentByAssignmentGroupQuery,
  fetchTaskFieldContentSuccess,
} from "../../../modules/taskFieldContent";
import {
  deliverablesByAssignmentGroupQuery,
  fetchDeliverablesSuccess,
} from "../../../modules/deliverables";
import {
  assignmentsByAssignmentGroupQuery,
  fetchAssignmentsSuccess,
} from "../../../modules/assignments";
import {
  taskFieldsByAssignmentGroupQuery,
  fetchTaskFieldsSuccess,
} from "../../../modules/taskFields";
import { webSpellCheckerSuccess } from "../../../modules/webSpellChecker";
import { getLoggedInUser } from "../../../utils/entitySelector";
import { PersonType } from "../../../modules/people";

export const checkGrammarQuery = `webSpellChecker (deliverableIds: $deliverableIds) {
  grammarCheckId, deliverableId, taskFieldId, rawGrammarCheck, createDate, rawContent
}`;

export const deliverablesQuery = `query deliverablesByAssignmentGroup($assignmentGroupId: String, $includeArchivedAssignments: Boolean) {
  ${taskFieldsByAssignmentGroupQuery}
  ${assignmentsByAssignmentGroupQuery}
  ${taskFieldContentByAssignmentGroupQuery}
  ${deliverablesByAssignmentGroupQuery}
}`;

export const grammarQuery = `query grammarCheckByDeliverableIdsQuery ($deliverableIds: String) {
  ${checkGrammarQuery}
}`;

export function getInitialData({ match: { params } }, url, cookies) {
  return async (dispatch, getState) => {
    const {
      featureToggles: { adminGrammarCheck, freelancerGrammarCheck },
    } = getState();
    const { personType } = getLoggedInUser(getState());
    const allowGrammarCheck =
      (personType === PersonType.Admin && adminGrammarCheck) ||
      (personType === PersonType.Freelancer && freelancerGrammarCheck);
    const { taskFieldContent, deliverables, assignments, taskFields } =
      await getGraphQL(deliverablesQuery, params, url, cookies);
    dispatch(fetchAssignmentsSuccess(assignments));
    dispatch(fetchTaskFieldsSuccess(taskFields));
    dispatch(fetchTaskFieldContentSuccess(taskFieldContent));
    dispatch(fetchDeliverablesSuccess(deliverables));
    const deliverableIds = deliverables.map(
      ({ deliverableId }) => deliverableId
    );
    if (allowGrammarCheck) {
      const { webSpellChecker } = await getGraphQL(
        grammarQuery,
        { deliverableIds },
        url,
        cookies
      );
      const grammarCheck = webSpellChecker.map(
        ({
          deliverableId,
          taskFieldId,
          rawGrammarCheck,
          createDate,
          rawContent,
        }) => {
          return {
            cellId: `${taskFieldId}-${deliverableId}`,
            deliverableId,
            taskFieldId,
            createDate,
            rawContent,
            corrections: JSON.parse(rawGrammarCheck),
          };
        }
      );
      dispatch(webSpellCheckerSuccess(grammarCheck));
    }
  };
}
