import ParentAccountsList from "./containers/ParentAccountsList";
import {
  parentAccountActionHandlers,
  parentAccountInitialState,
} from "../../../modules/parentAccounts";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../modules/accounts";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../modules/orderForms";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../modules/projects";

export default {
  Component: ParentAccountsList,
  reducers: [
    {
      key: "parentAccounts",
      actionHandlers: parentAccountActionHandlers,
      initialState: parentAccountInitialState,
    },
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
  ],
};
