import { createAction } from "redux-actions";
import handleErrors from "../utils/handleErrors";
import { postGraphQL } from "../../utils/fetch";
import { upsertData } from "../utils/normalize";
import { updateAssignmentsSuccess } from "./assignments";
import { updateDeliverablesSuccess } from "./deliverables";
import { updateCommentGroupsSuccess } from "./commentGroups";
import {
  startRequest,
  endRequest,
  TRANSITION_REQUEST,
} from "../modules/requestTracker";
import { RESET_INITIAL_STATE } from "./me";
import { createPlagiarismMessage } from "./plagiarismCheck/notifications";
import { showSuccessMessage } from "./messagesV2";

export const ResponseTypes = {
  PLAGIARISM_CHECK_INITIATED: "PLAGIARISM_CHECK_INITIATED",
  TRANSITION_LOGS_CREATED: "TRANSITION_LOGS_CREATED",
};

// ------------------------------------
// GraphQL Queries
// ------------------------------------
const transitionLogFields =
  "transitionLogId, personId, transitionId, deliverableId, createDate";

export const transitionLogsByAssignmentGroupQuery = `transitionLogs (assignmentGroupId: $assignmentGroupId) {
  ${transitionLogFields}
}`;

export const transitionLogsByAssignmentQuery = `transitionLogs (assignmentId: $assignmentId) {
  ${transitionLogFields}
}`;

export const transitionLogsByDeliverableIdQuery = `transitionLogs (deliverableIds: $deliverableId) {
  ${transitionLogFields}
}`;

export const transitionLogsByBatchIdQuery = `transitionLogs (batchId: $batchId) {
  ${transitionLogFields}
}`;

const createTransitionLogsResponseFields = `
  assignments { status, actionable },
  deliverables { currentStageId },
  transitionLogs { transitionLogId, personId, transitionId, deliverableId },
  commentGroups { cleared }

  deliverableIds
  meta {
    eventType
  }
`;

export const createTransitionLogsV2 = `
  createTransitionLogsV2(transitionLogs: $transitionLogs) {
    ${createTransitionLogsResponseFields}
  }
`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_TRANSITION_LOGS_SUCCESS = "FETCH_TRANSITION_LOGS_SUCCESS";
export const CREATE_TRANSITION_LOGS_SUCCESS = "CREATE_TRANSITION_LOGS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchTransitionLogsSuccess = createAction(
  FETCH_TRANSITION_LOGS_SUCCESS
);
export const createTransitionLogsSuccess = createAction(
  CREATE_TRANSITION_LOGS_SUCCESS
);

export function createTransitionLogsFromAssignments(transition, selectedItems) {
  return (dispatch, getState) => {
    const { assignments, me } = getState();

    const transitionLogs = selectedItems.map((assignmentId) => {
      const assignment = assignments.entities[assignmentId];
      return {
        deliverableId: assignment.deliverableId,
        transitionId: transition.transitionId,
        personId: me,
      };
    });

    return dispatch(createTransitionLogs(transitionLogs));
  };
}

export function createTransitionLogsFromDeliverables(
  transition,
  deliverableIds
) {
  return (dispatch, getState) => {
    const { me } = getState();

    const transitionLogs = deliverableIds.map((deliverableId) => ({
      deliverableId,
      transitionId: transition.transitionId,
      personId: me,
    }));

    return dispatch(createTransitionLogs(transitionLogs));
  };
}

const CREATE_TRANSITION_LOGS = `
  mutation createTransitionLogs ($transitionLogs: [TransitionLogInput]) {
    ${createTransitionLogsV2}
  }
`;

function createTransitionLogs(transitionLogRequest) {
  return async (dispatch, getState) => {
    await dispatch(startRequest(TRANSITION_REQUEST));

    try {
      const json = await postGraphQL(
        CREATE_TRANSITION_LOGS,
        { transitionLogs: transitionLogRequest },
        "createTransitionLogsV2"
      );

      const {
        assignments: assignmentParams,
        deliverables: deliverableParams,
        commentGroups: commentGroupParams,
        transitionLogs,
        deliverableIds,
        meta: { eventType },
      } = json;

      if (eventType === ResponseTypes.PLAGIARISM_CHECK_INITIATED) {
        dispatch(showSuccessMessage(createPlagiarismMessage(deliverableIds)));
      } else if (eventType === ResponseTypes.TRANSITION_LOGS_CREATED) {
        const {
          transitions: { entities: transitionEntities },
          assignments: {
            entities: assignmentEntities,
            result: assignmentResult,
          },
          commentGroups: {
            entities: commentGroupEntities,
            result: commentGroupResult,
          },
        } = getState();

        const transition = transitionEntities[transitionLogs[0].transitionId];
        await dispatch(createTransitionLogsSuccess(transitionLogs));

        const updatedDeliverableParams = [];
        const updatedCommentGroupParams = [];
        const updatedAssignmentParams = [];

        const assignmentsByDeliverable = assignmentResult.reduce((acc, id) => {
          const assignment = assignmentEntities[id];
          const { deliverableId, stageId } = assignment;
          if (stageId !== transition.fromStageId) return acc;

          if (!acc[deliverableId]) acc[deliverableId] = [];
          acc[deliverableId].push(assignment);
          return acc;
        }, {});

        const commentGroupsByDeliverable = commentGroupResult.reduce(
          (acc, id) => {
            const commentGroup = commentGroupEntities[id];
            const { deliverableId, atStage } = commentGroup;
            if (atStage !== transition.clearStageId) return acc;

            if (!acc[deliverableId]) acc[deliverableId] = [];
            acc[deliverableId].push(commentGroup);
            return acc;
          },
          {}
        );

        transitionLogs.forEach(({ deliverableId }) => {
          updatedDeliverableParams.push({
            deliverableId,
            ...deliverableParams,
          });

          const commentGroupWithUpdatedParams = (
            commentGroupsByDeliverable[deliverableId] || []
          ).map((cg) => {
            return {
              ...cg,
              ...commentGroupParams,
            };
          });

          updatedCommentGroupParams.push(...commentGroupWithUpdatedParams);

          const assignmentsWithUpdatedParams = (
            assignmentsByDeliverable[deliverableId] || []
          ).map((a) => {
            return {
              ...a,
              ...assignmentParams,
            };
          });

          updatedAssignmentParams.push(...assignmentsWithUpdatedParams);
        });

        await Promise.all([
          dispatch(updateDeliverablesSuccess(updatedDeliverableParams)),
          dispatch(updateCommentGroupsSuccess(updatedCommentGroupParams)),
          dispatch(updateAssignmentsSuccess(updatedAssignmentParams)),
        ]);
      }
      return json;
    } catch (err) {
      handleErrors(err);
    } finally {
      await dispatch(endRequest(TRANSITION_REQUEST));
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
export const transitionLogsActionHandlers = {
  [RESET_INITIAL_STATE]: () => transitionLogsInitialState,
  [FETCH_TRANSITION_LOGS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "transitionLogId"),
  [CREATE_TRANSITION_LOGS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "transitionLogId"),
};

export const transitionLogsInitialState = { entities: {}, result: [] };
