import {
  isNotBlank,
  checkValidations,
} from "../../../../../../utils/validations";

const validate = (values) => {
  const errors = {};

  const personIdErrors = checkValidations(values.personId, [
    { validate: isNotBlank, msg: "Please select a freelancer" },
  ]);

  const monthErrors = checkValidations(values.month, [
    { validate: isNotBlank, msg: "Please select a month" },
  ]);

  if (personIdErrors) errors.personId = personIdErrors;
  if (monthErrors) errors.month = monthErrors;

  return errors;
};

export default validate;
