import { getGraphQL } from "../../../../../../utils/fetch";
import {
  paymentsByPaymentIdsQuery,
  fetchPaymentsSuccess,
} from "../../../../../modules/payments";
import {
  projectsByProjectIds,
  fetchProjectSuccess,
} from "../../../../../modules/projects";

export const initialQuery = `query PaymentsSearch ($paymentId: String) {
  ${paymentsByPaymentIdsQuery}
}`;

const projectQuery = `query fetchProjectsbyId ($projectIds: String) {
  ${projectsByProjectIds}
}`;

export function getInitialData({ paymentId }, url, cookies) {
  return async (dispatch, getState) => {
    const { payments } = await getGraphQL(
      initialQuery,
      { paymentId },
      url,
      cookies
    );
    dispatch(fetchPaymentsSuccess(payments));
    if (payments) {
      const projectIds = payments.reduce((acc, { projectId }) => {
        if (!acc.includes(projectId)) acc.push(projectId);
        return acc;
      }, []);
      const { projects } = await getGraphQL(
        projectQuery,
        { projectIds },
        url,
        cookies
      );
      dispatch(fetchProjectSuccess(projects));
    }
  };
}
