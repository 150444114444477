import React from "react";
import PropTypes from "prop-types";
import PersonForm from "../../../../../components/PersonForm";
import Helmet from "react-helmet";
import PageHeader from "../../../../../components/PageHeader";
import { getLSItem } from "../../../../../utils/localStorage";

const getPreviouslyAccessedTabUrl = () => {
  const baseUrl = "/admin/people/list";

  if (typeof window !== "undefined" && window.localStorage) {
    const activeTab = getLSItem("admin-people-active-tab");

    // if we have an active tab then render the link as that
    if (activeTab) {
      return `${baseUrl}/${activeTab}`;
    }
  }

  // if no tab in storage, just return to freelancer page
  return `${baseUrl}/freelancers`;
};

export const PeopleNew = (props) => {
  const previousTabUrl = getPreviouslyAccessedTabUrl();

  return (
    <div>
      <Helmet>
        {" "}
        <title>New person</title>{" "}
      </Helmet>
      <PageHeader
        breadCrumbItems={[
          { url: previousTabUrl, text: "People" },
          { text: "New person" },
        ]}
        title={"New person"}
      />
      <PersonForm
        accounts={props.accounts}
        cancelUrl={previousTabUrl}
        editMode={false}
        languages={props.languages}
        onSubmit={props.createPerson}
        searchAccounts={props.searchAccounts}
      />
    </div>
  );
};

PeopleNew.propTypes = {
  accounts: PropTypes.array.isRequired,
  createPerson: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  searchAccounts: PropTypes.func.isRequired,
};

export default PeopleNew;
