import PropTypes from "prop-types";
import styles from "./List.module.scss";
import Helmet from "react-helmet";
import BriefingFieldContainer from "../../../../../../../components/BriefingFieldContainer";
import PageHeader from "../../../../../../../components/PageHeader";
import ProjectInfo from "../../../../../../../components/PageHeader/ProjectInfo";
import Errors from "../../../../../../../components/Errors";
import OverlayLoading from "../../../../../../../components/OverlayLoading/v1";
import QualityGradingModal from "../../../../../../../components/QualityGradingModal";
import { Redirect, useLocation } from "react-router";

const List = ({
  accountName,
  assignmentGroupId,
  asyncFetched,
  briefingFields,
  data,
  dataReady,
  errors,
  projectId,
  currencyCode,
  projectName,
  stage: { stageId, stageName },
  transitionAssignments,
  transitions,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const baseUrl = `/freelancer/projects/${projectId}/assignments/${assignmentGroupId}/tasks`;
  const targetPath = baseUrl.replace("/tasks", "");

  if (!dataReady && !asyncFetched) return <OverlayLoading />;

  if (currentPath !== targetPath) {
    return <Redirect to={targetPath} />;
  }

  return (
    <div>
      <Helmet>
        {" "}
        <title>Assignment List</title>{" "}
      </Helmet>
      {errors.display && <Errors />}
      <div className={styles.flex}>
        <PageHeader
          breadCrumbItems={[
            { url: "/freelancer", text: "Dashboard" },
            {
              text: `Assignment list (${stageName} | ${projectName} | ${accountName})`,
            },
          ]}
          title={"Assignment list"}
        >
          <div className={styles.infoContainer}>
            <ProjectInfo
              url={`/freelancer/projects/${projectId}/project-info`}
            />
          </div>
        </PageHeader>
      </div>

      <BriefingFieldContainer
        assignmentGroupId={assignmentGroupId}
        baseUrl={baseUrl}
        briefingFields={briefingFields}
        data={data}
        exportContent
        processMultipleTransitions={transitionAssignments}
        projectId={projectId}
        currencyCode={currencyCode}
        transitions={transitions}
      />

      <QualityGradingModal reviewerStageId={stageId} />
    </div>
  );
};

List.propTypes = {
  accountName: PropTypes.string.isRequired,
  assignmentGroupId: PropTypes.number.isRequired,
  asyncFetched: PropTypes.bool.isRequired,
  briefingFields: PropTypes.array,
  data: PropTypes.array,
  dataReady: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    display: PropTypes.bool.isRequired,
    msg: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired,
    ]),
  }),
  projectId: PropTypes.number,
  projectName: PropTypes.string,
  stage: PropTypes.shape({
    stageId: PropTypes.number,
    stageName: PropTypes.string,
  }).isRequired,
  transitionAssignments: PropTypes.func.isRequired,
  transitions: PropTypes.arrayOf(
    PropTypes.shape({
      fromStageId: PropTypes.number.isRequired,
      payStageId: PropTypes.number,
      toStageId: PropTypes.number.isRequired,
      transitionId: PropTypes.number.isRequired,
      transitionName: PropTypes.string.isRequired,
      workflowId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default List;
