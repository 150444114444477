import { checkValidations, isNotBlank } from "../../../../../utils/validations";

const validate = (values) => {
  const errors = {};
  const accountNameErrors = checkValidations(values.accountName, [
    { validate: isNotBlank, msg: "Required" },
  ]);

  if (accountNameErrors) errors.accountName = accountNameErrors;

  return errors;
};

export default validate;
