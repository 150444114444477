import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { QuillFormField } from "../../../../../../../../components/QuillForm";
import { SelectWithErrors } from "../../../../../../../../decorators/WithErrors";
import { itemToValueAndLabel } from "../../../../../../../../utils/entitySelector";
import styles from "./BatchForm.module.scss";
import DeliverableCount from "./DeliverableCount";

const BatchForm = (props) => {
  const {
    batches,
    change,
    deliverableIds,
    clearAssignmentsForm,
    projectLanguages,
    stages,
    defaultAssignees,
    languageCodes,
    selectedBatchId,
    maxNumParentDeliverables,
    unallocatedLanguages,
    isAiContentWorkflow,
    fetchDeliverablesByBatchId,
  } = props;

  useEffect(() => {
    if (selectedBatchId) {
      // By default, select all languages associated with the batch when it is changed.
      const languages = Object.keys(unallocatedLanguages).filter(
        (languageCode) => unallocatedLanguages[languageCode] > 0
      );
      change("projectLanguages", languages);
    }
  }, [selectedBatchId, unallocatedLanguages]);

  const languages = projectLanguages
    .filter((language) => unallocatedLanguages[language.languageCode] > 0)
    .map((language) => itemToValueAndLabel(language.languageCode));

  const handleBatchSelection = async (batchId) => {
    change("numParentDeliverables", null);
    change("projectLanguages", []);
    clearAssignmentsForm();
    await fetchDeliverablesByBatchId(batchId);
  };

  return (
    <div className={styles.formContainer}>
      <form>
        <div className={styles.labelLeft}>
          Which batch are you allocating from?
        </div>
        <QuillFormField
          clearable={false}
          component={SelectWithErrors}
          customContainerStyle={styles.inputRight}
          name="batchId"
          options={batches}
          onChange={handleBatchSelection}
        />
        <div>
          <div className={styles.labelLeft}>
            Which language are you allocating?
          </div>
          <QuillFormField
            clearable={false}
            component={SelectWithErrors}
            customContainerStyle={styles.inputRight}
            name="projectLanguages"
            onChange={() => {
              change("numParentDeliverables", null);
              clearAssignmentsForm();
            }}
            multi
            options={languages}
          />
        </div>

        <DeliverableCount
          languageCodes={languageCodes}
          deliverableIds={deliverableIds}
          stages={stages}
          selectedBatchId={selectedBatchId}
          defaultAssignees={defaultAssignees}
          maxNumParentDeliverables={maxNumParentDeliverables}
          isAiContentWorkflow={isAiContentWorkflow}
          change={change}
          unallocatedLanguages={unallocatedLanguages}
        />
      </form>
    </div>
  );
};

BatchForm.propTypes = {
  batches: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  clearAssignmentsForm: PropTypes.func.isRequired,
  deliverableIds: PropTypes.shape({
    allocated: PropTypes.object.isRequired,
    unallocated: PropTypes.object.isRequired,
  }).isRequired,
  featureToggles: PropTypes.object,
  isLocalisation: PropTypes.bool,
  maxNumParentDeliverables: PropTypes.number.isRequired,
  projectLanguages: PropTypes.array.isRequired,
  selectedBatchId: PropTypes.number,
  unallocatedLanguages: PropTypes.object,
};

export default reduxForm({
  form: "batchForm",
})(BatchForm);
