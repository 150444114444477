import React from "react";
import PropTypes from "prop-types";

import styles from "./TaskFieldsArray.module.scss";

import RenderFieldArray from "../_decorators/RenderFieldArray";
import FieldsWithFormat from "../FieldsWithFormat";
import { DragSource, DropTarget } from "react-dnd";
import DraggableHelpers from "../DraggableHelpers";
import flow from "lodash/flow";
import Icon from "../../../../../../../../components/Icon";
import NewFieldAddButton from "./../NewFieldAddButton/NewFieldAddButton";

const TaskLabel = (props) => (
  <div className={styles.container}>
    <label className={styles.quillHeader} htmlFor="task">
      Task Fields
    </label>
    <Icon
      name="EditSquare"
      className={styles.qualCheck}
      onClick={() => props.showModal()}
      size="lg"
    />
  </div>
);

TaskLabel.propTypes = {
  showModal: PropTypes.func.isRequired,
  addField: PropTypes.func.isRequired,
};

const TaskFields = (props) => {
  return (
    <FieldsWithFormat
      {...props}
      inputFieldName="taskFieldName"
      placeholder="Enter task field name, eg Product description, Meta description"
      selectFieldName="taskFieldFormat"
      type="taskFields"
    />
  );
};

const DraggableTaskFields = flow(
  DropTarget("taskFields", DraggableHelpers.dragTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  })),
  DragSource("taskFields", DraggableHelpers.dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }))
)(TaskFields);

const TaskFieldsArray = RenderFieldArray(
  TaskLabel,
  DraggableTaskFields,
  NewFieldAddButton
);

export default TaskFieldsArray;
