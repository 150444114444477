import { connect } from "react-redux";
import { hideMessages } from "../../../../modules/messages";
import Messages from "../components/Messages";

const mapDispatchToProps = (dispatch, ownProps) => ({
  hideMessages: () => dispatch(hideMessages()),
});

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
