import React from "react";
import styles from "./BatchFormInput.module.scss";
import { reduxForm } from "redux-form";
import classNames from "classnames";
import { InputWithErrors } from "../../../../../../../../../decorators/WithErrors/WithErrors";
import { TextAreaWithErrors } from "../../../../../../../../../decorators/WithErrors";
import { QuillFormField } from "../../../../../../../../../components/QuillForm";
import { currentISODate } from "../../../../../../../../../utils/date";
import validate from "../../validate";
import Icon from "../../../../../../../../../components/Icon";

type PropTypes = {
  error: string;
  handleSubmit: (e: any) => void;
  inputKey: string;
  inputName: string;
  pristine: boolean;
  setActiveField: (arg0: string) => void;
  submitSucceeded: boolean;
  type: string;
  onSubmit: any;
};

function BatchFormInputs(props: PropTypes) {
  const {
    handleSubmit,
    type,
    inputKey,
    setActiveField,
    pristine,
    error,
    submitSucceeded,
  } = props;

  const isTextArea = inputKey === "details";

  const inputStyles = classNames({
    [styles.input]: true,
    [styles.customAreaInput]: isTextArea,
  });

  const formStyles = classNames({
    [styles.newInputForm]: true,
    [styles.textAreaForm]: isTextArea,
  });

  const closeActiveField = (e) => {
    e.preventDefault();
    setActiveField("");
  };

  return (
    <form
      className={formStyles}
      onSubmit={pristine ? closeActiveField : handleSubmit}
    >
      <QuillFormField
        autoFocus
        component={isTextArea ? TextAreaWithErrors : InputWithErrors}
        customContainerStyle={styles.customInput}
        customErrorStyles={styles.batchEditError}
        max="9999-12-31T00:00"
        min={inputKey !== "receivedAt" && currentISODate()}
        name={inputKey}
        styles={inputStyles}
        type={type}
      />
      <button className={styles.confirm} type="submit">
        {!submitSucceeded ? (
          <Icon name="CheckMark" size="lg" fill="#DA0034" />
        ) : (
          <div className={styles.loader} />
        )}
      </button>
      <div className={styles.formUpdate}>
        {error && <div className={styles.errorContainer}>{error}</div>}
      </div>
    </form>
  );
}

// @ts-ignore
const BatchFormInputsForm: (props: PropTypes) => JSX.Element = reduxForm({
  // @ts-ignore
  validate,
  onSubmitSuccess: (_result, _dispatch, props: PropTypes) => {
    setTimeout(() => props.setActiveField(""), 500);
  },
  // @ts-ignore
})(BatchFormInputs);

export default BatchFormInputsForm;
