import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./DropdownV2.module.scss";
import useOutsideClick from "../../hooks/useOutsideClick";
import { getBounds } from "../../utils/dom";
import DownloadPopup from "./DownloadPopup";

interface DropdownV2Props {
  onToggle: any;
  containerStyles?: any;
  buttonStyles?: any;
  customIcon: any;
  title: any;
  shouldOpenUpwards: any;
  disabledTooltip?: string;
  children: any;
  disabled?: boolean;
}

const DropdownV2 = ({
  onToggle,
  containerStyles,
  buttonStyles,
  customIcon,
  title,
  shouldOpenUpwards,
  children,
  disabled,
  disabledTooltip,
}: DropdownV2Props) => {
  const [menu, showMenu] = useState(false);
  const node = useRef();

  const toggle = (toggleState) => {
    showMenu(toggleState);
    if (onToggle) onToggle(toggleState);
  };

  useOutsideClick(node, () => {
    toggle(false);
  });

  const toggleDropdown = () => {
    if (disabled) {
      return;
    }

    toggle(!menu);
  };

  const preventBubble = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (disabledTooltip) {
      showMenu(false);
    }
  }, [disabledTooltip]);

  const dropdownContainerStyles = classNames({
    [styles.dropdownContainer]: true,
    [styles.disabled]: disabled,
    [containerStyles]: containerStyles,
  });

  const dropdownStyles = classNames({
    [styles.dropdown]: true,
    [styles.dropdownBoxShadow]: menu,
    [styles.disabled]: !!disabledTooltip,
  });

  const dropdownButtonStyles = classNames({
    [styles.dropdownButton]: true,
    [styles.dropdownButtonBorder]: menu,
    [buttonStyles]: buttonStyles,
  });

  const dropdownMenuStyles = classNames({
    [styles.dropdownMenu]: true,
    [styles.alignRight]: true,
  });

  const coords = node.current ? getBounds(node.current) : {};

  return (
    <div className={dropdownContainerStyles} onClick={preventBubble}>
      {/* @ts-ignore */}
      <div ref={node} className={dropdownStyles} onClick={toggleDropdown}>
        {disabled && disabledTooltip && (
          <div className={styles.disabledTooltip}>{disabledTooltip}</div>
        )}
        {customIcon || (
          <div className={dropdownButtonStyles}>
            <span>{title}</span>
            <svg height="18" width="18">
              <path d="M3.5 6l4.5 4.5 4.5-4.5h-9z" fill="#fff" />
            </svg>
          </div>
        )}

        {menu && (
          <DownloadPopup
            coords={coords}
            shouldOpenUpwards={shouldOpenUpwards}
            dropdownMenuStyles={dropdownMenuStyles}
          >
            {children}
          </DownloadPopup>
        )}
      </div>
    </div>
  );
};

export default DropdownV2;
