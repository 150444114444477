import React from "react";
import * as Icons from "../../static/icons";
import classNames from "classnames";
import styles from "./Icon.module.scss";
function Icon(props) {
  const { name, size, className, ...otherProps } = props;
  const svgStyles = classNames({
    [styles.icon]: true, // sm can be default
    [styles.isMedium]: size === "md",
    [styles.isLarge]: size === "lg",
    [styles.isExtraLarge]: size === "xl",
  });

  const Component = Icons[name];
  return <Component className={svgStyles} {...otherProps} />;
}

export default Icon;
