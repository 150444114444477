import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../modules/projects";
import {
  briefingFieldsByProjectIdQuery,
  fetchBriefingFieldsSuccess,
} from "../../../../../../modules/briefingFields";
import {
  keywordGroupsByProjectIdQuery,
  fetchKeywordGroupsSuccess,
} from "../../../../../../modules/keywordGroups";
import {
  taskFieldsByProjectQuery,
  fetchTaskFieldsSuccess,
} from "../../../../../../modules/taskFields";
import {
  rateBandsByProjectIdQuery,
  fetchRateBandsSuccess,
} from "../../../../../../modules/rateBands";
import {
  taskFieldRateBandsQuery,
  fetchTaskFieldRateBandsSuccess,
} from "../../../../../../modules/taskFieldRateBands";

export const initialQuery = `query getParentAccountAccountOrderFormProjectAndFields
  ($projectId: String){
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${projectQuery}
  ${briefingFieldsByProjectIdQuery}
  ${keywordGroupsByProjectIdQuery}
  ${taskFieldsByProjectQuery}
  ${rateBandsByProjectIdQuery}
  ${taskFieldRateBandsQuery}
}`;

export const defaultTaskField = {
  taskFieldFormat: "HTML",
  qualityCheck: true,
  rateBands: [],
};
export const createDefaultTaskField = (rateBands) => ({
  ...defaultTaskField,
  rateBands,
});

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchParentAccountsSuccess(json.parentAccounts));
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchBriefingFieldsSuccess(json.briefingFields));
      dispatch(fetchKeywordGroupsSuccess(json.keywordGroups));
      dispatch(fetchTaskFieldsSuccess(json.taskFields));
      dispatch(fetchRateBandsSuccess(json.rateBands));

      dispatch(fetchTaskFieldRateBandsSuccess(json.taskFieldRateBands));
    });
  };
}
