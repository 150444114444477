import { getGraphQL } from "../../../../../../utils/fetch";
import {
  accountsQuery,
  fetchAccountsSuccess,
} from "../../../../../modules/accounts";

export const initialQuery = `query PaymentsSearch {
  ${accountsQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchAccountsSuccess(json.accounts));
    });
  };
}
