import React, { Component } from 'react'
import styles from './PaymentList.module.scss'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import PaymentRow from './PaymentRow'
import Pagination from '../../../../../components/Pagination'
import AccountFilter from './AccountFilter'
import { formatDateString } from '../../../../../utils/date'

class PaymentList extends Component {
  constructor(props) {
    super(props)
    const {
      location: { search },
    } = props
    this.paginate = !search
    this.accountsLoaded = false
    this.state = {
      accounts: new Set(),
      selectedAccount: 'all',
      filteredPayments: [],
      paginatedPayments: this.paginate ? [] : props.payments,
    }
  }

  loadAccountList() {
    const { payments } = this.props
    const accounts = payments.reduce((acc, { accountName }) => {
      acc.add(accountName)
      return acc
    }, new Set())
    this.setState({ accounts })
  }

  filterPayments = () => {
    const { selectedAccount } = this.state
    const {
      payments,
      location: { search },
    } = this.props

    const regex = new RegExp(/[^\d|\-|&]/, 'g')
    const formattedDate = search.replace(regex, '')
    if (!formattedDate) return []

    const [start, end] = formattedDate.split('&')
    return payments.filter(({ createDate, accountName }) => {
      const date = new Date(formatDateString(createDate))
      // manually insert the time to catch all possible intervals
      return (
        date >= new Date(formatDateString(`${start} 00:00:00`)) &&
        date <= new Date(formatDateString(`${end} 23:59:59`)) &&
        (accountName === selectedAccount || selectedAccount === 'all')
      )
    })
  }

  sumField = (field) => {
    return this.filterPayments()
      .reduce((acc, cur) => acc + cur[field], 0)
      .toFixed(2)
  }

  getAssignmentTotal = () => this.filterPayments().length

  setSelectedAccount = (selectedAccount) => {
    this.setState({ selectedAccount })
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>List payments</title>
        </Helmet>

        {
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <AccountFilter
                    accounts={this.state.accounts}
                    setSelectedAccount={this.setSelectedAccount}
                  />
                </th>
                <th>Project</th>
                <th>Task</th>
                <th>Approved date</th>
                <th>Assignment ID</th>
                <th>Batch</th>
                <th>Rate</th>
                {this.props.featureToggles.QCC_1298_showVatAmount && (
                  <th>VAT</th>
                )}
                {this.props.featureToggles.QCC_1298_showVatAmount && (
                  <th>Total</th>
                )}
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {this.filterPayments().map((payment, i) => (
                <PaymentRow
                  key={`${payment.paymentId}-${i}`}
                  history={this.props.history}
                  payment={payment}
                  featureToggles={this.props.featureToggles}
                />
              ))}

              {this.filterPayments().length > 0 ? (
                <tr className={styles.totalRow}>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>Tasks: {this.getAssignmentTotal()}</td>
                  <td>Totals:</td>
                  <td>{this.sumField('rate')}</td>
                  {this.props.featureToggles.QCC_1298_showVatAmount && (
                    <td>{this.sumField('vatAmount')}</td>
                  )}
                  {this.props.featureToggles.QCC_1298_showVatAmount && (
                    <td>{this.sumField('grossAmount')}</td>
                  )}
                  <td />
                </tr>
              ) : (
                <tr className={styles.inactive}>
                  <td colspan="3">No payments meet search criteria</td>
                  <td />
                  <td />
                  {this.props.featureToggles.QCC_1298_showVatAmount && <td />}
                  {this.props.featureToggles.QCC_1298_showVatAmount && <td />}
                  <td />
                </tr>
              )}
            </tbody>
          </table>
        }

        {this.paginate && (
          <Pagination
            items={this.state.filteredPayments}
            onChangePage={this.paginatePayments}
            pageSize={50}
          />
        )}
        {!this.filterPayments().length && (
          <p className={styles.mobileInactive}>
            No payments meet search criteria
          </p>
        )}
        <div className={styles.mobileTotals}>
          <p>
            <span className={styles.labels}>Tasks:</span>{' '}
            {this.getAssignmentTotal()}
          </p>
          <p>
            <span className={styles.labels}>Rate (£): </span>
            {this.sumField('rate')}
          </p>
          {this.props.featureToggles.QCC_1298_showVatAmount && (
            <p>
              <span className={styles.labels}>Vat (£): </span>
              {this.sumField('vatAmount')}
            </p>
          )}
          {this.props.featureToggles.QCC_1298_showVatAmount && (
            <p>
              <span className={styles.labels}>Total (£): </span>
              {this.sumField('grossAmount')}
            </p>
          )}
        </div>
      </div>
    )
  }
}

PaymentList.propTypes = {
  payments: PropTypes.array,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dataReady: PropTypes.bool.isRequired,
  featureToggles: PropTypes.object.isRequired,
}

export default PaymentList
