import {
  ClientProjectDashboard,
  ClientProjectDashboardInProgressV2,
  ClientProjectDashboardOverviewV2,
  ClientProjectDashboardCompletedV2,
  ClientSearch,
  ClientParentDeliverables,
} from "../bundles";

const routes = (_featureToggles) => [
  {
    component: ClientParentDeliverables,
    path:
      "/clients/projects/:projectId/parent-deliverables/:parentDeliverableId",
    exact: true,
  },
  {
    component: ClientProjectDashboard,
    path: "/clients/projects/:projectId",
    routes: [
      {
        component: ClientProjectDashboardInProgressV2,
        path: "/clients/projects/:projectId",
        exact: true,
      },
      {
        component: ClientProjectDashboardCompletedV2,
        path: "/clients/projects/:projectId/completed",
        exact: true,
      },
      {
        component: ClientProjectDashboardOverviewV2,
        path: "/clients/projects/:projectId/overview",
        exact: true,
      },
    ],
  },
  { component: ClientSearch, path: "/clients/search", exact: true },
];

export default routes;
