import classNames from "classnames";
import PropTypes from "prop-types";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import DownloadCSVOrExcel from "../../../../../../../components/DownloadCSVOrExcel/v2";
import FeatureToggle from "../../../../../../../components/FeatureToggle";
import { ModalType } from "../../../../../../../modules/modal";
import makeCreateLink from "../../../../../../../routes/_layouts/DashboardNav";
import styles from "./Layout.module.scss";
import ProjectHeader from "./ProjectHeader";

const getDefaultLocation = (path) => {
  if (path.indexOf("briefing") > -1) {
    return "briefing";
  } else if (path.indexOf("completed") > -1) {
    return "completed";
  } else if (path.indexOf("with-client") > -1) {
    return "withClient";
  }

  return "inProduction";
};

const Layout = (props) => {
  const {
    projectId,
    showModal,
    noAssignmentGroupsMarked,
    fetchAllAssignmentGroups,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    const params = queryString.parse(location.search);
    const fetchAll = params.all === "true";
    // Directly using queryString for parsing for consistency
    setIsToggled(fetchAll);
    if (fetchAll) {
      fetchAllAssignmentGroups();
    }
  }, [location.search]);

  const toggleWithClient = () => {
    const params = queryString.parse(location.search);
    params.all = !isToggled ? "true" : undefined; // Toggle the `all` parameter based on current state

    // Ensure we're not losing other parameters
    const newSearch = queryString.stringify(params);

    if (!isToggled) fetchAllAssignmentGroups();
    history.push(
      `/admin/projects/${projectId}${newSearch ? `?${newSearch}` : ""}`
    );
    setIsToggled(!isToggled);
  };

  const [active, setActive] = useState(
    getDefaultLocation(props.location.pathname)
  );

  const toggleTab = (e) => {
    setActive(e.target.dataset.nav);
  };

  const baseUrl = `/admin/projects/${projectId}`;

  const createNavItemStyles = (tab) =>
    classNames({
      [styles.active]: active === tab,
      [styles.navItem]: true,
    });

  // TODO: QCC-1955 Temporary admin user hack for First Choice
  if (props.me === 2670 && props.parentAccount.parentAccountId !== 117) {
    return <Redirect to="/" />;
  }

  const briefingStyles = createNavItemStyles("briefing");
  const inProductionStyles = createNavItemStyles("inProduction");
  const completedStyles = createNavItemStyles("completed");
  const withClientStyles = createNavItemStyles("withClient");

  const createLink = makeCreateLink(toggleTab);

  const createNavButton = (text, link, key) =>
    createLink(
      <button className={styles.button}>{text}</button>,
      link,
      styles.link,
      "",
      key
    );

  return (
    <div className={styles.layout}>
      <ProjectHeader
        account={props.account}
        archiveProject={props.archiveProject}
        hideModal={props.hideModal}
        modal={props.modal}
        orderForm={props.orderForm}
        parentAccount={props.parentAccount}
        project={props.project}
        showModal={props.showModal}
      />
      <div className={styles.nav}>
        {createLink(
          "Briefing",
          `${baseUrl}/briefing`,
          briefingStyles,
          "briefing"
        )}
        {createLink(
          "In production",
          `${baseUrl}`,
          inProductionStyles,
          "inProduction"
        )}
        {createLink(
          "With client",
          `${baseUrl}/with-client`,
          withClientStyles,
          "withClient"
        )}
        {createLink(
          "Completed",
          `${baseUrl}/completed`,
          completedStyles,
          "completed"
        )}

        <div className={styles.assignContainer}>
          {active === "briefing" && [
            createNavButton(
              "Bulk uploads",
              `${baseUrl}/bulk-uploads`,
              "bulk-uploads"
            ),
            createNavButton(
              "New parent deliverable",
              `${baseUrl}/parent-deliverables/new`,
              "pd-new"
            ),
            createLink(
              "Assign work",
              `${baseUrl}/assignments/new`,
              styles.assignButton
            ),
          ]}

          {active === "inProduction" && (
            <>
              <FeatureToggle toggle="assignmentGroupsByStatus">
                <div className={styles.toggleDiv}>
                  <label className={styles.switch}>
                    <input
                      checked={isToggled}
                      onChange={toggleWithClient}
                      type="checkbox"
                    />
                    <span className={`${styles.slider} ${styles.round}`} />
                  </label>
                  <span className={styles.label}>Show all deliverables</span>
                </div>
              </FeatureToggle>

              <button
                onClick={() => showModal(ModalType.UpdateAssignmentGroups)}
                className={styles.updateButton}
                disabled={noAssignmentGroupsMarked}
              >
                Update assignments
              </button>
            </>
          )}

          {active === "completed" && (
            <FeatureToggle toggle="merged-outputs">
              {/* @ts-ignore */}
              <DownloadCSVOrExcel projectId={projectId} isMergedOutputs />
            </FeatureToggle>
          )}
        </div>
      </div>

      {renderRoutes(props.route.routes)}
    </div>
  );
};

Layout.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  archiveProject: PropTypes.func.isRequired,
  featureToggles: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  me: PropTypes.number.isRequired,
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number,
  }),
  parentAccount: PropTypes.shape({
    parentAccountId: PropTypes.number,
    parentAccountName: PropTypes.string,
  }),
  projectId: PropTypes.number,
  project: PropTypes.shape({
    projectId: PropTypes.number,
    projectLanguages: PropTypes.array,
    projectName: PropTypes.string,
  }).isRequired,
  route: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default Layout;
