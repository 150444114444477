import { getGraphQL } from "../../../../../../utils/fetch";
import {
  peopleByPersonIdQuery,
  fetchPersonSuccess,
} from "../../../../../modules/people";
import {
  orderFormByIdQuery,
  fetchOrderFormSuccess,
} from "../../../../../modules/orderForms";

export const asyncQuery = `query PaymentsAsyncSearch ($personId: String, $orderFormId: String) {
  ${peopleByPersonIdQuery}
  ${orderFormByIdQuery}
}`;

export function getAsyncData({ paymentId }, url, cookies) {
  return (dispatch, getState) => {
    const { payments } = getState();
    const payment = payments.entities[paymentId];
    const { orderFormId, payeePersonId: personId } = payment;
    const params = { personId, orderFormId };

    return getGraphQL(asyncQuery, params, url, cookies).then((json) => {
      dispatch(fetchPersonSuccess(json.person));
      dispatch(fetchOrderFormSuccess(json.orderForm));
    });
  };
}
