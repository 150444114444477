import { createAction } from "redux-actions";
import { postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";
import { startRequest, endRequest } from "./requestTracker";
import { showErrorMessage } from "../modules/messages";

export const wordRatesByProjectIdQuery = `wordRates (projectId: $projectId) {
  projectId, languageCode, rate
}`;
const wordRatesUpdateMutation = `mutation updateWordRates ($associations: [WordRatesInput]){
  updateWordRates (associations: $associations){
    projectId, languageCode, rate
  }
}`;

const REQUEST_NAME = `updatingWordRates`;
export const UPDATE_WORD_RATES_SUCCESS = "UPDATE_WORD_RATES_SUCCESS";

export const updateWordRatesSuccess = createAction(UPDATE_WORD_RATES_SUCCESS);

export function updateWordRates(projectId, values, history) {
  return async (dispatch, getState) => {
    try {
      const associations = Object.entries(
        values
      ).map(([languageCode, rate]) => ({ languageCode, rate, projectId }));

      // disable buttons while we are processing
      dispatch(startRequest(REQUEST_NAME));

      const results = await postGraphQL(
        wordRatesUpdateMutation,
        { associations },
        "updateWordRates"
      );

      // update redux store
      dispatch(updateWordRatesSuccess(results));

      history.push(`/admin/projects/${projectId}`);
    } catch (errors) {
      dispatch(showErrorMessage(errors[0].message));
    } finally {
      // Always re-enable buttons after requests are done / errors handled
      dispatch(endRequest(REQUEST_NAME));
    }
  };
}

export const wordRatesActionHandlers = {
  [RESET_INITIAL_STATE]: () => wordRatesInitialState,
  [UPDATE_WORD_RATES_SUCCESS]: (state, { payload }) => {
    return payload.reduce(
      (acc, { projectId, languageCode, rate }) => {
        if (!acc[projectId]) acc[projectId] = {};
        acc[projectId][languageCode] = rate;

        return acc;
      },
      { ...state }
    );
  },
};

export const wordRatesInitialState = {};
