import React from "react";
import PropTypes from "prop-types";
import styles from "./QualityGradingForm.module.scss";
import { reduxForm } from "redux-form";
import { ValidationError } from "../../../utils/validations";
import GradeField, { parseGrade } from "./GradeField";

/**
 * A form to collect quality grades for multiple people
 */
let QualityGradingForm = ({
  gradeTypes,
  handleSubmit,
  onCancel,
  peopleToGrade,
  submitting,
  transitionName,
  valid,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      {peopleToGrade.map(({ deliverableIds, personId, personFullName }) => {
        const assignmentCount = deliverableIds.length;

        return (
          <div key={personId} className={styles.person}>
            <h2 className={styles.personName}>
              {personFullName} ({assignmentCount}{" "}
              {assignmentCount === 1 ? "assignment" : "assignments"})
            </h2>

            <div className={styles.personGrades}>
              {gradeTypes.map((gradeType) => (
                <GradeField
                  key={gradeType.name}
                  {...gradeType}
                  personId={personId}
                />
              ))}
            </div>
          </div>
        );
      })}

      <div className={styles.buttonContainer}>
        <button
          className={styles.cancelButton}
          onClick={onCancel}
          type={"button"}
        >
          Cancel
        </button>

        <button
          className={styles.transitionButton}
          disabled={!valid || submitting}
          type={"submit"}
        >
          {transitionName}
        </button>
      </div>
    </form>
  );
};

QualityGradingForm.propTypes = {
  gradeTypes: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  peopleToGrade: PropTypes.arrayOf(
    PropTypes.shape({
      personFullName: PropTypes.string.isRequired,
      personId: PropTypes.number.isRequired,
    })
  ),
  submitting: PropTypes.bool,
  transitionName: PropTypes.string.isRequired,
  valid: PropTypes.bool,
};

QualityGradingForm.defaultProps = {
  gradeTypes: [],
};

/**
 * Extract grades from the form values
 *
 * @param   {Object[]}  peopleToGrade people to grade
 * @param   {Object}    values      form values (name : value pairs)
 *
 * @returns {Object[]}  grades ({ personId, objectiveGrade, briefGrade })
 * @throws  Error if any grades aren't present
 */
const parseGrades = (peopleToGrade, gradeTypes, values) =>
  // Grades for each person
  peopleToGrade.map((personToGrade) =>
    // Append grade for each grade type
    gradeTypes.reduce(
      (acc, gradeType) => {
        acc[gradeType.name] = parseGrade(
          gradeType,
          personToGrade.personId,
          values
        );
        return acc;
      },
      { ...personToGrade }
    )
  );

QualityGradingForm = reduxForm({
  form: "QualityGradingForm",
  validate: (values, { peopleToGrade, gradeTypes }) => {
    try {
      parseGrades(peopleToGrade, gradeTypes, values);
    } catch (e) {
      if (e instanceof ValidationError) return { [e.field]: e.message };
    }
  },
  onSubmit: async (values, _, { gradeTypes, peopleToGrade, submitGrades }) => {
    const grades = parseGrades(peopleToGrade, gradeTypes, values);
    await submitGrades(grades);
  },
})(QualityGradingForm);

export default QualityGradingForm;
