import { connect } from "react-redux";
import PropTypes from "prop-types";
import { submitGrades } from "../../../modules/grades";
import { stageById } from "../../../utils/entitySelector";
import QualityGradingForm from "./QualityGradingForm";

// Options to display when grading Writers/Translators
const GRADE_OPTIONS = {
  Writer: [
    {
      name: "objectiveGrade",
      label: "Objective quality",
      options: [
        {
          label:
            "5 - Content is well-researched, structured and clear, requiring minor cosmetic changes only",
          value: 5,
        },
        {
          label:
            "4 - Content is mostly well-researched, structured and clear, and may require larger changes",
          value: 4,
        },
        {
          label:
            "3 - Content features objective errors and some elements require rewriting",
          value: 3,
        },
        {
          label:
            "2 - Content features multiple objective errors and large parts require rewriting",
          value: 2,
        },
        {
          label:
            "1 - Content requires rewriting from scratch to address objective errors",
          value: 1,
        },
      ],
    },
    {
      name: "briefGrade",
      label: "Brief-specific",
      options: [
        { label: "5 - Content is completely on-brief", value: 5 },
        {
          label:
            "4 - Content features minor brief-specific issues such as incorrect word choice or use of SEO",
          value: 4,
        },
        {
          label:
            "3 - Content features larger brief-specific issues such as topic and tone of voice",
          value: 3,
        },
        { label: "2 - Content does not meet the brief", value: 2 },
        {
          label:
            "1 - Content requires rewriting from scratch to meet the brief",
          value: 1,
        },
      ],
    },
  ],
  Editor: [
    {
      name: "objectiveGrade",
      label: "Objective quality",
      options: [
        {
          label:
            "5 - Content is free from objective errors, requiring no changes",
          value: 5,
        },
        {
          label:
            "4 - Content is mostly free from objective errors, requiring minor cosmetic changes",
          value: 4,
        },
        {
          label:
            "3 - Content features objective errors including one or two critical errors",
          value: 3,
        },
        {
          label: "2 - Content features many objective and critical errors",
          value: 2,
        },
        {
          label:
            "1 - Content requires rewriting from scratch to address objective errors",
          value: 1,
        },
      ],
    },
    {
      name: "briefGrade",
      label: "Brief-specific",
      options: [
        { label: "5 - Content is completely on-brief", value: 5 },
        {
          label:
            "4 - Content features minor brief-specific issues such as incorrect word choice",
          value: 4,
        },
        {
          label:
            "3 - Content features larger brief-specific issues such as topic and tone of voice",
          value: 3,
        },
        { label: "2 - Content does not meet the brief", value: 2 },
        {
          label:
            "1 - Content requires rewriting from scratch to meet the brief",
          value: 1,
        },
      ],
    },
  ],
  QA: [
    {
      name: "objectiveGrade",
      label: "Objective quality",
      options: [
        {
          label:
            "5 - Entire batch is free from objective errors, requiring no changes",
          value: 5,
        },
        { label: "4 - Batch requires minor cosmetic changes only", value: 4 },
        { label: "3 - Batch features one or two critical errors", value: 3 },
        {
          label: "2 - Batch features many objective and critical errors",
          value: 2,
        },
        {
          label:
            "1 - Some content in the batch requires rewriting to address objective errors",
          value: 1,
        },
      ],
    },
    {
      name: "briefGrade",
      label: "Brief-specific",
      options: [
        { label: "5 - Batch is completely on-brief", value: 5 },
        {
          label:
            "4 - Batch features minor brief-specific issues such as incorrect word choice",
          value: 4,
        },
        {
          label:
            "3 - Some content in the batch features larger brief-specific issues such as topic and tone of voice",
          value: 3,
        },
        {
          label: "2 - Some content in the batch does not meet the brief",
          value: 2,
        },
        {
          label:
            "1 - Some content in the batch requires rewriting from scratch to meet the brief",
          value: 1,
        },
      ],
    },
  ],
};

function mapDispatchToProps(
  dispatch,
  { projectId, reviewerStageId, stageToGrade: { stageId } }
) {
  return {
    submitGrades: (grades) => {
      dispatch(submitGrades(projectId, reviewerStageId, stageId, grades));
    },
  };
}

function mapStateToProps(state, { reviewerStageId }) {
  const { gradeStageRole } = stageById(state, reviewerStageId);
  const gradeTypes = GRADE_OPTIONS[gradeStageRole] || GRADE_OPTIONS.Writer;

  return {
    gradeTypes,
  };
}

const WithContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QualityGradingForm);

WithContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  reviewerStageId: PropTypes.number.isRequired,
  stageToGrade: PropTypes.shape({
    gradeStageId: PropTypes.number,
    stageId: PropTypes.number.isRequired,
  }).isRequired,
};

export default WithContainer;
