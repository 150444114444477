import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import ResetForm from "../../_components/ResetForm";
import styles from "./Reset.module.scss";
import { SubmissionError } from "redux-form";

import Helmet from "react-helmet";

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = { success: false };
  }

  reset = (data) => {
    return this.props.resetFetch(data).then((r) => {
      if (r.status === 200) {
        this.setState({ success: true });
      } else {
        throw new SubmissionError({
          _error:
            "There was an error connecting to the server. Please try again later.",
        });
      }
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        {this.state.success ? (
          <div>Instructions have been sent to reset your password.</div>
        ) : (
          <ResetForm onSubmit={this.reset} />
        )}

        <Link to="/" className={styles.link}>
          {" "}
          Return to Login{" "}
        </Link>
      </div>
    );
  }
}
Reset.propTypes = {
  resetFetch: PropTypes.func.isRequired,
};

export default Reset;