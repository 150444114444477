import { connect } from "react-redux";
import { projectById } from "../../../../modules/projects";
import {
  isProjectCreation,
  isProjectLocalisation,
} from "../../../../utils/entitySelector";
import { createQueryString } from "../../../../utils/url";
import DownloadCSVOrExcel from "../components/DownloadCSVOrExcel";

const FORMAT_TYPE = {
  GOOGLE_DOC: "google-doc",
};

/**
 * v2 Downloads generates spreadsheets on the backend with HTML export filters
 */
const mapDispatchToProps = () => {
  return {
    // Generate download on the backend
    download: async (options, formatArg, clientFormat) => {
      const { stageId, batchId, languageCode, exportFormat = {} } = options;
      const batchLangs = options.batchLangs || `${batchId},${languageCode}`;

      const isGoogleDocExport = formatArg === FORMAT_TYPE.GOOGLE_DOC;

      const params = isGoogleDocExport
        ? { batchId, languageCode, stageId }
        : {
            name: "batchFields",
            format: formatArg === "excel" ? "xlsx" : formatArg,
            clientFormat,
            batchLangs,
            stageId,
            ...exportFormat,
          };

      const queryString = createQueryString(params);
      const API_GATEWAY =
        (window as any).__API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY;

      if (isGoogleDocExport) {
        // @ts-ignore
        const docUrl = `${API_GATEWAY}/reports/google-doc`;
        const url = `${docUrl}?${queryString}`;

        const data = await fetch(url, { credentials: "include" });

        const json = await data.json();

        if (data.status !== 200) {
          throw new Error(json);
        }

        return json;
      } else {
        window.location.assign(
          // @ts-ignore
          `${API_GATEWAY}/report?${queryString}`
        );
      }
    },
  };
};

const mapStateToProps = (state, { projectId }) => {
  const { featureToggles, downloads } = state;
  const { parentAccountName } = projectById(state, projectId);

  // Only show LV extracts for localisation projects on the LV parent account
  const showLVExtracts =
    featureToggles.QCC_1932_lvExtractDownloads &&
    parentAccountName === "Louis Vuitton";

  const customDownloadButtons = {
    // @ts-ignore
    lvLocalisation: showLVExtracts && isProjectLocalisation(state, projectId),
    // @ts-ignore
    lvCreation: showLVExtracts && isProjectCreation(state, projectId),
  };

  return {
    downloads,
    customDownloadButtons,
  };
};

const ConnectedDownloadCSVOrExcel = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(DownloadCSVOrExcel);

export default ConnectedDownloadCSVOrExcel;
