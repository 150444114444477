import { getGraphQL } from "../../utils/fetch";
import { taskFieldsByDeliverableIdsQuery } from "./taskFields";
import { taskFieldContentByDeliverableIdsQueryV2 } from "./taskFieldContent";
import { briefingFieldsByDeliverableIdsQuery } from "./briefingFields";
import { parentDeliverableBriefingFieldsByDeliverableIdsQuery } from "./parentDeliverableBriefingFields";
import { keywordGroupsByDeliverableIdsQuery } from "./keywordGroups";
import { keywordsByDeliverableIdsQuery } from "./keywords";
import {
  deliverablesByDeliverableIdQuery,
  deliverablesByBatchQuery,
} from "./deliverables";
import { sourceFieldsByDeliverableIdsQuery } from "./sourceFields";
import { parentDeliverableSourceFieldsByDeliverableIdsQuery } from "./parentDeliverableSourceFields";
import endpoints from "../../config/endpoints";
import { generateDownload } from "../../utils/files";

function briefingFieldKeywordGroupsAndTaskFieldName({
  briefingFields,
  keywordGroups,
  taskFields,
  sourceFields,
}) {
  return briefingFields
    .map((b) => b.briefingFieldName)
    .concat(keywordGroups.map((kwg) => kwg.keywordGroupName))
    .concat(sourceFields.map((sf) => `${sf.taskFieldName} (source)`))
    .concat(taskFields.map((tf) => tf.taskFieldName));
}
function completedWorkHeaders(headerInfo) {
  return ["Language"].concat(
    briefingFieldKeywordGroupsAndTaskFieldName(headerInfo)
  );
}
function assignmentGroupHeaders(headerInfo) {
  return ["deliverableId"]
    .concat(briefingFieldKeywordGroupsAndTaskFieldName(headerInfo))
    .concat(["URL"]);
}

function rowDataContent(
  rowData,
  {
    completedWorkDownload,
    batchId,
    languageCode,
    briefingFields,
    keywordGroups,
    taskFields,
    sourceFields,
    baseUrl,
    assignmentIds,
  },
  exportType
) {
  const rows = [];

  Object.keys(rowData).forEach((deliverableId, rowIdx, rowKeys) => {
    const row = [];
    const data = rowData[deliverableId];

    if (!completedWorkDownload || data.languageCode === languageCode) {
      if (completedWorkDownload) {
        row.push(data.languageCode);
      } else {
        row.push(deliverableId);
      }

      // NOTE: The order of inserts needs to correspond to the order of headers

      // parentDeliverableBriefingFields
      briefingFields.forEach(({ briefingFieldId }) => {
        row.push(data.parentDeliverableBriefingFields[briefingFieldId]);
      });

      // keywords
      keywordGroups.forEach(({ keywordGroupId }) => {
        row.push(data.keywords[keywordGroupId]);
      });

      // parentDeliverableSourceFields
      sourceFields.forEach(({ sourceFieldId }) => {
        row.push(data.parentDeliverableSourceFields[sourceFieldId]);
      });

      // taskFields
      taskFields.forEach(({ taskFieldId, taskFieldFormat }) => {
        let content = data.taskFieldContent[taskFieldId];
        // in text format fields (for excel documents) replace newlines with a temporary placeholder to insert the newline character after we sanitize.
        if (exportType === "excel" && taskFieldFormat === "Text") {
          content = content.split("\n").join("[[[NEWLINE]]]");
        }
        row.push(content);
      });

      if (!completedWorkDownload) {
        // use our object of { deliverableId: assignmentId } to find the assignmentId
        const assignmentId = assignmentIds[deliverableId];
        row.push(
          `${endpoints.frontend.__QUILL_CLOUD_FRONTEND__}${baseUrl}/${assignmentId}`
        );
      }

      rows.push(row);
    }
  });
  return rows;
}

export function download(
  { batchId, languageCode, deliverableIds, baseUrl },
  type
) {
  return async (dispatch, getState) => {
    const state = getState(); // TEMPORARY
    /* eslint-disable no-unused-vars */ const {
      deliverables: {
        entities: deliverableEntities,
        result: deliverableResult,
      },
      stages: { entities: stageEntities },
      batches: { entities: batchEntities },
    } = state;
    /* eslint-enable no-unused-vars */

    // if we have batchId and langCode instead of deliverableIds -> get the deliverables via the batch/langCode
    const completedWorkDownload = typeof batchId !== "undefined";

    if (completedWorkDownload) {
      /**
       * THIS IS A TEMPORARY FIX
       * UNTIL WE MOVE THE DOWNLOADING TO THE BACKEND/API
       */
      const deliverableQuery = `query ($batchId: String) { 
        ${deliverablesByBatchQuery}
      }`;
      const deliverableParams = { batchId };
      const deliverableIdResults = await getGraphQL(
        deliverableQuery,
        deliverableParams
      );

      // update the deliverableIds which is used in this whole function to only be the language specific deliverables of the batch
      deliverableIds = deliverableIdResults.deliverables
        // also make sure the stage the deliverable is in is a final stage
        .filter(
          (deliverable) =>
            deliverable.languageCode === languageCode &&
            stageEntities[deliverable.currentStageId].isFinal
        )
        .map((deliverable) => deliverable.deliverableId);
    }

    const assignmentIds = {};
    // So on pages where we need a URL we need to add the assignmentId
    // this is quite a process as the state.assignment.entities is an Object with assignmentId as the key and not deliverableId
    if (!completedWorkDownload) {
      // convert object to array of assignments
      const assignments = state.assignments.result.map(
        (id) => state.assignments.entities[id]
      );
      // for each deliverable add an entry of { deliverableId: assignmentId }
      deliverableIds.forEach((deliverableId) => {
        const assignment = assignments.find(
          (a) => a.deliverableId === deliverableId
        );
        assignmentIds[deliverableId] = assignment.assignmentId;
      });

      // Already filtered by language in the above if(completedWorkDownload)
      if (languageCode) {
        deliverableIds = deliverableIds.filter(
          (deliverableId) =>
            state.deliverables.entities[deliverableId].languageCode ===
            languageCode
        );
      }
    }

    const params = { deliverableId: deliverableIds.join(",") };

    const query = `query ($deliverableId: String) {
    ${taskFieldsByDeliverableIdsQuery}
    ${taskFieldContentByDeliverableIdsQueryV2}
    ${briefingFieldsByDeliverableIdsQuery}
    ${parentDeliverableBriefingFieldsByDeliverableIdsQuery}
    ${keywordGroupsByDeliverableIdsQuery}
    ${keywordsByDeliverableIdsQuery}
    ${deliverablesByDeliverableIdQuery}
    ${sourceFieldsByDeliverableIdsQuery}
    ${parentDeliverableSourceFieldsByDeliverableIdsQuery}
  }`;

    getGraphQL(query, params).then((res) => {
      const {
        briefingFields,
        parentDeliverableBriefingFields,
        keywordGroups,
        keywords,
        taskFields,
        deliverables,
        sourceFields,
        parentDeliverableSourceFields,
      } = res;
      const taskFieldContent = res.taskFieldContentV2;

      /* rowData structure is as follows; each deliverableId object represents
         one row in the csv:
        {
          [deliverableId]: {
            taskFieldContent: <content>,
            parentDeliverableBriefingField: <content>,
            keywords: <content>
          }
        }
      */
      const rowData = {};

      const parentDeliverableIdToDeliverableIdMap = deliverables.reduce(
        (acc, deliverable) => {
          const {
            deliverableId,
            languageCode,
            parentDeliverableId,
          } = deliverable;
          if (!acc[parentDeliverableId]) acc[parentDeliverableId] = {};
          if (!acc[parentDeliverableId][deliverableId])
            acc[parentDeliverableId][deliverableId] = languageCode;
          return acc;
        },
        {}
      );

      /* Create default keys for each row .We should create keys for fields
        that do not exist, so that empty columns are created where no content exists */
      const defaultTaskFieldContent = taskFields.reduce((acc, tf) => {
        acc[tf.taskFieldId] = "";
        return acc;
      }, {});

      const defaultKeywordGroups = keywordGroups.reduce((acc, kwg) => {
        acc[kwg.keywordGroupId] = "";
        return acc;
      }, {});

      const defaultBriefingFields = briefingFields.reduce((acc, bf) => {
        acc[bf.briefingFieldId] = "";
        return acc;
      }, {});

      const defaultSourceFields = sourceFields.reduce((acc, sf) => {
        acc[sf.sourceFieldId] = "";
        return acc;
      }, {});

      /*  NOTE: Because we do not store task field content at the isFinal stage, i.e.
          the client delivery stage, we instead organise the data by deliverableId
          and taskFieldId and retrieve the item with the most recent createDate, i.e.
          the newest and therefore most recently submitted item, which should be
          the final version */
      const taskFieldContentByDeliverableId = taskFieldContent.reduce(
        (acc, tfc) => {
          const { deliverableId, taskFieldId } = tfc;
          if (!acc[deliverableId]) acc[deliverableId] = {};
          if (!acc[deliverableId][taskFieldId])
            acc[deliverableId][taskFieldId] = tfc;
          if (tfc.createDate > acc[deliverableId][taskFieldId].createDate)
            acc[deliverableId][taskFieldId] = tfc;
          return acc;
        },
        {}
      );

      deliverableIds.forEach((deliverableId) => {
        rowData[deliverableId] = {
          taskFieldContent: { ...defaultTaskFieldContent },
          keywords: { ...defaultKeywordGroups },
          parentDeliverableBriefingFields: { ...defaultBriefingFields },
          parentDeliverableSourceFields: { ...defaultSourceFields },
        };
      });

      Object.keys(taskFieldContentByDeliverableId).forEach((deliverableId) => {
        Object.keys(taskFieldContentByDeliverableId[deliverableId]).forEach(
          (taskFieldId) => {
            const { content } = taskFieldContentByDeliverableId[deliverableId][
              taskFieldId
            ];
            rowData[deliverableId].taskFieldContent[taskFieldId] = content;
          }
        );
      });

      parentDeliverableBriefingFields.forEach((dbf) => {
        const { fieldValue, briefingFieldId, parentDeliverableId } = dbf;
        Object.keys(
          parentDeliverableIdToDeliverableIdMap[parentDeliverableId]
        ).forEach((deliverableId) => {
          rowData[deliverableId].parentDeliverableBriefingFields[
            briefingFieldId
          ] = fieldValue;

          // also add languageCode
          rowData[deliverableId].languageCode =
            parentDeliverableIdToDeliverableIdMap[parentDeliverableId][
              deliverableId
            ];
        });
      });

      parentDeliverableSourceFields.forEach((dsf) => {
        const { fieldValue, sourceFieldId, parentDeliverableId } = dsf;
        Object.keys(
          parentDeliverableIdToDeliverableIdMap[parentDeliverableId]
        ).forEach((deliverableId) => {
          rowData[deliverableId].parentDeliverableSourceFields[
            sourceFieldId
          ] = fieldValue;

          // also add languageCode
          rowData[deliverableId].languageCode =
            parentDeliverableIdToDeliverableIdMap[parentDeliverableId][
              deliverableId
            ];
        });
      });

      keywords.forEach((kw) => {
        const { word, keywordGroupId, parentDeliverableId } = kw;
        Object.keys(
          parentDeliverableIdToDeliverableIdMap[parentDeliverableId]
        ).forEach((deliverableId) => {
          rowData[deliverableId].keywords[keywordGroupId] = word;
        });
      });

      // sort by position
      taskFields.sort((a, b) => a.taskFieldPosition - b.taskFieldPosition);
      briefingFields.sort(
        (a, b) => a.briefingFieldPosition - b.briefingFieldPosition
      );
      keywordGroups.sort(
        (a, b) => a.keywordGroupPosition - b.keywordgroupPosition
      );
      sourceFields.sort((a, b) => a.taskFieldPosition - b.taskFieldPosition);

      const headers = completedWorkDownload
        ? completedWorkHeaders({
            briefingFields,
            keywordGroups,
            taskFields,
            sourceFields,
          })
        : assignmentGroupHeaders({
            briefingFields,
            keywordGroups,
            taskFields,
            sourceFields,
          });

      const rowDataParams = {
        completedWorkDownload,
        batchId,
        languageCode,
        briefingFields,
        keywordGroups,
        taskFields,
        sourceFields,
        baseUrl,
        assignmentIds,
      };
      const rows = [headers].concat(
        rowDataContent(rowData, rowDataParams, type)
      );

      const csvData =
        type === "csv" ? generateCSVString(rows) : generateXLSString(rows);

      let name;
      if (!completedWorkDownload) {
        const person = state.people.entities[state.me];

        const deliverable = state.deliverables.entities[deliverableIds[0]];
        const parentDeliverable =
          state.parentDeliverables.entities[deliverable.parentDeliverableId];
        const project = state.projects.entities[parentDeliverable.projectId];

        name = `${project.projectName}-${person.firstName} ${person.lastName}`;
      } else {
        name = batchEntities[batchId].batchName;
      }

      downloadFile(csvData, name, type);
    });
  };
}

// csv needs to escape double quotes
const escapeCSVText = (c) => c.replace(/"/g, '""');

const escapeHash = {
  "&": "&amp;",
  "<": "&lt;",
};

const escapeXMLText = (c) => {
  // xml needs to escape 5 characters: " ' < > &
  // However in text portions only < and & need to be escaped
  return c.replace(/<|&/g, (matchChar) => escapeHash[matchChar]);
};

// Pretty much taken directly from https://stackoverflow.com/questions/16078544/export-to-csv-using-jquery-and-html
// it handles escaping double quotes when they appear
function generateCSVString(rows) {
  // Temporary delimiter characters unlikely to be typed by keyboard
  // This is to avoid accidentally splitting the actual contents
  const tmpColDelim = String.fromCharCode(11); // vertical tab character
  const tmpRowDelim = String.fromCharCode(0); // null character

  let csvData = "";

  rows.forEach((cols, rowIdx, rowKeys) => {
    cols.forEach((cell, colIdx) => {
      const escapedCell = escapeCSVText(cell);
      csvData += escapedCell;

      // add column delimiter if we aren't last column
      if (colIdx < cols.length - 1) csvData += tmpColDelim;
    });

    // and row delimiter if we aren't last row
    if (rowIdx < rowKeys.length - 1) csvData += tmpRowDelim;
  });

  // actual delimiter characters for CSV format
  const colDelim = '","';
  const rowDelim = '"\r\n"';
  csvData =
    '"' +
    csvData
      .split(tmpRowDelim)
      .join(rowDelim)
      .split(tmpColDelim)
      .join(colDelim) +
    '"';
  return csvData;
}

function generateXLSString(rows) {
  let xlsData = `<?xml version="1.0" encoding="utf-8"?>
  <?mso-application progid="Excel.Sheet"?>
  <Workbook
    xmlns="urn:schemas-microsoft-com:office:spreadsheet"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
    xmlns:html="http://www.w3.org/TR/REC-html40">
  <Styles>
    <Style ss:ID="s1">
      <Alignment ss:Vertical="Bottom" ss:WrapText="1"/>
    </Style>
  </Styles>
  <Worksheet ss:Name="Sheet 1">
    <Table>`;

  rows[0].forEach((cell) => {
    xlsData += "<Column/>";
  });

  rows.forEach((cols, rowIdx) => {
    xlsData += `<Row>`;
    cols.forEach((cell) => {
      // replace our temporary newlines with the actual horizontal line character
      const escapedCell = escapeXMLText(cell)
        .split("[[[NEWLINE]]]")
        .join("&#13;");
      xlsData += `<Cell ss:StyleID="s1"><Data ss:Type="String">${escapedCell}</Data></Cell>`;
    });
    xlsData += `</Row>`;
  });

  xlsData += "</Table></Worksheet></Workbook>";
  return xlsData;
}

function downloadFile(csvData, filename, type) {
  if (!window.URL || !window.URL.createObjectURL) {
    throw Error(
      "Native support for creating URLs from blobs unavailable - CSV or XLS could not be downloaded"
    );
  }

  const mimetype = type === "csv" ? "csv" : "plain";
  const fileEnding = type === "csv" ? "csv" : "xls";
  const blob = new Blob([csvData], { type: `text/${mimetype};charset=utf-8` });
  const csvUrl = window.URL.createObjectURL(blob);

  // I refuse to allow spaces in filenames (could come from any of the user inputted variables)
  const finalFilename = `${filename}.${fileEnding}`.replace(/ /g, "_");

  generateDownload(finalFilename, csvUrl);
}
