import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";
import { getGraphQL } from "../../utils/fetch";
import { accountByIdQuery, fetchAccountSuccess } from "./accounts";
import { startRequest, endRequest } from "./requestTracker";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "./projectLanguages";
// ------------------------------------
// GraphQL Queries
// ------------------------------------
const searchFields = `
  batchId, batchName, projectId, projectName, parentDeliverableId, sourceFieldValue, briefingFieldValue
`;

export const searchByAccountIdAndSearchTerm = `batchSearch (accountId: $accountId, searchTerm: $searchTerm) {
  ${searchFields}
}`;

const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const fetchSearchSuccess = createAction(FETCH_SEARCH_SUCCESS);

export const searchQuery = `query batchSearch ($accountId: Int, $searchTerm: String) {
  ${searchByAccountIdAndSearchTerm}
}`;

export const accountQuery = `query accountById ($accountId: Int) {
  ${accountByIdQuery}
}`;

export const projectLanguagesQuery = `query projectLanguagesByIds ($projectId: String) {
  ${projectLanguagesByProjectIdQuery}
}`;

function getDistinctProjectIds(batchSearch) {
  const projectIds = batchSearch.reduce((acc, { projectId }) => {
    acc[projectId] = true;
    return acc;
  }, {});

  return Object.keys(projectIds);
}

export const REQUEST_NAME = "BATCH_SEARCH";
export function updateSearch(params) {
  return async (dispatch) => {
    try {
      dispatch(startRequest(REQUEST_NAME));

      if (params.accountId) {
        const { account } = await getGraphQL(accountQuery, params);
        dispatch(fetchAccountSuccess(account));
      }

      const { batchSearch } = await getGraphQL(searchQuery, params);
      dispatch(fetchSearchSuccess(batchSearch));

      const distinctProjectIds = getDistinctProjectIds(batchSearch);

      if (distinctProjectIds.length > 0) {
        const { projectLanguages } = await getGraphQL(projectLanguagesQuery, {
          projectId: distinctProjectIds.join(","),
        });
        dispatch(fetchProjectLanguagesByProjectIdSuccess(projectLanguages));
      }
    } catch (err) {
      throw err;
    } finally {
      dispatch(endRequest(REQUEST_NAME));
    }
  };
}

export const searchActionHandlers = {
  [RESET_INITIAL_STATE]: () => searchInitialState,
  [FETCH_SEARCH_SUCCESS]: (state, { payload }) =>
    upsertData(searchInitialState, payload, "parentDeliverableId"),
};

export const searchInitialState = { entities: {}, result: [] };
