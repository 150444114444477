import { connect } from "react-redux";
import BulkQA from "../components/BulkQA";
import WithData from "../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { tfcByDeliverables } from "../../../../modules/taskFieldContent";
import {
  assignmentsByGroupSelector,
  projectById,
  stageById,
} from "../../../../utils/entitySelector";

const mapDispatchToProps = (dispatch) => ({
  getInitialData: (params) => dispatch(getInitialData(params)),
});

const mapStateToProps = (state, ownProps) => {
  const assignmentGroupId = Number(ownProps.match.params.assignmentGroupId);

  const tfcByDeliverable = tfcByDeliverables(state, assignmentGroupId);

  const [{ stageId, projectId } = {}] = assignmentsByGroupSelector(
    state,
    assignmentGroupId
  );

  const listUrl = `/freelancer/projects/${projectId}/assignments/${assignmentGroupId}`;

  const project = projectById(state, projectId);
  const stage = stageById(state, stageId);

  return {
    listUrl,
    project,
    stage,
    tfcByDeliverable,
    assignmentGroupId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithData(BulkQA));
