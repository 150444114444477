import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import DefaultAssigneesForm from "./DefaultAssigneesForm";
import PageHeader from "../../../../../../components/PageHeader";

const DefaultAssignees = (props) => {
  const {
    project: { projectName, projectId },
  } = props;

  return (
    <div>
      <Helmet>
        {" "}
        <title>Team</title>{" "}
      </Helmet>
      <div>
        <PageHeader
          breadCrumbItems={[
            { url: `/admin`, text: "Parent accounts" },
            {
              text: `${props.parentAccount.parentAccountName} | ${props.account.accountName} | ${props.orderForm.orderFormNumber}`,
            },
            { url: `/admin/projects/${projectId}`, text: `${projectName}` },
            { text: "Team" },
          ]}
          title={`${projectName} - Team`}
        />
        <DefaultAssigneesForm
          assignees={props.assignees}
          enableReinitialize
          initialValues={props.initialValues}
          onSubmit={props.updateDefaultAssignees}
          people={props.people}
          projectLanguages={props.projectLanguages}
          searchPeople={props.searchPeople}
          stages={props.stages}
        />
      </div>
    </div>
  );
};

DefaultAssignees.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
  }),
  assignees: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number.isRequired,
  }),
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string.isRequired,
  }),
  people: PropTypes.array.isRequired,
  project: PropTypes.shape({
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  }).isRequired,
  projectLanguages: PropTypes.array,
  searchPeople: PropTypes.func.isRequired,
  stages: PropTypes.array.isRequired,
  updateDefaultAssignees: PropTypes.func.isRequired,
};

export default DefaultAssignees;
