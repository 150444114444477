import React, { useState } from "react";
import PropTypes from "prop-types";
import OverlayLoading from "../../../../../../../components/OverlayLoading/v1";
import ProgressTab from "../../../../../../../components/ProgressTab/v3";

export const Completed = ({
  asyncFetched,
  dataReady,
  history,
  location,
  match,
  onFilterLanguage,
}) => {
  const [isClientBatchDetailsLoading, setClientBatchDetailsLoading] = // NOSONAR
    useState(false);
  if (!dataReady || !asyncFetched) return <OverlayLoading />;

  const projectId = Number(match.params.projectId);
  const baseUrl = `/clients/projects/${projectId}`;

  return (
    <>
      {isClientBatchDetailsLoading && <OverlayLoading />}
      <ProgressTab
        baseUrl={baseUrl}
        history={history}
        location={location}
        projectId={projectId}
        selectedStageTypes={["Client"]}
        showFinal
        isMultiSelectLanguageFilter
        onFilterLanguage={async (langs) => {
          setClientBatchDetailsLoading(true);
          await onFilterLanguage(langs);
          setClientBatchDetailsLoading(false);
        }}
      />
    </>
  );
};

Completed.propTypes = {
  asyncFetched: PropTypes.bool.isRequired,
  dataReady: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }),
  }),
  onFilterLanguage: PropTypes.func.isRequired,
};

export default Completed;
