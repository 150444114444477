import Show from "./containers/Show";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../../../modules/projects";
import {
  bannedWordActionHandlers,
  bannedWordInitialState,
} from "../../../../../modules/bannedWords";
import {
  projectLanguageActionHandlers,
  projectLanguageInitialState,
} from "../../../../../modules/projectLanguages";

export default {
  Component: Show,
  reducers: [
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
    {
      key: "bannedWords",
      actionHandlers: bannedWordActionHandlers,
      initialState: bannedWordInitialState,
    },
    {
      key: "projectLanguages",
      actionHandlers: projectLanguageActionHandlers,
      initialState: projectLanguageInitialState,
    },
  ],
};
