import { connect } from "react-redux";
import LanguageSelector from "../components/LanguageSelector";

const mapDispatchToProps = (dispatch, ownProps) => ({});

const mapStateToProps = (state) => {
  let title = "Language";

  const selectedLanguage = state.projectLanguages.find((pl) => pl.selected);
  if (selectedLanguage) {
    // get the languageName from the selectedLanguage's languageCode
    title =
      state.languages.entities[selectedLanguage.languageCode].languageName;
  }

  return {
    title,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
