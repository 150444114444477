import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  projectLanguagesSelector,
  projectBannedWordsSelector,
} from "../../../../../../utils/entitySelector";
import BannedWords from "../components/BannedWords";
import { updateBannedWords } from "../../../../../../modules/bannedWords";
import WithData from "../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    updateBannedWords: (data) =>
      dispatch(
        updateBannedWords(data, ownProps.history, ownProps.match.params)
      ),
  };
};

const initialValuesSelector = createSelector(
  projectBannedWordsSelector,
  (projectBannedWords) => {
    const initialValues = {};

    projectBannedWords.forEach((bw) => {
      const { languageCode, word } = bw;

      if (initialValues[languageCode]) {
        initialValues[languageCode].push(word);
        return;
      }
      initialValues[languageCode] = [word];
    });
    return initialValues;
  }
);

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);

  const project = state.projects.entities[projectId] || {};
  const projectLanguages = projectLanguagesSelector(state, Number(projectId));
  const initialValues = initialValuesSelector(state, Number(projectId));

  const orderForm = state.orderForms.entities[project.orderFormId] || {};
  const account = state.accounts.entities[orderForm.accountId] || {};
  const parentAccount =
    state.parentAccounts.entities[account.parentAccountId] || {};

  return {
    account,
    orderForm,
    parentAccount,
    project,
    projectLanguages,
    initialValues,
  };
};

const ProjectBannedWords = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(BannedWords));
ProjectBannedWords.getInitialData = getInitialData;
export default ProjectBannedWords;
