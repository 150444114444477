import React, { Component } from "react";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import DatePicker from "../../components/DatePicker";
import styles from "./WithErrors.module.scss";

import {
  ReduxFormCompatibleSelect,
  ReduxFormCompatibleCreatable,
} from "../ReduxFormCompatible";

function withErrors(WrappedComponent) {
  return class WithErrors extends Component {
    render() {
      /* eslint-disable react/prop-types */
      const { containerWidth, hideErrors, active, customErrorStyles, ...rest } =
        this.props;
      const {
        input,
        meta: { touched, error, warning },
      } = rest;
      /* eslint-enable react/prop-types */

      // Textarea's don't seem to change 'touched' until you blur the field, so we can check if any text is in the input
      const containsText = input && input.value !== "";
      const errorStyles = customErrorStyles || styles.error;

      return (
        <div style={{ width: containerWidth || "auto" }}>
          <WrappedComponent {...rest} />
          {!hideErrors &&
            (touched || containsText) &&
            ((error && <span className={errorStyles}>{error}</span>) ||
              (warning && <span className={styles.error}>{warning}</span>))}
        </div>
      );
    }
  };
}

export const InputWithErrors = withErrors(Input);
export const SelectWithErrors = withErrors(ReduxFormCompatibleSelect);
export const CreatableWithErrors = withErrors(ReduxFormCompatibleCreatable);
export const TextAreaWithErrors = withErrors(TextArea);
export const DatePickerWithErrors = withErrors(DatePicker);

export default withErrors;
