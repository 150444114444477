import { connect } from "react-redux";
import DashboardFilters from "../components/DashboardFilters";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../../decorators/WithData";
import {
  currentLanguagesSelector,
  languageNamesFromLanguages,
  projectLanguagesWithFullNameSelector,
} from "../../../../../../utils/entitySelector";
import {
  appComponentSelector,
  setAppComponentData,
} from "../../../../../../modules/appComponents";
import { multiSelectLanguageSuccess } from "../../../../../../modules/projectLanguages";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps)),
  setActiveLanguages: (activeLanguages) =>
    dispatch(multiSelectLanguageSuccess(activeLanguages)),
  setReadyForReview: (readyForReview) =>
    dispatch(
      setAppComponentData({
        component: "overviewList",
        data: { readyForReview },
      })
    ),
});

const mapStateToProps = (state, ownProps) => {
  const { readyForReview } = appComponentSelector(state, "overviewList");

  const activeLanguages = currentLanguagesSelector(state, ownProps.projectId);

  const languages = projectLanguagesWithFullNameSelector(
    state,
    Number(ownProps.projectId)
  );
  const languageNames = languageNamesFromLanguages(languages);

  return {
    activeLanguages,
    languageNames,
    readyForReview,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(DashboardFilters));
