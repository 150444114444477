import { connect } from "react-redux";
import { resetFetch } from "../../_modules/reset";

import Reset from "../components/Reset";

export const mapDispatchToProps = (dispatch, ownProps) => ({
  resetFetch: (data) => dispatch(resetFetch(data)),
});

export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
