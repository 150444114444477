import PropTypes from "prop-types";
import TransitionButton from "../TransitionButton";
import ManualOverrideTransitions from "../ManualOverrideTransitions";

const Transitions = ({
  children,
  containerStyles,
  disable,
  manualOverrideFn,
  orderedTransitions,
  processTransition,
  wasModifiedByClient,
  aiStageId,
  aiRevert,
}) => {
  return (
    <div className={containerStyles}>
      <ManualOverrideTransitions
        onClick={manualOverrideFn}
        disabled={disable}
      />

      {orderedTransitions.map((t) => {
        const isAiStage = aiStageId === t.toStageId;
        const canTransitionBackwards = !isAiStage || (isAiStage && aiRevert);

        return (
          <TransitionButton
            key={t.transitionId}
            disable={disable || !canTransitionBackwards}
            wasModifiedByClient={wasModifiedByClient}
            processTransition={processTransition}
            altButtonStyle
            {...t}
          />
        );
      })}

      {children}
    </div>
  );
};

Transitions.propTypes = {
  children: PropTypes.element,
  containerStyles: PropTypes.string,
  disable: PropTypes.bool,
  manualOverrideFn: PropTypes.func,
  orderedTransitions: PropTypes.arrayOf(
    PropTypes.shape({
      fromStageId: PropTypes.number.isRequired,
      toStageId: PropTypes.number.isRequired,
      transitionId: PropTypes.number.isRequired,
      transitionName: PropTypes.string.isRequired,
      transitionForward: PropTypes.bool,
      workflowId: PropTypes.number.isRequired,
      payStageId: PropTypes.number,
    })
  ).isRequired,
  processTransition: PropTypes.func,
};

export default Transitions;
