import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import styles from "./Search.module.scss";
import SearchResults from "../../../components/Search/SearchResults";
import SearchInput from "../../../components/Search/SearchInput";
import parseQuery from "../../../utils/parseQuery";

const Search = (props) => {
  const { updateSearch, searchResults } = props;

  const { search } = useLocation();
  const { searchTerm } = parseQuery(search);

  useEffect(() => {
    if (searchTerm) {
      updateSearch({ searchTerm });
    }
  }, [searchTerm, updateSearch]);

  return (
    <div>
      <Helmet>
        <title>Search results</title>
      </Helmet>
      <div>
        <PageHeader
          breadCrumbItems={[
            { url: "/clients", text: "Accounts" },
            { text: "Search results" },
          ]}
          title={`Search results for "${searchTerm}"`}
        >
          <div className={styles.headerRight}>
            <SearchInput />
          </div>
        </PageHeader>

        <SearchResults searchResults={searchResults} />
      </div>
    </div>
  );
};

export default Search;
