import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../modules/projects";
import {
  automatedRemindersByProjectQuery,
  fetchAutomatedReminderSuccess,
} from "../../../../../../modules/automatedReminders";

export const initialQuery = `query ProjectsEdit ($projectId: String){
  ${parentAccountsByProjectQuery}
  ${projectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${automatedRemindersByProjectQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch, getState) => {
    const {
      parentAccounts,
      project,
      accounts,
      orderForms,
      automatedReminders,
    } = await getGraphQL(initialQuery, params, url, cookies);

    dispatch(fetchParentAccountsSuccess(parentAccounts));
    dispatch(fetchAccountsSuccess(accounts));
    dispatch(fetchOrderFormsSuccess(orderForms));
    dispatch(fetchProjectSuccess(project));
    dispatch(fetchAutomatedReminderSuccess(automatedReminders));
  };
}
