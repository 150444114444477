import { normalize, schema } from "normalizr";
import { sortFn } from "../utils/splitItems";
import { createAction } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_CONSTANT_DATA_SUCCESS = "FETCH_CONSTANT_DATA_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------

export const fetchConstantDataSuccess = createAction(
  FETCH_CONSTANT_DATA_SUCCESS
);

// schemas

const languages = new schema.Entity(
  "languages",
  {},
  { idAttribute: "languageCode" }
);
const currencies = new schema.Entity(
  "currencies",
  {},
  { idAttribute: "currencyCode" }
);
const contentTypes = new schema.Entity(
  "contentTypes",
  {},
  { idAttribute: "contentTypeId" }
);
const workflows = new schema.Entity(
  "workflows",
  {},
  { idAttribute: "workflowId" }
);
const stages = new schema.Entity("stages", {}, { idAttribute: "stageId" });
const transitions = new schema.Entity(
  "transitions",
  {},
  { idAttribute: "transitionId" }
);

const schemas = {
  languages,
  contentTypes,
  workflows,
  stages,
  transitions,
  currencies,
};

export function normalizeConstantData(entity, data) {
  /* equivalent to normalizing the specific entity: ie.
   normalize({ contentTypes: data.contentTypes }, { contentTypes: [schema.contentTypes] })
   */
  const normalized = normalize(
    {
      [entity]: data[entity],
    },
    {
      [entity]: [schemas[entity]],
    }
  );

  const flat = {};
  Object.keys(normalized.entities).forEach((key) => {
    flat[key] = {};
    flat[key].entities = normalized.entities[key];
    flat[key].result = Object.keys(normalized.entities[key]).sort(sortFn);
  });

  return flat[entity];
}

// ------------------------------------
// Action Handlers
// ------------------------------------

export const actionHandlers = {
  [FETCH_CONSTANT_DATA_SUCCESS]: (state, { payload }, entity) =>
    normalizeConstantData(entity, payload),
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = {
  entities: {},
  result: [],
};

export function constantReducer(entity) {
  return (state = initialState, action) => {
    const handleFn = actionHandlers[action.type];
    return handleFn ? handleFn(state, action, entity) : state;
  };
}

export default constantReducer;
