import {
  checkValidations,
  isNotBlank,
  isNotBlankArray,
} from "../../../../../utils/validations";

const validate = (values) => {
  const errors = {};
  const orderFormIdErrors = checkValidations(values.orderFormId, [
    { validate: isNotBlank, msg: "Required" },
  ]);
  const projectIdErrors = checkValidations(values.projectId, [
    { validate: isNotBlank, msg: "Required" },
  ]);
  const projectNameErrors = checkValidations(values.projectName, [
    { validate: isNotBlank, msg: "Required" },
  ]);
  const fieldsErrors = checkValidations(values.fields, [
    { validate: isNotBlankArray, msg: "Required" },
  ]);

  if (orderFormIdErrors) errors.orderFormId = orderFormIdErrors;
  if (projectIdErrors) errors.projectId = projectIdErrors;
  if (projectNameErrors) errors.projectName = projectNameErrors;
  if (fieldsErrors) errors.fields = fieldsErrors;

  return errors;
};

export default validate;
