import { connect } from "react-redux";
import { createSelector } from "reselect";
import FieldsWithFormat from "./FieldsWithFormat.js";
import { rateBandsByProjectIdSelector } from "../../../../../../../../utils/entitySelector";

const getRateBandsAsList = createSelector(
  rateBandsByProjectIdSelector,
  (rateBands) => {
    return rateBands.map((rb) => ({
      label: rb.rateBandName,
      value: Number(rb.rateBandId),
    }));
  }
);

const mapStateToProps = (state, { projectId }) => ({
  rateBandOptions: getRateBandsAsList(state, projectId),
});

export default connect(mapStateToProps)(FieldsWithFormat);
