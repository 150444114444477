import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ProjectHeader.module.scss";

import Modal from "../../../../../../../../components/Modal";
import Dropdown from "../../../../../../../../components/Dropdown";
import { Link } from "react-router-dom";
import PageHeader from "../../../../../../../../components/PageHeader";
import ProjectInfo from "../../../../../../../../components/PageHeader/ProjectInfo";
import FeatureToggle from "../../../../../../../../components/FeatureToggle";
import { ModalType } from "../../../../../../../../modules/modal";

const ProjectHeader = (props) => {
  const [showArchive, toggleArchive] = useState(false);
  const {
    account: { accountName },
    archiveProject,
    hideModal,
    modal,
    orderForm: { orderFormNumber },
    project: { quoting, projectId, projectName },
    parentAccount: { parentAccountName },
    showModal,
  } = props;
  const baseUrl = `/admin/projects/${projectId}`;

  const handleModal = () => {
    toggleArchive(!showArchive);
    !showArchive ? showModal(ModalType.ArchiveProject) : hideModal();
  };

  return (
    <div>
      <PageHeader
        breadCrumbItems={[
          { url: "/admin", text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { text: projectName },
        ]}
        title={projectName}
      >
        <div className={styles.pageHeaderRight}>
          <Dropdown buttonStyles={styles.title} title="Edit project">
            <Link className={styles.dropdownItem} to={`${baseUrl}/edit`}>
              Info
            </Link>
            <Link className={styles.dropdownItem} to={`${baseUrl}/fields`}>
              Template
            </Link>
            <Link
              className={styles.dropdownItem}
              to={`${baseUrl}/banned-words`}
            >
              Banned words
            </Link>
            <Link className={styles.dropdownItem} to={`${baseUrl}/rates`}>
              Rates
            </Link>
            {quoting && (
              <Link className={styles.dropdownItem} to={`${baseUrl}/quotes`}>
                Quotes
              </Link>
            )}
            <Link
              className={styles.dropdownItem}
              to={`${baseUrl}/default-assignees`}
            >
              Team
            </Link>
            <Link
              className={styles.dropdownItem}
              to={`${baseUrl}/export-format`}
            >
              Export format
            </Link>
            <Link
              className={styles.dropdownItem}
              to={`${baseUrl}/project-batches`}
            >
              Batches
            </Link>
            <FeatureToggle toggle="automatedReminders">
              <Link
                className={styles.dropdownItem}
                to={`${baseUrl}/automated-reminders`}
              >
                Automated reminders
              </Link>
            </FeatureToggle>

            <button className={styles.deleteDropdown} onClick={handleModal}>
              Archive project
            </button>
          </Dropdown>
          <ProjectInfo url={`${baseUrl}/project-info`} />
        </div>
        {modal.display && showArchive && (
          <Modal
            body={<div>Are you sure you want to archive this project?</div>}
            display={modal.display}
            footer={
              <div>
                <button className={styles.modalCancel} onClick={handleModal}>
                  Cancel
                </button>
                <button
                  className={styles.modalConfirm}
                  onClick={archiveProject}
                >
                  Archive
                </button>
              </div>
            }
            onClickCallback={handleModal}
            type={ModalType.ArchiveProject}
          />
        )}
      </PageHeader>
    </div>
  );
};

ProjectHeader.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  archiveProject: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number,
  }),
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string,
  }),
  project: PropTypes.shape({
    projectId: PropTypes.number,
    projectName: PropTypes.string,
    quoting: PropTypes.bool,
  }).isRequired,
  showModal: PropTypes.func.isRequired,
};

export default ProjectHeader;
