import isEmail from "validator/lib/isEmail";
import isLength from "validator/lib/isLength";

export class ValidationError extends Error {
  constructor(field, message) {
    super(message);
    // a workaround to make `instanceof ValidationError` work in ES5
    // see: https://github.com/babel/babel/issues/4485
    this.constructor = ValidationError;
    this.__proto__ = ValidationError.prototype; // eslint-disable-line no-proto
    this.field = field;
  }
}

export function checkValidations(value, validations) {
  for (var i = 0; i < validations.length; i++) {
    if (!validations[i].validate(value)) return validations[i].msg;
  }
}

export function isNotBlank(value) {
  return !!value;
}

export function isNotBlankArray(value) {
  return value && value.length > 0;
}

export function isPositiveValue(value) {
  return value && value > 0;
}

export function validateEmail(value) {
  return isNotBlank(value) && isEmail(value);
}

export function containsLowercase(value) {
  return /^(?=.*[a-z])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]{1,}$/.test(
    value
  );
}

export function containsDigit(value) {
  return /^(?=.*\d)[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]{1,}$/.test(
    value
  );
}

export function containsUppercase(value) {
  return /^(?=.*[A-Z])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]{1,}$/.test(
    value
  );
}

export function containsSpecialCharacter(value) {
  return /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]{1,}$/.test(
    value
  );
}

export function validLength(options) {
  return (value) => {
    // If I don't have this check it complains about comparing a string which is undefined... dumb library
    if (value === undefined || value.length === 0) {
      return false;
    }
    return isLength(value, options);
  };
}

export function passwordMatch(pass, confirmPass) {
  return pass === confirmPass;
}

export function returnNumber(val) {
  return Number(val) || 0;
}
