import {
  validLength,
  isNotBlank,
  isPositiveValue,
  checkValidations,
} from "../../../../../../utils/validations";

const validate = (values) => {
  const errors = {};

  const peopleIdErrors = checkValidations(values.peopleIds, [
    { validate: isNotBlank, msg: "Please select a content creator to pay" },
  ]);
  const accountIdErrors = checkValidations(values.accountId, [
    { validate: isNotBlank, msg: "Please select an account" },
  ]);
  const orderFormIdErrors = checkValidations(values.orderFormId, [
    { validate: isNotBlank, msg: "Please select an order form" },
  ]);
  const projectIdErrors = checkValidations(values.projectId, [
    { validate: isNotBlank, msg: "Please select a project" },
  ]);
  const taskErrors = checkValidations(values.task, [
    { validate: isNotBlank, msg: "Please select a task" },
  ]);
  const rateErrors = checkValidations(values.rate, [
    { validate: isNotBlank, msg: "Please enter a payment value" },
    { validate: isPositiveValue, msg: "Payment cannot be a negative value" },
  ]);
  const notesError =
    values.notes &&
    checkValidations(values.notes, [
      {
        validate: validLength({ max: 100 }),
        msg: "Notes can only be up to 100 characters",
      },
    ]);

  if (peopleIdErrors) errors.peopleIds = peopleIdErrors;
  if (accountIdErrors) errors.accountId = accountIdErrors;
  if (orderFormIdErrors) errors.orderFormId = orderFormIdErrors;
  if (projectIdErrors) errors.projectId = projectIdErrors;
  if (taskErrors) errors.task = taskErrors;
  if (rateErrors) errors.rate = rateErrors;
  if (notesError) errors.notes = notesError;

  return errors;
};

export default validate;
