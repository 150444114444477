import React from "react";
import PropTypes from "prop-types";
import PersonForm from "../../../../../../components/PersonForm";
import PageHeader from "../../../../../../components/PageHeader";
import { getPeopleListUrl } from "../../../../../../routes";

const PeopleEdit = (props) => {
  const {
    person: { personId, firstName, lastName, archived, personType },
    accounts,
    personRoles,
    languages,
    updatePerson,
    searchAccounts,
    featureToggles,
  } = props;

  const fullName = `${firstName} ${lastName}`;

  return (
    <div>
      <PageHeader
        title={fullName}
        breadCrumbItems={[
          {
            url: getPeopleListUrl(featureToggles, personType, archived),
            text: "People",
          },
          { url: `/admin/people/${personId}`, text: fullName },
          { text: "Edit person" },
        ]}
      />

      <PersonForm
        enableReinitialize
        accounts={accounts}
        cancelUrl={`/admin/people/${personId}`}
        header="Edit person"
        initialValues={props.person}
        languages={languages}
        onSubmit={updatePerson}
        roles={personRoles}
        searchAccounts={searchAccounts}
        disabled={archived} // Adding prop to disable form on archived users
      />
    </div>
  );
};

PeopleEdit.propTypes = {
  person: PropTypes.shape({
    personId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    nativeLanguage: PropTypes.arrayOf(PropTypes.string),
    personType: PropTypes.string.isRequired,
    role: PropTypes.array,
    account: PropTypes.array,
    paymentMethod: PropTypes.string,
    payPalEmail: PropTypes.string,
    vatNumber: PropTypes.string,
  }).isRequired,
  personRoles: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  updatePerson: PropTypes.func.isRequired,
  searchAccounts: PropTypes.func.isRequired,
  featureToggles: PropTypes.object.isRequired,
};

export default PeopleEdit;
