import { connect } from "react-redux";
import { fetchLanguageRateBandItems } from "../../../../../../../../modules/rateBandItems";
import { fetchLanguageRateBandVolumes } from "../../../../../../../../modules/rateBandVolume";
import LanguageRates from "./LanguageRates";

const mapDispatchToProps = (dispatch) => ({
  fetchLanguageRatesAndVolumes: (projectId, languageCode) => {
    dispatch(fetchLanguageRateBandItems(projectId, languageCode));
    dispatch(fetchLanguageRateBandVolumes(projectId, languageCode));
  },
});

export default connect(null, mapDispatchToProps)(LanguageRates);
