import ProjectsNew from "./containers/ProjectsNew";
import {
  parentAccountActionHandlers,
  parentAccountInitialState,
} from "../../../../modules/parentAccounts";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../../modules/accounts";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../../modules/orderForms";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../../modules/projects";
import {
  peopleActionHandlers,
  peopleInitialState,
} from "../../../../modules/people";
import {
  projectLanguageActionHandlers,
  projectLanguageInitialState,
} from "../../../../modules/projectLanguages";
import {
  projectTeamActionHandlers,
  projectTeamInitialState,
} from "../../../../modules/projectTeams";

export default {
  Component: ProjectsNew,
  reducers: [
    {
      key: "parentAccounts",
      actionHandlers: parentAccountActionHandlers,
      initialState: parentAccountInitialState,
    },
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
    {
      key: "people",
      actionHandlers: peopleActionHandlers,
      initialState: peopleInitialState,
    },
    {
      key: "projectLanguages",
      actionHandlers: projectLanguageActionHandlers,
      initialState: projectLanguageInitialState,
    },
    {
      key: "projectTeams",
      actionHandlers: projectTeamActionHandlers,
      initialState: projectTeamInitialState,
    },
  ],
};
