import React, { useRef } from "react";
import styles from "./KeywordCommentsDecorators.module.scss";
import classNames from "classnames";

const WordSegments = ({
  type,
  start,
  end,
  blockKey,
  children,
  isResolved,
  ranges,
  onClick,
}) => {
  const ref = useRef(null);

  const inlineCommentStyles = classNames({
    [styles.inlineComment]: true,
    [styles.resolvedComment]: isResolved,
  });

  const comKeyStyles = classNames({
    [styles.inlineComment]: true,
    [styles.resolvedComment]: isResolved,
    [styles.hasKeyword]: true,
  });

  const handleClick = () => {
    onClick(ref);
  };

  const [rangeStart, rangeEnd, rangeBlockKey] =
    ranges.get(`${type}-${start}-${end}-${blockKey}`) || [];
  if (type === "comment") {
    if (
      rangeStart === start &&
      rangeEnd === end &&
      rangeBlockKey === blockKey
    ) {
      return (
        <span
          key={type}
          ref={ref}
          className={inlineCommentStyles}
          onClick={handleClick}
        >
          {children}
        </span>
      );
    }
  }
  if (type === "keyword") {
    if (
      rangeStart === start &&
      rangeEnd === end &&
      rangeBlockKey === blockKey
    ) {
      return (
        <span key={type} ref={ref} className={styles.hasKeyword}>
          {children}
        </span>
      );
    }
  }
  if (type === "comKey") {
    if (
      rangeStart === start &&
      rangeEnd === end &&
      rangeBlockKey === blockKey
    ) {
      return (
        <span
          key={type}
          ref={ref}
          className={comKeyStyles}
          onClick={handleClick}
        >
          {children}
        </span>
      );
    }
  }
  return null;
};

export default WordSegments;
