import { getGraphQL } from "../../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../../modules/projectLanguages";
import {
  ratesByProjectIdQuery,
  fetchRatesSuccess,
} from "../../../../../../../modules/rates";

export const initialQuery = `query projectRates ($projectId: String){
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
  ${ratesByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchParentAccountsSuccess(json.parentAccounts));
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
      dispatch(fetchRatesSuccess(json.rates));
    });
  };
}
