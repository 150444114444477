import { connect } from "react-redux";
import { projectById } from "../../../../../../utils/entitySelector";
import Layout from "../components/Layout";

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { projectId },
    },
  } = ownProps;
  const project = projectById(state, projectId);
  const { featureToggles } = state;

  return { featureToggles, project, projectId };
};

export default connect(mapStateToProps)(Layout);
