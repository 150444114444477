import { createSelector } from "reselect";
import { connect } from "react-redux";
import RoleSelector from "../components/RoleSelector";
import { peopleSelector } from "../../../utils/entitySelector";

const rolesSelector = createSelector(peopleSelector, (people) =>
  Object.keys(
    people.reduce((acc, { roles }) => {
      roles && roles.forEach((role) => (acc[role] = true));
      return acc;
    }, {})
  )
    .filter((item) => item !== undefined)
    .sort()
);

const mapStateToProps = (state) => {
  const filteredRoles = rolesSelector(state);

  return {
    filteredRoles,
  };
};

export default connect(mapStateToProps)(RoleSelector);
