import React from "react";
import PropTypes from "prop-types";
import OverlayLoading from "../../../../../../../components/OverlayLoading/v1";
import ProgressTab from "../../../../../../../components/ProgressTab/v3";

export const InProgress = (props) => {
  const { dataReady, featureToggles, history, location, projectId } = props;
  if (!dataReady) return <OverlayLoading />;

  const baseUrl = `/clients/projects/${projectId}`;

  return (
    <ProgressTab
      baseUrl={baseUrl}
      featureToggles={featureToggles}
      history={history}
      location={location}
      projectId={projectId}
      selectedStageTypes={["Amends", "Client"]}
    />
  );
};

InProgress.propTypes = {
  dataReady: PropTypes.bool.isRequired,
  featureToggles: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default InProgress;
