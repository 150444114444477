import PeopleNew from "./containers/PeopleNew";
import {
  peopleActionHandlers,
  peopleInitialState,
} from "../../../../modules/people";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../../modules/accounts";

export default {
  Component: PeopleNew,
  reducers: [
    {
      key: "people",
      actionHandlers: peopleActionHandlers,
      initialState: peopleInitialState,
    },
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
  ],
};
