import Papa from "papaparse";
import { postGraphQL } from "../../../../../../../utils/fetch";
import { createParentDeliverablesSuccess } from "../../../../../../modules/parentDeliverables";
import { createDeliverablesSuccess } from "../../../../../../modules/deliverables";
import { createKeywordsSuccess } from "../../../../../../modules/keywords";
import { createParentDeliverableBriefingFieldsSuccess } from "../../../../../../modules/parentDeliverableBriefingFields";
import {
  createParentDeliverableSourceFieldsSuccess,
  addRawContentToParentDeliverableSourceField,
} from "../../../../../../modules/parentDeliverableSourceFields";
import { extractErrorFromObject } from "../../../../../../utils/errors";

export function processFileHandler(data, featureToggles) {
  return (dispatch, getState) => {
    const {
      sourceFields: { entities: sfEntities },
    } = getState();

    return new Promise((resolve, reject) => {
      const {
        briefingFields,
        sourceFields,
        keywordGroups,
        batchId,
        projectId,
        file,
        primaryStageId,
        projectLanguages,
        languagesField,
        rateBands,
      } = data;
      const parentDeliverable = { projectId, batchId };
      const rateBandColumnCount = featureToggles.QCC_1081_rateBands
        ? rateBands.length > 1
          ? 1
          : 0
        : 0;
      const expectedColumnLength =
        briefingFields.length +
        sourceFields.length +
        keywordGroups.length +
        rateBandColumnCount;

      Papa.parse(file, {
        delimiter: ",",
        newline: "\n",
        complete: (results) => {
          const { data } = results;
          const [headers, ...rows] = data;
          const deliverables = projectLanguages
            .filter(({ languageCode }) =>
              languagesField.languages?.includes(languageCode)
            )
            .map(({ languageCode }) => ({
              languageCode,
              currentStageId: primaryStageId,
            }));
          const parentDeliverables = [];
          const usedHeaderNames = [];
          const errors = [];
          /*  Check the number of headers to ensure there is an exact length match
              when compared to the database values we expect to append data to */
          if (headers.length > expectedColumnLength)
            errors.push("Too many columns");
          if (headers.length < expectedColumnLength)
            errors.push("Too few columns");

          /*  The mapping table is intended to filter values from our rows to the relevant
              entity. For each relevant entry, we store the index, where the index
              corresponds to the column that the value was sourced from (i.e. a column
              from a CSV). We then build parentDeliverables by grabbing all relevant records
              from the appropriate entity and index(es) */
          const mappingTable = {
            briefingFields: [],
            sourceFields: [],
            keywords: [],
            rateBandIndex: -1,
          };

          headers.forEach((header, index) => {
            header = header.replace(/\s*\(source\)\s*/g, "");
            if (usedHeaderNames.includes(header))
              errors.push(`Duplication column detected: ${header}`);
            usedHeaderNames.push(header);

            let invalidColumnName = true;

            briefingFields.forEach((briefingField) => {
              const { briefingFieldId, briefingFieldName } = briefingField;
              if (briefingFieldName.toLowerCase() === header.toLowerCase()) {
                invalidColumnName = false;
                mappingTable.briefingFields.push({
                  briefingFieldId,
                  briefingFieldName,
                  index,
                });
              }
            });

            sourceFields.forEach((sourceField) => {
              const { sourceFieldId, taskFieldName } = sourceField;
              if (taskFieldName.toLowerCase() === header.toLowerCase()) {
                invalidColumnName = false;
                mappingTable.sourceFields.push({
                  sourceFieldId,
                  taskFieldName,
                  index,
                });
              }
            });

            keywordGroups.forEach((keywordGroup) => {
              const { keywordGroupId, keywordGroupName } = keywordGroup;
              if (keywordGroupName.toLowerCase() === header.toLowerCase()) {
                invalidColumnName = false;
                mappingTable.keywords.push({
                  keywordGroupId,
                  keywordGroupName,
                  index,
                });
              }
            });

            rateBands.forEach(({ rateBandName }) => {
              if (header === "Band") {
                invalidColumnName = false;
                mappingTable.rateBandIndex = index;
              }
            });

            if (invalidColumnName)
              errors.push(`Incorrectly labelled column (${header})`);
          });

          // if we need a rateBand column but a header wasn't found
          if (rateBandColumnCount && mappingTable.rateBandIndex === -1)
            errors.push("You need to have a rate band column");

          rows.forEach((row, rowIndex) => {
            // We do not insert a parentDeliverable if all of the columns are empty strings
            if (!row.some((columnValue) => columnValue !== "")) return;
            const insertLength = parentDeliverables.push({
              parentDeliverable: { ...parentDeliverable },
              deliverables,
              keywords: [],
              parentDeliverableBriefingFields: [],
              parentDeliverableSourceFields: [],
            });

            row.forEach((columnValue, columnIndex) => {
              const parentDeliverable = parentDeliverables[insertLength - 1];
              mappingTable.briefingFields.forEach((briefingField) => {
                const { briefingFieldId, index } = briefingField;
                if (index === columnIndex)
                  parentDeliverable.parentDeliverableBriefingFields.push({
                    briefingFieldId,
                    fieldValue: columnValue,
                  });
              });

              mappingTable.sourceFields.forEach((sourceField) => {
                const { sourceFieldId, index } = sourceField;
                if (index === columnIndex) {
                  const pdsf = addRawContentToParentDeliverableSourceField(
                    { sourceFieldId, fieldValue: columnValue },
                    sfEntities,
                    true
                  );

                  parentDeliverable.parentDeliverableSourceFields.push(pdsf);
                }
              });

              mappingTable.keywords.forEach((keyword) => {
                const { keywordGroupId, index } = keyword;
                if (index === columnIndex) {
                  /*  Because we expect users to separate keywords with commas,
                      we need to handle this here */
                  columnValue.split(",").forEach((word) => {
                    parentDeliverable.keywords.push({ keywordGroupId, word });
                  });
                }
              });

              if (featureToggles && featureToggles.QCC_1081_rateBands) {
                if (mappingTable.rateBandIndex === columnIndex) {
                  // just remove quote marks and trim whitespace to make it easier for selecting the rate band
                  const trimmedValue = columnValue.replace(/"+/g, "").trim();
                  const rateBand = rateBands.find(
                    ({ rateBandName }) => rateBandName === trimmedValue
                  );
                  if (!rateBand) {
                    errors.push(
                      `Cannot find a rate band on this project called: "${trimmedValue}"`
                    );
                  } else {
                    parentDeliverable.parentDeliverable.rateBandId =
                      rateBand.rateBandId;
                  }
                }

                // if the project has 1 rateBand then use that as the parentDeliverables rateBand
                if (rateBands.length === 1) {
                  parentDeliverable.parentDeliverable.rateBandId =
                    rateBands[0].rateBandId;
                }
              }
            });
          });

          /*  If errors exist, then update Component state to reflect this and do not
              send any query to the backend */
          if (errors.length) return reject(errors);

          const query = `mutation createParentDeliverablesWithDependencies ($input: [ParentDeliverablesInput]) {
            createParentDeliverables (input: $input) {
              parentDeliverables { parentDeliverableId, projectId, batchId, rateBandId },
              keywords { keywordId, keywordGroupId, parentDeliverableId, word },
              parentDeliverableBriefingFields { parentDeliverableBriefingFieldId, parentDeliverableId, briefingFieldId, fieldValue },
              parentDeliverableSourceFields { parentDeliverableSourceFieldId, parentDeliverableId, sourceFieldId, fieldValue },
              deliverables { deliverableId, parentDeliverableId, languageCode, currentStageId, allocated }
            }
          }`;

          return postGraphQL(query, { input: parentDeliverables })
            .then((json) => {
              const {
                parentDeliverables,
                keywords,
                parentDeliverableBriefingFields,
                parentDeliverableSourceFields,
                deliverables,
              } = json.createParentDeliverables;

              dispatch(createParentDeliverablesSuccess(parentDeliverables));
              dispatch(
                createParentDeliverableBriefingFieldsSuccess(
                  parentDeliverableBriefingFields
                )
              );
              dispatch(
                createParentDeliverableSourceFieldsSuccess(
                  parentDeliverableSourceFields
                )
              );
              dispatch(createDeliverablesSuccess(deliverables));
              dispatch(createKeywordsSuccess(keywords));
              resolve();
            })
            .catch((errors) => {
              return reject(extractErrorFromObject(errors));
            });
        },
      });
    });
  };
}
