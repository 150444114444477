import { createAction } from "redux-actions";
import { postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";
import { showErrorMessage, showSuccessMessage } from "./messagesV2";

export const exportFormatFields = [
  "projectId",
  "bold",
  "briefingFields",
  "bullets",
  "formatting",
  "linebreaks",
  "sourceFields",
  "special",
  "other",
  "emptyLinebreaks",
  "docExport",
  "docExportName",
  "docExportTaskFieldId",
  "docExportBriefingFields",
  "docExportBriefingFieldId",
];

export const exportFormatByProjectIdQuery = `exportFormat (projectId: $projectId) {
  ${exportFormatFields}
}`;

export const FETCH_EXPORT_FORMAT_SUCCESS = "FETCH_EXPORT_FORMAT_SUCCESS";

const EXPORT_FORMAT_UPDATE_SUCCESS_TEXT =
  "The export format was saved in the database!";
const EXPORT_FORMAT_UPDATE_ERROR_TEXT = "The export format could not be saved.";

export const fetchExportFormatSuccess = createAction(
  FETCH_EXPORT_FORMAT_SUCCESS
);

export function updateExportFormat(
  { projectId, exportNameConfiguration, ...values },
  history
) {
  return async (dispatch) => {
    const query = `mutation updateExportFormat($input: ExportFormatInput){
      updateExportFormat(input: $input) {
        ${exportFormatFields}
      }
    }`;

    try {
      const input = {
        projectId,
        ...values,
      };

      const json = await postGraphQL(query, { input }, "updateExportFormat");

      dispatch(fetchExportFormatSuccess(json));
      dispatch(showSuccessMessage(EXPORT_FORMAT_UPDATE_SUCCESS_TEXT));

      history.push(`/admin/projects/${projectId}`);
    } catch (err) {
      dispatch(showErrorMessage(EXPORT_FORMAT_UPDATE_ERROR_TEXT));
    }
  };
}

export const exportFormatInitialState = {};

export const exportFormatActionHandlers = {
  [RESET_INITIAL_STATE]: () => exportFormatInitialState,
  [FETCH_EXPORT_FORMAT_SUCCESS]: (
    state,
    { payload: { projectId, ...options } }
  ) => ({
    ...state,
    [projectId]: options,
  }),
};
