import React, { Component } from "react";

/**
 * Scrolls the window to the top-left when the component is mounted
 */
export default function (WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
