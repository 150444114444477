import { PureComponent } from "react";
import PropTypes from "prop-types";
import { RichUtils } from "draft-js";
import Editor from "draft-js-plugins-editor";
import classNames from "classnames";
import { getVisibleSelectionRect, KeyBindingUtil } from "draft-js";
import { HoverButton } from "../../../_plugins/InlineComments";
import CommentGroup from "../../../_components/CommentGroup";
import {
  addInlineStyle,
  handleReadOnly,
  removeInlineStyle,
} from "../../../editorUtils";
import styles from "./TaskEditor.module.scss";

const { hasCommandModifier } = KeyBindingUtil;

class TaskEditor extends PureComponent {
  handleKeyCommand = (command, newEditorState) => {
    if (this.props.isTargetEditor && command === "split-block") {
      return "handled";
    }

    const newState = RichUtils.handleKeyCommand(newEditorState, command);
    if (newState) {
      // directly update with parent as it's just inline styling
      this.props.updateEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  keyBindingFn = (e) => {
    const returnKey = e.keyCode === 13;

    if (!this.props.isConfirmed && returnKey && hasCommandModifier(e)) {
      this.props.confirmSegment();
    }
  };

  updateEditorState = (editorState) => {
    if (this.props.readOnly) return;

    // NOTE: a bug has appeared with an updated package which causes an infinite
    // render loop with empty task segments which means we need to be selective
    // on when a draft js editor updates
    const prevContent = this.props.editorState.getCurrentContent();
    const newContent = editorState.getCurrentContent();
    const sameContent = prevContent === newContent;

    // first check if the content is different (cheap operation)
    if (sameContent) {
      const prevSelection = this.props.editorState.getSelection();
      const newSelection = editorState.getSelection();
      const sameSelection =
        JSON.stringify(prevSelection) === JSON.stringify(newSelection);

      // check if we can bail out of the update if the selection is the same
      if (sameSelection) return;
    }

    this.props.updateEditorState(editorState);
  };

  onFocus = () => {
    const { draftKey, onFocus } = this.props;
    if (onFocus) {
      onFocus(draftKey);
    }
  };

  /**
   * @param {string} styleName the style name to add
   * @param {Object} [selection] draft js selection to apply the style to
   * @param {string} [changeType] the change type
   */
  addStyle = (styleName, selection, changeType) => {
    this.props.updateEditorState(
      addInlineStyle(this.props.editorState, styleName, selection, changeType)
    );
  };

  /**
   * @param {string} styleName the style name to add
   * @param {Object} [selection] draft js selection to apply the style to
   * @param {string} [changeType] the change type
   */
  removeStyle = (styleName, selection, changeType = "remove-inline-style") => {
    this.props.updateEditorState(
      removeInlineStyle(
        this.props.editorState,
        styleName,
        selection,
        changeType
      )
    );
  };

  closeCommentBox = () => {
    this.props.closeCommentBox(this.props.draftKey);
  };

  /**
   * @param {Object} positionRect position area rectangle
   * @param {string} type the type of comment (inline, field)
   * @param {number} [commentGroupId]
   * @param {Object} [selection] draft js selection
   */
  openCommentBox = (positionRect, type, commentGroupId, selection) => {
    const selectedCommentGroup = {
      commentGroupId,
      positionRect,
      selection,
      type,
    };

    this.props.updateSelectedCommentGroup(
      this.props.draftKey,
      selectedCommentGroup
    );
  };

  onClick = () => {
    if (!this.props.readOnly && this.props.isConfirmed) {
      this.props.unconfirmSegment();
    }
  };

  onCommentAdded = ({ commentGroupId }) => {
    // Ensure rawContent inline-styles are saved
    this.props.saveConfirmedSegments();

    const { draftKey, selectedCommentGroup, updateSelectedCommentGroup } =
      this.props;

    if (commentGroupId !== selectedCommentGroup.commentGroupId) {
      updateSelectedCommentGroup(draftKey, {
        ...(selectedCommentGroup || {}),
        commentGroupId,
      });
    }
  };

  renderCommentHoverButton = () => {
    const { editorState, isCommentable, selectedCommentGroup } = this.props;

    // Hover new comment group button above selected text
    const selection = editorState.getSelection();

    if (
      typeof window !== "undefined" &&
      isCommentable &&
      !selectedCommentGroup &&
      selection.getHasFocus()
    ) {
      const selectionRect = getVisibleSelectionRect(window);

      if (selectionRect && selectionRect.width > 1) {
        selectionRect.top += window.pageYOffset;
        selectionRect.bottom += window.pageYOffset;

        return (
          <HoverButton
            openCommentBox={this.openCommentBox}
            selectionRect={selectionRect}
          />
        );
      }
    }
  };

  renderCommentGroup = () => {
    const {
      deliverableId,
      isCommentable,
      selectedCommentGroup,
      stageId,
      taskFieldId,
    } = this.props;

    if (selectedCommentGroup) {
      const { commentGroupId, positionRect, selection, type } =
        selectedCommentGroup;

      return (
        <CommentGroup
          addInlineStyle={this.addStyle}
          commentGroupId={commentGroupId}
          commentType={type}
          deliverableId={deliverableId}
          isCommentable={isCommentable}
          onClose={this.closeCommentBox}
          onCommentAdded={this.onCommentAdded}
          position={positionRect}
          removeInlineStyle={this.removeStyle}
          selection={selection}
          stageId={stageId}
          taskFieldId={taskFieldId}
        />
      );
    }
  };

  updateSegmentEditorRef = (ref) => {
    const { updateSegmentEditorRef, draftKey, isConfirmed } = this.props;

    if (updateSegmentEditorRef) {
      updateSegmentEditorRef(draftKey, !isConfirmed && ref);
    }
  };

  render() {
    const {
      decorators,
      editorState,
      isConfirmed,
      isTargetEditor,
      plugins = [],
      readOnly,
    } = this.props;

    const editorStyles = classNames({
      [styles.taskEditor]: true,
      [styles.targetEditor]: isTargetEditor,
      [styles.confirmed]: isConfirmed,
      [styles.unconfirmed]: !isConfirmed,
      [styles.editable]: !readOnly,
    });

    return (
      <div className={editorStyles} onClick={this.onClick}>
        <Editor
          // content editable doesn't allow for turning off spellcheck once activated
          // so we unmount and remount the component to toggle the spellcheck
          ref={this.updateSegmentEditorRef}
          decorators={decorators}
          editorState={editorState}
          handleKeyCommand={this.handleKeyCommand}
          keyBindingFn={
            readOnly || isConfirmed ? handleReadOnly : this.keyBindingFn
          }
          onChange={this.updateEditorState}
          onFocus={this.onFocus}
          plugins={plugins}
          readOnly={readOnly}

          // TODO: Reimplement spellcheck with https://quillcontent.atlassian.net/browse/QCC-1868
          // key={spellCheck}
          // spellCheck={spellCheck}
        />

        {/*
        For now we have disabled inline functionality for TM segments
        { this.renderCommentHoverButton() }
        { this.renderCommentGroup() }
         */}
      </div>
    );
  }
}

TaskEditor.propTypes = {
  closeCommentBox: PropTypes.func,
  confirmSegment: PropTypes.func,
  decorators: PropTypes.array,
  deliverableId: PropTypes.number,
  draftKey: PropTypes.string,
  editorState: PropTypes.object.isRequired,
  isCommentable: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  isTargetEditor: PropTypes.bool,
  onFocus: PropTypes.func,
  plugins: PropTypes.array,
  readOnly: PropTypes.bool,
  saveConfirmedSegments: PropTypes.func,
  selectedCommentGroup: PropTypes.shape({
    commentGroupId: PropTypes.number,
    positionRect: PropTypes.object,
    selection: PropTypes.object,
    type: PropTypes.string,
  }),
  stageId: PropTypes.number,
  taskFieldId: PropTypes.number,
  unconfirmSegment: PropTypes.func,
  updateEditorState: PropTypes.func,
  updateSegmentEditorRef: PropTypes.func,
  updateSelectedCommentGroup: PropTypes.func,
};

export default TaskEditor;
