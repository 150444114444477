import { connect } from "react-redux";
import ByDeliverable from "./ByDeliverable";
import ScrollToTop from "../../../../decorators/ScrollToTop";
import { deliverableById } from "../../../../utils/entitySelector";

const mapStateToProps = (state, { deliverableId }) => {
  const deliverable = deliverableById(state, deliverableId);

  return {
    deliverable,
  };
};

export default connect(mapStateToProps)(ScrollToTop(ByDeliverable));
