import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import { postJson, getJson } from "../../utils/fetch";
import {
  deliverableById,
  projectById,
  stageById,
} from "../utils/entitySelector";

const url = () =>
  `${
    window.__REACT_APP_API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY
  }/translation-memory`;

export const TranslationCertainty = Object.freeze({
  Text: "TEXT_MATCH",
  Vertical: "VERTICAL_MATCH",
  Full: "FULL_MATCH",
  MachineTranslation: "MACHINE_TRANSLATION",
});

/**
 * Fetches translations and adds them to the store
 *
 * @param   {number}      accountId
 * @param   {string}      verticalType
 * @param   {string}      sourceLanguage
 * @param   {string}      targetLanguage
 * @param   {Object}      segments
 * @param   {Set<string>} segments.includeMT segments to translate with MT
 * @param   {Set<string>} segments.excludeMT segments to translate without MT
 * @returns {function}    function that returns dispatcher
 */
export function fetchTranslations(
  accountId,
  verticalType,
  sourceLanguage,
  targetLanguage,
  segments
) {
  return async (dispatch) => {
    const context = { accountId, verticalType, sourceLanguage, targetLanguage };
    const { translations } = await postJson(`${url()}/v2/translate`, {
      ...context,
      segments,
    });

    dispatch(
      fetchTranslationsSuccess({
        context,
        translations,
      })
    );
  };
}

/**
 * Request all similar glossary terms from the TM service
 *
 * @param {Object} context
 * @param {string} searchTerm
 */
export async function fetchGlossaryMatches(context, searchTerm) {
  const { matches } = await getJson(`${url()}/v1/glossary-lookup`, {
    ...context,
    searchTerm,
  });

  return matches;
}

/**
 * Create a new translation
 *
 * @param {Object} request
 * @param {number} request.accountId
 * @param {number} request.deliverableId
 * @param {number} request.personId
 * @param {string} request.sourceLanguage
 * @param {string} request.sourceSegment
 * @param {number} request.stageId
 * @param {string} request.translatedLanguage
 * @param {string} request.translatedSegment
 * @param {string} request.verticalType
 */
export function createTranslation(request) {
  return async (dispatch) => {
    const {
      translation: {
        context: { accountId, verticalType },
        sourceSegment: { segmentText: source, languageCode: sourceLanguage },
        translatedSegment: {
          segmentText: target,
          languageCode: targetLanguage,
        },
      },
    } = await postJson(`${url()}/v1/translation`, request);

    dispatch(
      fetchTranslationsSuccess({
        context: { accountId, verticalType, sourceLanguage, targetLanguage },
        translations: [
          { source, target, certainty: TranslationCertainty.Full },
        ],
      })
    );
  };
}

/**
 * Create a key that identifies a given translation context
 *
 * @param   {Object} context
 * @param   {number} context.accountId
 * @param   {string} context.verticalType
 * @param   {string} context.sourceLanguage
 * @param   {string} context.targetLanguage
 * @returns {string} key
 */
function getContextKey({
  accountId,
  verticalType,
  sourceLanguage,
  targetLanguage,
}) {
  return `${accountId}_${
    verticalType || ""
  }_${sourceLanguage}_${targetLanguage}`;
}

/**
 * Converts an exact piece of source text to a fuzzy match string
 *
 * @param   {string} source
 * @returns {string} key
 */
export function getTranslationKey(source) {
  return source.toLowerCase().trim();
}

/**
 * Retrieve a translation
 *
 * @param   {Object}  translations translations indexed by key
 * @param   {string}  source source text to translate
 * @returns {Object}  { source, target, certainty }, or undefined if none
 */
export function getTranslation(translations, source) {
  return translations[getTranslationKey(source)];
}

/**
 * @param {Object} state
 * @param {number} deliverableId
 * @param {number} projectId
 */
export const getTranslationMemoryContext = createSelector(
  deliverableById,
  (state, _, projectId) => projectById(state, projectId),
  ({ languageCode }, { accountId, verticalType, sourceLanguage }) => ({
    accountId,
    verticalType,
    sourceLanguage,
    // TODO: Refactor target/translated terms into one single term across all repos
    // https://quillcontent.atlassian.net/browse/QCC-1836
    targetLanguage: languageCode,
    translatedLanguage: languageCode,
  })
);

/**
 * Figure out if the current assignment should show TM features
 *
 * @param {Object} state
 * @param {number} projectId
 * @param {number} stageId
 */
export const shouldShowTM = createSelector(
  projectById,
  (state, _p, stageId) => stageById(state, stageId),
  ({ isProjectTM }, { stageName }) => {
    return isProjectTM && stageName === "AMT";
  }
);

/**
 * Retrieve a set of translations for a given context
 *
 * @param {Object} state
 * @param {Object} context
 * @param {number} context.accountId
 * @param {string} context.verticalType
 * @param {string} context.sourceLanguage
 * @param {string} context.targetLanguage
 * @returns {Object} translations indexed by fuzzy-match key
 */
const nullContext = Object.freeze({});
export function getContextTranslations(state, context) {
  const contextKey = getContextKey(context);
  return state.translations[contextKey] || nullContext;
}

/**
 * Actions
 */
const FETCH_TRANSLATIONS_SUCCESS = "FETCH_TRANSLATIONS_SUCCESS";
export const fetchTranslationsSuccess = createAction(
  FETCH_TRANSLATIONS_SUCCESS
);

export const translationsActionHandlers = {
  [FETCH_TRANSLATIONS_SUCCESS]: (
    state,
    { payload: { context, translations } }
  ) => {
    // Retrieve/initialise context
    const contextKey = getContextKey(context);
    let contextTranslations = state[contextKey] || {};

    // Get new/changed translations
    const newTranslations = translations
      .map((t) => ({ ...t, key: getTranslationKey(t.source) }))
      .filter(({ key, target, certainty }) => {
        const current = contextTranslations[key];
        return (
          !current ||
          current.target !== target ||
          current.certainty !== certainty
        );
      });

    if (newTranslations.length === 0) {
      return state; // No changes
    }

    // Add translations to context
    contextTranslations = {
      ...contextTranslations,
      ...newTranslations.reduce((acc, cur) => {
        acc[cur.key] = cur;
        return acc;
      }, {}),
    };

    // Update state
    return Object.freeze({
      ...state,
      [contextKey]: Object.freeze(contextTranslations),
    });
  },
};

export const translationsInitialState = Object.freeze({});
