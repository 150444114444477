import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { QuillForm } from "../QuillForm";
import StageSection from "./StageSection";
import styles from "./AssignmentsForm.module.scss";
import validate from "./validations";
import addTrainingDeliverablesToStages from "./utility";

const AssignmentsForm = (props) => {
  const {
    assignmentData,
    defaultAssignees,
    languageCodes,
    featureToggles,
    numParentDeliverables,
    people,
    personGrades,
    projectLanguages,
    reassignment,
    selectedAssignment,
    selectedBatchId,
    trainingStage,
    projectId,
    unallocatedLanguages,
  } = props;
  const stagesWithTraining =
    assignmentData && !trainingStage
      ? addTrainingDeliverablesToStages([...props.stages], assignmentData)
      : [...props.stages];

  const stages = trainingStage
    ? stagesWithTraining.filter((s) => s.stageType === "Training")
    : stagesWithTraining;

  return (
    <QuillForm
      key={selectedBatchId}
      buttonContainerStyles={styles.buttonContainer}
      cancelHandler={props.cancelHandler}
      cancelText="Cancel"
      cancelUrl={props.cancelUrl}
      disabled={props.submitting || !props.valid}
      error={props.error}
      handleSubmit={props.handleSubmit}
      header={props.header}
      headerNote={
        featureToggles.QCC_1798_freelancerGrading &&
        "* Grades averaged across this order form"
      }
      hideButtons={props.isInlineForm}
      hideRequiredHeader
      submitText={props.submitText}
    >
      {stages.map((stage, idx) => {
        const { stageId } = stage;

        // Only remove the 'reassignmentAssignee' from the first stage if it's set (which is only on the reassignment form)
        let stagePeople = people;
        if (props.reassignmentAssignee && idx === 0) {
          stagePeople = { ...people };
          delete stagePeople[props.reassignmentAssignee];
        }

        const stageData = assignmentData?.[stageId] || {};

        return (
          <StageSection
            key={stageId}
            defaultAssignees={defaultAssignees}
            featureToggles={featureToggles}
            languageCodes={languageCodes}
            numParentDeliverables={numParentDeliverables}
            people={stagePeople}
            personGrades={personGrades}
            projectId={projectId}
            projectLanguages={projectLanguages}
            reassignment={reassignment}
            selectedAssignment={selectedAssignment}
            selectedBatchId={selectedBatchId}
            stage={stage}
            stageData={stageData}
            trainingStage={trainingStage}
            unallocatedLanguages={unallocatedLanguages}
          />
        );
      })}
    </QuillForm>
  );
};

AssignmentsForm.propTypes = {
  assignmentData: PropTypes.object,
  cancelHandler: PropTypes.func,
  cancelUrl: PropTypes.string,
  defaultAssignees: PropTypes.object.isRequired,
  error: PropTypes.string,
  featureToggles: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  numParentDeliverables: PropTypes.number.isRequired,
  people: PropTypes.object.isRequired,
  personGrades: PropTypes.object.isRequired,
  projectLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      languageCode: PropTypes.string.isRequired,
    })
  ).isRequired,
  reassignmentAssignee: PropTypes.number,
  selectedAssignment: PropTypes.object,
  selectedBatchId: PropTypes.number,
  languageCodes: PropTypes.arrayOf(PropTypes.string),
  unallocatedLanguages: PropTypes.object,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      stageId: PropTypes.number.isRequired,
      stageName: PropTypes.string.isRequired,
    })
  ).isRequired,
  submitText: PropTypes.string.isRequired, // only used for reassignment form to hide assignee for first stage (and not subsequent)
  submitting: PropTypes.bool,
  trainingStage: PropTypes.bool,
  valid: PropTypes.bool,
};

const AssignmentsFormReduxForm = reduxForm({
  form: "assignmentsForm",
  validate,
  shouldValidate: () => true,
})(AssignmentsForm);

const ConnectedAssignmentsForm = connect((state) => ({
  assignmentData: state.form.assignmentsForm?.values?.assignees || {},
}))(AssignmentsFormReduxForm);

export default ConnectedAssignmentsForm;
