import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  peopleQueryV2,
  fetchPeopleSuccess,
} from "../../../../../../modules/people";

export const initialQuery = `query fetchPeople {
  ${peopleQueryV2}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) =>
      dispatch(fetchPeopleSuccess(json.peopleV2))
    );
  };
}
