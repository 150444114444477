import React from "react";
import PropTypes from "prop-types";
import styles from "./WordCount.module.scss";

function WordCount({ count }) {
  return (
    <div className={styles.wordCount}>
      <span className={styles.label}>Words: </span>
      <span className={styles.count}>{count}</span>
    </div>
  );
}

WordCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default WordCount;
