import React from "react";
import PropTypes from "prop-types";
import styles from "./BriefingFieldHeader.module.scss";
import { rowGridStyles } from "../../utils/gridHelpers";

const BriefingFieldHeader = (props) => {
  const {
    allChecked,
    briefingFields,
    disabled,
    includeDeliverableId,
    includePreviousAssignee,
    includeRates,
    includeStatus,
    toggleAll,
  } = props;

  const renderCell = (text, key) => (
    <div key={key} className={styles.cell}>
      {text}
    </div>
  );

  const gridStyles = rowGridStyles(briefingFields.length, {
    includePreviousAssignee,
    includeRates,
    includeStatus,
    includeDeliverableId,
  });

  return (
    <div className={styles.headerRow} style={gridStyles}>
      <div className={styles.cellCentered}>
        <div className={styles.checkboxContainer}>
          <input
            checked={disabled ? false : allChecked}
            disabled={disabled}
            id="all"
            onChange={toggleAll}
            type="checkbox"
          />
          <label
            className={styles.label}
            htmlFor="all"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="hoverShadow"></span>
          </label>
        </div>
      </div>
      {includeDeliverableId && renderCell("ID")}
      {includePreviousAssignee && renderCell("Previous assignee")}
      {includeRates && renderCell("Rate")}
      {includeStatus && renderCell("Status")}
      {briefingFields.map(({ briefingFieldId, briefingFieldName }) =>
        renderCell(briefingFieldName, briefingFieldId)
      )}
    </div>
  );
};

BriefingFieldHeader.propTypes = {
  allChecked: PropTypes.bool.isRequired,
  briefingFields: PropTypes.arrayOf(
    PropTypes.shape({
      briefingFieldId: PropTypes.number.isRequired,
      briefingFieldName: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  includeDeliverableId: PropTypes.bool,
  includePreviousAssignee: PropTypes.bool,
  includeRates: PropTypes.bool,
  includeStatus: PropTypes.bool,
  toggleAll: PropTypes.func.isRequired,
};

export default BriefingFieldHeader;
