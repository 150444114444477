import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import { RESET_INITIAL_STATE } from "./me";
import { setLSItemV2 } from "../utils/localStorage";
import { nullObject } from "../utils/nullObjects";

// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_APP_COMPONENT = "SHOW_APP_COMPONENT";
export const HIDE_APP_COMPONENT = "HIDE_APP_COMPONENT";
export const SET_APP_COMPONENT_DATA = "SET_APP_COMPONENT_DATA";

const LOCAL_STORAGE_KEY = "app-components";

/**
 * Retrieve appComponent data from the store or localStorage
 *
 * @param   {Object}  state
 * @param   {string}  componentName the name of the app component
 * @returns {Object}  appComponent data
 */
export const appComponentSelector = createSelector(
  (state, componentName) => {
    // If state.appComponents is not defined then state has already been reduced
    const appComponents = state.appComponents || state;
    return appComponents[componentName] || nullObject;
  },
  (_, componentName) => componentName,
  () => typeof window !== "undefined" && window.localStorage[LOCAL_STORAGE_KEY],
  (appComponent, componentName, localStorageString) => {
    let localStorageData;

    try {
      const parsed = localStorageString && JSON.parse(localStorageString);
      const componentData = parsed[componentName];

      if (typeof componentData === "object") {
        localStorageData = componentData;
      }
    } catch (e) {}

    return { ...appComponent, ...localStorageData };
  }
);

// ------------------------------------
// Actions
// ------------------------------------
export const showAppComponent = createAction(SHOW_APP_COMPONENT);
export const hideAppComponent = createAction(HIDE_APP_COMPONENT);
export const setAppComponentData = createAction(SET_APP_COMPONENT_DATA);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const appComponentActionHandlers = {
  [RESET_INITIAL_STATE]: () => appComponentInitialState,
  [SHOW_APP_COMPONENT]: (state, { payload: component }) => ({
    ...state,
    [component]: { ...state[component], display: true },
  }),
  [HIDE_APP_COMPONENT]: (state, { payload: component }) => ({
    ...state,
    [component]: { ...state[component], display: false },
  }),
  [SET_APP_COMPONENT_DATA]: (state, { payload: { component, data } }) => {
    const oldData = appComponentSelector(state, component);
    const newData = { ...oldData, ...data };
    setLSItemV2([LOCAL_STORAGE_KEY, component], newData);
    return { ...state, [component]: newData };
  },
};

export const appComponentInitialState = {
  batchFeedbackPanel: { display: false },
  overviewList: {
    activeLanguage: "",
    readyForReview: true,
  },
  taskSidePanel: { display: true },
};
