import { connect } from "react-redux";
import AttachmentThumbnails from "../components/AttachmentThumbnails";
import WithData from "../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { attachmentsByParentDeliverable } from "../../../utils/entitySelector";

const mapDispatchToProps = (dispatch, { parentDeliverableId }) => {
  return {
    getInitialData: () => dispatch(getInitialData({ parentDeliverableId })),
  };
};

const mapStateToProps = (state, { parentDeliverableId }) => {
  const attachments = attachmentsByParentDeliverable(
    state,
    parentDeliverableId
  );
  return {
    attachments,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AttachmentThumbnails));
