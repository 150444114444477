import { getGraphQL } from "../../../../../utils/fetch";
import {
  accountsQuery,
  fetchAccountsSuccess,
} from "../../../../modules/accounts";
import { fetchFavouritesSuccess } from "../../../../modules/favourites";
import {
  fetchOrderFormsSuccess,
  orderFormsQuery,
} from "../../../../modules/orderForms";
import {
  fetchParentAccountsSuccess,
  parentAccountsQuery,
} from "../../../../modules/parentAccounts";
import {
  fetchProjectsSuccess,
  projectsQuery,
} from "../../../../modules/projects";

export const initialQuery = `query getParentAccountAndAccounts {
  ${projectsQuery}
  ${orderFormsQuery}
  ${accountsQuery}
  ${parentAccountsQuery}
  favourites {
    favouriteId, parentAccountId, accountId, orderFormId, projectId
  } 
}`;

export function getInitialData(params) {
  return async (dispatch, getState) => {
    const {
      parentAccounts: { result: parentAccountId },
    } = getState();
    const params = { parentAccountId }; // Represents multiple parentAccountIds

    const { parentAccounts, favourites, projects, orderForms, accounts } =
      await getGraphQL(initialQuery, params);

    dispatch(fetchParentAccountsSuccess(parentAccounts));
    dispatch(fetchAccountsSuccess(accounts));
    dispatch(fetchOrderFormsSuccess(orderForms));
    dispatch(fetchProjectsSuccess(projects));
    dispatch(fetchFavouritesSuccess(favourites));
  };
}
