import React from "react";
import PropTypes from "prop-types";
import Archive from "../../Archive/Archive";
import styles from "./AttachmentsDisplay.module.scss";

const allowedFileFormats = [".png", ".jpg"];
const attachmentHeaders = ["File Name", "Parent Deliverable Id", "Project Id"];

const AttachmentsDisplay = ({ attachments, archiveAttachment, selectFile }) => {
  const attachmentsList = attachments.map(
    (
      { attachmentId, attachmentUrl, fileName, parentDeliverableId, projectId },
      i
    ) => (
      <div className={styles.attachment} key={i}>
        <span className={styles.attachmentItem}>
          <a rel="noopener noreferrer" href={attachmentUrl} target="_blank">
            {fileName}
          </a>
        </span>
        <span className={styles.attachmentItem}>{parentDeliverableId}</span>
        <span className={styles.attachmentItem}>{projectId}</span>
        <Archive
          title="Archive"
          id={attachmentId}
          archive={archiveAttachment}
        />
      </div>
    )
  );

  const columnHeaders = attachmentHeaders.map((header, i) => (
    <span key={i} className={styles.columnHeaders}>
      {header}
    </span>
  ));

  return (
    <div className={styles.outerDiv} key={attachments}>
      {attachments.length > 0 ? (
        <div>
          {columnHeaders}
          {attachmentsList}
        </div>
      ) : (
        <div className={styles.noAttachments}>
          No attachments for this parent deliverable.
        </div>
      )}
      <input
        className={styles.fileInput}
        key="input"
        accept={allowedFileFormats}
        id="upload"
        onChange={selectFile}
        type="file"
      />
    </div>
  );
};

AttachmentsDisplay.propTypes = {
  archiveAttachment: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  selectFile: PropTypes.func.isRequired,
};

export default AttachmentsDisplay;
