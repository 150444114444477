import { connect } from "react-redux";
import ClientVersionHistory from "../ClientVersionHistory.js";
import { versionHistoryByDeliverableId } from "../../../../../utils/entitySelector";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../decorators/WithData";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
});

const mapStateToProps = (state, ownProps) => ({
  versionHistory: versionHistoryByDeliverableId(
    state,
    Number(ownProps.match.params.deliverableId)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ClientVersionHistory));
