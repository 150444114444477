import { Auth0Client, LogoutOptions } from "@auth0/auth0-spa-js";
import { loginSuccess } from "../modules/me";

let auth0Client: Auth0Client | null = null;

const getClient = (): Auth0Client => {
  if (!auth0Client) {
    auth0Client = new Auth0Client({
      domain:
        (window as any).__REACT_APP_AUTH0_DOMAIN__ ||
        (process.env.REACT_APP_AUTH0_DOMAIN as string),
      clientId:
        (window as any).__REACT_APP_AUTH0_CLIENT_ID__ ||
        (process.env.REACT_APP_AUTH0_CLIENT_ID as string),
    });
  }
  return auth0Client;
};

const authLogout = (options?: LogoutOptions) => {
  getClient().logout(options);
};

const getEmailFromAccessAndIdTokens = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 1);
  });

  const AUTH0 = getClient();

  try {
    const query = window.location.search;

    if (query.includes("code=") && query.includes("state=")) {
      await AUTH0.handleRedirectCallback();
      const idToken = await AUTH0.getIdTokenClaims();

      return idToken?.email;
    }
    return undefined;
  } catch (err) {
    console.log(err);
  }
};

const handleAuth0Authentication = async (store) => {
  try {
    const email = await getEmailFromAccessAndIdTokens();

    // Avoid hitting api when no email found from auth0
    if (!email) {
      return;
    }

    const API_GATEWAY =
      process.env.REACT_APP_API_GATEWAY ||
      (window as any).__REACT_APP_API_GATEWAY__;

    const response = await fetch(`${API_GATEWAY}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
      credentials: "include",
    });

    const json = await response.json();

    if (json.personType === "unknown") {
      window.location.assign("/contact");
    }

    store.dispatch(loginSuccess(json));
  } catch (err) {
    console.log("Issue matching email", err);
  }
};

export { handleAuth0Authentication, getClient, authLogout };
