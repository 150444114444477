import { connect } from "react-redux";
import WithData from "../../../decorators/WithData";
import CurrencyDisplay from "../components/CurrencyDisplay";


const mapStateToProps = (state, { currencyCode }) => {
  const { currencySymbol } = state.currencies.entities[currencyCode] || {};

  return {
    currencySymbol,
  };
};

export default connect(
  mapStateToProps,
)(WithData(CurrencyDisplay));
