import { connect } from "react-redux";
import {
  fetchTranslations,
  getContextTranslations,
  getTranslationMemoryContext,
} from "../../../modules/translations";
import Component from "./Tasks";

function mapDispatchToProps(dispatch) {
  return {
    fetchTranslations: (
      { accountId, verticalType, sourceLanguage, translatedLanguage },
      segments
    ) => {
      dispatch(
        fetchTranslations(
          accountId,
          verticalType,
          sourceLanguage,
          translatedLanguage,
          segments
        )
      );
    },
  };
}

const mapStateToProps = (state, { deliverableId, projectId, stageId }) => {
  const { featureToggles, me, taskFieldRateBands } = state;
  const personId = Number(me);

  const tmContext = getTranslationMemoryContext(
    state,
    deliverableId,
    projectId
  );
  const { accountId, translatedLanguage } = tmContext;

  const translationParams = {
    deliverableId,
    personId,
    stageId,
    ...tmContext,
  };

  const translations = getContextTranslations(state, {
    ...tmContext,
    targetLanguage: tmContext.translatedLanguage,
  });

  return {
    accountId,
    translationParams,
    featureToggles,
    translations,
    taskFieldRateBands,
    localStorageKeys: [
      `task-tm-${personId}`,
      `account-${accountId}_${translatedLanguage}`,
    ],
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Component);
