import Helmet from "react-helmet";
import { connect } from "react-redux";
import styles from "./Contact.module.scss";
import { logoutFetch } from "../../../../modules/me";

export const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => dispatch(logoutFetch()),
});

const Contact = (props) => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className={styles.leftColumn}>
        <div className={styles.logoName}>
          <img alt="Scribe logo" src="/icons/nav/sidebar-scribe-logo.svg" />
          <span>Scribe</span>
        </div>
        <div className={styles.welcomeText}>
          You do not have access to this application
        </div>
        <div className={styles.message}>
          <p>
            This may be because your account has not yet been created. In this
            case, please contact a Project Manager.
          </p>
          <p>
            If you already have an account, please check that you are logging in
            with the email address linked to this account.
          </p>
          <p>
            If you are still unable to log in, please contact a Project Manager.
          </p>
        </div>
        <div className={styles.backButton}>
          <button onClick={props.logout}>Logout</button>
        </div>
      </div>
      <div className={styles.rightColumn}></div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Contact);
