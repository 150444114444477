import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  InputWithErrors,
  SelectWithErrors,
  TextAreaWithErrors,
} from "../../decorators/WithErrors";
import styles from "./PersonForm.module.scss";
import debounce from "lodash/debounce";
import validate from "./validations";
import { QuillForm } from "../../components/QuillForm";

const exceptions = {
  Freelancer: "Content creator",
  "Lead editor": "Quality Lead",
  "Editorial trainer": "Content Trainer",
};

const toValueLabelObject = (data) => {
  const label = exceptions[data] || data;
  return { value: data, label };
};

const listToValueAndLabel = (list) => list.map(toValueLabelObject);

let PersonForm = class PersonForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personType: this.initialValueSelector("personType", ""),
      account: this.initialValueSelector("account", []),
      paymentMethod: this.initialValueSelector("paymentMethod", ""),
    };

    this.accountSearch = debounce((input) => {
      if (input.length > 1) {
        if (props.searchAccounts) {
          props.searchAccounts(input);
        }
      }
    }, 150);
  }

  initialValueSelector = (type, defaultValue) =>
    this.props.initialValues && this.props.initialValues[type]
      ? this.props.initialValues[type]
      : defaultValue;

  changeState = (newState) => this.setState(newState);

  render() {
    const roles = listToValueAndLabel([
      "Writer",
      "Translator",
      "Editor",
      "Lead editor",
      "Photo researcher",
      "Photo editor",
      "Editorial trainer",
      "Freelance resourcer",
      "Content uploader",
      "Illustrator",
      "Video producer",
      "Designer",
      "Coder/developer",
      "Agency",
      "Content researcher",
    ]);

    const personTypes = listToValueAndLabel([
      "In-house",
      "Client",
      "Freelancer",
    ]);

    const paymentMethods = listToValueAndLabel([
      "PayPal",
      "Bank transfer",
      "Payoneer",
    ]);

    const hideForFreelancerProfile = !this.props.freelancerProfile;

    return (
      <QuillForm
        cancelText="Cancel"
        cancelUrl={this.props.cancelUrl}
        disabled={
          this.props.submitting || !this.props.valid || this.props.disabled
        }
        error={this.props.error}
        handleSubmit={this.props.handleSubmit}
        header={this.props.header || "New person"}
        submitText="Save"
        rebrandingContainer
      >
        {hideForFreelancerProfile && (
          <div className={styles.innerFormContainerStyle}>
            <label className={styles.requiredLabel} htmlFor="firstName">
              First name
            </label>
            {/* Reason for adding autoComplete='foo' is because Chrome complains that firstName should have autocomplete,
                  but as QC2 doesn't allow users to register themselves it would just autofill as the person creating the user */}
            <Field
              autoComplete="foo"
              component={InputWithErrors}
              id="firstName"
              name="firstName"
              type="text"
              disabled={this.props.disabled}
            />
          </div>
        )}
        {hideForFreelancerProfile && (
          <div className={styles.formGroup}>
            <label className={styles.requiredLabel} htmlFor="lastName">
              Surname
            </label>
            <Field
              autoComplete="foo"
              component={InputWithErrors}
              name="lastName"
              type="text"
              disabled={this.props.disabled}
            />
          </div>
        )}
        <label className={styles.requiredLabel} htmlFor="email">
          Email
        </label>
        {/* Lastpass October 2019 release has broken autofill type=search has
            the same behaviour as type=text but doesn't allow lastpass to autofil */}
        <Field
          autoComplete="foo"
          component={InputWithErrors}
          name="email"
          type="email"
          disabled={this.props.disabled}
        />
        {hideForFreelancerProfile && (
          <div className={styles.formGroup}>
            <label className={styles.requiredLabel} htmlFor="nativeLanguage">
              Native language
            </label>
            <Field
              clearable={false}
              component={SelectWithErrors}
              disabled={this.props.submitting || this.props.disabled}
              multi
              name="nativeLanguage"
              options={this.props.languages}
            />
          </div>
        )}
        {/* personType selection dropdown */}
        {hideForFreelancerProfile && (
          <div className={styles.formGroup}>
            <label className={styles.requiredLabel} htmlFor="personType">
              Person type
            </label>
            <Field
              clearable={false}
              component={SelectWithErrors}
              disabled={this.props.submitting || this.props.disabled}
              name="personType"
              onChange={(e, personType) => this.changeState({ personType })}
              options={personTypes}
            />
          </div>
        )}

        {/* FOR: Clients --- ONLY SHOW IF 'client' is selected above */}
        {this.state.personType === "Client" && (
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="account">
              Account
            </label>
            <Field
              clearable={false}
              component={SelectWithErrors}
              customArrow={() => null}
              disabled={this.props.submitting || this.props.disabled}
              multi
              name="account"
              onChange={(e, account) => this.changeState({ account })}
              onInputChange={this.accountSearch}
              options={this.props.accounts}
              placeholder="Search..."
            />
            <div className={styles.notice}>
              If you cannot find the account you are looking for, you will need
              to create it in the dashboard first.
            </div>
          </div>
        )}

        {/* FOR: Freelancers --- ONLY SHOW IF 'freelancer' is selected above */}
        {this.state.personType.includes("Freelancer") && (
          <div>
            {hideForFreelancerProfile && (
              <div className={styles.formGroup}>
                <label className={styles.requiredLabel} htmlFor="role">
                  Role
                </label>
                <Field
                  clearable={false}
                  component={SelectWithErrors}
                  disabled={this.props.submitting || this.props.disabled}
                  multi
                  name="role"
                  options={roles}
                />
              </div>
            )}
            {hideForFreelancerProfile && (
              <div className={styles.formGroup}>
                <label className={styles.requiredLabel} htmlFor="paymentMethod">
                  Payment method
                </label>
                <Field
                  clearable={false}
                  component={SelectWithErrors}
                  disabled={this.props.submitting || this.props.disabled}
                  name="paymentMethod"
                  onChange={(e, paymentMethod) =>
                    this.changeState({ paymentMethod })
                  }
                  options={paymentMethods}
                />
              </div>
            )}
            {this.state.paymentMethod === "Bank transfer" ? (
              <div className={styles.formGroup}>
                <label className={styles.label} htmlFor="bankDetails">
                  Bank details
                </label>
                <Field
                  component={TextAreaWithErrors}
                  name="bankDetails"
                  disabled={this.props.disabled}
                />
              </div>
            ) : null}
            {this.state.paymentMethod === "PayPal" ? (
              <div className={styles.formGroup}>
                <label className={styles.label} htmlFor="payPalEmail">
                  PayPal email
                </label>
                <Field
                  autoComplete="foo"
                  component={InputWithErrors}
                  name="payPalEmail"
                  type="email"
                  disabled={this.props.disabled}
                />
              </div>
            ) : null}
            <div className={styles.formGroup}>
              <label
                className={`${
                  this.props.vatRegistered ? styles.requiredLabel : styles.label
                }`}
                htmlFor="vatNumber"
              >
                VAT number
              </label>
              <Field
                component={InputWithErrors}
                name="vatNumber"
                type="text"
                disabled={this.props.disabled}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.checkboxLabel} htmlFor="ukResident">
                Are you a UK resident?
              </label>
              <Field
                component={InputWithErrors}
                name="ukResident"
                type="checkbox"
                disabled={this.props.disabled}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.checkboxLabel} htmlFor="vatRegistered">
                Are you VAT registered in the UK?
              </label>
              <Field
                component={InputWithErrors}
                name="vatRegistered"
                type="checkbox"
                disabled={this.props.disabled}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="companyRegNumber">
                Please enter a company registration number, if the freelancer is
                registered as a business
              </label>
              <Field
                autoComplete="foo"
                component={InputWithErrors}
                name="companyRegNumber"
                type="text"
                disabled={this.props.disabled}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="businessName">
                Please enter business name if relevant
              </label>
              <Field
                autoComplete="foo"
                component={InputWithErrors}
                name="businessName"
                type="text"
                disabled={this.props.disabled}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="address">
                Address
              </label>
              <Field
                autoComplete="foo"
                component={TextAreaWithErrors}
                name="address"
                disabled={this.props.disabled}
              />
            </div>
          </div>
        )}
      </QuillForm>
    );
  }
};

PersonForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  error: PropTypes.string,
  freelancerProfile: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  header: PropTypes.string,
  initialValues: PropTypes.shape({
    account: PropTypes.array,
    paymentMethod: PropTypes.string,
    personType: PropTypes.string.isRequired,
  }),
  languages: PropTypes.array.isRequired,
  searchAccounts: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    personType: PropTypes.isRequired,
  }).isRequired,
  valid: PropTypes.bool.isRequired,
  vatRegistered: PropTypes.bool,
  disabled: PropTypes.bool.isRequired, // Added PropType for disabled form
};

PersonForm = reduxForm({
  form: "person",
  initialValues: {
    personType: "",
    account: [],
    paymentMethod: "PayPal",
  },
  validate,
})(PersonForm);

const personFormSelector = formValueSelector("person");
PersonForm = connect((state) => ({
  user: state.people.entities[state.me],
  vatRegistered: personFormSelector(state, "vatRegistered"),
}))(PersonForm);

export default PersonForm;
