import {
  fetchParentDeliverablesSuccess,
  parentDeliverablesByBatchQuery,
} from "../../../../../../../../modules/parentDeliverables";
import {
  fetchParentDeliverableBriefingFieldsSuccess,
  parentDeliverableBriefingFieldsByBatchQuery,
} from "../../../../../../../../modules/parentDeliverableBriefingFields";

import { getGraphQL } from "../../../../../../../../../utils/fetch";

export function fetchParentDeliverablesAndBriefingFieldsByBatchId(batchId) {
  return (dispatch, getState) => {
    const query = `query fetchParentDeliverablesAndBriefingFieldsByBatchId ($batchId: String) {
        ${parentDeliverableBriefingFieldsByBatchQuery}
        ${parentDeliverablesByBatchQuery}
      }`;

    return getGraphQL(query, { batchId }).then((json) => {
      dispatch(
        fetchParentDeliverableBriefingFieldsSuccess(
          json.parentDeliverableBriefingFields
        )
      );
      dispatch(fetchParentDeliverablesSuccess(json.parentDeliverables));
    });
  };
}
