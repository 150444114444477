import {
  isNotBlank,
  validateEmail,
  checkValidations,
} from "../../../../../utils/validations";

const validate = (values) => {
  const errors = {};

  const emailErrors = checkValidations(values.email, [
    { validate: isNotBlank, msg: "Cannot be blank" },
    { validate: validateEmail, msg: "Please provide a valid email" },
  ]);

  const passwordErrors = checkValidations(values.password, [
    { validate: isNotBlank, msg: "Cannot be blank" },
  ]);

  if (emailErrors) errors.email = emailErrors;
  if (passwordErrors) errors.password = passwordErrors;

  return errors;
};

export default validate;
