import { connect } from "react-redux";
import RateBandsForm from "../components/RateBandsForm";
import { rateBandsByProjectIdSelector } from "../../../../../../../../utils/entitySelector";
import { updateRateBands } from "../../../../../../../../modules/rateBands";

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values) =>
    dispatch(
      updateRateBands(
        values,
        ownProps.history,
        ownProps.match.params,
        ownProps.location
      )
    ),
});

const mapStateToProps = (state, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId);
  const rateBands = rateBandsByProjectIdSelector(state, projectId).filter(
    (rb) => !rb.archived
  );
  const initialValues = { rateBands };
  const isRequesting = state.requestTracker.updatingRateBands;
  return {
    initialValues,
    isRequesting,
  };
};

const RateBandsFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RateBandsForm);
export default RateBandsFormConnected;
