import React from "react";
import { Field } from "redux-form";
import Input from "../../../../../../components/Input";

const returnNumber = (val) => Number(val) || 0;

const AutomatedRemindersForm = ({ submitting, stages, reminderTypes }) => {
  return stages.map(({ stageName, stageId }) => {
    return (
      <tr>
        <td>{stageName}</td>
        {reminderTypes.map((rt) => {
          return (
            <td>
              <Field
                component={Input}
                disabled={submitting}
                max="999"
                min="0"
                name={`automatedReminders[${rt}][${stageId}]`}
                parse={returnNumber}
                step="0.01"
                type="number"
              />
            </td>
          );
        })}
      </tr>
    );
  });
};

export default AutomatedRemindersForm;
