import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "../../../../../../components/Dropdown";
import styles from "./PeopleList.module.scss";
import { TABS_CONFIG } from "./PeopleList";

const PersonTypeDropdown = ({ title, containerStyles, selectPersonType }) => {
  // const [selectedPersonType, setSelectedPersonType] = useState(null);

  const handleSelectPersonType = (personType) => {
    selectPersonType(personType);
  };

  return (
    <Dropdown title={title} containerStyles={containerStyles}>
      <div
        key={"All"}
        className={styles.dropdownItem}
        onClick={() => {
          handleSelectPersonType(null);
        }}
      >
        {"All"}
      </div>
      {TABS_CONFIG.map(({ personType, label }, i) => {
        if (personType === "Archived") return null;
        return (
          <div
            key={i}
            className={styles.dropdownItem}
            data-value={personType}
            value={personType}
            onClick={() => {
              handleSelectPersonType(label);
            }}
          >
            {label}
          </div>
        );
      })}
    </Dropdown>
  );
};

PersonTypeDropdown.propTypes = {
  containerStyles: PropTypes.string,
  title: PropTypes.string.isRequired,
  personTypeFilter: PropTypes.oneOf(["Freelancer", "In-house", "Client"]),
};

export default PersonTypeDropdown;
