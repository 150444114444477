import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./InProgress.module.scss";
import OverlayLoading from "../../../../../components/OverlayLoading/v1";
import Helmet from "react-helmet";
import AssignmentGroupRowContainer from "../../_components/AssignmentGroupRowContainer";

class InProgress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignmentGroupIds: [],
    };
  }

  get assignmentActions() {
    return {
      accept: this.acceptNewAssignment,
      reject: this.rejectNewAssignment,
      acceptAll: this.acceptAllNewAssignments,
      rejectAll: this.rejectAllNewAssignments,
      show: this.props.newAssignmentGroups.length !== 0,
    };
  }
  acceptNewAssignment = (e) => this.processNewAssignment("Accepted", e);
  rejectNewAssignment = (e) => this.processNewAssignment("Rejected", e);
  acceptAllNewAssignments = () => this.processNewAssignments("Accepted");
  rejectAllNewAssignments = () => this.processNewAssignments("Rejected");

  processNewAssignments(processType) {
    const { newAssignmentGroups, processAssignments } = this.props;
    const assignmentGroupIds = newAssignmentGroups.map(
      ({ assignmentGroupId }) => assignmentGroupId
    );
    processAssignments(assignmentGroupIds, processType);
  }

  processNewAssignment(processType, event) {
    event.preventDefault();
    const assignmentGroupId = Number(
      event.currentTarget.getAttribute("data-assignment-group-id")
    );
    this.props.processAssignments([assignmentGroupId], processType);
  }

  render() {
    const {
      dataReady,
      currentAssignmentGroups,
      newAssignmentGroups,
      upcomingAssignmentGroups,
      submittedAssignmentGroups,
    } = this.props;

    if (!dataReady) return <OverlayLoading />;

    return (
      <div className={styles.freelancerDashboardInprogress}>
        <Helmet>
          <title>Freelancer dashboard</title>
        </Helmet>

        <AssignmentGroupRowContainer
          assignmentActions={this.assignmentActions}
          header={"New assignments"}
          assignmentGroups={newAssignmentGroups}
          emptyCopy={
            "Any new tasks assigned to you on a project will appear here."
          }
        />

        <AssignmentGroupRowContainer
          header={"Current assignments"}
          assignmentGroups={currentAssignmentGroups}
          emptyCopy={
            "Any tasks you’ve accepted and are ready to work on will appear here."
          }
        />

        <AssignmentGroupRowContainer
          header={"Upcoming assignments"}
          assignmentGroups={upcomingAssignmentGroups}
          emptyCopy={
            "Any tasks you’ve accepted, but are not yet ready to work on, will appear here."
          }
        />

        <AssignmentGroupRowContainer
          header={"Submitted assignments"}
          assignmentGroups={submittedAssignmentGroups}
          emptyCopy={
            "Any tasks you’ve submitted, but could still have amends requested, will appear here."
          }
        />
      </div>
    );
  }
}

InProgress.propTypes = {
  dataReady: PropTypes.bool.isRequired,
  newAssignmentGroups: PropTypes.array,
  currentAssignmentGroups: PropTypes.array,
  upcomingAssignmentGroups: PropTypes.array,
  submittedAssignmentGroups: PropTypes.array,
  processAssignments: PropTypes.func.isRequired,
};

export default InProgress;
