import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  projectBriefingFieldsSelector,
  projectKeywordGroupsSelector,
  projectTaskFieldsSelector,
  projectById,
  rateBandsByProjectIdSelector,
} from "../../../../../../utils/entitySelector";
import Fields from "../components/Fields";
import { updateFields } from "../../../../../../modules/projects";
import { showModal, hideModal } from "../../../../../../modules/modal";
import WithData from "../../../../../../decorators/WithData";
import {
  getInitialData,
  createDefaultTaskField,
} from "../modules/getInitialData";
import { formValueSelector } from "redux-form";

const BRIEFING_FIELD_DEFAULT = [{ briefingFieldFormat: "Text" }];
const KEYWORD_GROUP_DEFAULT = [{ keywordGroupName: "" }];
const AI = "AI";

const selector = formValueSelector("fieldsForm");

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    updateFields: (data) =>
      dispatch(
        updateFields(
          data,
          ownProps.history,
          ownProps.match.params,
          ownProps.location
        )
      ),
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
  };
};

const initialValuesSelector = createSelector(
  projectBriefingFieldsSelector,
  projectKeywordGroupsSelector,
  projectTaskFieldsSelector,
  (_s, projectId) => projectId,
  (_s, _p, rateBands) => rateBands,
  (_s, _p, _rb, taskFieldRateBands) => taskFieldRateBands,
  (
    projectBriefingFields,
    projectKeywordGroups,
    projectTaskFields,
    projectId,
    rateBands,
    taskFieldRateBands
  ) => {
    const rateBandMap = rateBands.reduce((acc, rb) => {
      const { rateBandId, rateBandName } = rb;
      acc[rateBandId] = { label: rateBandName, value: Number(rateBandId) };
      return acc;
    }, {});
    const defaultTaskField = createDefaultTaskField(Object.values(rateBandMap));

    const taskFields =
      projectTaskFields.length > 0
        ? projectTaskFields.map((tf) => {
            const tfRateBands = (
              taskFieldRateBands[tf.taskFieldId] || []
            ).reduce((acc, tfrbId) => {
              const tfrb = rateBandMap[tfrbId];
              // filter out archived rate bands (we don't send them to the frontend so it errors otherwise)
              if (tfrb) {
                acc.push(tfrb);
              }
              return acc;
            }, []);

            return {
              ...tf,
              rateBands: tfRateBands,
            };
          })
        : [defaultTaskField];

    const briefingFields =
      projectBriefingFields.length > 0
        ? projectBriefingFields
        : BRIEFING_FIELD_DEFAULT;

    const keywordGroups =
      projectKeywordGroups.length > 0
        ? projectKeywordGroups
        : KEYWORD_GROUP_DEFAULT;

    return {
      defaultTaskField,
      projectId,
      briefingFields,
      taskFields,
      keywordGroups,
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  const { match, location } = ownProps;
  const { featureToggles, modal, taskFieldRateBands } = state;

  const projectCreation = location.state
    ? location.state.projectCreation
    : false;

  const projectId = Number(match.params.projectId);

  const project = projectById(state, projectId);
  const { workflowId } = project;

  const { workflowType, workflowName } =
    state.workflows.entities[workflowId] || {};
  const isLocalisationProject = workflowType === "Localisation";
  const isAiWorkflow = workflowName?.includes(AI);

  const rateBands = rateBandsByProjectIdSelector(state, projectId);

  const { defaultTaskField, ...initialValues } = initialValuesSelector(
    state,
    project.projectId,
    rateBands,
    taskFieldRateBands
  );

  const orderForm = state.orderForms.entities[project.orderFormId] || {};
  const account = state.accounts.entities[orderForm.accountId] || {};
  const parentAccount =
    state.parentAccounts.entities[account.parentAccountId] || {};

  const briefingFields = selector(state, "briefingFields");

  // Determine if any of the briefing fields have the 'AI content model' format
  const hasAiContentModel =
    briefingFields &&
    briefingFields.some(
      ({ briefingFieldFormat }) => briefingFieldFormat === "AI content model"
    );

  return {
    account,
    featureToggles,
    initialValues,
    defaultTaskField,
    isAiWorkflow,
    isLocalisationProject,
    orderForm,
    projectCreation,
    project,
    projectId,
    parentAccount,
    modal,
    hasAiContentModel,
  };
};

const ProjectFields = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Fields));
ProjectFields.getInitialData = getInitialData;
export default ProjectFields;
