import Fields from "./containers/Fields";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../../../modules/accounts";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../../../modules/orderForms";
import {
  parentAccountActionHandlers,
  parentAccountInitialState,
} from "../../../../../modules/parentAccounts";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../../../modules/projects";
import {
  keywordGroupActionHandlers,
  keywordGroupInitialState,
} from "../../../../../modules/keywordGroups";
import {
  briefingFieldActionHandlers,
  briefingFieldInitialState,
} from "../../../../../modules/briefingFields";
import {
  taskFieldActionHandlers,
  taskFieldInitialState,
} from "../../../../../modules/taskFields";

export default {
  Component: Fields,
  reducers: [
    {
      key: "parentAccounts",
      actionHandlers: parentAccountActionHandlers,
      initialState: parentAccountInitialState,
    },
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
    {
      key: "taskFields",
      actionHandlers: taskFieldActionHandlers,
      initialState: taskFieldInitialState,
    },
    {
      key: "briefingFields",
      actionHandlers: briefingFieldActionHandlers,
      initialState: briefingFieldInitialState,
    },
    {
      key: "keywordGroups",
      actionHandlers: keywordGroupActionHandlers,
      initialState: keywordGroupInitialState,
    },
  ],
};
