/**
 * generateDownload - Generates a download by creating a temporary download button
 * and programmatically clicking it before removing it from the DOM
 *
 * @param  {string} filename
 * @param  {string} href
 * @return {null}
 */
export function generateDownload(filename, href) {
  const link = document.createElement("a");
  link.setAttribute("href", href);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
