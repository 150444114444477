import { connect } from "react-redux";
import gql from "graphql-tag";
import { getGraphQL } from "../../../../../utils/fetch";
import WithData from "../../../../decorators/WithData";
import ParentDeliverables from "./ParentDeliverables";
import {
  fetchParentDeliverableSuccess,
  parentDeliverableQuery,
} from "../../../../modules/parentDeliverables";
import { parentDeliverableById } from "../../../../utils/entitySelector";

const query = gql`
  query Client_ParentDeliverables_getInitialData($parentDeliverableId: String) {
    ${parentDeliverableQuery}
  }
`;

export function getInitialData(params) {
  return async (dispatch) => {
    const { parentDeliverable } = await getGraphQL(query, params);
    dispatch(fetchParentDeliverableSuccess(parentDeliverable));
  };
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
});

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { parentDeliverableId },
    },
  } = ownProps;

  const parentDeliverable = parentDeliverableById(state, parentDeliverableId);

  return {
    parentDeliverable,
  };
};

const ConnectedParentDeliverables = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ParentDeliverables));
ConnectedParentDeliverables.getInitialData = getInitialData;

export default {
  Component: ConnectedParentDeliverables,
};
