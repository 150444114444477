import { getGraphQL } from "../../../../../../utils/fetch";
import {
  parentAccountsByOrderFormQuery,
  fetchParentAccountsSuccess,
} from "../../../../../modules/parentAccounts";
import {
  accountsByOrderFormQuery,
  fetchAccountsSuccess,
} from "../../../../../modules/accounts";
import {
  orderFormByIdQuery,
  fetchOrderFormSuccess,
} from "../../../../../modules/orderForms";
import { peopleQuery, fetchPeopleSuccess } from "../../../../../modules/people";

export const initialQuery = `query getParentAccountAccountAndOrderForm
  ($orderFormId: String) {
  ${parentAccountsByOrderFormQuery}
  ${orderFormByIdQuery}
  ${accountsByOrderFormQuery}
  ${peopleQuery}
}`;

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, params, url, cookies).then((json) => {
      dispatch(fetchParentAccountsSuccess(json.parentAccounts));
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormSuccess(json.orderForm));
      dispatch(fetchPeopleSuccess(json.people));
    });
  };
}
