import PaymentsNew from "./containers/PaymentsNew";
import {
  accountActionHandlers,
  accountInitialState,
} from "../../../../modules/accounts";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../../../../modules/orderForms";
import {
  projectActionHandlers,
  projectInitialState,
} from "../../../../modules/projects";

export default {
  Component: PaymentsNew,
  reducers: [
    {
      key: "accounts",
      actionHandlers: accountActionHandlers,
      initialState: accountInitialState,
    },
    {
      key: "orderForms",
      actionHandlers: orderFormActionHandlers,
      initialState: orderFormInitialState,
    },
    {
      key: "projects",
      actionHandlers: projectActionHandlers,
      initialState: projectInitialState,
    },
  ],
};
