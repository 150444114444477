import { connect } from "react-redux";
import ProfileShow from "../components/ProfileShow";
import { getLoggedInUser } from "../../../../../utils/entitySelector";

const mapStateToProps = (state) => {
  const { fullName, personId } = getLoggedInUser(state);

  return {
    fullName,
    personId,
  };
};

export default connect(mapStateToProps)(ProfileShow);
