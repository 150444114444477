import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../components/PageHeader";
import styles from "./PaymentsShow.module.scss";
import PaymentsShowForm from "./PaymentsShowForm";
import { Link } from "react-router-dom";
import Modal from "../../../../../../components/Modal";
import {
  PAYMENT_TYPE_AD_HOC,
  PAYMENT_TYPE_AD_HOC_DRAFT,
} from "../../../../../../modules/payments";
import { ModalType } from "../../../../../../modules/modal";

class PaymentsShow extends Component {
  archivePayment = async () => {
    await this.props.archivePayment(this.props.payment.paymentId);
    this.hideModal();
  };

  showModal = () => {
    this.showArchiveModal = true;
    this.props.showModal();
  };

  hideModal = () => {
    this.showArchiveModal = false;
    this.props.hideModal();
  };

  approvePaymentHandler = async () => {
    const {
      approvePayment,
      payment: { paymentId },
    } = this.props;
    approvePayment(paymentId);
  };

  renderPayments = () => {
    const { payment } = this.props;
    const { paymentComplete, paymentType, paymentStatus } = payment;

    if (!payment) {
      return <div className={styles.notice}>No payment available</div>;
    }

    const editPaymentButton = (
      <div className={styles.buttonContainer}>
        <Link to={`/admin/payments/${payment.paymentId}/edit`}>
          <button disabled={paymentComplete} className={styles.edit}>
            Edit payment
          </button>
        </Link>
      </div>
    );

    const isAdHoc =
      paymentType === PAYMENT_TYPE_AD_HOC ||
      paymentType === PAYMENT_TYPE_AD_HOC_DRAFT;

    const needsApproval = paymentStatus === "Needs Approval";
    const isPending = paymentStatus === "Pending" || needsApproval;
    const isCompleted = paymentStatus === "Completed";
    const isArchived = paymentStatus === "Archived";

    return (
      <div>
        <PaymentsShowForm {...this.props} />

        {isAdHoc ? (
          <>
            {isPending && (
              <div className={styles.buttonContainer}>
                {needsApproval && (
                  <button
                    onClick={this.approvePaymentHandler}
                    className={styles.edit}
                  >
                    Approve payment
                  </button>
                )}

                <Link to={`/admin/payments/${payment.paymentId}/edit`}>
                  <button disabled={paymentComplete} className={styles.edit}>
                    Edit payment
                  </button>
                </Link>

                <button onClick={this.showModal} className={styles.archive}>
                  Archive
                </button>
              </div>
            )}

            {isCompleted && (
              <div className={styles.paymentBar}>
                This payment has been made
              </div>
            )}

            {isArchived && (
              <div className={styles.paymentBar}>
                This payment has been archived{" "}
              </div>
            )}
          </>
        ) : (
          <>
            {isCompleted && (
              <div className={styles.paymentBar}>
                This payment has been made
              </div>
            )}

            {isPending && editPaymentButton}
          </>
        )}

        <Modal
          type={ModalType.ArchivePayment}
          title={`Archive this payment?`}
          display={this.props.modal.display}
          onClickCallback={this.hideModal}
          body={<div>Are you sure? This will archive the payment. </div>}
          footer={
            <div>
              <button className={styles.modalCancel} onClick={this.hideModal}>
                Cancel
              </button>
              <button
                className={styles.modalConfirm}
                onClick={this.archivePayment}
              >
                Yes
              </button>
            </div>
          }
        />
      </div>
    );
  };

  render() {
    const {
      payee,
      location: { state },
    } = this.props;

    /* state is undefined by default, so we need to check that it exists before
     * trying to access properties on it */
    const originator = state && state.originator;

    return (
      <div>
        <Helmet>
          <title>Payment info</title>
        </Helmet>

        <PageHeader
          title={"Payment info"}
          breadCrumbItems={[
            { url: "/admin/payments/search", text: "Search payments" },
            ...(originator && payee
              ? [
                  {
                    url: originator,
                    text: `List payments (${payee.firstName} ${payee.lastName})`,
                  },
                ]
              : []),
            { text: "View payment" },
          ]}
        />

        {this.renderPayments()}
      </div>
    );
  }
}

PaymentsShow.propTypes = {
  archivePayment: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    state: PropTypes.shape({
      originator: PropTypes.string,
    }),
  }).isRequired,
  payment: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    task: PropTypes.string,
    stageName: PropTypes.string,
    createDate: PropTypes.string.isRequired,
    assignmentId: PropTypes.number,
    batchName: PropTypes.string,
    rate: PropTypes.number,
    paymentType: PropTypes.string,
    notes: PropTypes.string,
    paymentId: PropTypes.number,
  }).isRequired,
  payee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  orderForm: PropTypes.shape({
    orderFormName: PropTypes.string,
  }),
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PaymentsShow;
