import { getGraphQL } from "../../../../../../utils/fetch";
import { briefingFieldsByProjectIdQuery } from "../../../../../modules/briefingFields";
import { keywordGroupsByProjectIdQuery } from "../../../../../modules/keywordGroups";
import { rateBandsByProjectIdQuery } from "../../../../../modules/rateBands";
import { sourceFieldsByProjectIdQuery } from "../../../../../modules/sourceFields";
import handleErrors from "../../../../../utils/handleErrors";
import { downloadCSVTemplateHandler } from "../../../Projects/Id/BulkUploads/containers/BulkUploads";

export const initialQuery = `query ProjectBulkUploads ($projectId: String) {
  ${briefingFieldsByProjectIdQuery}
  ${keywordGroupsByProjectIdQuery}
  ${sourceFieldsByProjectIdQuery}
  ${rateBandsByProjectIdQuery}
}`;

export function downloadCSVTemplate(project) {
  return async (dipsatch) => {
    try {
      const { projectId, projectName } = project;
      const params = { projectId };
      const { briefingFields, sourceFields, keywordGroups, rateBands } =
        await getGraphQL(initialQuery, params);

      downloadCSVTemplateHandler(
        projectName,
        briefingFields,
        sourceFields,
        keywordGroups,
        rateBands
      );
    } catch (err) {
      handleErrors(err);
    }
  };
}
