import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Modal from "../../../../../../../components/Modal";
import OverlayLoading from "../../../../../../../components/OverlayLoading/v2";
import PageHeader from "../../../../../../../components/PageHeader";
import Tabs from "../../../../../../../components/Tabs/v2";
import Messages from "../../../../../../../components/Messages/v1";
import styles from "./ParentDeliverablesEdit.module.scss";
import ParentDeliverablesForm from "../../_components/ParentDeliverablesForm";
import UsefulQueries from "./UsefulQueries";
import DeliverableLinks from "./DeliverableLinks";

const ParentDeliverables = Object.freeze({
  Edit: "Edit",
  Deliverables: "Deliverables",
});

class ParentDeliverablesEdit extends Component {
  state = {
    currentIdIndex: null,
    nextId: null,
  };

  submitForm = () => this.parentDeliverablesForm.submit();

  showModal = () => {
    this.showArchiveModal = true;
    this.props.showModal();
  };

  hideModal = () => {
    this.showArchiveModal = false;
    this.props.hideModal();
  };

  archiveParentDeliverable = () => {
    this.props.archiveParentDeliverable();
    this.hideModal();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.parentDeliverableId !== this.props.parentDeliverableId) {
      this.props.getInitialData();
    }
  }

  handleSave = async () => {
    await this.submitForm();
    const { history } = this.props;
    history.push(`/admin/projects/${this.props.projectId}/briefing`);
  };

  handleSaveAndNext = async () => {
    await this.submitForm();
    const { history } = this.props;
    history.push(this.props.nextParentDeliverableUrL);
  };

  render() {
    const {
      activeTab,
      dataReady,
      deliverableLinks,
      messages,
      parentDeliverableId,
      projectId,
      projectName,
      sourceLanguage,
      storageKey,
      parentAccountName,
      accountName,
      orderFormNumber,
      updateDate,
    } = this.props;

    if (!dataReady) return <OverlayLoading />;

    return (
      <div>
        <Helmet>
          {" "}
          <title>{projectName} - Edit parent deliverable</title>{" "}
        </Helmet>
        <PageHeader
          breadCrumbItems={[
            { url: "/admin", text: "Parent accounts" },
            {
              text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
            },
            { url: `/admin/projects/${projectId}`, text: `${projectName}` },
            { text: "Edit parent deliverable" },
          ]}
          title={`${projectName} - Edit parent deliverable`}
        />
        {messages.display && <Messages />}

        <Tabs
          initialValue={ParentDeliverables.Edit}
          items={[
            { key: ParentDeliverables.Edit, text: "Edit" },
            { key: ParentDeliverables.Deliverables, text: "Deliverables" },
          ]}
          storageKey={storageKey}
        />

        {activeTab === ParentDeliverables.Edit && (
          <div>
            <ParentDeliverablesForm
              ref={(r) => {
                this.parentDeliverablesForm = r;
              }}
              key={parentDeliverableId}
              parentDeliverableId={parentDeliverableId}
              batches={this.props.batches}
              briefingFields={this.props.briefingFields}
              createBatch={this.props.createBatch}
              edit
              enableReinitialize
              featureToggles={this.props.featureToggles}
              header="Edit parent deliverable"
              updateDate={updateDate}
              hideModal={this.props.hideModal}
              initialValues={this.props.initialValues}
              keywordGroups={this.props.keywordGroups}
              modal={this.props.modal}
              onSubmit={this.props.updateParentDeliverable}
              projectId={projectId}
              projectSourceLanguage={sourceLanguage}
              rateBands={this.props.rateBands}
              showModal={this.props.showModal}
              sourceFields={this.props.sourceFields}
            />

            <div className={styles.buttonsContainer}>
              <button className={styles.archive} onClick={this.showModal}>
                Archive parent deliverable
              </button>
              <button
                className={`${
                  this.props.nextParentDeliverableUrL
                    ? styles.save
                    : styles.saveAndNext
                }`}
                disabled={
                  this.parentDeliverablesForm &&
                  this.parentDeliverablesForm.invalid
                }
                onClick={this.handleSave}
              >
                Save
              </button>
              {this.props.nextParentDeliverableUrL && (
                <button
                  className={styles.saveAndNext}
                  disabled={
                    this.parentDeliverablesForm &&
                    this.parentDeliverablesForm.invalid
                  }
                  onClick={this.handleSaveAndNext}
                >
                  Save and Next
                </button>
              )}
            </div>
            {this.props.modal.display && this.showArchiveModal && (
              <Modal
                body={
                  <div>
                    Are you sure? This will archive the parent deliverable, all
                    associated deliverables and assignments
                  </div>
                }
                display={this.props.modal.display}
                footer={
                  <div>
                    <button
                      className={styles.modalCancel}
                      onClick={this.hideModal}
                    >
                      Cancel
                    </button>
                    <button
                      className={styles.modalConfirm}
                      onClick={this.archiveParentDeliverable}
                    >
                      Yes
                    </button>
                  </div>
                }
                onClickCallback={this.hideModal}
                title={"Archive this parent deliverable?"}
              />
            )}
          </div>
        )}

        {activeTab === ParentDeliverables.Deliverables && [
          <DeliverableLinks
            key="deliverableLinks"
            deliverableLinks={deliverableLinks}
          />,
          <UsefulQueries
            key="usefulQueries"
            parentDeliverableId={parentDeliverableId}
          />,
        ]}
      </div>
    );
  }
}

ParentDeliverablesEdit.propTypes = {
  accountName: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  archiveParentDeliverable: PropTypes.func.isRequired,
  batches: PropTypes.array.isRequired,
  briefingFields: PropTypes.object.isRequired,
  createBatch: PropTypes.func.isRequired,
  dataReady: PropTypes.bool.isRequired,
  deliverableLinks: PropTypes.arrayOf(
    PropTypes.shape({
      deliverableId: PropTypes.number.isRequired,
      languageName: PropTypes.string.isRequired,
      stageName: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  featureToggles: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    keywords: PropTypes.array.isRequired,
    parentDeliverableBriefingFields: PropTypes.array.isRequired,
    projectId: PropTypes.number.isRequired,
    updateDate: PropTypes.string.isRequired,
  }).isRequired,
  keywordGroups: PropTypes.array.isRequired,
  messages: PropTypes.object,
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
  orderFormNumber: PropTypes.number.isRequired,
  parentAccountName: PropTypes.string.isRequired,
  parentDeliverableId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  rateBands: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  sourceFields: PropTypes.object,
  sourceLanguage: PropTypes.string,
  storageKey: PropTypes.string.isRequired,
  updateParentDeliverable: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  updateDate: PropTypes.string,
  location: PropTypes.object.isRequired,
};

export default ParentDeliverablesEdit;
