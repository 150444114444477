import { createAction } from "redux-actions";
import { upsertData, removeData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";
import { createSelector } from "reselect";
import { projectBriefingFieldsSelector } from "../utils/entitySelector";
import { getGraphQL } from "../../utils/fetch";

export const BriefingFieldFormat = Object.freeze({
  Text: "Text",
  HTML: "HTML",
  Hyperlink: "Hyperlink",
  Image: "Image",
  "AI content model": "AI content model",
  "AI attribute": "AI attribute",
});

// ------------------------------------
// GraphQL Queries
// ------------------------------------
const briefingFieldFields = `briefingFieldId, projectId, briefingFieldName, briefingFieldPosition, briefingFieldFormat`; // what a name

export const briefingFieldsByProjectIdQuery = `briefingFields (projectId: $projectId) {
  ${briefingFieldFields}
}`;
export const briefingFieldsByAssignmentGroupQuery = `briefingFields (assignmentGroupId: $assignmentGroupId) {
  ${briefingFieldFields}
}`;
export const briefingFieldsByAssignmentQuery = `briefingFields (assignmentId: $assignmentId) {
  ${briefingFieldFields}
}`;
export const briefingFieldsByDeliverableIdsQuery = `briefingFields (deliverableIds: $deliverableId) {
  ${briefingFieldFields}
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_BRIEFING_FIELDS_SUCCESS = "FETCH_BRIEFING_FIELDS_SUCCESS";
export const UPDATE_BRIEFING_FIELDS_SUCCESS = "UPDATE_BRIEFING_FIELDS_SUCCESS";
export const REMOVE_BRIEFING_FIELDS_SUCCESS = "REMOVE_BRIEFING_FIELDS_SUCCESS";
export const CREATE_BRIEFING_FIELDS_SUCCESS = "CREATE_BRIEFING_FIELDS_SUCCESS";
// ------------------------------------
// Actions
// ------------------------------------
export const fetchBriefingFieldsSuccess = createAction(
  FETCH_BRIEFING_FIELDS_SUCCESS
);
export const createBriefingFieldsSuccess = createAction(
  CREATE_BRIEFING_FIELDS_SUCCESS
);
export const updateBriefingFieldsSuccess = createAction(
  UPDATE_BRIEFING_FIELDS_SUCCESS
);
export const removeBriefingFieldsSuccess = createAction(
  REMOVE_BRIEFING_FIELDS_SUCCESS
);

export const projectBriefingFieldsAsMapSelector = createSelector(
  projectBriefingFieldsSelector,
  (briefingFields) =>
    briefingFields.reduce((acc, val) => {
      acc[val["briefingFieldId"]] = val;
      return acc;
    }, {})
);

export function fetchBriefingFields(projectId) {
  return async (dispatch) => {
    try {
      const params = { projectId };

      const query = `query fetchBriefingFieldFieldsByProjectId ($projectId: String) {
        ${briefingFieldsByProjectIdQuery}
      }`;

      const { briefingFields } = await getGraphQL(query, params);

      dispatch(fetchBriefingFieldsSuccess(briefingFields));
    } catch (err) {
      console.log(err);
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
export const briefingFieldActionHandlers = {
  [RESET_INITIAL_STATE]: () => briefingFieldInitialState,
  [FETCH_BRIEFING_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "briefingFieldId"),
  [CREATE_BRIEFING_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "briefingFieldId"),
  [UPDATE_BRIEFING_FIELDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "briefingFieldId"),
  [REMOVE_BRIEFING_FIELDS_SUCCESS]: (state, { payload }) => {
    return payload.reduce(
      (acc, { briefingFieldId }) => removeData(acc, briefingFieldId),
      state
    );
  },
};

export const briefingFieldInitialState = { entities: {}, result: [] };
