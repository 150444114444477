import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "./DeliverableInfo.module.scss";
import { getParentDeliverableEditUrl } from "../../../../../../../routes";
import LocalTime from "../../../../../../../components/LocalTime";

export const DeliverableInfo = ({
  assignments,
  isAdmin,
  deliverable: { currentStageId, deliverableId, parentDeliverableId },
  projectId,
}) => {
  return (
    <div>
      <div className={styles.deliverableField}>
        <span>Deliverable ID:</span> {deliverableId}
      </div>

      {isAdmin && (
        <div className={styles.deliverableField}>
          <span>Parent deliverable ID:</span>
          <a
            className={styles.linkButton}
            href={getParentDeliverableEditUrl(projectId, parentDeliverableId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {parentDeliverableId}
          </a>
        </div>
      )}

      <hr />

      {assignments.map(({ assignee, deadline, stageId, stageName }) => {
        const isCurrentStage = stageId === currentStageId;
        const stageText = `${stageName}${
          isCurrentStage ? " (Current stage)" : ""
        }`;

        return (
          <div key={stageId} className={styles.fieldContainer}>
            {isCurrentStage && (
              <svg height="16px" width="16px">
                <path d="M16 8.5l-6-6v4l-10 1.5v1l10 1.5v4z" fill="#DA0034" />
              </svg>
            )}

            <div className={styles.item}>
              <div className={styles.stage}>{stageText}</div>
              <div className={styles.assignee}>{assignee}</div>
              <LocalTime isDeadline small utcDateString={deadline} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

DeliverableInfo.propTypes = {
  assignments: PropTypes.array.isRequired,
  deliverable: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  projectId: PropTypes.number.isRequired,
};

export default memo(DeliverableInfo);
