import React, { Component } from "react";
import LoadingV2 from "../../Loading/v2";
import styles from "./OverlayLoading.module.scss";

const OverlayLoading = class OverlayLoading extends Component {
  constructor(props) {
    super(props);
    this.state = { render: false, transition: false };
  }

  componentWillMount() {
    /*  Set render to true after a small delay, so that fast-loading content does
        not show a flashing loader. Also set transition to true and apply
        styling changes so that the transition appears smoothly */
    this.renderTimeout = setTimeout(() => {
      this.setState({ render: true }, () => {
        this.transitionTimeout = setTimeout(() =>
          this.setState({ transition: true })
        );
      });
    }, 200);
  }

  componentWillUnmount() {
    clearTimeout(this.renderTimeout);
    clearTimeout(this.transitionTimeout);
  }

  render() {
    return this.state.render ? (
      <div
        className={`${styles.overlay} ${
          this.state.transition ? styles.overlayColour : ""
        }`}
      >
        <LoadingV2 />
      </div>
    ) : null;
  }
};

export default OverlayLoading;
