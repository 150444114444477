import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import { RESET_INITIAL_STATE } from "./me";
import { projectTeamsSelector } from "../utils/entitySelector";
import { PersonType, personFields } from "./people";
import { fields as personNativeLanguageFields } from "./personNativeLanguages";

const fields = ["projectId", "personId"];
// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const projectTeamsByProjectIdQuery = `
  projectTeams(projectIds: $projectId) { 
    ${fields}

    person {
      ${personFields}

      personNativeLanguages {
        ${personNativeLanguageFields}
      }
    }
  }
`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_PROJECT_TEAMS_BY_PROJECT_ID_SUCCESS =
  "FETCH_PROJECT_TEAMS_BY_PROJECT_ID_SUCCESS";
const CREATE_PROJECT_TEAMS_SUCCESS = "CREATE_PROJECT_TEAMS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchProjectTeamsByProjectIdSuccess = createAction(
  FETCH_PROJECT_TEAMS_BY_PROJECT_ID_SUCCESS
);
export const createProjectTeamsSuccess = createAction(
  CREATE_PROJECT_TEAMS_SUCCESS
);

export const initialProjectTeamsSelector = (personType) => {
  return createSelector(
    projectTeamsSelector,
    (state) => state.people.entities,
    (projectTeams, entities) => {
      return projectTeams
        .map(({ personId }) => personId)
        .filter((personId) => {
          const person = entities[personId];
          return person?.personType === personType;
        });
    }
  );
};
export const projectTeamsClientsSelector = initialProjectTeamsSelector(
  PersonType.Client
);

// ------------------------------------
// Action Handlers
// ------------------------------------

export const projectTeamActionHandlers = {
  [RESET_INITIAL_STATE]: () => projectTeamInitialState,
  [FETCH_PROJECT_TEAMS_BY_PROJECT_ID_SUCCESS]: (state, { payload }) => {
    return payload.reduce((acc, pT) => {
      if (
        acc.some(
          (a) => a.projectId === pT.projectId && a.personId === pT.personId
        )
      ) {
        return acc;
      }
      return acc.concat(pT);
    }, state);
  },
  [CREATE_PROJECT_TEAMS_SUCCESS]: (state, { payload }) => {
    const submittedProjectId =
      payload.constructor === Array ? payload[0].projectId : payload;
    const prevState = [...state].filter(
      ({ projectId }) => projectId !== submittedProjectId
    );
    /*  If the payload is not an array, we assume it is a projectId and that project
        team members have been removed/do not exist for the project in question;
        we therefore remove all members from that project */
    if (payload.constructor !== Array) return prevState;
    return [...prevState, payload];
  },
};

export const projectTeamInitialState = [];
