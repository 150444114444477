import { connect } from "react-redux";
import RequestWrapper from "../components/RequestWrapper";
import { startRequest, endRequest } from "../../../modules/requestTracker";
import { compose } from "redux";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startRequest: (requestID) => dispatch(startRequest(requestID)),
    endRequest: (requestID) => dispatch(endRequest(requestID)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { requestTracker } = state;

  return { requestTracker };
};

// We compose connect and RequestWrapper here - this is to allow WrappedComponents
// to be passed dynamically, while still being connected to the store
const RequestWrapperContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  RequestWrapper
);
export default RequestWrapperContainer;
