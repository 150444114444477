import { getGraphQL } from "../../../../../../../../utils/fetch";

import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../../modules/accounts";
import {
  exportFormatByProjectIdQuery,
  fetchExportFormatSuccess,
} from "../../../../../../../modules/exportFormats";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../modules/projects";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../modules/batches";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../../modules/projectLanguages";

import {
  personNativeLanguagesQuery,
  fetchPersonNativeLanguagesSuccess,
} from "../../../../../../../modules/personNativeLanguages";

export const initialQuery = `query ClientProjectCompleted ($projectId: String, $personId: String){
  ${accountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${exportFormatByProjectIdQuery}
  ${personNativeLanguagesQuery}
  ${projectQuery}
  ${batchesByProjectIdQuery}
  ${projectLanguagesByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  const { projectId } = params;
  return (dispatch, getState) => {
    const personId = getState().me;

    return getGraphQL(initialQuery, { projectId, personId }, url, cookies).then(
      (json) => {
        dispatch(fetchAccountsSuccess(json.accounts));
        dispatch(fetchExportFormatSuccess(json.exportFormat));
        dispatch(fetchOrderFormsSuccess(json.orderForms));
        dispatch(fetchPersonNativeLanguagesSuccess(json.personNativeLanguages));
        dispatch(fetchProjectSuccess(json.project));
        dispatch(
          fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages)
        );
        dispatch(fetchBatchesSuccess(json.batches));
      }
    );
  };
}
