import { createAction } from "redux-actions";

export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE_V2";
export const SHOW_WARNING_MESSAGE = "SHOW_WARNING_MESSAGE_V2";
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE_V2";
export const HIDE_MESSAGES = "HIDE_MESSAGES_V2";

export const showSuccessMessage = createAction(SHOW_SUCCESS_MESSAGE);
export const showWarningMessage = createAction(SHOW_WARNING_MESSAGE);
export const showErrorMessage = createAction(SHOW_ERROR_MESSAGE);
export const hideMessages = createAction(HIDE_MESSAGES);

// types of messages
export const TYPE_SUCCESS = "TYPE_SUCCESS";
export const TYPE_WARNING = "TYPE_WARNING";
export const TYPE_ERROR = "TYPE_ERROR";

export const messageActionHandlers = {
  [SHOW_SUCCESS_MESSAGE]: (state, { payload: message }) => ({
    display: true,
    type: TYPE_SUCCESS,
    message,
  }),
  [SHOW_WARNING_MESSAGE]: (state, { payload: message }) => ({
    display: true,
    type: TYPE_WARNING,
    message,
  }),
  [SHOW_ERROR_MESSAGE]: (state, { payload: message }) => ({
    display: true,
    type: TYPE_ERROR,
    message,
  }),
  [HIDE_MESSAGES]: (state, { payload }) => ({ ...state, display: false }),
};

export const messageInitialState = {
  display: false,
  type: TYPE_SUCCESS,
  message: null,
};
