// Bundles.js

import syncComponent from "../decorators/SyncComponent";

// ========================================================
// Admin bundles
// ========================================================

export const AdminLayout = syncComponent(
  "AdminLayout",
  require("./Admin/_layout")
);
export const ParentAccountsList = syncComponent(
  "ParentAccountsList",
  require("./Admin/ParentAccountsList")
);
export const Search = syncComponent("Search", require("./Admin/Search"));
export const ProjectsNew = syncComponent(
  "ProjectsNew",
  require("./Admin/Projects/ProjectsNew")
);
export const ProjectsInfo = syncComponent(
  "ProjectsInfo",
  require("./Admin/Projects/Id/ProjectsInfo")
);
export const ProjectsBannedWords = syncComponent(
  "ProjectsBannedWords",
  require("./Admin/Projects/Id/BannedWords")
);
export const ParentDeliverablesNew = syncComponent(
  "ParentDeliverablesNew",
  require("./Admin/Projects/Id/ParentDeliverables/ParentDeliverablesNew")
);
export const ParentDeliverablesEdit = syncComponent(
  "ParentDeliverablesEdit",
  require("./Admin/Projects/Id/ParentDeliverables/ParentDeliverablesEdit")
);
export const ProjectsDefaultAssignees = syncComponent(
  "ProjectsDefaultAssignees",
  require("./Admin/Projects/Id/DefaultAssignees")
);
export const ProjectsEdit = syncComponent(
  "ProjectsEdit",
  require("./Admin/Projects/Id/ProjectsEdit")
);
export const ProjectsFields = syncComponent(
  "ProjectsFields",
  require("./Admin/Projects/Id/Fields")
);
export const ProjectsRatesV1 = syncComponent(
  "ProjectsRatesV1",
  require("./Admin/Projects/Id/Rates/v1")
);
export const ProjectsRatesV2 = syncComponent(
  "ProjectsRatesV2",
  require("./Admin/Projects/Id/Rates/v2")
);
export const ProjectsQuotesV1 = syncComponent(
  "ProjectsQuotesV1",
  require("./Admin/Projects/Id/Quotes/v1")
);
export const ProjectBatches = syncComponent(
  "ProjectBatches",
  require("./Admin/Projects/Id/ProjectBatches")
);
export const ProjectsExportFormat = syncComponent(
  "ProjectsExportFormat",
  require("./Admin/Projects/Id/ExportFormat")
);

export const ProjectsShowLayout = syncComponent(
  "ProjectsShowLayout",
  require("./Admin/Projects/Id/Show/_layout")
);

export const ProjectsShowBriefingV2 = syncComponent(
  "ProjectsShowBriefingV2",
  require("./Admin/Projects/Id/Show/Briefing/v2")
);

export const ProjectsShowInProductionV2 = syncComponent(
  "ProjectsShowInProductionV2",
  require("./Admin/Projects/Id/Show/InProduction/v2")
);

export const ProjectsShowCompletedV2 = syncComponent(
  "ProjectsShowCompletedV2",
  require("./Admin/Projects/Id/Show/Completed/v2")
);

export const ProjectsAssignmentsNew = syncComponent(
  "ProjectsAssignmentsNew",
  require("./Admin/Projects/Id/Assignments/AssignmentsNew")
);

export const ProjectsAssignmentsList = syncComponent(
  "ProjectsAssignmentsList",
  require("./Admin/Projects/Id/Assignments/Id/AssignmentsList")
);

export const ProjectsAssignmentsShowV2 = syncComponent(
  "ProjectsAssignmentsShowV2",
  require("./Admin/Projects/Id/Assignments/Id/AssignmentsShow/v2")
);

export const ProjectsBulkUploads = syncComponent(
  "ProjectsBulkUploads",
  require("./Admin/Projects/Id/BulkUploads")
);

export const ProjectsWithClientV2 = syncComponent(
  "ProjectsWithClientV2",
  require("./Admin/Projects/Id/Show/WithClient/v2")
);

export const ProjectsClientBatchStage = syncComponent(
  "ProjectsClientBatchStage",
  require("./Admin/Projects/Id/Batches/Id/Stages/Id/List")
);

export const ProjectsClientDeliverableV2 = syncComponent(
  "ProjectsClientDeliverableV2",
  require("./Admin/Projects/Id/Batches/Id/Stages/Id/Deliverable/v2")
);

export const AdminVersionHistory = syncComponent(
  "AdminVersionHistory",
  require("./Admin/Deliverable/Id/AdminVersionHistory")
);
export const AdminTommyOutputs = syncComponent(
  "AdminTommyOutputs",
  require("./Admin/TommyOutputs")
);
export const AIEnhancementNew = syncComponent(
  "AIEnhancementNew",
  require("./Admin/AIEnhancement/New")
);
export const AIEnhancementList = syncComponent(
  "AIEnhancementNew",
  require("./Admin/AIEnhancement/List")
);

export const PeopleNew = syncComponent(
  "PeopleNew",
  require("./Admin/People/PeopleNew")
);
export const PeopleListV2 = syncComponent(
  "PeopleListV2",
  require("./Admin/People/PeopleList/v2")
);
export const PeopleShowV2 = syncComponent(
  "PeopleShowV2",
  require("./Admin/People/Id/PeopleShow/v2")
);
export const PeopleEdit = syncComponent(
  "PeopleEdit",
  require("./Admin/People/Id/PeopleEdit")
);

/** PAYMENTS **/
export const PaymentList = syncComponent(
  "PaymentList",
  require("./Admin/Payments/PaymentList")
);
export const PaymentsNew = syncComponent(
  "PaymentsNew",
  require("./Admin/Payments/PaymentsNew")
);
export const PaymentsSearch = syncComponent(
  "PaymentsSearch",
  require("./Admin/Payments/PaymentsSearch")
);
export const PaymentsShow = syncComponent(
  "PaymentsShow",
  require("./Admin/Payments/Id/PaymentsShow")
);
export const PaymentsEdit = syncComponent(
  "PaymentsEdit",
  require("./Admin/Payments/Id/PaymentsEdit")
);
export const AdminKeywordValidation = syncComponent(
  "AdminKeywordValidation",
  require("./Admin/KeywordValidation")
);

export const AdminBulkQA = syncComponent(
  "AdminBulkQA",
  require("./Admin/BulkQA")
);

export const AdminAutomatedReminders = syncComponent(
  "AdminAutomatedReminders",
  require("./Admin/Projects/Id/AutomatedReminders")
);

export const Archived = syncComponent("Archived", require("./Admin/Archived"));
export const Favourites = syncComponent(
  "Favourites",
  require("./Admin/Favourites")
);

export const AdminGlossary = syncComponent(
  "Favourites",
  require("./Admin/Glossary")
);

// ========================================================
// Freelancer bundles
// ========================================================

export const FreelancerLayout = syncComponent(
  "FreelancerLayout",
  require("./Freelancer/_layout")
);
export const FreelancerPaymentList = syncComponent(
  "FreelancerPaymentList",
  require("./Freelancer/Payments/PaymentList")
);
export const FreelancerAssignments = syncComponent(
  "FreelancerAssignments",
  require("./Freelancer/Dashboard/Assignments/Id/List")
);
export const FreelancerTasksV2 = syncComponent(
  "FreelancerTasksV2",
  require("./Freelancer/Dashboard/Assignments/Id/Tasks/Id/Show/v2")
);
export const FreelancerDashboardLayout = syncComponent(
  "FreelancerDashboardLayout",
  require("./Freelancer/Dashboard/_layout")
);
export const FreelancerPaymentLayout = syncComponent(
  "FreelancerPaymentLayout",
  require("./Freelancer/Payments/_layout")
);
export const FreelancerDashboardInProgress = syncComponent(
  "FreelancerDashboardInProgress",
  require("./Freelancer/Dashboard/InProgress")
);
export const FreelancerDashboardApproved = syncComponent(
  "FreelancerDashboardApproved",
  require("./Freelancer/Dashboard/Approved")
);
export const FreelancerProfileShow = syncComponent(
  "FreelancerProfileShow",
  require("./Freelancer/Profile/ProfileShow")
);
export const FreelancerProfileEdit = syncComponent(
  "FreelancerProfileEdit",
  require("./Freelancer/Profile/ProfileEdit")
);
export const FreelancerProjects = syncComponent(
  "FreelancerProjects",
  require("./Freelancer/Projects/Id/Show")
);
export const FreelancerVersionHistory = syncComponent(
  "FreelancerVersionHistory",
  require("./Freelancer/Deliverable/Id/FreelancerVersionHistory")
);
export const FreelancerKeywordValidation = syncComponent(
  "FreelancerKeywordValidation",
  require("./Freelancer/KeywordValidation")
);
export const FreelancerBulkQA = syncComponent(
  "FreelancerKeywordValidation",
  require("./Freelancer/BulkQA")
);

// ========================================================
// Client bundles
// ========================================================

export const ClientLayout = syncComponent(
  "ClientLayout",
  require("./Client/_layout")
);
export const ClientAccounts = syncComponent(
  "ClientAccounts",
  require("./Client/ClientAccounts")
);

export const ClientProjectDashboard = syncComponent(
  "ClientProjectDashboard",
  require("./Client/Project/Dashboard/_layout")
);

export const ClientProjectDashboardInProgressV2 = syncComponent(
  "ClientProjectDashboardInProgressV2",
  require("./Client/Project/Dashboard/InProgress/v2")
);

export const ClientProjectDashboardCompletedV2 = syncComponent(
  "ClientProjectDashboardCompletedV2",
  require("./Client/Project/Dashboard/Completed/v2")
);

export const ClientProjectDashboardOverviewV1 = syncComponent(
  "ClientProjectDashboardOverviewV1",
  require("./Client/Project/Dashboard/Overview/v1")
);
export const ClientProjectDashboardOverviewV2 = syncComponent(
  "ClientProjectDashboardOverviewV2",
  require("./Client/Project/Dashboard/Overview/v2")
);

export const ClientBatchStage = syncComponent(
  "ClientBatchStage",
  require("./Client/Project/Batches/Id/Stages/Id/Show")
);

export const ClientProjectInfo = syncComponent(
  "ClientProjectInfo",
  require("./Client/ProjectInfo")
);

export const ClientDeliverableV2 = syncComponent(
  "ClientDeliverableV2",
  require("./Client/Project/Batches/Id/Stages/Id/Deliverable/v2")
);

export const ClientVersionHistory = syncComponent(
  "ClientVersionHistory",
  require("./Client/ClientVersionHistory/DeliverableId")
);

export const ClientSearch = syncComponent(
  "ClientSearch",
  require("./Client/Search")
);
export const ClientParentDeliverables = syncComponent(
  "ClientParentDeliverables",
  require("./Client/Project/ParentDeliverables")
);

// ========================================================
// Authentication bundles
// ========================================================

export const UnauthorizedLayout = syncComponent(
  "UnauthorizedLayout",
  require("./Unauthorized/_layout")
);
export const Login = syncComponent("Login", require("./Unauthorized/Login"));
export const Update = syncComponent("Update", require("./Unauthorized/Update"));
export const Reset = syncComponent("Reset", require("./Unauthorized/Reset"));
export const Contact = syncComponent(
  "Contact",
  require("./Unauthorized/Contact")
);
