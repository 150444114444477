import React from "react";
import styles from "./TommyOutputsForm.module.scss";
import { reduxForm } from "redux-form";
import {
  QuillFormField,
  QuillInnerFormContainer,
} from "../../../../components/QuillForm";
import QuillHeader from "../../../../components/QuillHeader";
import { InputWithErrors } from "../../../../decorators/WithErrors";

const TommyOutputsForm = ({ handleSubmit, submitting }) => {
  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <QuillHeader header={"Tommy output files"} hideRequiredHeader />
      <QuillInnerFormContainer
        containerStyle={styles.container}
        className={styles.filtersInnerContainer}
      >
        <QuillFormField
          component={InputWithErrors}
          disabled={submitting}
          labelText="batch ids (separate with commas)"
          name="batchIds"
          required
          type="text"
        />

        <div className={styles.buttonContainer}>
          <div className={styles.alignLeft}>
            <button
              className={styles.submit}
              disabled={submitting}
              type="submit"
            >
              Create files
            </button>
          </div>
        </div>
      </QuillInnerFormContainer>
    </form>
  );
};

const ConnectTommyOutputsForm = reduxForm({
  form: "TommyOutputsForm",
  // @ts-ignore
})(TommyOutputsForm);

export default ConnectTommyOutputsForm;
