import React from "react";
import PropTypes from "prop-types";
import { SelectionState } from "draft-js";
import { wordMatchRegex } from "../../../../../utils/string";
import { parseCommentGroupId } from "../InlineComments/InlineComments";
import WordSegments from "./WordSegments";

const ranges = new Map();
const COMMENT = "comment";
const KEYWORD = "keyword";
const COMKEY = "comKey";

const callRanges = (name, start, end, blockKey, callback) => {
  ranges.set(`${name}-${start}-${end}-${blockKey}`, [start, end, blockKey]);
  callback(start, end);
};

const mergeRanges = (ranges) => {
  const result = [];
  let last;

  ranges.forEach((range) => {
    if (!last || range[0] > last[1]) result.push((last = range));
    else if (range[1] > last[1]) last[1] = range[1];
  });

  return result;
};

const clearRanges = (blockKey) => {
  for (let [key, value] of ranges.entries()) {
    const [, , block] = value;
    if (block === blockKey) {
      ranges.delete(key);
    }
  }
};

const KeywordsCommentsStrategy = (commentGroups, keywords) => {
  /**
   * @param {ContentBlock}  contentBlock  DraftJS content block to check
   * @param {function}      callback      callback to call with the start and end
   */

  return (contentBlock, callback) => {
    const commentRanges = [];
    const keywordRanges = [];
    const regex = wordMatchRegex(keywords);
    const text = contentBlock.getText();
    const blockKey = contentBlock.getKey();
    clearRanges(blockKey);

    if (keywords.length === 0 || text.trim().length === 0) return;

    contentBlock.findStyleRanges(
      ({ style }) => {
        return style.some((name) => {
          const commentGroupId = parseCommentGroupId(name);
          return commentGroupId && commentGroups[commentGroupId];
        });
      },
      (start, end) => {
        commentRanges.push([start, end]);
      }
    );

    let matchArr, start, end;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      end = start + matchArr[0].length;
      keywordRanges.push([start, end]);
    }

    const mergedCommentRanges = mergeRanges(commentRanges);
    const merged = [...mergedCommentRanges, ...keywordRanges];
    const values = {};
    const segments = [];

    merged.forEach((a) => {
      values[a[0]] = true;
      values[a[1]] = true;
    });

    Object.keys(values)
      .map(Number)
      .sort((a, b) => a - b)
      .reduce((acc, cur) => {
        segments.push([acc, cur]);
        return cur;
      }, 0);

    const splicedSegments = segments.splice(1); // previous reduce function requires an initial value which we remove here
    splicedSegments.forEach(([start, end], i) => {
      mergedCommentRanges.forEach(([comStart, comEnd]) => {
        keywordRanges.forEach(([keyStart, keyEnd]) => {
          // if keywords are within comment range
          if (start >= comStart && end <= comEnd) {
            // keyword and comment span over same range
            if (start === keyStart && end === keyEnd) {
              callRanges("comKey", start, end, blockKey, callback);
            }
            // if comment in middle but doesn't hit keyword edges
            if (start > keyStart && end < keyEnd) {
              callRanges("comKey", start, end, blockKey, callback);
            }
            //if comment starts from the left and overlaps keyword towards right
            if (start === keyStart && end === comEnd) {
              callRanges("comKey", start, end, blockKey, callback);
            }
            // if comments starts from the right and overlaps keyword towards left
            if (start === comStart && end === keyEnd) {
              callRanges("comKey", start, end, blockKey, callback);
            }
            // if comment is before keyword
            if (start < keyStart && end === keyStart) {
              callRanges("comment", start, end, blockKey, callback);
            }
            // if comment is after keyword
            if (start === keyEnd && end > keyEnd) {
              callRanges("comment", start, end, blockKey, callback);
            }
          }
          // if keyword continues after a comKey from left to right
          if (start === comEnd && end === keyEnd) {
            callRanges("keyword", start, end, blockKey, callback);
          }
          // if keyword continues after a comKey from right to left
          if (start === keyStart && end === comStart) {
            callRanges("keyword", start, end, blockKey, callback);
          }
          // if keyword in between two comKeys on same keyword
          if (
            start > keyStart &&
            end < keyEnd &&
            (start === comEnd || end === comStart)
          ) {
            callRanges("keyword", start, end, blockKey, callback);
          }
        });
      });
    });
  };
};

/**
 * Highlight commented content with keywords
 */
const KeywordCommentsComponent = (props) => {
  const getCommentGroupDetails = () => {
    const { commentGroups, contentState, children, offsetKey } = props;

    // Get comment group id from style
    const [blockKey] = offsetKey.split("-");
    const block = contentState.getBlockForKey(blockKey);
    const { start } = children[0].props;

    const commentGroupId = block
      .getInlineStyleAt(start)
      .toArray()
      .map((styleName) => parseCommentGroupId(styleName))
      .filter((id) => id && commentGroups[id])
      .pop();

    return { commentGroupId, blockKey, block };
  };

  const onClick = (ref) => {
    const { openCommentBox } = props;
    const { commentGroupId, blockKey, block } = getCommentGroupDetails();

    // Create selection
    const selection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: block.getLength(),
      isBackward: false,
    });

    if (ref.current) {
      const positionRect = ref.current.getBoundingClientRect();

      if (typeof window !== "undefined") {
        positionRect.y += window.pageYOffset;
      }

      openCommentBox(positionRect, "inline", commentGroupId, selection);
    }
  };

  const { commentGroups, offsetKey, start, end, children } = props;
  const [blockKey] = offsetKey.split("-");
  const { commentGroupId } = getCommentGroupDetails();
  const isResolved = commentGroups[commentGroupId]?.resolved;
  const typeArray = [COMMENT, KEYWORD, COMKEY];

  return typeArray.map((type, i) => (
    <WordSegments
      key={i}
      type={type}
      ranges={ranges}
      start={start}
      end={end}
      blockKey={blockKey}
      children={children}
      isResolved={isResolved}
      onClick={onClick}
    />
  ));
};

KeywordCommentsComponent.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        start: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
  commentGroups: PropTypes.object.isRequired,

  // Draftjs
  contentState: PropTypes.object.isRequired,
  offsetKey: PropTypes.string.isRequired,
  openCommentBox: PropTypes.func.isRequired,
};

/**
 * @param   {Object}    commentGroups
 * @param   {function}  openCommentBox
 * @returns {Object}    DraftJS decorator object
 */
export default (commentGroups, openCommentBox, keywords) => {
  return {
    component: KeywordCommentsComponent,
    props: { commentGroups, openCommentBox, keywords },
    strategy: KeywordsCommentsStrategy(commentGroups, keywords),
  };
};
