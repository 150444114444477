import React, { useCallback } from "react";
import styles from "./index.module.scss";
import { change } from "redux-form";
import useHover from "../../../../hooks/useHover";
import Tooltip from "../Tooltip";
import { useDispatch } from "react-redux";

const AvailableHeader = ({
  allSelected,
  languageCode,
  stageId,
  defaultAssignees,
}) => {
  const [hoverRef, isHovered] = useHover();
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    defaultAssignees.forEach(({ personId }) => {
      const path = `assignees[${stageId}][${languageCode}][${personId}].available`;
      dispatch(change("assignmentsForm", path, !allSelected));
    });
  }, [allSelected, defaultAssignees, dispatch, languageCode, stageId]);

  return (
    <div ref={hoverRef} className={styles.container} onClick={onClick}>
      <div className={styles.textContainer}>A</div>

      {isHovered && (
        <Tooltip>{allSelected ? "Deselect all" : "Select all"}</Tooltip>
      )}
    </div>
  );
};

export default AvailableHeader;
