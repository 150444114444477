import React from "react";
import PropTypes from "prop-types";

import styles from "./BriefingFieldsArray.module.scss";

import RenderFieldArray from "../_decorators/RenderFieldArray";
import FieldsWithFormat from "../FieldsWithFormat";
import { DragSource, DropTarget } from "react-dnd";
import DraggableHelpers from "../DraggableHelpers";
import flow from "lodash/flow";
import NewFieldAddButton from "./../NewFieldAddButton/NewFieldAddButton";

const BriefingLabel = (props) => (
  <div className={styles.container}>
    <label className={styles.quillHeader} htmlFor="briefing">
      Briefing Fields
    </label>
  </div>
);

const BriefingFields = (props) => (
  <FieldsWithFormat
    {...props}
    type="briefingFields"
    inputFieldName="briefingFieldName"
    placeholder="Enter briefing field name, eg URL, Topic"
    selectFieldName="briefingFieldFormat"
  />
);

const DraggableBriefingFields = flow(
  DropTarget(
    "briefingFields",
    DraggableHelpers.dragTarget,
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
    })
  ),
  DragSource(
    "briefingFields",
    DraggableHelpers.dragSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    })
  )
)(BriefingFields);

const BriefingFieldsArray = RenderFieldArray(
  BriefingLabel,
  DraggableBriefingFields,
  NewFieldAddButton
);

export default BriefingFieldsArray;
