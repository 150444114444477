import React, { Component } from "react";
import PropTypes from "prop-types";
import UpdateForm from "./UpdateForm";
import { SubmissionError } from "redux-form";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import ResetForm from "../../_components/ResetForm";
import styles from "./Update.module.scss";

class Update extends Component {
  constructor(props) {
    super(props);
    this.state = { success: false, tokenError: false, tokenExpiryMsg: false };
  }

  update = (data) => {
    return this.props.updateFetch(data).then((r) => {
      if (r.status === 200) return this.setState({ success: true });
      if (r.status === 401)
        return this.setState({ tokenError: true, tokenExpiryMsg: true });

      throw new SubmissionError({
        _error:
          "There was an error connecting to the server. Please try again later.",
      });
    });
  };

  reset = (data) => {
    return this.props.resetFetch(data).then((r) => {
      if (r.status === 200) {
        this.setState({ tokenExpiryMsg: false });
      } else {
        throw new SubmissionError({
          _error:
            "There was an error connecting to the server. Please try again later.",
        });
      }
    });
  };

  renderContent() {
    if (this.state.tokenError) {
      return (
        <div>
          {this.state.tokenExpiryMsg ? (
            <div>
              <div className={styles.notice}>
                <div>This password reset token has expired.</div>
                <div>Please generate a new token.</div>
              </div>
              <ResetForm onSubmit={this.reset} />
            </div>
          ) : (
            <div className={styles.center}>
              Please check your email for reset instructions
            </div>
          )}
        </div>
      );
    }
    if (this.state.success) {
      return (
        <div>
          <div>We have successfully updated your password.</div>
          <Link to="/"> Return to Login </Link>
        </div>
      );
    }
    return (
    <div className={styles.container}>
      <UpdateForm onSubmit={this.update} />;
    </div>
    )
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Update Password</title>
        </Helmet>
        {this.renderContent()}
      </div>
    );
  }
}

Update.propTypes = {
  updateFetch: PropTypes.func.isRequired,
  resetFetch: PropTypes.func.isRequired,
};

export default Update;
