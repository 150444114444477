import React from "react";
import VersionHistory from "../../../../../../components/VersionHistory";
import PropTypes from "prop-types";

const FreelancerVersionHistory = ({ versionHistory }) => {
  return <VersionHistory versionHistory={versionHistory} />;
};

FreelancerVersionHistory.propTypes = {
  versionHistory: PropTypes.array.isRequired,
};

export default FreelancerVersionHistory;
