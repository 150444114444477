import React from "react";
import PropTypes from "prop-types";
import styles from "./CurrencyDisplay.module.scss";
import { QuillFormField } from "../../QuillForm";
import { InputWithErrors } from "../../../decorators/WithErrors";
import { QuillField } from "../../QuillContainer";

const CurrencyDisplay = ({
  isInputField,
  isFormField,
  currencySymbol,
  text,
  rate,
  parsedRate,
  name,
}) => {
  const currency = currencySymbol ? currencySymbol : "";
  const isText = text ? text : "Payment amount";
  const parse = rate && parseFloat(rate).toFixed(2);

  if (isInputField && currencySymbol) {
    return (
      <QuillFormField
        currencySymbol={currencySymbol}
        component={InputWithErrors}
        labelText={`${isText}`}
        min={0}
        name={name}
        options={[]}
        parse={parsedRate}
        placeholder="0000.00"
        required
        step={0.01}
        type="number"
      />
    );
  }
  if (isFormField) {
    return (
      <QuillField labelText={`${isText}`} value={`${currencySymbol}${rate}`} />
    );
  }

  if (text && parse) return `${text} ${currency}${parse}`;
  if (text && !parse) return `${text} ${currency}`;
  if (!text && parse >= 0) return `${currency}${parse}`;

  return <span className={styles.symbol}>{currency}</span>;
};

CurrencyDisplay.propTypes = {
  currencySymbol: PropTypes.string,
  isFormField: PropTypes.bool,
  isInputField: PropTypes.bool,
  name: PropTypes.string,
  parsedRate: PropTypes.func,
  rate: PropTypes.number,
  text: PropTypes.string,
};

export default CurrencyDisplay;
