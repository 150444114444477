import { getGraphQL } from "../../../../utils/fetch";
import {
  accountsByPersonIdQuery,
  fetchAccountsSuccess,
} from "../../../modules/accounts";
import {
  personOverallGradesQuery,
  fetchOverallGradeSuccess,
  shouldDisplayGrades,
} from "../../../modules/grades";
import {
  peopleByPersonIdQuery,
  fetchPersonSuccess,
} from "../../../modules/people";
import {
  personAccountsQuery,
  fetchPersonAccountsSuccess,
} from "../../../modules/personAccounts";
import {
  personNativeLanguagesQuery,
  fetchPersonNativeLanguagesSuccess,
} from "../../../modules/personNativeLanguages";
import {
  personRolesByPersonIdQuery,
  fetchPersonRolesSuccess,
} from "../../../modules/personRoles";

export function getInitialData(params, url, cookies) {
  return async (dispatch, getState) => {
    const initialQuery = `query PeopleShow_fetchPersonAndAccounts ($personId: String) {
      ${accountsByPersonIdQuery}
      ${personAccountsQuery}
      ${shouldDisplayGrades(getState()) ? personOverallGradesQuery : ""}
      ${peopleByPersonIdQuery}
      ${personNativeLanguagesQuery}
      ${personRolesByPersonIdQuery}
    }`;

    const json = await getGraphQL(initialQuery, params, url, cookies);
    dispatch(fetchAccountsSuccess(json.accounts));
    dispatch(fetchPersonAccountsSuccess(json.personAccounts));
    dispatch(fetchPersonSuccess(json.person));
    dispatch(fetchPersonNativeLanguagesSuccess(json.personNativeLanguages));
    dispatch(fetchPersonRolesSuccess(json.personRoles));

    if (json.personOverallGrades) {
      dispatch(fetchOverallGradeSuccess(json.personOverallGrades));
    }
  };
}
