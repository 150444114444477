import {
  checkValidations,
  isNotBlank,
} from "../../../../../../../utils/validations";

import { toMysqlFormat } from "../../../../../../../utils/date";

const validate = (values) => {
  const errors = {};

  const dateHasChanged =
    values.createDate &&
    toMysqlFormat(values.createDate) !== values.prevCreateDate;
  const rateHasChanged = Number(values.newRate) !== values.prevRate;

  const newRateErrors = checkValidations(values.newRate, [
    { validate: isNotBlank, msg: "Field cannot be blank" },
    {
      validate: () => rateHasChanged || dateHasChanged,
      msg: "Please enter a new rate (cannot be equal to the previous rate)",
    },
  ]);

  const datePickerErrors = checkValidations(values.createDate, [
    {
      validate: () => dateHasChanged || rateHasChanged,
      msg: "Please enter a new date (cannot be equal to the previous date)",
    },
  ]);

  if (newRateErrors) errors.newRate = newRateErrors;
  if (datePickerErrors) errors.createDate = datePickerErrors;

  if (!dateHasChanged && !rateHasChanged) {
    errors._error = "Please make sure either the date or rate has been changed";
  }

  return errors;
};

export default validate;
