import handleErrors from "../../../../../../utils/handleErrors";
import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../modules/parentAccounts";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../modules/accounts";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../modules/projects";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../modules/projectLanguages";
import {
  briefingFieldsByProjectIdQuery,
  fetchBriefingFieldsSuccess,
} from "../../../../../../modules/briefingFields";
import {
  keywordGroupsByProjectIdQuery,
  fetchKeywordGroupsSuccess,
} from "../../../../../../modules/keywordGroups";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../modules/batches";
import {
  sourceFieldsByProjectIdQuery,
  fetchSourceFieldsSuccess,
} from "../../../../../../modules/sourceFields";
import {
  rateBandsByProjectIdQuery,
  fetchRateBandsSuccess,
} from "../../../../../../modules/rateBands";

export const initialQuery = `query ProjectBulkUploads ($projectId: String) {
  ${parentAccountsByProjectQuery}
  ${orderFormsByProjectQuery}
  ${accountsByProjectQuery}
  ${projectQuery}
  ${projectLanguagesByProjectIdQuery}
  ${briefingFieldsByProjectIdQuery}
  ${batchesByProjectIdQuery}
  ${keywordGroupsByProjectIdQuery}
  ${sourceFieldsByProjectIdQuery}
  ${rateBandsByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    try {
      const json = await getGraphQL(initialQuery, params, url, cookies);
      dispatch(fetchParentAccountsSuccess(json.parentAccounts));
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectSuccess(json.project));
      dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
      dispatch(fetchBriefingFieldsSuccess(json.briefingFields));
      dispatch(fetchBatchesSuccess(json.batches));
      dispatch(fetchKeywordGroupsSuccess(json.keywordGroups));
      dispatch(fetchSourceFieldsSuccess(json.sourceFields));
      dispatch(fetchRateBandsSuccess(json.rateBands));
    } catch (err) {
      handleErrors(err);
    }
  };
}
