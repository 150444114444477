import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { renderRoutes } from "react-router-config";
import { Link } from "react-router-dom";
import styles from "./Layout.module.scss";

class Layout extends Component {
  constructor(props) {
    super(props);

    let active;
    if (this.props.location.pathname.indexOf("approved") > -1) {
      active = "approved";
    } else {
      active = "inProgress";
    }

    this.state = { active };
    this.toggleTab = (val) => this.setState({ active: val });
  }

  render() {
    const baseUrl = "/freelancer";

    const inProgressStyles = classNames({
      [styles.active]: this.state.active === "inProgress",
      [styles.navLink]: true,
    });
    const approvedStyles = classNames({
      [styles.active]: this.state.active === "approved",
      [styles.navLink]: true,
    });

    return (
      <div>
        <div className={styles.nav}>
          <Link
            to={`${baseUrl}`}
            className={inProgressStyles}
            onClick={() => this.toggleTab("inProgress")}
          >
            In Progress
          </Link>

          <Link
            to={`${baseUrl}/approved`}
            className={approvedStyles}
            onClick={() => this.toggleTab("approved")}
          >
            Approved
          </Link>
        </div>

        {renderRoutes(this.props.route.routes)}
      </div>
    );
  }
}

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  route: PropTypes.object.isRequired,
};

export default Layout;
