import { createAction } from "redux-actions";
import { getGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";

const rateBandVolumeFields = `rateBandId, languageCode, volume`;

export const rateBandVolumeQuery = `rateBandVolume (projectId: $projectId, languageCode: $languageCode) {
    ${rateBandVolumeFields}
}`;

export const rateBandItemsByProjectIdQuery = `rateBandVolume (projectId: $projectId) {
  ${rateBandVolumeFields}
}`;

export const rateBandVolumesUpdateQuery = `updateRateBandVolumes (associations: $associations){
  ${rateBandVolumeFields}
}`;

export const FETCH_RATE_BAND_VOLUME_SUCCESS = "FETCH_QUOTED_RATES_SUCCESS";
export const UPDATE_RATE_BAND_VOLUME_SUCCESS = "UPDATE_QUOTED_RATES_SUCCESS";
export const fetchQuotedRatesSuccess = createAction(
  FETCH_RATE_BAND_VOLUME_SUCCESS
);
export const updateRateBandVolumeSuccess = createAction(
  UPDATE_RATE_BAND_VOLUME_SUCCESS
);

export function fetchLanguageRateBandVolumes(projectId, languageCode) {
  return async (dispatch) => {
    const { rateBandVolume } = await getGraphQL(
      `
        query fetchRateBandVolume($projectId: String, $languageCode: String) {
          rateBandVolume: ${rateBandVolumeQuery}
        }
        `,
      { projectId, languageCode }
    );
    dispatch(fetchQuotedRatesSuccess(rateBandVolume));
  };
}

export const rateBandVolumeActionHandlers = {
  [RESET_INITIAL_STATE]: () => rateBandVolumeInitialState,
  [FETCH_RATE_BAND_VOLUME_SUCCESS]: (state, { payload }) => {
    return state.concat(payload);
  },
  [UPDATE_RATE_BAND_VOLUME_SUCCESS]: (state, { payload }) => {
    const newState = [...state];

    payload.forEach((rateBandVolume) => {
      const { rateBandId, languageCode } = rateBandVolume;

      const index = newState.indexOf(
        newState.find(
          (rbi) =>
            rbi.rateBandId === rateBandId && rbi.languageCode === languageCode
        )
      );

      if (index > -1) {
        newState[index] = rateBandVolume;
      } else {
        newState.push(rateBandVolume);
      }
    });

    return newState;
  },
};

export const rateBandVolumeInitialState = [];
