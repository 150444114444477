import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./CreateBatchRow.module.scss";

const CreateBatchRow = ({ projectId }) => {
  const url = `/admin/projects/${projectId}/project-batches?new=true`;

  return (
    <div className={styles.batchContainer}>
      <label className={styles.labelRequired}>Batch</label>
      <Link className={styles.createBtn} to={url}>
        <span>Create batch</span>
      </Link>
    </div>
  );
};

CreateBatchRow.propTypes = {
  projectId: PropTypes.number,
};

export default memo(CreateBatchRow);
