import React from "react";
import styles from "./LinkButton.module.scss";
import { RichUtils, EditorState, Modifier } from "draft-js";
import PropTypes from "prop-types";
import classNames from "classnames";
import Input from "../../../../Input";

class LinkEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: this.props.url || "",
      text: this.props.text || this.props.selectedText || "",
      title: this.props.title || "",
      target: this.props.target,
    };

    this.onURLChange = (e) => this.setState({ url: e.target.value });
    this.onTextChange = (e) => this.setState({ text: e.target.value });
    this.onTitleChange = (e) => this.setState({ title: e.target.value });
  }

  getMergedSelection() {
    const { editorState } = this.props;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const blockWithLink = contentState.getBlockForKey(startKey);
    const selectedText = blockWithLink.getText().slice(startOffset, endOffset);

    let updatedSelection;

    blockWithLink.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === "LINK"
        );
      },
      (start, end) => {
        if (start <= startOffset && startOffset < end) {
          updatedSelection = selection.merge({
            anchorOffset: start,
            focusOffset: end,
          });
          return updatedSelection;
        }
      }
    );
    return {
      editorState,
      contentState,
      selection,
      selectedText,
      updatedSelection,
    };
  }

  update = () => {
    const { target, text, url, title } = this.state;
    const {
      editorState,
      contentState,
      selection,
      updatedSelection,
      selectedText,
    } = this.getMergedSelection();

    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { target, url, title, text }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithLink = Modifier.replaceText(
      contentState,
      !selectedText && updatedSelection ? updatedSelection : selection,
      !selectedText ? text : selectedText,
      editorState.getCurrentInlineStyle(),
      entityKey
    );

    const updatedEditorState = EditorState.push(
      editorState,
      contentStateWithLink,
      "insert-characters"
    );
    this.props.updateEditorState(
      RichUtils.toggleLink(
        updatedEditorState,
        !selectedText && updatedSelection ? updatedSelection : selection,
        entityKey
      )
    );

    this.props.togglePopup();
  };

  delete = () => {
    const { editorState, selection, updatedSelection, selectedText } =
      this.getMergedSelection();

    this.props.updateEditorState(
      RichUtils.toggleLink(
        editorState,
        !selectedText ? updatedSelection : selection,
        null
      )
    );

    this.props.togglePopup();
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      return this.update();
    }
  };

  setTarget = () => {
    const { target } = this.state;
    if (target) {
      this.setState({ target: null });
    } else {
      this.setState({ target: "_blank" });
    }
  };

  render() {
    const { text, title, url, target } = this.state;

    const urlInputStyles = classNames({
      [styles.input]: true,
      [styles.errorInput]: !this.state.url,
    });

    return (
      <div onKeyDown={this.handleKeyPress} className={styles.popup}>
        <div className={styles.crossDiv}>
          <svg
            onClick={this.props.togglePopup}
            className={styles.popupCross}
            width="15"
            height="15"
          >
            {/* eslint-disable max-len  */}
            <path
              fill="#1A1A1A"
              d="M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z"
            />
            {/* eslint-enable max-len */}
          </svg>
        </div>

        {!this.props.text && !this.props.selectedText && (
          <Input
            onChange={this.onTextChange}
            placeholder="Add text here"
            type="text"
            value={text}
            input={{ name: "linkText" }}
            styles={styles.input}
          />
        )}
        <Input
          styles={urlInputStyles}
          onChange={this.onURLChange}
          placeholder="https://www.example.com"
          type="text"
          input={{ name: "linkUrl" }}
          value={url}
        />
        <Input
          styles={styles.input}
          onChange={this.onTitleChange}
          placeholder="Add alt text here"
          type="text"
          value={title}
          input={{ name: "linkAlt" }}
        />
        <label className={styles.checkboxLabel}>
          Open in new tab after export:
          <input
            className={styles.checkboxInput}
            type="checkbox"
            onClick={this.setTarget}
            checked={target}
          />
        </label>

        <div className={styles.buttonsGroup}>
          <button
            disabled={!this.state.url}
            className={styles["update-add"]}
            onClick={this.update}
          >
            {this.props.text ? "Update" : "Add"}
          </button>
          <button className={styles["remove"]} onClick={this.delete}>
            Remove
          </button>
        </div>
      </div>
    );
  }
}

LinkEditor.propTypes = {
  editorState: PropTypes.object,
  selectedText: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  togglePopup: PropTypes.func.isRequired,
  updateEditorState: PropTypes.func.isRequired,
  url: PropTypes.string,
};

export default LinkEditor;
