import { createSelector } from "reselect";
import {
  genericSelectorWithReference,
  individualSelector,
} from "../utils/entitySelector";

export const StageType = Object.freeze({
  Amends: "Amends",
  Client: "Client",
  Production: "Production",
  Training: "Training",
});

export type StageType = {
  stageId: number;
  stageName: string;
  stageType: string;
  isFinal: boolean;
};

type StageByIdType = (state: any, stageId: number) => StageType;

export const stageById: StageByIdType = individualSelector("stages");

export const AI_STAGES = ["AI Writing", "AI Localisation"];

type WorkflowStagesSelectorType = (state: any, stageId: number) => StageType[];

export const workflowStagesSelector: WorkflowStagesSelectorType =
  genericSelectorWithReference("stages", "workflowId", {
    sort: { keys: ["stagePosition"] },
  });

type HasSourceAmendsStageType = (state: any, workflowId: number) => boolean;
export const hasSourceAmendsStage: HasSourceAmendsStageType = createSelector(
  workflowStagesSelector,
  (stages) => stages.some((s) => s.stageName.match(/source amends/i))
);

const assignableStageTypes = new Set(["Production", "Amends", "Training"]);
export const getAssignableWorkflowStages = createSelector(
  workflowStagesSelector,
  (stages) => stages.filter((s) => assignableStageTypes.has(s.stageType))
);

export const workflowClientStages = createSelector(
  workflowStagesSelector,
  (stages) => stages.filter((s) => s.stageType === StageType.Client)
);
