import React from "react";
import styles from "./index.module.scss";

const Tooltip = (props) => {
  return (
    <div className={styles.tooltip}>
      <div className={styles.triangle} />
      <div className={styles.tooltipText}>{props.children}</div>
    </div>
  );
};

export default Tooltip;
