import { postGraphQL } from "../../utils/fetch";
import { upsertData, removeData } from "../utils/normalize";
import { createAction } from "redux-actions";
import { change, SubmissionError } from "redux-form";
import { hideModal } from "../modules/modal";
import { RESET_INITIAL_STATE } from "./me";
import { convertDate, formatDateObject, toMysqlFormat } from "../utils/date";

// Date fields for batches

export const dateFields = [
  { label: "Amended content feedback due at", field: "amendedContentDueAt" },
  { label: "Approval due at", field: "approvalDueAt" },
  { label: "Due live at", field: "dueLiveAt" },
  { label: "New content feedback due at", field: "newContentFeedbackDueAt" },
  { label: "Received at", field: "receivedAt" },
];

export const textFields = [
  { label: "Batch name * ", field: "batchName" },
  { label: "Details", field: "details" },
  { label: "Link", field: "link" },
];

// ------------------------------------
// GraphQL Queries
// ------------------------------------

const batchDeadlineFields = [
  "amendedContentDueAt",
  "approvalDueAt",
  "dueLiveAt",
  "newContentFeedbackDueAt",
  "receivedAt",
];
const batchFields = `
  batchId, batchName, commentGroupId, details, link, projectId, archived, 
  defaultDeadlines { 
    batchId, stageId, deadline 
  }, 
  ${batchDeadlineFields}
`;

export const batchesByProjectIdQuery = `batches (projectId: $projectId) { ${batchFields} }`;
export const batchesByProjectIdAndStatusQuery = `batches (projectId: $projectId, batchStatus: $batchStatus) { ${batchFields} }`;
export const batchByIdQuery = `batch (batchId: $batchId) { ${batchFields} }`;
export const batchesByAssignmentGroupIdQuery = `batches (assignmentGroupId: $assignmentGroupId) { ${batchFields} }`;
export const batchesByAssignmentQuery = `batches (assignmentId: $assignmentId) { ${batchFields} }`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_BATCHES_SUCCESS = "FETCH_BATCHES_SUCCESS";
export const FETCH_BATCHES_BY_PERSON_ID_SUCCESS =
  "FETCH_BATCHES_BY_PERSON_ID_SUCCESS";
export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS";
export const UPDATE_BATCH_SUCCESS = "UPDATE_BATCH_SUCCESS";
export const ARCHIVE_BATCH_SUCCESS = "ARCHIVE_BATCH_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchBatchesSuccess = createAction(FETCH_BATCHES_SUCCESS);
export const fetchBatchesByPersonIdSuccess = createAction(
  FETCH_BATCHES_BY_PERSON_ID_SUCCESS
);
export const createBatchSuccess = createAction(CREATE_BATCH_SUCCESS);
export const updateBatchSuccess = createAction(UPDATE_BATCH_SUCCESS);
export const archiveBatchSuccess = createAction(ARCHIVE_BATCH_SUCCESS);

/**
 * @param   {Object}  batch data
 * @returns {Object}  formatted dates
 */
const formatDates = (data) =>
  batchDeadlineFields.reduce((acc, key) => {
    if (data[key]) {
      acc[key] = toMysqlFormat(new Date(data[key]));
    }
    return acc;
  }, {});

export function createBatch(data, formName) {
  return async (dispatch, getState) => {
    const { me: createdBy } = getState();

    const batchDeadlines = Object.keys(data.batchDeadlines || {}).reduce(
      (acc, stageId) => {
        const deadline = formatDateObject(
          convertDate(data.batchDeadlines[stageId], true)
        );
        if (!deadline) return acc;
        return acc.concat({ createdBy, deadline, stageId });
      },
      []
    );

    const input = {
      ...data,
      ...formatDates(data),
      batchDeadlines,
    };

    const query = `mutation createBatch($input: BatchInput) {
      createBatch(input: $input) {
        ${batchFields}
      }
    }`;

    return postGraphQL(query, { input }, "createBatch")
      .then((json) => {
        dispatch(createBatchSuccess(json));
        dispatch(hideModal());
        if (formName) dispatch(change(formName, "batchId", json.batchId));

        return json.batchId;
      })
      .catch((err) => {
        if (err.errors) {
          const _error = err.errors.map((error) => error.message).join(", ");
          throw new SubmissionError({ _error: `${_error}` });
        }

        throw new SubmissionError({
          _error:
            "There was an error connecting to the server. Please try again later.",
        });
      });
  };
}

export function updateBatch(data) {
  return async (dispatch) => {
    const query = `mutation updateBatch($input: BatchInput) {
      updateBatch(input: $input) {
        ${batchFields}
      }
    }`;

    const { defaultDeadlines = {}, ...batch } = data;

    const defaultDeadlinesFormatted = Object.entries(defaultDeadlines).map(
      ([id, deadline]) => ({
        stageId: Number(id),
        deadline: deadline ? toMysqlFormat(new Date(deadline)) : "",
      })
    );

    const input = {
      ...batch,
      ...formatDates(batch),
      defaultDeadlines: defaultDeadlinesFormatted,
    };

    try {
      const json = await postGraphQL(query, { input }, "updateBatch");
      dispatch(updateBatchSuccess(json));
    } catch (e) {
      if (e.errors) {
        const _error = e.errors.map((error) => error.message).join(", ");
        throw new SubmissionError({ _error });
      }
      throw new SubmissionError({
        _error:
          "There was an error connecting to the server. Please try again later.",
      });
    }
  };
}

export function archiveBatch(batchId) {
  return async (dispatch, getState) => {
    const query = `mutation archiveBatch($input: BatchInput){
      archiveBatch(input: $input) {
        batchId
      }
    }`;
    try {
      await postGraphQL(query, { input: { batchId } }, "archiveBatch");
      dispatch(archiveBatchSuccess(batchId));
    } catch (err) {
      console.log("Error archiving batch", err.message);
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
export const batchActionHandlers = {
  [RESET_INITIAL_STATE]: () => batchInitialState,
  [FETCH_BATCHES_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "batchId"),
  [CREATE_BATCH_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "batchId"),
  [FETCH_BATCHES_BY_PERSON_ID_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "batchId"),
  [UPDATE_BATCH_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "batchId"),
  [ARCHIVE_BATCH_SUCCESS]: (state, { payload }) => removeData(state, payload),
};

export const batchInitialState = { entities: {}, result: [] };
