import { connect } from "react-redux";
import {
  accountsDropDownSelector,
  accountsFromPersonAccountsSelector,
  languagesDropdownSelector,
  personNativeLanguagesByPersonId,
  personRolesSelector,
} from "../../../../../../utils/entitySelector";
import PeopleEdit from "../components/PeopleEdit";
import { updatePerson } from "../../../../../../modules/people";
import { getInitialData } from "../modules/getInitialData";
import { searchAccounts } from "../../../../../../modules/accounts";
import WithData from "../../../../../../decorators/WithData";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    updatePerson: (data) => dispatch(updatePerson(data, ownProps.history)),
    searchAccounts: (input) => dispatch(searchAccounts(input)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const personId = Number(ownProps.match.params.personId);
  const personAccounts = accountsFromPersonAccountsSelector(
    personId,
    state,
    "accountId"
  );
  const accounts = accountsDropDownSelector(state);
  const languages = languagesDropdownSelector(state);
  const personNativeLanguages = personNativeLanguagesByPersonId(
    state,
    personId
  ).map(({ nativeLanguage }) => nativeLanguage);
  const personRoles = personRolesSelector(state, personId);
  const personRolesDropdown = personRoles.map(({ role }) => role);
  const person = {
    ...state.people.entities[personId],
    account: personAccounts,
    role: personRolesDropdown,
    nativeLanguage: personNativeLanguages,
  };

  return {
    accounts,
    languages,
    person,
    personNativeLanguages,
    personRoles,
    featureToggles: state.featureToggles,
  };
};

const PeopleEditWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(PeopleEdit));
PeopleEditWithData.getInitialData = getInitialData;
export default PeopleEditWithData;
