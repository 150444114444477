import { connect } from "react-redux";
import PaymentsNew from "../components/PaymentsNew";
import { getInitialData } from "../modules/getInitialData";
import {
  accountsDropDownSelector,
  orderFormsDropdownSelector,
  projectsDropdownSelector,
  listToValueAndLabel,
  listToValueAndLabelMap,
  personDropdownSelector,
  projectById,
} from "../../../../../utils/entitySelector";
import WithData from "../../../../../decorators/WithData";
import { fetchOrderFormsByAccountId } from "../../../../../modules/orderForms";
import { fetchProjectsByOrderFormId } from "../../../../../modules/projects";
import { searchFreelancers } from "../../../../../modules/people";
import {
  createPayment,
  clearPaymentsForm,
  paymentTasks,
} from "../../../../../modules/payments";
import { showErrors } from "../../../../../modules/errors";
import { isArray } from "../../../../../utils/dataTypes";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  fetchOrderFormsByAccountId: (accountIds) =>
    dispatch(fetchOrderFormsByAccountId(accountIds)),
  fetchProjectsByOrderFormId: (orderFormId) =>
    dispatch(fetchProjectsByOrderFormId(orderFormId)),
  searchFreelancers: (input) => dispatch(searchFreelancers(input)),
  clearPaymentsForm: () => dispatch(clearPaymentsForm()),
  createPayment: (data) =>
    dispatch(createPayment(data, ownProps.history, ownProps.match.params)),
  showErrors: (err) => {
    let errorMessage = err.message;
    if (err && err.errors && isArray(err.errors) && err.errors.length) {
      errorMessage = `${err.errors[0].name}: ${err.errors[0].message}`;
    }
    dispatch(showErrors(errorMessage));
  },
});

const mapStateToProps = (state, ownProps) => {
  const {
    form: { paymentsForm },
    me,
  } = state;

  const freelancers = personDropdownSelector(state).filter(
    (person) => person.object.personType === "Freelancer"
  );
  const accounts = accountsDropDownSelector(state);
  let orderForms = [];
  let projects = [];
  let formProjectId;
  const tasks = listToValueAndLabelMap(paymentTasks);

  if (paymentsForm && paymentsForm.values) {
    const { accountId, orderFormId, projectId } =
      state.form.paymentsForm.values;

    orderForms = orderFormsDropdownSelector(state).filter((orderForm) => {
      return orderForm.object.accountId === accountId;
    });

    projects = projectsDropdownSelector(state).filter((project) => {
      return project.object.orderFormId === orderFormId;
    });

    formProjectId = projectById(state, projectId);
  }
  const { currencyCode } = formProjectId || {};

  const errors = state.errors;

  return {
    me,
    freelancers,
    accounts,
    orderForms,
    projects,
    tasks,
    errors,
    currencyCode,
    featureToggles: state.featureToggles,
  };
};

const PaymentsNewWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(PaymentsNew));
PaymentsNewWithData.getInitialData = getInitialData;
export default PaymentsNewWithData;
