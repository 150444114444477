const ID_WIDTH = "80px";
const PREVIOUS_ASSIGNEE_WIDTH = "180px";
const RATE_WIDTH = "100px";
const STATUS_WIDTH = "160px";
const BRIEFING_FIELD_MIN_WIDTH = "180px";

/**
 * An object with the grid row styles
 *
 * @param {*} briefingFieldColumnCount the amount of columns we need to dynamically account for
 * @param {Object} columnsToInclude determines whether we should include some columns
 */
export const rowGridStyles = (
  briefingFieldColumnCount,
  {
    includePreviousAssignee,
    includeRates,
    includeStatus,
    includeDeliverableId,
  } = {}
) => {
  const idColumn = includeDeliverableId ? ID_WIDTH : "";
  const previousAssigneeColumn = includePreviousAssignee
    ? PREVIOUS_ASSIGNEE_WIDTH
    : "";
  const rateColumn = includeRates ? RATE_WIDTH : "";
  const statusColumn = includeStatus ? STATUS_WIDTH : "";
  const briefingFieldColumns = `repeat(${briefingFieldColumnCount}, minmax(${BRIEFING_FIELD_MIN_WIDTH}, 1fr))`;

  return {
    display: "grid",
    gridTemplateColumns: ` 50px ${idColumn} ${previousAssigneeColumn} ${rateColumn} ${statusColumn} ${briefingFieldColumns}`,
  };
};
