import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";
import { PersonType } from "../../../../../../../modules/people";
import {
  deliverableById,
  getLoggedInUser,
} from "../../../../../../../utils/entitySelector";
import WithData from "../../../../../../../decorators/WithData";
import DeliverableInfo from "../components/DeliverableInfo";

const mapDispatchToProps = (dispatch, ownProps) => {
  const { deliverableId } = ownProps;
  return {
    getInitialData: () => dispatch(getInitialData({ deliverableId })),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { deliverableId } = ownProps;
  const deliverable = deliverableById(state, deliverableId);
  const { assignments = [] } = deliverable;
  const { personType } = getLoggedInUser(state);

  return {
    assignments,
    isAdmin: personType === PersonType.Admin,
    deliverable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(DeliverableInfo));
