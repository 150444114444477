import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// GraphQL Queries
// ------------------------------------

export const batchDeadlinesByProjectIdQuery = `batchDeadlines(projectId: $projectId) {
    batchId, stageId, deadline, createdAt
}`;

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_BATCH_DEADLINES_SUCCESS = "FETCH_BATCH_DEADLINES_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------

export const fetchBatchDeadlinesSuccess = createAction(
  FETCH_BATCH_DEADLINES_SUCCESS
);

// ------------------------------------
// Action Handlers
// ------------------------------------

export const batchDeadlineInitialState = [];

export const batchDeadlineActionHandlers = {
  [RESET_INITIAL_STATE]: () => batchDeadlineInitialState,
  [FETCH_BATCH_DEADLINES_SUCCESS]: (state, { payload }) => {
    const newState = payload.map((p) => {
      p.deadline = p.deadline = p.deadline.substring(0, 16);
      return p;
    });
    return newState;
  },
};
