import { connect } from "react-redux";
import ReassignmentForm from "../components/ReassignmentForm";
import WithData from "../../../../../../../../../decorators/WithData";
import { getAsyncData } from "../modules/getAsyncData";
import { createReassignments } from "../../../../../../../../../modules/assignments";
import {
  defaultAssigneesByStageAndLanguageSelector,
  workflowStagesSelector,
  projectLanguagesSelector,
} from "../../../../../../../../../utils/entitySelector";

import { hideModal, showModal } from "../../../../../../../../../modules/modal";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAsyncData: () => dispatch(getAsyncData(ownProps)),
    createReassignments: (data) => dispatch(createReassignments(data)),
    hideModal: () => dispatch(hideModal()),
    showModal: (type) => dispatch(showModal(type)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    featureToggles,
    modal,
    people: { entities: peopleEntities },
    stages: { entities: stageEntities },
  } = state;
  const { project, assignmentGroup } = ownProps;
  const { projectId, workflowId } = project;
  const projectLanguages = projectLanguagesSelector(state, projectId);
  const stages = workflowStagesSelector(state, workflowId);
  const defaultAssignees = defaultAssigneesByStageAndLanguageSelector(
    state,
    projectId
  );

  return {
    assignmentGroup,
    defaultAssignees,
    featureToggles,
    modal,
    orderFormId: project.projectId,
    projectId,
    peopleEntities,
    projectLanguages,
    stageEntities,
    stages,
  };
};

const ReassignmentFormWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ReassignmentForm));
export default ReassignmentFormWithData;
