import { connect } from "react-redux";
import Search from "./Search";
import { createSelector } from "reselect";
import { updateSearch } from "../../../modules/search";
import { getProjectLangaugesByProjectId } from "../../../modules/projectLanguages";
import { searchSelector } from "../../../utils/entitySelector";

const mapDispatchToProps = (dispatch) => ({
  updateSearch: (searchTerm) => dispatch(updateSearch(searchTerm)),
});

const getSearchResultsWithLanguages = createSelector(
  searchSelector,
  getProjectLangaugesByProjectId,
  (searchRes, projectLanguages) => {
    return searchRes.map((res) => {
      return {
        ...res,
        projectLanguages: projectLanguages[res.projectId],
      };
    });
  }
);

const mapStateToProps = (state) => {
  const searchResults = getSearchResultsWithLanguages(state);

  return {
    searchResults,
  };
};

const ConnectedSearch = connect(mapStateToProps, mapDispatchToProps)(Search);

export default {
  Component: ConnectedSearch,
};
