import React from "react";
import { AppContainer } from "react-hot-loader";

let ErrorReporter;

// Only show redbox for dev or staging
if (window.__DEV__ || window.__STAGING__) {
  ErrorReporter = require("./ErrorReporter").default;
}

export default (props) => (
  <AppContainer {...props} errorReporter={ErrorReporter}>
    {props.children}
  </AppContainer>
);
