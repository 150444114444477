import { upsertData, removeData } from "../utils/normalize";
import handleErrors from "../utils/handleErrors";
import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import { postGraphQL } from "../../utils/fetch";
import { projectBannedWordsSelector } from "../utils/entitySelector";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const bannedWordsByProjectIdQuery = `bannedWords(projectId: $projectId) {
  bannedWordId, word, languageCode, projectId
}`;

export const bannedWordsByAssignmentGroupQuery = `bannedWords(assignmentGroupId: $assignmentGroupId) {
  bannedWordId, word, languageCode, projectId
}`;

export const bannedWordsByAssignmentQuery = `bannedWords(assignmentId: $assignmentId) {
  bannedWordId, word, languageCode, projectId
}`;

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_BANNED_WORDS_SUCCESS = "FETCH_BANNED_WORDS_SUCCESS";
export const CREATE_BANNED_WORDS_SUCCESS = "CREATE_BANNED_WORDS_SUCCESS";
export const REMOVE_BANNED_WORDS_SUCCESS = "REMOVE_BANNED_WORDS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchBannedWordsSuccess = createAction(FETCH_BANNED_WORDS_SUCCESS);
export const createBannedWordsSuccess = createAction(
  CREATE_BANNED_WORDS_SUCCESS
);
export const removeBannedWordsSuccess = createAction(
  REMOVE_BANNED_WORDS_SUCCESS
);

export function updateBannedWords(data, history, params) {
  const { projectId } = params;
  const bannedWords = [];
  Object.keys(data).forEach((languageCode) => {
    data[languageCode].forEach((word) => {
      bannedWords.push({ projectId, languageCode, word });
    });
  });
  return (dispatch, getState) => {
    const query = `mutation updateBannedWords($associations: [BannedWordInput], $projectId: Int){
      updateBannedWords(associations: $associations, projectId: $projectId) {
        bannedWordId, word, languageCode
      }
    }`;

    return postGraphQL(
      query,
      { associations: bannedWords, projectId },
      "updateBannedWords"
    )
      .then((json) => {
        const oldBannedWords = projectBannedWordsSelector(
          getState(),
          Number(projectId)
        );
        dispatch(createBannedWordsSuccess(json));
        dispatch(removeBannedWordsSuccess(oldBannedWords));
        const url = `/admin/projects/${projectId}`;
        history.push(url);
      })
      .catch((err) => handleErrors(err));
  };
}

// ------------------------------------
// Selectors
// ------------------------------------

/**
 * @param    {Object}    state
 * @param    {number}    projectId
 * @param    {string}    languageCode
 * @returns  {string[]}  banned words
 */
export const bannedWordsForProjectAndLanguage = createSelector(
  (state) => state.bannedWords.entities,
  (_, projectId) => projectId,
  (_s, _p, languageCode) => languageCode,
  (bannedWords, projectId, languageCode) =>
    Object.values(bannedWords)
      .filter(
        (bw) => bw.projectId === projectId && bw.languageCode === languageCode
      )
      .map(({ word }) => word)
);

// ------------------------------------
// Action Handlers
// ------------------------------------
export const bannedWordActionHandlers = {
  [RESET_INITIAL_STATE]: () => bannedWordInitialState,
  [FETCH_BANNED_WORDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "bannedWordId"),
  [CREATE_BANNED_WORDS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "bannedWordId"),
  [REMOVE_BANNED_WORDS_SUCCESS]: (state, { payload }) => {
    return payload.reduce(
      (acc, { bannedWordId }) => removeData(acc, bannedWordId),
      state
    );
  },
};

export const bannedWordInitialState = { entities: {}, result: [] };
