import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./BatchFeedbackButton.module.scss";

/**
 * A button to show the Batch feedback panel if it is not already open
 */
const BatchFeedbackButton = ({ commentCount, display, showBatchFeedback }) => {
  if (!display) return null;

  return (
    <button
      className={classNames({
        [styles.batchFeedbackButton]: true,
        [styles.hasComments]: commentCount > 0,
      })}
      onClick={showBatchFeedback}
    >
      Batch feedback {commentCount > 0 && <span>({commentCount})</span>}
    </button>
  );
};

BatchFeedbackButton.propTypes = {
  commentCount: PropTypes.number.isRequired,
  display: PropTypes.bool.isRequired,
  showBatchFeedback: PropTypes.func.isRequired,
};

export default memo(BatchFeedbackButton);
