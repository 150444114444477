import { connect } from "react-redux";
import Archived from "../components/Archived";
import WithData from "../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import {
  hiddenIdSelector,
  hiddenAccountSelector,
  hiddenOrderFormSelector,
  hiddenProjectSelector,
  hiddenParentAccountSelector,
} from "../../../../utils/entitySelector";
import { restore } from "../../../../modules/hidden";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
    restore: (data) => dispatch(restore(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const parentAccounts = hiddenParentAccountSelector(state);
  const accounts = hiddenAccountSelector(state);
  const orderForms = hiddenOrderFormSelector(state);
  const projects = hiddenProjectSelector(state);
  // @ts-ignore
  const { parentAccountIds, orderFormIds, accountIds, projectIds } =
    hiddenIdSelector(state);
  return {
    parentAccounts,
    accounts,
    orderForms,
    projects,
    parentAccountIds,
    orderFormIds,
    accountIds,
    projectIds,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithData(Archived));
