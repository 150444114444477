import styles from "./AIEnhancementForm.module.scss";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import {
  QuillForm,
  QuillFormField,
  QuillInnerFormContainer,
} from "../../../../../../components/QuillForm";
import QuillHeader from "../../../../../../components/QuillHeader";
import { SelectWithErrors } from "../../../../../../decorators/WithErrors";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
// Define the type for the account objects in the accounts array
interface AccountOption {
  label: string;
  value: number | string;
  object: any;
}

interface SuccessData {
  status?: string;
  jobId?: string;
}

interface ProjectOption {
  label: string;
  value: number | string;
  object: any;
}

interface AttributeOption {
  label: string;
  value: number | string;
  object: any;
}

interface AIEnhancementFormOwnProps {
  accounts: AccountOption[];
  projects: ProjectOption[];
  attributeFields: AttributeOption[];
  fetchProjectsByAccountId: (number) => void;
  fetchBriefingFields: (number) => void;
  downloadCSVTemplate: (projectId) => void;
  processFile: (data) => SuccessData;
}

const AIEnhancementForm: React.FC<
  AIEnhancementFormOwnProps & InjectedFormProps<{}, AIEnhancementFormOwnProps>
> = ({
  handleSubmit,
  submitting,
  accounts,
  projects,
  attributeFields,
  fetchProjectsByAccountId,
  fetchBriefingFields,
  downloadCSVTemplate,
  processFile,
}) => {
  const history = useHistory();
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    null
  );
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    null
  );

  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState<SuccessData>({});
  const [isLoading, setIsLoading] = useState(false);

  const filteredAttributeFields = attributeFields.filter(
    ({ object: { projectId } }) => projectId === selectedProjectId
  );

  const updateSubmitButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setFile(selectedFile);
  };

  useEffect(() => {
    let timeoutId = null;

    if (success && success.jobId) {
      timeoutId = setTimeout(() => {
        history.push("/admin/ai-enhancement");
      }, 500);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [success, history]);

  const handleFileRead = useCallback(
    async (e, file: File) => {
      setIsLoading(true);
      const result = e.target?.result;
      const fileName = file.name;

      if (result && typeof result === "string") {
        const cleanedFile = result.replace(/\r\n?|\n/g, "\n");
        const data = {
          file: cleanedFile,
          projectId: selectedProjectId,
          attributeFields,
          fileName,
        };

        try {
          const results = await processFile(data);
          if (results?.jobId) {
            setSuccess({
              status: "Upload successful, now redirecting...",
              jobId: results.jobId,
            });
          }
        } catch (err) {
          const errorMessage =
            err instanceof Error ? err.message : "An unknown error occurred.";
          setErrors([errorMessage]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setErrors(["Failed to read the file content."]);
      }
    },
    [selectedProjectId, attributeFields]
  );

  const processFileHandler = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      setErrors([]);
      setSuccess(null);

      const fileInput = document.getElementById("upload") as HTMLInputElement;
      if (fileInput?.files?.[0]) {
        const file = fileInput.files[0];

        const reader = new FileReader();
        reader.onload = (e) => handleFileRead(e, file);
        reader.onerror = () => setErrors(["Error reading file."]);
        reader.readAsText(file);
      } else {
        setErrors(["No file selected."]);
      }
    },
    [handleFileRead]
  );

  const handleAccountSelection = async (accountId) => {
    setSelectedAccountId(accountId);
    fetchProjectsByAccountId(accountId);
  };

  const handleProjectSelection = async (projectId) => {
    setSelectedProjectId(projectId);
    fetchBriefingFields(projectId);
  };

  const inputElement = (
    <input
      key="input"
      accept=".csv"
      className={`${styles.greyscaleButton} ${styles.padding} ${styles.marginLeft}`}
      id="upload"
      onChange={updateSubmitButton}
      type="file"
    />
  );

  const submitButton = file && (
    <button
      key="submit-button"
      className={styles.button}
      onClick={processFileHandler}
      type="submit"
      disabled={isLoading}
    >
      Generate AI
    </button>
  );

  const prepareDownloadCSVTemplate = () => {
    downloadCSVTemplate({
      projectId: selectedProjectId,
      projectName: "test",
    });
  };

  return (
    <>
      {/* @ts-ignore */}
      <QuillForm handleSubmit={handleSubmit} rebrandingContainer hideButtons>
        <QuillHeader header="AI Input Enhancement" hideRequiredHeader />
        <QuillInnerFormContainer
          containerStyle={styles.container}
          className={styles.filtersInnerContainer}
        >
          <QuillFormField
            labelText="Select Account"
            required
            name="orderFormId"
            options={accounts}
            component={SelectWithErrors}
            type="text"
            onChange={handleAccountSelection}
          />
          <QuillFormField
            labelText="Select project"
            required
            name="projectId"
            options={projects.filter(
              ({ object: { accountId } }) => accountId === selectedAccountId
            )}
            component={SelectWithErrors}
            type="text"
            onChange={handleProjectSelection}
          />

          <div className={styles.marginBottom}>
            <label>Select AI attributes</label>
            <Field
              name="aiAttributes"
              component={SelectWithErrors}
              disabled={submitting}
              multi
              options={filteredAttributeFields}
              type="number"
            />
          </div>

          <div>
            <div className={`${styles.row} ${styles.right}`}>
              <button
                className={styles.secondaryButton}
                type="button"
                onClick={prepareDownloadCSVTemplate}
              >
                Download template
              </button>
              {[inputElement, submitButton]}
            </div>
          </div>
        </QuillInnerFormContainer>
        <div className={styles.successContainer}>
          {isLoading && "Loading"}
          {errors.length > 0 &&
            errors.map((error, index) => (
              <li className={styles.error} key={index}>
                {error}
              </li>
            ))}
          {success?.status && (
            <li className={styles.success}>{success?.status}</li>
          )}
        </div>
      </QuillForm>
    </>
  );
};

export default reduxForm<{}, AIEnhancementFormOwnProps>({
  form: "AIEnhancementForm",
  enableReinitialize: true,
})(AIEnhancementForm);
