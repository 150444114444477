import React from "react";
import PropTypes from "prop-types";
import styles from "./Profile.module.scss";
import { QuillContainer, QuillField } from "../../../components/QuillContainer";

const exceptions = {
  Freelancer: "Content creator",
  "Lead editor": "Quality Lead",
  "Editorial trainer": "Content Trainer",
};

const toLabel = (data) => exceptions[data] || data;

const Profile = (props) => {
  const {
    freelancerProfile,
    languages,
    person,
    personAccounts,
    personNativeLanguages,
    personRoles,
    qualityGrade,
  } = props;

  // Get a list of all accounts which correspond to this person
  const accountsJoined = personAccounts ? personAccounts.join(", ") : null;

  const personNativeLanguagesJSX =
    personNativeLanguages && personNativeLanguages.length
      ? personNativeLanguages.map(({ nativeLanguage }) => (
          <div className={styles.multiItem}>
            {languages[nativeLanguage].languageName}
          </div>
        ))
      : null;

  return (
    <QuillContainer formContainerStyles={styles.profileContainer}>
      {qualityGrade && (
        <QuillContainer header="Current grades" hideRequiredHeader>
          <div>
            <span className={styles.gradeLabel}>Quality grade:</span>
            <span className={styles.grade}>{qualityGrade}</span> / 5
          </div>
        </QuillContainer>
      )}
      <QuillContainer
        header="Profile"
        hideRequiredHeader
        formContainerStyles={styles.mb0}
      ></QuillContainer>
      <QuillField labelText="Email" value={person.email} />
      <QuillField
        customStyles={styles.multiContainer}
        labelText="Native language"
        value={personNativeLanguagesJSX}
      />
      <QuillField labelText="Person type" value={toLabel(person.personType)} />

      {
        /* If the personType is a client then show the accounts */
        person.personType === "Client" && (
          <QuillField
            labelText="Accounts"
            value={<div className="accounts-data">{accountsJoined}</div>}
          />
        )
      }

      {
        /* If the personType is a freelancer then show the accounts */
        person.personType && person.personType.includes("Freelancer") && (
          <div>
            <QuillField customStyles={styles.multiContainer} labelText="Roles">
              {personRoles.map(({ role }) => (
                <div className={styles.multiItem}>{toLabel(role)}</div>
              ))}
            </QuillField>
            {!freelancerProfile ? (
              <QuillField
                labelText="Payment method"
                value={person.paymentMethod}
              />
            ) : null}
            {person.paymentMethod === "PayPal" ? (
              <QuillField labelText="PayPal email" value={person.payPalEmail} />
            ) : null}
            <QuillField labelText="VAT number" value={person.vatNumber} />
            <QuillField
              labelText="UK resident"
              value={person.ukResident ? "Yes" : "No"}
            />
            <QuillField
              labelText="VAT Registered"
              value={person.vatRegistered ? "Yes" : "No"}
            />
            <QuillField
              labelText="Company registration number"
              value={person.companyRegNumber}
            />
            <QuillField labelText="Business name" value={person.businessName} />
            {!freelancerProfile && person.paymentMethod === "Bank transfer" ? (
              <QuillField labelText="Bank details" value={person.bankDetails} />
            ) : null}
            <QuillField labelText="Address" value={person.address} />
          </div>
        )
      }
    </QuillContainer>
  );
};

Profile.propTypes = {
  admin: PropTypes.bool,
  freelancerProfile: PropTypes.bool,
  languages: PropTypes.object,
  person: PropTypes.shape({
    account: PropTypes.string,
    address: PropTypes.string,
    bankDetails: PropTypes.string,
    businessName: PropTypes.string,
    collective: PropTypes.bool,
    companyRegNumber: PropTypes.string,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    payPalEmail: PropTypes.string,
    paymentMethod: PropTypes.string,
    personId: PropTypes.number.isRequired,
    personType: PropTypes.string.isRequired,
    role: PropTypes.string,
    ukResident: PropTypes.bool,
    vatNumber: PropTypes.string,
    vatRegistered: PropTypes.bool,
  }).isRequired,
  personAccounts: PropTypes.array,
  personNativeLanguages: PropTypes.array.isRequired,
  personRoles: PropTypes.array,
  qualityGrade: PropTypes.number,
};

export default Profile;
