import React, { Component } from "react";
import PropTypes from "prop-types";

function Collapsible(WrappedComponent) {
  class Collapsible extends Component {
    constructor(props) {
      super(props);
      this.state = { open: !props.defaultCollapsed };
    }

    toggle = (open) => {
      const nextState = open === true ? true : !this.state.open;
      this.setState({ open: nextState });
    };

    render() {
      const { children, defaultCollapsed, ...rest } = this.props;
      return (
        <WrappedComponent toggle={this.toggle} open={this.state.open} {...rest}>
          {children}
        </WrappedComponent>
      );
    }
  }

  Collapsible.propTypes = {
    children: PropTypes.element,
    defaultCollapsed: PropTypes.bool,
  };

  return Collapsible;
}

export default Collapsible;
