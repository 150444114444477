import {
  checkValidations,
  isNotBlank,
} from "../../../../../../../utils/validations";

function duplicatesExistOnField(arr, field) {
  if (!arr) return false;

  const fields = arr.map((v) => v[field]);
  const fieldDuplicates = fields.some((val, i) => {
    return fields.indexOf(val) !== i;
  });
  if (fieldDuplicates) return true;
  return false;
}

function checkFieldsRequired(arr, fields, isAiWorkflow) {
  let aiContentModelFound = false;

  for (var i = 0; i < arr.length; i++) {
    const value = arr[i];

    // Check if 'AI content model' is present in at least one of the briefingFieldFormat values
    if (
      isAiWorkflow &&
      value.briefingFieldFormat &&
      value.briefingFieldFormat.includes("AI content model")
    ) {
      aiContentModelFound = true;
    }

    for (var j = 0; j < fields.length; j++) {
      const field = fields[j];
      const error = checkValidations(value[field.key], [
        { validate: isNotBlank, msg: field.message },
      ]);
      if (error) return error;
    }
  }

  // If isAiWorkflow is true and AI content model format was not found in any of the items, return an error
  if (isAiWorkflow && !aiContentModelFound) {
    return "AI content model is required in at least one Briefing Field";
  }

  return false;
}

const validate = (values, { isAiWorkflow }) => {
  const errors = {};

  if (values && values.briefingFields) {
    const arrayErrors = checkFieldsRequired(
      values.briefingFields,
      [
        {
          key: "briefingFieldName",
          message: "Briefing field name is required",
        },
        {
          key: "briefingFieldFormat",
          message: "Briefing field format is required",
        },
      ],
      isAiWorkflow
    );

    if (arrayErrors) errors.briefingFields = { _error: arrayErrors };
  }

  if (values && values.taskFields) {
    const arrayErrors = checkFieldsRequired(values.taskFields, [
      { key: "taskFieldName", message: "Task field name is required" },
      { key: "taskFieldFormat", message: "Task field format is required" },
    ]);
    if (arrayErrors) errors.taskFields = { _error: arrayErrors };
  }

  if (!errors.briefingFields) {
    if (duplicatesExistOnField(values.briefingFields, "briefingFieldName")) {
      errors.briefingFields = { _error: "Briefing field names must be unique" };
    }
  }

  if (!errors.taskFields) {
    if (duplicatesExistOnField(values.taskFields, "taskFieldName")) {
      errors.taskFields = { _error: "Task field names must be unique" };
    }
  }

  if (!errors.keywordGroups) {
    if (duplicatesExistOnField(values.keywordGroups, "keywordGroupName")) {
      errors.keywordGroups = { _error: "Keyword group names must be unique" };
    }
  }

  return errors;
};

export default validate;
