import React from "react";
import PropTypes from "prop-types";
import styles from "./Count.module.scss";
import classNames from "classnames";

const Count = ({ label, count, min, max }) => {
  const minWarning = count < min;
  const maxWarning = count > max;

  const minStyles = classNames({
    [styles.warning]: minWarning,
  });

  const maxStyles = classNames({
    [styles.warning]: maxWarning,
  });

  return (
    <div className={styles.container}>
      <span>
        {label}: {count}
      </span>

      {(min || max) && (
        <span>
          {" "}
          ({min && <span className={minStyles}>min: {min}</span>}
          {min && max ? " / " : null}
          {max && <span className={maxStyles}>max: {max}</span>})
        </span>
      )}
    </div>
  );
};

Count.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
};

export default Count;
