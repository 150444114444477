import gql from "graphql-tag";

const fields = [
  "assignmentGroupAdjustmentId",
  "assignmentGroupId",
  "adjustmentType",
  "deadline",
  "reason",
  "actioned",
];

// GraphQL tagged for useQuery/useMutation
export const GET_ADJUSTMENT_QUERY_GQL = gql`
  query getAssignmentGroupAdjustment($assignmentGroupId: Int) {
    assignmentGroupAdjustment(assignmentGroupId: $assignmentGroupId) {
      ${fields}
    }
  }
`;

export const GET_ASSIGNMENT_GROUP_DELIVERABLES = gql`
  query getAssignmentGroupDeliverables($assignmentGroupId: String) {
    deliverables(assignmentGroupId: $assignmentGroupId) {
      deliverableId
    }
  }
`;

export const GET_DELIVERABLE = gql`
  query getDeliverable($deliverableId: String, $includeAssignments: Boolean) {
    deliverable(
      deliverableId: $deliverableId
      includeAssignments: $includeAssignments
    ) {
      assignments {
        assignee
        deadline
        stageId
        stageName
        assignmentGroupId
      }
    }
  }
`;

export const UPDATE_ADJUSTMENT_GQL = gql`
  mutation updateAssignmentGroupAdjustment(
    $input: AssignmentGroupAdjustmentUpdateInput
  ) {
    updateAssignmentGroupAdjustment(input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_ADJUSTMENT_QUERY_GQL = gql`
  mutation createAssignmentGroupAdjustment(
    $input: AssignmentGroupAdjustmentInput
  ) {
    createAssignmentGroupAdjustment(input: $input) {
      ${fields}
    }
  }
`;
