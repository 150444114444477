import React, { useState } from "react";
import Helmet from "react-helmet";
import BulkQA from "../../../../components/BulkQA";
import PageHeader from "../../../../components/PageHeader";
import Errors from "../../../../components/Errors";
import ProjectInfo from "../../../../components/PageHeader/ProjectInfo";
import styles from "./BulkQA.module.scss";
import ToggleThrough from "../../../../components/ToggleThrough";

const BulkQAdmin = (props) => {
  const {
    errors,
    tfcByDeliverable,
    project: {
      accountName,
      orderFormNumber,
      parentAccountName,
      projectId,
      projectName,
    },
    listUrl,
  } = props;

  const [index, setIndex] = useState();
  const [words, setWords] = useState([]);
  const [toggled, setToggle] = useState();

  const setWordMatches = (wordMatches, index, hasBeenToggled) => {
    setWords(wordMatches);
    setIndex(index);
    setToggle(hasBeenToggled);
  };

  return (
    <>
      <Helmet>
        <title>Batch view</title>
      </Helmet>
      {errors && errors.display && <Errors />}

      <PageHeader
        breadCrumbItems={[
          { url: `/admin`, text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { url: listUrl, text: `Assignment list` },
          { text: "Batch view" },
        ]}
        title={projectName || ""}
      >
        <div className={styles.headerRight}>
          <ToggleThrough
            setWordMatches={setWordMatches}
            content={tfcByDeliverable}
          />
          <ProjectInfo url={`/admin/projects/${projectId}/project-info`} />
        </div>
      </PageHeader>
      <BulkQA
        listUrl={listUrl}
        toggled={toggled}
        index={index}
        wordMatches={words}
        {...props}
      />
    </>
  );
};

export default BulkQAdmin;
