import React from "react";
import PropTypes from "prop-types";
import styles from "./ValueComponent.module.scss";
import classNames from "classnames";

// To customize the Remove button we needed to make a custom value component
// https://github.com/JedWatson/react-select/issues/1263#issuecomment-285890229

const ValueComponent = (props) => {
  const {
    disabled, // state of this option
    value, // the original option object
    onClick, // the function supplied by onValueClick property
    onRemove, // internal function to remove the selected item.
    // If you want to call it you need to also give it the value field.
    rebrandingContainer,
  } = props;

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.disabled]: disabled,
    [styles.teamAssignmentRebranding]: rebrandingContainer,
  });

  return (
    <div
      className={containerStyles}
      onMouseDown={(e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
        // for sanity's sake
        e.stopPropagation();
      }}
    >
      <div className={styles.label}>{value.label}</div>
      {!disabled && (
        <div
          className={styles.clear}
          onMouseDown={(e) => {
            if (!disabled) onRemove(value);
          }}
        >
          <svg version="1.1" width="16" height="16" viewBox="0 0 16 16">
            {/* eslint-disable max-len */}
            <path d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z" />
            {/* eslint-enable max-len */}
          </svg>
        </div>
      )}
    </div>
  );
};

ValueComponent.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
  }),
  onClick: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
};

export default ValueComponent;
