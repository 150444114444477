import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./LinkButton.module.scss";
import LinkEditor from "./LinkEditor";

class LinkButton extends React.Component {
  constructor(props) {
    super(props);
    const { target, url, title, text, selectedText } = this.getSelectedLink();

    this.state = {
      text,
      url,
      title,
      target,
      selectedText,
      popupVisible: false,
    };
  }

  togglePopup = () => {
    if (this.props.disabled) return;

    this.setState({
      popupVisible: !this.state.popupVisible,
    });
  };

  /**
   * @returns {object} data for the selected link (url, text, title, linkKey)
   *  and the currently selected text
   */
  getSelectedLink() {
    if (!this.props.editorState) return {};

    const editorState = this.props.editorState;
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(anchorKey);
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = currentBlock.getText().slice(start, end);
    const linkKey = currentBlock.getEntityAt(start);

    if (linkKey) {
      const { target, url, title, text } = contentState
        .getEntity(linkKey)
        .getData();
      return { url, title, text, linkKey, selectedText, target };
    }

    return { selectedText };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /* Close popup when clicking outside of box */
  handleClickOutside = (event) => {
    if (
      this.state.popupVisible &&
      this.props.wrapperRef &&
      !this.props.wrapperRef.contains(event.target)
    ) {
      this.setState({ popupVisible: false });
    }
  };

  render() {
    const { target, url, title, selectedText, text, linkKey } =
      this.getSelectedLink();
    const buttonStyles = classNames({
      [styles.buttonBase]: true,
      [styles.buttonActive]: linkKey || this.state.popupVisible,
    });

    return (
      <div className={styles.buttonContainer}>
        <span className={buttonStyles} onClick={this.togglePopup}>
          <svg className={styles.urlLogo} height="15" width="15">
            {/* eslint-disable max-len  */}
            <path
              d="M13.967,0.95 C13.3555,0.3385 12.53975,0.00175 11.66975,0.00175 C10.8,0.00175 9.984,0.3385 9.3725,0.95 L7.105,3.2175 C6.28625,4.03625 6,5.1865 6.23975,6.24 C6.0045,6.187 5.76225,6.15675 5.51475,6.15675 C4.645,6.15675 3.829,6.4935 3.21775,7.105 L0.95,9.37275 C-0.3165,10.63925 -0.3165,12.70075 0.95,13.96725 C1.5615,14.57875 2.37725,14.9155 3.24725,14.9155 C4.11725,14.9155 4.933,14.57875 5.5445,13.96725 L7.812,11.69975 C8.63075,10.881 8.917,9.73075 8.67725,8.67725 C8.9125,8.73025 9.15475,8.7605 9.40225,8.7605 C10.27225,8.7605 11.08825,8.42375 11.6995,7.81225 L13.96725,5.54475 C15.234,4.278 15.234,2.21675 13.967,0.95 L13.967,0.95 Z M7.105,10.9925 L4.8375,13.26 C4.415,13.6825 3.85,13.91525 3.24725,13.91525 C2.6445,13.91525 2.07975,13.6825 1.657,13.26 C0.78025,12.383 0.78025,10.9565 1.657,10.0795 L3.92475,7.812 C4.34725,7.3895 4.912,7.15675 5.51475,7.15675 C5.94575,7.15675 6.35625,7.2775 6.71025,7.49975 L4.77225,9.43775 C4.577,9.633 4.577,9.9495 4.77225,10.14475 C4.86975,10.2425 4.99775,10.29125 5.12575,10.29125 C5.25375,10.29125 5.38175,10.2425 5.47925,10.14475 L7.4175,8.2065 C7.963,9.075 7.86,10.23725 7.105,10.9925 L7.105,10.9925 Z M13.26,4.8375 L10.99225,7.105 C10.56975,7.5275 10.005,7.76025 9.402,7.76025 C8.971,7.76025 8.56075,7.6395 8.20675,7.41725 L10.14475,5.47925 C10.34,5.284 10.34,4.9675 10.14475,4.77225 C9.94975,4.577 9.63275,4.577 9.43775,4.77225 L7.4995,6.7105 C6.954,5.842 7.057,4.68 7.812,3.92475 L10.0795,1.65725 C10.502,1.23475 11.067,1.002 11.66975,1.002 C12.27275,1.002 12.83725,1.23475 13.26,1.65725 C13.68275,2.07975 13.91525,2.6445 13.91525,3.2475 C13.91525,3.85025 13.6825,4.415 13.26,4.8375 L13.26,4.8375 Z"
              fill={linkKey || this.state.popupVisible ? "#da0034" : "#999"}
            />
            {/* eslint-enable max-len */}
          </svg>
        </span>

        {this.state.popupVisible && (
          <LinkEditor
            key={linkKey || selectedText}
            editorState={this.props.editorState}
            selectedText={selectedText}
            target={target}
            text={text}
            title={title}
            togglePopup={this.togglePopup}
            updateEditorState={this.props.updateEditorState}
            url={url}
          />
        )}
      </div>
    );
  }
}

LinkButton.propTypes = {
  updateEditorState: PropTypes.func.isRequired,
  wrapperRef: PropTypes.object,
  editorState: PropTypes.object,
  disabled: PropTypes.bool,
};

export default LinkButton;
