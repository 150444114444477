import { upsertData, removeData } from "../utils/normalize";
import { createAction } from "redux-actions";
import handleErrors from "../utils/handleErrors";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import {
  accountsByParentAccountIdQuery,
  fetchAccountsSuccess,
} from "./accounts";
import {
  orderFormsByParentAccountQuery,
  fetchOrderFormsSuccess,
} from "./orderForms";
import {
  projectsByParentAccountIdsQuery,
  fetchProjectsSuccess,
} from "./projects";
import { hideModal } from "./modal";
import { RESET_INITIAL_STATE } from "./me";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
export const parentAccountsQuery =
  "parentAccounts { parentAccountId, parentAccountName }";
export const parentAccountByIdQuery =
  "parentAccount(parentAccountId: $parentAccountId) { parentAccountId, parentAccountName }";
export const archivedParentAccounts =
  "parentAccounts (archived: $archived) { parentAccountId, parentAccountName }";
export const parentAccountsByOrderFormQuery =
  "parentAccounts (orderFormId: $orderFormId){ parentAccountId, parentAccountName }";
export const parentAccountsByProjectQuery =
  "parentAccounts (projectId: $projectId){ parentAccountId, parentAccountName }";
export const fetchParentAccountRelationshipsQuery = `query fetchParentAccountRelationships ($parentAccountId: String) {
  ${accountsByParentAccountIdQuery}
  ${orderFormsByParentAccountQuery}
  ${projectsByParentAccountIdsQuery}
}`;

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_PARENT_ACCOUNT_SUCCESS = "FETCH_PARENT_ACCOUNT_SUCCESS";
export const FETCH_PARENT_ACCOUNTS_SUCCESS = "FETCH_PARENT_ACCOUNTS_SUCCESS";
export const CREATE_PARENT_ACCOUNT_SUCCESS = "CREATE_PARENT_ACCOUNT_SUCCESS";
export const UPDATE_PARENT_ACCOUNT_SUCCESS = "UPDATE_PARENT_ACCOUNT_SUCCESS";
export const ARCHIVE_PARENT_ACCOUNT_SUCCESS = "ARCHIVE_PARENT_ACCOUNT_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchParentAccountSuccess = createAction(
  FETCH_PARENT_ACCOUNT_SUCCESS
);
export const fetchParentAccountsSuccess = createAction(
  FETCH_PARENT_ACCOUNTS_SUCCESS
);
export const createParentAccountSuccess = createAction(
  CREATE_PARENT_ACCOUNT_SUCCESS
);
export const updateParentAccountSuccess = createAction(
  UPDATE_PARENT_ACCOUNT_SUCCESS
);
export const archiveParentAccountSuccess = createAction(
  ARCHIVE_PARENT_ACCOUNT_SUCCESS
);

export function createParentAccount(data) {
  return (dispatch, getState) => {
    const query = `mutation createParentAccount($input: ParentAccountInput){
      createParentAccount(input: $input) {
        parentAccountId, parentAccountName
      }
    }`;

    return postGraphQL(query, { input: data }, "createParentAccount")
      .then((json) => {
        dispatch(createParentAccountSuccess(json));
        dispatch(hideModal());
      })
      .catch((err) => handleErrors(err));
  };
}

export function updateParentAccount(data) {
  return (dispatch, getState) => {
    const query = `mutation updateParentAccount($input: ParentAccountInput){
      updateParentAccount(input: $input) {
        parentAccountId, parentAccountName
      }
    }`;
    return postGraphQL(query, { input: data }, "updateParentAccount")
      .then((json) => {
        dispatch(updateParentAccountSuccess(json));
        dispatch(hideModal());
      })
      .catch((err) => handleErrors(err));
  };
}

export function archiveParentAccount(parentAccountId, history) {
  return (dispatch, getState) => {
    const query = `mutation archiveParentAccount($input: ParentAccountInput){
      archiveParentAccount(input: $input) {
        parentAccountId
      }
    }`;
    return postGraphQL(
      query,
      { input: { parentAccountId } },
      "archiveParentAccount"
    ).then((json) => {
      dispatch(archiveParentAccountSuccess(parentAccountId));
    });
  };
}

export function fetchParentAccountRelationships(parentAccountId) {
  return (dispatch, getState) => {
    return getGraphQL(fetchParentAccountRelationshipsQuery, {
      parentAccountId,
    }).then((json) => {
      dispatch(fetchAccountsSuccess(json.accounts));
      dispatch(fetchOrderFormsSuccess(json.orderForms));
      dispatch(fetchProjectsSuccess(json.projects));
    });
  };
}

export function searchParentAccounts(input) {
  return async (dispatch, getState) => {
    const query = `query searchParentAccounts($search: String){
      parentAccounts(search: $search) { parentAccountId, parentAccountName }
    }`;
    try {
      const json = await getGraphQL(query, { search: input });
      dispatch(fetchParentAccountsSuccess(json.parentAccounts));
    } catch (err) {}
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
export const parentAccountActionHandlers = {
  [RESET_INITIAL_STATE]: () => parentAccountInitialState,
  [FETCH_PARENT_ACCOUNT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentAccountId"),
  [FETCH_PARENT_ACCOUNTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentAccountId"),
  [CREATE_PARENT_ACCOUNT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentAccountId"),
  [UPDATE_PARENT_ACCOUNT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "parentAccountId"),
  [ARCHIVE_PARENT_ACCOUNT_SUCCESS]: (state, { payload }) =>
    removeData(state, payload),
};

export const parentAccountInitialState = { entities: {}, result: [] };
