export function sortedInsert(sortedArray, item) {
  const idx = locationToInsert(item, sortedArray) + 1;
  const copy = sortedArray.slice();
  // need to make a copy because splice makes changes inplace
  copy.splice(idx, 0, item);
  return copy;
}

function locationToInsert(element, array, start, end) {
  if (array.length === 0) {
    return -1;
  }

  start = start || 0;
  end = end || array.length;
  var pivot = (start + end) >> 1; // should be faster than dividing by 2

  var c = sortFn(element, array[pivot]);

  if (end - start <= 1) return c < 0 ? pivot - 1 : pivot;

  const val = c === 0 ? c : c < 0 ? -1 : 1;
  switch (val) {
    case -1:
      return locationToInsert(element, array, start, pivot);
    case 0:
      return pivot;
    case 1:
      return locationToInsert(element, array, pivot, end);
    default:
      return locationToInsert(element, array, pivot, end);
  }
}

export const binarySearch = (items, match, key) => {
  let low = 0;
  let high = items.length - 1;
  while (low <= high) {
    const mid = parseInt((low + high) / 2);
    const current =
      key && typeof items[mid] === "object" ? items[mid][key] : items[mid];
    // we can have undefined if someone creates a new object, the id will not be ther
    // in that case we increment the loop 1 and continue
    if (current === undefined) {
      low += 1;
      continue;
    }

    const sortRes = sortFn(current, match);

    if (sortRes > 0) {
      high = mid - 1;
    } else if (sortRes < 0) {
      low = mid + 1;
    } else {
      return mid;
    }
  }
  return -1;
};

export function sortFn(a, b) {
  if (typeof a === "undefined") {
    return -1;
  } else if (typeof b === "undefined") {
    return 1;
  }
  const numericA = Number(a);
  if (isNaN(numericA) && typeof a === "string") return a.localeCompare(b);
  // should be numbers here only
  const numericB = Number(b);
  return numericA - numericB;
}

/* takes 2 arrays of objects and returns
  what's unique in array 1, what's unique in array 2
  and what exists in both.
  Useful for determining which elements were deleted,
  which need to be created, and which need updating
*/
export function splitItems(first, second, key) {
  const removeItems = [];
  const updateItems = [];
  const createItems = [];

  for (let i = 0; i < first.length; i++) {
    const searchTerm = typeof first[i] === "object" ? first[i][key] : first[i];
    if (binarySearch(second, searchTerm, key) === -1) {
      removeItems.push(first[i]);
    }
  }

  for (let i = 0; i < second.length; i++) {
    // if searching by a key and the key doesn't exist in the object then we assume it is created
    // ie a new item won't have the id field
    // if we can't find it in the first array, then we also know it is a new item
    const searchTerm =
      typeof second[i] === "object" ? second[i][key] : second[i];
    if (
      (key && !second[i].hasOwnProperty(key)) ||
      binarySearch(first, searchTerm, key) === -1
    ) {
      createItems.push(second[i]);
    } else {
      updateItems.push(second[i]);
    }
  }

  return [removeItems, createItems, updateItems];
}

export function splitArrayObjects(first, second, key) {
  const originalArray = first.slice();
  const newArray = second.slice();
  originalArray.sort((a, b) => sortFn(a[key], b[key]));
  newArray.sort((a, b) => sortFn(a[key], b[key]));
  return splitItems(originalArray, newArray, key);
}
